import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component
import { FETCH_URL } from "fetchIp";
import DeleteDialog from "../../../../../../ActionDialog/DeleteDialog";
import SwtichDialog from "../../../../../../ActionDialog/SwitchDialog";
export default function Radio(props) {
  const { TrainerId } = props;
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  const [assignedCourse, setAssignedCourse] = useState([]);

  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  console.log(TrainerId, "TrainerId>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  const getAllAssignCourseToTrainer = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getTrainerCourses/${TrainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setAssignedCourse(res.msg);
        console.log(" Course Assign Trainer =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  React.useEffect(() => {
    getAllAssignCourseToTrainer();
  }, []);
  console.log(assignedCourse, "assignedCourse>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..");
  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead"> Course Name </TableCell>
                <TableCell className="ColHead"> Course Description</TableCell>
                <TableCell className="ColHead"> Course Price</TableCell>
                <TableCell className="ColHead"> Assigned on </TableCell>
                <TableCell className="ColHead"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {assignedCourse.map((cousrseAssigned) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {cousrseAssigned.courseUid} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {cousrseAssigned.courseTitle}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {cousrseAssigned.courseDesc}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {cousrseAssigned.coursePrice}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {cousrseAssigned.assignedOn.split("T")[0]}
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <SwtichDialog accessCheck={access} />
                    </IconButton>
                    <DeleteDialog />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

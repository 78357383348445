import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
} from "@material-ui/core";
// React Icons
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { GiTeacher } from "react-icons/gi";
//core Module
import TrainerAssign from "./TrainerAssignTab/TrainerSelectAssignTab";
import LPRename from "./TrainerAssignTab/TrainerRenameTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="" {...other}>
      {/* <Typography className="DialogTitleTypo">Assign Trainer</Typography> */}
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AddDialog({ TrainerId }) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);
  const [trainerData, setTrainerData] = React.useState(null);
  const [assigntrainerid, setAssignTrainerId] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data
  const [LPUid, setLPUid] = React.useState("");
  const [LPStartDate, setLPStartDate] = React.useState(null);
  const [LPEndDate, setLPEndDate] = React.useState(null);
  const [LPTitle, setLPTitle] = React.useState("");
  /////
  const [selectTrainerText, setSelectTrainerText] = React.useState("");
  const [lessonplanUidText, setLessonPlanUidText] = React.useState("");
  const [lessonPlanTitleText, setLessonPlanTitleText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setLPUid("");
    setSelectTrainerText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setLPStartDate(null);
    setLPEndDate(null);
    setLPTitle("");
    setValue(0);
  };

  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    if (filterdata.length === 0) {
      setSelectTrainerText("please select a trainer!");

      return;
    } else {
      setSelectTrainerText("");
    }
    setValue(newValue);
  };
  function TabNext() {
    if (filterdata.length === 0) {
      setSelectTrainerText("please select a trainer!");

      return;
    } else {
      setSelectTrainerText("");
    }
    setValue(1);
  }

  const getAllTrainers = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/learner/getAllTrainers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setTrainerData(res.msg);
        setAssignTrainerId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllTrainers();
  }, []);

  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }

  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  console.log(filterdata, "filterdata");
  // const assignedTrainers = filterdata.map((aT) => aT._id);
  const assignedTrainers = filterdata.map((a) => ({
    trainerId: a._id,
    notificationToken: a.notificationToken,
    assignedSchool: a.assignedSchool,
  }));

  console.log("assignedTrainers", assignedTrainers);
  const submitAssignTrainer = async (e) => {
    e.preventDefault();
    ///////
    if (
      LPUid === null ||
      LPUid.length === 0 ||
      (LPUid === undefined && LPTitle === null) ||
      LPTitle === undefined ||
      LPTitle.length === 0
    ) {
      if (LPUid.length === 0) {
        setLessonPlanUidText("please enter lesson plan uid !");
      }

      if (LPTitle.length === 0) {
        setLessonPlanTitleText("please enter lesson plan title !");
      }
    }
    /////
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/assignLessonPlanToTrainer/${TrainerId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            assignedTrainers: assignedTrainers,
            LPUid: LPUid,
            LPTitle: LPTitle,
            LPStartDate: LPStartDate,
            LPEndDate: LPEndDate,
          }),
        }
      );

      // console.log("Assign Id", filtered);
      console.log("Assign Id", LPUid);
      console.log("Assign Id", LPTitle);

      const res = await response.json();
      if (response.ok) {
        console.log("My Trainer   Post response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
        getAllTrainers();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <GiTeacher className="ActionButtonIcon" onClick={handleClickOpen} />
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Select Trainer"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
          </Tabs>
          <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        </DialogTitle>

        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <TrainerAssign
              states={{
                trainerData,
                setTrainerData,
                originalData,
                selectTrainerText,
              }}
              storeSkill={storeSkill}
              getAllTrainers={getAllTrainers}
              filterdata={filterdata}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LPRename
              states={{
                LPUid,
                setLPUid,
                LPStartDate,
                setLPStartDate,
                LPEndDate,
                setLPEndDate,
                LPTitle,
                setLPTitle,
                lessonPlanTitleText,
                lessonplanUidText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={
              value == 0 ? TabNext : value == 1 ? submitAssignTrainer : null
            }
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
          {/* <Button
            className="GreyButton700 EquipActionBottom"
            onClick={handleClose}
          >
            {"Cancel"}
          </Button>
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={submitAssignTrainer}
          >
            {"Done"}
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

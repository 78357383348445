import * as React from "react";
import {
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  Input,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

export default function ExtendTab(props) {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const {
    states: {
      fullName,
      setFullName,
      dob,
      setDob,
      regNo,
      setRegNo,
      enrollmentDate,
      setEnrollmentDate,
      fathersName,
      setFathersName,
      mothersName,
      setMothersName,
      learnerEmail,
      setLearnerEmail,
      learnerPhoneNo,
      setLearnerPhoneNo,
      learnerLocation,
      setLearnerLocation,
      learnerAddress,
      setLearnerAddress,
      learnerZipCode,
      setLearnerZipCode,
      learnerCity,
      setLearnerCity,
      learnerState,
      setLearnerState,
      learnerCountry,
      setlearnerCountry,
      guardianFullName,
      setGuardianFullName,
      guardianLocation,
      setGuardianLocation,
      guardianEmail,
      setGuardianEmail,
      guardingPhoneNo,
      setGuardingPhoneNo,
      schoolName,
      setSchoolName,
      grade,
      setGrade,
      location,
      setLocation,
    },
    Tabnext2,
  } = props;
  console.log({ Tabnext2 });
  const [postalData, setPostalData] = React.useState([]);

  const [postCodeMsg, setPostCodeMsg] = React.useState("");

  const searchByPostalCode = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    console.log(response, "response");
    if (response.ok) {
      const Postaldata = res;
      console.log(res, "resres");
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setPostCodeMsg("please enter a valid post code!");
        setLearnerCity("");
        setLearnerState("");
        setlearnerCountry("");
        return;
      }
      setPostCodeMsg("");
      setPostalData(Postaldata);
      setLearnerCity(Postaldata?.map((a) => a?.PostOffice[0].District)[0]);
      setLearnerState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setlearnerCountry(Postaldata?.map((a) => a?.PostOffice[0].Country)[0]);
    }
  };

  console.log(postalData, "postalData>>>>>>>>>>>");

  React.useEffect(() => {
    if (String(learnerZipCode).length === 6) {
      searchByPostalCode(learnerZipCode);
    } else {
      console.log("iiiiiiiiiiiiiiiiiii");
    }
    if (String(learnerZipCode).length < 6) {
      setLearnerCity("");
      setLearnerState("");
      setlearnerCountry("");
      // setPostCodeMsg("please enter a valid post code!");
    }
  }, [learnerZipCode]);

  const [validTxt, setvalidTxt] = React.useState("");

  function PhoneNumberValidation(txtPhone) {
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;
    if (txtPhone.match(phoneno) || txtPhone.match(mobileno)) {
      console.log("Valid");
      setvalidTxt("");
    } else {
      console.log("notValid");
      if (txtPhone.length < 10) {
        setvalidTxt("please enter a valid phone number!");
      }
    }
  }

  console.log(typeof learnerPhoneNo, "learnerPhoneNolearnerPhoneNo");

  return (
    <form>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6}>
          <Typography className="BlueColorTypography400" align="center">
            Learner Contact Detail
          </Typography>
          <Grid item container direction="column" style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography GreyColorTypography">
              Email ID
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="email"
                value={learnerEmail}
                onChange={(e) => setLearnerEmail(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Mobile Number
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1 "
                type="number"
                value={learnerPhoneNo}
                onChange={(e) => {
                  PhoneNumberValidation(e.target.value);
                  setLearnerPhoneNo(e.target.value.slice(0, 10));
                }}
                onKeyDown={handleEnter}
              ></Input>
              <Typography className="warningTypography">{validTxt}</Typography>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location
              {/* <span className="ImpColor"> *</span> */}
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={learnerLocation}
                onChange={(e) => setLearnerLocation(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Address
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <TextareaAutosize
                className="ContactTextArea"
                aria-label="minimum height"
                value={learnerAddress}
                onChange={(e) => setLearnerAddress(e.target.value)}
                onKeyDown={handleEnter}
              />
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              ZipCode
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="number"
                value={learnerZipCode}
                onChange={(e) => setLearnerZipCode(e.target.value.slice(0, 6))}
                onKeyDown={handleEnter}
              ></Input>
              {postCodeMsg.length > 0 && (
                <Typography className="warningTypography">
                  {postCodeMsg}
                </Typography>
              )}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              City
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={learnerCity}
                onChange={(e) => setLearnerCity(e.target.value)}
                onKeyDown={handleEnter}
                disabled
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              State
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              {/* <FormControl
              variant="standard"
              className="FormControlInput"
              style={{ backgroundColor: "white" }}
            >
              <Select
                className=" SkillsSelectStyle "
                value={learnerState ? learnerState : 0}
                onChange={setLearnerState}
                color="white"
                label="Locomotor"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  Select State
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Delhi"}>
                  Delhi
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Noida"}>
                  Noida
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Mumbai"}>
                  Mumbai
                </MenuItem>
              </Select>
            </FormControl>{" "} */}
              <Input
                className="Input1"
                value={learnerState}
                onChange={(e) => setLearnerState(e.target.value)}
                onKeyDown={handleEnter}
                disabled
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              learnerCountry
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              {/* <FormControl
              variant="standard"
              className="FormControlInput"
              style={{ backgroundColor: "white" }}
            >
              <Select
                className=" SkillsSelectStyle "
                value={learnerCountry ? learnerCountry : 0}
                onChange={setlearnerCountry}
                color="white"
                label="Locomotor"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  Select Country
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"India"}>
                  India
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"UK"}>
                  UK
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Shri Lanka"}>
                  Shri Lanka
                </MenuItem>
              </Select>
            </FormControl>{" "} */}
              <Input
                className="Input1"
                value={learnerCountry}
                onChange={(e) => setlearnerCountry(e.target.value)}
                onKeyDown={handleEnter}
                disabled
              ></Input>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} direction="column">
          {" "}
          <Typography className="BlueColorTypography400" align="center">
            Guardian’s Contact Detail
          </Typography>{" "}
          <Grid item container direction="column">
            <Typography className="InputTitleTypography GreyColorTypography">
              Full Name
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={guardianFullName}
                onChange={(e) => setGuardianFullName(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={guardianLocation}
                onChange={(e) => setGuardianLocation(e.target.value)}
                onKeyDown={handleEnter}
              >
                {" "}
              </Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Email ID
              {/* <span className="ImpColor"> *</span> */}
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="email"
                value={guardianEmail}
                onChange={(e) => setGuardianEmail(e.target.value)}
                onKeyDown={handleEnter}
              >
                {" "}
              </Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Mobile Number
              {/* <span className="ImpColor"> *</span> */}
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="number"
                value={guardingPhoneNo}
                onChange={(e) =>
                  setGuardingPhoneNo(e.target.value.slice(0, 10))
                }
                onKeyDown={handleEnter}
              >
                {" "}
              </Input>
            </Grid>
            <DialogActions className="">
              <Button
                className="GreenButton700 NextActionBottom"
                autoFocus
                onClick={Tabnext2}
              >
                Next
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>{" "}
    </form>
  );
}

import React, { useState, useEffect } from "react";

import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";

export default function BasicTabs(props) {
  const {
    states: {
      learnerAssesmentResult,
      schoolSelected,

      totalR,
      pageR,
      searchR,
    },

    handleSearch,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [value, setValue] = React.useState(0);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">Assessment Uid</TableCell>
              <TableCell className="Head">Assessment Name</TableCell>

              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> PP</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerAssesmentResult.map((lAresult, i) => (
              <TableRow key={i}>
                <TableCell className="Col ActionButtonSize">
                  {lAresult.assessmentData[0].uid}{" "}
                </TableCell>
                <TableCell className="Col">
                  {lAresult.assessmentData[0].assessmentName}
                </TableCell>
                <TableCell className="Col">
                  <Typography className="GreyColorTypography500 overflow-style">
                    {lAresult.assessmentData[0].assessmentDesc}
                  </Typography>
                </TableCell>
                <TableCell className="Col">
                  {lAresult.assessmentData[0].assignedXp}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {learnerAssesmentResult?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Result Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected ||
      searchR ||
      learnerAssesmentResult?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => handleSearch("", value, value2)}
            pageSize={rowsPerPage}
            total={totalR}
            current={pageR}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

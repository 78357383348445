import React from "react";
import {
  Typography,
  Grid,
  Input,
  TextareaAutosize,
  Chip,
} from "@material-ui/core";
import paddleImg from "assets/img/paddleImg.jpg";

export default function EquipmentProfile(props) {
  const { equipment } = props;

  console.log("equipment Details ", equipment);

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid container item className="PT-10px">
          <Grid item md={3} xs={3} sm={3}>
            <Typography className="GreyColorTypography PL-20px  ">
              {" "}
              Name
            </Typography>{" "}
          </Grid>

          <Grid md={9} xs={9} sm={9} item>
            <Typography className="GreenColorTypography">
              {" "}
              {equipment.equipmentName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid md={3} xs={3} sm={3} item>
            <Typography className="GreyColorTypography PL-20px">
              Description
            </Typography>{" "}
          </Grid>
          <Grid md={9} xs={9} sm={9} item>
            <Typography className="GreyColorTypography">
              {equipment.equipmentDesc}
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container item className="PL-20px">
          <Typography className="GreyColorTypography"> Sports</Typography>{" "}
        </Grid>

        {/* <Grid container item>
          {equipment.map((user, key) => (
            <Grid item className="PL-20px">
              <Chip
                label={user.labelEquipKit2}
                className="WhiteColorTypography GPCEquipChip   "
              />
            </Grid>
          ))}
        </Grid> */}

        <Grid container md={12} className="GPCEqupImage ">
          <Grid item xs={6}>
            <img src={equipment.equipmentImg} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

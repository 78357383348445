import React, { useState } from "react";
import {
  TextareaAutosize,
  IconButton,
  Grid,
  Input,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Chip,
  DialogActions,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Icons
import { IoIosAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

//core component
import AssignSkills from "../CoursAssignSkills";
import AssignEquipment from "../CourseAssignEquip";
import { event } from "jquery";
export default function AboutTab(props) {
  const {
    states: {
      TabNext,
      courseUid,
      setCourseUid,
      coursePrice,
      setCoursePrice,
      courseDuration,
      setCourseDuration,
      durationSlot,
      courseTitle,
      setCourseTitle,
      grade,
      setGrade,
      sports,
      setSports,
      courseDesc,
      setCourseDesc,
      skills,
      locomotorP,
      setLocomotorP,
      bodyManagementP,
      setBodyManagementP,
      objectControlP,
      setObjectControlP,
      originalData,
      bmoriginalData,
      ocoriginalData,
      open,
      setOpen,
      file,
      setFile,
      filterAssignequiment,
      storeEquipment,
      assignequipment,
      openEquip,
      setOpenEquip,
      media,
      setMedia,
      mediatype,
      setMediaType,
      selectuidValid,
      durationValid,
      durationSlotValid,
      priceValid,
      titleValid,
      gradeValid,
      sportsValid,
      descValid,
      skillsValid,
      imageValid,
      equipmentValid,
      medaiValid,
    },
    handleChangeDuration,
    top100Films,
    Sports,
    storeSkill,
    BMstoreSkill,
    OCstoreSkill,
    reuploadClick,
    ImageUploader,
    MediaUploadClick,
    MediaUploader,
  } = props;
  const SkillsData = skills.slice(0, 1);
  const totalCount = skills.length - 1;
  const EquipmentData = filterAssignequiment.slice(0, 5);
  const totalEquipmentCount = filterAssignequiment.length - 5;
  const [inputValue, setInputValue] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid container className="ml-18">
            <Grid container md={12} sm={12} xs={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Typography
                    className={
                      selectuidValid ? "InputTitle-warn" : "InputTitle"
                    }
                  >
                    Course UID <span className="ImpColor">*</span>
                  </Typography>
                  <Input
                    value={courseUid}
                    onChange={(e) => setCourseUid(e.target.value)}
                    disableUnderline
                    className={
                      selectuidValid ? "InputStyle-warn" : "InputStyle"
                    }
                  />
                </Grid>
                <Grid item md={5}>
                  <Typography
                    className={
                      durationValid || durationSlotValid
                        ? "InputTitle-warn"
                        : "InputTitle"
                    }
                  >
                    Duration <span className="ImpColor"> * </span>
                  </Typography>
                  <Grid
                    container
                    className={
                      durationValid || durationSlotValid
                        ? "border-red  b-r10"
                        : "border-grey b-r10"
                    }
                  >
                    <Grid
                      item
                      md={5}
                      className={
                        durationValid
                          ? " border--right-red "
                          : "border-right-grey"
                      }
                    >
                      <Input
                        value={courseDuration}
                        type="number"
                        onChange={(e) => setCourseDuration(e.target.value)}
                        disableUnderline
                        className="InputStyle-no-br width100"
                      />
                    </Grid>
                    <Grid item md={7}>
                      <FormControl className="MainPageFormControl width100">
                        <Select
                          className="Selectdrop-no hgt-33"
                          value={durationSlot}
                          onChange={handleChangeDuration}
                          label="Select School"
                          disableUnderline
                          defaultValue={"Month"}
                        >
                          <MenuItem className="Selectmenustyle" value={"Month"}>
                            Month
                          </MenuItem>
                          <MenuItem className="Selectmenustyle" value={"Days"}>
                            Days
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    className={priceValid ? "InputTitle-warn" : "InputTitle"}
                  >
                    Price <span className="ImpColor"> * </span>
                  </Typography>
                  <Input
                    value={coursePrice}
                    onChange={(e) => setCoursePrice(e.target.value)}
                    disableUnderline
                    type="number"
                    className={priceValid ? "InputStyle-warn" : "InputStyle"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TextField
                id={titleValid ? "outlined-error" : "outlined-basic"}
                error={titleValid ? true : null}
                label={
                  <spa>
                    Course Title <span className="ImpColor"> * </span>
                  </spa>
                }
                variant="outlined"
                size="small"
                className={
                  titleValid
                    ? "width90 mt-24 textfield-outline-error"
                    : "width90 mt-24 textfield-outline"
                }
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                size="small"
                options={top100Films}
                className={
                  gradeValid
                    ? "width90  b-r10  autocomplete-course-warn"
                    : "width90 b-r10  autocomplete-course"
                }
                getOptionLabel={(option) => option}
                value={grade}
                onChange={(event, newValue, data) => {
                  setGrade(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={
                      <span>
                        Grade <span className="ImpColor"> * </span>
                      </span>
                    }
                    className="width100  b-r10"
                  />
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Typography
                className={sportsValid ? "InputTitle-warn" : "InputTitle"}
              >
                Sports <span className="ImpColor"> * </span>
              </Typography>
              <TagsInput
                value={sports}
                placeholder="Add a sports tag"
                className={
                  sportsValid ? "InputStyle-warn tags" : "InputStyle tags"
                }
                onChange={(event) => setSports(event)}
              />
            </Grid>

            <Grid item md={12} className="mt-8">
              <div>
                <Typography
                  className={descValid ? "InputTitle-warn" : "InputTitle"}
                >
                  About <span className="ImpColor"> * </span>
                </Typography>
                <div>
                  <TextareaAutosize
                    className={
                      descValid
                        ? "TextArea width90  border-red "
                        : " border-grey TextArea width90"
                    }
                    aria-label="minimum height"
                    value={courseDesc}
                    maxRows={10}
                    onChange={(e) => setCourseDesc(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography
              className={
                skillsValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10"
              }
            >
              Add Skills <span className="ImpColor"> * </span>
            </Typography>
            <div
              className={
                skillsValid
                  ? "Thumbnailform  border-red"
                  : "Thumbnailform border-grey"
              }
            >
              {open === false ? (
                <>
                  {SkillsData?.map((data) => {
                    let icon;

                    if (data.subSkill === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <>
                        <Chip
                          icon={icon}
                          label={data.subSkill}
                          className="Course-SkillChip"

                          // onDelete={
                          //   data.subSkillName === "React"
                          //     ? undefined
                          //     : handleDelete(data)
                          // }
                        />
                      </>
                    );
                  })}
                  {skills.length > 1 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}

              <AssignSkills
                storeSkill={storeSkill}
                BMstoreSkill={BMstoreSkill}
                OCstoreSkill={OCstoreSkill}
                locomotorP={locomotorP}
                setLocomotorP={setLocomotorP}
                bodyManagementP={bodyManagementP}
                setBodyManagementP={setBodyManagementP}
                objectControlP={objectControlP}
                setObjectControlP={setObjectControlP}
                states={{
                  originalData,
                  bmoriginalData,
                  ocoriginalData,
                  open,
                  setOpen,
                }}
              />
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography
              className={
                imageValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10 fs-16"
              }
            >
              Add Thumbnail <span className="ImpColor"> * </span>
            </Typography>
            <Grid item className="ChooseFileButtonItem">
              {file ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    imageValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                >
                  <IconButton
                    aria-label="close"
                    className="DialogCloseButton"
                    onClick={() => {
                      reuploadClick();
                      setFile(undefined);
                    }}
                  >
                    <IoMdClose className="DialogCloseIcon" />
                  </IconButton>
                  <img src={file} className="image-thumb" />{" "}
                </Grid>
              ) : (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="Image-button-file"
                    accept="image/*"
                    onChange={(e) => {
                      ImageUploader(e);
                    }}
                  />
                  <Grid
                    item
                    md={12}
                    className={
                      imageValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                  >
                    <label htmlFor="Image-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle className="cursor Icon-Color mt-24 fs-100" />
                      </Typography>
                    </label>
                    <Typography
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12 width80-LR"
                    >
                      Click here to upload image
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography
              className={
                equipmentValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10"
              }
            >
              Add Equipment <span className="ImpColor"> * </span>
            </Typography>
            <div
              className={
                equipmentValid
                  ? "Thumbnailform  border-red mb20px"
                  : "Thumbnailform border-grey mb20px"
              }
            >
              {openEquip === false ? (
                <>
                  {EquipmentData?.map((data) => {
                    let icon;

                    if (data.subSkillName === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <Chip
                        icon={icon}
                        label={data.name}
                        className="Course-SkillChip"
                      />
                    );
                  })}
                  {EquipmentData.length > 5 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalEquipmentCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}
              <AssignEquipment
                storeEquipment={storeEquipment}
                states={{ assignequipment, openEquip, setOpenEquip }}
              />
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography
              className={
                medaiValid
                  ? "RedColorTypography fs-16 mt-10"
                  : "TypographyTitle mt-10  fs-16"
              }
            >
              Add Hero Conent <span className="ImpColor"> * </span>
            </Typography>
            <Grid item className="ChooseFileButtonItem">
              {media ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    medaiValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                >
                  <IconButton
                    aria-label="close"
                    className="DialogCloseButton"
                    onClick={() => {
                      MediaUploadClick();
                      setMedia(undefined);
                      setMediaType(undefined);
                    }}
                  >
                    <IoMdClose className="DialogCloseIcon" />
                  </IconButton>
                  {mediatype === "video/mp4" ? (
                    <video
                      autoplay
                      muted
                      src={media}
                      type="video/mp4"
                      className="image-thumb"
                    />
                  ) : (
                    <img src={media} className="image-thumb" />
                  )}
                </Grid>
              ) : (
                <>
                  {mediatype === "video/mp4" ? (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="raised-button-file"
                        accept="image/*,video/*"
                        onChange={(e) => {
                          MediaUploader(e);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="raised-button-file"
                        accept="image/*,video/*"
                        onChange={(e) => {
                          MediaUploader(e);
                        }}
                      />
                    </>
                  )}
                  <Grid
                    item
                    md={12}
                    className={
                      medaiValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                  >
                    <label htmlFor="raised-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle className="cursor Icon-Color mt-24 fs-100" />
                      </Typography>
                    </label>
                    <Typography
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12 width80-LR"
                    >
                      Click here to upload image/video
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <DialogActions className="mt-24 mb-20 width100">
          <Button
            className="GreenButton700 next-btn"
            type="submit"
            onClick={TabNext}
          >
            Next
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  DialogContent,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Skill Detail
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog({ skills }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(skills, "Inside skills I am");

  return (
    <div>
      <Link onClick={handleClickOpen}> {skills.subSkillName} </Link>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle">
            {" "}
            <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
              <Grid
                container
                direction="column"
                item
                style={{ marginLeft: "25px" }}
              >
                <Typography className="SkillsInputTitle  GreyColorTypography">
                  Skill
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    defaultValue={skills.mainSkill}
                    disabled
                    // value={equipmentUid}
                    // onChange={(e) => setEquipmentUid(e.target.value)}
                  ></Input>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Sub Skill UID
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    defaultValue={skills.subSkillUid}
                    disabled
                    // value={equipmentNames}
                    // onChange={(e) => setEquipmentNames(e.target.value)}
                  ></Input>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Sub Skill Name
                  <span style={{ color: "red" }}> *</span>
                </Typography>{" "}
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    defaultValue={skills.subSkillName}
                    disabled
                    // value={equipmentUid}
                    // onChange={(e) => setEquipmentUid(e.target.value)}
                  ></Input>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Description
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <TextareaAutosize
                    className="SkillTextArea"
                    aria-label="minimum height"
                    defaultValue={skills.subSkillDesc}
                    disabled
                    // value={equipmentDesc}
                    // onChange={(e) => setEquipmentDesc(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
              <Typography className="GreyColorTypography  EquipMeadiaTypo  ">
                Media
              </Typography>
              <Grid item container className="AddThumbnilGridStyle">
                <img width="100%" height="100%" src={skills.skillImg} />
              </Grid>{" "}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="EquipDialogActionBottom">
          <Button
            className="GreenButton700 EquipActionBottom"
            style={{ marginRight: "1.3%" }}
            autoFocus
            onClick={handleClose}
          >
            Save
          </Button>
          <Button
            className="GreyButton700  EquipActionBottom"
            style={{ marginRight: "1%" }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import {
  Input,
  Button,
  Grid,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { BsDownload } from "react-icons/bs";
import { BiSort } from "react-icons/bi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
export default function ExtendTab({ data, schoolAgreement }) {
  const location = useLocation()?.state;
  let dataNew = location?.school?.schoolAgreement?.file?.split("-");
  if (dataNew) {
    dataNew.shift(0, 1);
    dataNew = dataNew.join("-");
  }

  return (
    <>
      <div className="ResponsiveTableAL">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="HeadAL ">
              <TableCell className="ColHeadALBLue ">
                Document Name
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>
              <TableCell className="ColHeadALBLue">
                Uploaded on
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>

              <TableCell className="ColHeadALBLue"> Action</TableCell>
            </TableRow>
          </TableHead>
          {location?.school?.agreement != undefined && (
            <TableBody>
              <TableRow>
                <TableCell className="Col700">{dataNew}</TableCell>
                <TableCell className="Col700">
                  {location?.school?.agreement?.uploadOn?.split("T")[0]}
                </TableCell>

                <TableCell className="Action">
                  <a href={location?.school?.agreement?.file}>
                    <IconButton className="ActionButtonSize">
                      <BsDownload color="#cddc39" />
                    </IconButton>
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        {location?.school?.agreement == undefined || null ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Agreement Found !
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
    </>
  );
}

import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";

export default function CompletetTab({ course }) {
  return (
    <>
      <Grid container style={{ minHeight: "70vh" }}>
        <div className="ResponsiveTable width100">
          <Table className="Tableroot">
            <TableHead>
              <TableCell className="Head"> UID </TableCell>
              <TableCell className="Head">Course name</TableCell>
              <TableCell className="Head">Start Date</TableCell>{" "}
              <TableCell className="Head">End Date</TableCell>
              <TableCell className="Head"> Total PP</TableCell>
              <TableCell className="Head"> Price</TableCell>
            </TableHead>

            <TableBody>
              {course &&
                course?.length > 0 &&
                course?.map((Data) => (
                  <TableRow>
                    <TableCell className="Col">{Data?.courseUid}</TableCell>
                    <TableCell className="Col">{Data?.courseTitle}</TableCell>
                    <TableCell className="Col ActionButtonSize">
                      {Data?.newStartDate?.split("T")[0]}
                    </TableCell>
                    <TableCell className="Col ActionButtonSize">
                      {Data?.newEndDate?.split("T")[0]}
                    </TableCell>
                    <TableCell className="Col">{Data?.assignedPP}</TableCell>
                    <TableCell className="Col"> {Data?.coursePrice}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {course?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Course Expired!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Button,
  Tab,
  Tabs,
  Box,
  Snackbar,
  Grid,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//Tabs Import
import AboutTab from "./CourseProfileTab's/AboutTab";
import OverviewTab from "./CourseProfileTab's/OverviewTab";
import CurriculumTab from "./CourseProfileTab's/CurriculumTab";
import Benefits from "./CourseProfileTab's/BenefitsTab";
import Lessonplan from "./CourseProfileTab's/LessonplanTab";
import Feedback from "./CourseProfileTab's/FeedbackTab";

import { Typography } from "antd";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ExtendedTables() {
  const location = useLocation();
  const history = useHistory();
  const Coursedata = location?.state?.post;

  // const { loadUsers } = location.data;
  const { userToken } = useLogin();

  function handleClick(event) {
    event.preventDefault();
  }
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  /////////////////////////////////////// Image Api////////////////
  //For CreateInput
  const [inputDisable, setInputDisable] = useState(true);
  const [courseUid, setCourseUid] = useState(null);
  const [courseDuration, setCourseDuration] = useState(null);
  const [durationSlot, setDurationSlot] = useState(null);
  const handleChangeDuration = (e) => {
    setDurationSlot(e.target.value);
  };
  const [coursePrice, setCoursePrice] = useState(null);
  const [courseTitle, setCourseTitle] = useState(null);
  const top100Films = [
    "Nursery",
    "LKG",
    "UKG",
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
  ];
  const [grade, setGrade] = useState([]);
  // const gradeData = grade?.map((data) => {
  //   return data?.title;
  // });
  console.log("Ceheck grade", grade);
  // console.log("Ceheck gradeDatagradeData", gradeData);

  const Sports = [{ title: "Nursery" }, { title: "LKG" }, { title: "UKG" }];
  const [sports, setSports] = useState([]);

  const [courseDesc, setCourseDesc] = useState(null);

  ///// Assign Skills /////////////////////
  const [open, setOpen] = React.useState(false);

  const [locomotorP, setLocomotorP] = useState([]);
  const [bodyManagementP, setBodyManagementP] = useState([]);
  const [objectControlP, setObjectControlP] = useState([]);
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data

  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x?.mainSkill,
        subSkill: x?.subSkillName,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  const [bmoriginalData, setBMOriginalData] = React.useState([]);

  function BMstoreSkill(e, x, i) {
    let storeArr = [...bmoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkill: x.subSkillName,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setBMOriginalData(storeArr);
  }
  const filterdata2 = bmoriginalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  const [ocoriginalData, setOCOriginalData] = React.useState([]);
  function OCstoreSkill(e, x, i) {
    let storeArr = [...ocoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkill: x.subSkillName,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOCOriginalData(storeArr);
  }
  const filterdata3 = ocoriginalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  //Skills Value send in Backend
  const skills = [...filterdata, ...filterdata2, ...filterdata3];
  console.log("check Skills Value ", skills);
  //Upload Thumbnail
  const [file, setFile] = useState();
  const [image, setImage] = React.useState("");

  function ImageUploader(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const reuploadClick = () => {
    setFile(false);
  };
  const imageUpload = async (ID) => {
    let courseID = Coursedata?._id;

    console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("courseId", courseID);
    formdata.append("file", image);

    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadCourseThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  ////////////////////////////////////////////////////// Assing Equpment ////////////////////////////////////

  const [openEquip, setOpenEquip] = React.useState(false);

  const [assignequipment, setAssignQuipment] = React.useState([]);
  function storeEquipment(e, x, i) {
    let storeArr = [...assignequipment];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        // _id: x.equipmentId,
        // name: x.equipmentName,
        _id: x._id,
        name: x.equipmentKitName,
        addedEquipment: x.addedEquipment,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setAssignQuipment(storeArr);
  }
  const filterAssignequiment = assignequipment?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  // Upload Hero Content
  const [media, setMedia] = useState();
  const [mediatype, setMediaType] = useState();
  const [mediaPost, setMediaPost] = useState();

  const MediaUploadClick = () => {
    setMedia(false);
  };

  const MediaUploadHeroImage = async (ID) => {
    let courseID = Coursedata?._id;

    const formdata = new FormData();
    formdata.set("courseId", courseID);
    formdata.append("file", mediaPost);

    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadHeroImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        setInputDisable(true);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const MediaUploadHeroVideo = async (ID) => {
    let courseID = Coursedata?._id;
    const formdata = new FormData();
    formdata.set("courseId", courseID);
    formdata.append("file", mediaPost);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadHeroVideo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        setInputDisable(true);
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };
  function MediaUploader(e) {
    setMedia(URL.createObjectURL(e.target.files[0]));
    setMediaType(e.target.files[0].type);
    setMediaPost(e.target.files[0]);
  }
  const [overviewState, setOverviewState] = useState(null);
  const [curriculumState, setCurriculumState] = useState(null);
  const [benefitesState, setBenefitesState] = useState(null);

  // console.log(
  //   "Overview convertedOverviewContent State ",
  //   convertedOverviewContent
  // );

  // useEffect(() => {
  //   let html = convertToHTML(overviewState.getCurrentContent());
  //   setConvertedOverviewContent(html);
  // }, [overviewState]);

  const [lessonplan, setLessonPlan] = useState(null);

  ////////////////////////////////////////////////Assing LessonPlna//////////////////
  const [assignlessonplandata, setAssignLessonPlanData] = React.useState([]);
  function storeLessonPlan(e, x, i) {
    let storeArr = [...assignlessonplandata];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        title: x.LPTitle,
        uid: x.LPUid,
        assignedPP: x.LPXp,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setAssignLessonPlanData(storeArr);
  }

  const filterLessonPlanData = assignlessonplandata?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  const assignedPPSum = filterLessonPlanData?.reduce((accumulater, elem) => {
    return (accumulater = accumulater + elem.assignedPP);
  }, 0);

  const [FeadbackQuestion, setFeadBackQuestion] = useState([
    {
      feedbackQuery: "",
      feedbackType: "",
    },
  ]);
  console.log("Coursedata Profile", Coursedata);
  useEffect(() => {
    if (Coursedata) {
      let locoSkill = [];
      let bodySkill = [];
      let objSkill = [];
      setCourseUid(Coursedata?.courseUid ?? " ");
      setCoursePrice(Coursedata?.coursePrice ?? " ");
      setCourseTitle(Coursedata?.courseTitle ?? "");
      setCourseDuration(Coursedata?.duration?.courseDuration ?? "");
      setDurationSlot(Coursedata?.duration?.durationSlot ?? "");
      setGrade(Coursedata?.grade ?? "");
      setCourseDesc(Coursedata?.courseDesc ?? "");
      setFile(Coursedata?.courseThumbnail ?? "");
      setMedia(Coursedata?.heroContent?.url ?? "");
      setSports(Coursedata?.sports ?? "");
      setOverviewState(Coursedata?.overview ?? "");
      setBenefitesState(Coursedata?.benefits ?? "");
      setCurriculumState(Coursedata?.curriculum ?? "");
      setAssignLessonPlanData(Coursedata?.lessonPlans ?? "");
      // setOriginalData(Coursedata?.skills ?? "");
      setAssignQuipment(Coursedata?.equipmentKit ?? "");
      setFeadBackQuestion(Coursedata?.feedback ?? "");
      if (Coursedata.skills != undefined) {
        Coursedata.skills.map((x, i) => {
          if (x.mainSkill == "Locomotor") {
            locoSkill.push(x);
          } else if (x.mainSkill == "Body Management") {
            bodySkill.push(x);
          } else if (x.mainSkill == "Object Control") {
            objSkill.push(x);
          }
        });
      }

      setOriginalData(locoSkill);
      setBMOriginalData(bodySkill);
      setOCOriginalData(objSkill);
    }
  }, [Coursedata]);

  // For Edit
  console.log("cc", Coursedata);

  const EditSubmitClick = async (e) => {
    if (file) {
      imageUpload(file);
    }
    setInputDisable(true);
    MediaUploadHeroImage();
    MediaUploadHeroVideo();
    if (
      filterLessonPlanData.length === 0 ||
      filterAssignequiment.length === 0 ||
      skills.length === 0
    ) {
      return;
    }
    let courseID = Coursedata?._id;

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/editCourse/${courseID}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },

          body: JSON.stringify({
            courseUid,
            courseTitle,
            duration: { durationSlot, courseDuration },
            grade: grade,
            coursePrice: +coursePrice,
            courseDesc: courseDesc,
            overview: overviewState,
            curriculum: curriculumState,
            benefits: benefitesState,
            sports: sports,
            skills: skills,
            equipmentKit: filterAssignequiment,
            lessonPlans: filterLessonPlanData,
            assignedPP: assignedPPSum,
            feedback: FeadbackQuestion,
          }),
        }
      );
      let res = await responce.json();
      console.log("Response after  >> ", res);
      if (responce.ok) {
        // loadUsers();
        setInputDisable(true);
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        console.log("errorrrrrrrrrr", res);
        setInputDisable(true);
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div className="bodyStyle">
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb" className="mt-16">
            <Button
              onClick={() => history.goBack()}
              disableRipple
              className="Breadcrumb"
            >
              Courses
            </Button>
            <div className="Breadcrumb-child"> {Coursedata?.courseTitle}</div>
          </Breadcrumbs>
        </div>
        <Grid
          container
          className="mt-16"
          justify="space-between"
          direction="row"
        >
          <Typography className="BlueColorTypography fs-18">
            {Coursedata?.courseTitle}
          </Typography>
        </Grid>
        <Box className="mt-16">
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="TabsCustom-Style   "
            centered
          >
            <Tab
              className="ActivityTabChanges"
              label="About Course"
              {...a11yProps(0)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Overview"
              {...a11yProps(1)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Curriculum"
              {...a11yProps(2)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Benefits"
              {...a11yProps(3)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Lessonplan"
              {...a11yProps(4)}
            />
            {/* <Tab
              className="ActivityTabChanges"
              label="Feedback"
              {...a11yProps(5)}
            /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className="width100">
          <AboutTab
            states={{
              inputDisable,
              setInputDisable,
              courseUid,
              setCourseUid,
              coursePrice,
              setCoursePrice,
              courseDuration,
              setCourseDuration,
              durationSlot,
              courseTitle,
              setCourseTitle,
              grade,
              setGrade,
              sports,
              setSports,
              courseDesc,
              setCourseDesc,
              skills,
              setOCOriginalData,
              locomotorP,
              setLocomotorP,
              bodyManagementP,
              setBodyManagementP,
              objectControlP,
              setObjectControlP,
              originalData,
              bmoriginalData,
              ocoriginalData,
              open,
              setOpen,
              file,
              setFile,
              filterAssignequiment,
              storeEquipment,
              assignequipment,
              openEquip,
              setOpenEquip,
              media,
              setMedia,
              mediatype,
              setMediaType,
              Coursedata,
            }}
            handleChangeDuration={handleChangeDuration}
            top100Films={top100Films}
            Sports={Sports}
            storeSkill={storeSkill}
            BMstoreSkill={BMstoreSkill}
            reuploadClick={reuploadClick}
            ImageUploader={ImageUploader}
            MediaUploadClick={MediaUploadClick}
            MediaUploader={MediaUploader}
            OCstoreSkill={OCstoreSkill}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <OverviewTab
            states={{
              inputDisable,
              setInputDisable,
              overviewState,
              setOverviewState,
              Coursedata,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CurriculumTab
            states={{
              inputDisable,
              setInputDisable,
              curriculumState,
              setCurriculumState,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Benefits
            states={{
              inputDisable,
              setInputDisable,
              benefitesState,
              setBenefitesState,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Lessonplan
            states={{
              assignlessonplandata,
              inputDisable,
              setInputDisable,
              lessonplan,
              setLessonPlan,
              filterLessonPlanData,
            }}
            storeLessonPlan={storeLessonPlan}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={5}>
          <Feedback
            states={{
              FeadbackQuestion,
              setFeadBackQuestion,
              inputDisable,
              setInputDisable,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel> */}
      </div>
    </>
  );
}

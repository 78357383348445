import React, { useState } from "react";

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  FormControl,
  MenuItem,
  IconButton,
  Input,
  Select,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
import { AiOutlineMinus as RemoveIcon } from "react-icons/ai";
export default function FeedbackTab(props) {
  const {
    states: { TabPre4, FeadbackQuestion, setFeadBackQuestion },
    submitClick,
  } = props;
  const [show, setShowData] = useState(false);
  const [options, setOptions] = useState([
    { id: uuidv4(), option: "", optionNo: "" },
  ]);
  const handleAddFields = (e) => {
    setFeadBackQuestion([
      ...FeadbackQuestion,
      {
        feedbackQuery: "",
        feedbackType: "",
      },
    ]);
  };
  const handleRemoveFields = (e, i) => {
    const inputList = [...FeadbackQuestion];
    inputList.splice(i, 1);
    setFeadBackQuestion(inputList);
  };

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const inputList = [...FeadbackQuestion];
    inputList[i][name] = value;

    if (inputList[i][name] === 20) {
      setShowData(true);
    } else {
      setShowData(false);
    }
    setFeadBackQuestion(inputList);
  };
  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18">
            Add feedback
          </Typography>
          <Grid container>
            {options.slice(0, 10).map((option, index) => (
              <Grid
                container
                key={option.id}
                style={{
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Grid container style={{ gap: "30px" }}>
                  {FeadbackQuestion?.map((x, i) => {
                    console.log("TESTING ==>", i, FeadbackQuestion[i]);
                    return (
                      <>
                        <Grid container key={i}>
                          <Grid item xs={3}>
                            <Typography className="BlackColorTypography">
                              Type
                            </Typography>
                            <Grid item xs={9}>
                              <FormControl className="width100">
                                <Select
                                  className="RubicInputs"
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  name="feedbackType"
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <MenuItem value={1}>Star</MenuItem>
                                  <MenuItem value={2}>Theoretical</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography className="BlackColorTypography">
                              QuestionName
                            </Typography>
                            <Grid item md={12} sm={12} xs={12} lg={12}>
                              <Input
                                className="RubicInputs"
                                id="QuestionName"
                                name="feedbackQuery"
                                onChange={(e) => handleInputChange(e, i)}
                              ></Input>
                            </Grid>
                          </Grid>

                          <Grid item xs={2}>
                            <Grid
                              container
                              style={{
                                alignItems: "center",
                                alignSelf: "center",
                                justifyContent: "center",
                                paddingTop: "20px",
                              }}
                            >
                              <IconButton
                                style={{ marginRight: "30px" }}
                                className="GreyButton700"
                                disabled={option.length === 1}
                                onClick={(e) => handleRemoveFields(e, i)}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <IconButton
                                className="GreenButton700"
                                onClick={(e) => handleAddFields(e)}
                              >
                                <AddIcon />
                              </IconButton>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <DialogActions className="mt-24  width100">
            <Button onClick={TabPre4} className=" GreenButton-border next-btn">
              Previous
            </Button>
            <Button className="GreenButton700 next-btn" onClick={submitClick}>
              Submit
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  Grid,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component
import { FETCH_URL } from "fetchIp";
// import DeleteDialog from "../../../TrainerActionDialog/TrainerDeleteDialog";
// import SwtichDialog from "../../../TrainerActionDialog/TrainerSwitch";
export default function TAExpiredLessonPlanTab({ lessonplan }) {
  //   const { TrainerId } = props;
  const [lessonPlanData, setLessonPlanData] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);

  const GetExpiredLessonPlan = async (lessonplan) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            lessonPlanIds: lessonplan,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlanData(res.msg);
        // //.log("Get AssignedOnGoingLessonPlan Responce   ", res.msg);
      } else {
        //.log("Check GetAssignedCourseOngoing Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  useEffect(() => {
    GetExpiredLessonPlan(lessonplan);
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead"> Subject </TableCell>
                <TableCell className="ColHead"> Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {lessonPlanData &&
                lessonPlanData?.length > 0 &&
                lessonPlanData?.map((data) => (
                  <TableRow>
                    <TableCell className="Col">
                      {" "}
                      <Link>{data.LPUid}</Link>
                    </TableCell>
                    <TableCell className="Col">{data.LPTitle}</TableCell>
                    <TableCell className="Col ActionButtonSize">
                      <Typography className="GreyColorTypography500 overflow-style">
                        {data.LPDesc}
                      </Typography>
                    </TableCell>

                    {/* <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <SwtichDialog accessCheck={access} />
                    </IconButton>
                    <DeleteDialog />
                  </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>{" "}
          {lessonPlanData?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No LessonPlan Expired!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {/* <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div> */}
      </div>
    </>
  );
}

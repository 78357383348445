import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component
import LearnerProfile from "../GLearnerProfile/GLearnerProfile";
import { useLogin } from "Context/ExportContext";

// React Icons

export default function Radio(props) {
  const { learnerDivisonData, divisionName } = props;

  return (
    <>
      {/* <div className="DivisionStyleGrid"> */}
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Roll No </TableCell>
              <TableCell className="ColHead"> Learner’s Name</TableCell>
              <TableCell className="ColHead"> Password </TableCell>

              <TableCell className="ColHead"> Division</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerDivisonData &&
              learnerDivisonData.length > 0 &&
              learnerDivisonData.map((learner) => (
                <TableRow>
                  <TableCell className="Col">
                    {learner.learnerDetail.regNo}
                  </TableCell>
                  <TableCell className="Col">
                    {learner.academicDetails.rollNumber}
                  </TableCell>
                  <TableCell className="Col">
                    <LearnerProfile learner={learner} />
                  </TableCell>
                  <TableCell className="Col">{learner.password}</TableCell>

                  <TableCell className="Col">{divisionName}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {/* <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div> */}
      {/* </div> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
//core component
import ManImage from "assets/img/Man.png";
import SkillsProfileReport from "./SkillsProfileReport2";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="ActivityDialogTitleReport" {...other}>
      <Grid container>
        <Grid container item md={12} sm={12} xs={12} lg={12}>
          <Grid container item md={1} sm={1} xs={1} lg={1} justify="flex-end">
            <img className="LPReportImg" src={ManImage} />
            <Typography className="BlueColorTypography"> 11405079 </Typography>
          </Grid>
          <Grid
            container
            item
            md={3}
            sm={3}
            xs={3}
            lg={3}
            className="UserGridStyle"
            direction="column"
          >
            <Typography className="BlueColorTypography UserNameTypo">
              Abhisekh Sharma
            </Typography>
            <Typography className="GreyColorTypography ">A01</Typography>
            <Typography className="GreyColorTypography ">Grade 1</Typography>
            <Typography className="GreyColorTypography ">
              Zoomer Public School
            </Typography>
            <Typography className="GreyColorTypography ">Pune</Typography>
          </Grid>
          <Grid
            container
            item
            md={4}
            sm={4}
            xs={4}
            lg={4}
            direction="column"
            className="LTPCenterGridStyle"
          >
            <Typography className="BlueColorTypography RCLearnerNameTypo">
              ActivityDialogTitleReport
            </Typography>
            <Typography className="GreenColorTypography RCLearnerNameTypo">
              Smiley Bounce Standing
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={3}
            sm={3}
            xs={3}
            lg={3}
            direction="column"
            className="DownLGridStyle"
          >
            <Typography className="GreyColorTypography AttempTypo">
              Attempted on : 19/10/21
            </Typography>
            <Typography className="GreenColorTypography DownLTypo">
              Download Report Card
            </Typography>
            <IconButton className="ActionButtonSize">
              <BsDownload className="ActivityReportCIcon" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const classes = useStyles();

  const [scroll, setScroll] = React.useState("paper");

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);
  return (
    <div>
      <Button className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        Learn To Play
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapReportCardDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent style={{ backgroundColor: "#E3E3E3" }}>
          <div className="ResponsiveTableAL">
            <Table className="Tableroot">
              <TableHead>
                <TableRow className="HeadAL">
                  <TableCell className="ColHeadALBLue">UID </TableCell>
                  <TableCell className="ColHeadALBLue">
                    Name of activity Variant
                  </TableCell>

                  <TableCell className="ColHeadALBLue">Mastery </TableCell>
                  <TableCell className="ColHeadALBLue">
                    {" "}
                    Marks Obtained{" "}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {posts.slice(page, rowsPerPage).map((post) => (
                  <TableRow>
                    <TableCell className="Col700WAB">SC1</TableCell>
                    <TableCell className="Col700WAB">
                      <Link>
                        <SkillsProfileReport />
                      </Link>
                    </TableCell>
                    <TableCell className="Col700WAB"> Gets It </TableCell>{" "}
                    <TableCell className="Col700WAB"> 3/3 </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Grid container item className="ReportCloseButton">
            <Button className="GreyButton700"> Close</Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@material-ui/core";

// core components
import EquipmentTab from "./GEquipmentTab's/GEquipmentTab";
import KitTab from "./GEquipmentTab's/GKitTab";
import AddKit from "./GAddEquipments/GAddKit";
import AddEquipment from "./GAddEquipments/GAddKit";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { useLocation } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EquipmnetTabs(props) {
  const [access, setAccess] = useState(1);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  ///////////////////////////////////////////////////////////////////
  const location = useLocation();
  // console.log(" Check Grade  Data", location.state.data);
  const gradeId = location.state.data._id;
  ///////////////////////////////////////////////////////////////////
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  // API Calling
  const { userToken } = useLogin();
  const [value, setValue] = React.useState(0);
  const [equipmentData, setEquipmentData] = React.useState(null);
  const [equipmentKit, setEquipmentKit] = React.useState(null);

  // Get API
  const getAllEquipment = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getPaginatedEquipment?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setEquipmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // KITAPI
  const getAllEquipmentKIT = async (gradeId) => {
    console.log("ok");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setEquipmentKit(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllEquipmentKIT(gradeId);
    getAllEquipment(gradeId);
  }, []);
  console.log(equipmentKit, "equipmentKit>>>>>>>>>>>>>>>>>>>>>>>");
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Equipment"
            {...a11yProps(0)}
          />
          <Tab className="ActivityTabChanges" label="Kit" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {/* <Grid container>
        <Grid container item className="   DivisionStyleGridSkill  ">
          <Grid container item className="DiviStyle ">
            <Typography></Typography>

            {value == 0 ? (
              <AddEquipment getAllEquipment={getAllEquipment} />
            ) : (
              <AddKit
                getAllEquipmentKIT={getAllEquipmentKIT}
                states={{ equipmentKit }}
                setEquipmentKit={setEquipmentKit}
              />
            )}
          </Grid>
        </Grid>
      </Grid> */}
      <TabPanel value={value} index={0}>
        <EquipmentTab
          equipmentData={equipmentData}
          gradeId={gradeId}
          getAllEquipment={getAllEquipment}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KitTab
          equipmentKit={equipmentKit}
          gradeId={gradeId}
          getAllEquipmentKIT={getAllEquipmentKIT}
        />
      </TabPanel>
    </>
  );
}

import * as React from "react";

import {
  Grid,
  Input,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp.js";

export default function ExtendTab(props) {
  const { userToken } = useLogin();
  const [locmotor, setSchool] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [apiSchoolNames, setApiSchoolNames] = React.useState(null);
  const [uniqueSchools, setUniqueSchools] = React.useState(null);
  const [filteredLocations, setFilteredLocations] = React.useState(null);
  const [filteredGrades, setFilteredGrades] = React.useState(null);
  const [divisionNames, setDivisionNames] = React.useState(null);
  const {
    learner,
    states: {
      firstTermStartDate,
      secondTermEndDate,
      firstTermEndDate,
      secondTermStartDate,
      setGrade,
      setFirstTermStartDate,
      setsecondTermStartDate,
      setfirstTermEndDate,
      setsecondTermEndDate,
      thirdTermStartDate,
      setThirdTermStartDate,
      thirdTermEndDate,
      setThirdTermEndDate,
      setRollNumber,
      setApiGrades,
      apiGrades,
      division,
      setDivision,
    },
    Tabnext3,
    submitClick,
  } = props;
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  // const fetchDivisons = async (gradeName) => {
  //   try {
  //     // //.log("Fetching Schools from AcademicTab.js ==>");
  //     const response = await fetch(
  //       `${FETCH_URL}/api/school/grade/getAllDivisions?gradeName=${gradeName}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );

  //     const res = await response.json();

  //     if (response.ok) {
  //       //.log("divison Response ====>", res);
  //       setDivisionNames(res.msg);
  //     } else {
  //       //.log("Error from divison =====>", res);
  //     }
  //   } catch (error) {
  //     //.log("Error from fetch divison", error);
  //   }
  // };

  ///////////////////////////get divison api/////////////////////////

  //.log(division, "divisionNames>>>>>>");

  return (
    <div>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          Grid
          item
          md={6}
          sm={6}
          xs={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <Grid container direction="column" item style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography GreyColorTypography">
              School Name<span className="ImpColor"> *</span>
            </Typography>
            <Grid container item md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={learner.academicDetails.schoolName}
                disabled
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location <span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={learner.academicDetails.location}
                disabled
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Grade <span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={learner.academicDetails.grade}
                disabled
              ></Input>
            </Grid>
            {/* <Grid item container md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput"
                style={{ backgroundColor: "white" }}
              >
                <Select
                  className=" SkillsSelectStyle "
                  defaultValue={learner.academicDetails.grade}
                  onChange={(e) => {
                    setGrade(e.target.value);
                  }}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  {apiGrades && apiGrades.length > 0 ? (
                    apiGrades.map((mapGrade, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        value={mapGrade.grade}
                      >
                        {mapGrade.grade}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      No Grades available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid> */}
            <Typography className="InputTitleTypography GreyColorTypography">
              Divisions
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={division}
                disabled
              ></Input>
            </Grid>
            {/* <Grid item container md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput colorwhite"
              >
                <Select
                  className=" SkillsSelectStyle "
                  value={division}
                  onChange={(e) => {
                    // changeValue(e.target.value);
                    setDivision(e.target.value);
                  }}
                  onKeyDown={handleEnter}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  {divisionNames && divisionNames.length > 0 ? (
                    divisionNames.map((mapDivison, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        value={mapDivison._id}
                      >
                        {mapDivison.division}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      No Divisons available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid> */}
            <Typography className="InputTitleTypography GreyColorTypography">
              Roll Number <span className="ImpColor"> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={learner.academicDetails.rollNumber}
                onChange={(e) => setRollNumber(e.target.value)}
              ></Input>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <Typography className="InputTitleTypography GreyColorTypography">
            1st Term Start Date<span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={
                    firstTermStartDate
                      ? firstTermStartDate
                      : learner.academicDetails.firstTermStartDate
                  }
                  onChange={(e) => {
                    setFirstTermStartDate(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            1st Term End Date <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={
                    firstTermEndDate
                      ? firstTermEndDate
                      : learner.academicDetails.firstTermEndDate
                  }
                  onChange={(e) => {
                    setfirstTermEndDate(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            2nd Term Start Date <span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={
                    secondTermStartDate
                      ? secondTermStartDate
                      : learner.academicDetails.secondTermStartDate
                  }
                  onChange={(e) => {
                    setsecondTermStartDate(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            2nd Term End Date*<span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={
                    secondTermEndDate
                      ? secondTermEndDate
                      : learner.academicDetails.secondTermEndDate
                  }
                  onChange={(newValue) => {
                    setsecondTermEndDate(newValue);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            3nd Term Start Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={
                    thirdTermStartDate
                      ? thirdTermStartDate
                      : learner.academicDetails.thirdTermStartDate
                  }
                  minDate={new Date("2017-01-01")}
                  onChange={(e) => {
                    setThirdTermStartDate(e);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            3nd Term End Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={
                    thirdTermEndDate
                      ? thirdTermEndDate
                      : learner.academicDetails.thirdTermEndDate
                  }
                  onChange={(newValue) => {
                    setThirdTermEndDate(newValue);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>

      <DialogActions
        style={{
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {/* <Button
          className="GreenButton700 ContactNextBottom"
          autoFocus
          onClick={Tabnext3}
        >
          Next
        </Button> */}
        <Button className="DocumnetSubmit" onClick={submitClick}>
          Submit
        </Button>
      </DialogActions>
    </div>
  );
}

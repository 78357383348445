import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { BiBook } from "react-icons/bi";
import { useEffect } from "react";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//core Module
import SelectLessonPlan from "./LLessonPlanTab/LPSelectLessonPlan";
import RenameTab from "./LLessonPlanTab/LPRenameTab";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LPDialog({
  learnerId,
  getPaginatedLearner,
  divisionDataId,
}) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const { userToken, schoolDetail } = useLogin();
  //////////////////////////////////////////////////////////
  const [lessonPlanUid, setLessonPlanUid] = React.useState("");
  const [lessonPlanTitle, setLessonPlanTitle] = React.useState("");
  const [lessonPlanStartDate, setLessonPlanStartDate] = React.useState(null);
  const [lessonPlanEndate, setLessonPlanEndate] = React.useState(null);
  const [originalData, setOriginalData] = React.useState([]);
  const [lessonplan, setLessonPlan] = React.useState(null);
  /////////////////////////////////////////////////////////////////////
  const [selectLessonPlanText, setSelectLessonPlanText] = React.useState("");
  const [lessonPlanUidText, setLessonPlanUidText] = React.useState("");
  const [lessonPlanTitleText, setLessonPlanTitleText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [lessonPlanId, setLessonPlanId] = React.useState("");

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setLessonPlanUid("");
    setLessonPlanTitle("");
    setLessonPlanStartDate(null);
    setLessonPlanEndate(null);
    setSelectLessonPlanText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setLessonPlanId("");
    setValue(0);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    // if (filterdata.length === 0) {
    //   setSelectLessonPlanText("please select a lesson plan!");
    //   return;
    // }
    if (!lessonPlanId) {
      setSelectLessonPlanText("please select a lesson plan!");
      return;
    }
    setSelectLessonPlanText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setValue(newValue);
  };
  function TabNext() {
    // if (filterdata.length === 0) {
    //   setSelectLessonPlanText("please select a lesson plan!");
    //   return;
    // }
    if (!lessonPlanId) {
      setSelectLessonPlanText("please select a lesson plan!");
      return;
    }
    setSelectLessonPlanText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setValue(1);
  }
  ////////////////////////////////////////////////////////////

  const getAllLessonPlan = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Lesson Plan==>", res);
        setLessonPlan(res.msg);
      } else {
        //.log("Error while fetching LessonPlan", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllLessonPlan();
  }, []);

  const submitClick = async (e) => {
    e.preventDefault();
    if (
      lessonPlanUid === null ||
      lessonPlanUid.length === 0 ||
      (lessonPlanUid === undefined && lessonPlanTitle === null) ||
      lessonPlanTitle === undefined ||
      lessonPlanTitle.length === 0
    ) {
      if (lessonPlanUid.length === 0) {
        setLessonPlanUidText("please enter lesson plan uid !");
      }

      if (lessonPlanTitle.length === 0) {
        setLessonPlanTitleText("please enter lesson plan title !");
      }
      return;
    }
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/assignLessonPlanToLearnerFromLearner`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            learnerId,
            lessonPlanId: lessonPlanId,
            LPUid: lessonPlanUid,
            LPTitle: lessonPlanTitle,
            // LPStartDate: lessonPlanStartDate,
            // LPEndDate: lessonPlanEndate,
          }),
        }
      );

      // //.log("Assign Id", filtered);
      const res = await response.json();
      if (response.ok) {
        getPaginatedLearner(divisionDataId);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="LessonPlan">
        <IconButton className="ActionButtonSize">
          <BiBook className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Select Lesson Plan"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectLessonPlan
              states={{
                lessonplan,
                selectLessonPlanText,
                lessonPlanId,
                setLessonPlanId,
              }}
              // storeLessonPlan={storeLessonPlan}
              // originalData={originalData}
              // filterdata={filterdata}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenameTab
              states={{
                lessonPlanUid,
                setLessonPlanUid,
                lessonPlanStartDate,
                setLessonPlanStartDate,
                lessonPlanEndate,
                setLessonPlanEndate,
                lessonPlanTitle,
                setLessonPlanTitle,
                lessonPlanUidText,
                lessonPlanTitleText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

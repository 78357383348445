import React, { useState } from "react";
import {
  Input,
  Button,
  Grid,
  Typography,
  DialogActions,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function ExtendTab(props) {
  const { Tabnext3 } = props;
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  return (
    <Grid container spacing={4} justify="center" className="DailogLTRBStyle">
      {/* <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
        <Grid direction="column" item style={{ marginLeft: "3%" }}>
          <Typography className="BlueColorTypography AddDocumentTypography">
            Document Name{" "}
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input onKeyDown={handleEnter} className="Input1"></Input>
          </Grid>
          <Typography className="BlueColorTypography AddDocumentTypography">
            Document Name{" "}
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input onKeyDown={handleEnter} className="Input1">
              {" "}
            </Input>
          </Grid>
          <Typography className="BlueColorTypography AddDocumentTypography">
            Document Name{" "}
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input onKeyDown={handleEnter} className="Input1">
              {" "}
            </Input>
          </Grid>
          <Typography className="BlueColorTypography AddDocumentTypography">
            Document Name{" "}
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input onKeyDown={handleEnter} className="Input1">
              {" "}
            </Input>
          </Grid>
          <Typography className="BlueColorTypography AddDocumentTypography">
            Document Name{" "}
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input className="Input1"> </Input>
          </Grid>
          <Typography className="BlueColorTypography AddDocumentTypography">
            Document Name{" "}
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input className="Input1"> </Input>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid
        item
        md={6}
        sm={6}
        xs={6}
        lg={6}
        direction="column"
        style={{ marginTop: "1px" }}
      >
        <DropzoneArea
          filesLimit={3}
          maxFileSize={2097152}
          acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
          // onChange={(e) => handleImageChange(e)}
          useChipsForPreview={true}
          dropzoneText="Upload Docs"
        />

        {/* <Button
          type="submit"
      
          onClick={submitClick}
        >
          Submit
        </Button> */}
        <Typography className="ALAddSkilltypography">
          Support only .doc / .pdf format(max file size 2 MB)(max file size 2
          MB)
        </Typography>
        <DialogActions className="">
          <Button
            className="GreenButton700 SumbitButton"
            onKeyDown={handleEnter}
            onClick={Tabnext3}
          >
            Next
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
}

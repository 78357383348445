import * as React from "react";
import { useHistory, Redirect } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  CssBaseline,
  FormControlLabel,
  TextField,
  Container,
  Avatar,
  Link,
  Paper,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import Carousel from "react-material-ui-carousel";
import Checkbox from "@material-ui/core/Checkbox";
//React Icons
// core  component
import { useLogin } from "../../Context/ExportContext";
import { FETCH_URL } from "../../fetchIp";
//Images
import ZoomerSign from "../../assets/img/ZoomerSignin.png";
import ImgSlideSign from "../../assets/img/LoginIamges/ImgSlideSign.png";
import ImgSlideSign2 from "../../assets/img/ImgSignSlide2.png";
import ImgSlideSign3 from "../../assets/img/ImgSignSlide3.png";
import Slider from "./SliderSign";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignIn() {
  const history = useHistory();
  const { authHelper, userToken } = useLogin();
  const { signIn } = authHelper;
  const [snackopen, setSnackOpen] = React.useState(false);
  const [snackmsg, setSnackMsg] = React.useState("");
  const [snackErrMsg, setSnackErrMsg] = React.useState();
  const [snackerropen, setSnackerropen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const login = async () => {
    //.log("I worked");
    if (!email || !password) {
      // return alert("Please enter an email/password");
      setSnackerropen(true);
      setSnackErrMsg("Please enter an email/password");
    }
    try {
      const response = await fetch(`${FETCH_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        //.log("My response ==>", res);
        // alert("Successfully Signed In");
        setSnackOpen(true);
        setSnackMsg("Successfully Signed In");
        console.log("role and token", res.role, res.token);
        localStorage.setItem("loginToken", res.token);
        localStorage.setItem("userrole", res.role);
        setTimeout(signIn, 1000);
        // signIn();
        // history.push("/admin/dashboard");
      } else {
        //.log("Error while signing in", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (e) {
      //.log("Catch block error =>", e);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  // if (userToken) {
  //   return  <Redirect to="/admin/dashboard" />;
  // }

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div style={{ height: "100vh", backgroundColor: "#cddc39" }}>
        <Grid container style={{ backgroundColor: "#cddc39" }}>
          <Grid item md={12} lg={12}>
            <Box
              sx={{
                flexDirection: "column",
                marginTop: 8,
                alignItems: "center",
                display: "flex",
                marginTop: "130px",
              }}
            >
              <img src={ZoomerSign} />

              <Grid
                item
                md={12}
                style={{
                  backgroundColor: "#FFFF",
                  borderRadius: "8px",
                  boxShadow: "0px 16px 40px 0px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Grid container item style={{ justifyContent: "center" }}>
                  <Box
                    sx={{
                      flexDirection: "column",
                      marginTop: 8,
                      alignItems: "left",
                      display: "flex",
                    }}
                  ></Box>
                </Grid>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "86%",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    align="left"
                    className="GreyColorTypography"
                    style={{
                      borderBottom: "3px solid #cddc39",
                      width: "25%",
                      marginBottom: "9px",
                    }}
                  >
                    Login
                  </Typography>
                  <Typography align="left" className="GreyColorTypography">
                    {" "}
                    Sign in using your login credentials
                  </Typography>
                  <Typography
                    style={{
                      color: "rgba(119, 119, 119, 1)",
                      marginTop: "18px",
                      marginBottom: "6px",
                    }}
                  >
                    UID/Phone number/Email
                  </Typography>
                  <TextField
                    style={{ border: "1px solid #474747" }}
                    required
                    fullWidth
                    id="emailA"
                    onChange={(e) => setEmail(e.target.value)}
                    // label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <Typography
                    style={{
                      color: "rgba(119, 119, 119, 1)",
                      marginTop: "10px",
                      marginBottom: "6px",
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    className="SignTextField"
                    required
                    fullWidth
                    style={{ border: "1px solid #474747" }}
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    // label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox value="remember" style={{ color: "#cddc39" }} />
                    }
                    label="Remember me"
                  />
                  <Button
                    className="GreenButton"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ marginTop: "30px" }}
                  >
                    LOG IN
                  </Button>
                  <Grid container style={{ margin: "20px 0px" }}>
                    <Grid item xs>
                      {/* <Link href="#" variant="body2">
                   Forgot password?
                 </Link> */}
                    </Grid>
                    <Grid item>
                      {/* <Link href="#" variant="body2">
                   {"Don't have an account? Sign Up"}
                 </Link> */}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
          {/* <Grid md={6}>
       <div>
         <Slider />
       </div>
     </Grid> */}
        </Grid>
      </div>
    </>
  );
}

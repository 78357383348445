import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import $ from "jquery";
// GrapesJS
import grapesjs from "grapesjs";
import gjsBlockBasic from "grapesjs-blocks-basic";
import gjsPluginCkeditor from "grapesjs-plugin-ckeditor";
import gjsPresentWebpage from "grapesjs-preset-webpage";
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";
import "grapesjs/dist/css/grapes.min.css";
import "./css/grapes.css";
import "./css/main.scss";

import {
  Radio,
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  TextField,
  Button,
  Grid,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Input,
  Backdrop,
} from "@material-ui/core";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import StyleIcon from "@material-ui/icons/Style";
import LayersIcon from "@material-ui/icons/Layers";
import WidgetsIcon from "@material-ui/icons/Widgets";
import SettingsIcon from "@material-ui/icons/Settings";
// For icons
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
import { AiOutlineMinus as RemoveIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { IoIosAddCircle } from "react-icons/io";
import { MdClose } from "react-icons/md";
// MUI Dialog for MCQ

// Internal Component Import
import styleManager from "./config/styleManager";
import ClosePage from "../ActionButton/CloseDialog";
import {
  deviceManager,
  panels,
  layerManager,
  traitManager,
} from "./config/utilities";
import { LOCAL_URL } from "fetchIp";
import { FETCH_URL } from "fetchIp";
import Logo from "./img/logo.png";
import { useLogin } from "Context/ExportContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

const ThemeEditor = () => {
  const blockManager2 = [
    {
      id: "mcqAdd",
      label: "MCQ",
      category: "Questions",
      attributes: {
        class: "fa fa-question",
      },
      content: `<div id="mcq__block" style="display:flex;flex-direction:column;">
    <div class ="mcq__question" style="color:red;">Enter the question</div>
    <div class="mcq__checkbox" style="display:flex;flex-direction:column;"></div>   
    </div>
    <br />
    </div>`,
    },
    {
      id: "fillUps",
      label: "Fill Ups",
      category: "Questions",
      attributes: {
        class: "fa fa-heading",
      },
      content: `<div id="fillup_block" style="display:flex;flex-direction:column;">
    <div class ="fillup__question" style="color:red;">Enter the fill up</div>
    <div class="fillup_checkbox"></div>   
    </div>
    <br />
    </div>`,
    },
    {
      id: "media",
      label: "Local Video",
      category: "Basic",
      attributes: {
        class: "fa fa-file-video-o",
      },
      content: ` 
      <div id="media_block" style="display:flex;flex-direction:column;">
      <div id="video_show" style="width:50%;"> </div>
     </div>`,
    },
    {
      id: "mediaImage",
      label: "Local Image",
      category: "Basic",
      // media: svgImage,
      attributes: {
        class: "fa fa-picture-o",
      },
      content: ` 
      <div id="media_image_block" style="display:flex;flex-direction:column;">
      <div id="image_show" style="width:50%;"> </div>
     </div>`,
    },
    {
      id: "image",
      label: "Imagpe",
      category: "Basic",
      activate: true,
      content: { type: "image" },
    },
    //   {
    //     id: "card",
    //     label: "Card",
    //     category: "Basic",
    //     attributes: {
    //       class: "fa fa-image",
    //     },
    //     content: `<div class="card" style="width:22rem;">
    //   <div class="card-body"></div>
    // </div>`,
    //   },
  ];
  const blockManager = {
    appendTo: "#blocks-container",
    blocks: blockManager2,
  };
  const { themeId } = useParams();
  const location = useLocation();

  const { folderId, page, folders, folder } = location.state;
  const PageID = page?._id;

  console.log("Check Page ID");
  const { userToken } = useLogin();

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [pageSave, setPage] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [editor, setEditor] = useState(null);
  const [value, setValue] = useState(0);

  // FOR MCQ :

  const [open, setOpen] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState("");
  // MCQ states
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([
    { id: uuidv4(), option: "", optionNo: "" },
  ]);
  const [answer, setAnswer] = useState("");

  // END of MCQs

  // For Fill Ups:

  const [fillUpOpen, setFillUpOpen] = useState(false);
  // For match the following:

  const [tableOpen, setTableOpen] = useState(false);
  const [tableOptions, setTableOptions] = useState([
    { id: uuidv4(), option1: "", option2: "" },
  ]);

  // For Identifying Object:

  // For Media State

  const [filetype, setFileType] = useState("video/mp4");
  const [openIdentify, setOpenIdentify] = useState(false);

  const { pageId } = useParams();

  console.log("My pageData Id of params =====>", pageData);
  useEffect(() => {
    getSinglePageData();
  }, []);

  useEffect(() => {
    initEditor();
  }, [pageData]);

  const initEditor = async () => {
    $(".panel__editor").html("");
    $(".panel__devices").html("");
    $(".panel__basic-actions").html("");
    $("#blocks-container").html("");
    $("#styles-container").html("");
    $("#layers-container").html("");
    $("#traits-container").html("");
    const topBar = $("#top-bar");
    const sidebar = $("#sidebar");
    const grapesConfig = {
      container: "#editor",
      // height: "100vh",
      plugins: [gjsPresentWebpage, gjsBlockBasic, grapesjsBlockBootstrap],
      pluginsOpts: {
        gjsPresentWebpage: {},
        gjsBlockBasic: {},
        grapesjsBlockBootstrap: {},
      },
      layerManager: layerManager,
      traitManager: traitManager,
      deviceManager: deviceManager,
      allowScripts: 1,
      showOffsets: 1,
      autorender: 1,
      noticeOnUnload: 0,
      clearOnRender: 0,
      protectedCss: `.iframe-wrapper{padding-bottom:30px;}section:last-child{margin-bottom:30px}
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        .row {
          flex-wrap: wrap-reverse;
          margin-left:-1px;
        }
        td,
        th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
        }
  
        tr:nth-child(even) {
          background-color: #CDDC39;
        }

        .l-radio {
          padding: 6px;
          border-radius: 50px;
          display: inline-flex;
          cursor: pointer;
          transition: background 0.2s ease;
          margin: 8px 0;
          -webkit-tap-highlight-color: transparent;
        }
        .l-radio:hover, .l-radio:focus-within {
          background: rgba(0, 100, 0, 0.1);
        }
        .l-radio input {
          vertical-align: middle;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: none;
          border: 0;
          box-shadow: inset 0 0 0 1px #006400;
          box-shadow: inset 0 0 0 1.5px #006400;
          appearance: none;
          padding: 0;
          margin: 0;
          transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
          pointer-events: none;
        }
        .l-radio input:focus {
          outline: none;
        }
        .l-radio input:checked {
          box-shadow: inset 0 0 0 6px #CDDC39;
        }
        .l-radio span {
          vertical-align: middle;
          display: inline-block;
          line-height: 20px;
          padding: 0 8px;
        }
        `,
      canvas: {
        styles: [
          "https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap",
          "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.2/css/bootstrap.min.css",
          "https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.3.0/font-awesome-line-awesome/css/all.min.css",
          "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css",
          "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
          "https://fonts.googleapis.com/css?family=Raleway",
          "http://fonts.googleapis.com/css?family=Lato:400,700",
          "https://sachinchoolur.github.io/lightslider/dist/css/lightslider.css",
          "https://cdn.jsdelivr.net/npm/fullcalendar@5.10.2/main.css",
        ],
        scripts: [
          "https://code.jquery.com/jquery-1.12.4.js",
          "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.2/js/bootstrap.min.js",
          "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js",
          "https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js",
          "https://sachinchoolur.github.io/lightslider/dist/js/lightslider.js",
          "https://cdn.jsdelivr.net/npm/fullcalendar@5.10.2/main.min.js",
          "https://cdn.jsdelivr.net/npm/fullcalendar@5.10.2/main.js",
          "https://cdn.ckeditor.com/ckeditor5/12.4.0/classic/ckeditor.js",
        ],
      },
      components: pageData?.pageHtml
        ? pageData?.pageHtml
        : `<div style="display:flex; justify-content:space-around; position: absolute; bottom: 40; width:100%; outline:none">
        <div style="outline:none;font-wieght:700" > 
      <input 
        style="padding:6px 14px;font-size:16px; outline:none; border:none;  border-radius:  7px; font-wieght:700; font-family: 'Play', sans-serif;"
        type="button"
        name="submit"
        onclick="prevButtonHandler()"
        id="butn1"
        value="Previous"
      />
      </div>
      <div style="outline:none"> 
      <input
      style="padding:6px 20px; font-size:16px;  outline:none;background-color:#cddc39; border:none; border-radius: 7px; color:#ffffff;font-wieght:700; font-family: 'Play', sans-serif;"
        type="button"
        name="submit"
        onclick="nextButtonHandler()"
        id="butn2"
        value="Next"
      />
     </div> </div>`,
      style: pageData?.pageCss ? pageData?.pageCss : "",
      panels,
      // selectorManager,
      styleManager,
      blockManager: {
        appendTo: "#blocks-container",
        // blocks: blockManager.blocks,
      },
      plugins: [gjsBlockBasic, gjsPluginCkeditor],
      pluginsOpts: {
        [gjsPluginCkeditor]: {
          options: {
            language: "en",
            position: "right",
          },
        },
        [gjsBlockBasic]: {
          stylePrefix: "", // no gjs- Prefix
          // flexGrid: 1, // use flexbox instead of tables
          blocks: [
            "column1",
            "column2",
            "column3",
            "column3-7",
            "text",
            "image",
            "video",
            // "link",
          ],
        },
      },
      storageManager: {
        type: "indexeddb",
        autosave: true,
        stepsBeforeSave: 1,
        id: `gjs-${themeId}-${pageSave}`,
      },
    };
    let editorInstance = grapesjs.init(grapesConfig);

    const bm = editorInstance.BlockManager;
    // Adding blocks
    blockManager.blocks.map((item) => {
      bm.add(item.id, item);
    });

    if (pageData?.pageHtml === "") {
      console.log(
        "I am runnning pageData................................................................"
      );
      editorInstance.addComponents(`<script type="text/javascript" id="my_script">
      document.addEventListener(
        "DOMContentLoaded",
        function () {
          console.log("DOM loaded......");
          let options;
          if (document.getElementsByName("products").length > 0) {
            options = document.getElementsByName("products");
          } else if (document.getElementsByName("fillups").length > 0) {
            options = document.getElementsByName("fillups");
          } else {
            options = document.getElementsByName("matchups");
          }

          console.log(
            "Yooooooooooooo the options here ...............",
            options
          );
          let prevChoice;
          window.addEventListener("message", function (e) {
            // Get the sent data
            console.log("Data arrival on child window", e.data);

            // If prevChoice exists(This is if user foes to previous page, we need to remember and check that option)
            prevChoice = e.data.prevChoice ? e.data.prevChoice : null;

            if (prevChoice) {
              console.log("Oh yeah prevChoice exists atmmmm ==========>");
              checkActive(options, prevChoice);
            } else {
              console.log("No prevChoice is avaialble ========>");
            }
            // end of prevChoice
          });
          let initialQuestionState = {};

          if (
            document.getElementsByClassName("fillup__question")[0] ||
            document.getElementsByClassName("mcq__question")[0] ||
            document.getElementById("checkers")
          ) {
            let questionsArr = [];

            let question =
              document.getElementsByClassName("fillup__question")[0] ||
              document.getElementsByClassName("mcq__question")[0];

            console.log("Questions of the question...........", question);
            let options;
            if (document.getElementsByName("products").length > 0) {
              options = document.getElementsByName("products");
            } else if (document.getElementsByName("fillups").length > 0) {
              options = document.getElementsByName("fillups");
            } else {
              options = document.getElementsByName("matchups");
            }
            let correctChoice = document.getElementById("correct")?.value;
            console.log("Correct choice ====>", correctChoice);

            for (let i = 0; i < options.length; i++) {
              console.log("My options mappppppp =>", options[i].value);
              questionsArr.push(options[i].value);
            }

            console.log("If block is executing");

            initialQuestionState = {
              question: question?.innerText,
              options: questionsArr,
              correctChoice,
              isMcq: true,
            };

            // console.log("My options ====>",question.innerText ,"  ", options)
            window.parent.postMessage(initialQuestionState, "*");
          } else {
            console.log("Running Else block");
            initialQuestionState = { isMcq: false };

            window.parent.postMessage(initialQuestionState, "*");
          }
          console.log(initialQuestionState);
        },
        false
      );

      window.onunload = function () {
        console.log("Window unload");

        console.log("Unloading the HTML componet.....");
        window.removeEventListener("message", null);
      };

      console.log("script test");

      function checkActive(options, prevChoice) {
        console.log("Checking the active woooooo ==>", options);
        for (let i = 0; i < options.length; i++) {
          console.log("My options mappppppp =>", options[i].value);
          if (options[i].value === prevChoice) {
            options[i].checked = true;
          }
        }
      }

        function nextButtonHandler() {
        let options;
        if (document.getElementsByName("products").length > 0) {
          options = document.getElementsByName("products");
        } else if (document.getElementsByName("fillups").length > 0) {
          options = document.getElementsByName("fillups");
        } else {
          options = document.getElementsByName("matchups");
        }
        // console.log(options[0].value)

        let checkedAnswer = null;
        for (let i = 0; i < options.length; i++) {
          console.log(
            "My options mappppppp =>",
            options[i].checked,
            options[i].value
          );
          if (options[i].checked) {
            console.log("Yeah running =====>", options[i].value);
            let val = options[i].value;
            checkedAnswer = val;
          }
        }
        var isCorrect = document.getElementById("correct")?.checked;

        let questionExists;

        console.log("Next running");
        const message = {
          next: true,
          prev: false,
          checkedAnswer,
        };

        console.log("My message ====>", message);
        window.parent.postMessage(message, "*");
      }

      function prevButtonHandler() {
        const message = {
          next: false,
          prev: true,
        };
        window.parent.postMessage(message, "*");
      }

      // Here goes the MCQ logic
      console.log("Script running");
      var marks;

      function submitHandler() {
        // What we have to do is check if the option that is selected is the correct one or not, if not then we have to give 0 marks else 2 marks
        var isCorrect = document.getElementById("correct").checked;

        if (isCorrect) {
          marks = 2;
        } else {
          marks = 0;
        }
      }
    </script>`);
    } else {
    }

    editorInstance.Commands.add("set-device-desktop", {
      run: (editorInstance) => editorInstance.setDevice("Desktop"),
    });
    editorInstance.Commands.add("set-device-mobile", {
      run: (editorInstance) => editorInstance.setDevice("Mobile"),
    });
    editorInstance.Commands.add("set-device-tablet", {
      run: (editorInstance) => editorInstance.setDevice("Tablet"),
    });
    editorInstance.Commands.add("set-device-landscape", {
      run: (editorInstance) => editorInstance.setDevice("landscape"),
    });
    // editorInstance.Commands.add("set-device-landscape", {
    //   run: (editorInstance) => editorInstance.setDevice("Landscape"),
    // });

    editorInstance.on("run:preview", () => {
      editorInstance.stopCommand("sw-visibility");
      sidebar.addClass("d-none");
      topBar.addClass("d-none");
    });
    editorInstance.on("stop:preview", () => {
      editorInstance.runCommand("sw-visibility");
      topBar.removeClass("d-none");
      sidebar.removeClass("d-none");
    });
    // Initiate select product dialog if product card is dropped onto the canvas
    editorInstance.on("component:add", (component) => {
      console.log("component :>> ", component);

      if (component.attributes.attributes.id) {
        if (component.attributes.attributes.id.split("-")[0] === "mcq__block") {
          console.log("This works ====>", component.getId());
          setSelectedBlock(component.getId());
          setOpen(true);
        } else if (
          component.attributes.attributes.id.split("-")[0] === "fillup_block"
        ) {
          setSelectedBlock(component.getId());
          setFillUpOpen(true);
          // setSelectedBlock(component.getId());
          // setOpen(true);
        } else if (
          component.attributes.attributes.id.split("-")[0] === "table_block"
        ) {
          setSelectedBlock(component.getId());
          setTableOpen(true); // state to be made
        } else if (
          component.attributes.attributes.id.split("-")[0] === "media_block"
        ) {
          setSelectedBlock(component.getId());
          console.log("This works media_block ====>", component.getId());
          setMediaOpen(true); // state to be made
        } else if (
          component.attributes.attributes.id.split("-")[0] ===
          "media_image_block"
        ) {
          setSelectedBlock(component.getId());
          console.log("This works media_image_block ====>", component.getId());
          setImageMediaOpen(true); // state to be made
        }
      }
    });

    editorInstance.on("storage:store", function (e) {
      // console.log("saving");
      // Storing to IndexedDb on changes to our template
      // addToLocalDb(`gjs-${themeId}-${page}html`, e.html);
      // addToLocalDb(`gjs-${themeId}-${page}css`, e.css);
    });

    editorInstance.on("load", function (e) {
      // * here comes the logic to remove the quiz blocks if any one of them already exists on load.....

      if (
        editorInstance
          .select(editorInstance.DomComponents.getWrapper().find("#mcq__block"))
          .getSelected() ||
        editorInstance
          .select(
            editorInstance.DomComponents.getWrapper().find("#fillup_block")
          )
          .getSelected() ||
        editorInstance
          .select(
            editorInstance.DomComponents.getWrapper().find("#table_block")
          )
          .getSelected()
      ) {
        const bm = editorInstance.BlockManager;
        bm.remove("mcqAdd");
        bm.remove("fillUps");
        bm.remove("matchUps");
      }
    });

    setEditor(editorInstance);
  };

  // console.log("Check setImage", file);
  // API Call

  async function getSinglePageData() {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getSinglePage/${pageId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after single page API", res);
        setPageData(res.msg.pageData);
      } else {
        console.log("On resp err on single page retrieval", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Save Page:

  const savePage = async (e) => {
    e.preventDefault();
    // Add onclick to buttons
    // Next Button:

    // ! Testing start //////////
    // let tex = editor.getHtml();
    // let resul = tex.indexOf("</body>");

    // console.log(addStr(tex, resul, `<style>${editor.getCss()}</style>`)); //outPut : "This is a modified string"
    // return;

    // ! ///////////// Testing end
    editor.select(editor.DomComponents.getWrapper().find(`#butn1`));
    let selectedEl = editor.getSelected();
    selectedEl.addAttributes({ onclick: "prevButtonHandler()" });

    // Prev Button:
    editor.select(editor.DomComponents.getWrapper().find(`#butn2`));
    selectedEl = editor.getSelected();
    selectedEl.addAttributes({ onclick: "nextButtonHandler()" });

    // console.log(editor.getHtml());

    let text = editor.getHtml();
    let result = text.indexOf("</body>");
    console.log("My result =====>", result);
    function addStr(str, index, stringToAdd) {
      return (
        str.substring(0, index) + stringToAdd + str.substring(index, str.length)
      );
    }
    console.log(addStr(text, result, `<style>${editor.getCss()}</style>`)); //outPut : "This is a modified string"

    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/updatePage/${pageId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            // pageHtml: editor.getHtml(),
            pageHtml: addStr(text, result, `<style>${editor.getCss()}</style>`),
            pageCss: `<style>${editor.getCss()}</style>`, // the function call of above
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after creating page", res);
      } else {
        console.log("On resp err on creating page", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMatchSubmit = async (e) => {
    e.preventDefault();

    // console.log(tableOptions);

    // Add onclick to buttons
    // Next Button:

    editor.select(editor.DomComponents.getWrapper().find(`#previous_btn`));
    let selectedEl = editor.getSelected();
    selectedEl.addAttributes({ onclick: "prevButtonHandler()" });

    // Prev Button:
    editor.select(editor.DomComponents.getWrapper().find(`#next_btn`));
    selectedEl = editor.getSelected();
    selectedEl.addAttributes({ onclick: "nextButtonHandler()" });

    // console.log(editor.getHtml());

    let text = editor.getHtml();
    let result = text.indexOf("</body>");
    console.log("My result =====>", result);
    function addStr(str, index, stringToAdd) {
      return (
        str.substring(0, index) + stringToAdd + str.substring(index, str.length)
      );
    }
    console.log(addStr(text, result, `<style>${editor.getCss()}</style>`)); //outPut : "This is a modified string"

    try {
      const response = await fetch(`${FETCH_URL}/api/lessonplan/createTable`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          mcqOptions: options,
          correct: answer,
          tableOptions,
          pageHtml: addStr(text, result, `<style>${editor.getCss()}</style>`),
          pageCss: editor.getCss(),
        }),
      });
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after creating page", res);
        setPageData(res.msg.pageData);

        // * Remove Match the following block after success

        handleMatchClose();
        // console.log(
        //   "I trigger the timeout.......",
        //   editor.BlockManager.getAll()
        // );

        // ! To check......
        setTimeout(() => {
          console.log(
            "I trigger the timeout.......",
            editor.BlockManager.getAll()
          );
          const bm = editor.BlockManager;
          bm.remove("matchUps");
          bm.remove("mcqAdd");
          bm.remove("fillUps");
          bm.remove("previous_btn");
          bm.remove("next_btn");
          bm.remove("submit_btn");
        }, 5000);
      } else {
        console.log("On resp err on creating page", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create dynamic MCQs here

    if (!answer) {
      return alert("You need to select an answer before submitting");
    }
    console.log("InputFields", options, " ", question);

    let selectedEl;

    // Select the MCQ block main div
    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));

    selectedEl = editor.getSelected();

    // Select the MCQ heading element
    editor.select(
      editor.DomComponents.getWrapper().find(
        `#${selectedBlock} > .mcq__question`
      )
    );

    selectedEl = editor.getSelected();
    console.log("My selected element from submit ===>", selectedEl);

    selectedEl.replaceWith(
      `<div class ="mcq__question">${question}</div><br />`
    );

    editor.select(
      editor.DomComponents.getWrapper().find(
        `#${selectedBlock} > .mcq__checkbox`
      )
    );

    console.log("My selected elem =======>", editor);

    selectedEl = editor.getSelected();

    // ** For single correct answer, radiobutton

    for (let i = 0; i < options.length; i++) {
      selectedEl.append(
        `<label for=${
          answer === options[i].id ? "correct" : options[i].option
        } class="l-radio">
        <input type="radio" id=${
          answer === options[i].id ? "correct" : options[i].option
        } value=${options[i].option} name="products" />
        <span>${options[i].option}</span>
        </label>`
      );
    }

    // selectedEl.append(
    //   `<button type="button" onclick="addData()" id="butn1">Submit</button>`
    // );
    // frameEle.contentWindow.postMessage(message, "*");

    // ** For multiple correct answers, checkboxes

    // for (let i = 0; i < options.length; i++) {
    //   selectedEl.append(
    //     `<input type="checkbox" id=${options[i].option + i}" value=${
    //       options[i].option
    //     } name="products" />
    //     <label for=${options[i].option + i}>${options[i].option}</label>
    //     `
    //   );
    // }

    const bm = editor.BlockManager;

    bm.remove("matchUps");
    bm.remove("mcqAdd");
    bm.remove("fillUps");
    bm.remove("previous_btn");
    bm.remove("next_btn");
    bm.remove("submit_btn");

    handleClose();
  };
  const [mediaOpen, setMediaOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const reuploadClick = () => {
    setFile(false);
    setFileType(false);
  };
  const [videores, setVideoRes] = useState();

  const [videopath, setVideoPath] = React.useState("");
  console.log("Uploaded content log => ", videores);

  const onChangeFile = (event) => {
    if (event.target.files[0].type == "video/mp4") {
      setLoading(true);
    }
    setFile(URL.createObjectURL(event.target.files[0]));
    setVideoPath(event.target.files[0]);
  };

  useEffect(() => {
    VideoUploadFromServer();
  }, [videopath]);

  const handleSubmitMedia = (e) => {
    e.preventDefault();
    let selectedEl;
    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));
    selectedEl = editor.getSelected();
    console.log("My selected elem  Video  =======>", editor.getSelected());
    console.log("check Video  Selected", file);
    console.log("My selected block's ID ======>", selectedBlock);
    selectedEl.append(
      `<video
      className="width100"
      src=${videores?.videoUrl}
      autoplay
      muted
      type="video/mp4"
      controls
      controlsList="nodownload"
      style="width:50%;"
     />`
    );
    handleMediaClose();
  };

  const VideoUploadFromServer = async () => {
    const formdata = new FormData();
    formdata.append("pageId", folderId);
    formdata.append("video", videopath);
    console.log("Check videores", formdata);
    try {
      const response = await fetch(`${FETCH_URL}/api/lessonplan/videoUpload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      const res = await response.json();
      if (response.ok) {
        setVideoRes(res.msg2);
        setLoading(false);
        console.log("On response arrived after creating folder", res);
      } else {
        setLoading(false);

        console.log("On resp err on creating folder", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //For Image Upload
  const [mediaImageOpen, setImageMediaOpen] = useState(false);
  const [imageFile, setImageFile] = useState();

  const [Imageres, setImgaeRes] = useState();

  const [imgaepath, setImagePath] = React.useState("");

  const ImageuploadClick = () => {
    setImageFile(false);
  };

  const onChangeImageFile = (event) => {
    if (event.target.files[0].type == "video/mp4") {
      setLoading(true);
    }
    setImageFile(URL.createObjectURL(event.target.files[0]));
    setImagePath(event.target.files[0]);
  };

  const ImageUploadFromServer = async () => {
    const formdata = new FormData();
    formdata.append("pageId", folderId);
    formdata.append("image", imgaepath);
    console.log("Check videores", formdata);
    try {
      const response = await fetch(`${FETCH_URL}/api/lessonplan/imageUpload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      const res = await response.json();
      if (response.ok) {
        setImgaeRes(res.msg2);
        setLoading(false);
        console.log("On response arrived after creating folder", res);
      } else {
        setLoading(false);

        console.log("On resp err on creating folder", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmitImageMedia = (e) => {
    e.preventDefault();
    let selectedEl;
    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));
    selectedEl = editor.getSelected();
    selectedEl.append(
      `<img
      className="width100"
      src=${Imageres?.imageUrl}
      style="width:100%;"
     />`
    );
    handleMediaImageClose();
  };

  useEffect(() => {
    ImageUploadFromServer();
  }, [imgaepath]);

  const handleFillUpSubmit = (e) => {
    e.preventDefault();
    // Create dynamic MCQs here
    console.log("InputFields", options, " ", question);

    let selectedEl;

    // Select the MCQ block main div
    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));

    selectedEl = editor.getSelected();

    console.log("My selcted element of fillllll ups #1=========>", selectedEl);

    // Select the MCQ heading element
    editor.select(
      editor.DomComponents.getWrapper().find(
        `#${selectedBlock} > .fillup__question`
      )
    );

    selectedEl = editor.getSelected();
    console.log("My selected element from submit ===>", selectedEl);

    selectedEl.replaceWith(
      `<div class ="fillup__question">${question}</div><br />`
    );

    editor.select(
      editor.DomComponents.getWrapper().find(
        `#${selectedBlock} > .fillup_checkbox`
      )
    );

    console.log("My selected elem =======>", editor.getSelected());
    selectedEl = editor.getSelected();

    // ** For single correct answer, radiobutton

    for (let i = 0; i < options.length; i++) {
      selectedEl.append(
        `<label for=${
          answer === options[i].id ? "correct" : options[i].option
        } class="l-radio">
        <input type="radio" id=${
          answer === options[i].id ? "correct" : options[i].option
        } value=${options[i].option} name="products" />
        <span>${options[i].option}</span>
        </label>`
      );
    }

    const bm = editor.BlockManager;

    bm.remove("matchUps");
    bm.remove("mcqAdd");
    bm.remove("fillUps");
    bm.remove("previous_btn");
    bm.remove("next_btn");
    bm.remove("submit_btn");

    handleFillUpClose();
  };

  // * Function for Identify Object
  const handleIdentifyObjectSubmit = (e) => {
    e.preventDefault();
    // Create dynamic MCQs here
    console.log("InputFields", options, " ", question);

    let selectedEl;

    // Select the MCQ block main div
    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));

    selectedEl = editor.getSelected();

    console.log("Selected the element for identifying Object", selectedEl);
  };

  const handleChangeInput = (id, event) => {
    if (event.target.name === "radioMcqAnswer") {
      setAnswer(event.target.value);
      return;
    }

    if (event.target.name === "radioFillUpAnswer") {
      setAnswer(event.target.value);
      return;
    }

    const newInputFields = options.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setOptions(newInputFields);
  };

  const handleAddFields = () => {
    setOptions([...options, { id: uuidv4(), option: "", optionNo: "" }]);
  };

  const handleRemoveFields = (id) => {
    const values = [...options];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setOptions(values);
  };

  // Functions for Match the following......

  const handleChangeTableInput = (id, event) => {
    const newInputFields = tableOptions.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setTableOptions(newInputFields);
  };

  const handleAddTableFields = () => {
    setTableOptions([
      ...tableOptions,
      { id: uuidv4(), option1: "", option2: "" },
    ]);
  };

  const handleRemoveTableFields = (id) => {
    const values = [...tableOptions];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setTableOptions(values);
  };

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };

  // For MCQs
  const handleClose = () => {
    setOpen(false);
    let selectedEl;

    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));

    selectedEl = editor.getSelected();

    if (!question || !answer || options.length < 1) {
      selectedEl.remove();
    }

    setOptions([{ id: uuidv4(), option: "", optionNo: "" }]);

    // // Select the MCQ block main div
    console.log("My selected block's ID ======>", selectedBlock);
    console.log("selectionsss==>", document.getElementById(selectedBlock));

    console.log("My selected Element =====>", question);

    setQuestion("");
    setAnswer("");
  };

  // For FillUp
  const handleFillUpClose = () => {
    setFillUpOpen(false);
    let selectedEl;

    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));

    selectedEl = editor.getSelected();

    if (!question || !answer || options.length < 1) {
      selectedEl.remove();
    }

    setOptions([{ id: uuidv4(), option: "", optionNo: "" }]);

    // // Select the MCQ block main div
    console.log("My selected block's ID ======>", selectedBlock);
    console.log("selectionsss==>", document.getElementById(selectedBlock));

    console.log("My selected Element =====>", question);

    setQuestion("");
    setAnswer("");
  };

  const handleMatchClose = () => {
    setTableOpen(false);
    let selectedEl;

    editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));

    selectedEl = editor.getSelected();

    // if (!question || !answer || options.length < 1) {
    selectedEl.remove();
    // }

    setTableOptions([{ id: uuidv4(), option1: "", option2: "" }]);
    setOptions([{ id: uuidv4(), option: "", optionNo: "" }]);

    // // Select the MCQ block main div
    console.log("My selected block's ID ======>", selectedBlock);
    console.log("selectionsss==>", document.getElementById(selectedBlock));

    console.log("My selected Element =====>", question);

    setQuestion("");
    setAnswer("");
  };

  const handleMediaClose = () => {
    setMediaOpen(false);
    setFile(" ");
    // let selectedEl;
    // editor.select(editor.DomComponents.getWrapper().find(`#${selectedBlock}`));
    // selectedEl = editor.getSelected();
    // selectedEl.remove();
  };

  const handleMediaImageClose = () => {
    setImageMediaOpen(false);
    setImageFile(" ");
  };

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      // bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {/* ///* Dialog for MCQs */}
      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle style={{ backgroundColor: "#cddc39" }}>
          <Typography
            align="center"
            style={{
              color: "#ffffff",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            Create MCQ
          </Typography>
        </DialogTitle>
        <DialogContent style={{ height: "20vw", margin: "10px" }}>
          {/* </Grid>  */}
          <Grid item xs={12}>
            <Input
              style={{
                border: "2px solid #e3e3e3",
                borderRadius: "10px",
                paddingLeft: "7px",
                width: "100%",
                marginTop: "15px",
              }}
              placeholder="Enter Question"
              onChange={(e) => setQuestion(e.target.value)}
            />{" "}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Input
              style={{
                paddingLeft: "7px",
                border: "2px solid #e3e3e3",
                borderRadius: "10px",
                width: "100%",
              }}
              placeholder="Enter Answer"
              onChange={(e) => setAnswer(e.target.value)}
            />
          </Grid>

          {options.map((option, index) => (
            <div key={option.id} style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography>{index === 0 ? "Correct" : ""}</Typography>
                  <Radio
                    checked={answer === option.id}
                    onChange={(event) => handleChangeInput(option.id, event)}
                    value={option.id}
                    name="radioMcqAnswer"
                    // inputProps={{ "aria-label": "A" }}
                  />
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: "20px" }}>
                  <Typography>{index === 0 ? "Option Name" : ""}</Typography>
                  <TextField
                    className="Quiztext"
                    style={{ width: "90%", padding: "0px", margin: "0px" }}
                    name="option"
                    // label="Option"
                    variant="filled"
                    value={option.option}
                    onChange={(event) => handleChangeInput(option.id, event)}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    paddingTop: "19px",
                  }}
                >
                  <IconButton
                    style={{ marginRight: "10px" }}
                    className="GreyButton700"
                    disabled={option.length === 1}
                    onClick={() => handleRemoveFields(option.id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton
                    className="GreenButton700"
                    onClick={handleAddFields}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button className="CraeteButton" onClick={handleSubmit}>
            Create
          </Button>
          <Button className="CancelButton" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* // * Dialog for fill ups: */}
      <Dialog onClose={handleFillUpClose} open={fillUpOpen} fullWidth>
        <DialogTitle style={{ backgroundColor: "#cddc39" }}>
          <Typography
            align="center"
            style={{
              color: "#ffffff",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            {" "}
            Create Fill Up
          </Typography>
        </DialogTitle>
        <DialogContent style={{ height: "20vw", margin: "10px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Input
                style={{
                  border: "2px solid #e3e3e3",
                  borderRadius: "10px",
                  paddingLeft: "7px",
                  width: "100%",
                }}
                placeholder="Enter Question"
                onChange={(e) => setQuestion(e.target.value)}
              />{" "}
            </Grid>
            {/* <Grid item xs={12} style={{ marginTop: "10px" }}>
            <Input
              style={{
                paddingLeft: "7px",
                border: "2px solid #e3e3e3",
                borderRadius: "10px",
                width: "100%",
              }}
              placeholder="Enter Answer"
              onChange={(e) => setAnswer(e.target.value)}
            />{" "}
          </Grid> */}
          </Grid>
          {options.map((option, index) => (
            <div key={option.id} style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography>{index === 0 ? "Correct" : ""}</Typography>
                  <Radio
                    checked={answer === option.id}
                    onChange={(event) => handleChangeInput(option.id, event)}
                    value={option.id}
                    name="radioFillUpAnswer"
                    // inputProps={{ "aria-label": "A" }}
                  />
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: "20px" }}>
                  <Typography>{index === 0 ? "Option Name" : ""}</Typography>
                  <TextField
                    className="Quiztext"
                    style={{ width: "90%", padding: "0px", margin: "0px" }}
                    name="option"
                    // label="Option"
                    variant="filled"
                    value={option.option}
                    onChange={(event) => handleChangeInput(option.id, event)}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    paddingTop: "19px",
                  }}
                >
                  <IconButton
                    style={{ marginRight: "10px" }}
                    className="GreyButton700"
                    disabled={option.length === 1}
                    onClick={() => handleRemoveFields(option.id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton
                    className="GreenButton700"
                    onClick={handleAddFields}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button className="CraeteButton" onClick={handleFillUpSubmit}>
            Create
          </Button>
          <Button className="CancelButton" onClick={handleFillUpClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ///* Dialog for Match ups  */}
      <Dialog onClose={handleMatchClose} open={tableOpen}>
        <DialogTitle style={{ backgroundColor: "#cddc39" }}>
          <Typography
            align="center"
            style={{
              color: "#ffffff",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            {" "}
            Create Match Ups
          </Typography>
        </DialogTitle>
        <DialogContent style={{ height: "20vw", margin: "10px" }}>
          {tableOptions.map((option) => (
            <div key={option.id} style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography> Column 1 </Typography>
                  <TextField
                    className="Quiztext"
                    name="option1"
                    // label="Option No"
                    variant="filled"
                    value={option.option1}
                    onChange={(event) =>
                      handleChangeTableInput(option.id, event)
                    }
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                  <Typography>Column 2</Typography>
                  <TextField
                    className="Quiztext"
                    style={{ width: "90%", padding: "0px", margin: "0px" }}
                    name="option2"
                    // label="Option"
                    variant="filled"
                    value={option.option2}
                    onChange={(event) =>
                      handleChangeTableInput(option.id, event)
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    paddingTop: "19px",
                  }}
                >
                  <IconButton
                    style={{ marginRight: "10px" }}
                    className="GreyButton700"
                    disabled={option.length === 1}
                    onClick={() => handleRemoveTableFields(option.id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton
                    className="GreenButton700"
                    onClick={handleAddTableFields}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
              {/* <Grid container>
              <Grid item xs={12}>
                <Input
                  style={{
                    border: "2px solid #e3e3e3",
                    borderRadius: "10px",
                    paddingLeft: "7px",
                    width: "100%",
                  }}
                  placeholder="Enter Question"
                  onChange={(e) => setQuestion(e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Input
                  style={{
                    paddingLeft: "7px",
                    border: "2px solid #e3e3e3",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  placeholder="Enter Answer"
                  onChange={(e) => setAnswer(e.target.value)}
                />{" "}
              </Grid>
            </Grid> */}
            </div>
          ))}

          {options.map((option) => (
            <div key={option.id} style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography> Option No </Typography>
                  <TextField
                    className="Quiztext"
                    name="optionNo"
                    // label="Option No"
                    variant="filled"
                    value={option.optionNo}
                    onChange={(event) => handleChangeInput(option.id, event)}
                  />
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: "20px" }}>
                  <Typography> Option </Typography>
                  <TextField
                    className="Quiztext"
                    style={{ width: "90%", padding: "0px", margin: "0px" }}
                    name="option"
                    // label="Option"
                    variant="filled"
                    value={option.option}
                    onChange={(event) => handleChangeInput(option.id, event)}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    paddingTop: "19px",
                  }}
                >
                  <IconButton
                    style={{ marginRight: "10px" }}
                    className="GreyButton700"
                    disabled={option.length === 1}
                    onClick={() => handleRemoveFields(option.id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton
                    className="GreenButton700"
                    onClick={handleAddFields}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          ))}

          <Grid container>
            {/* <Grid item xs={12}>
                <Input
                  style={{
                    border: "2px solid #e3e3e3",
                    borderRadius: "10px",
                    paddingLeft: "7px",
                    width: "100%",
                  }}
                  placeholder="Enter Question"
                  onChange={(e) => setQuestion(e.target.value)}
                />{" "}
              </Grid> */}
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Input
                style={{
                  paddingLeft: "7px",
                  border: "2px solid #e3e3e3",
                  borderRadius: "10px",
                  width: "100%",
                }}
                placeholder="Enter Answer"
                onChange={(e) => setAnswer(e.target.value)}
              />{" "}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="CraeteButton" onClick={handleMatchSubmit}>
            Create
          </Button>
          <Button className="CancelButton" onClick={handleMatchClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ///* Dialog for Identifying Object */}
      <Dialog
        onClose={() => {
          setOpenIdentify(false);
        }}
        open={openIdentify}
      >
        <DialogTitle style={{ backgroundColor: "#cddc39" }}>
          <Typography
            align="center"
            style={{
              color: "#ffffff",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            {" "}
            Identifying Object creation
          </Typography>
        </DialogTitle>

        <DialogContent style={{ height: "20vw", margin: "10px" }}>
          <input
            type="image"
            accept="image/*"
            onChange={handleIdentifyObjectSubmit}
          />
        </DialogContent>
      </Dialog>
      {/* Dialog for Media */}
      <Dialog onClose={handleMediaClose} open={mediaOpen} fullWidth={true}>
        <DialogTitle style={{ backgroundColor: "#cddc39" }}>
          <Typography
            align="center"
            style={{
              color: "#ffffff",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            Upload Video
          </Typography>
        </DialogTitle>
        <DialogContent style={{ height: "15vw", margin: "10px" }}>
          <Grid container style={{ marginTop: "10px" }}>
            <input
              style={{ display: "none" }}
              type="file"
              id="raised-button-file"
              accept="video/*"
              onChange={(e) => {
                onChangeFile(e);
              }}
            />

            <Grid container>
              {file === undefined ? null : (
                <Grid
                  container
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {file === null ? null : (
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        reuploadClick();
                        setFile(undefined);
                        setFileType(undefined);
                      }}
                      style={{
                        position: "absolute",
                        right: 8,
                        color: "red",
                      }}
                    >
                      <MdClose />
                    </IconButton>
                  )}

                  <Grid
                    item
                    className="width100"
                    style={{ border: "1px solid #e4e6ef" }}
                  >
                    <video
                      // className={file === "" ? null : "video-height"}
                      className="video-height"
                      muted
                      style={{ marginTop: "15px" }}
                      src={file}
                      type="video/mp4"
                      oncontextmenu="return false;"
                      controlsList="nodownload"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className="ChooseFileButtonItem">
              <label htmlFor="raised-button-file">
                <Button
                  className="GreenButton "
                  variant="raised"
                  component="span"
                  style={{ marginTop: "5px" }}
                >
                  <IoIosAddCircle className="AddCircleIcon" />
                  Choose File
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="CraeteButton" onClick={handleSubmitMedia}>
            Create
          </Button>
          <Button className="CancelButton" onClick={handleMediaClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for Media Image*/}

      <Dialog
        onClose={handleMediaImageClose}
        open={mediaImageOpen}
        fullWidth={true}
      >
        <DialogTitle style={{ backgroundColor: "#cddc39" }}>
          <Typography
            align="center"
            style={{
              color: "#ffffff",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            Upload Image
          </Typography>
        </DialogTitle>
        <DialogContent style={{ height: "15vw", margin: "10px" }}>
          <Grid container style={{ marginTop: "10px" }}>
            <input
              style={{ display: "none" }}
              type="file"
              id="raised-button-file"
              accept="image/*"
              onChange={(e) => {
                onChangeImageFile(e);
              }}
            />

            <Grid container>
              {imageFile === undefined ? null : (
                <Grid
                  container
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {imageFile === null ? null : (
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        ImageuploadClick();
                        setImageFile(undefined);
                      }}
                      style={{
                        position: "absolute",
                        right: 8,
                        color: "red",
                      }}
                    >
                      <MdClose />
                    </IconButton>
                  )}

                  <Grid
                    item
                    className="width100"
                    style={{ border: "1px solid #e4e6ef" }}
                  >
                    <img src={imageFile} className="video-height" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className="ChooseFileButtonItem">
              <label htmlFor="raised-button-file">
                <Button
                  className="GreenButton "
                  variant="raised"
                  component="span"
                  style={{ marginTop: "5px" }}
                >
                  <IoIosAddCircle className="AddCircleIcon" />
                  Choose File
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="CraeteButton" onClick={handleSubmitImageMedia}>
            Create
          </Button>
          <Button className="CancelButton" onClick={handleMediaImageClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* loading PopUP */}
      <Dialog
        fullScreen
        open={loading}
        PaperProps={{
          className: "loading-Dialog",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%", height: "100vh" }}
        >
          <Typography align="center" style={{ width: "100%" }}>
            <CircularProgress style={{ color: " #cddc39" }} />
          </Typography>
        </Grid>
      </Dialog>

      {/* Sidebar Tabs */}
      <div className="container" style={{ position: "relative" }}>
        <div id="top-bar">
          <div id="top-bar-actioins-left">
            <img src={Logo} style={{ width: "260px", height: "100px" }} />
            <div className="panel__devices"></div>
          </div>

          <div id="top-bar-actions-center">
            <Button
              variant="contained"
              size="small"
              endIcon={<SaveIcon style={{ fill: "#ffffff" }} />}
              className="savebutton"
              style={{
                color: "#ffffff",
                backgroundColor: "transparent",
                textTransform: "capitalize",
              }}
              onClick={
                savePage // add pageId here
              }
            >
              Save
            </Button>
            <ClosePage
              savePage={savePage}
              folderId={folderId}
              PageID={PageID}
              folders={folders}
              folder={folder}
            />
          </div>

          <div id="top-bar-actions-right" className="rightactionbutton">
            <div className="panel__editor"> </div>
          </div>
        </div>
        <div className="flex">
          <aside id="sidebar">
            <Tabs
              className="tabicon"
              id="tabs-heading"
              variant="fullWidth"
              centered
              value={value}
              onChange={handleTabChange}
              color="dark"
              TabIndicatorProps={{ style: { background: "#cddc39" } }}
            >
              <Tab
                className="tabicon"
                id="tab-blocksab-blocks"
                style={{ minWidth: "25%" }}
                icon={<WidgetsIcon style={{ color: "#898989" }} />}
              />
              <Tab
                className="tabicon"
                id="tab-styles"
                style={{ minWidth: "25%" }}
                icon={<StyleIcon style={{ color: "#898989" }} />}
              />
              <Tab
                className="tabicon"
                id="tab-traits"
                style={{ minWidth: "25%" }}
                icon={<SettingsIcon style={{ color: "#898989" }} />}
              />
              <Tab
                className="tabicon"
                id="tab-layers"
                style={{ minWidth: "25%" }}
                icon={<LayersIcon style={{ color: "#898989" }} />}
              />
            </Tabs>

            <div id="tab-content">
              <TabPanel value={value} index={0}>
                <div id="blocks-container"></div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div id="styles-container"></div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div id="traits-container"></div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div id="layers-container"></div>
              </TabPanel>
            </div>
          </aside>
          <section className="main">
            <div id="editor" className="main2"></div>
            <div id="editor" className="main2"></div>
          </section>
        </div>
      </div>
    </Box>
  );
};

export default ThemeEditor;

import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
// ReactIcons
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//core Component

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleSmall" {...other}>
      <Typography className="DialogTitleTypographySmall">
        Are you sure you want to delete this item?
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ qs, skills, searchSkill }) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
  };
  console.log(skills._id);
  console.log(qs._id);
  const deleteRubrics = async (gradeId) => {
    console.log("i am running");
    try {
      const response = await fetch(
        `${FETCH_URL}/skill/deleteRubrics/${skills._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            rubricId: qs._id,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My Grade Delete response ==>", res);
        // getAllGrades();
        // setSnackOpen(true);
        // setSnackMsg(res.msg);
        searchSkill(skills._id);
      } else {
        //.log("Error while fetching single school", res.err);
        // setSnackerropen(true);
        // setSnackErrMsg(res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  return (
    <div>
      {" "}
      <Tooltip title="Delete">
        <IconButton className="ActionButtonSize">
          <RiDeleteBin6Line
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogActions>
          <Button
            className="GreyButton DeleteCancelB"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton  DeleteOkB"
            autoFocus
            onClick={() => {
              deleteRubrics();
              setOpen(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

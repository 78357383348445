import * as React from "react";

import {
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  Input,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

export default function ExtendTab(props) {
  const {
    states: {
      fullName,
      setFullName,
      trainerEmail,
      setTrainerEmail,
      trainerPhoneNo,
      setTrainerPhoneNo,
      trainerLocation,
      setTrainerLocation,
      trainerAddress,
      setTrainerAddress,
      trainerZipCode,
      setTrainerZipCode,
      trainerCity,
      setTrainerCity,
      trainerState,
      setTrainerState,
      trainerCountry,
      setTrainerCountry,
      guardianFullName,
      setGuardianFullName,
      guardianLocation,
      setGuardianLocation,
      guardianEmail,
      setGuardianEmail,
      guardingPhoneNo,
      setGuardingPhoneNo,
    },
    TabNext2,
    trainer,
    submitClick,
  } = props;

  return (
    <Grid container spacing={4} className="DailogLTRBStyle">
      <Grid item md={6} sm={6} xs={6}>
        <Grid item container direction="column" style={{ marginLeft: "3%" }}>
          <Typography className="InputTitleTypography GreyColorTypography">
            Email ID <span style={{ color: "red" }}> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerEmail}
              onChange={(e) => setTrainerEmail(e.target.value)}
            ></Input>{" "}
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Mobile Number <span style={{ color: "red" }}> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              type="number"
              defaultValue={trainer.contactDetail.trainerPhoneNo}
              onChange={(e) => setTrainerPhoneNo(e.target.value.slice(0, 10))}
            ></Input>{" "}
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Location <span style={{ color: "red" }}> *</span>
          </Typography>{" "}
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerLocation}
              onChange={(e) => setTrainerLocation(e.target.value)}
            ></Input>{" "}
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Address <span style={{ color: "red" }}> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <TextareaAutosize
              className="ContactTextArea"
              aria-label="minimum height"
              defaultValue={trainer.contactDetail.trainerAddress}
              onChange={(e) => setTrainerAddress(e.target.value)}
            />
          </Grid>
          {/* <Typography className="InputTitleTypography GreyColorTypography">
            ZipCode
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerZipCode}
              onChange={(e) => setTrainerZipCode(e.target.value)}
            ></Input>{" "}
          </Grid> */}
          <Typography className="InputTitleTypography GreyColorTypography">
            ZipCode
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerZipCode}
              onChange={(e) => setTrainerZipCode(e.target.value)}
              disabled
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            City <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerCity}
              onChange={(e) => setTrainerCity(e.target.value)}
              disabled
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            State <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerState}
              onChange={(e) => setTrainerState(e.target.value)}
              disabled
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Country <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.trainerCountry}
              onChange={(e) => setTrainerCountry(e.target.value)}
            >
              {" "}
            </Input>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={6} xs={6} direction="column">
        <Grid item container direction="column">
          <Typography className="InputTitleTypography GreyColorTypography">
            Full Name <span style={{ color: "red" }}> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.guardianFullName}
              onChange={(e) => setGuardianFullName(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Location <span style={{ color: "red" }}> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.guardianLocation}
              onChange={(e) => setGuardianLocation(e.target.value)}
            ></Input>{" "}
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Email ID <span style={{ color: "red" }}> *</span>
          </Typography>{" "}
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={trainer.contactDetail.guardianEmail}
              onChange={(e) => setGuardianEmail(e.target.value)}
            ></Input>{" "}
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Mobile Number <span style={{ color: "red" }}> *</span>
          </Typography>{" "}
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              type="number"
              defaultValue={trainer.contactDetail.guardianPhoneNo}
              onChange={(e) => setGuardingPhoneNo(e.target.value.slice(0, 10))}
            ></Input>{" "}
          </Grid>
          <DialogActions className="">
            {/* <Button
              className="GreenButton700 NextActionBottom"
              autoFocus
              onClick={TabNext2}
            >
              Next
            </Button> */}
            <Button className="DocumnetSubmit" onClick={submitClick}>
              {" "}
              Submit
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import { Tabs, Tab, Box } from "@material-ui/core";

//core Component
import DepartmentCompo from "./DepartmentCompo";
import UserTab from "./UserCompoTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const {
    states: {
      value,
      setValue,
      userDetails,
      setUserDetails,
      departMentDetail,
      setDepartMentDetails,
      searchU,
      setSearchU,
      pageU,
      setPageU,
      totalU,
      setTotalU,
      searchO,
      setSearchO,
      pageO,
      setPageO,
      totalO,
      setTotalO,
    },
    getUserDetails,
    getDepartmentDetails,
    handleSearch,
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
        >
          <Tab className="ActivityTabChanges" label="Users" {...a11yProps(0)} />
          <Tab
            className="ActivityTabChanges"
            label="Organisation"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <UserTab
          states={{ searchU, setSearchU, pageU, setPageU, totalU, setTotalU }}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          getUserDetails={getUserDetails}
          handleSearch={handleSearch}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DepartmentCompo
          states={{ searchO, setSearchO, pageO, setPageO, totalO, setTotalO }}
          departMentDetail={departMentDetail}
          setDepartMentDetails={setDepartMentDetails}
          getDepartmentDetails={getDepartmentDetails}
          handleSearch={handleSearch}
        />
      </TabPanel>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Switch,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
// React Icons
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

// core Component

export default function OrgnasationTab({ userDetails }) {
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">User Name</TableCell>
              <TableCell className="Head">Organisation</TableCell>
              <TableCell className="Head">Role</TableCell>
              <TableCell className="Head">Assigned On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetails &&
              Object.keys(userDetails).length > 0 &&
              userDetails.msg.map((usrData, i) => (
                <TableRow key={i}>
                  <TableCell className="Col700WAB">
                    <Link>{usrData.fullname} </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Link to="/hos/userprofile"> Zoomer Sports </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">{usrData.role}</TableCell>
                  <TableCell className="Col700WAB">
                    {" "}
                    {usrData.dateOfJoining}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@material-ui/core";
import GradeDivision from "./GradeProfileTab's/GDivisions/GradeDivision";
import AddGLearner from "./GradeProfileTab's/GLearnerTab/GAddLearner/GLearnerAdd";
import { Box, Tab, Tabs, Grid, FormControl } from "@material-ui/core";
// React Icons

//Core Component
import GradeProfile from "./GradeProfileTab's/GradeDetailsTab/GradeDetailTab";
import LearnerRadioA from "./GradeProfileTab's/GLearnerTab/GLearnerTable's/LearnerRadioA";
import GTrainer from "./GradeProfileTab's/GTrainerTab/GTrainer";
import GCourse from "./GradeProfileTab's/GCourseTab/GCourse";
import GSkill from "./GradeProfileTab's/Skills/GSkills";
import GEquipment from "./GradeProfileTab's/GEquipmentsTab/GEqipments";
import GLessonPlan from "./GradeProfileTab's/GLessonPlanTab/GlessonPlan";
// import GLearnerUploadExcel from "../GradeProfile's/GradeProfileTab's/GLearnerTab/GLearnerUploadExcel";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { useLocation } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const {
    states: { data },
  } = props;
  const [value, setValue] = React.useState(0);
  const [rowData, setRowData] = useState(null);
  const [selected, setSelected] = useState("");
  const [divisionName, setDivisionName] = React.useState(0);
  const [divisionDataId, setDivisionDataId] = useState("");
  const [learnerDivisonData, setLearnerDivisonData] = useState([]);
  const [divisonData, setDivisonData] = useState([]);
  const { schoolDetail, userToken, loadingHandler } = useLogin();
  const location = useLocation();

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data) {
      handleSchoolSearch();
    }
  }, [data]);

  const handleSchoolSearch = async () => {
    console.log("Check Data schoolUid ", data?.schoolUid);
    const response = await fetch(
      `${FETCH_URL}/api/school/getSingleSchool/${data?.schoolUid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My School response from searching ==>", res);
    } else {
    }
  };
  const getAllDivisons = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setDivisonData(res.msg);
        console.log("My Grade Divison response ==>", res);
      } else {
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      console.log("Error while getting divison =======>", error);
    }
  };
  useEffect(() => {
    getAllDivisons(data._id);
  }, []);
  /////////////////////////////get paginate learner////////////////////////////

  const getPaginatedLearner = async (divisionId) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedLearner?division=${divisionId}&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setLearnerDivisonData(res.msg);
        console.log("My learner Divison response ==>", res);
      } else {
        loadingHandler(false);
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Error while getting divison =======>", error);
    }
  };

  /////////////////////////////get paginate learner////////////////////////////
  function handleChange(e, datas) {
    setSelected(e.target.value);
    setDivisionName(datas.props.children);
  }
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          variant="scrollable"
          scrollButtons="auto"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Grade Detail"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Divisions"
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Learner"
            {...a11yProps(2)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Trainer"
            {...a11yProps(3)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Course"
            {...a11yProps(4)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Lesson Plans"
            {...a11yProps(5)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Skills"
            {...a11yProps(6)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Equipment"
            {...a11yProps(7)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GradeProfile states={{ data, rowData }} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {" "}
        <GradeDivision data={data} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container className="  colorwhite DivisionStyleGridRadio ">
          <Grid container className="DiviStyle">
            {" "}
            <Grid item>
              <FormControl>
                <Select
                  style={{
                    border: "1px solid #dddddd",
                    padding: "5px",
                    borderRadius: "8px",
                  }}
                  defaultValue={0}
                  value={selected ? selected : 0}
                  onChange={(e, datas) => {
                    handleChange(e, datas);
                    setDivisionDataId(e.target.value);
                    getPaginatedLearner(e.target.value);
                  }}
                >
                  <MenuItem className="BlueColorTypography" value={0}>
                    Select Divisons
                  </MenuItem>

                  {data?.divisions?.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data._id}>
                        {data.division}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Grid container>
                <AddGLearner
                  getPaginatedLearner={getPaginatedLearner}
                  gradeIds={data?._id}
                  divisionDataId={divisionDataId}
                  gradeData={data}
                  rowData1={rowData}
                />
                {/* <GLearnerUploadExcel
                  gradeData={data}
                  gradeId={data?._id}
                  rowData1={rowData}
                  divisonData1={divisonData}
                /> */}
              </Grid>
            </Grid>
          </Grid>{" "}
        </Grid>

        <LearnerRadioA
          gradeId={data?._id}
          learnerDivisonData={learnerDivisonData}
          divisionDataId={divisionDataId}
          getPaginatedLearner={getPaginatedLearner}
          divisionName={divisionName}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GTrainer data={data} gradeId={data._id} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <GCourse data={data} gradeId={data._id} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <GLessonPlan data={data} gradeId={data._id} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <GSkill data={data} gradeId={data._id} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <GEquipment data={data} gradeId={data._id} />
      </TabPanel>
    </>
  );
}

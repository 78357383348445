import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
} from "@material-ui/core";
// ReactIcons
import { MdEdit } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
import { IoSchoolOutline } from "react-icons/io5";
import { BsFileEarmark } from "react-icons/bs";

//Core Component

import LocoDeleteDialog from "../ActionButtonDialog's/LocomotorActionB/DeleteLocoDialog";
import LocoEdit from "../ActionButtonDialog's/LocomotorActionB/EdiLocomotor/LocoEditMain";
import LocoSwitch from "../ActionButtonDialog's/LocomotorActionB/SwitchLocoDialog";
import AssignSchool from "../ActionButtonDialog's/LocomotorActionB/LocoAssignSchool/LocoSchoolMainDialog";
import AssignGrade from "../ActionButtonDialog's/LocomotorActionB/LocoGradeDialog/LocoGradeMainDialog";
import AssignDivision from "../ActionButtonDialog's/LocomotorActionB/LocoDivisionDialog/LocoDivisionMainDialog";
import { useParams } from "react-router-dom";

export default function LocomotorTab(props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
      searchLoco,
      setSearchLoco,
      pageNoLoco,
      setPageNoLoco,
      totalLoco,
      setTotalLoco,
    },
    TabNext,
    skillsData,
    deleteSkills,
    getAllSkill,
    searchSkill,
  } = props;
  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Skill Name</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {skillsData &&
              skillsData.length > 0 &&
              skillsData.map((skills, index) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {skills.subSkillUid} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: `/admin/skillprofile/${skills._id}`,
                        states: {
                          mainSkill,
                          setMainSkill,
                          subSkillName,
                          setSubSkillName,
                          subSkillUid,
                          setSubSkillUid,
                          subSkillDesc,
                          setSubSkillDesc,
                          xpAssigned,
                          setXpAssigned,
                          level,
                          setLevel,
                          rubricsQts,
                          setRubricsQts,
                          skillsData,
                          skills,
                          searchSkill,
                        },
                        data: { getAllSkill: getAllSkill },
                      }}
                    >
                      {skills.subSkillName}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {skills.subSkillDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <LocoEdit
                        states={{
                          mainSkill,
                          setMainSkill,
                          subSkillName,
                          setSubSkillName,
                          subSkillUid,
                          setSubSkillUid,
                          subSkillDesc,
                          setSubSkillDesc,
                          xpAssigned,
                          setXpAssigned,
                          level,
                          setLevel,
                          rubricsQts,
                          setRubricsQts,
                          skills,
                          skillsData,
                        }}
                        searchSkill={searchSkill}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <AssignSchool />
                    </IconButton> */}
                    <IconButton className="ActionButtonSize">
                      <AssignGrade SkillsId={skills._id} />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <AssignDivision SkillsId={skills._id} />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <LocoSwitch
                        searchSkill={searchSkill}
                        // getAllSkill={getAllSkill}
                        SkillsId={skills._id}
                        access={skills.access}
                      />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {skillsData.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Skill Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>

      {searchLoco || skillsData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => setPageNoLoco(value)}
            pageSize={rowsPerPage}
            total={totalLoco}
            current={pageNoLoco}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";
//Core Component
import ContactTab from "./ContactTab";
import DocumnetTab from "./DocumentTab";
import TrainerDetails from "./TrainerDetails";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TrainerAdd(props) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const TabOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const TabClose = () => {
    setOpen(false);
    setTabValue(0);
    setFullName("");
    setDob(null);
    setRegNo("");
    setEnrollmentDate(null);
    setFathersName("");
    setMothersName("");
    setTrainerEmail("");
    setTrainerPhoneNo("");
    setTrainerLocation("");
    setTrainerAddress("");
    setTrainerZipCode("");
    setTrainerCity("");
    setTrainerState("");
    setTrainerCountry("");
    setGuardianFullName("");
    setGuardianLocation("");
    setGuardianEmail("");
    setGuardingPhoneNo("");
    setGender("");
    setImage("");
    setFile("");
  };
  const [tabvalue, setTabValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function TabNext() {
    setTabValue(1);
  }
  function Tabnext2() {
    setTabValue(2);
  }
  //For CreateInput
  const [fullName, setFullName] = useState("");
  const [dob, setDob] = useState(null);
  const [regNo, setRegNo] = useState("");
  const [enrollmentDate, setEnrollmentDate] = useState(null);
  const [fathersName, setFathersName] = useState(null);
  const [mothersName, setMothersName] = useState(null);
  const [trainerEmail, setTrainerEmail] = useState(null);
  const [trainerPhoneNo, setTrainerPhoneNo] = useState(null);
  const [trainerLocation, setTrainerLocation] = useState(null);
  const [trainerAddress, setTrainerAddress] = useState(null);
  const [trainerZipCode, setTrainerZipCode] = useState(null);
  const [trainerCity, setTrainerCity] = useState(null);
  const [trainerState, setTrainerState] = useState(null);
  const [trainerCountry, setTrainerCountry] = useState(null);
  const [guardianFullName, setGuardianFullName] = useState(null);
  const [guardianLocation, setGuardianLocation] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState(null);
  const [guardingPhoneNo, setGuardingPhoneNo] = useState(null);
  const [gender, setGender] = useState(null);

  const { getAllTrainers, data } = props;
  const gradeName = data?.grade;
  const schoolId = data?.schoolId;
  const gradeId = data?.gradeId;
  const location = data?.location;
  const schoolName = data?.schoolName;
  //Api Calling
  const submitClick = async (e) => {
    e.preventDefault();

    try {
      const responce = await fetch(`${FETCH_URL}/api/learner/createTrainer`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          fullName,
          dob,
          regNo,
          enrollmentDate,
          gender,
          fathersName,
          mothersName,
          trainerEmail,
          trainerPhoneNo,
          trainerLocation,
          trainerAddress,
          trainerZipCode,
          trainerCity,
          trainerState,
          trainerCountry,
          guardianFullName,
          guardianLocation,
          guardianEmail,
          guardianPhoneNo: guardingPhoneNo,
        }),
      });
      let res = await responce.json();
      console.log(res, "this is my response of somth");
      if (!responce.ok) {
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        return;
      }
      let trainerId = res.msg2._id;
      if (trainerId) {
        imageUpload(trainerId);
        console.log("Response after create >> ", res.msg2._id);
      }
      if (responce.ok) {
        console.log("Mah respo =====>", res);

        assignTrainerToGrade(trainerId);

        setSnackOpen(true);
        setSnackMsg(res.msg);

        TabClose();
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  const assignTrainerToGrade = async (trainerId) => {
    try {
      const response1 = await fetch(
        `${FETCH_URL}/api/school/grade/getSingleGrade/${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res1 = await response1.json();

      const response = await fetch(
        `${FETCH_URL}/api/learner/assignTrainerToGrade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            gradeName,
            schoolId,
            gradeId,
            location,
            trainerId,
            schoolName,
            departmentId: res1?.schoolId?.departmentDetails?.departmentId,
            departmentName: res1?.schoolId?.departmentDetails?.departmentName,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("Trainer Assigned ======>", res.msg);

        getAllTrainers(gradeId);

        TabClose();
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();

  const imageUpload = async (trainerId) => {
    const formdata = new FormData();
    formdata.set("trainerId", trainerId);
    formdata.set("regNo", regNo);
    formdata.append("file", image);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/learner/uploadTrainerImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      {gradeId ? (
        <Button className="ButtonTransparent " onClick={TabOpen("paper")}>
          <IoIosAddCircle
            className="MiniAddIcon IconColor"
            onClick={TabOpen("paper")}
          />
          AddNew
        </Button>
      ) : (
        <IconButton className="HeaderAddButton">
          <IoIosAddCircleOutline
            className="AddIcon"
            onClick={TabOpen("paper")}
          />
        </IconButton>
      )}

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={TabClose}
        scroll={scroll}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        {" "}
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={tabvalue}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Trainer Detail"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Contact Detail"
                {...a11yProps(1)}
              />
              {/* <Tab className="TabStyle" label="Documents" {...a11yProps(2)} /> */}

              <BootstrapDialogTitle onClose={TabClose}></BootstrapDialogTitle>
            </Tabs>
          </Box>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle ">
          <TabPanel value={tabvalue} index={0}>
            <TrainerDetails
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                setGender,
                gender,
                image,
                setImage,
                file,
                setFile,
              }}
              TabNext={TabNext}
              imageUpload={imageUpload}
            />
          </TabPanel>
          <TabPanel value={tabvalue} index={1}>
            <ContactTab
              states={{
                fullName,
                setFullName,
                trainerEmail,
                setTrainerEmail,
                trainerPhoneNo,
                setTrainerPhoneNo,
                trainerLocation,
                setTrainerLocation,
                trainerAddress,
                setTrainerAddress,
                trainerZipCode,
                setTrainerZipCode,
                trainerCity,
                setTrainerCity,
                trainerState,
                setTrainerState,
                trainerCountry,
                setTrainerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
              }}
              TabNext2={Tabnext2}
              submitClick={submitClick}
            />
          </TabPanel>
          <TabPanel value={tabvalue} index={2}>
            <DocumnetTab submitClick={submitClick} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import {
  Box,
  Grid,
  Input,
  Button,
  Typography,
  FormControl,
  TextareaAutosize,
  Select,
  MenuItem,
  DialogActions,
} from "@material-ui/core";
// React Icons
import { IoIosAddCircle } from "react-icons/io";

export default function AddSkill(props) {
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const {
    states: {
      uid,
      setUid,
      assessmentName,
      setAssessmentName,
      assessmentDesc,
      setAssessmentDesc,
      assignedXp,
      setAssignedXp,
      level,
      setLevel,
      file,
      setFile,
      image,
      setImage,
      imageUpload,
      rubricsQts,
      setRubricsQts,
    },
    TabNext,
  } = props;
  return (
    <div>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            container
            direction="column"
            item
            style={{ marginLeft: "25px" }}
          >
            <Typography className="SkillsInputTitle  GreyColorTypography">
              Assessment UID<span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="RubicInputs"
                value={uid}
                onChange={(e) => setUid(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Assessment Name <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="RubicInputs"
                value={assessmentName}
                onChange={(e) => setAssessmentName(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Assessment Description <span style={{ color: "red" }}> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <TextareaAutosize
                className="SkillTextArea"
                aria-label="minimum height"
                value={assessmentDesc}
                onChange={(e) => setAssessmentDesc(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="GreyColorTypography   EquipMeadiaTypo  ">
            Media
          </Typography>
          <Grid
            item
            className="AddThumbnilGridStyle"
            style={{ paddingBottom: "80px" }}
          >
            <Typography className=" BlueColorTypography AddThumbnailTypography">
              Add Thumbnail
            </Typography>
            <Typography style={{ marginLeft: "20px", marginBottom: "10px" }}>
              <img
                src={file}
                style={{ height: "100px", width: "94%", marginTop: "10px" }}
              />
            </Typography>
            <Grid item className="Thumbnildiv">
              <input
                style={{ display: "none" }}
                type="file"
                id="raised-button-file"
                accept="image/*"
                onChange={ImageUploader}
              />
              <label htmlFor="raised-button-file">
                <Button
                  className="GreenButton700 SkillsCFB"
                  variant="raised"
                  component="span"
                >
                  <IoIosAddCircle className="AddCircleIcon" />
                  Choose File
                </Button>
              </label>
            </Grid>
          </Grid>

          <Grid item container className="GridPP-levelStyle">
            <Grid container item className=" PPGridStyle">
              <Typography className="BlueColorTypography  PPTypo">
                PP
              </Typography>
              <Input
                disableUnderline
                className="SkilsPPInput"
                value={assignedXp}
                onChange={(e) => setAssignedXp(e.target.value)}
              />
            </Grid>
            <Grid item container className="LevelGridStyle">
              <Typography className="BlueColorTypography  LevelTypo">
                Level
              </Typography>

              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className=" SkillsSelectStyle"
                  onChange={(e) => setLevel(e.target.value)}
                  value={level}
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={10}>
                    XI
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={20}>
                    X
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={30}>
                    Vi
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

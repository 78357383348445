import React, { useState } from "react";

import {
  IconButton,
  FormControl,
  Toolbar,
  Box,
  Typography,
  Input,
  InputAdornment,
} from "@material-ui/core";
// React Icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
// core  component
import ComponentTabs from "./HosUserComponentTabs/ComponentTabs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Conversion(props) {
  ////////////////////////Api Calling//////////////////////////////////////
  const [userDetails, setUserDetails] = useState(null);
  const [departMentDetail, setDepartMentDetails] = useState(null);
  const [value, setValue] = React.useState(0);
  const { userToken } = useLogin();
  const getUserDetails = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/auth/getAllUsers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of HOS UserDetails==>", res);
        setUserDetails(res);
      } else {
        console.log("Error while fetching UserDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  ////////////////////get department/////////////////
  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/auth/getAllDepartments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        setDepartMentDetails(res.msg);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ///////////////////////////searh users and department///////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (value === 0) {
      if (searchValue.length === 0) {
        getUserDetails();
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(
        `${FETCH_URL}/api/auth/getPaginatedUsers?search=${searchValue}&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setUserDetails(res);
        console.log("My searching users response ==>", res);
      } else {
        console.log("Error while searching users", res.err);
      }
    } else {
      if (searchValue.length === 0) {
        getDepartmentDetails();
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(
        `${FETCH_URL}/api/auth/getAllDepartments?search=${searchValue}&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setDepartMentDetails(res.msg);
        console.log("My searching users response ==>", res);
      } else {
        console.log("Error while searching users", res.err);
      }
    }
  };
  ///////////////////////////searh users and department///////////////////////

  React.useEffect(() => {
    getUserDetails();
    getDepartmentDetails();
  }, []);
  ////////////////////////Api Calling//////////////////////////////////////

  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Users</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); //
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
        </div>
      </Toolbar>

      <ComponentTabs
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        departMentDetail={departMentDetail}
        setDepartMentDetails={setDepartMentDetails}
        value={value}
        setValue={setValue}
      />
    </>
  );
}

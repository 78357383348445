import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Input,
  IconButton,
  Typography,
  Button,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// React Icons
import { IoIosAddCircle } from "react-icons/io";

export default function Details(props) {
  const [datevalue, setDateValue] = useState(null);
  const {
    states: {
      fullName,
      setFullName,
      dob,
      setDob,
      regNo,
      setRegNo,
      enrollmentDate,
      setEnrollmentDate,
      fathersName,
      setFathersName,
      mothersName,
      setMothersName,
      setGender,
      gender,
      image,
      setImage,
      file,
      setFile,
    },
    TabNext,
    trainer,
  } = props;

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  return (
    <>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} direction="column">
          <Grid container direction="column" item style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography  GreyColorTypography">
              Full Name
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={trainer.trainerDetail.fullName}
                onChange={(e) => setFullName(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              UID
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={regNo}
                defaultValue={trainer.trainerDetail.regNo}
                disabled
              ></Input>
            </Grid>

            <Typography className="InputTitleTypography GreyColorTypography">
              Enrollement Date<span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="MM/dd/yyyy"
                    value={
                      enrollmentDate
                        ? enrollmentDate
                        : trainer.trainerDetail.enrollmentDate
                    }
                    onChange={(e) => setEnrollmentDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Gender
            </Typography>
            <Grid item md={10} sm={10} xs={10}>
              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className=" SkillsSelectStyle "
                  value={gender ? gender : trainer.trainerDetail.gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  color="white"
                  label="Select Gender"
                  disableUnderline
                >
                  <MenuItem disabled className="Selectmenustyle" value={0}>
                    Select Gender
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value="Male">
                    Male
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value="Female">
                    Female
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value="Other">
                    Other
                  </MenuItem>
                </Select>
              </FormControl>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Date of Birth<span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="MM/dd/yyyy"
                    value={dob ? dob : trainer.trainerDetail.dob}
                    onChange={(e) => setDob(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Father's Name
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={trainer.trainerDetail.fathersName}
                onChange={(e) => setFathersName(e.target.value)}
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Mother's Name
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                defaultValue={trainer.trainerDetail.mothersName}
                onChange={(e) => setMothersName(e.target.value)}
              ></Input>{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <Grid
            item
            container
            md={11}
            sm={11}
            xs={11}
            style={{
              borderRadius: "15px",
              border: "1.2px solid #d4d4d4",
              marginTop: "3%",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography className=" BlueColorTypography AddLogoTypography">
                Add Image{" "}
                <img
                  src={file}
                  style={{
                    height: "250px",
                    width: "340px",
                    objectFit: "contain",
                    marginTop: "10px",
                  }}
                />
              </Typography>
              <Grid item className="ChooseFileButtonItem">
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/*"
                  onChange={ImageUploader}
                />{" "}
              </Grid>{" "}
            </div>
            <label htmlFor="raised-button-file">
              <Button
                style={{ marginTop: "10px" }}
                className="GreenButton700 ASTChooseFileButton"
                variant="raised"
                component="span"
              >
                <IoIosAddCircle size="1.6em" style={{ marginRight: "4px" }} />{" "}
                Choose File
              </Button>
            </label>{" "}
          </Grid>

          <DialogActions className="">
            <Button
              className="GreenButton700 EquipActionBottom"
              style={{ marginTop: "45%", marginRight: "6%" }}
              autoFocus
              onClick={TabNext}
            >
              Next
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

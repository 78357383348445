import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import { IoIosAddCircle } from "react-icons/io";

export default function SubSkills(props) {
  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
      image,
      setImage,
      file,
      setFile,
    },
    TabNext,
    CreateSkills,
    imageUpload,
  } = props;
  return (
    <>
      <form>
        <Grid container className="DailogLTRBStyle">
          <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
            <Grid
              item
              container
              direction="column"
              style={{ marginLeft: "25px" }}
            >
              <Typography className="SkillsInputTitle  GreyColorTypography">
                Skill <span className="ImpColor"> *</span>
              </Typography>
              <Grid container item style={{ width: "67%" }}>
                <FormControl variant="standard" className="FormControlInput">
                  <Select
                    className=" SkillsSelectStyle "
                    value={mainSkill ? mainSkill : 0}
                    onChange={(e) => {
                      setMainSkill(e.target.value);
                    }}
                    color="white"
                    label="Select Skills"
                    disableUnderline
                  >
                    <MenuItem disabled className="Selectmenustyle" value={0}>
                      Select skills
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value="Locomotor">
                      Locomotor
                    </MenuItem>
                    <MenuItem
                      className="Selectmenustyle"
                      v
                      value="Body Management"
                    >
                      Body Management
                    </MenuItem>
                    <MenuItem
                      className="Selectmenustyle"
                      value="Object Control"
                    >
                      Object Control
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Typography className="SkillsInputTitle  GreyColorTypography">
                Sub Skill UID<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="RubicInputs"
                  value={subSkillUid}
                  onChange={(e) => setSubSkillUid(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="SkillsInputTitle GreyColorTypography">
                Sub Skill Name<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="RubicInputs"
                  value={subSkillName}
                  onChange={(e) => setSubSkillName(e.target.value)}
                  onKeyDown={handleEnter}
                ></Input>
              </Grid>
              <Typography className="SkillsInputTitle GreyColorTypography">
                Sub Skill Description<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="SkillTextArea"
                  aria-label="minimum height"
                  value={subSkillDesc}
                  onChange={(e) => setSubSkillDesc(e.target.value)}
                  onKeyDown={handleEnter}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
            <Typography className="GreyColorTypography   EquipMeadiaTypo  ">
              Media
            </Typography>
            <Grid item className="AddThumbnilGridStyle">
              <Typography className=" BlueColorTypography AddThumbnailTypography">
                Add Thumbnail
              </Typography>
              <Typography style={{ marginLeft: "20px", marginBottom: "10px" }}>
                <img
                  src={file}
                  style={{ height: "100px", width: "94%", marginTop: "10px" }}
                />
              </Typography>
              <Grid item className="Thumbnildiv">
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/*"
                  onChange={ImageUploader}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    className="GreenButton700 SkillsCFB"
                    variant="raised"
                    component="span"
                  >
                    <IoIosAddCircle className="AddCircleIcon" />
                    Choose File
                  </Button>
                </label>
              </Grid>
              {/* <Typography className=" BlueColorTypography AddThumbnailTypography">
                Add Thumbnail
              </Typography>

              <div className="Thumbnildiv">
                <div>
                  <Button className="GreenButton700 SkillsCFB">
                    <IoIosAddCircle className="CFButtonIcon" /> Choose File
                  </Button>
                </div>
              </div> */}
            </Grid>
            {/* <Grid item container className="GridPP-levelStyle">
              <Grid container item className=" PPGridStyle">
                <Typography className="BlueColorTypography  PPTypo">
                  PP
                </Typography>
                <Input
                  disableUnderline
                  className="SkilsPPInput"
                  value={xpAssigned}
                  onChange={(e) => setXpAssigned(e.target.value)}
                />
              </Grid>
              <Grid item container className="LevelGridStyle">
                <Typography className="BlueColorTypography  LevelTypo">
                  Level
                </Typography>

                <FormControl variant="standard" className="FormControlInput">
                  <Select
                    className=" SkillsSelectStyle"
                    value={level ? level : 0}
                    onChange={setLevel}
                    color="white"
                    disableUnderline
                  >
                    <MenuItem className="Selectmenustyle" value={10}>
                      XI
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={20}>
                      X
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={30}>
                      Vi
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}
          </Grid>
          <DialogActions className="DialogActionBottom ">
            <Button
              className="GreenButton700   RubricsActionButton "
              onClick={TabNext}
            >
              Next
            </Button>
            <Button
              className="GreyButton700   RubricsActionButton "
              onClick={CreateSkills}
            >
              Cancel
            </Button>
          </DialogActions>
        </Grid>
      </form>
    </>
  );
}

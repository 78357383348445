import React, { useState, useEffect } from "react";
import axios from "axios";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import PropTypes from "prop-types";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Switch,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core";
// React Icons

export default function OrgnasationTab(props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    states: { searchO, pageO, setPageO, totalO, setTotalO },
    departMentDetail,

    handleSearch,
  } = props;

  const onShowSizeChange = (current, pageSize) => {
    console.log("pageSize111111", pageSize);
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">Organisation</TableCell>
              <TableCell className="Head">Number of Schools</TableCell>
              <TableCell className="Head">Point of Contact</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departMentDetail &&
              Object.keys(departMentDetail).length > 0 &&
              departMentDetail.msg.map((dptData) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {dptData.departmentName} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {dptData.associatedSchool?.length}
                  </TableCell>
                  <TableCell className="Col">
                    <Link>{dptData.pointOfContact} </Link>
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize"></IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {departMentDetail.msg.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Organisation Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {searchO || departMentDetail?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              setPageO(value);
              handleSearch("", value, value2);
            }}
            pageSize={rowsPerPage}
            total={totalO}
            current={pageO}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  MenuItem,
  makeStyles,
  Select,
  Breadcrumbs,
  TextareaAutosize,
  IconButton,
  Grid,
  Input,
  Typography,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// @matertial-ui/core  Icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
//core component
import AssignSkills from "./CourseSkillAssign";
import AssignEquipment from "./CourseEquipmentAss";
import { useLocation } from "react-router-dom";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
const useStyles = makeStyles((theme) => ({}));

export default function ExtendedTables() {
  const classes = useStyles();

  const data = useLocation();
  const { userToken } = useLogin();
  const editCoursedata = data;
  const courseData = editCoursedata.state.post;
  const getAllCourses = editCoursedata.data.loadUsers;
  console.log(getAllCourses, "getAllCourses>>>>>>>>>>>>>>>its here");
  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };
  //For CreateInput
  const [courseUid, setCourseUid] = useState(null);
  const [courseTitle, setCourseTitle] = useState(null);
  const [courseDuration, setCourseDuration] = useState(null);
  const [coursePrice, setCoursePrice] = useState(null);
  const [courseDesc, setCourseDesc] = useState(null);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [open, setOpen] = React.useState(false);
  //////////////////////////////////////////////////
  const [skills, setSkills] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [lessonPlans, setLessonPlans] = useState(null);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  const history = useHistory();
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DialogClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    // console.log(open);
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const imageUpload = async (ID) => {
    console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("courseId", ID);
    formdata.append("file", image);
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadCourseThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const submitClick = async (e) => {
    // For Editing
    e.preventDefault();
    const response = await fetch(
      `${FETCH_URL}/api/lessonplan/course/editCourse/${courseData._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          // courseUid: courseUid ? courseUid : courseData.courseUid,
          courseTitle: courseTitle ? courseTitle : courseData.courseTitle,
          courseDuration: courseDuration
            ? courseDuration
            : courseData.courseDuration,
          coursePrice: coursePrice ? coursePrice : courseData.coursePrice,
          courseDesc: courseDesc ? courseDesc : courseData.courseDesc,
        }),
      }
    );

    const res = await response.json();
    console.log(res, "yo i am res man");
    let courseId = courseData._id;
    if (courseId) {
      imageUpload(courseId);
      console.log("Response after create >> ", courseId);
    }
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      getAllCourses();
      setSnackOpen(true);
      setSnackMsg(res.msg);
      setOpen(false);
      history.goBack("/admin/course");
      // setSnackOpen(true);
      // setSnackMsg(res.msg);
      // setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
      setSnackerropen(true);
      setSnackErrMsg(res.err);
      // setSnackerropen(true);
      // setSnackErrMsg(res.err);
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div>

      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Courses{" "}
          </Button>
          <div className="Breadcrumb2">New Course</div>
        </Breadcrumbs>
      </div>

      <Grid
        container
        spacing={3}
        style={{
          marginBottom: "28px",
        }}
      >
        <Grid
          item
          container
          style={{
            backgroundColor: "white",
            marginTop: "20px",
            marginRight: "4%",
            marginLeft: "39px",
          }}
        >
          {/* <Grid item container>
            <Grid itemmd={3} sm={3} xs={3} lg={3}>
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={school ? school : 0}
                  onChange={handleChange}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select school
                  </MenuItem>

                  <MenuItem className="Selectmenustyle" value={10}>
                    Blue Ridge Public School
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={20}>
                    Zoomer Public School
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={30}>
                    Ridge Public School
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={location ? location : 0}
                  onChange={locChange}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Location
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={10}>
                    Pune
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={20}>
                    Delhi
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={30}>
                    Mumbai
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container itemmd={4} sm={4} xs={4} lg={4}>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              lg={12}
              direction="column"
              style={{
                marginLeft: "18px",
              }}
            >
              <Typography className="InputTitle">Course UID</Typography>
              <Input
                defaultValue={courseData.courseUid}
                value={courseUid}
                onChange={(e) => setCourseUid(e.target.value)}
                disabled
                disableUnderline
                className="InputStyle"
              />

              <Typography className="InputTitle">Course Title</Typography>
              <Input
                defaultValue={courseData.courseTitle}
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
                disableUnderline
                className="InputStyle"
              />
              <Typography className="InputTitle">Course Duration</Typography>
              <Input
                defaultValue={courseData.courseDuration}
                value={courseDuration}
                onChange={(e) => setCourseDuration(e.target.value)}
                disableUnderline
                className="InputStyle2"
              />
              <Typography className="InputTitle">Course Score</Typography>
              <Input
                defaultValue={courseData.coursePrice}
                value={coursePrice}
                onChange={(e) => setCoursePrice(e.target.value)}
                disableUnderline
                className="InputStyle2"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={4}
            sm={4}
            xs={4}
            lg={4}
            style={{ marginTop: "10px" }}
          >
            <Grid container md={11} sm={11} xs={11} lg={11} direction="column">
              <div>
                <Typography className="TypographyTitle">
                  Course Description
                </Typography>
                <div>
                  <TextareaAutosize
                    className="TextArea"
                    aria-label="minimum height"
                    defaultValue={courseData.courseDesc}
                    value={courseDesc}
                    onChange={(e) => setCourseDesc(e.target.value)}
                  />
                </div>
              </div>
              <Typography
                style={{ marginTop: "3px" }}
                className="TypographyTitle"
              >
                Add Thumbnail
                <img
                  src={file}
                  style={{ height: "100px", width: "100%", marginTop: "10px" }}
                />
              </Typography>
              <Grid item className="ChooseFileButtonItem">
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/*"
                  onChange={ImageUploader}
                />{" "}
                <label htmlFor="raised-button-file">
                  <Button
                    className="GreenButton CourseCFButton "
                    variant="raised"
                    component="span"
                  >
                    <IoIosAddCircle className="CFButtonIcon" />
                    Choose File
                  </Button>
                </label>
              </Grid>
              <div className="AddCourseCenter">
                <div>
                  <IconButton
                    className=" GreenButton700 CourseAddButton"
                    onClick={submitClick}
                  >
                    <IoIosAddCircle className="CFButtonIcon" />
                    Add Course
                  </IconButton>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <Grid
            item
            container
            md={4}
            sm={4}
            xs={4}
            lg={4}
            style={{ marginTop: "10px" }}
          >
            <Grid
              item
              container
              direction="column"
              md={11}
              sm={11}
              xs={11}
              lg={11}
            >
              <Typography className="TypographyTitle">Add Skills</Typography>
              <div className="Thumbnailform ">
                <AssignSkills
                  states={{
                    skills,
                    setSkills,
                  }}
                />
              </div>
              <Typography
                style={{ marginTop: "8px" }}
                className="TypographyTitle"
              >
                Sub-Skills Tags
              </Typography>
              <div className=" Thumbnailform ">
                <AssignEquipment
                  states={{
                    equipment,
                    setEquipment,
                  }}
                />
              </div>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Box,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  IconButton,
  FormControl,
  DialogActions,
  Select,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//Recact Icons
import { IoIosAddCircle } from "react-icons/io";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function User({
  handleEnter,
  open,
  setOpen,
  DialogClose,
  getDepartmentDetails,
  setSnackOpen,
  setSnackMsg,
  setSnackerropen,
  setSnackErrMsg,
}) {
  // const [open, setOpen] = React.useState(false);

  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };

  const { userToken } = useLogin();

  const [uid, setUid] = useState(null);
  const [pointOfContact, setPointOfContact] = useState(null);
  const [phoneno, setPhoneNo] = useState(null);
  const [address, setAddress] = useState(null);
  const [departmentName, setDepartment] = useState(null);
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [email, setEmail] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  // const [departmentId, setDepartmentId] = useState(null);
  const [location, setLocation] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  const [error, setError] = useState();

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const imageUpload = async (departmentId) => {
    const formdata = new FormData();
    formdata.set("departmentId", departmentId);
    formdata.set("uid", uid);
    formdata.append("file", image);

    try {
      const responce = await fetch(`${FETCH_URL}/api/auth/addDepartmentImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
        getDepartmentDetails();
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const submitClick = async (e) => {
    e.preventDefault();
    console.log("departmentName", departmentName);
    if (!isEmail(email)) {
      setError("invalid email");
    } else {
      setError("");
      const responce = await fetch(`${FETCH_URL}/api/auth/addDepartment`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          departmentName,
          pointOfContact,
          phoneno,
          address,
          uid,
          location,
          dateOfJoining,
          emailId: email,
          zipCode,
          city,
          state,
          country,
        }),
      });
      let res = await responce.json();
      let departmentId = res.data;
      if (departmentId) {
        imageUpload(departmentId);
        console.log("Response after create >> ", res.data);
      }
      if (responce.ok) {
        getDepartmentDetails();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        DialogClose();
      } else {
        // setSnackOpen(true);
        console.log("errorrrrrrrrrr", res);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    }
  };

  // const getAllSchoolData = async (schoolName, location) => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/school/getAllSchools?schoolName/${schoolName}?location/${location}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",

  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response of SchoolData==>", res);
  //       setSchoolData(res.msg);
  //     } else {
  //       console.log("Error while fetching trainers", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };

  //  const newData = schoolData && schoolData.length>0 && schoolData[0]._id

  // const newData = schoolData && schoolData.length > 0 && schoolData[0]._id;
  // React.useEffect(() => {
  //   if (departmentName.length > 0 && location.length > 0) {
  //     getAllSchoolData(departmentName, location);
  //   }
  // }, [departmentName, location]);

  const [postCodeMsg, setPostCodeMsg] = React.useState("");

  const searchByPostalCode = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    console.log(response, "response");
    if (response.ok) {
      const Postaldata = res;
      console.log(res, "resres");
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setPostCodeMsg("please enter a valid post code!");
        setCity("");
        setState("");
        setCountry("");
        return;
      }
      setPostCodeMsg("");
      setCity(Postaldata?.map((a) => a?.PostOffice[0].District)[0]);
      setState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setCountry(Postaldata?.map((a) => a?.PostOffice[0].Country)[0]);
    }
  };
  React.useEffect(() => {
    if (String(zipCode).length === 6) {
      searchByPostalCode(zipCode);
    } else {
      console.log("iiiiiiiiiiiiiiiiiii");
    }
    if (String(zipCode).length < 6) {
      setCity("");
      setState("");
      setCountry("");
    }
  }, [zipCode]);

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid container spacing={4} justify="flex-start">
          <Grid item md={6} sm={6} xs={6} lg={6}>
            <Grid
              container
              direction="column"
              item
              style={{ marginLeft: "8%" }}
            >
              <Typography className="UserTitleTypography GreyColorTypography">
                Organisation
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={departmentName}
                  onChange={(e) => setDepartment(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Point Of Contact(POC)<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={pointOfContact}
                  onChange={(e) => setPointOfContact(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Mobile Number <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  type="number"
                  value={phoneno}
                  onChange={(e) => setPhoneNo(e.target.value.slice(0, 10))}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Address <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="UserTextArea"
                  aria-label="minimum height"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid
                item
                container
                md={11}
                sm={11}
                xs={11}
                style={{
                  borderRadius: "15px",
                  // border: "1.2px solid #d4d4d4",
                  marginTop: "3%",
                }}
              >
                {" "}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Typography className=" BlueColorTypography ">
                    Add Image{" "}
                    <img
                      src={file}
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "contain",
                      }}
                    />
                  </Typography>
                  <Grid item className="ChooseFileButtonItem">
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="raised-button-file"
                      accept="image/*"
                      onChange={ImageUploader}
                    />
                  </Grid>
                </div>
                <label htmlFor="raised-button-file">
                  <Button
                    style={{ marginTop: "10px" }}
                    className="GreenButton700 "
                    variant="raised"
                    component="span"
                  >
                    <IoIosAddCircle
                      size="1.6em"
                      style={{ marginRight: "4px" }}
                    />{" "}
                    Choose File
                  </Button>
                </label>{" "}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
            <Grid container direction="column" item>
              <Typography className="UserTitleTypography GreyColorTypography">
                UID
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onChange={(e) => setUid(e.target.value)}
                  value={uid}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Location
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                ></Input>
              </Grid>

              <Typography className="UserTitleTypography GreyColorTypography">
                Date of joining
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      format="yyyy/MM/dd"
                      value={dateOfJoining}
                      onChange={(e) => {
                        setDateOfJoining(e);
                      }}
                      onKeyDown={handleEnter}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Email Id
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={email}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setError("");
                    } else if (!isEmail(e.target.value)) {
                      setError("invalid email");
                    } else {
                      setError("");
                    }
                    setEmail(e.target.value);
                  }}
                >
                  {" "}
                </Input>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {error}
                </span>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Zip Code <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value.slice(0, 6))}
                >
                  {" "}
                </Input>
                {postCodeMsg.length > 0 && (
                  <Typography className="warningTypography">
                    {postCodeMsg}
                  </Typography>
                )}
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                City <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                State <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Country <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions className="">
        <Button
          className="GreenButton700  EquipActionBottom  mr1"
          onClick={submitClick}
        >
          Save
        </Button>
        {/* <Button
          className="GreyButton700 EquipActionBottom mr2-7"
          // onClick={handleClose}
        >
          Cancel
        </Button> */}
      </DialogActions>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import { DropzoneArea } from "material-ui-dropzone";

//React-Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsUpload } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";

import { IoIosAddCircle } from "react-icons/io";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Upload Agreement
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UploadAgreement({
  schoolId,
  schoolAgreement,
  setSchoolAgreement,
  schoolUId,
}) {
  const [agreement, setAgreement] = useState({});

  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(120),
      // height: theme.spacing(27),
    },
  }));

  const handleFileChange = (files) => {
    if (files.length > 0) {
      setAgreement(files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAgreement({});
  };

  const AgreementUpload = async () => {
    if (!agreement) {
      setSnackerropen(true);
      setSnackErrMsg("Choose Agreement File");
    }
    const formdata = new FormData();
    formdata.set("schoolId", schoolId);
    formdata.append("file", agreement);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/school/schoolAgreementS3`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
        getSingleSchoolAndUpdateDocsData();
        console.log("I am the response after Agreement upload >>", responce);
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        handleClose();
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const getSingleSchoolAndUpdateDocsData = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getSingleSchool/${schoolUId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setSchoolAgreement(res.msg.agreement);
      }
    } catch (error) {}
  };

  const [scroll, setScroll] = React.useState("paper");
  const [rowData, setRowData] = useState({});

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const { userToken } = useLogin();

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Button
        className="ButtonTransparent"
        onClick={handleClickOpen}
        style={{ paddingTop: "10px" }}
      >
        {/* <IconButton> */}
        <BsUpload className="UploadIcon MiniAddIcon" />
        {/* </IconButton> */}
        Upload Agreement
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid
            container
            justify="center"
            spacing={4}
            className="DailogLTRBStyle"
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
              lg={6}
              direction="column"
              style={{ marginTop: "1px" }}
            >
              <DropzoneArea
                filesLimit={1}
                maxFileSize={2097152}
                // acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
                acceptedFiles={["application/pdf"]}
                onChange={handleFileChange}
                useChipsForPreview={true}
                dropzoneText="Upload Agreement"
                multiple={false}
              />
              <Typography className="ALAddSkilltypography">
                Support only .pdf format (max file size 2 MB)
              </Typography>
              <Typography className="ALAddSkilltypography">
                You will lose your current agreement
              </Typography>
              {agreement && (
                <Typography className="ALAddSkilltypography">
                  {agreement?.path}
                </Typography>
              )}

              <DialogActions>
                <Button
                  type="submit"
                  className="SumbitButton"
                  onClick={AgreementUpload}
                >
                  Upload
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>{" "}
        {/* <DialogActions className="">
          <Button
            type="submit"
            className="GreenButton "
            style={{ padding: "3px 20px" }}
            onClick={submitClick}
          >
            Upload
          </Button>
          <Button className="GreyButton GradeCancelB" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

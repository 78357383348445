import Dashboard from "principalviews/PrincipalDashboard/Dashboard";
import SchoolProfile from "principalviews/PrincipalSchool/PrincipalSchool/PSchoolProfile/SchoolDetails";
import Grade from "principalviews/PrincipalSchool/PrincipalGrade/Grade";
import GradeProfile from "principalviews/PrincipalSchool/PrincipalGrade/PGradeProfile's/GradeProfieMainPage";
import Trainer from "principalviews/PrincipalSchool/PrincipalTrainer/Trainer";
import Learner from "principalviews/PrincipalSchool/PrincipalLearner/Learner";
// import LearnerProfile from "principalviews/PrincipalSchool/PrincipalLearner/PLearnerProfile/LearnerProfile.";
import Course from "principalviews/PrincipalCourse/Course";
import CourseProfile from "principalviews/PrincipalCourse/PCourseProfile/CourseProfile";
import Lesson from "principalviews/PrincipalLessonPlan/LessonPlans";
import Skill from "principalviews/PrincipalSkills/PSkills";
import Equipments from "principalviews/PrincipalEquipments/Eqipments";
import EquipmentProfile from "principalviews/PrincipalEquipments/PEquipmentProfile/EquipmentProfile";
import KitProfile from "principalviews/PrincipalEquipments/PKitProfile/EquipmentKitProfile";
import Assessment from "principalviews/PrincipalAssesments/Assesments";
import AssesmentProfile from "principalviews/PrincipalAssesments/AssesmentProfile/AssesmentProfile";
import QuizResult from "principalviews/PrincipalAssesments/ResultProfile/QuizProfile/QuizProfile";
import ActivityResult from "principalviews/PrincipalAssesments/ResultProfile/RActivityProfile/ActivityProfile";
import AssesmentResult from "principalviews/PrincipalAssesments/ResultProfile/RAssesmentProfile/AssesProfile";
import Events from "principalviews/PrincipalEvent/PrincipalEvents";
import User from "principalviews/PrincipalUsers/User";
import UserProfile from "principalviews/PrincipalUsers/PrincipalUserProfile/UserProfile";
import LessonPlanProfile from "./principalviews/PrincipalLessonPlan/PrincipalLPProfile/LessonPlanProfile";
//Other Core Component
import SignIn from "views/SignIn/SignIn";
//ReactIcons
import { FaSchool } from "react-icons/fa";
import { IoBody, IoSchoolOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { GiTeacher, GiSoccerBall } from "react-icons/gi";
import { MdOutlineLibraryBooks, MdDashboard } from "react-icons/md";
import { BiBook } from "react-icons/bi";
import { BsFileEarmark, BsBook } from "react-icons/bs";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import MessageIcon from "@material-ui/icons/Message";
import { MdOutlineBook } from "react-icons/md";
import { BsBarChartLine } from "react-icons/bs";
import { BsTrophy } from "react-icons/bs";

const principalRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: MdDashboard,
    component: Dashboard,
    layout: "/principal",
  },
  {
    collapse: true,
    rtlName: "المكونات",
    icon: FaSchool,
    name: "School",
    state: "componentsCollapse",
    views: [
      {
        path: "/school",
        name: "School",
        rtlName: "وصفت",
        icon: IoSchoolOutline,
        rtlMini: "ب",
        component: SchoolProfile,
        layout: "/principal",
      },
      {
        path: "/grade",
        name: "Grade",
        icon: BsFileEarmark,
        component: Grade,
        layout: "/principal",
      },
      {
        invisible: true,
        path: "/gradeprofile",
        component: GradeProfile,
        layout: "/principal",
      },

      {
        path: "/learner",
        name: "Learner",
        rtlName: "الحلو تنبيه",
        icon: AiOutlineUser,
        rtlMini: "ومن",
        component: Learner,
        layout: "/principal",
      },
      {
        path: "/trainer",
        name: "Trainer",
        rtlName: "إخطارات",
        icon: GiTeacher,
        rtlMini: "ن",
        component: Trainer,
        layout: "/principal",
      },
    ],
  },
  {
    path: "/course",
    name: "Courses",
    rtlName: "الرسوم البيانية",
    icon: BsBook,
    component: Course,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/courseprofile",
    component: CourseProfile,
    layout: "/principal",
  },
  {
    path: "/Lesson",
    name: "Lesson Plans",
    rtlName: "الرسوم البيانية",
    icon: BiBook,
    component: Lesson,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/lessonplanprofile",
    component: LessonPlanProfile,
    layout: "/principal",
  },
  {
    path: "/skills",
    name: "Skills",
    rtlName: "التقويم",
    icon: BsBarChartLine,
    component: Skill,
    layout: "/principal",
  },

  {
    path: "/equipments",
    name: "Equipments",
    rtlName: "الحاجيات",
    icon: GiSoccerBall,
    component: Equipments,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/equipmentprofile",
    component: EquipmentProfile,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/equipmentkitprofile",
    component: KitProfile,
    layout: "/principal",
  },
  {
    path: "/assesments",
    name: "Assesments",
    rtlName: "الحاجيات",
    icon: MdOutlineBook,
    component: Assessment,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/assesmentprofile",
    component: AssesmentProfile,
    layout: "/principal",
  },

  {
    invisible: true,
    path: "/resultquizprofile",
    component: QuizResult,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/resultactivityprofile",
    component: ActivityResult,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/resultassessprofile",
    component: AssesmentResult,
    layout: "/principal",
  },
  {
    path: "/events",
    name: "Events",
    icon: BsTrophy,
    component: Events,
    layout: "/principal",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleOutlineRoundedIcon,
    component: User,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/userprofile",
    component: UserProfile,
    layout: "/principal",
  },
  {
    invisible: true,
    path: "/signin",
    name: "SignIn",
    rtlName: "الحاجيات",
    icon: MessageIcon,
    component: SignIn,
    layout: "/auth",
  },
];
export default principalRoutes;

import * as React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { IoSchoolOutline } from "react-icons/io5";

//React Icons
import { BsFileEarmark } from "react-icons/bs";
//core Module
import SelectGrade from "./AssesGradeTab/ASelectAssesTab";
import LPRename from "./AssesGradeTab/AssesRenameTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ assesmnetId, getAllAssesmentLearner }) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);
  ////////////////////////////////////////////////////////////////////
  const [gradeData, setGradeData] = React.useState(null);
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [originalData, setOriginalData] = React.useState([]);

  ////////////////////////////////////////////////////////////////////
  const [assestmentUid, setAssestmentUid] = React.useState("");
  const [assestmentTitle, setAssestmentTitle] = React.useState("");
  const [assestmentStartDate, setAssestmentStartDate] = React.useState(null);
  const [assestmentEndate, setAssestmentEndate] = React.useState(null);
  /////////////////////////////////////////////////////////////////////////
  ///////////
  const [selectGradeText, setSelectGradeText] = React.useState("");
  const [assesmentUidText, setAssesmentUidText] = React.useState("");
  const [assesmentTitleText, setAssesmentTitleText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const { userToken } = useLogin();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setAssestmentUid("");
    setAssestmentTitle("");

    setSelectGradeText("");
    setAssesmentUidText("");
    setAssesmentTitleText("");
    setAssestmentStartDate(null);
    setAssestmentEndate(null);
    setValue(0);
  };

  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    if (filterdata.length === 0) {
      setSelectGradeText("please select a grade!");

      return;
    } else {
      setSelectGradeText("");
    }
    setValue(newValue);
  };
  function TabNext() {
    if (filterdata.length === 0) {
      setSelectGradeText("please select a grade!");

      return;
    } else {
      setSelectGradeText("");
    }
    setValue(1);
  }
  // const submitClick = async (e) => {
  //   console.log("StudentUID----------");
  //   e.preventDefault();
  // };
  //////////////////////////////////////////////////////////////////////////////////////////

  function storeGrade(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  /////////////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  //////////////////////////////////////////////////////////////////////
  // console.log(filterdata, "filter data");
  const grades = filterdata.map((a) => {
    return {
      _id: a._id,
      schoolId: a.schoolId,
      schoolName: a.schoolName,
      location: a.location,
      grade: a.grade,
    };
  });

  const divisions = filterdata.map((data) => {
    return data.divisions.map((datas) => {
      return {
        _id: datas._id,
        gradeId: datas.grade,
        gradeName: datas.gradeName,
        schoolId: datas.school,
        schoolName: datas.schoolName,
        location: datas.location,
        grade: datas.gradeName,
      };
    });
  });

  // console.log(grades, "grades>>>>>>>>>>>>>>>");
  const assignedLearners = filterdata.map((a) => a.assignedLearners).flat();
  const assignedTrainers = filterdata
    .map((a) => a.assignedTrainersWithPushNotificationTokens)
    .flat();
  // const assignedTrainers = filterdata.map((a) => a.assignedTrainers).flat();
  console.log(assignedLearners, "assignedLearners>>>>>>>>>>>>>>");
  console.log(assignedTrainers, "assignedTrainers>>>>>>>>>>>>>>");

  ///////////////////get DropDown Schools////////////////////////////////
  const getDropDownSchools = async (location) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My DropDown schools ============>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      // .error("Catch block ====>", error);
    }
  };
  ///////////////////get DropDown Schools////////////////////////////////
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  ///////////////////////////////////////////////////////////////////////
  const getAllGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////////////////////
  const submitClick = async (e) => {
    e.preventDefault();
    ///////
    if (
      assestmentUid === null ||
      assestmentUid.length === 0 ||
      (assestmentUid === undefined && assestmentTitle === null) ||
      assestmentTitle === undefined ||
      assestmentTitle.length === 0
    ) {
      if (assestmentUid.length === 0) {
        setAssesmentUidText("please enter assesment uid !");
      }

      if (assestmentTitle.length === 0) {
        setAssesmentTitleText("please enter assesment title !");
      }
    }
    if (assestmentUid.length === 0 || assestmentTitle.length === 0) {
      return;
    }

    /////
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/assignAssessmentToGrade/${assesmnetId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            uid: assestmentUid,
            assessmentName: assestmentTitle,
            grades,
            divisions: divisions?.flat(),
            assignedLearners,
            assignedTrainers,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My course assign grade Post response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllAssesmentLearner();
        handleClose();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAllGrade();
    getDistinctSchoolAndLocation(); // for dropdowns
  }, []);
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Grade">
        <IconButton className="ActionButtonSize">
          <BsFileEarmark
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Select Grade" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectGrade
              states={{
                gradeData,
                schoolLocationSelected,
                setSchoolLocationSelected,
                schoolLoc,
                schoolNames,
                setSchoolSelected,
                schoolSelected,
                originalData,
                selectGradeText,
              }}
              filterdata={filterdata}
              storeGrade={storeGrade}
              getDropDownSchools={getDropDownSchools}
              getDistinctSchoolAndLocation={getDistinctSchoolAndLocation}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LPRename
              states={{
                assestmentUid,
                setAssestmentUid,
                assestmentStartDate,
                setAssestmentStartDate,
                assestmentEndate,
                setAssestmentEndate,
                assestmentTitle,
                setAssestmentTitle,
                assesmentTitleText,
                assesmentUidText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

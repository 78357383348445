import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Button,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

//ReactIcons
import { IoCloseSharp } from "react-icons/io5";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleSmall" {...other}>
      <Typography className="DialogTitleTypographySmall">
        Are you sure you want to disable this item?{" "}
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const label = { inputProps: { "aria-label": "Switch demo" } };

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  const { schoolId, access, Row, data, getAllSchool, handleSearch } = props;
  const { userToken } = useLogin();
  const [open, setOpen] = React.useState(false);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const changeSchoolStatus = async (schoolId, access) => {
    console.log("Access Status", data);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/changeSchoolStatus/${schoolId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ status: access === 0 ? 1 : 0 }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My Grade status response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        // handleSearch();
        getAllSchool();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  const StatusChange = () => {
    changeSchoolStatus(schoolId, access);
    setOpen(false);
  };
  const SwitchClick = () => {
    setOpen(true);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose}>{snackmsg}</Alert>
      </Snackbar>
      <Snackbar
        severity="error"
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Swicth">
        <IconButton className="ActionButtonSize">
          <Switch
            {...label}
            className="SwitchStyle"
            open={open}
            checked={access === 1 ? true : false}
            onChange={() => {
              SwitchClick();
            }}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogActions>
          <Button
            className="GreyButton  SwitchCancelB"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton  SwitchOkB"
            autoFocus
            onClick={() => {
              StatusChange();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Typography,
  IconButton,
} from "@material-ui/core";

//Core Component
import LocoDeleteDialog from "../ActionButtonDialog's/LocomotorActionB/DeleteLocoDialog";
import LocoEdit from "../ActionButtonDialog's/LocomotorActionB/EdiLocomotor/LocoEditMain";
import LocoSwitch from "../ActionButtonDialog's/LocomotorActionB/SwitchLocoDialog";
import GSkillProfile from "../GSkillProfile/GSkillProfile";

export default function LocomotorTab(props) {
  const { TabNext } = props;
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
    },

    skillsData,
    getAllSkill,
    searchSkill,
    gradeId,
  } = props;

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Name of Skill</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {skillsData &&
              skillsData.length &&
              skillsData.map((skills, index) => (
                <TableRow>
                  <TableCell className="Col"> {skills.subSkillUid}</TableCell>
                  <TableCell className="Col">
                    {skills.subSkillName}
                    {/* <GSkillProfile /> */}
                  </TableCell>
                  <TableCell className="Col">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {skills.subSkillDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <LocoEdit
                        states={{
                          mainSkill,
                          setMainSkill,
                          subSkillName,
                          setSubSkillName,
                          subSkillUid,
                          setSubSkillUid,
                          subSkillDesc,
                          setSubSkillDesc,
                          xpAssigned,
                          setXpAssigned,
                          level,
                          setLevel,
                          rubricsQts,
                          setRubricsQts,
                          skills,
                          skillsData,
                        }}
                        searchSkill={searchSkill}
                        gradeId={gradeId}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <LocoSwitch
                        searchSkill={searchSkill}
                        SkillsId={skills._id}
                        gradeId={gradeId}
                        accessCheck={access}
                        access={skills.access}
                      />
                    </IconButton> */}
                    {/* <IconButton className="ActionButtonSize">
                      <LocoDeleteDialog />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

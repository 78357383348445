import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  InputAdornment,
  IconButton,
  Checkbox,
  FormGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
// ReactIcons
import { GoSearch } from "react-icons/go";

export default function SelectCourseTab(props) {
  const { TabNext } = props;
  const {
    states: { course, selectCourseText, courseId, setCourseId },
    // storeCourse,
    // originalData,
    // filterdata,
  } = props;
  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid
          item
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <FormControl className="CourseSerachInput">
            <Input
              disableUnderline
              className="GCourseInput"
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ marginRight: "7px" }}>
                    <GoSearch className=" HaedAddIcon" />
                  </IconButton>
                </InputAdornment>
              }
              label="Serach Course"
            />
          </FormControl>
        </Grid>

        {course.map((courses, i) => (
          <Grid
            key={i}
            container
            item
            md={6}
            sm={6}
            xs={6}
            lg={6}
            // className=" AssignActivitesGridPaper    "
          >
            <Grid container item md={12}>
              <FormGroup className="GCourseFromGroup AssignActivitesGridPaper">
                <FormControlLabel
                  control={
                    <Radio
                      checked={courseId === courses._id}
                      onChange={(e) => {
                        setCourseId(e.target.value);
                      }}
                      value={courses._id}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  className="CheckLabelStyleBlue"
                  label={
                    <div className="GCourseLabel">
                      <div>
                        <Typography>{courses.courseTitle}</Typography>
                        <Typography className="GreyColorTypography">
                          {courses.courseUid}
                        </Typography>
                      </div>

                      {/* <div>
                        <Typography className="GreyColorTypography">
                          {courses.courseDuration}
                        </Typography>
                        <Typography className="GreyColorTypography">
                          {user.CourseNo}
                        </Typography>
                      </div> */}
                    </div>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        ))}
        {!courseId && (
          <Grid container style={{ marginTop: "10px" }}>
            <Typography className="warningTypography">
              {selectCourseText}
            </Typography>
          </Grid>
        )}
      </Grid>{" "}
    </>
  );
}

import React, { useState } from "react";

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";

import LessonPlanAdd from "../../CourseAddPage/CLessonPlanDialog/CLPDialogMain";
export default function Lessonplan(props) {
  const {
    states: {
      inputDisable,
      setInputDisable,
      assignlessonplandata,
      filterLessonPlanData,
    },
    storeLessonPlan,
    EditSubmitClick,
  } = props;

  const [lessonplan, setLessonPlan] = useState(filterLessonPlanData);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid
        container
        className="shadow-style  mt-24"
        style={{ padding: "20px" }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item md={6}>
            <Typography className="DarkBlackColorTypo fs-18">
              Add lesson plan
            </Typography>
          </Grid>
          <Grid item md={6}>
            {inputDisable ? null : (
              <Typography align="right">
                <LessonPlanAdd
                  storeLessonPlan={storeLessonPlan}
                  assignlessonplandata={assignlessonplandata}
                  open={open}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                />
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container style={{ minHeight: "50vh" }}>
          <div className=" width100 ResponsiveTable">
            <Table className="mt-20" style={{ border: "1px solid #d4d4d4" }}>
              <TableHead>
                <TableRow className="Head">
                  <TableCell className="ColHead">ID</TableCell>
                  <TableCell className="ColHead">Name </TableCell>
                  <TableCell className="ColHead"> Score</TableCell>
                  {/* <TableCell className="ColHead"> Action </TableCell> */}
                </TableRow>
              </TableHead>
              {open === false ? (
                <>
                  {filterLessonPlanData?.map((data) => {
                    return (
                      <>
                        <TableBody>
                          <TableRow>
                            <TableCell className=" Col700">
                              {data?.uid}
                            </TableCell>
                            <TableCell className=" Col700">
                              {data?.title}
                            </TableCell>
                            <TableCell className="Col700  ActionButtonSize">
                              {data?.assignedPP}
                            </TableCell>
                            {/* <TableCell className="Action"></TableCell> */}
                          </TableRow>
                        </TableBody>
                      </>
                    );
                  })}
                </>
              ) : null}
            </Table>
            {filterLessonPlanData?.length === 0 ? (
              <Grid
                container
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  height: "40vh",
                }}
              >
                <Typography>
                  <Typography className="width100 DarkBlackColorTypo">
                    No Lesson Plan found! Click below button to add Lesson Plan{" "}
                  </Typography>
                  <Typography align="center" className="mt-20">
                    <LessonPlanAdd
                      storeLessonPlan={storeLessonPlan}
                      assignlessonplandata={assignlessonplandata}
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                    />
                  </Typography>
                </Typography>
              </Grid>
            ) : null}
          </div>{" "}
        </Grid>
        <DialogActions className="mt-24 mb-20 width100">
          {inputDisable ? (
            <Button
              className="GreenButton700 next-btn"
              onClick={() => setInputDisable(false)}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                className="GreenButton-border next-btn"
                onClick={() => setInputDisable(true)}
              >
                Cancel
              </Button>
              <Button
                className="GreenButton700 next-btn"
                onClick={() => EditSubmitClick()}
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Grid>
    </>
  );
}

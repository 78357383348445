import * as React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  DialogContent,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Equipment Details{" "}
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const classes = useStyles();
  const { equipmentData } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //For CreateInput
  console.log(equipmentData, " call me maybe");

  return (
    <div>
      <Button className="ButtonTransparent" onClick={handleClickOpen("paper")}>
        {equipmentData.equipmentName}
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle">
            <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
              <Grid
                container
                direction="column"
                item
                style={{ marginLeft: "25px" }}
              >
                <Typography className="SkillsInputTitle  GreyColorTypography">
                  Equipment UID <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Typography variant className="RubicInputs">
                    {/* {equipmentData.equipmentUid} */}
                    {equipmentData.equipmentUid}
                  </Typography>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Equipment Name <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    defaultValue={equipmentData?.equipmentName}
                    readOnly
                  ></Input>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Equipment Description <span style={{ color: "red" }}> *</span>
                </Typography>{" "}
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <TextareaAutosize
                    className="SkillTextArea"
                    aria-label="minimum height"
                    readOnly
                    defaultValue={equipmentData?.equipmentDesc}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
              <Typography className="GreyColorTypography  EquipMeadiaTypo  ">
                Media
              </Typography>
              {/* <Grid item container ></Grid> */}
              <Grid item xs={6} className="ThumbnilGridStyle">
                <img
                  style={{ width: "100%" }}
                  src={equipmentData.equipmentImg}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="EquipDialogActionBottom">
          <Button
            className="GreenButton700 EquipActionBottom"
            style={{ marginRight: "1.3%" }}
            autoFocus
            onClick={handleClose}
          >
            Save
          </Button>
          <Button
            className="GreyButton700  EquipActionBottom"
            style={{ marginRight: "1%" }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Box,
  Tab,
  Tabs,
  Grid,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
// React Icons

//Core Component
import GradeProfile from "./GradeProfileTab's/GradeDetailsTab/GradeDetailTab";
import { useLogin } from "Context/ExportContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { FETCH_URL } from "fetchIp";
import GTrainer from "./GradeProfileTab's/GTrainerTab/GTrainer";
import GCourse from "./GradeProfileTab's/GCourseTab/GCourse";
import GSkill from "./GradeProfileTab's/Skills/GSkills";
import GEquipment from "./GradeProfileTab's/GEquipmentsTab/GEqipments";
import LoaderDialog from "components/Loader/LoaderDialog";
import GradeDivision from "./GradeProfileTab's/GradeDivision/GradeDivision";
import LearnerRadioA from "./GradeProfileTab's/GLearnerTab/GLearnerTable's/LearnerTable";
import GLessonPlan from "./GradeProfileTab's/GLessonPlanTab/GlessonPlan";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ gradeId, gradeData }) {
  const { departmentDetail, userToken, loadingHandler } = useLogin();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [divisionName, setDivisionName] = React.useState(0);
  const [rowData, setRowData] = useState(null);
  const [divisonData, setDivisonData] = useState([]);
  const [learnerDivisonData, setLearnerDivisonData] = useState([]);
  ////////////////////////////////////////////////////
  // const [values, setValues] = React.useState([]);
  const [selected, setSelected] = useState("");
  const [divisionDataId, setDivisionDataId] = useState("");
  // console.log(gradeId, "gradeId>>>>>>>>>>>>>>>>>asd>>>>>>>>>>>>>>>asd");
  //////////////////////////////////////////////////////
  useEffect(() => {
    if (gradeData) {
      handleSchoolSearch();
    }
  }, [gradeData]);

  const handleSchoolSearch = async () => {
    console.log("Check Data schoolUid ", gradeData?.schoolUid);
    const response = await fetch(
      `${FETCH_URL}/api/school/getSingleSchool/${gradeData?.schoolUid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My School response from searching ==>", res);
    } else {
    }
  };

  const getAllDivisons = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setDivisonData(res.msg);
        console.log("My Grade Divison response ==>", res);
      } else {
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      console.log("Error while getting divison =======>", error);
    }
  };

  /////////////////////////////get paginate learner////////////////////////////

  const getPaginatedLearner = async (divisionId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedLearner?division=${divisionId}&schoolName=${departmentDetail?.departmentName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLearnerDivisonData(res.msg);
        console.log("My learner Divison response ==>", res);
      } else {
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      console.log("Error while getting divison =======>", error);
    }
  };

  /////////////////////////////get paginate learner////////////////////////////
  useEffect(() => {
    getAllDivisons(gradeId);
  }, []);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleChange(e, datas) {
    setSelected(e.target.value);
    setDivisionName(datas.props.children);
  }

  /////////////////////////////////////////////////////////////
  // const defaultDivison = divisonData.map((a) => a.division);
  // console.log(defaultDivison[0], "defaultDivison>>>");
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            className="ActivityTabChanges"
            label="Grade Detail"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Divisions"
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Learner"
            {...a11yProps(2)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Trainer"
            {...a11yProps(3)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Course"
            {...a11yProps(4)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Lesson Plans"
            {...a11yProps(5)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Skills"
            {...a11yProps(6)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Equipment"
            {...a11yProps(7)}
          />
        </Tabs>
      </Box>
      <LoaderDialog loading={loading} />

      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <GradeProfile
            rowData={rowData}
            data={gradeData}
            divisonData={divisonData}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GradeDivision
            divisonData={divisonData}
            gradeId={gradeId}
            getAllDivisons={getAllDivisons}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container className="  colorwhite DivisionStyleGridRadio ">
            <Grid container className="DiviStyle">
              <Grid item>
                <FormControl>
                  <Select
                    style={{
                      border: "1px solid #dddddd",
                      padding: "5px",
                      borderRadius: "8px",
                    }}
                    defaultValue={0}
                    value={selected ? selected : 0}
                    onChange={(e, datas) => {
                      handleChange(e, datas);
                      setDivisionDataId(e.target.value);
                      getPaginatedLearner(e.target.value);
                    }}
                  >
                    <MenuItem className="BlueColorTypography" value={0}>
                      Select Divisons
                    </MenuItem>

                    {divisonData?.map((data, index) => {
                      return (
                        <MenuItem key={index} value={data._id}>
                          {data.division}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <LearnerRadioA
            gradeId={gradeId}
            learnerDivisonData={learnerDivisonData}
            divisionName={divisionName}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GTrainer gradeId={gradeId} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <GCourse data={gradeData} gradeId={gradeId} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <GLessonPlan data={gradeData} gradeId={gradeId} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <GSkill data={gradeData} gradeId={gradeId} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <GEquipment data={gradeData} gradeId={gradeId} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

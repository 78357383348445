import * as React from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Button,
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";

//ReactIcons
import { IoCloseSharp } from "react-icons/io5";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleSmall" {...other}>
      <Typography className="DialogTitleTypographySmall">
        Are you sure you want to disable this item?{" "}
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const {
    courseId,
    changeCourseStatus,
    access,
    snackopen,
    snackmsg,
    snackErrMsg,
    snackerropen,
    SnanbarClose,
    SnackbarErrorClose,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  console.log(" Access Status Check", access);

  const StatusChange = () => {
    changeCourseStatus(courseId, access);
    setOpen(false);
  };
  const SwitchClick = () => {
    setOpen(true);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Switch">
        <IconButton className="ActionButtonSize">
          <Switch
            {...label}
            className="SwitchStyle"
            open={open}
            checked={access === 1 ? true : false}
            onChange={() => {
              SwitchClick();
            }}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogActions>
          <Button
            className="GreyButton  SwitchCancelB"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton  SwitchOkB"
            autoFocus
            onClick={() => {
              StatusChange();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import { IoIosAddCircle } from "react-icons/io";

export default function SubSkills(props) {
  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
    },
    TabNext,
  } = props;

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="SkillsInputTitle  GreyColorTypography">
              Skill <span className="ImpColor"> *</span>
            </Typography>
            <Grid container item style={{ width: "67%" }}>
              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className=" SkillsSelectStyle "
                  value={mainSkill ? mainSkill : 0}
                  onChange={setMainSkill}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Locomotor
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={10}>
                    Blue Ridge P
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Typography className="SkillsInputTitle  GreyColorTypography">
              Sub Skill UID<span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="RubicInputs"
                value={subSkillUid}
                onChange={(e) => setSubSkillUid(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Sub Skill Name<span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="RubicInputs"
                value={subSkillName}
                onChange={(e) => setSubSkillName(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Sub Skill Description<span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <TextareaAutosize
                className="SkillTextArea"
                aria-label="minimum height"
                value={subSkillDesc}
                onChange={(e) => setSubSkillDesc(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="GreyColorTypography   EquipMeadiaTypo  ">
            Media
          </Typography>
          <Grid item container className="AddThumbnilGridStyle">
            <Typography className=" BlueColorTypography AddThumbnailTypography">
              Add Thumbnail
            </Typography>
            <div className="Thumbnildiv">
              <div>
                <Button className="GreenButton700 SkillsCFB">
                  <IoIosAddCircle className="CFButtonIcon" /> Choose File
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item container className="GridPP-levelStyle">
            <Grid container item className=" PPGridStyle">
              <Typography className="BlueColorTypography  PPTypo">
                PP
              </Typography>
              <Input
                disableUnderline
                className="SkilsPPInput"
                value={xpAssigned}
                onChange={(e) => setXpAssigned(e.target.value)}
              />
            </Grid>
            <Grid item container className="LevelGridStyle">
              <Typography className="BlueColorTypography  LevelTypo">
                Level
              </Typography>

              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className=" SkillsSelectStyle"
                  value={level ? level : 0}
                  onChange={setLevel}
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={10}>
                    XI
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={20}>
                    X
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={30}>
                    Vi
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions className="DialogActionBottom ">
          <Button
            className="GreenButton700   SkillActionButton "
            onClick={TabNext}
          >
            Next
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}

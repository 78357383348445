import React from "react";
import { Typography, Grid, Input, TextareaAutosize } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import EquipmentKitEditDialog from "../../ActionDialog/KitActionDialog's/KitEdit";
export default function EquipmentProfile(props) {
  const { singleEquipKit } = props;
  console.log(" Kit", singleEquipKit);

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        {/* <Grid container item className="   SkillRubDialofGrid  ">
          <Grid container item className="DiviStyle ">
            <Typography></Typography>

            <EquipmentKitEditDialog />
          </Grid>
        </Grid> */}
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            container
            direction="column"
            item
            style={{ marginLeft: "25px" }}
          >
            <Typography className="SkillsInputTitle  GreyColorTypography">
              Equipment KIT UID <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Typography className="RubicInputs" readOnly>
                {singleEquipKit?.equipmentKitUid}
              </Typography>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Equipment KIT Name <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Typography className="RubicInputs" readOnly>
                {singleEquipKit?.equipmentKitName}{" "}
              </Typography>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Equipment KIT Description <span style={{ color: "red" }}> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <TextareaAutosize
                className="SkillTextArea"
                aria-label="minimum height"
                defaultValue={singleEquipKit?.equipmentKitDesc}
                readOnly
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="GreyColorTypography  EquipMeadiaTypo  ">
            Equipments
          </Typography>
          <Grid item container className="KEquipmentGridStyle">
            <Grid
              md={12}
              sm={12}
              xs={12}
              lg={12}
              item
              className="DialogLRStyle"
            >
              <>
                {singleEquipKit &&
                  singleEquipKit.addedEquipment.map((k, l) => {
                    return (
                      <Chip
                        label={k?.equipmentName}
                        className="GreenColorTypography  KEquipmentChip  "
                      />
                    );
                  })}
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

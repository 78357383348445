import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  Grid,
  Input,
  Checkbox,
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  DialogTitle,
  TextareaAutosize,
  Typography,
  IconButton,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";
import { FaPercent } from "react-icons/fa";

export default function VarientDetailsTab() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const TabOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            direction="column"
            style={{ marginLeft: "20px", marginBottom: "10px " }}
          >
            <Grid container direction="column" item>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Activity Name <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1 " readOnly></Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                UID <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1 " readOnly></Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Description <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="ActivityTextArea CursorDefault "
                  aria-label="minimum height"
                  readOnly
                />
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Level<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1" readOnly>
                  {" "}
                </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                XP Reward
                <span className="ImpColor" className="ImpColor">
                  *
                </span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1 " readOnly>
                  {" "}
                </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Link<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1 " readOnly>
                  {" "}
                </Input>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid item md={11} sm={11} xs={11} lg={11} container>
            <Grid
              item
              container
              md={11}
              sm={11}
              xs={11}
              lg={11}
              style={{
                backgroundColor: "white",
                marginTop: "3%",
                borderRadius: "15px",
                marginRight: "20px",
                border: "1px solid #d4d4d4",
                maxHeight: "240px",
              }}
            >
              <Typography className="BlueColorTypography AddLogoTypography">
                Preview
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

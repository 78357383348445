import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Tab,
  Tabs,
  Box,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//React Icpns
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

//core Component
import AssesmentTrainerDetails from "./Asses-Trainer";
import AssesTrainerRubrics from "./Asses-Trainer-Rubrics";

import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function AddDialog(props) {
  const classes = useStyles();
  const { getAllAssesmentTrainer } = props;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  //////////////////////////////////////////////////////////
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  ///////////////////////////////////////////////////////////////
  function TabNext() {
    setValue(1);
  }

  //For CreateInput
  const [uid, setUid] = useState(null);
  const [assessmentName, setAssessmentName] = useState(null);
  const [assessmentDesc, setAssessmentDesc] = useState(null);
  const [assignedXp, setAssignedXp] = useState(null);
  const [level, setLevel] = useState(null);
  const [skills, setSkills] = useState(null);
  const [Imagevalue, setImageValue] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [rubricsQts, setRubricsQts] = React.useState([
    { quesNo: "", quesDesc: "", quesMarks: "" },
  ]);

  const { userToken } = useLogin();

  //Api Calling
  const imageUpload = async (assessmentId) => {
    const formdata = new FormData();
    formdata.set("assessmentId", assessmentId);
    formdata.set("assessmentUid", uid);
    formdata.append("file", image);
    // console.log("I am the form data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/uploadAssessmentImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        getAllAssesmentTrainer();
        console.log("I am the response after image upload >>", res);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      console.error("imgError>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", error);
    }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const submitClick = async (e) => {
    e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/createTrainerAssessment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            uid,
            assessmentName,
            assessmentDesc,
            assignedXp,
            level,
            rubricsQts,
            // skills: skillstore,
          }),
        }
      );
      let res = await responce.json();

      let assessmentId = res.msg2._id;
      if (assessmentId) {
        imageUpload(assessmentId);
        console.log(
          "Response after create check assessment ID >> ",
          res.msg2._id
        );
      }
      if (responce.ok) {
        console.log(" i work bitch");
        setSnackOpen(true);
        setSnackMsg(res.msg);
      }
      if (responce.ok) {
        // console.log("Mah respo =====>", res);
        handleClose();
        getAllAssesmentTrainer();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline
          className="AddIcon"
          onClick={handleClickOpen("paper")}
        />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        scroll={scroll}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              centered
              onChange={TabChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Assessment Details"
                {...a11yProps(0)}
              />
              <Tab className="TabStyle" label="Rubrics " {...a11yProps(1)} />

              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>
        </DialogTitle>

        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <AssesmentTrainerDetails
              states={{
                uid,
                setUid,
                assessmentName,
                setAssessmentName,
                assessmentDesc,
                setAssessmentDesc,
                assignedXp,
                setAssignedXp,
                level,
                setLevel,
                skills,
                setSkills,
                Imagevalue,
                setImageValue,
                file,
                setFile,
                image,
                setImage,
                imageUpload,
                rubricsQts,
                setRubricsQts,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AssesTrainerRubrics states={{ rubricsQts, setRubricsQts }} />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            className="GreenButton   EquipSaveButton"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Save" : "Next"}
          </Button>
          <Button
            className=" GreyButton   EquipCancelButton "
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

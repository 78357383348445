import {
  CREATE_SCHOOL_SUCCESS,
  CREATE_SCHOOL_REQUEST,
  CREATE_SCHOOL_FAILS,
  CREATE_GRADE_SUCCESS,
  CREATE_GRADE_REQUEST,
  CREATE_GRADE_FAILS,
} from "../constants/Constants";

const initialState = {
  schoolUid: "Ac11",
  schoolName: "XYZ School",
  location: "XYZ",
  enrolledOn: "XYZ",
  firstTermStartDate: "XYZ",
  secondTermStartDate: "XYZ",
  firstTermEndDate: "XYZ",
  secondTermEndDate: "XYZ",
  address: "XYZ",
  zipCode: 112244,
  city: "XYZ",
  state: "XYZ",
  country: "XYZ",
  representativeName: "XYZ",
  representativeEmailId: "XYZ",
  representativeMobilePhoneNo: 9232103922,
  representativeAddress: "XYZ",
};

export const CreateSchoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SCHOOL_SUCCESS:
      console.log("Action incoming =====>", action);
      return { ...state, loading: true, table: action.payload };
      break;
    case CREATE_SCHOOL_REQUEST:
      return { ...state, loading: false, table: action.payload };
      break;
    case CREATE_SCHOOL_FAILS:
      return { ...state, loading: false, error: action.payload };
      break;
    default:
      return state;
  }
};

export const CreateGradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SCHOOL_SUCCESS:
      console.log("Action incoming =====>", action);
      return { ...state, loading: true, table: action.payload };
      break;
    case CREATE_SCHOOL_REQUEST:
      return { ...state, loading: false, table: action.payload };
      break;
    case CREATE_SCHOOL_FAILS:
      return { ...state, loading: false, error: action.payload };
      break;
    default:
      return state;
  }
};

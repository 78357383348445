import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Grid,
  Input,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { FaPercent } from "react-icons/fa";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function SkilsDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [locomotor, setLocomotor] = React.useState([]);
  const [BodyMgt, setBodyMgt] = React.useState([]);
  const [objcontrol, setObjectControl] = React.useState([]);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState("");
  const [value] = React.useState(0);

  const { userToken } = useLogin();
  const {
    states: { originalData, bmoriginalData, ocoriginalData },
    assesmnet,
    storeSkill,
    OCstoreSkill,
    BMstoreSkill,
    locomotorP,
    setLocomotorP,
    bodyManagementP,
    setBodyManagementP,
    objectControlP,
    setObjectControlP,
  } = props;

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    const totaldata =
      parseInt(locomotorP ? locomotorP : 0) +
      parseInt(bodyManagementP ? bodyManagementP : 0) +
      parseInt(objectControlP ? objectControlP : 0);

    const disabled = (totaldata) => {
      if (totaldata === 100) {
        setDisable(false);
        setMessage("Skill percentage is 100 now");
      } else {
        setDisable(true);
        setMessage("Skill percentage should be Equal to 100");
      }
    };
    disabled(totaldata);
    console.log(totaldata);
  }, [locomotorP, bodyManagementP, objectControlP]);
  // Get API
  const getAllSkill = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/skill/viewAllSkills`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Skills==>", res);
        setLocomotor(
          res.msg.filter((skill) => skill.mainSkill === "Locomotor")
        );
        setBodyMgt(
          res.msg.filter((skill) => skill.mainSkill === "Body Management")
        );
        setObjectControl(
          res.msg.filter((skill) => skill.mainSkill === "Object Control")
        );
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getAllSkill();
  }, [value]);

  return (
    <div>
      <Grid container className="DailogLTRBStyle">
        <div>
          <Typography
            className={
              disable ? "GreyColorTypography400 " : "GreenColorTypography"
            }
            style={{ textAlign: "center", marginTop: "5px" }}
          >
            {message}
          </Typography>
          <Grid container className="DialogLTRStyle">
            <Grid container item>
              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid container item>
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <Grid
                      item
                      container
                      md={7}
                      sm={7}
                      xs={7}
                      lg={7}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Locomotor Skills
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={5}
                      sm={5}
                      xs={5}
                      lg={5}
                      className=" CheckHeadTitle"
                    >
                      <span>
                        <Input
                          className="PercentInput"
                          value={locomotorP}
                          onChange={(e) => setLocomotorP(e.target.value)}
                        ></Input>
                      </span>
                      <FaPercent className="PercenIcon" />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    md={10}
                    sm={10}
                    xs={10}
                    lg={10}
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      maxHeight: "340px",
                      overflow: "auto",
                    }}
                  >
                    {locomotor &&
                      locomotor.length > 0 &&
                      locomotor.map((x, i) => {
                        let data = false;
                        originalData.map((datas) => {
                          if (datas?._id == x?._id) {
                            data = true;
                          }
                        });
                        return (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            className="SECheckBox  "
                            item
                            container
                            direction="column"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data == true ? true : false}
                                  />
                                }
                                className="CheckLabelStyleGrey  "
                                value={x.subSkillName}
                                onChange={(e) =>
                                  storeSkill(e, x, i, locomotorP)
                                }
                                label={x.subSkillName}
                              />
                            </FormGroup>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid
                  item
                  container
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  style={{ marginLeft: "2%", flexBasis: "18.5%" }}
                >
                  <Grid item container>
                    <Grid
                      item
                      container
                      md={7}
                      sm={7}
                      xs={7}
                      lg={7}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Body Management
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={5}
                      sm={5}
                      xs={5}
                      lg={5}
                      className=" CheckHeadTitle"
                    >
                      <span>
                        <Input
                          className="PercentInput"
                          value={bodyManagementP}
                          onChange={(e) => setBodyManagementP(e.target.value)}
                        ></Input>
                      </span>
                      <FaPercent className="PercenIcon  " />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      maxHeight: "350px",
                      overflow: "auto",
                    }}
                  >
                    {BodyMgt &&
                      BodyMgt.length > 0 &&
                      BodyMgt.map((x, i) => {
                        let data = false;
                        bmoriginalData.map((datas) => {
                          if (datas?._id == x?._id) {
                            data = true;
                          }
                        });
                        return (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            className="SECheckBox"
                            item
                            container
                            direction="column"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data == true ? true : false}
                                  />
                                }
                                value={x.subSkillName}
                                onChange={(e) =>
                                  BMstoreSkill(e, x, i, bodyManagementP)
                                }
                                label={x.subSkillName}
                                className="CheckLabelStyleGrey  "
                              />
                            </FormGroup>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid
                  item
                  container
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  style={{ marginLeft: "5%", flexBasis: "16%" }}
                >
                  {" "}
                  <Grid item container>
                    <Grid
                      item
                      container
                      md={7}
                      sm={7}
                      xs={7}
                      lg={7}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Object Control
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={5}
                      sm={5}
                      xs={5}
                      lg={5}
                      className=" CheckHeadTitle"
                    >
                      <span>
                        <Input
                          className="PercentInput"
                          value={objectControlP}
                          onChange={(e) => setObjectControlP(e.target.value)}
                        ></Input>
                      </span>
                      <FaPercent className="PercenIcon  " />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      overflow: "auto",
                    }}
                  >
                    {objcontrol &&
                      objcontrol.length > 0 &&
                      objcontrol.map((x, i) => {
                        let data = false;
                        ocoriginalData.map((datas) => {
                          if (datas?._id == x?._id) {
                            data = true;
                          }
                        });
                        return (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            className="SECheckBox  "
                            item
                            container
                            direction="column"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data == true ? true : false}
                                  />
                                }
                                className="CheckLabelStyleGrey"
                                value={x.subSkillName}
                                onChange={(e) =>
                                  OCstoreSkill(e, x, i, objectControlP)
                                }
                                label={x.subSkillName}
                              />
                            </FormGroup>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

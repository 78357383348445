import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

//core Component
import ContactTab from "./ContactTab";
import { useLogin } from "../../../../Context/ExportContext";
import DocumentTab from "./DocumentTab";
import OrganisationTab from "../SchoolAddDialog/OrgnisationTab";
import Aggrement from "./Aggrement";
import { FETCH_URL } from "fetchIp";
//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function handleEnter(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}
export default function AddDialog({ getAllSchool }) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);

  const { userToken } = useLogin();

  //For CreateInput
  const [schoolUid, setSchoolUid] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [address, setAddress] = useState(null);
  const [enrolledOn, setEnrolledOn] = useState(null);
  const [firstTermStartDate, setFirstTermStartDate] = useState(null);
  const [secondTermStartDate, setsecondTermStartDate] = useState(null);
  const [firstTermEndDate, setfirstTermEndDate] = useState(null);
  const [secondTermEndDate, setsecondTermEndDate] = useState(null);
  const [thirdTermStartDate, setThirdTermStartDate] = useState(null);
  const [thirdTermEndDate, setThirdTermEndDate] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [representativeName, setRepresentativeName] = useState(null);
  const [representativeEmailId, setRepresentativeEmailId] = useState(null);
  const [
    representativeMobilePhoneNo,
    setRepresentativeMobilePhoneNo,
  ] = useState(null);
  const [representativeAddress, setRepresentativeAddress] = useState(null);
  const [value, setValue] = React.useState(0);
  const [departMentDetail, setDepartMentDetails] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();
  const [location, setLocation] = useState(null);

  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  const [rimage, setRImage] = useState();
  const [rfile, setRFile] = useState();
  const [agreement, setAgreement] = React.useState({});
  const [docs, setDocs] = React.useState([]);

  //Events
  const DialogClickOpen = () => {
    setOpen(true);
  };

  const DialogClose = () => {
    setOpen(false);
    setDepartmentSelect("");
    setValue(0);
    setSchoolUid("");
    setSchoolName("");
    setLocation("");
    setAddress("");
    setEnrolledOn(null);
    setFirstTermStartDate(null);
    setsecondTermStartDate(null);
    setfirstTermEndDate(null);
    setsecondTermEndDate(null);
    setThirdTermStartDate(null);
    setThirdTermEndDate(null);
    setZipCode("");
    setCity("");
    setState("");
    setCountry("");
    setRepresentativeName("");
    setRepresentativeEmailId("");
    setRepresentativeMobilePhoneNo("");
    setRepresentativeAddress("");
    setImage("");
    setFile("");
    setRFile("");
    setRImage("");
  };

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  function TabNext3() {
    setValue(3);
  }
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const handleClick = () => {
    setSnackOpen(true);
  };

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getAllDepartments?pagination=true&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        setDepartMentDetails(res);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  //Api Calling
  const submitClick = async (e) => {
    if (!agreement) {
      setSnackerropen(true);
      setSnackErrMsg("Choose Agreement File");
    }
    if (docs.length == 0) {
      setSnackerropen(true);
      setSnackErrMsg("Choose Document File");
    }
    e.preventDefault();
    console.log("firstTermStartDate", departmentSelect);
    let departmentDetails = {
      departmentName: departmentSelect?.departmentName,
      uid: departmentSelect?.uid,
      departmentId: departmentSelect?._id,
      imageUrl: departmentSelect?.imageUrl,
      createdOn: departmentSelect?.createdOn,
      zipCode: departmentSelect?.zipCode,
      emailId: departmentSelect?.emailId,
      address: departmentSelect?.address,
    };
    console.log("departmentDetails", departmentDetails);
    try {
      const responce = await fetch(`${FETCH_URL}/api/school/createSchool`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          schoolUid,
          schoolName,
          location,
          enrolledOn,
          firstTermStartDate,
          secondTermStartDate,
          firstTermEndDate,
          secondTermEndDate,
          thirdTermStartDate,
          thirdTermEndDate,
          address,
          zipCode,
          city,
          state,
          country,
          representativeName,
          representativeEmailId,
          representativeMobilePhoneNo,
          representativeAddress,
          departmentDetails,
        }),
      });
      let res = await responce.json();
      console.log(res, "res");
      if (!responce.ok) {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        return;
      }

      console.log("Response after create >> ", res.msg2._id);
      let ID = res.msg2._id;
      if (ID) {
        imageUpload(ID);
        AgreementUpload(ID);
        DocUpload(ID);
      }
      if (responce.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        DialogClose();
        getAllSchool();
      }
    } catch (error) {
      // console.error(error);
    }
  };
  useEffect(() => {
    getDepartmentDetails();
  }, []);

  const reset = () => {
    setSchoolUid({ schoolUid: "" });
    setSchoolName({ schoolName: "" });
  };

  const imageUpload = async (ID) => {
    console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("schoolId", ID);
    formdata.set("schoolName", schoolName);
    formdata.append("logo", image);
    formdata.append("representative_photo", rimage);

    console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/school/uploadImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const DocUpload = async (ID) => {
    console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("schoolId", ID);
    docs.map((data) => {
      formdata.append("file", data);
    });
    // formdata.append("file", docs);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/school/uploadSchoolDocs`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after Docs upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const AgreementUpload = async (ID) => {
    const formdata = new FormData();
    formdata.set("schoolId", ID);
    formdata.append("file", agreement);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/school/schoolAgreementS3`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after Agreement upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={DialogClickOpen} />
      </IconButton>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            onChange={TabChange}
            centered
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Organisation Details"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Contact" {...a11yProps(1)} />
            <Tab className="TabStyle" label="Documents" {...a11yProps(2)} />
            <Tab className="TabStyle" label="Agreement" {...a11yProps(3)} />

            <BootstrapDialogTitle
              onPress={reset}
              onClose={DialogClose}
            ></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}

        <DialogContent dividers={false} className="TabPanelStyle ">
          <TabPanel value={value} index={0}>
            <OrganisationTab
              states={{
                departmentSelect,
                setDepartmentSelect,
                schoolUid,
                setSchoolUid,
                schoolName,
                setSchoolName,
                location,
                setLocation,
                address,
                setAddress,
                enrolledOn,
                setEnrolledOn,
                firstTermStartDate,
                setFirstTermStartDate,
                secondTermStartDate,
                setsecondTermStartDate,
                firstTermEndDate,
                setfirstTermEndDate,
                secondTermEndDate,
                setsecondTermEndDate,
                thirdTermEndDate,
                setThirdTermEndDate,
                thirdTermStartDate,
                setThirdTermStartDate,
                zipCode,
                setZipCode,
                city,
                setCity,
                state,
                setState,
                country,
                setCountry,
                representativeName,
                setRepresentativeName,
                representativeEmailId,
                setRepresentativeEmailId,
                representativeMobilePhoneNo,
                setRepresentativeMobilePhoneNo,
                representativeAddress,
                setRepresentativeAddress,
                image,
                setImage,
                file,
                setFile,
                departMentDetail,
              }}
              imageUpload={imageUpload}
              TabNext={TabNext}
              handleEnter={handleEnter}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ContactTab
              states={{
                schoolUid,
                setSchoolUid,
                schoolName,
                setSchoolName,
                location,
                setLocation,
                address,
                setAddress,
                enrolledOn,
                setEnrolledOn,
                firstTermStartDate,
                setFirstTermStartDate,
                secondTermStartDate,
                setsecondTermStartDate,
                firstTermEndDate,
                setfirstTermEndDate,
                secondTermEndDate,
                setsecondTermEndDate,

                zipCode,
                setZipCode,
                city,
                setCity,
                state,
                setState,
                country,
                setCountry,
                representativeName,
                setRepresentativeName,
                representativeEmailId,
                setRepresentativeEmailId,
                representativeMobilePhoneNo,
                setRepresentativeMobilePhoneNo,
                representativeAddress,
                setRepresentativeAddress,
                rimage,
                setRImage,
                rfile,
                setRFile,
              }}
              Tabnext2={TabNext2}
              handleEnter={handleEnter}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DocumentTab Tabnext3={TabNext3} docs={docs} setDocs={setDocs} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Aggrement
              submitClick={submitClick}
              // snackClickOpen={snackClickOpen}
              agreement={agreement}
              setAgreement={setAgreement}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

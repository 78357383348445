import React, { useState, useEffect } from "react";
import LoaderDialog from "components/Loader/LoaderDialog";
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
// core components
import AddEquipment from "./AddEquipments/AddEquipment";
import EquipmentTab from "./EquipmentTabs";
import AddKit from "./AddEquipments/AddKit";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Equipment() {
  const { userToken } = useLogin();
  const [value, setValue] = React.useState(0);
  const [equipmentData, setEquipmentData] = React.useState([]);
  const [equipmentKit, setEquipmentKit] = React.useState([]);

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };

  ////////////////////////////////////////////////////////////////
  const [schoolLoc, setSchoolLoc] = useState("");
  const [schoolNames, setSchoolNames] = useState("");
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState("");
  const [selectGrade, setSelectGrade] = useState("");
  const [gradeSelected, setGradeSelected] = useState("");
  const [loading, setLoading] = useState(true);

  const [pageNoE, setPageNoE] = useState(1);
  const [totalE, setTotalE] = useState(10);
  const [rowsPerPageE, setRowsPerPageE] = useState(10);

  const [pageNoEK, setPageNoEK] = useState(1);
  const [totalEK, setTotalEK] = useState(10);
  const [rowsPerPageEK, setRowsPerPageEK] = useState(10);

  const [searchDataE, setSerachDataE] = useState();
  const [searchDataEK, setSerachDataEK] = useState();
  const [totalEquipmentData, setTotalEquipmentData] = useState([]);

  ///////////////////////////////////////////////////////////
  const TabChange = (event, newValue) => {
    setValue(newValue);
    setSchoolSelected("");
    setSchoolLocationSelected("");
    setGradeSelected("");
    setSchoolLoc([]);
    setSelectGrade([]);
  };

  // Get API
  const getAllEquipment = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipments?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers ++==>", res);
        setEquipmentData(res.msg);
        setTotalE(res.lengthData);
        // setLoading(false);
      } else {
        // console.log("Error while fetching trainers", res.err);
      }
      setLoading(false);
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };

  const getTotalEquipment = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipments`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setTotalEquipmentData(res.msg);
        // setLoading(false);
      } else {
        // console.log("Error while fetching trainers", res.err);
      }
      setLoading(false);
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllEquipment();
    getAllEquipmentKIT();
  }, [value]);

  // KITAPI
  const getAllEquipmentKIT = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipmentKits?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setEquipmentKit(res.msg);
        setTotalEK(res.lengthData);
        setLoading(false);
      } else {
        // console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getDistinctSchoolAndLocation();
    getTotalEquipment();
  }, []);

  useEffect(() => {
    getAllEquipment();
    getAllEquipmentKIT();
    setSchoolLocationSelected("");
    setGradeSelected("");
    setSchoolLoc([]);
    setSelectGrade([]);
  }, [schoolSelected == 0 || ""]);
  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      setSchoolLocationSelected("");
      setGradeSelected("");
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setGradeSelected("");
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////

  const getPaginatedEquipmentData = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getPaginatedEquipment?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated Skills @123==>", res);
        // setEquipmentKit(res.msg);
        setEquipmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ////////////////////////getPaginatedEquipmentKitData/////////////////////////////
  const getPaginatedEquipmentKitData = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated Skills @123==>", res);
        // setEquipmentKit(res.msg);
        setEquipmentKit(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  // const getPaginatedEquipmentKitData = async (grade) => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/equipment/getAllEquipmentKits?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response of paginated Skills @123==>", res);
  //       // setEquipmentKit(res.msg);
  //       setEquipmentKit(res.msg);
  //     } else {
  //       console.log("Error while fetching trainers", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };

  //////////////////////////////////////////////////////////////////////////////
  /////////////////////handle search//////////////////////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (value === 0) {
      let url;

      if (searchValue) {
        setPageNoE(1);
        setRowsPerPageE(10);
        setSchoolSelected("");
        setSchoolLocationSelected("");
        setGradeSelected("");
        console.log("searchValue1", searchValue);
        url = `${FETCH_URL}/api/equipment/getAllEquipments?search=${searchValue}`;
      } else {
        console.log("searchValue2", searchValue);

        url = `${FETCH_URL}/api/equipment/getAllEquipments?pagination=true&page=1&limit=10`;
      }

      if (searchValue.length === 0) {
        getAllEquipment();
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(
        // `${FETCH_URL}/api/equipment/getPaginatedEquipment?search=${searchValue}&page=${
        //   pageNum ? pageNum : 1
        // }&limit=${rowSize ? rowSize : 10}`,
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setEquipmentData(res.msg);
        // setEquipmentKit(res.msg);
        console.log("My searching equipment data response ==>", res);
      } else {
        console.log("Error while searching course", res.err);
      }
    } else {
      let url;

      if (searchValue) {
        setPageNoEK(1);
        setRowsPerPageEK(10);
        setSchoolSelected("");
        setSchoolLocationSelected("");
        setGradeSelected("");
        console.log("searchValue1", searchValue);
        url = `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?search=${searchValue}`;
      } else {
        console.log("searchValue2", searchValue);

        url = `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?pagination=true&page=1&limit=10`;
      }
      if (searchValue.length === 0) {
        getAllEquipmentKIT();
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(
        // `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?search=${searchValue}&page=${
        //   pageNum ? pageNum : 1
        // }&limit=${rowSize ? rowSize : 10}`,
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setEquipmentKit(res.msg);
        console.log("My searching equipment kit response ==>", res);
      } else {
        console.log("Error while searching course", res.err);
      }
    }
  };

  const handleSearchE = async (pageNum, rowSize) => {
    // if (searchValue.length === 0) {
    //   getAllEquipment();
    // }
    const response = await fetch(
      `${FETCH_URL}/api/equipment/getPaginatedEquipment?&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setEquipmentData(res.msg);
      // setEquipmentKit(res.msg);
      console.log("My searching equipment data response ==>", res);
    } else {
      console.log("Error while searching course", res.err);
    }
  };
  const handleSearchEK = async (pageNum, rowSize) => {
    // if (searchValue.length === 0) {
    //   getAllEquipmentKIT();
    // }
    // console.log("My handling of search ========>", searchValue);
    const response = await fetch(
      `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setEquipmentKit(res.msg);
      setTotalEK(res.lengthData);
      console.log("My searching equipment kit response ==>", res);
    } else {
      console.log("Error while searching course", res.err);
    }
  };
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Equipments</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                {value == 0 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    value={searchDataE}
                    onChange={(e) => {
                      setSerachDataE(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    value={searchDataEK}
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); // Add debouncing for this
                      setSerachDataEK(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                )}
              </FormControl>
            </div>
          </Box>
          {value == 0 ? (
            <AddEquipment getAllEquipment={getAllEquipment} />
          ) : (
            <AddKit
              getAllEquipmentKIT={getAllEquipmentKIT}
              states={{ equipmentKit }}
              setEquipmentKit={setEquipmentKit}
              equipmentData={totalEquipmentData}
            />
          )}
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />
      <Grid container>
        <Grid item md={3} sm={3} xs={3} lg={3}>
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              // onChange={handleChange}
              label="Select School"
              color="white"
              disableUnderline
              onChange={(e) => {
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
              }}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                All school
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              // onChange={locChange}
              label="Select Location"
              disableUnderline
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getGrades(e.target.value);
              }}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={gradeSelected ? gradeSelected : 0}
              onChange={(e) => {
                getPaginatedEquipmentData(e.target.value);
                getPaginatedEquipmentKitData(e.target.value);
                setGradeSelected(e.target.value);
              }}
              label="Select Location"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Grade
              </MenuItem>
              {selectGrade &&
                selectGrade.length > 0 &&
                selectGrade.map((a) => (
                  <MenuItem className="Selectmenustyle" value={a._id}>
                    {a._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <EquipmentTab
        getAllEquipment={getAllEquipment}
        getAllEquipmentKIT={getAllEquipmentKIT}
        TabChange={TabChange}
        handleSearchE={handleSearchE}
        handleSearchEK={handleSearchEK}
        states={{
          value,
          setValue,
          equipmentData,
          totalEquipmentData,
          equipmentKit,
          schoolSelected,
          searchDataE,
          searchDataEK,
          pageNoE,
          setPageNoE,
          totalE,
          setTotalE,
          rowsPerPageE,
          setRowsPerPageE,
          pageNoEK,
          setPageNoEK,
          totalEK,
          setTotalEK,
          rowsPerPageEK,
          setRowsPerPageEK,
        }}
      />
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

//core Module

import RubricsTab from "./RubricsTab";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddRubricsDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [rubricsQts, setRubricsQts] = React.useState([
    {
      quesNo: "",
      quesDesc: "",
      quesMarks: "",
      quesType: "",
      minValue: "",
    },
  ]);

  /////////////////////////////////////////////

  const { searchSkill, skills, rubricsQus } = props;
  ///////////////////////////////////////////////

  const defaultRubricsData = skills?.rubricsQts;
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }

  const CreateSkills = async (e) => {
    e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/skill/addRubricsToSkill/${skills._id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            rubricsQts,
          }),
        }
      );

      let res = await responce.json();
      if (responce.ok) {
        console.log("Mah respo =====>", res);
        searchSkill();
        handleClose();
      } else {
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  return (
    <div>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={handleClickOpen} />{" "}
        <Typography
          variant="h2"
          style={{ fontSize: "13px", marginLeft: "5px" }}
        >
          Add Rubrics
        </Typography>
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Rubrics" {...a11yProps(0)} />
          </Tabs>{" "}
          <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <RubricsTab
              states={{
                rubricsQts,
                setRubricsQts,
                defaultRubricsData,
              }}
              searchSkill
              CreateSkills={CreateSkills}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

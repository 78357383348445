import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@material-ui/core";

//core component
import KitDeleteDialog from "../GEActionDialog/KitActionDialog's/KitDeleteDialog";
import KitSwitchDailog from "../GEActionDialog/KitActionDialog's/KitSwitchDialog";
import GKitProfileDialog from "../GEquipProfile/GKitProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function RenameTab(props) {
  const [value, setValue] = React.useState(0);
  const { userToken } = useLogin();

  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { equipmentKit, getAllEquipmentKIT, gradeId } = props;
  const deleteKit = async (KitId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/deleteEquipmentsKit/${KitId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response ==>", res);
        getAllEquipmentKIT();
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log(equipmentKit, "equipmentKit>>>>>>>>>>>>>>>>>>>>>.in grade");
  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID</TableCell>
              <TableCell className="Head">Equipment KIT Name </TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head">Assigned Grades</TableCell>
              <TableCell className="Head"> Assigned Learners</TableCell>
              {/* <TableCell className="Head"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {equipmentKit &&
              equipmentKit.length &&
              equipmentKit.map((kit) => (
                <TableRow>
                  <TableCell className="Col ActionButtonSize">
                    {/* <Link> {kit.equipmentKitUid}</Link> */}
                    {kit.equipmentKitUid}
                  </TableCell>
                  <TableCell className="Col">
                    <GKitProfileDialog equipmentKit={kit} />
                  </TableCell>
                  <TableCell className="Col">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {kit.equipmentKitDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Col">
                    {kit.assignedGrades && kit.assignedGrades.length > 0
                      ? kit.assignedGrades.length
                      : "--"}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {kit.assignedLearners && kit.assignedLearners.length > 0
                      ? kit.assignedLearners.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Action">
                    {/* <IconButton className="ActionButtonSize">
                      <KitSwitchDailog
                        kitId={kit._id}
                        access={kit.access}
                        gradeId={gradeId}
                        getAllEquipmentKIT={getAllEquipmentKIT}
                      />
                    </IconButton> */}
                    {/* <KitDeleteDialog deleteKit={deleteKit} KitId={kit._id} /> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Input,
  Typography,
  Button,
  Grid,
  Box,
  DialogActions,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
import { AiOutlineMinus as RemoveIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
// import { info } from "console";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SubSkills(props) {
  const {
    states: { rubricsQts, setRubricsQts },
    CreateSkills,
    skillsData,
  } = props;

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  console.log(rubricsQts, "hey i am rubrics");
  const classes = useStyles();
  const handleAddFields = (e) => {
    setRubricsQts([
      ...rubricsQts,
      {
        quesNo: "",
        quesDesc: "",
        // quesMarks: "" * 1,
        quesMarks: "",

        quesType: "",
        minValue: "",
      },
    ]);
  };
  const [show, setShowData] = useState(false);
  const handleRemoveFields = (e, i) => {
    const inputList = [...rubricsQts];
    inputList.splice(i, 1);
    setRubricsQts(inputList);
  };
  const [options, setOptions] = useState([
    { id: uuidv4(), option: "", optionNo: "" },
  ]);

  console.log(" Check options: ");
  useEffect(() => {
    if (options.length > 10) {
      alert("Can't add more than 10 questions");
    }
    console.log("options ===> ", options);
  }, [options]);

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const inputList = [...rubricsQts];
    inputList[i][name] = value;

    if (inputList[i][name] === 20) {
      setShowData(true);
    } else {
      setShowData(false);
    }
    setRubricsQts(inputList);
  };

  return (
    <div>
      <Grid container className="DailogLTRBStyle">
        {options.slice(0, 10).map((option, index) => (
          <Grid
            container
            key={option.id}
            style={{
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Grid container style={{ gap: "30px" }}>
              {rubricsQts?.map((x, i) => {
                // rubricsQts[i]["minValue"] === 20
                console.log("TESTING ==>", i, rubricsQts[i]);
                return (
                  <>
                    <Grid
                      container
                      key={i}
                      style={{ gap: "20px", paddingLeft: "44px" }}
                    >
                      <Grid item xs={1}>
                        <Typography>Q.No</Typography>
                        <Grid item md={11} sm={11} xs={11} lg={11}>
                          <Input
                            className="RubicInputs"
                            type="number"
                            id="QuNo"
                            value={rubricsQts[i]?.quesNo}
                            name="quesNo"
                            onChange={(e) => handleInputChange(e, i)}
                          ></Input>
                        </Grid>
                      </Grid>

                      <Grid item xs={8}>
                        <Typography>Question</Typography>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          <Input
                            className="RubicInputs"
                            id="QuestionName"
                            value={rubricsQts[i]?.quesDesc}
                            name="quesDesc"
                            onChange={(e) => handleInputChange(e, i)}
                          ></Input>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        style={{
                          alignItems: "center",
                          alignSelf: "center",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <IconButton
                          style={{ marginRight: "10px" }}
                          className="GreyButton700"
                          disabled={option.length === 1}
                          onClick={(e) => handleRemoveFields(e, i)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <IconButton
                          className="GreenButton700"
                          onClick={(e) => handleAddFields(e)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Grid>

                      <Grid item xs={2} style={{ marginTop: "5px" }}>
                        <Typography style={{ marginBottom: "8px" }}>
                          Marks
                        </Typography>
                        <Grid xs={9}>
                          <Input
                            className="RubicInputs"
                            type="number"
                            name="quesMarks"
                            value={rubricsQts[i]?.quesMarks}
                            id="MarksName"
                            onChange={(e) => handleInputChange(e, i)}
                          ></Input>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: "5px" }}>
                        <Typography>Type</Typography>
                        <Grid xs={9}>
                          <FormControl className={classes.formControl}>
                            <Select
                              className="RubicInputs"
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              name="quesType"
                              value={rubricsQts[i]?.quesType}
                              // value={data}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <MenuItem value={1}>Theoretical</MenuItem>
                              <MenuItem value={2}>YES/No</MenuItem>
                              <MenuItem value={3}>Not Yet Type</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {rubricsQts[i]["quesType"] === 1 && (
                        <Grid item xs={2} style={{ marginTop: "5px" }}>
                          <Typography style={{ marginBottom: "8px" }}>
                            Min Value
                          </Typography>
                          <Grid xs={9}>
                            <Input
                              className="RubicInputs"
                              type="number"
                              value={rubricsQts[i]?.minValue}
                              onChange={(e) => handleInputChange(e, i)}
                              name="minValue"
                              id="minValue"
                            ></Input>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        ))}

        <DialogActions className="DialogActionBottom ">
          <Button
            className="GreenButton700   RubricsActionButton "
            onClick={CreateSkills}
          >
            Save
          </Button>
        </DialogActions>
      </Grid>
    </div>
  );
}

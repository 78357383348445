import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Tabs, Tab, Grid } from "@material-ui/core";
//Icons
import Equipment from "./AssignEquipment";
import EquipmentKit from "./AssignEquipmentKit";
import { FETCH_URL } from "fetchIp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LearnerDialog({ learner }) {
  const [value, setValue] = React.useState(0);
  const [equipment, setequipment] = useState(null);
  const [equipmentkit, setEquipmentKit] = useState(null);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const newArray = learner?.assignedEquipments?.map((obj) => ({
    equipmentId: obj?.equipmentId,
  }));
  console.log(newArray);
  const newArrayKitData = learner?.assignedEquipmentKits?.map((obj) => ({
    equipmentKitId: obj?.equipmentKitId,
  }));
  console.log(newArrayKitData, "newArrayKitData");

  const GetAssignedEquipment = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getEquipmentsByEquipmentIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ EquipmentIds: newArray }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setequipment(res.msg);
      } else {
        console.log("Check Skills Error ", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };
  const getEquipmentKitsByEquipmentKitIds = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getEquipmentKitsByEquipmentKitIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ EquipmentKitIds: newArrayKitData }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setEquipmentKit(res.msg);
      } else {
        console.log("Check Skills Error ", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  useEffect(() => {
    GetAssignedEquipment();
    getEquipmentKitsByEquipmentKitIds();
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        justify="center"
        alignItems="center"
      >
        <Tabs
          value={value}
          onChange={TabChange}
          centered
          aria-label="basic tabs example"
          className="GProcessTabStyle mt-20"
        >
          <Tab
            className="GProceesTabChanges"
            label="Equipment-Kit"
            {...a11yProps(0)}
          />
          <Tab
            className="GProceesTabChanges"
            label="Equipment"
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      <TabPanel value={value} index={0} className="TabPanelStyle">
        {" "}
        <EquipmentKit equipmentkit={equipmentkit} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {" "}
        <Equipment equipment={equipment} />
      </TabPanel>
    </>
  );
}

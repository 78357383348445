import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
//core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Radio(props) {
  const { TrainerId } = props;
  const { userToken } = useLogin();
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  const [lessonPlans, setLessonPlans] = useState({});

  useEffect(() => {
    getAllLessonPlans();
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const getAllLessonPlans = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?trainerId=${TrainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlans(res.msg);
        console.log("Lesson Plans =======>", res.msg[0]);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log("LPData in LEsson Plan", lessonPlans.createdOn);
  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead"> Title </TableCell>
                <TableCell className="ColHead"> Duration</TableCell>
                <TableCell className="ColHead"> PP</TableCell>
                <TableCell className="ColHead"> Created On </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {lessonPlans &&
                lessonPlans.length > 0 &&
                lessonPlans.map((lp) => (
                  <TableRow>
                    <TableCell className="Col">ST-101</TableCell>
                    <TableCell className="Col">{lp.LPTitle}</TableCell>
                    <TableCell className="Col"> {lp.LPDuration}</TableCell>
                    <TableCell className="Col"> {lp.LPXp}</TableCell>
                    <TableCell className="ActionButtonSize Col">
                      {lp.createdOn.split("T")[0]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

import React from "react";

import {
  AppBar,
  MenuItem,
  Select,
  FormControl,
  styled,
  InputBase,
  Toolbar,
  IconButton,
  makeStyles,
  Grid,
  InputAdornment,
  Input,
  Box,
} from "@material-ui/core";
import cx from "classnames";
import { Button } from "antd";
// material-ui icons
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
// core components
import { useLogin } from "../../Context/ExportContext";
import logo from "../../assets/img/logo-white.png";

const useStyles = makeStyles();
export default function AdminNavbar(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState(null);
  const { color, rtlActive, brandText } = props;

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  const { authHelper } = useLogin();
  const { signOut } = authHelper;
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <AppBar className="AppBar">
      <Toolbar className="AppToolbar">
        <div>
          <img src={logo} alt="logo" className="ZoomerImg" />
        </div>
        <Grid
          container
          style={{
            justifyContent: "center",
          }}
        >
          <Grid container item style={{ width: "38% " }}>
            <FormControl className="MainPageFormControl">
              <Input
                disableUnderline
                className="NavBarSearchEngine"
                placeholder="Serach Schools, Subject, Trianer,Skills...."
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton style={{ marginRight: "2px" }}>
                      <GoSearch className="SearchIcon" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Serach"
              />
            </FormControl>
          </Grid>
        </Grid>

        <div style={{ display: "flex" }}>
          <Button className="NotificationButton">
            <IoIosNotificationsOutline className="NotificationIcon" />
          </Button>
          <Button className="ProfileButton">
            <FaRegUserCircle className="ProfileIcon" />
          </Button>
          <FormControl className="NavSelect">
            <Select
              className="NavbarSelectStyle"
              disableUnderline
              variant="standard"
              onChange={handleChange}
              displayEmpty
              value={age ? age : "Admin"}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value="Admin"
                className="Selectmenustyle"
                className="NavSelect"
              >
                <em
                  className="Selectmenustyleem"
                  style={{ fontStyle: "normal" }}
                >
                  Principal
                </em>
              </MenuItem>
              <MenuItem className="NavbarSelectMenu" value={10}>
                My Profile
              </MenuItem>
              <MenuItem
                className="NavbarSelectMenu"
                className="NavbarSelectMenu"
                value={20}
              >
                Setting
              </MenuItem>
              <MenuItem
                className="NavbarSelectMenu"
                onClick={signOut}
                value={30}
              >
                Log Out
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </Toolbar>
    </AppBar>
  );
}

import * as React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Image from "../../../../assets/img/Zoomer.png";

export default function OrganisationDetails(props) {
  const { data } = props;
  console.log("   Admin Data", data);

  return (
    <Grid container spacing={3} className="SPContainerStyle">
      <Grid item container lg={4} md={5} xs={8} className=" MT">
        <Grid container item>
          <Grid
            item
            container
            xs={6}
            direction="column"
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="BlueColorTypography SPCardTypo">
                School Name
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Location
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Enrolled on
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                1st Term Start Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                1st Term End Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                2nd Term Start Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                2nd Term End Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                3rd Term Start Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                3rd Term End Date
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="GreyColorTypography SPCardTypo GtSideGridStyle">
                {data.organisationDetails.schoolName}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo GtSideGridStyle">
                {data.organisationDetails.location}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.enrolledOn?.split("T")[0]}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.firstTermStartDate?.split("T")[0]}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.firstTermEndDate?.split("T")[0]}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.secondTermStartDate?.split("T")[0]}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.secondTermEndDate?.split("T")[0]}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.thirdTermStartDate?.split("T")[0]}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.thirdTermEndDate?.split("T")[0]}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container lg={4} md={7} xs={8}>
        <Grid container item>
          <Grid
            item
            container
            xs={9}
            direction="column"
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="BlueColorTypography SPCardTypo">
                Active Grades
              </Typography>

              <Typography className="BlueColorTypography SPCardTypo">
                Assigned Courses
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Assigned LessonPlans
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Active Learners{" "}
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Active Trainers{" "}
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Active Users
              </Typography>
              {/* <Typography className="BlueColorTypography SPCardTypo">
                Leaderboard Rank{" "}
              </Typography> */}
            </div>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={3}
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="GreyColorTypography SPCardTypo">
                {data.grade.length}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.assignedLessonPlans.length}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.assignedLearners.length}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.assignedTrainers.length}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.assignedTrainers.length}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.assignedLearners.length}
              </Typography>
              {/* <Typography className="GreyColorTypography SPCardTypo">
                2{" "}
              </Typography> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container lg={4} md={6} xs={8}>
        <Grid item alignItems="center" xs={12} className="colorwhite">
          <Typography
            className="BlueColorTypography "
            align="center"
            style={{ marginTop: "10px" }}
          >
            School
          </Typography>
          <Typography align="center" className="BlueColorTypography ">
            Logo
          </Typography>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
            direction="column"
          >
            <Typography align="center" className="BlueColorTypography ">
              <img
                style={{
                  width: "250px",
                  height: "100%",
                  marginTop: "60px",
                  marginBottom: "60px",
                  objectFit: "contain",
                }}
                src={data.organisationDetails.schoolLogoImg}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Box,
  Tab,
  Tabs,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
//core Component
import { FETCH_URL } from "fetchIp";
// import OngoingTab from "./OngoingTab";
// import CompleteTab from "./LPCompleteTab";
import TAOngoingLessonPlanTab from "./TAOngoingLessonPlanTab";
import TAExpiredLessonPlanTab from "./TAExpiredLessonPlanTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TALessonPlanTab({ trainer }) {
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  // const completedLesssonPlan = trainer?.assignedLessonPlans?.filter(
  //   (item) => item.completion === true
  // );

  // const OngoingLessonPlan = trainer?.assignedLessonPlans?.filter(
  //   (item) => item.completion === false
  // );
  let currentDate = moment().format();

  const OngoingLessonPlan = trainer?.assignedLessonPlans?.filter(
    (item) => currentDate <= moment(item?.expireOn).format()
  );
  const completedLesssonPlan = trainer?.assignedLessonPlans?.filter(
    (item) => currentDate > moment(item?.expireOn).format()
  );

  const newArray = completedLesssonPlan?.map((obj) => ({
    lessonPlanId: obj?.lessonPlanId,
  }));
  const newArrayonGoing = OngoingLessonPlan?.map((obj) => ({
    lessonPlanId: obj?.lessonPlanId,
  }));

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Grid item conatiner>
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="GProcessTabStyle"
          >
            <Tab
              className="GProceesTabChanges"
              label="Ongoing"
              {...a11yProps(0)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Expired"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <TAOngoingLessonPlanTab lessonPlanOnGoing={newArrayonGoing} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TAExpiredLessonPlanTab lessonplan={newArray} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

import React from "react";
import { Typography, Grid, Input, TextareaAutosize } from "@material-ui/core";

export default function EquipmentProfile(props) {
  const { singleEquip } = props;
  const [equipmentUid, setEquipmentUid] = React.useState(null);
  const [equipmentName, setEquipmentName] = React.useState(null);
  const [equipmentDesc, setEquipmentDesc] = React.useState(null);
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            container
            direction="column"
            item
            style={{ marginLeft: "25px" }}
          >
            <Typography className="SkillsInputTitle  GreyColorTypography">
              Equipment UID <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Typography variant className="RubicInputs">
                {singleEquip.equipmentUid}
              </Typography>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Equipment Name <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="RubicInputs"
                value={singleEquip?.equipmentName}
                readOnly
              ></Input>
            </Grid>
            <Typography className="SkillsInputTitle GreyColorTypography">
              Equipment Description <span style={{ color: "red" }}> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <TextareaAutosize
                className="SkillTextArea"
                aria-label="minimum height"
                readOnly
                defaultValue={singleEquip?.equipmentDesc}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="GreyColorTypography  EquipMeadiaTypo  ">
            Media
          </Typography>
          <Grid item container className="ThumbnilGridStyle">
            <img
              style={{ width: "100%", height: "100%" }}
              src={singleEquip?.equipmentImg}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
//React Icons

import { BsFileEarmark } from "react-icons/bs";
//core Component
import GEquipmnetProfile from "../GEquipProfile/GPEquipmentProfile";
export default function RenameTab(props) {
  const [value, setValue] = React.useState(0);

  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const {
    states: { equipmentData },
    getAllEquipment,
  } = props;

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID</TableCell>
              <TableCell className="Head">Equipment Name</TableCell>
              <TableCell className="Head">Description</TableCell>
              <TableCell className="Head"> Assigned On</TableCell>
              <TableCell className="Head"> Quantity </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {equipmentData &&
              equipmentData.length &&
              equipmentData.map((equipment) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link>{equipment.equipmentUid} </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Link
                    // to={{
                    //   pathname: `/admin/equipmentprofile`,
                    // }}
                    >
                      <GEquipmnetProfile equipment={equipment} />{" "}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {equipment.equipmentDesc}
                  </TableCell>
                  <TableCell className="Col700WAB">14/09/2021</TableCell>
                  <TableCell className="Col700WAB">30</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

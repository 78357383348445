import HosDashboard from "hosviews/HosDashboard/HDashboard.js";
import HosLeaderBoard from "hosviews/HosLeaderBoard/HLeaderBoard";
import School from "hosviews/HosSchool/HSchool/HSchool.js";
import HosSchoolProfile from "hosviews/HosSchool/HSchool/HosSchoolProfile/SchoolDetails";
import Grade from "hosviews/HosSchool/HosGrade/Grade";
import GradeProfile from "hosviews/HosSchool/HosGrade/HosGradeProfile's/GradeProfieMainPage";
import Trainer from "hosviews/HosSchool/HosTrainer/Trainer";
import Learner from "hosviews/HosSchool/HosLearner/Learner";
import HosCourse from "hosviews/HosCourse/HosCourse";
import HosCourseProfile from "hosviews/HosCourse/HosCourseProfile/HosCProfile";
import Activity from "hosviews/HosActivityLibrary/HosActivityLibrary";
import ActivityProfile from "hosviews/HosActivityLibrary/ActivityProfile/HosActivityProfile";
import Lesson from "hosviews/HosLessonPlan/LessonPlans";
import LessonPlanProfile from "hosviews/HosLessonPlan/LessonPlans";
import Skill from "hosviews/HosSkills/HosSkills";
import SkillProfile from "hosviews/HosSkills/HosSkillsProfile/SkillProfileMain";
import Equipments from "hosviews/HosEquipments/Eqipments";
import EquipmentProfile from "hosviews/HosEquipments/EquipmentProfile/EquipmentProfile";
import KitProfile from "hosviews/HosEquipments/KitProfile/EquipmentKitProfile";
import Assessment from "hosviews/HosAssesments/Assesments";
import HosAssesmentProfile from "hosviews/HosAssesments/HosAssesmentProfile/HosAssesmentProfile";
import Events from "hosviews/HosEvent/HosEvents";
import User from "hosviews/HosUsers/HosUser";
import UserProfile from "hosviews/HosUsers/HosUserProfile/UserProfile";
//Other Core Component
import SignIn from "views/SignIn/SignIn";
//ReactIcons
import { FaSchool } from "react-icons/fa";
import { IoBody, IoSchoolOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { GiTeacher, GiSoccerBall } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import { BiBook } from "react-icons/bi";
import { BsFileEarmark, BsBook } from "react-icons/bs";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import MessageIcon from "@material-ui/icons/Message";
import { MdOutlineBook } from "react-icons/md";
import { BsBarChartLine, BsFillBarChartLineFill } from "react-icons/bs";
import { BsTrophy } from "react-icons/bs";

const hosRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: MdDashboard,
    component: HosDashboard,
    layout: "/hos",
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    rtlName: "لوحة القيادة",
    icon: BsFillBarChartLineFill,
    component: HosLeaderBoard,
    layout: "/hos",
  },
  {
    collapse: true,
    rtlName: "المكونات",
    icon: FaSchool,
    name: "School",
    state: "componentsCollapse",
    views: [
      {
        path: "/school",
        name: "School",
        rtlName: "وصفت",
        icon: IoSchoolOutline,
        rtlMini: "ب",
        component: School,
        layout: "/hos",
      },
      {
        invisible: true,
        path: "/schoolprofile",
        component: HosSchoolProfile,
        layout: "/hos",
      },

      {
        path: "/grade",
        name: "Grade",
        icon: BsFileEarmark,
        component: Grade,
        layout: "/hos",
      },
      {
        invisible: true,
        path: "/gradeprofile",
        component: GradeProfile,
        layout: "/hos",
      },

      {
        path: "/learner",
        name: "Learner",
        rtlName: "الحلو تنبيه",
        icon: AiOutlineUser,
        rtlMini: "ومن",
        component: Learner,
        layout: "/hos",
      },
      {
        path: "/trainer",
        name: "Trainer",
        rtlName: "إخطارات",
        icon: GiTeacher,
        rtlMini: "ن",
        component: Trainer,
        layout: "/hos",
      },
    ],
  },
  {
    path: "/course",
    name: "Courses",
    rtlName: "الرسوم البيانية",
    icon: BsBook,
    component: HosCourse,
    layout: "/hos",
  },

  {
    invisible: true,
    path: "/courseprofile",
    component: HosCourseProfile,
    layout: "/hos",
  },
  // {
  //   path: "/activity",
  //   name: "Activity Library",
  //   rtlName: "الرسوم البيانية",
  //   icon: IoBody,
  //   component: Activity,
  //   layout: "/hos",
  // },

  {
    invisible: true,
    path: "/activityprofile",
    component: ActivityProfile,
    layout: "/hos",
  },
  {
    path: "/Lesson",
    name: "Lesson Plans",
    rtlName: "الرسوم البيانية",
    icon: BiBook,
    component: Lesson,
    layout: "/hos",
  },

  {
    invisible: true,
    path: "/lessonplanprofile",
    component: LessonPlanProfile,
    layout: "/hos",
  },
  {
    path: "/skills",
    name: "Skills",
    rtlName: "التقويم",
    icon: BsBarChartLine,
    component: Skill,
    layout: "/hos",
  },
  {
    invisible: true,
    path: "/skillprofile",
    component: SkillProfile,
    layout: "/hos",
  },
  {
    path: "/equipments",
    name: "Equipments",
    rtlName: "الحاجيات",
    icon: GiSoccerBall,
    component: Equipments,
    layout: "/hos",
  },
  {
    invisible: true,
    path: "/equipmentprofile",
    component: EquipmentProfile,
    layout: "/hos",
  },
  {
    invisible: true,
    path: "/equipmentkitprofile",
    component: KitProfile,
    layout: "/hos",
  },
  {
    path: "/assesments",
    name: "Assesments",
    rtlName: "الحاجيات",
    icon: MdOutlineBook,
    component: Assessment,
    layout: "/hos",
  },
  {
    invisible: true,
    path: "/assesmentprofile",
    component: HosAssesmentProfile,
    layout: "/hos",
  },

  {
    path: "/events",
    name: "Events",
    icon: BsTrophy,
    component: Events,
    layout: "/hos",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleOutlineRoundedIcon,
    component: User,
    layout: "/hos",
  },
  {
    invisible: true,
    path: "/userprofile",
    component: UserProfile,
    layout: "/hos",
  },
  {
    invisible: true,
    path: "/signin",
    name: "SignIn",
    rtlName: "الحاجيات",
    icon: MessageIcon,
    component: SignIn,
    layout: "/auth",
  },
];
export default hosRoutes;

import React, { useState, useEffect } from "react";
import {
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import LoaderDialog from "components/Loader/LoaderDialog";

// ReactIcons
import { GoSearch } from "react-icons/go";
import { useSelector } from "react-redux";
// core components

import AssesmentTabs from "./AssesmentsTabMain";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function ExtendedTables() {
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [assesvalue, setAssesValue] = React.useState(0);
  const [value, setValue] = useState(0);
  const [totalA, setTotalA] = useState();
  const [pageA, setPageA] = useState(1);
  const [totalR, setTotalR] = useState();
  const [pageR, setPageR] = useState(1);
  const [searchA, setSearchA] = useState("");
  const [searchR, setSearchR] = useState("");
  const [learnerAssessmentData, setLearnerAssessmentData] = useState(null);
  const [trainerAssessmentData, setTrainerAssessmentData] = useState(null);
  const [learnerAssesmentResult, setLearnerAssesmentResult] = useState([]);
  ////////////////////////////////////////////////////////////////
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState();

  const [gradeSelected, setGradeSelected] = useState("");
  const [loading, setLoading] = useState(true);
  const [gradeValue, setGradeValue] = useState(null);
  const [searchText, setSearchText] = useState("");
  ////////////////////////////////////////////////////////////

  const AssesmentresultTabValue = useSelector(
    (state) => state?.GetTabvalue?.ARValue
  );
  const [gradeData, setGradeData] = useState([]);
  const getAllGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // Get API
  //paginated Learner
  const getPaginatedAssesmentLearner = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAllAssessments?isLearner=true&schoolId=${schoolDetail?.schoolId}&gradeId=${gradeValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setLearnerAssessmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  const getPaginatedAssesmentTrainer = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAllAssessments?isTrainer=true&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTrainerAssessmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  //Result APi
  const getAssesmentResults = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getpaginatedFilteredAssessmentResult?isLearner=true&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("learnerAssesmentResult", res);
        console.log("result", res.msg);
        setLearnerAssesmentResult(res.msg);
        setTotalR(res.lengthData);
      } else {
        console.log("Error while fetching Assesment result", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  //Result APi
  //handle search
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (AssesmentresultTabValue === 0) {
      let url;
      setPageA(pageNum ? pageNum : 1);
      setLoading(true);
      if (searchValue) {
        setSchoolSelected("");
        setSchoolLocationSelected("");
        url = `${FETCH_URL}/api/assessment/getPaginatedAssessments?isLearner=true&search=${searchValue}&schoolId=${schoolDetail?.schoolId}`;
      } else {
        url = `${FETCH_URL}/api/assessment/getPaginatedAssessments?isLearner=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}&schoolId=${schoolDetail?.schoolId}`;
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLearnerAssessmentData(res.msg);
        setLoading(false);
        // setTrainerAssessmentData(res.msg);
        console.log("My searching course response ==>", res);

        // getAllGrades();
      } else {
        setLoading(false);
        console.log("Error while searching course", res.err);
      }
    } else {
      let url;
      setPageR(pageNum ? pageNum : 1);
      setLoading(true);
      if (searchValue) {
        setSchoolSelected("");
        setSchoolLocationSelected("");
        url = `${FETCH_URL}/api/assessment/getpaginatedFilteredAssessmentResult?isLearner=true&search=${searchValue}&schoolId=${schoolDetail?.schoolId}`;
      } else {
        url = `${FETCH_URL}/api/assessment/getpaginatedFilteredAssessmentResult?isLearner=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}&schoolId=${schoolDetail?.schoolId}`;
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLearnerAssesmentResult(res.msg);
        console.log("Result", res.msg);
        setLoading(false);
        // setTrainerAssessmentData(res.msg);
        console.log("My searching course response ==>", res);

        // getAllGrades();
      } else {
        setLoading(false);
        console.log("Error while searching course", res.err);
      }
    }
  };
  useEffect(() => {
    getAllGrade();
  }, []);
  useEffect(() => {
    if (value == 0) {
      getPaginatedAssesmentLearner();
    } else {
      getAssesmentResults();
    }
  }, [value, gradeValue]);
  return (
    <>
      <Grid container className="MT-3 ML-10px">
        <Grid container item className="  TitleGridStyle">
          <div>
            <Grid container>
              {" "}
              <Typography className="BlueColorTypography LPTitle  PL-5">
                {`${schoolDetail?.schoolName} ${schoolDetail?.location} `}
              </Typography>
              <FormControl variant="standard" className="ML-20px">
                <Select
                  className=" EventDatedropstyle"
                  value={gradeValue ? gradeValue : 0}
                  defaultValue={0}
                  disableUnderline
                  onChange={(e) => {
                    setSearchText("");

                    setGradeValue(e.target.value);
                  }}
                  label="Select grade"
                >
                  <MenuItem value={0} className="Eventmenustyle" disabled>
                    Select grade
                  </MenuItem>
                  {gradeData?.map((a, i) => (
                    <MenuItem key={i} className="Eventmenustyle" value={a?._id}>
                      {a?.grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </div>

          <Grid item>
            <div>
              <Box>
                <div>
                  <FormControl>
                    <Input
                      disableUnderline
                      className="input"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        setGradeValue(null);
                        handleSearch(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className="HeadSearchButton">
                            <GoSearch className=" HaedAddIcon" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Serach"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item md={6} sm={6} xs={6} lg={6}>
        <Typography
          className="BlueColorTypography LPTitle ML-10px "
          style={{ marginTop: "25px" }}
        >
          Assigned Assesments
        </Typography>
      </Grid>{" "}
      <AssesmentTabs
        getAllAssesmentLearner={getPaginatedAssesmentLearner}
        getAllAssesmentTrainer={getPaginatedAssesmentTrainer}
        getAssesmentResults={getAssesmentResults}
        handleSearch={handleSearch}
        value={value}
        setValue={setValue}
        states={{
          learnerAssessmentData,
          setLearnerAssessmentData,
          trainerAssessmentData,
          setTrainerAssessmentData,
          learnerAssesmentResult,
          setLearnerAssesmentResult,
          assesvalue,
          setAssesValue,
          schoolSelected,
          setSchoolSelected,
          setSchoolLocationSelected,
          setGradeSelected,
          setSearchText,
          setGradeValue,
          totalA,
          pageA,
          totalR,
          pageR,
          searchA,
          searchR,
        }}
      />
    </>
  );
}

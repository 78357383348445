import * as React from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Button,
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";

//ReactIcons
import { IoCloseSharp } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleSmall" {...other}>
      <Typography className="DialogTitleTypographySmall">
        Are you sure you want to disable this item?{" "}
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ accessCheck }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [checked, setChecked] = React.useState(true);

  const handleApiFunc = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(false);
    setChecked(false);
  };
  const SwitchClick = () => {
    setOpen(true);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <Switch
        {...label}
        className="SwitchStyle"
        open={open}
        checked={checked}
        onChange={() => {
          accessCheck === 1 ? SwitchClick() : handleApiFunc();
        }}
      />

      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogActions>
          <Button
            className="GreyButton  SwitchCancelB"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton  SwitchOkB"
            autoFocus
            onClick={() => {
              accessCheck === 1 ? handleClick() : handleApiFunc();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

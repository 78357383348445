import React, { useState } from "react";

import {
  Grid,
  Input,
  Button,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";
//React Icons
import { IoIosAddCircle } from "react-icons/io";

export default function ActivityTab() {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            direction="column"
            style={{ marginLeft: "20px", marginBottom: "10px " }}
          >
            <Grid container direction="column" item>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Activity Name <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1"></Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                UID <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1"></Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Description <span style={{ color: "red" }}> *</span>
              </Typography>{" "}
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="ActivityTextArea"
                  aria-label="minimum height"
                />{" "}
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Level<span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1"> </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                XP Reward<span style={{ color: "red" }}> *</span>
              </Typography>{" "}
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1"> </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Link<span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1"> </Input>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid itemmd={11} sm={11} xs={11} lg={11} container>
            <Grid
              item
              container
              md={11}
              sm={11}
              xs={11}
              lg={11}
              style={{
                backgroundColor: "white",
                marginTop: "3%",
                borderRadius: "15px",
                marginRight: "20px",
                border: "1px solid #d4d4d4",
              }}
            >
              <Typography className="BlueColorTypography AddLogoTypography">
                Add Preview
              </Typography>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  height: "192px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button className="GreenButton700 AlChooseFileButton">
                    <IoIosAddCircle
                      size="1.6em"
                      style={{ marginRight: "4px" }}
                    />{" "}
                    Choose File
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item container md={12} sm={12} xs={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "flex-end",
                  width: "100%",
                  marginTop: "20px ",
                }}
              >
                <div>
                  <Button className="GreenButton700 AlNextButton">Next</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

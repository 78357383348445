import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CreatorMode from "./CraetorMode";
import Navbar from "./Appbar";
export default function App() {
  return (
    <>
      {/* <Navbar /> */}
      <Grid container>
        {/* <Grid item sm={2}></Grid> */}
        <Grid item sm={12}>
          <CreatorMode />
        </Grid>

        {/* <Grid item sm={2}></Grid> */}
      </Grid>
    </>
  );
}

// import * as React from "react";
// import PropTypes from "prop-types";
// import moment from "moment";

// import {
//   Input,
//   FormControl,
//   Select,
//   Typography,
//   Button,
//   TextareaAutosize,
//   Grid,
//   DialogActions,
//   MenuItem,
// } from "@material-ui/core";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// export default function RenameTab(props) {
//   const { TabNext } = props;

//   const {
//     states: {
//       lessonPlanUid,
//       setLessonPlanUid,
//       lessonPlanStartDate,
//       setLessonPlanStartDate,
//       lessonPlanEndate,
//       setLessonPlanEndate,
//       lessonPlanTitle,
//       setLessonPlanTitle,
//       lessonPlanUidText,
//       lessonPlanTitleText,
//     },
//   } = props;

//   return (
//     <>
//       <Grid container className="DailogLTRBStyle">
//         <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
//           <Grid
//             item
//             container
//             direction="column"
//             style={{ marginLeft: "25px" }}
//           >
//             <Typography className="InputTitleTypography  GreyColorTypography">
//               Assement UID<span className="ImpColor"> *</span>
//             </Typography>
//             <Grid item container md={10} sm={10} xs={10} lg={10}>
//               <Input
//                 className="Input1"
//                 value={lessonPlanUid}
//                 onChange={(e) => setLessonPlanUid(e.target.value)}
//               ></Input>
//             </Grid>
//             {lessonPlanUid.length === 0 && (
//               <Typography className="warningTypography">
//                 {lessonPlanUidText}
//               </Typography>
//             )}

//             <Typography className="InputTitleTypography  GreyColorTypography">
//               Assement Title<span className="ImpColor"> *</span>
//             </Typography>
//             <Grid item container md={10} sm={10} xs={10} lg={10}>
//               <Input
//                 className="Input1"
//                 value={lessonPlanTitle}
//                 onChange={(e) => setLessonPlanTitle(e.target.value)}
//               ></Input>
//             </Grid>
//             {lessonPlanTitle.length === 0 && (
//               <Typography className="warningTypography">
//                 {lessonPlanTitleText}
//               </Typography>
//             )}
//           </Grid>
//         </Grid>
//         <Grid
//           item
//           md={6}
//           sm={6}
//           xs={6}
//           lg={6}
//           style={{ marginBottom: "308px " }}
//           direction="column"
//         >
//           <Grid
//             item
//             container
//             direction="column"
//             style={{ marginLeft: "25px" }}
//           >
//             <Typography className="InputTitleTypography  BlueColorTypography">
//               Start Date <span style={{ color: "red" }}> *</span>
//             </Typography>
//             <Grid item md={10} sm={10} xs={10} lg={10}>
//               <MuiPickersUtilsProvider
//                 className="Calender"
//                 utils={DateFnsUtils}
//               >
//                 <Grid item md={12} sm={12} xs={12} lg={12}>
//                   <KeyboardDatePicker
//                     className="Calender"
//                     format="dd/MM/yyyy"
//                     placeholder="DD/MM/YYYY"
//                     value={lessonPlanStartDate}
//                     onChange={(e) => setLessonPlanStartDate(e)}
//                     KeyboardButtonProps={{
//                       "aria-label": "change date",
//                     }}
//                   />
//                 </Grid>
//               </MuiPickersUtilsProvider>
//             </Grid>
//             <Typography className="InputTitleTypography  BlueColorTypography">
//               End Date <span style={{ color: "red" }}> *</span>
//             </Typography>
//             <Grid item md={10} sm={10} xs={10} lg={10}>
//               <MuiPickersUtilsProvider
//                 className="Calender"
//                 utils={DateFnsUtils}
//               >
//                 <Grid item md={12} sm={12} xs={12} lg={12}>
//                   <KeyboardDatePicker
//                     className="Calender"
//                     format="dd/MM/yyyy"
//                     value={lessonPlanEndate}
//                     placeholder="DD/MM/YYYY"
//                     minDate={moment(
//                       moment(lessonPlanStartDate).format("YYYY-MM-DD")
//                     ).add(1, "days")}
//                     onChange={(e) => setLessonPlanEndate(e)}
//                     KeyboardButtonProps={{
//                       "aria-label": "change date",
//                     }}
//                   />
//                 </Grid>
//               </MuiPickersUtilsProvider>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
export default function RenameTab(props) {
  const { TabNext } = props;

  const {
    states: {
      lessonPlanUid,
      setLessonPlanUid,
      lessonPlanStartDate,
      setLessonPlanStartDate,
      lessonPlanEndate,
      setLessonPlanEndate,
      lessonPlanTitle,
      setLessonPlanTitle,
      lessonPlanUidText,
      lessonPlanTitleText,
    },
  } = props;

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  GreyColorTypography">
              Assement UID<span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                value={lessonPlanUid}
                onChange={(e) => setLessonPlanUid(e.target.value)}
              ></Input>
            </Grid>
            {lessonPlanUid.length === 0 && (
              <Typography className="warningTypography">
                {lessonPlanUidText}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="InputTitleTypography  GreyColorTypography">
            Assement Title<span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              value={lessonPlanTitle}
              onChange={(e) => setLessonPlanTitle(e.target.value)}
            ></Input>
          </Grid>
          {!lessonPlanTitle && (
            <Typography className="warningTypography">
              {lessonPlanTitleText}
            </Typography>
          )}
        </Grid>
        {/* <Grid
          item
          md={6}
          sm={6}
          xs={6}
          lg={6}
          style={{ marginBottom: "308px " }}
          direction="column"
        >
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  BlueColorTypography">
              Start Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    value={lessonPlanStartDate}
                    onChange={(e) => setLessonPlanStartDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography className="InputTitleTypography  BlueColorTypography">
              End Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="dd/MM/yyyy"
                    value={lessonPlanEndate}
                    placeholder="DD/MM/YYYY"
                    minDate={moment(
                      moment(lessonPlanStartDate).format("YYYY-MM-DD")
                    ).add(1, "days")}
                    onChange={(e) => setLessonPlanEndate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}

import CreatorMode from "views/CreatorMode/App.js";
import Pages from "views/CreatorMode/CreatorModeTab/NOFPage";
import Editor from "views/CreatorMode/template-editor/index";
const grapesRoutes = [
  {
    invisible: true,
    path: "/CaretorMode",
    component: CreatorMode,
    layout: "/grapes",
  },
  {
    invisible: true,
    path: "/folder/:folderId",
    component: Pages,
    layout: "/grapes",
  },
  {
    invisible: true,
    path: "/editor/:pageId",
    component: Editor,
    layout: "/grapes",
  },
];

export default grapesRoutes;

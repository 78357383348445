import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  TextField,
  Chip,
  IconButton,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FiPlus } from "react-icons/fi";

import "date-fns";

// ReactIcons
import { IoIosAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
// Import Files
import AssignEquipment from "../AssignEquipment";
import AssignSkills from "../AssignSkillsDialog";

export default function AboutLP(props) {
  const {
    states: {
      inputDisable,
      setInputDisable,
      uid,
      setUid,
      duration,
      setDuration,
      durationSlot,
      price,
      setPrice,
      folderSelected,
      setFolderSelected,
      playpoints,
      setPlayPoints,
      isForTrainer,
      setIsForTrainer,
      isForLearner,
      setIsForLearner,
      title,
      setTitle,
      grade,
      setGrade,
      sports,
      setSports,
      about,
      setAbout,
      open,
      setOpen,
      openEquip,
      setOpenEquip,
      id,
      calculateLPXp,
      foldername,
      file,
      setFile,
      media,
      setMedia,
      mediatype,
      setMediaType,
      originalData,
      bmoriginalData,
      ocoriginalData,
      locomotorP,
      setLocomotorP,
      bodyManagementP,
      setBodyManagementP,
      objectControlP,
      setObjectControlP,
      skillstore,
      assignequipment,
      Equiomentstore,
      handleChangeDuration,
      sportsValid,
      medaiValid,
      skillsValid,
      equipmentValid,
      imageValid,
    },
    MediaUploadClick,
    reuploadClick,
    MediaUploader,
    ImageUploader,
    storeSkill,
    BMstoreSkill,
    OCstoreSkill,
    storeEquipment,
    top100Films,
    lplearner,
    EditSubmitClick,
  } = props;

  console.log("folderSelected", folderSelected);
  const [inputValue, setInputValue] = React.useState("");
  const SkillsData = skillstore.slice(0, 5);
  const totalCount = skillstore.length - 5;
  const EquipmentData = assignequipment.slice(0, 5);
  const totalEquipmentCount = assignequipment.length - 5;
  console.log("Check assignequipment", assignequipment);
  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid container className="ml-18">
            <Grid container md={12} sm={12} xs={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Typography className="InputTitle">LP UID </Typography>
                  <Input
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
                    disableUnderline
                    disabled={true}
                    className="InputStyle"
                  />
                </Grid>
                <Grid item md={5}>
                  <Typography className="InputTitle">LP Duration</Typography>
                  <Grid container className="border-grey b-r10 ">
                    <Grid
                      item
                      md={5}
                      style={{ borderRight: "1px solid #d4d4d4" }}
                    >
                      <Input
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        disableUnderline
                        disabled={inputDisable ? true : false}
                        className="InputStyle-no-br width100"
                      />
                    </Grid>
                    <Grid item md={7}>
                      <FormControl className="MainPageFormControl  width100">
                        <Select
                          className="Selectdrop-no  hgt-33"
                          value={durationSlot}
                          onChange={handleChangeDuration}
                          disabled={inputDisable ? true : false}
                          label="Select School"
                          disableUnderline
                        >
                          <MenuItem className="Selectmenustyle" value={"Month"}>
                            Month
                          </MenuItem>
                          <MenuItem className="Selectmenustyle" value={"Days"}>
                            Days
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <Typography className="InputTitle">Price</Typography>
                  <Input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    disabled={inputDisable ? true : false}
                    disableUnderline
                    className="InputStyle"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={8}>
                <Typography className="LPTypo BlueColorTypography">
                  Lesson Plan Folders
                </Typography>
                <FormControl variant="standard" className="FormControlInput">
                  <Select
                    className="Selectdrop-no border-grey hgt-33"
                    disabled={inputDisable ? true : false}
                    value={folderSelected}
                    onChange={(e) => {
                      setFolderSelected(e.target.value);
                      calculateLPXp(e.target.value);
                    }}
                    disableUnderline
                  >
                    {foldername &&
                      foldername?.length > 0 &&
                      foldername?.map((data, index) => (
                        <MenuItem value={data._id} className="Selectmenustyle">
                          {data.folderName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <Typography className="InputTitle">Play Points </Typography>
                <Input
                  value={playpoints}
                  // onChange={(e) => setPlayPoints(e.target.value)}
                  disabled={true}
                  disableUnderline
                  className="InputStyle"
                />
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Typography className="InputTitle">LP Title </Typography>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                disableUnderline
                disabled={inputDisable ? true : false}
                className="InputStyle"
              />
            </Grid>
            <Grid container direction="row" className="mt-18">
              <Typography className="BlueColorTypography">Users</Typography>
              <FormGroup className="ml-16">
                <FormControlLabel
                  control={<Checkbox checked={isForTrainer ? true : false} />}
                  disabled={inputDisable ? true : false}
                  className="CheckStyleRecommendation"
                  label="Trainer"
                  value={isForTrainer}
                  onChange={(e) => {
                    setIsForTrainer(!isForTrainer);
                  }}
                />
              </FormGroup>
              <FormGroup className="ml-16">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isForLearner ? true : false}
                      className="CheckStyleRecommendation"
                    />
                  }
                  disabled={inputDisable ? true : false}
                  className="CheckStyleRecommendation"
                  label="Learner"
                  value={isForLearner}
                  onChange={(e) => {
                    setIsForLearner(!isForLearner);
                  }}
                />
              </FormGroup>
            </Grid>

            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                className="autocomplete-course"
                size="small"
                options={top100Films}
                getOptionLabel={(option) => option}
                value={grade}
                onChange={(event, value, data) => {
                  setGrade(value);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                disabled={inputDisable ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Grade"
                    className="width90"
                    disabled={inputDisable ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Typography
                className={sportsValid ? "InputTitle-warn" : "InputTitle"}
              >
                Sports <span className="ImpColor"> * </span>
              </Typography>
              <TagsInput
                value={sports}
                placeholder="Add a sports tag"
                className={
                  sportsValid ? "InputStyle-warn tags" : "InputStyle tags"
                }
                onChange={(event) => setSports(event)}
                disabled={inputDisable ? true : false}
              />
            </Grid>
            <Grid item md={12} className="mt-18">
              <div>
                <Typography className="TypographyTitle">About </Typography>
                <div>
                  <TextareaAutosize
                    className="TextArea width90"
                    aria-label="minimum height"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    disabled={inputDisable ? true : false}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography
              className={
                skillsValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10"
              }
            >
              Add Skills <span className="ImpColor"> * </span>
            </Typography>
            <div
              className={
                skillsValid
                  ? "Thumbnailform  border-red"
                  : "Thumbnailform border-grey"
              }
            >
              {open === false ? (
                <>
                  {SkillsData?.map((data) => {
                    let icon;

                    if (data.subSkill == "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <Chip
                        icon={icon}
                        label={data?.subSkill}
                        className="Course-SkillChip"
                      />
                    );
                  })}
                  {skillstore.length > 5 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}

              <AssignSkills
                LessonplanId={id}
                storeSkill={storeSkill}
                BMstoreSkill={BMstoreSkill}
                OCstoreSkill={OCstoreSkill}
                locomotorP={locomotorP}
                setLocomotorP={setLocomotorP}
                bodyManagementP={bodyManagementP}
                setBodyManagementP={setBodyManagementP}
                objectControlP={objectControlP}
                setObjectControlP={setObjectControlP}
                skillstore={skillstore}
                states={{
                  open,
                  setOpen,
                  originalData,
                  bmoriginalData,
                  inputDisable,
                  ocoriginalData,
                }}
              />
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography className="TypographyTitle mt-16">
              Add Thumbnail
            </Typography>
            <Grid item className="ChooseFileButtonItem ">
              {file ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    imageValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                >
                  {inputDisable ? null : (
                    <IconButton
                      aria-label="close"
                      className="DialogCloseButton"
                      onClick={() => {
                        reuploadClick();
                        setFile(undefined);
                      }}
                    >
                      <IoMdClose className="DialogCloseIcon" />
                    </IconButton>
                  )}
                  <img src={file} className="image-thumb" />{" "}
                </Grid>
              ) : (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="raised-button-file"
                    accept="image/*"
                    onChange={ImageUploader}
                  />
                  <Grid
                    item
                    md={12}
                    className={
                      imageValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                  >
                    <label htmlFor="raised-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle className="cursor Icon-Color mt-24 fs-100" />
                      </Typography>
                    </label>
                    <Typography
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12 width80-LR"
                    >
                      Click here to upload image
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography
              className={
                equipmentValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10"
              }
            >
              Add Equipment <span className="ImpColor"> * </span>
            </Typography>
            <div
              className={
                equipmentValid
                  ? "Thumbnailform  border-red mb20px"
                  : "Thumbnailform border-grey mb20px"
              }
            >
              {openEquip === false ? (
                <>
                  {EquipmentData?.map((data) => {
                    let icon;

                    if (data?.name === "React") {
                      icon = <TagFacesIcon />;
                    }
                    if (data != null) {
                      return (
                        <Chip
                          icon={icon}
                          label={data?.name}
                          className="Course-SkillChip"
                        />
                      );
                    }
                  })}
                  {EquipmentData.length > 5 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalEquipmentCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}
              <AssignEquipment
                storeEquipment={storeEquipment}
                states={{
                  assignequipment,
                  inputDisable,
                  openEquip,
                  setOpenEquip,
                }}
              />
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography className="TypographyTitle mt-16">
              Add Hero Conent{" "}
            </Typography>
            <Grid item className="ChooseFileButtonItem ">
              {media ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    medaiValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                >
                  {inputDisable ? null : (
                    <IconButton
                      aria-label="close"
                      className="DialogCloseButton"
                      onClick={() => {
                        MediaUploadClick();
                        setMedia(undefined);
                        setMediaType(undefined);
                      }}
                    >
                      <IoMdClose className="DialogCloseIcon" />
                    </IconButton>
                  )}

                  {/* {mediatype == "video/mp4" ||
                  lplearner?.heroContent?.type == "video" ? (
                    <video
                      className="image-thumb"
                      autoplay
                      muted
                      src={media}
                      type="video/mp4"
                    />
                  ) : (
                    <img src={media} className="image-thumb" />
                  )} */}
                  <img src={media} className="image-thumb" />
                </Grid>
              ) : (
                <>
                  {mediatype === "video/mp4" ? (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="raised-button-file"
                        accept="image/*"
                        onChange={(e) => {
                          MediaUploader(e);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="raised-button-file"
                        accept="image/*"
                        onChange={(e) => {
                          MediaUploader(e);
                        }}
                      />
                    </>
                  )}
                  <Grid
                    item
                    md={12}
                    className={
                      medaiValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                  >
                    <label htmlFor="raised-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle className="cursor Icon-Color mt-24 fs-100" />
                      </Typography>
                    </label>
                    <Typography
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12 width80-LR"
                    >
                      Click here to upload image/video
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <DialogActions className="mt-24 mb-20 width100">
          {inputDisable ? (
            <Button
              className="GreenButton700 next-btn"
              onClick={() => setInputDisable(false)}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                className="GreenButton-border next-btn"
                onClick={() => setInputDisable(true)}
              >
                Cancel
              </Button>
              <Button
                className="GreenButton700 next-btn"
                onClick={() => EditSubmitClick()}
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { Box, Tab, Tabs } from "@material-ui/core";

// core components
import EquipmentTab from "./GEquipmentTab's/GEquipmentTab";
import KitTab from "./GEquipmentTab's/GKitTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EquipmnetTabs(props) {
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    states: { equipmentData, equipmentKit, value, setValue },
    equipment,
    getAllEquipment,
    getAllEquipmentKIT,
  } = props;

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Equipment"
            {...a11yProps(0)}
          />
          <Tab className="ActivityTabChanges" label="Kit" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <EquipmentTab
          equipment={equipment}
          states={{ equipmentData }}
          getAllEquipment={getAllEquipment}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KitTab
          states={{ equipmentKit }}
          getAllEquipmentKIT={getAllEquipmentKIT}
        />
      </TabPanel>
    </>
  );
}

import React from "react";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

export default function OverviewTab() {
  return (
    <>
      <Typography className="GreyColorTypography400">
        Zoommer is a physical literacy program for young children aged 3 years
        and above. Physical Literacy is developed by continuously working on the
        Fundamental Movement Skills (FMS). FMS are the building blocks to more
        complex skills and movement patterns used in games, sport, dance and
        other recreational activities.
      </Typography>
      <Typography className="GreyColorTypography400 GPOTitle1">
        Just like when we are learning to speak, we first learn the ABC, then
        the phonetics (sounds of the ABC), then move on to learning simple words
        like Mat, Sat, Cat and finally we learn to string these simple words in
        to sentences.
      </Typography>
      <Typography className="GreyColorTypography400 GPOTitle1">
        In the same way FMS help us with developing basic movement skills which
        when linked together create a movement phrase that depicts a sport. For
        eg: basic skills required to play basketball will be: Running, Hopping,
        Jumping, Catching, Throwing, Bouncing, Static and Dynamic balance etc.
      </Typography>
      <Typography className="GreyColorTypography400 GPOTitle1">
        These skills are first taught in isolation and then slowly linked
        together. This is how sport is taught all around the world.
      </Typography>
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Box,
  Tabs,
  Breadcrumbs,
  Tab,
  Button,
  Typography,
} from "@material-ui/core";
//React Icons
import { IoIosArrowBack } from "react-icons/io";

// @material-ui/core components
import SkillsEquipTab from "./Skill&Equipment/Skills&Equipmnet";
import VarientsTab from "./ActivityEditTabs/Varients/EditVarientDialog";
import ActivityDetails from "./ActivityTabDetail";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables() {
  const history = useHistory();

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div>

      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Activity{" "}
          </Button>
          <div className="Breadcrumb2">Add Activity</div>
        </Breadcrumbs>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            className="ActivityTabsStyle"
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="ActivityTabChanges"
              label="Activity Detail"
              {...a11yProps(0)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Variants"
              {...a11yProps(1)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Skills & Equipments"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ActivityDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VarientsTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SkillsEquipTab />
        </TabPanel>
      </Box>
    </>
  );
}

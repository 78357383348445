import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
//Import  Css component
import "./assets/css/Style.css";
import "./assets/css/HosPanel.css";
import "./assets/css/PrincipalPanel.css";
//Import core component
import { Login } from "./Context/ExportContext";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import HosLayout from "layouts/Hos.js";
import PrincipalLayout from "layouts/Principal.js";
import GrapesLayout from "layouts/Graphe";
import store from "./Store.js";
import LoaderDialog from "./components/Loader/LoaderDialog";
import { FETCH_URL } from "fetchIp";

export default function App() {
  const [userToken, setUserToken] = useState(null);
  const [role, setRole] = React.useState(null);
  const [schoolDetail, setSchoolDetail] = useState({});
  const [departmentDetail, setDepartmentDetail] = useState({});
  const [userId, setUserId] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const authHelper = useMemo(() => {
    return {
      signIn: async () => {
        try {
          const token = localStorage.getItem("loginToken");
          const role = localStorage.getItem("userrole");
          console.log(" Check Role", role);
          if (token !== null) {
            console.log("Token is =====>", token);
            setUserToken(token);
            //setRole(role);
          }
          if (role !== null) {
            console.log("Role is =====>", token);

            // setRole(0);
            setRole(role);
          }
        } catch (e) {
          console.log("Token not found ", e);
        }
      },
      signOut: async () => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("userrole");
        setUserToken(null);
        setRole(null);
      },
    };
  }, []);

  const checkLoginStatus = async () => {
    try {
      const token = localStorage.getItem("loginToken");
      const role = localStorage.getItem("userrole");
      console.log("check token inside checkloginstatus() =======<", token);
      console.log("check role inside checkloginstatus() =======>", role);
      if (token !== null) {
        // value previously stored
        console.log("Login status is true =====>", token);
        setUserToken(token);
        //setRole(role);
      }
      if (role !== null) {
        // value previously stored

        try {
          const response = await fetch(
            `${FETCH_URL}/api/auth/getSingleUser`, //we find by token that consist id and role
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            if (role == 3) {
              setSchoolDetail(res.msg.assignedSchool);
              setUserId(res.msg._id);
            } else if (role == 2) {
              setDepartmentDetail(res?.msg?.departmentId);
            }
          } else {
          }
        } catch (error) {
          console.log("Catch block ====>", error);
        }

        console.log("Login status is true =====>", token);
        //setUserToken(token);
        setRole(role);
      }
    } catch (e) {
      console.log("Token not found ", e);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, [userToken]);
  const loadingHandler = (bool) => {
    setLoading(bool);
  };
  // useEffect(() => {
  //   if (userToken) {
  //     window.location.replace("https://www.google.com/");
  //   }
  // }, [userToken]);
  return (
    <>
      <Login.Provider
        value={{
          userToken,
          setUserToken,
          authHelper,
          userId,
          schoolDetail,
          loading,
          loadingHandler,
          departmentDetail,
        }}
      >
        {<LoaderDialog loading={loading} />}
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              {userToken ? (
                role && role == 0 ? (
                  <>
                    <Redirect exact from="/" to="/admin" />
                    <Route path="/admin" component={AdminLayout} />
                    <Route path="/grapes" component={GrapesLayout} />
                  </>
                ) : role && role == 2 ? (
                  <>
                    <Redirect exact from="/" to="/hos" />
                    <Route path="/hos" component={HosLayout} />
                  </>
                ) : role && role == 3 ? (
                  <>
                    <Redirect exact from="/" to="/principal" />
                    <Route path="/principal" component={PrincipalLayout} />
                  </>
                ) : (
                  <>
                    <Route path="/auth" component={AuthLayout} />
                    <Redirect exact from="/" to="/auth" />
                    <Redirect exact from="*" to="/auth" />
                  </>
                )
              ) : (
                <>
                  <Route path="/auth" component={AuthLayout} />
                  <Redirect exact from="/" to="/auth" />
                  <Redirect exact from="*" to="/auth" />
                </>
              )}
            </Switch>
          </BrowserRouter>
        </Provider>
      </Login.Provider>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

import { Tab, Tabs, Box } from "@material-ui/core";
// ReactIcons
/* Importing a component that is not defined. */
import TrainerTab from "./TrainerTable";
import LearnerTab from "./LearnerTable";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssignedTab(props) {
  const {
    states: {
      foldername,
      total,
      trainerlessonPlans,
      setTrainerLessonPlans,
      learnerLessonPlans,
      setLearnerLessonPlans,
      value,
      setValue,
      pageNoL,
      setPageNoL,
      totalL,
      setTotalL,
      rowsPerPageL,
      setRowsPerPageL,
      pageNoT,
      setPageNoT,
      totalT,
      setTotalT,
      rowsPerPageT,
      setRowsPerPageT,
      searchDataL,
      setSearchDataL,
      searchDataT,
      setSearchDataT,
    },
    GetFolder,
    getTrainerLessonPlan,
    getLearnerLessonPlan,
    TabChange,
    LeanerPaginatedData,
    TrainerPaginatedData,
  } = props;

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Learner"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Trainer"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LearnerTab
          states={{
            foldername,
            learnerLessonPlans,
            setLearnerLessonPlans,
            total,
            pageNoL,
            setPageNoL,
            totalL,
            setTotalL,
            rowsPerPageL,
            setRowsPerPageL,
            searchDataL,
            setSearchDataL,
          }}
          getLearnerLessonPlan={getLearnerLessonPlan}
          GetFolder={GetFolder}
          LeanerPaginatedData={LeanerPaginatedData}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TrainerTab
          states={{
            foldername,
            trainerlessonPlans,
            setTrainerLessonPlans,
            pageNoT,
            setPageNoT,
            totalT,
            setTotalT,
            rowsPerPageT,
            setRowsPerPageT,
            searchDataT,
            setSearchDataT,
          }}
          getTrainerLessonPlan={getTrainerLessonPlan}
          GetFolder={GetFolder}
          TrainerPaginatedData={TrainerPaginatedData}
        />
      </TabPanel>
    </>
  );
}

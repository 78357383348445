import React, { useState, useEffect } from "react";
import {
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
// material-ui icons
import { GoSearch } from "react-icons/go";
// core components
import EquipmentTab from "./EquipmentTabs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Equipment() {
  const [value, setValue] = React.useState(0);
  const { userToken, schoolDetail, loadingHandler } = useLogin();

  const [equipmentData, setEquipmentData] = React.useState(null);
  const [equipmentKit, setEquipmentKit] = React.useState(null);
  //
  const [pageNoE, setPageNoE] = useState(1);
  const [rowsPerPageE, setRowsPerPageE] = useState(10);
  const [pageNoEK, setPageNoEK] = useState(1);
  const [rowsPerPageEK, setRowsPerPageEK] = useState(10);
  const [searchDataE, setSerachDataE] = useState();
  const [searchDataEK, setSerachDataEK] = useState();
  // Get API
  const getAllEquipment = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipments?pagination=true&page=1&schoolId=${schoolDetail?.schoolId}&limit=10`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setEquipmentData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  // KITAPI
  const getAllEquipmentKIT = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipmentKits?pagination=true&schoolId=${schoolDetail?.schoolId}&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setEquipmentKit(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  // search Equipment and kit
  const handleSearch = async (searchValue) => {
    if (value === 0) {
      let url;
      if (searchValue) {
        setPageNoE(1);
        setRowsPerPageE(10);
        url = `${FETCH_URL}/api/equipment/getAllEquipments?pagination=true&search=${searchValue}&schoolId=${schoolDetail?.schoolId}`;
      } else {
        url = `${FETCH_URL}/api/equipment/getAllEquipments?pagination=true&schoolId=${schoolDetail?.schoolId}&page=1&limit=10`;
      }
      if (searchValue.length === 0) {
        getAllEquipment();
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setEquipmentData(res.msg);
        console.log("My searching equipment data response ==>", res);
      } else {
        console.log("Error while searching course", res.err);
      }
    } else {
      let url;
      if (searchValue) {
        setPageNoEK(1);
        setRowsPerPageEK(10);
        console.log("searchValue1", searchValue);
        url = `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?search=${searchValue}&schoolId=${schoolDetail?.schoolId}`;
      } else {
        console.log("searchValue2", searchValue);

        url = `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?pagination=true&schoolId=${schoolDetail?.schoolId}&page=1&limit=10`;
      }
      if (searchValue.length === 0) {
        getAllEquipmentKIT();
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setEquipmentKit(res.msg);
        console.log("My searching equipment kit response ==>", res);
      } else {
        console.log("Error while searching course", res.err);
      }
    }
  };

  useEffect(() => {
    getAllEquipmentKIT();
    getAllEquipment();
  }, []);

  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Equipments</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                {value == 0 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    value={searchDataE}
                    onChange={(e) => {
                      setSerachDataE(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    value={searchDataEK}
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); // Add debouncing for this
                      setSerachDataEK(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                )}
              </FormControl>
            </div>
          </Box>
        </div>
      </Toolbar>

      <EquipmentTab
        getAllEquipment={getAllEquipment}
        getAllEquipmentKIT={getAllEquipmentKIT}
        states={{
          value,
          setValue,
          equipmentData,
          equipmentKit,
          setSerachDataE,
          setSerachDataEK,
        }}
      />
    </>
  );
}

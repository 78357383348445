import React, { useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";

export default function AssesmentTab({ skills }) {
  return (
    <>
      <Grid container style={{ minHeight: "70vh" }}>
        <div className="ResponsiveTable width100">
          <Table className="Tableroot">
            <TableHead>
              <TableRow>
                <TableCell className="Head"> UID </TableCell>
                <TableCell className="Head">Skill </TableCell>
                <TableCell className="Head">Sub-Skill</TableCell>
                <TableCell className="Head">Description </TableCell>
                {/* <TableCell className="Head"> Action</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {skills &&
                skills.length > 0 &&
                skills.map((Data) => (
                  <TableRow>
                    <TableCell className="Col">{Data?.subSkillUid}</TableCell>
                    <TableCell className="Col"> {Data?.mainSkill}</TableCell>
                    <TableCell className="Col">{Data?.subSkillName}</TableCell>
                    <TableCell className="Col ActionButtonSize">
                      {Data?.subSkillDesc}
                    </TableCell>
                    {/* <TableCell className="Action">
                      <Typography> </Typography>
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!skills || skills.length == 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Skill Assign!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  InputAdornment,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
// ReactIcons
import { GoSearch } from "react-icons/go";

export default function SelectCourseTab(props) {
  const { TabNext } = props;
  const {
    states: { lessonplan, selectLessonPlanText, lessonPlanId, setLessonPlanId },
    storeLessonPlan,
  } = props;
  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid
          item
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <FormControl className="CourseSerachInput">
            <Input
              disableUnderline
              className="GCourseInput"
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ marginRight: "7px" }}>
                    <GoSearch className=" HaedAddIcon" />
                  </IconButton>
                </InputAdornment>
              }
              label="Serach Course"
            />
          </FormControl>
        </Grid>

        {lessonplan.map((lessonPlans, i) => (
          <Grid
            key={i}
            container
            item
            md={6}
            sm={6}
            xs={6}
            lg={6}
            // className=" AssignActivitesGridPaper    "
          >
            <Grid container item md={12}>
              <FormGroup className="GCourseFromGroup AssignActivitesGridPaper">
                <FormControlLabel
                  // control={<Checkbox />}
                  control={
                    <Radio
                      checked={lessonPlanId === lessonPlans._id}
                      onChange={(e) => {
                        setLessonPlanId(e.target.value);
                        // storeLessonPlan(e, data, index);
                      }}
                      value={lessonPlans._id}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  // onChange={(e) => storeLessonPlan(e, data, index)}
                  value={lessonPlans.LPTitle}
                  className="CheckLabelStyleBlue"
                  style={{ justifyContent: "space-between" }}
                  label={
                    <div className="GCourseLabel">
                      <div>
                        <Typography>{lessonPlans.LPTitle}</Typography>
                        <Typography className="GreyColorTypography">
                          {lessonPlans.LPUid}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="GreyColorTypography">
                          {/* {data.LPDuration.split("T")[0]} */}
                        </Typography>
                        <Typography className="GreyColorTypography">
                          {lessonPlans.LPXp}
                        </Typography>
                      </div>
                    </div>
                  }
                  // onChange={(e) => storeLessonPlan(e, lessonPlans, i)}
                  // className="CheckLabelStyleBlue"
                  // label={
                  //   <div className="GCourseLabel">
                  //     <div>
                  //       <Typography>{lessonPlans.LPTitle}</Typography>
                  //       <Typography className="GreyColorTypography">
                  //         {lessonPlans.LPUid}
                  //       </Typography>
                  //     </div>

                  //     <div>
                  //       {/* <Typography className="GreyColorTypography">
                  //         {courses.courseDuration}
                  //       </Typography> */}
                  //       <Typography className="GreyColorTypography">
                  //         {/* {user.CourseNo} */}
                  //       </Typography>
                  //     </div>
                  //   </div>
                  // }
                />
              </FormGroup>
            </Grid>
          </Grid>
        ))}

        {!lessonPlanId && (
          <Grid container style={{ marginTop: "10px" }}>
            <Typography className="warningTypography">
              {selectLessonPlanText}
            </Typography>
          </Grid>
        )}
      </Grid>{" "}
    </>
  );
}

import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  DialogContent,
  Input,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export default function OverviewTab(props) {
  const {
    states: {
      TabNext1,
      TabPre1,
      overviewState,
      setOverviewState,
      overviewValid,
    },
  } = props;

  console.log("check overviewValid", overviewValid);

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container className="p-20">
          <Typography
            className={
              overviewValid
                ? "RedColorTypography width100  fs-18"
                : " DarkBlackColorTypo fs-18 width100"
            }
          >
            Overview
          </Typography>
          {/* <div className="mt-16">
            <Editor
              editorState={overviewState}
              onEditorStateChange={setOverviewState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
            />
          </div> */}
          <Grid container>
            <TextareaAutosize
              aria-label="maximum height "
              className={
                overviewValid
                  ? "textareaauto width100  border-red  mt-16 "
                  : " textareaauto  width100 border-grey mt-16"
              }
              value={overviewState}
              onChange={(e) => setOverviewState(e.target.value)}
            />
          </Grid>

          <DialogActions className="mt-24  width100">
            <Button onClick={TabPre1} className=" GreenButton-border next-btn">
              Previous
            </Button>
            <Button onClick={TabNext1} className="GreenButton700 next-btn">
              Next
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

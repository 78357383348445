import React, { useState, useEffect } from "react";
import axios from "axios";

import PropTypes from "prop-types";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
// @material-ui/core components

import AddActivityDialog from "./AddVarientDetails";

export default function ExtendedTables() {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <Grid
        container
        style={{
          minHeight: "calc(100vh-500px)",
          height: "calc(100vh-500px)",
        }}
      >
        <Grid item md={12} sm={12} xs={12} lg={12} direction="column">
          <Grid item direction="column">
            <div className="ResponsiveTableAL">
              <Table className="Tableroot">
                <TableHead>
                  <TableRow className="HeadAL">
                    <TableCell className="ColHeadAL">UID </TableCell>
                    <TableCell className="ColHeadAL">
                      Name of activity Variant
                      {/* <IconButton disableRipple style={{ height: "10px" }}>
                            <BiSort color="#FFF" size=".8em" />
                          </IconButton> */}
                    </TableCell>

                    <TableCell className="ColHeadAL">Level </TableCell>
                    <TableCell className="ColHeadAL"> Action </TableCell>
                  </TableRow>
                </TableHead>

                {/* //  const indexOfLastPage = page + postPerPage;
        //  const indexOffFirstPage = indexOfLastPage - postPerPage;
        //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
                {/* <TableBody>
                  {posts.slice(page, rowsPerPage).map((post) => (
                    <TableRow>
                      <TableCell className="Col">
                        <Link to="/admin/schooldetails">SC101</Link>
                      </TableCell>
                      <TableCell className="Col">
                        <Link to="/admin/schooldetails">Zoomer Public School</Link>
                      </TableCell>
                      <TableCell className="Col"> Pune </TableCell>
                      <TableCell className="Col"> 978978978 </TableCell>
                      <TableCell className="Action">
                        <IconButton className="ActionButtonSize">
                          <Edit />
                        </IconButton>
                        <IconButton className="ActionButtonSize">
                          <GradeDialog />
                        </IconButton>
                        <IconButton className="ActionButtonSize">
                          <SwitchDialog accessCheck={access} />
                        </IconButton>
                        <DeleteDialog />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody> */}
              </Table>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "30px" }}>
          <Grid
            item
            md={10}
            sm={10}
            xs={10}
            lg={10}
            style={{ textAlign: "center" }}
          >
            <AddActivityDialog />
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            xs={2}
            lg={2}
            direction="column"
            style={{ textAlign: "center" }}
          >
            <Button className="GreenButton700  VNextButton">Next</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Image from "assets/img/Man.png";

export default function OrganisationDetails(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} className="SDCConatiner">
      <Grid item lg={5} md={6} xs={8}>
        <Grid container item>
          <Grid
            item
            container
            xs={5}
            direction="column"
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="BlueColorTypography SPCardTypo">
                School Name
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Location
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Full Address
              </Typography>

              <Typography className="BlueColorTypography SPCardTypo">
                Zipcode
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                State
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Country
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={7}
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.schoolName}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.organisationDetails.location}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.contactDetail.address}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.contactDetail.zipCode}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.contactDetail.state}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {data.contactDetail.country}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={4} md={6} xs={8}>
        <Grid container item className="HSGrid6Style" direction="column">
          <div className="HSdivStyle6">
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={6} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative Name
                </Typography>
              </Grid>

              <Grid item container md={6} sm={6} xs={4}>
                <Typography className="GreyColorTypography SPCardTypo">
                  {data.contactDetail.representativeName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={8} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative <br />
                  Mobile Number
                </Typography>
              </Grid>

              <Grid item container md={6} sm={4} xs={4}>
                <Typography className="GreyColorTypography SPCardTypo pt-17 GtSideGridStyle">
                  {data.contactDetail.representativeMobilePhoneNo}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={8} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative <br /> Email ID
                </Typography>
              </Grid>

              <Grid item container md={6} sm={4} xs={4}>
                <Typography className="GreyColorTypography  SPCardTypo pt-17 GtSideGridStyle">
                  {data.contactDetail.representativeEmailId}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={8} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative
                  <br /> Address
                </Typography>
              </Grid>

              <Grid item container md={6} sm={4} xs={4}>
                <Typography className="GreyColorTypography SPCardTypo pt-17 GtSideGridStyle">
                  {data.contactDetail.representativeAddress}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid item lg={3} md={4} xs={5}>
        <Grid container item>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
            direction="column"
            className="SDCGrid2"
          >
            <img
              src={data.contactDetail.representativeImg}
              className="SDCGrid2Image"
            />
            <Typography
              variant="h6"
              component="div"
              className="BlueColorTypography SDCGrid2Typo"
            >
              Representative Name
            </Typography>
            <Typography
              variant="h6"
              component="div"
              align="center"
              className="GreenColorTypography SDCGrid2Typo"
            >
              {data.contactDetail.representativeName}
            </Typography>
          </Grid>
          {/* <Grid
            item
            justify="center"
            alignItems="center"
            container
            xs={12}
            direction="column"
          >
            <img src={Image} className="SDCGrid2Image" />

            <Typography
              variant="h6"
              component="div"
              align="center"
              className="GreenColorTypography SDCGrid2Typo"
            >
              Abhay Garg
            </Typography>
            <Typography
              variant="h6"
              component="div"
              className="BlueColorTypography SDCGrid2Typo"
            >
              Representative
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

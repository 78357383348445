import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { IoCloseSharp } from "react-icons/io5";
//React Icons
import { MdOutlinePayments } from "react-icons/md";
//core Module
import SelectDivision from "./LocoDivisionTab/LocoDSelectDivisionTab";

import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypo">Assign Division </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AssignGarde({ SkillsId }) {
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setSelectSkillText("");
    setDivisionData(null);
  };
  // Api Call GetAllGrade
  const { userToken } = useLogin();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);
  const [divisionData, setDivisionData] = React.useState(null);
  const [assigntrainerid, setAssignTrainerId] = React.useState([]);
  ///
  const [selectSkillText, setSelectSkillText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // original array with undefined data
  const getAllGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log(
          res,
          "skill response>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
        );
        // setDivisionData(res.msg);
        setAssignTrainerId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllGrade();
  }, []);
  const [originalData, setOriginalData] = React.useState([]);
  console.log(" Check Locomotor Data", originalData);
  console.log(SkillsId, "SkillsId>>>>>>>>>>>>abc");
  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }

  /////////////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  //////////////////////////////////////////////////////////////////////
  console.log(filterdata, "filter data");
  const assignedDivision = filterdata.map((dId) => {
    return {
      _id: dId._id,
      schoolId: dId.school,
      gradeId: dId.grade,
      schoolName: dId.schoolName,
      location: dId.location,
      gradeName: dId.gradeName,
      schoolUid: dId.schoolUid,
      division: dId.division,
      assignedLearners: dId.assignedLearners,
    };
  });
  //   const assignedLearners = filterdata.map((a) => a.assignedLearners).flat();

  const submitAssignDivision = async (e) => {
    if (filterdata.length === 0) {
      setSelectSkillText("please select a skill !");
      return;
    }
    e.preventDefault();

    try {
      const response = await fetch(
        `${FETCH_URL}/api/skill/assignSkillToDivision`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            skillId: SkillsId,
            divisions: assignedDivision,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("grade has been assigned to scholl  ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllGrade();
        handleClose();
      } else {
        console.log("Error while assigning grade", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log(
    assigntrainerid,
    "assigntrainerid>>>>>>>>>>>>>>>>>>>>>>>>.in skills"
  );
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Division">
        <IconButton className="ActionButtonSize">
          <MdOutlinePayments
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        </DialogTitle>

        <SelectDivision
          divisionData={divisionData}
          setDivisionData={setDivisionData}
          storeSkill={storeSkill}
          selectSkillText={selectSkillText}
          filterdata={filterdata}
        />
        <DialogActions className=" ACourseAction">
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={submitAssignDivision}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";

import {
  IconButton,
  Button,
  Breadcrumbs,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
//ReactIcons
import { IoIosArrowBack } from "react-icons/io";
//core component
import EditDialog from "../ActionDialog/EditDialog";
import DeleteDialog from "../ActionDialog/DeleteDialog";
import SwitchDialog from "../ActionDialog/SwitchDialog";
import GradeProfileTab from "./GradePMainPageTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function GradeProfile(props) {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const { userToken } = useLogin();
  const data = location.state.data;
  const getAllGrades = location.data.getAllGrades;

  console.log(getAllGrades, "getAllGrades>>>>>>>>>>>>>>>>>>>>>>");
  return (
    <>
      {/* <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div> */}
      <div
        style={{ paddingTop: "20px" }}
        role="presentation"
        onClick={handleClick}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            variant="h6"
            component="div"
            className="SDBreadC"
          >
            <div className="SDBreadCTitle1">Grade</div>
          </Button>

          <div className="BreadcrumbColor   SDTitle2Style  ">Grade Detail</div>
        </Breadcrumbs>
      </div>

      <Grid container>
        <Grid item container className="ZoomerStyle">
          <Typography className="GreyColorTypography zoomerTypo ">
            {location.state?.data?.schoolName},
          </Typography>
          <Typography className="BlueColorTypography GradeTypo">
            {location.state?.data?.grade}
          </Typography>
        </Grid>
      </Grid>
      <GradeProfileTab
        gradeId={id}
        gradeData={data}
        getAllGrades={getAllGrades}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
//core component
import ManImage from "assets/img/Man.png";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="ActivityDialog2" {...other}>
      <Grid container>
        <Grid container item md={12} sm={12} xs={12} lg={12}>
          <Grid
            container
            item
            md={8}
            sm={8}
            xs={8}
            lg={8}
            className="ReportCardDialog2Title"
            direction="column"
          >
            <Typography className="BlueColorTypography RCLearnerNameTypo">
              ACT-1.1
            </Typography>
            <Typography className="GreenColorTypography RCLearnerNameTypo">
              Smiley Bounce Standing
            </Typography>
          </Grid>
          <div className="ReportCardDownIcons">
            <IconButton className="ActionButtonSize">
              <BsDownload className="ActivityReportCIcon" />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const classes = useStyles();

  const [scroll, setScroll] = React.useState("paper");

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        Bounce and catch with smiley with both hands
      </Link>
      <Dialog
        PaperProps={{
          className: "BootstrapReportCardDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="DialogBackGround">
          <Grid container>
            <Grid
              container
              item
              md={9}
              sm={9}
              xs={9}
              lg={9}
              className="colorwhite borderRadius15"
            >
              <Grid container item md={3} sm={3} xs={3} lg={3} justify="center">
                <img className="APReportImg" src={ManImage} />
                <Typography className="BlueColorTypography " align="center">
                  11405079
                </Typography>
              </Grid>
              <Grid
                container
                item
                md={3}
                sm={3}
                xs={3}
                lg={3}
                direction="column "
                className="APC2Card1"
              >
                <Typography className="BlueColorTypography UserNameTypo">
                  Name
                </Typography>
                <Typography className="BlueColorTypography ">Grade</Typography>
                <Typography className="BlueColorTypography ">
                  Roll No.
                </Typography>
                <Typography className="BlueColorTypography ">School</Typography>
                <Typography className="BlueColorTypography ">Branch</Typography>
              </Grid>
              <Grid
                container
                item
                md={5}
                sm={5}
                xs={5}
                lg={5}
                direction="column"
                className="APC2Card2"
              >
                <Typography className="GreyColorTypography UserNameTypo">
                  Abhisekh Sharma
                </Typography>
                <Typography className="GreyColorTypography ">
                  Grade-1
                </Typography>
                <Typography className="GreyColorTypography ">01</Typography>
                <Typography className="GreyColorTypography ">
                  Delhi Publi school
                </Typography>
                <Typography className="GreyColorTypography ">Pune</Typography>
              </Grid>
            </Grid>
            <Grid container item md={3} sm={3} xs={3} lg={3}>
              <Grid
                container
                item
                className="colorwhite APC2Card3"
                direction="column"
              >
                <Typography className="BlueColorTypography400 ">
                  Marks
                </Typography>
                <Typography className="GreyColorTypography ">
                  Total Marks
                </Typography>
                <Typography className="GreyColorTypography400 ">20</Typography>
                <Typography className="GreyColorTypography ">
                  Obtained Marks
                </Typography>
                <Typography className="GreyColorTypography400 ">7.5</Typography>
                <Typography className="RedColorTypography ">FAIL</Typography>
              </Grid>
            </Grid>

            <Grid container item className="colorwhite APC2GridNo3">
              <Grid container item className="APC2GridNo3Typo">
                <Typography align="center" className="BlueColorTypography400">
                  Powerful step forward with the foot opposite to the serving
                  hand before serving.
                </Typography>
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                className="APC2Grid3Style"
              >
                <Button className="RedButton700 APC2Grid3ButtonStyle">
                  Not Yet
                </Button>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="BlueColorTypography APC2Grid3-2Typo">
                  Total Marks : 5
                </Typography>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="DarkBlackColorTypo APC2Grid3-2Typo">
                  Obtained Marks : 0
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className="colorwhite APC2GridNo3">
              <Grid container item className="APC2GridNo3Typo">
                <Typography align="center" className="BlueColorTypography400">
                  Powerful step forward with the foot opposite to the serving
                  hand before serving.
                </Typography>
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                className="APC2Grid3Style"
              >
                <Button className="lightGreenButton700 APC2Grid3ButtonStyle">
                  Not Yet
                </Button>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="BlueColorTypography APC2Grid3-2Typo">
                  Total Marks : 5
                </Typography>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="DarkBlackColorTypo APC2Grid3-2Typo">
                  Obtained Marks : 0
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className="colorwhite APC2GridNo3">
              <Grid container item className="APC2GridNo3Typo">
                <Typography align="center" className="BlueColorTypography400">
                  Powerful step forward with the foot opposite to the serving
                  hand before serving.
                </Typography>
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                className="APC2Grid3Style"
              >
                <Button className="YellowButton700 APC2Grid3ButtonStyle">
                  Not Yet
                </Button>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="BlueColorTypography APC2Grid3-2Typo">
                  Total Marks : 5
                </Typography>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="DarkBlackColorTypo APC2Grid3-2Typo">
                  Obtained Marks : 0
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className="colorwhite APC2GridNo3">
              <Grid container item className="APC2GridNo3Typo">
                <Typography align="center" className="BlueColorTypography400">
                  Powerful step forward with the foot opposite to the serving
                  hand before serving.
                </Typography>
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                className="APC2Grid3Style"
              >
                <Button className="RedButton700 APC2Grid3ButtonStyle">
                  Not Yet
                </Button>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="BlueColorTypography APC2Grid3-2Typo">
                  Total Marks : 5
                </Typography>
              </Grid>
              <Grid container item md={4} sm={4} xs={4} lg={4}>
                <Typography className="DarkBlackColorTypo APC2Grid3-2Typo">
                  Obtained Marks : 0
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React from "react";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import ManImage from "assets/img/Man.png";
export default function OverviewTab() {
  return (
    <>
      <Grid container>
        <Grid container item sm={4} lg={4} md={4} xs={4}>
          <Grid container item className="GPRCGridStyle">
            <img className="GPRImageStyle" src={ManImage} />
            <Typography
              className="GreenColorTypography  GPRTypoTitle "
              align="center"
            >
              Play Points
            </Typography>
            <Typography align="center" className="GPRTypoPara">
              Play Points helps in loreum ipsum loreum ipsum loreum ipsum loreum
              ipsum loreum ipsum loreum ipsum loreum
            </Typography>
          </Grid>
        </Grid>
        <Grid container item sm={4} lg={4} md={4} xs={4}>
          <Grid container item className="GPRCGridStyle GPRCGridStyle1 ">
            <img className="GPRImageStyle" src={ManImage} />
            <Typography
              className="GreenColorTypography  GPRTypoTitle "
              align="center"
            >
              Play Points
            </Typography>
            <Typography align="center" className="GPRTypoPara">
              Play Points helps in loreum ipsum loreum ipsum loreum ipsum loreum
              ipsum loreum ipsum loreum ipsum loreum
            </Typography>
          </Grid>
        </Grid>
        <Grid container item sm={4} lg={4} md={4} xs={4}>
          <Grid container item className="GPRCGridStyle GPRCGridStyle1">
            <img className="GPRImageStyle" src={ManImage} />
            <Typography
              className="GreenColorTypography  GPRTypoTitle "
              align="center"
            >
              Play Points
            </Typography>
            <Typography align="center" className="GPRTypoPara">
              Play Points helps in loreum ipsum loreum ipsum loreum ipsum loreum
              ipsum loreum ipsum loreum ipsum loreum
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";

import { Typography, Button, DialogActions, Grid } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function OverviewTab(props) {
  const {
    states: {
      inputDisable,
      setInputDisable,
      overviewState,
      setOverviewState,
      overviewValid,
    },
    EditSubmitClick,
  } = props;

  // function createMarkup(html) {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // }

  // useEffect(() => {
  //   setOverviewState(Coursedata?.overview ?? "");
  // }, [Coursedata]);

  // useEffect(() => {
  //   if (Coursedata.overview) {
  //     console.log("Overview if (Coursedata.overview) ", Coursedata);

  //     function createMarkup(html) {
  //       return {
  //         __html: DOMPurify.sanitize(html),
  //       };
  //     }
  //     setOverviewState(createMarkup(Coursedata.overview));
  //   }
  // }, [Coursedata]);

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Overview
          </Typography>
          <Grid container>
            <TextareaAutosize
              aria-label="maximum height "
              disabled={inputDisable ? true : false}
              className={
                overviewValid
                  ? "textareaauto width100  border-red  mt-16 "
                  : " textareaauto  width100 border-grey mt-16"
              }
              value={overviewState}
              onChange={(e) => setOverviewState(e.target.value)}
            />
          </Grid>
          <DialogActions className="mt-24 mb-20 width100">
            {inputDisable ? (
              <Button
                className="GreenButton700 next-btn"
                onClick={() => setInputDisable(false)}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  className="GreenButton-border next-btn"
                  onClick={() => setInputDisable(true)}
                >
                  Cancel
                </Button>
                <Button
                  className="GreenButton700 next-btn"
                  onClick={() => EditSubmitClick()}
                >
                  Save
                </Button>
              </>
            )}
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

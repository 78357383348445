import React, { useState } from "react";
import {
  DialogActions,
  Input,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Icons
import { IoIosAddCircle } from "react-icons/io";

export default function ExtendTab(props) {
  const {
    states: {
      schoolName,
      setSchoolName,
      location,
      setLocation,
      enrolledOn,
      setEnrolledOn,
      firstTermStartDate,
      setFirstTermStartDate,
      secondTermStartDate,
      setsecondTermStartDate,
      firstTermEndDate,
      setfirstTermEndDate,
      secondTermEndDate,
      setsecondTermEndDate,
      image,
      setImage,
      file,
      setFile,
      departMentDetail,
      setDepartMentDetails,
      departmentSelect,
      setDepartmentSelect,
      thirdTermStartDate,
      setThirdTermStartDate,
      thirdTermEndDate,
      setThirdTermEndDate,
    },
    TabNext,
    data,
  } = props;
  console.log("data", data);
  const [imageData, setImagedata] = useState(
    data?.organisationDetails.schoolLogoImg
  );
  ///////////////////////////////////////////////////////////

  console.log("data.departmentDetails", data.departmentDetails);
  console.log("departmentSelect", departmentSelect);

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImagedata(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  /////////////////////////////////////////////////////////////
  return (
    <Grid container spacing={4} className="DailogLTRBStyle">
      <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
        <Grid direction="column" item style={{ marginLeft: "3%" }}>
          <Typography className="InputTitleTypography GreyColorTypography">
            DepartMent<span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <FormControl
              variant="standard"
              className="FormControlInput colorwhite"
            >
              <Select
                className=" SkillsSelectStyle "
                // key={data.departmentDetails}
                displayEmpty
                // defaultValue={data.departmentDetails}
                // value={
                //   departmentSelect ? departmentSelect : data.departmentDetails
                // }
                value={data.departmentDetails.departmentName}
                disabled
                // onChange={(e) => {
                //   setDepartmentSelect(e.target.value);
                //   setSchoolName(e.target.value.departmentName);
                //   console.log("e.target.value", e.target.value);
                //   // getSchoolLocation(e.target.value);
                // }}
                // onKeyDown={handleEnter}
                color="white"
                label="Locomotor"
                disableUnderline
              >
                {/* {departMentDetail &&
                Object.keys(departMentDetail).length > 0 ? (
                  departMentDetail.map((dprData, i) => (
                    <MenuItem
                      key={i}
                      className="Selectmenustyle"
                      value={dprData}
                    >
                      {dprData.departmentName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem className="Selectmenustyle" value={0} disabled>
                    No Department Available
                  </MenuItem>
                )} */}
                <MenuItem
                  className="Selectmenustyle"
                  value={data.departmentDetails.departmentName}
                >
                  {data.departmentDetails.departmentName}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Typography className="InputTitleTypography  GreyColorTypography">
            School UID <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              disabled
              // value={uid}
              // onChange={(e) => setUid(e.target.value)}

              defaultValue={data.schoolUid}
              className="Input1"
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            School Name <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              className="Input1 "
              disabled
              defaultValue={data.organisationDetails.schoolName}
              // onChange={(e) => setSchoolName(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Location<span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              className="Input1"
              disabled
              defaultValue={data.organisationDetails.location}
              // onChange={(e) => setLocation(e.target.value)}
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Enrolled on<span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            {/* <Input
              className="Input1"
              defaultValue={data.organisationDetails.enrolledOn}
              onChange={(e) => setEnrolledOn(e.target.value)}
            >
              {" "}
            </Input> */}

            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  disabled
                  defaultValue={data.organisationDetails.enrolledOn}
                  // value={enrolledOn}
                  // onChange={(e) => setEnrolledOn(e)}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            1st Term Start Date<span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value={
                    firstTermStartDate
                      ? firstTermStartDate
                      : data.organisationDetails.firstTermStartDate
                  }
                  // defaultValue={data.organisationDetails.firstTermStartDate}
                  // id="date-picker-dialog"
                  onChange={(e) => setFirstTermStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            1st Term End Date<span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  value={
                    firstTermEndDate
                      ? firstTermEndDate
                      : data.organisationDetails.firstTermEndDate
                  }
                  // defaultValue={data.organisationDetails.firstTermEndDate}
                  // value={firstTermEndDate}
                  // id="date-picker-dialog"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  onChange={(e) => setfirstTermEndDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            2nd Term Start Date <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  value={
                    secondTermStartDate
                      ? secondTermStartDate
                      : data.organisationDetails.secondTermStartDate
                  }
                  // defaultValue={data.organisationDetails.secondTermStartDate}
                  // value={secondTermStartDate}
                  // id="date-picker-dialog"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  onChange={(e) => setsecondTermStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            2nd Term End Date <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  value={
                    secondTermEndDate
                      ? secondTermEndDate
                      : data.organisationDetails.secondTermEndDate
                  }
                  // defaultValue={
                  //   new Date(data.organisationDetails.secondTermEndDate)
                  // }
                  // value={secondTermEndDate}
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  onChange={(e) => {
                    setsecondTermEndDate(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Typography className="InputTitleTypography GreyColorTypography">
            3nd Term Start Date <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  value={
                    thirdTermStartDate
                      ? thirdTermStartDate
                      : data.organisationDetails.thirdTermStartDate
                  }
                  // defaultValue={data.organisationDetails.thirdTermStartDate}
                  // value={thirdTermStartDate}
                  // id="date-picker-dialog"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  onChange={(e) => setThirdTermStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            3nd Term End Date <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  value={
                    thirdTermEndDate
                      ? thirdTermEndDate
                      : data.organisationDetails.thirdTermEndDate
                  }
                  // defaultValue={
                  //   new Date(data.organisationDetails.thirdTermEndDate)
                  // }
                  // value={thirdTermEndDate}
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  onChange={(e) => {
                    setThirdTermEndDate(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={6} xs={6} lg={6}>
        <Grid item container className="AddLogoStyleItem">
          <Typography className=" BlueColorTypography AddLogoTypography width-60">
            Add Logo{" "}
            <img
              src={imageData}
              // defaultValue={data.organisationDetails.schoolLogoImg}
              style={{
                height: "180px",
                width: "230px",
                objectFit: "contain",
                marginTop: "10px",
              }}
            />
          </Typography>
          <Grid item className="ChooseFileButtonItem">
            <input
              style={{ display: "none" }}
              type="file"
              id="raised-button-file"
              accept="image/*"
              onChange={ImageUploader}
            />{" "}
            <label htmlFor="raised-button-file">
              <Button
                className="GreenButton "
                variant="raised"
                component="span"
              >
                <IoIosAddCircle className="AddCircleIcon" />
                Choose File
              </Button>
            </label>
          </Grid>
        </Grid>
        <DialogActions className="OrgDialogActionButton">
          <Button className="GreenButton700 ASNextButton" onClick={TabNext}>
            Next
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
}

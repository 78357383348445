import React from "react";

import {
  DialogActions,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
//Icons
import { IoIosAddCircle } from "react-icons/io";

export default function ExtendTab(props) {
  const {
    states: {
      eventDetails,
      setEventDetails,
      eventGuidelines,
      setEventGuidelines,
      eventRules,
      setEventRules,
    },
    Tabnext2,
  } = props;
  console.log({ Tabnext2 });

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6}>
          <Grid item style={{ marginLeft: "2%" }}>
            <Typography className="BlueColorTypography EventDetailsStyle ">
              Events Details
            </Typography>
            <Grid
              item
              md={11}
              sm={11}
              xs={11}
              lg={11}
              className="AddEventDetails EventDetailsStyle"
            >
              <TextareaAutosize
                className="EventDetailsTextArea"
                aria-label="minimum height"
                value={eventDetails}
                onChange={(e) => setEventDetails(e.target.value)}
              />
            </Grid>{" "}
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}>
          <Grid item style={{ marginLeft: "2%" }}>
            <Typography className="BlueColorTypography  EventDetailsStyle1 ">
              Event Guidelines
            </Typography>
            <Grid
              item
              md={11}
              sm={11}
              xs={11}
              lg={11}
              className="AddEventDetails EventDetailsStyle1 "
            >
              <TextareaAutosize
                className="EventDetailsTextArea"
                aria-label="minimum height"
                value={eventGuidelines}
                onChange={(e) => setEventGuidelines(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}>
          <Grid item style={{ marginLeft: "2%" }}>
            <Typography className="BlueColorTypography  EventDetailsStyle">
              Event Rules
            </Typography>
            <Grid
              item
              md={11}
              sm={11}
              xs={11}
              lg={11}
              className="AddEventDetails EventDetailsStyle"
            >
              <TextareaAutosize
                className="EventDetailsTextArea"
                aria-label="minimum height"
                value={eventRules}
                onChange={(e) => setEventRules(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6}>
          <DialogActions className=" EventDetialAction">
            <Button
              className="GreenButton700 EventNextButton"
              onClick={Tabnext2}
            >
              Next
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

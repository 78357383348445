import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Button,
  Tab,
  Tabs,
  Box,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// @matertial-ui/core  Icons
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//Tabs Import
import AboutTab from "./CourseAddTab's/AboutTab";
import OverviewTab from "./CourseAddTab's/OverviewTab";
import CurriculumTab from "./CourseAddTab's/CurriculumTab";
import Benefits from "./CourseAddTab's/BenefitsTab";
import Lessonplan from "./CourseAddTab's/LessonplanTab";
import Feedback from "./CourseAddTab's/FeedbackTab";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { data } from "jquery";
// import { uid } from "components/Validation/Validation";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ExtendedTables() {
  const location = useLocation();
  const history = useHistory();
  // console.log("Uid Validation Msg", uid);
  // const { loadUsers } = location.data;
  const { userToken } = useLogin();

  function handleClick(event) {
    event.preventDefault();
  }
  const [value, setValue] = React.useState(0);

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  ///////////////////////////Api request ///////////////////////////
  ///////////////////////

  /////////////////////////////////////// Image Api////////////////
  //For CreateInput
  const [courseUid, setCourseUid] = useState(null);
  const [courseDuration, setCourseDuration] = useState(null);
  const [durationSlot, setDurationSlot] = useState("Month");
  const handleChangeDuration = (e) => {
    setDurationSlot(e.target.value);
  };
  const [coursePrice, setCoursePrice] = useState(null);
  const [courseTitle, setCourseTitle] = useState(null);
  const top100Films = [
    "Nursery",
    "LKG",
    "UKG",
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
  ];
  const [grade, setGrade] = useState([]);

  const Sports = [{ title: "Nursery" }, { title: "LKG" }, { title: "UKG" }];
  const [sports, setSports] = useState([]);

  // console.log("Check sports", sports);
  const sportsData = sports?.map((data) => {
    return data.title;
  });

  const [courseDesc, setCourseDesc] = useState(null);

  ///// Assign Skills /////////////////////
  const [open, setOpen] = React.useState(false);

  const [locomotorP, setLocomotorP] = useState([]);
  const [bodyManagementP, setBodyManagementP] = useState([]);
  const [objectControlP, setObjectControlP] = useState([]);
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data
  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    // console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        id: x._id,
        mainSkill: x.mainSkill,
        subSkill: x.subSkillName,
        skillImg: x?.skillImg,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    // console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  const [bmoriginalData, setBMOriginalData] = React.useState([]);

  function BMstoreSkill(e, x, i) {
    let storeArr = [...bmoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkill: x.subSkillName,
        skillImg: x?.skillImg,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    setBMOriginalData(storeArr);
  }
  const filterdata2 = bmoriginalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  console.log("filterdata2  ", filterdata2);

  const [ocoriginalData, setOCOriginalData] = React.useState([]);
  function OCstoreSkill(e, x, i) {
    let storeArr = [...ocoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkill: x.subSkillName,
        skillImg: x?.skillImg,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    // console.log(storeArr);
    setOCOriginalData(storeArr);
  }
  const filterdata3 = ocoriginalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  //Skills Value send in Backend
  const skills = [...filterdata, ...filterdata2, ...filterdata3];

  // const [skillswarn, setSkillsWarn] = useState(skills);
  // const [skillchipData, setSkillChipData] = useState();
  // const handleDelete = (chipToDelete) => () => {
  //   setSkillChipData((chips) =>
  //     chips.filter((chip) => chip.key !== chipToDelete.key)
  //   );
  // };

  //Upload Thumbnail
  const [file, setFile] = useState();
  const [image, setImage] = React.useState("");

  function ImageUploader(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const reuploadClick = () => {
    setFile(false);
  };
  const imageUpload = async (ID) => {
    // console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("courseId", ID);
    formdata.append("file", image);

    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadCourseThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  ////////////////////////////////////////////////////// Assing Equpment ////////////////////////////////////

  const [openEquip, setOpenEquip] = React.useState(false);

  const [assignequipment, setAssignQuipment] = React.useState([]);
  function storeEquipment(e, x, i) {
    let storeArr = [...assignequipment];
    // console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        name: x.equipmentKitName,
        addedEquipment: x.addedEquipment,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    // console.log(storeArr);
    setAssignQuipment(storeArr);
  }
  const filterAssignequiment = assignequipment?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  // Upload Hero Content
  const [media, setMedia] = useState();
  const [mediatype, setMediaType] = useState("video/mp4");
  const [mediaPost, setMediaPost] = useState();

  const MediaUploadClick = () => {
    setMedia(false);
  };
  function MediaUploader(e) {
    setMedia(URL.createObjectURL(e.target.files[0]));
    setMediaType(e.target.files[0].type);
    setMediaPost(e.target.files[0]);
  }

  const MediaUploadHeroImage = async (ID) => {
    const formdata = new FormData();
    console.log("heroId", ID);
    formdata.append("courseId", ID);
    formdata.append("file", mediaPost);

    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadHeroImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const MediaUploadHeroVideo = async (ID) => {
    const formdata = new FormData();
    formdata.set("courseId", ID);
    formdata.append("file", mediaPost);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/uploadHeroVideo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  // console.log("I convertedOverviewContent", convertedOverviewContent);
  const [overviewState, setOverviewState] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const [editorbenefitsState, setEditorBenefitdState] = useState(null);

  ////////////////////////////////////////////////Assing LessonPlna//////////////////
  const [assignlessonplandata, setAssignLessonPlanData] = React.useState([]);
  function storeLessonPlan(e, x, i) {
    let storeArr = [...assignlessonplandata];
    // console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        title: x.LPTitle,
        uid: x.LPUid,
        assignedPP: x.LPXp,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    // console.log(storeArr);
    setAssignLessonPlanData(storeArr);
  }

  const filterLessonPlanData = assignlessonplandata?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  const assignedPPSum = filterLessonPlanData?.reduce((accumulater, elem) => {
    return (accumulater = accumulater + elem.assignedPP);
  }, 0);

  // console.log("Check assignedPPSum", assignedPPSum);

  const [FeadbackQuestion, setFeadBackQuestion] = useState([
    {
      feedbackQuery: "",
      feedbackType: "",
    },
  ]);

  const FeadBack = FeadbackQuestion[0];
  console.log(
    "FeadBack.feedbackQuery == false",
    FeadBack.feedbackQuery == false
  );

  // State For Validation

  const [selectuidValid, setUidValid] = React.useState("");
  const [durationValid, setDurationValid] = useState("");
  const [durationSlotValid, setDurationSlotValid] = useState("");
  const [priceValid, setPriceValid] = useState("");
  const [titleValid, setTitleValid] = useState("");
  const [gradeValid, setGradeValid] = useState("");
  const [sportsValid, setSportsValid] = useState("");
  const [descValid, setDescValid] = useState("");
  const [skillsValid, setSkillsValid] = useState("");
  const [imageValid, setImgaeValid] = useState("");
  const [equipmentValid, setEquipmentValid] = useState("");
  const [medaiValid, setMediaValid] = useState("");
  const [overviewValid, setOverviewValid] = useState("");
  const [curriculumValid, setCurriculumValid] = useState("");
  const [benefitesValid, setBenefitesValid] = useState("");
  const [lessonplanValid, setLessonPlanValid] = React.useState("");
  const [feadbackValid, setFeadBackValid] = useState(true);
  const [feadbackTypeValid, setFeadBackTypeValid] = useState(true);

  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (courseUid) {
      setUidValid(false);
    }
    if (courseDuration) {
      setDurationValid(false);
    }
    if (coursePrice) {
      setPriceValid(false);
    }
    if (courseTitle) {
      setTitleValid(false);
    }
    if (grade?.length == 1) {
      setGradeValid(false);
    }
    if (sports?.length == 1) {
      setSportsValid(false);
    }
    if (courseDesc) {
      setDescValid(false);
    }
    if (courseDesc) {
      setDescValid(false);
    }
    if (skills?.length == 1) {
      setSkillsValid(false);
    }
    if (file) {
      setImgaeValid(false);
    }
    if (filterAssignequiment?.length == 1) {
      setEquipmentValid(false);
    }
    if (media) {
      setMediaValid(false);
    }
    if (overviewState) {
      setOverviewValid(false);
    }
    if (editorState) {
      setCurriculumValid(false);
    }
    if (editorbenefitsState) {
      setBenefitesValid(false);
    }
    if (FeadbackQuestion?.length == 1) {
      setLessonPlanValid(false);
    }
  }, [
    courseUid,
    courseDuration,
    coursePrice,
    courseTitle,
    grade,
    sports,
    courseDesc,
    skills,
    file,
    filterAssignequiment,
    media,
    overviewState,
    editorState,
    editorbenefitsState,
    assignlessonplandata,
  ]);

  const TabChange = (event, newValue) => {
    if (!courseUid) {
      setUidValid(true);
    }
    if (!courseDuration) {
      setDurationValid(true);
    }
    if (!coursePrice) {
      setPriceValid(true);
    }
    if (!courseTitle) {
      setTitleValid(true);
    }
    if (grade?.length == 0) {
      setGradeValid(true);
    }
    if (sports?.length == 0) {
      setSportsValid(true);
    }
    if (!courseDesc) {
      setDescValid(true);
    }
    if (skills?.length == 0) {
      setSkillsValid(true);
    }
    if (file === undefined) {
      setImgaeValid(true);
    }
    if (filterAssignequiment?.length == 0) {
      setEquipmentValid(true);
    }
    if (media === undefined) {
      setMediaValid(true);
    }
    if (!overviewState) {
      setOverviewValid(true);
    }
    if (!editorState) {
      setCurriculumValid(true);
    }
    if (
      courseUid &&
      courseDuration &&
      coursePrice &&
      courseTitle &&
      grade.length > 0 &&
      sports.length > 0 &&
      skills.length > 0 &&
      file &&
      filterAssignequiment.length > 0 &&
      media &&
      overviewState &&
      editorState &&
      editorbenefitsState &&
      assignlessonplandata.length > 0
    ) {
      setValue(newValue);
    }
  };

  function TabNext() {
    if (!courseUid) {
      setUidValid(true);
    }
    if (!courseDuration) {
      setDurationValid(true);
    }
    if (!coursePrice) {
      setPriceValid(true);
    }
    if (!courseTitle) {
      setTitleValid(true);
    }
    if (grade?.length == 0) {
      setGradeValid(true);
    }
    if (sports?.length == 0) {
      setSportsValid(true);
    }
    if (!courseDesc) {
      setDescValid(true);
    }
    if (skills?.length == 0) {
      setSkillsValid(true);
    }
    if (file === undefined) {
      setImgaeValid(true);
    }
    if (filterAssignequiment?.length == 0) {
      setEquipmentValid(true);
    }
    if (media === undefined) {
      setMediaValid(true);
    }
    if (
      courseUid &&
      courseDuration &&
      coursePrice &&
      courseTitle &&
      grade.length > 0 &&
      sports.length > 0 &&
      skills.length > 0 &&
      file &&
      filterAssignequiment.length > 0 &&
      media
    ) {
      setValue(1);
    }
  }
  function TabPre1() {
    setValue(0);
  }

  function TabNext1() {
    if (!overviewState) {
      setOverviewValid(true);
    }
    if (overviewState) {
      setValue(2);
    }
  }
  function TabPre2() {
    setValue(1);
  }

  function TabNext2() {
    if (!editorState) {
      setCurriculumValid(true);
    }

    if (editorState) {
      setValue(3);
    }
  }
  function TabPre3() {
    setValue(2);
  }
  function TabNext3() {
    if (!editorbenefitsState) {
      setBenefitesValid(true);
    }
    if (editorbenefitsState) {
      setValue(4);
    }
  }
  function TabPre4() {
    setValue(3);
  }

  function TabNext4() {
    if (filterLessonPlanData?.length == 0) {
      setLessonPlanValid(true);
    }
    if (filterLessonPlanData.length > 0) {
      setValue(5);
    }
  }
  function TabPre5() {
    setValue(4);
  }

  const submitClick = async (e) => {
    if (FeadBack.feedbackQuery == false || FeadBack.feedbackType == false) {
      setFeadBackValid(false);
      setFeadBackTypeValid(false);
      return;
    }

    // console.log("Added Equipment", filterAssignequiment);
    // console.log("Skill", skills);

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/createCourse`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },

          body: JSON.stringify({
            courseUid,
            courseTitle,
            duration: { durationSlot, courseDuration },
            grade: grade,
            coursePrice: +coursePrice,
            courseDesc: courseDesc,
            overview: overviewState,
            curriculum: editorState,
            benefits: editorbenefitsState,
            sports: sports,
            skills: skills,
            equipmentKit: filterAssignequiment,
            lessonPlans: filterLessonPlanData,
            assignedPP: assignedPPSum,
            feedback: FeadbackQuestion,
          }),
        }
      );
      let res = await responce.json();
      console.log("Response after create >> ", res.msg2._id);
      let ID = res.msg2._id;
      if (ID) {
        imageUpload(ID);
        MediaUploadHeroImage(ID);
        MediaUploadHeroVideo(ID);
      }

      if (responce.ok) {
        console.log("I am the response after users data upload >>", res);
        history.goBack("/admin/course");
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        console.log("errorrrrrrrrrr", res);
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  useEffect(() => {
    setFeadBackValid(true);
  }, [FeadBack.feedbackQuery]);
  useEffect(() => {
    setFeadBackTypeValid(true);
  }, [FeadBack.feedbackType]);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <div className="bodyStyle">
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb" className="mt-16">
            <Button
              onClick={() => history.goBack()}
              disableRipple
              className="Breadcrumb"
            >
              Courses
            </Button>
            <div className="Breadcrumb-child">New Course</div>
          </Breadcrumbs>
        </div>
        <Box className="mt-16">
          <Tabs
            value={value}
            onChange={TabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className="TabsCustom-Style   "
            centered
          >
            <Tab
              className="ActivityTabChanges"
              label="About Course"
              {...a11yProps(0)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Overview"
              {...a11yProps(1)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Curriculum"
              {...a11yProps(2)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Benefits"
              {...a11yProps(3)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Lessonplan"
              {...a11yProps(4)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Feedback"
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className="width100">
          <AboutTab
            states={{
              TabNext,
              courseUid,
              setCourseUid,
              coursePrice,
              setCoursePrice,
              courseDuration,
              setCourseDuration,
              durationSlot,
              courseTitle,
              setCourseTitle,
              grade,
              setGrade,
              sports,
              setSports,
              courseDesc,
              setCourseDesc,
              skills,
              locomotorP,
              setLocomotorP,
              bodyManagementP,
              setBodyManagementP,
              objectControlP,
              setObjectControlP,
              originalData,
              bmoriginalData,
              ocoriginalData,
              open,
              setOpen,
              file,
              setFile,
              filterAssignequiment,
              storeEquipment,
              assignequipment,
              openEquip,
              setOpenEquip,
              media,
              setMedia,
              mediatype,
              setMediaType,
              selectuidValid,
              durationValid,
              durationSlotValid,
              priceValid,
              titleValid,
              gradeValid,
              sportsValid,
              descValid,
              skillsValid,
              imageValid,
              equipmentValid,
              medaiValid,
            }}
            handleChangeDuration={handleChangeDuration}
            top100Films={top100Films}
            Sports={Sports}
            storeSkill={storeSkill}
            BMstoreSkill={BMstoreSkill}
            reuploadClick={reuploadClick}
            ImageUploader={ImageUploader}
            MediaUploadClick={MediaUploadClick}
            MediaUploader={MediaUploader}
            OCstoreSkill={OCstoreSkill}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <OverviewTab
            states={{
              TabNext1,
              TabPre1,
              overviewState,
              setOverviewState,
              overviewValid,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CurriculumTab
            states={{
              TabNext2,
              TabPre2,
              editorState,
              setEditorState,
              curriculumValid,
              setCurriculumValid,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Benefits
            states={{
              TabNext3,
              TabPre3,
              editorbenefitsState,
              setEditorBenefitdState,
              benefitesValid,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Lessonplan
            states={{
              TabNext4,
              TabPre4,
              assignlessonplandata,
              filterLessonPlanData,
              setAssignLessonPlanData,
              lessonplanValid,
            }}
            storeLessonPlan={storeLessonPlan}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Feedback
            states={{
              TabPre5,
              FeadbackQuestion,
              setFeadBackQuestion,
              feadbackValid,
              feadbackTypeValid,
            }}
            submitClick={submitClick}
          />
        </TabPanel>
      </div>
    </>
  );
}

import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import "date-fns";
// React Icons
export default function trainerTab({ trainer, fmsScore, totalPP }) {
  console.log(" Personal Data", trainer);
  const courseAttempt = trainer.assignedCourses.filter(
    (data) => data.completion == true
  );
  const lessonPlanAttempt = trainer.assignedLessonPlans.filter(
    (data) => data.completion == true
  );
  const newData = new Set();
  trainer.assignedSchool.map((x) => {
    newData.add(x.schoolId);
  });

  return (
    <>
      <Grid container className="DailogLTRBStyle-95">
        <Grid item className="L-profile-grid width-23">
          <Grid item className="width80-LR">
            {trainer?.trainerDetail?.trainerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={trainer?.trainerDetail?.trainerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",

                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {trainer?.trainerDetail?.fullName.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {trainer?.trainerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">Trainer</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.regNo ? (
                    trainer?.trainerDetail?.regNo
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.enrollmentDate ? (
                    trainer?.trainerDetail?.enrollmentDate.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span> <span className="pl-5"> {fmsScore} </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span> <span className="pl-5">{totalPP}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? (
                    trainer?.trainerDetail?.dob.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.fathersName ? (
                    trainer?.trainerDetail?.fathersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.mothersName ? (
                    trainer?.trainerDetail?.mothersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="ml-12 L-profile-grid width-75">
          <Grid container>
            <Typography className="width100 BlueColorTypography mb-10">
              Academic Detail
            </Typography>

            <Grid item md={5} className="mb-10">
              <Grid container>
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    School
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {newData?.size ? (
                      newData?.size
                    ) : (
                      <Typography className=" GreyColorTypography">
                        0
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    Grade
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {trainer.assignedSchool && trainer.assignedSchool.length > 0
                      ? trainer.assignedSchool.length
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    Courses
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {courseAttempt?.length ||
                    trainer.assignedCourses?.length ? (
                      <>
                        {courseAttempt?.length}/
                        {trainer.assignedCourses?.length}
                      </>
                    ) : (
                      <Typography className=" GreyColorTypography">
                        0
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    Lesson Plan
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {lessonPlanAttempt?.length ||
                    trainer.assignedLessonPlans?.length ? (
                      <>
                        {lessonPlanAttempt?.length}/
                        {trainer.assignedLessonPlans?.length}{" "}
                      </>
                    ) : (
                      <Typography className=" GreyColorTypography">
                        0
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ borderTop: "2px solid #dddddd" }}>
              <Typography className="width100 BlueColorTypography mb-10 mt-10">
                Contact Details
              </Typography>
              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Mobile Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerPhoneNo ? (
                        trainer?.contactDetail?.trainerPhoneNo
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Email ID
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerEmail ? (
                        trainer?.contactDetail?.trainerEmail
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Full Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerAddress ? (
                        trainer?.contactDetail?.trainerAddress
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Pincode
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerZipCode ? (
                        trainer?.contactDetail?.trainerZipCode
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>{" "}
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      City
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerCity ? (
                        trainer?.contactDetail?.trainerCity
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      State
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {" "}
                      {trainer?.contactDetail?.trainerState ? (
                        trainer?.contactDetail?.trainerState
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Country
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerCountry ? (
                        trainer?.contactDetail?.trainerCountry
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>

              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Name
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianFullName ? (
                        trainer?.contactDetail?.guardianFullName
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Location
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianLocation ? (
                        trainer?.contactDetail?.guardianLocation
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Email
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianEmail ? (
                        trainer?.contactDetail?.guardianEmail
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Mob No.
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianPhoneNo ? (
                        trainer?.contactDetail?.guardianPhoneNo
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsFileEarmark } from "react-icons/bs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Assign Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog({ equipmentId }) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  const [school, setSchool] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data
  const [gradeData, setGradeData] = useState(null);
  const [Quantity, setQuantity] = React.useState("");
  const [finalData, setFinalData] = React.useState([]); // finalData holds the value that will be sent to the API
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState([]);
  // const [selectGrade, setSelectGrade] = useState([]);
  // const [gradeSelected, setGradeSelected] = useState([]);
  ////////////////////////////////////////////////////////////
  ///
  const [selectGradeText, setSelectGradeText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////

  const handleClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    setOpen(false);
    setOriginalData([]);
    setQuantity(null);
    setSelectGradeText("");
  };
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const getAllGrades = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My  All Grade Get response ==>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllGrades();
  }, []);

  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }

  /////////////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  //////////////////////////////////////////////////////////////////////

  console.log(filterdata, "filterdata>>>>>>>>>>>>>>>>>>>>>>>>>>");
  const assignedGrade = filterdata.map((a) => {
    return {
      gradeId: a.gradeId,
      schoolId: a.schoolId,
      schoolName: a.schoolName,
      location: a.location,
      grade: a.grade,
      quantity: +Quantity,
      assignedLearners: a.assignedLearners,
    };
  });

  const submitAssignGrade = async (e) => {
    console.log(assignedGrade, "assignedGrade>>>>>>>>>>>>");
    e.preventDefault();
    if (filterdata.length === 0) {
      setSelectGradeText("please select a grade!");
      return;
    }
    if (Quantity <= 0) {
      return alert("Quantity cannot be negative/zero");
    }
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/assignEquipmentToGrade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            equipmentId,
            assignedGrade: assignedGrade,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Grade  Post response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllGrades();
        DialogClose();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const cleanArr = (arr) => {
    const filtered = arr.filter(
      (a) => a !== undefined && a.isChecked !== false
    );

    console.log("Filtered ====>", filtered);
  };
  React.useEffect(() => {
    getDistinctSchoolAndLocation();
  }, []);
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Grade">
        <IconButton className="ActionButtonSize">
          <BsFileEarmark
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={DialogClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              lg={12}
              style={{ marginLeft: "2%" }}
            >
              <Grid item container>
                <Grid item container className="ParticipantStyleSelect">
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" DialogSelectdropstyle"
                      value={schoolSelected ? schoolSelected : 0}
                      // onChange={handleChange}
                      onChange={(e) => {
                        getDistinctSchoolAndLocation(e.target.value);
                        setSchoolSelected(e.target.value);
                      }}
                      label="Select School"
                      color="white"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0}>
                        Select school
                      </MenuItem>
                      {schoolNames &&
                        schoolNames.length > 0 &&
                        schoolNames.map((school) => (
                          <MenuItem
                            value={school._id}
                            className="Selectmenustyle"
                          >
                            {school._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container className="ParticipantStyleSelect1">
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" Selectdropstyle"
                      value={
                        schoolLocationSelected ? schoolLocationSelected : 0
                      }
                      onChange={(e) => {
                        setSchoolLocationSelected(e.target.value);
                        getGrades(e.target.value);
                      }}
                      label="Select Location"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0}>
                        Select Location
                      </MenuItem>
                      {schoolLoc &&
                        schoolLoc.length > 0 &&
                        schoolLoc.map((location) => (
                          <MenuItem
                            value={location._id}
                            className="Selectmenustyle"
                          >
                            {location._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item container className="ParticipantStyleSelect1">
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" Selectdropstyle"
                      value={gradeSelected ? gradeSelected : 0}
                      onChange={(e) => {
                        // getPaginatedSkills(e.target.value);
                        setGradeSelected(e.target.value);
                      }}
                      label="Select Grade"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0}>
                        Select Grade
                      </MenuItem>
                      {selectGrade &&
                        selectGrade.length > 0 &&
                        selectGrade.map((a) => (
                          <MenuItem className="Selectmenustyle" value={a._id}>
                            {a._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item container className="ASGQuanitiyStyle">
                  <Typography className="BlueColorTypography OuantityTypo">
                    Quantity
                  </Typography>
                  <Input
                    className="QuntityInput"
                    onChange={(e) => setQuantity(e.target.value)}
                  >
                    {" "}
                  </Input>
                </Grid>
              </Grid>
              {gradeData &&
                gradeData.length > 0 &&
                gradeData.map((x, i) => (
                  <Grid
                    container
                    item
                    md={4}
                    sm={4}
                    xs={4}
                    lg={4}
                    direction="row"
                    style={{ marginTop: "10px" }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={(e) => storeSkill(e, x, i)}
                      value={x.schoolName}
                      className="CheckLabelStyleBlue AssignActivitesGridPaper  AssignActivites"
                      label={
                        <Typography>
                          {x.schoolName}
                          <span style={{ paddingLeft: "10px" }}>{x.grade}</span>
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
              {filterdata.length === 0 && (
                <Grid container style={{ marginTop: "10px" }}>
                  <Typography className="warningTypography">
                    {selectGradeText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={submitAssignGrade}
          >
            {" "}
            Ok
          </Button>
          <Button className="GreyButton700 EquipActionBottom"> Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

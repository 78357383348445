import React, { useState, useEffect } from "react";
// @material-ui/core components
import { Pagination } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import DeleteDialog from "../SchoolActionDialog.js/DeleteDialog";
// // material-ui icons
import { BsDownload } from "react-icons/bs";
import { BiSort } from "react-icons/bi";
import UploadDocuments from "./UploadDocuments";

// core components

export default function DocumentDetail({ data, schoolDocs, setSchoolDocs }) {
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [schoolDocs, setSchoolDocs] = useState(data?.schoolDocs);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  // const loadUsers = async () => {
  //   const res = await axios.get(
  //     "https://jsonplaceholder.typicode.com/comments "
  //   );
  //   setPosts(res.data);
  //   setTotal(res.data.length);
  // };

  // useEffect(() => {
  //   loadUsers();
  // }, []);

  return (
    <>
      <div className="ResponsiveTableAL">
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <UploadDocuments
            schoolId={data._id}
            schoolUId={data.schoolUid}
            setSchoolDocs={setSchoolDocs}
          />
        </div>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="HeadAL ">
              <TableCell className="ColHeadALBLue ">
                Document Name
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>
              <TableCell className="ColHeadALBLue">
                Uploaded on
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>
              {/* <TableCell className="ColHeadALBLue">
                Size
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell> */}
              <TableCell className="ColHeadALBLue"> Action</TableCell>
            </TableRow>
          </TableHead>

          {/* <TableBody>
            {posts.slice(page, rowsPerPage).map((post) => (
              <TableRow>
                <TableCell className="Col700">Invoice</TableCell>
                <TableCell className="Col700"> 10/09/2020</TableCell>
                <TableCell className="Col700"> 2 MB </TableCell>
                <TableCell className="Action">
                  <IconButton className="ActionButtonSize">
                    <BsDownload color="#cddc39" />
                  </IconButton>

                  <IconButton className="ActionButtonSize">
                    <DeleteDialog />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody> */}
          {schoolDocs?.map((datas, i) => {
            let dataNew = datas?.doc.split("-");
            // dataNew.splice(0, 1);
            dataNew.shift(0, 1);

            dataNew = dataNew.join("-");
            return (
              <TableBody>
                <TableRow>
                  <TableCell className="Col700">
                    {/* {datas?.doc?.split("-")[1]} */}
                    {dataNew}
                  </TableCell>
                  <TableCell className="Col700">
                    {datas?.uploadOn?.split("T")[0]}
                  </TableCell>
                  {/* <TableCell className="Col700"> 2 MB </TableCell> */}
                  <TableCell className="Action">
                    <a href={datas?.doc}>
                      <IconButton className="ActionButtonSize">
                        <BsDownload color="#cddc39" />
                      </IconButton>
                    </a>
                    {/* <IconButton className="ActionButtonSize">
        <DeleteDialog />
      </IconButton> */}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
        {schoolDocs.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Document Found !
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>

      {/* <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { Button, Tab, Tabs, Box, Breadcrumbs } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// ReactIcons
// Import Files
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { Typography } from "antd";
//core Component
import AboutTab from "./AddLessonplanTab/AboutLessonplanTab";
import Overview from "./AddLessonplanTab/OverviewTab";
import Curriculum from "./AddLessonplanTab/CurriculumTab";
import Benefits from "./AddLessonplanTab/BenefitsTab";
import Feedback from "./AddLessonplanTab/FeedbackTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ExtendedTables(props) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const history = useHistory();
  const { userToken } = useLogin();
  const location = useLocation();

  useEffect(() => {
    setFolderSelected(location?.folderIdFromPublishedTable);
    calculateLPXp(location?.folderIdFromPublishedTable);
  }, [location?.folderIdFromPublishedTable]);
  // const {  foldername } = location.data;
  const { id } = useParams();
  const [value, setValue] = React.useState(0);

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  //Dialog State
  const [open, setOpen] = React.useState(false);
  const [openEquip, setOpenEquip] = React.useState(false);
  // About Tab State

  const top100Films = [
    "Nursery",
    "LKG",
    "UKG",
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
  ];
  const TargetUserOptons = [
    {
      title: "Trainer",
    },
    {
      title: "Learner",
    },
  ];

  const Sports = [{ title: "Nursery" }, { title: "LKG" }, { title: "UKG" }];

  const [uid, setUid] = useState(null);
  const [duration, setDuration] = useState(null);
  const [durationSlot, setDurationSlot] = useState(null);
  const [price, setPrice] = useState(null);
  const [folderSelected, setFolderSelected] = useState("");
  const [playpoints, setPlayPoints] = useState(null);
  const [targetUser, setTargetUser] = useState([]);
  //  for trainer select and learner check box
  const [isForTrainer, setIsForTrainer] = React.useState(false);
  const [isForLearner, setIsForLearner] = React.useState(false);
  //////////
  const [title, setTitle] = useState(null);
  const [grade, setGrade] = useState([]);
  const [sports, setSports] = useState([]);
  const [about, setAbout] = useState(null);
  const [locomotorP, setLocomotorP] = useState("");
  const [bodyManagementP, setBodyManagementP] = useState("");
  const [objectControlP, setObjectControlP] = useState("");
  const [originalData, setOriginalData] = React.useState([]);
  const [bmoriginalData, setBMOriginalData] = React.useState([]);
  const [ocoriginalData, setOCOriginalData] = React.useState([]);
  const [assignequipment, setAssignQuipment] = React.useState([]);

  const handleChangeDuration = (e) => {
    setDurationSlot(e.target.value);
  };
  //Assign Skills
  // const gradeData = grade?.map((data) => {
  //   return data.title;
  // });

  function storeSkill(e, x, i, dataPercent) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  function BMstoreSkill(e, x, i, dataPercent) {
    let storeArr = [...bmoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setBMOriginalData(storeArr);
  }
  const filterdata2 = bmoriginalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  function OCstoreSkill(e, x, i, dataPercent) {
    let storeArr = [...ocoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOCOriginalData(storeArr);
  }
  const filterdata3 = ocoriginalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  const skillstore = [...filterdata, ...filterdata2, ...filterdata3];
  const skillsStore = skillstore?.map((a) => {
    console.log("Check skillstore", a);

    return {
      _id: a._id,
      mainSkill: a.mainSkill,
      subSkill: a.subSkillName,
      percent: a.percent,
      skillImg: a?.skillImg,
      // rubricsQts: a.rubricsQts,
    };
  });

  const totaldata =
    parseInt(locomotorP ? locomotorP : 0) +
    parseInt(bodyManagementP ? bodyManagementP : 0) +
    parseInt(objectControlP ? objectControlP : 0);

  //Assign Equipment
  function storeEquipment(e, x, i) {
    let storeArr = [...assignequipment];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        name: x.equipmentKitName,
        addedEquipment: x.addedEquipment,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setAssignQuipment(storeArr);
  }
  const filterAssignequiment = assignequipment?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  console.log("Check Equiomentstore", filterAssignequiment);

  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const reuploadClick = () => {
    setFile(false);
  };

  // Upload Hero Content
  const [media, setMedia] = useState();
  const [mediatype, setMediaType] = useState("video/mp4");
  const [mediaPost, setMediaPost] = useState();

  const MediaUploadClick = () => {
    setMedia(false);
  };
  function MediaUploader(e) {
    setMedia(URL.createObjectURL(e.target.files[0]));
    setMediaType(e.target.files[0].type);
    setMediaPost(e.target.files[0]);
  }

  const MediaUploadHeroImage = async (ID) => {
    const formdata = new FormData();
    formdata.append("lessonPlanId", ID);
    formdata.append("file", mediaPost);

    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/lessonPlanUploadHeroImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const MediaUploadHeroVideo = async (ID) => {
    const formdata = new FormData();
    formdata.set("lessonPlanId", ID);
    formdata.append("file", mediaPost);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/lessonPlanUploadHeroVideo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const [overviewState, setOverviewState] = useState(null);
  const [editorCurrriculamState, setEditorCurrriculamState] = useState(null);
  const [editorbenefitsState, setEditorBenefitdState] = useState(null);

  // Overview Tab
  const [convertedOverviewContent, setConvertedOverviewContent] = useState(
    null
  );
  // Curriculum Tab
  const [convertedCurriculumContent, setConvertedCurriculumContent] = useState(
    null
  );
  // Benefits Tab
  const [convertedBenefitsContent, setConvertedBenefitesContent] = useState(
    null
  );
  // Feadback Tab
  const [FeadbackQuestion, setFeadBackQuestion] = useState([
    {
      feedbackQuery: "",
      feedbackType: "",
    },
  ]);

  const FeadBack = FeadbackQuestion[0];
  console.log(
    "FeadBack.feedbackQuery == false",
    FeadBack.feedbackQuery == false
  );

  // State For Validation

  const [selectuidValid, setUidValid] = React.useState("");
  const [durationValid, setDurationValid] = useState("");
  const [durationSlotValid, setDurationSlotValid] = useState("");
  const [priceValid, setPriceValid] = useState("");
  const [titleValid, setTitleValid] = useState("");
  const [gradeValid, setGradeValid] = useState("");
  const [sportsValid, setSportsValid] = useState("");
  const [aboutValid, setAboutValid] = useState("");
  const [skillsValid, setSkillsValid] = useState("");
  const [imageValid, setImgaeValid] = useState("");
  const [folderSelectValid, setFolderSelectValid] = useState("");
  const [playpointsValid, setPlaypointsValid] = useState("");

  const [equipmentValid, setEquipmentValid] = useState("");
  const [medaiValid, setMediaValid] = useState("");
  const [overviewValid, setOverviewValid] = useState("");
  const [curriculumValid, setCurriculumValid] = useState("");
  const [benefitesValid, setBenefitesValid] = useState("");
  const [feadbackValid, setFeadBackValid] = useState(true);
  const [feadbackTypeValid, setFeadBackTypeValid] = useState(true);
  useEffect(() => {
    if (uid) {
      setUidValid(false);
    }
    if (duration) {
      setDurationValid(false);
    }
    if (price) {
      setPriceValid(false);
    }
    if (title) {
      setTitleValid(false);
    }
    if (grade?.length == 1) {
      setGradeValid(false);
    }
    if (sports?.length == 1) {
      setSportsValid(false);
    }
    if (about) {
      setAboutValid(false);
    }
    if (skillstore?.length == 1) {
      setSkillsValid(false);
    }
    if (file) {
      setImgaeValid(false);
    }
    if (filterAssignequiment?.length == 1) {
      setEquipmentValid(false);
    }
    if (media) {
      setMediaValid(false);
    }
    if (folderSelected) {
      setFolderSelectValid(false);
    }
    if (playpoints) {
      setPlaypointsValid(false);
    }
  }, [
    uid,
    duration,
    price,
    title,
    grade,
    sports,
    about,
    skillstore,
    file,
    filterAssignequiment,
    media,
    folderSelected,
    overviewState,
    playpoints,
    // editorState,
    // editorbenefitsState,
  ]);

  const TabChange = (event, newValue) => {
    // setValue(newValue);
    if (!uid) {
      setUidValid(true);
    }
    if (!duration) {
      setDurationValid(true);
    }
    if (!price) {
      setPriceValid(true);
    }
    if (!title) {
      setTitleValid(true);
    }
    if (grade?.length == 0) {
      setGradeValid(true);
    }
    if (sports?.length == 0) {
      setSportsValid(true);
    }
    if (!about) {
      setAboutValid(true);
    }
    if (skillstore?.length == 0) {
      setSkillsValid(true);
    }
    if (file === undefined) {
      setImgaeValid(true);
    }
    if (filterAssignequiment?.length == 0) {
      setEquipmentValid(true);
    }
    if (media === undefined) {
      setMediaValid(true);
    }
    if (!overviewState) {
      setOverviewValid(true);
    }
    if (!editorCurrriculamState) {
      setCurriculumValid(true);
    }
    if (!folderSelected) {
      setFolderSelectValid(true);
    }
    if (!playpoints) {
      setPlaypointsValid(true);
    }
    if (
      uid &&
      duration &&
      price &&
      title &&
      grade.length > 0 &&
      sports.length > 0 &&
      skillstore.length > 0 &&
      file &&
      filterAssignequiment.length > 0 &&
      media &&
      overviewState &&
      editorCurrriculamState &&
      editorbenefitsState &&
      playpoints &&
      folderSelected
    ) {
      setValue(newValue);
    }
  };

  function TabNext() {
    // setValue(1);
    if (!uid) {
      setUidValid(true);
    }
    if (!duration) {
      setDurationValid(true);
    }
    if (!price) {
      setPriceValid(true);
    }
    if (!title) {
      setTitleValid(true);
    }
    if (grade?.length == 0) {
      setGradeValid(true);
    }
    if (sports?.length == 0) {
      setSportsValid(true);
    }
    if (!about) {
      setAboutValid(true);
    }
    if (skillstore?.length == 0) {
      setSkillsValid(true);
    }
    if (file === undefined) {
      setImgaeValid(true);
    }
    if (filterAssignequiment?.length == 0) {
      setEquipmentValid(true);
    }
    if (media === undefined) {
      setMediaValid(true);
    }
    if (!folderSelected) {
      setFolderSelectValid(true);
    }
    if (!playpoints) {
      setPlaypointsValid(true);
    }
    if (
      uid &&
      duration &&
      price &&
      title &&
      grade.length > 0 &&
      sports.length > 0 &&
      skillstore.length > 0 &&
      file &&
      filterAssignequiment.length > 0 &&
      media &&
      playpoints &&
      folderSelected
    ) {
      setValue(1);
    }
  }
  function TabPre1() {
    setValue(0);
  }
  function TabNext1() {
    // setValue(2);
    if (!overviewState) {
      setOverviewValid(true);
    }
    if (overviewState) {
      setValue(2);
    }
  }
  function TabPre2() {
    setValue(1);
  }
  function TabNext2() {
    // setValue(3);
    if (!editorCurrriculamState) {
      setCurriculumValid(true);
    }

    if (editorCurrriculamState) {
      setValue(3);
    }
  }
  function TabPre3() {
    setValue(2);
  }
  function TabNext3() {
    // setValue(4);
    if (!editorbenefitsState) {
      setBenefitesValid(true);
    }
    if (editorbenefitsState) {
      setValue(4);
    }
  }
  function TabPre4() {
    setValue(3);
  }

  ///////////////////////////////////////////////////////////////////// Assign Skill Store In Array //////////////////////

  /////////////////////////////////////////////////Image Upload Api///////////////////////////////////

  const imageUpload = async (LPId) => {
    const formdata = new FormData();
    formdata.set("LPId", LPId);
    formdata.append("thumbnail", image);
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/uploadLessonPlanThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  ////////////////////////////////////////////////////////Get Folder  Api/////////////////////////////////
  const [foldername, setFolderName] = useState(null);
  const GetFolder = async (pageNum, rowSize) => {
    const response = await fetch(
      `${FETCH_URL}/api/lessonplan/getPaginatedFolders?isPublished=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setFolderName(res.msg);
      console.log(res.msg, "folderApi");
      // console.log("My Folder response from searching ==>", res);
    } else {
      console.log("Error while searching single school", res.err);
    }
  };
  useEffect(() => {
    GetFolder();
  }, []);

  const calculateLPXp = async (value) => {
    try {
      console.log("value", value);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getSingleFolder/${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      console.log(res);
      if (response.ok) {
        let marks = 0;
        res.msg.pages.map((data) => {
          marks = marks + parseInt(data.marks);
        });
        console.log("marks", marks);
        setPlayPoints(marks);
      } else {
        console.log("Error while searching single school", res.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /////////////////////////////////Grade Store In Array ////////////////////////////////////////////

  const submitClick = async (e) => {
    ///////////////////////////////////////////////

    console.log("equipmentData", filterAssignequiment);

    if (FeadBack.feedbackQuery == false || FeadBack.feedbackType == false) {
      setFeadBackValid(false);
      setFeadBackTypeValid(false);
      return;
    }
    if (
      skillstore.length === 0 ||
      totaldata !== 100 ||
      filterAssignequiment.length === 0
    ) {
      return;
    }
    if (filterdata.length > 0) {
      for (let key of filterdata) {
        key.percent = locomotorP;
      }
    }
    if (filterdata2.length > 0) {
      for (let key of filterdata2) {
        key.percent = bodyManagementP;
      }
    }
    if (filterdata3.length > 0) {
      for (let key of filterdata3) {
        key.percent = objectControlP;
      }
    }
    const datas = [...filterdata, ...filterdata2, ...filterdata3];
    const skillItems = datas?.map((a) => {
      console.log("Check skillstore", a);

      return {
        _id: a._id,
        mainSkill: a.mainSkill,
        subSkill: a.subSkillName,
        percent: a.percent,
        skillImg: a?.skillImg,
        // rubricsQts: a.rubricsQts,
      };
    });

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/createLessonPlan`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            LPTitle: title,
            LPUid: uid,
            LPXp: playpoints,
            LPDesc: about,
            duration: { courseDuration: +duration, durationSlot },
            grade: grade,
            Price: +price,
            sports,
            overview: overviewState,
            curriculum: editorCurrriculamState,
            benefits: editorbenefitsState,
            skills: skillItems,
            equipments: filterAssignequiment,
            isForTrainer: isForTrainer,
            isForLearner: isForLearner,
            feedback: FeadbackQuestion,
            folderId: folderSelected,
          }),
        }
      );
      let res = await responce.json();

      let LPId = res.msg2._id;
      if (LPId) {
        imageUpload(LPId);
        MediaUploadHeroImage(LPId);
        MediaUploadHeroVideo(LPId);
      }
      if (responce.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
      }
      if (responce.ok) {
        history.goBack("/admin/Lesson");
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  useEffect(() => {
    setFeadBackValid(true);
  }, [FeadBack.feedbackQuery]);
  useEffect(() => {
    setFeadBackTypeValid(true);
  }, [FeadBack.feedbackType]);

  return (
    <>
      {" "}
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div className="bodyStyle">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb" className="mt-16">
            <Link to="/admin/Lesson">
              <Button disableRipple className="Breadcrumb">
                Lesson Plan
              </Button>
            </Link>
            <div className="Breadcrumb-child">New Lesson Plan</div>
          </Breadcrumbs>
        </div>

        <Typography className="BlueColorTypography fs-18 mt-10">
          Add Lesson Plan
        </Typography>
        <Box className="mt-16">
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="TabsCustom-Style   "
            centered
          >
            <Tab
              className="ActivityTabChanges"
              label="About Lessonplan"
              {...a11yProps(0)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Overview"
              {...a11yProps(1)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Curriculum"
              {...a11yProps(2)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Benefits"
              {...a11yProps(3)}
            />

            <Tab
              className="ActivityTabChanges"
              label="Feedback"
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <AboutTab
            states={{
              TabNext,
              uid,
              setUid,
              duration,
              setDuration,
              durationSlot,
              setDurationSlot,
              price,
              setPrice,
              folderSelected,
              setFolderSelected,
              playpoints,
              setPlayPoints,
              isForTrainer,
              setIsForTrainer,
              isForLearner,
              setIsForLearner,
              title,
              setTitle,
              grade,
              setGrade,
              sports,
              setSports,
              about,
              setAbout,
              id,
              open,
              setOpen,
              openEquip,
              setOpenEquip,
              assignequipment,
              filterAssignequiment,
              calculateLPXp,
              foldername,
              file,
              setFile,
              media,
              setMedia,
              mediatype,
              setMediaType,
              originalData,
              bmoriginalData,
              ocoriginalData,
              skillstore,
              handleChangeDuration,
              TargetUserOptons,
              selectuidValid,
              durationValid,
              durationSlotValid,
              priceValid,
              titleValid,
              gradeValid,
              sportsValid,
              aboutValid,
              skillsValid,
              imageValid,
              equipmentValid,
              medaiValid,
              playpointsValid,
              folderSelectValid,
            }}
            ImageUploader={ImageUploader}
            MediaUploadClick={MediaUploadClick}
            MediaUploader={MediaUploader}
            reuploadClick={reuploadClick}
            storeSkill={storeSkill}
            BMstoreSkill={BMstoreSkill}
            OCstoreSkill={OCstoreSkill}
            locomotorP={locomotorP}
            setLocomotorP={setLocomotorP}
            bodyManagementP={bodyManagementP}
            setBodyManagementP={setBodyManagementP}
            objectControlP={objectControlP}
            setObjectControlP={setObjectControlP}
            storeEquipment={storeEquipment}
            top100Films={top100Films}
            Sports={Sports}
            handleEnter={handleEnter}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Overview
            states={{
              TabNext1,
              TabPre1,
              overviewState,
              setOverviewState,
              overviewValid,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Curriculum
            states={{
              TabNext2,
              TabPre2,
              curriculumValid,
              editorCurrriculamState,
              setEditorCurrriculamState,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Benefits
            states={{
              TabNext3,
              TabPre3,
              editorbenefitsState,
              setEditorBenefitdState,
              benefitesValid,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Feedback
            states={{
              TabPre4,
              FeadbackQuestion,
              feadbackValid,
              setFeadBackQuestion,
            }}
            submitClick={submitClick}
          />
        </TabPanel>
      </div>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
  DialogContent,
  Grid,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
// ReactIcons
import { AiOutlineDelete } from "react-icons/ai";
import { BiWindowClose } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { FaRegClone } from "react-icons/fa";
//core Component

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Clone Page
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  folders,
  PageID,
  getFolderPages,
  onClose,
}) {
  const classes = useStyles();
  console.log("Check Clone Msg", folders);
  const { userToken } = useLogin();

  const [open, setOpenDialog] = React.useState(false);
  const [folderSelected, setFolderSelected] = React.useState("");
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  console.log("Check Folder selected ID", folderSelected);
  const handleClose = async () => {
    setOpenDialog(false);
  };
  const CloneFolder = async () => {
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/createClonePage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ folderId: folderSelected, pageId: PageID }),
        }
      );
      const res = await responce.json();
      if (responce.ok) {
        handleClose();
        getFolderPages();
        onClose();
        console.log("check Clone Status ", res.msg);
      } else {
        handleClose();
      }
    } catch (error) {
      console.log("Catch Block Error ==========>", error);
    }
  };

  return (
    <div>
      <Typography
        align="center"
        className="cursor mt-40px hover Menu-IconStyle"
        onClick={handleClickOpen}
      >
        <FaRegClone className="ActionIcon fs-70" />
        <Typography className="GreyColorTypography ">Clone</Typography>
      </Typography>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent style={{ margin: "20px 0px" }}>
          <Typography className="GreyColorTypography ">
            {" "}
            Select Folder{" "}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className="mt-5px"
          >
            <Grid item md={6}>
              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className="SkillsSelectStyle"
                  value={folderSelected ? folderSelected : 0}
                  onChange={(e) => {
                    setFolderSelected(e.target.value);
                  }}
                  defaultValue={0}
                  color="white"
                  label="Select Folder"
                  disableUnderline
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Where you want to clone this page
                  </MenuItem>
                  {folders &&
                    folders.length > 0 &&
                    folders.map((data, index) => (
                      <MenuItem value={data._id} className="Selectmenustyle">
                        {data.folderName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>{" "}
            </Grid>
            <Grid item md={6}>
              <Typography align="right" style={{ width: "100%" }}>
                <Button
                  className="GreenButton"
                  style={{ height: "32px" }}
                  onClick={CloneFolder}
                  autoFocus
                >
                  Submit
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

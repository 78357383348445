import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { createMuiTheme } from "@material-ui/core/styles";
import { Typography, styled, Box, Tabs, Tab } from "@material-ui/core";
//core component
import Organisation from "./OrganisationDetails";
import Contact from "./ContactDetail";
import Document from "./DocumentDetail";
import Agreement from "./Agreement";
// import EditDialog from "../PSchoolActionButton's/PSEditDialog/HSEditDialog";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Schooldetails(props) {
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const { data } = props;
  const [schoolDocs, setSchoolDocs] = useState(data?.schoolDocs);
  const [schoolAgreement, setSchoolAgreement] = useState(data?.agreement);

  useEffect(() => {
    setSchoolDocs(data?.schoolDocs);
    setSchoolAgreement(data?.agreement);
  }, [data]);

  return (
    <>
      <Box>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            style={{ marginLeft: "20px " }}
            className="ActivityTabChanges"
            label="Orgnasation Detail"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Contact Detail"
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Documents"
            {...a11yProps(2)}
          />
          <Tab
            style={{ marginRight: "40px" }}
            className="ActivityTabChanges"
            label="Agreement"
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Organisation data={data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Contact data={data} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Document
            data={data}
            schoolDocs={schoolDocs}
            setSchoolDocs={setSchoolDocs}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Agreement
            data={data}
            schoolAgreement={schoolAgreement}
            setSchoolAgreement={setSchoolAgreement}
          />
        </TabPanel>
      </Box>
    </>
  );
}

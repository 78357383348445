import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
// ReactIcons
import { IoCloseSharp } from "react-icons/io5";
import { BsFileEarmark } from "react-icons/bs";
import { FETCH_URL } from "fetchIp";

import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(90),
    height: theme.spacing(37),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Add Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ trainerId, getAllTrainers }) {
  const classes = useStyles();
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [open, setOpen] = useState(false);
  const [grades, setGrades] = useState([]);
  const [gradeValue, setGradeValue] = useState(null);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  //   helper function
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);

    setGrades([]);
  };
  //   api calling
  const getAllGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setGrades(res.msg);
      } else {
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  const assignTrainerToGrade = async () => {
    try {
      const response1 = await fetch(
        `${FETCH_URL}/api/school/grade/getSingleGrade/${gradeValue?.gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res1 = await response1.json();

      const response = await fetch(
        `${FETCH_URL}/api/learner/assignTrainerToGrade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            trainerId,
            location: schoolDetail?.location,
            schoolId: schoolDetail?.schoolId,
            schoolName: schoolDetail?.schoolName,
            gradeId: gradeValue?.gradeId,
            gradeName: gradeValue?.grade,
            departmentId: res1?.msg?.schoolId?.departmentDetails?.departmentId,
            departmentName:
              res1?.msg?.schoolId?.departmentDetails?.departmentName,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("Trainer Assigned ======>", res.msg);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllTrainers();
        handleClose();
      } else {
        console.log("Error while fetching distinct school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllGrade();
  }, []);
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Grade">
        <IconButton className="ActionButtonSize">
          <BsFileEarmark
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  School Name <span className="ImpColor"> *</span>
                </Typography>
                <Grid container item md={12} sm={12} xs={12} lg={12}>
                  <FormControl
                    variant="standard"
                    className="FormControlInput"
                    style={{ backgroundColor: "white" }}
                  >
                    <Select
                      className="SkillsSelectStyle "
                      color="white"
                      disableUnderline
                      value={schoolDetail?.schoolName}
                      disabled
                    >
                      <MenuItem
                        className="Selectmenustyle"
                        value={schoolDetail?.schoolName}
                        disabled
                      >
                        {schoolDetail?.schoolName}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Typography className="InputTitleTypography  GreyColorTypography">
                  Grade<span className="ImpColor"> *</span>
                </Typography>
                <Grid container item md={12} sm={12} xs={12} lg={12}>
                  <FormControl
                    variant="standard"
                    className="FormControlInput"
                    style={{ backgroundColor: "white" }}
                  >
                    <Select
                      className=" SkillsSelectStyle "
                      value={gradeValue ? gradeValue : 0}
                      onChange={(e) => {
                        setGradeValue(e.target.value);
                      }}
                      color="white"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0} disabled>
                        Select Grade
                      </MenuItem>
                      {grades &&
                        grades.length > 0 &&
                        grades.map((grade, index) => (
                          <MenuItem
                            className="Selectmenustyle"
                            value={grade}
                            key={index}
                          >
                            {grade.grade}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid direction="column" item md={6} sm={6} xs={6} lg={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Location<span className="ImpColor"> *</span>
              </Typography>
              <Grid container item md={12} sm={12} xs={12} lg={12}>
                <FormControl
                  variant="standard"
                  className="FormControlInput"
                  style={{ backgroundColor: "white" }}
                >
                  <Select
                    className=" SkillsSelectStyle "
                    value={schoolDetail?.location}
                    color="white"
                    disableUnderline
                  >
                    <MenuItem
                      className="Selectmenustyle"
                      value={schoolDetail?.location}
                    >
                      {schoolDetail?.location}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <DialogActions style={{ marginTop: "65px " }}>
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={assignTrainerToGrade}
                >
                  Save
                </Button>
                <Button
                  className="GreyButton GradeCancelB"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Box, Tab, Tabs, DialogContent, Grid } from "@material-ui/core";
// //core module
// import AssesmentLearnerTab from "./AssesmentTab's/Asses-Learner";
// import AssesmentTrainerTab from "./AssesmentTab's/Asses-Trainer";
// import { saveLearnerTrainerTabValue } from "redux/action/TableAction";
// import { useDispatch } from "react-redux";
// // import { saveLearnerTrainerTabValue } from "redux/action/TableAction";

// function TabPanel(props) {
//   const { children, assesvalue, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={assesvalue !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {assesvalue === index && <Box>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   assesvalue: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// export default function BasicTabs(props) {
//   const {
//     states: {
//       assessmentdata,
//       learnerAssessmentData,
//       setLearnerAssessmentData,
//       trainerAssessmentData,
//       setTrainerAssessmentData,
//       assesvalue,
//       setAssesValue,
//     },
//     // getAllAssesment,
//     handleSearch,
//     getAllAssesmentTrainer,
//     getAllAssesmentLearner,
//   } = props;
//   // const dispatch = useDispatch();
//   // const TabChange = (event, newValue) => {
//   //   setAssesValue(newValue);
//   //   dispatch(saveLearnerTrainerTabValue(newValue));
//   // };
//   const [value, setValue] = React.useState(0);

//   const TabChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   const label = { inputProps: { "aria-label": "Switch demo" } };

//   return (
//     <>
//       <Grid
//         container
//         style={{
//           marginTop: "20px",
//         }}
//       >
//         <Grid item conatiner>
//           <Tabs
//             value={value}
//             onChange={TabChange}
//             aria-label="basic tabs example"
//             className="AResultTabStyle"
//           >
//             <Tab
//               className="AResultTabChanges"
//               label="Learner"
//               {...a11yProps(0)}
//             />
//             {/* <Tab
//               className="AResultTabChanges"
//               label="trainer"
//               {...a11yProps(1)}
//               disabled
//             />{" "} */}
//           </Tabs>
//         </Grid>
//       </Grid>
//       <DialogContent dividers={false} className="TabPanelStyle">
//         <TabPanel assesvalue={assesvalue} index={0}>
//           <AssesmentLearnerTab
//             // getAllAssesment={getAllAssesment}
//             getAllAssesmentLearner={getAllAssesmentLearner}
//             handleSearch={handleSearch}
//             states={{ assessmentdata, learnerAssessmentData }}
//           />
//         </TabPanel>
//         <TabPanel assesvalue={assesvalue} index={1}>
//           <AssesmentTrainerTab
//             // getAllAssesment={getAllAssesment}
//             getAllAssesmentTrainer={getAllAssesmentTrainer}
//             handleSearch={handleSearch}
//             states={{ assessmentdata, trainerAssessmentData }}
//           />
//         </TabPanel>
//       </DialogContent>
//     </>
//   );
// }

import React, { useState } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";

//Core Component

import AssesSwitch from "../AssesActionDialog/AssesmentActionDialog/AssesSwitchDialog";
import AssesmentEdit from "../AssesActionDialog/AssesmentActionDialog/AssesEditDialog/AssesmentEdit";
import AssignGrade from "../AssesActionDialog/AssesmentActionDialog/AssesmentAssignGrade/AGradeMainDialog";
import AssignDivision from "../AssesActionDialog/AssesmentActionDialog/AssessmentAssignDivision/ADivisionMainDialog";

import AssesmentProfileDialog from "../AssesmentProfile/AssesmentProfile";
export default function BasicTabs(props) {
  const {
    states: {
      assessmentdata,
      learnerAssessmentData,
      schoolSelected,
      totalA,
      pageA,
      searchA,
    },
    handleSearch,
    getAllAssesmentLearner,
  } = props;
  const [access, setAccess] = useState(1);
  const [posts, setPosts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [value, setValue] = React.useState(0);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(learnerAssessmentData, "i am inside learner");

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID </TableCell>
              <TableCell> Assessment Name</TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> PP</TableCell>
              <TableCell className="Head"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerAssessmentData &&
              learnerAssessmentData.length > 0 &&
              learnerAssessmentData.map((assesmnet) => (
                <TableRow>
                  <TableCell className="Col">
                    {" "}
                    <Link> {assesmnet.uid} </Link>{" "}
                  </TableCell>
                  <TableCell className="Col width100px">
                    <Link>
                      <AssesmentProfileDialog assesmnet={assesmnet} />
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Typography align="center">
                      <Typography
                        align="center"
                        className="GreyColorTypography500 overflow-style-nwidth width100px"
                      >
                        {assesmnet.assessmentDesc}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className="Col">{assesmnet.assignedXp}</TableCell>

                  <TableCell className="Action">
                    <AssesmentEdit
                      assesmnet={assesmnet}
                      getAllAssesmentLearner={getAllAssesmentLearner}
                    />
                    <IconButton className="ActionButtonSize">
                      <AssignGrade
                        assesmnetId={assesmnet._id}
                        getAllAssesmentLearner={getAllAssesmentLearner}
                      />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <AssignDivision
                        assesmnetId={assesmnet._id}
                        getAllAssesmentLearner={getAllAssesmentLearner}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <AssesSwitch
                        handleSearch={handleSearch}
                        // accessCheck={access}
                        access={assesmnet.access}
                        assesmnetId={assesmnet._id}
                        accessCheck={access}
                        getAllAssesmentLearner={getAllAssesmentLearner}
                      />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {learnerAssessmentData?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Assessment Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected ||
      searchA ||
      learnerAssessmentData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              handleSearch("", value, value2);
              // setPage(value);
            }}
            pageSize={rowsPerPage}
            total={totalA}
            current={pageA}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

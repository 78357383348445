import * as React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  Title1: {
    color: "#2E3180",
    fontWeight: 700,
  },
  Title2: {
    color: "#474747",
    fontWeight: 700,
  },
}));
export default function OrganisationDetails() {
  const location = useLocation();
  const contactDetails = location.state;
  return (
    <Grid container spacing={3} className="SDCConatiner">
      <Grid item lg={5} md={6} xs={8}>
        <Grid container item>
          <Grid
            item
            container
            xs={5}
            direction="column"
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="BlueColorTypography SPCardTypo">
                School Name
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Location
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Full Address
              </Typography>

              <Typography className="BlueColorTypography SPCardTypo">
                Zipcode
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                State
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Country
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={7}
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="GreyColorTypography SPCardTypo">
                {contactDetails?.school?.organisationDetails?.schoolName}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {contactDetails?.school?.organisationDetails?.location}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {contactDetails?.school?.contactDetail?.address}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {contactDetails?.school?.contactDetail?.zipCode}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {contactDetails?.school?.contactDetail?.state}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {contactDetails?.school?.contactDetail?.country}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={4} md={6} xs={8}>
        <Grid container item className="HSGrid6Style" direction="column">
          <div className="HSdivStyle6">
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={6} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative Name
                </Typography>
              </Grid>

              <Grid item container md={6} sm={6} xs={4}>
                <Typography className="GreyColorTypography SPCardTypo">
                  {contactDetails?.school?.contactDetail?.representativeName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={8} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative <br />
                  Mobile Number
                </Typography>
              </Grid>

              <Grid item container md={6} sm={4} xs={4}>
                <Typography className="GreyColorTypography SPCardTypo pt-17 GtSideGridStyle">
                  {
                    contactDetails?.school?.contactDetail
                      ?.representativeMobilePhoneNo
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={8} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative <br /> Email ID
                </Typography>
              </Grid>

              <Grid item container md={6} sm={4} xs={4}>
                <Typography className="GreyColorTypography  SPCardTypo pt-17 GtSideGridStyle">
                  {contactDetails?.school?.contactDetail?.representativeEmailId}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={12}>
              <Grid item container md={6} sm={8} xs={8}>
                <Typography className="BlueColorTypography SPCardTypo">
                  Representative
                  <br /> Address
                </Typography>
              </Grid>

              <Grid item container md={6} sm={4} xs={4}>
                <Typography className="GreyColorTypography SPCardTypo pt-17 GtSideGridStyle">
                  {contactDetails?.school?.contactDetail?.representativeAddress}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid item lg={3} md={4} xs={5}>
        <Grid container item>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
            direction="column"
            className="SDCGrid2"
          >
            <img
              src={contactDetails?.school?.contactDetail?.representativeImg}
              className="SDCGrid2Image"
            />
            <Typography
              variant="h6"
              component="div"
              className="BlueColorTypography SDCGrid2Typo"
            >
              Representative Name
            </Typography>
            <Typography
              variant="h6"
              component="div"
              align="center"
              className="GreenColorTypography SDCGrid2Typo"
            >
              {contactDetails?.school?.contactDetail?.representativeName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

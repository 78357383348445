import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
// ReactIcons
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";

//core Component
import { useDispatch } from "react-redux";
import { deleteSchool } from "redux/action/TableAction";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleSmall" {...other}>
      <Typography className="DialogTitleTypographySmall">
        Are you sure you want to delete this item?
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ schoolId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await dispatch(deleteSchool(userToken, schoolId));
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Delete">
        <IconButton className="ActionButtonSize">
          <RiDeleteBin6Line
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogActions>
          <Button
            className="GreyButton DeleteCancelB"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton  DeleteOkB"
            autoFocus
            // onClick={() => history.goBack()}
            onClick={handleDelete}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

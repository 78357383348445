import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
// ReactIcons
import { GoSearch } from "react-icons/go";

export default function SelectCourseTab(props) {
  const {
    states: { lessonplan, selectLessonPlanText, lessonPlanId, setLessonPlanId },
    // storeLessonPlan,
    // originalData,
    // filterdata,
  } = props;

  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid
          item
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <FormControl className="CourseSerachInput">
            <Input
              disableUnderline
              className="GCourseInput"
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ marginRight: "7px" }}>
                    <GoSearch className=" HaedAddIcon" />
                  </IconButton>
                </InputAdornment>
              }
              label="Serach LessonPlan"
            />
          </FormControl>
        </Grid>
        {lessonplan &&
          lessonplan.length > 0 &&
          lessonplan.map((data, index) => (
            <Grid md={6} sm={6} xs={6} lg={6}>
              <FormGroup className="GCourseFromGroup AssignActivitesGridPaper">
                <FormControlLabel
                  control={
                    <Radio
                      checked={lessonPlanId === data._id}
                      onChange={(e) => {
                        setLessonPlanId(e.target.value);
                        // storeLessonPlan(e, data, index);
                      }}
                      value={data._id}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                  }
                  // onChange={(e) => storeLessonPlan(e, data, index)}
                  value={data.LPTitle}
                  className="CheckLabelStyleBlue"
                  style={{ justifyContent: "space-between" }}
                  label={
                    <div className="GCourseLabel">
                      <div>
                        <Typography>{data.LPTitle}</Typography>
                        <Typography className="GreyColorTypography">
                          {data.LPUid}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="GreyColorTypography">
                          {/* {data.LPDuration.split("T")[0]} */}
                        </Typography>
                        <Typography className="GreyColorTypography">
                          {data.LPXp}
                        </Typography>
                      </div>
                    </div>
                  }
                />
              </FormGroup>
            </Grid>
          ))}
        {!lessonPlanId && (
          <Grid container style={{ marginTop: "10px" }}>
            <Typography className="warningTypography">
              {selectLessonPlanText}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Pagination } from "antd";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  Input,
  Grid,
  MenuItem,
} from "@material-ui/core";

export default function ExtendedTables({ equipmentDetails }) {
  const { userToken } = useLogin();
  const [access, setAccess] = useState(1);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginatedLearner, setPaginatedLearner] = useState([]);

  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const equipmentId = equipmentDetails.equipment._id;

  console.log(equipmentDetails, "equipmentDetails>>>>>>>>>>>>>>>>>");
  /////////////////////////////////////////////////////////////////////////////////////
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          console.log("Else condition coming", nameOfSchool);

          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////getDistinctSchoolAndLocation////////////////////////////
  ////////////////////////getDropDownschools/////////////////////////////////
  const getDropDownSchools = async (location) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My DropDown schools ============>", res);
        setPaginatedLearner(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };
  ////////////////////////getDropDownschools/////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////

  const getPaginatedLearners = async (equipmentId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedLearner?equipmentId=${equipmentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated learners in equipments==>", res);
        setPaginatedLearner(res.msg);
      } else {
        console.log("Error while paginated learners", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log(paginatedLearner, "paginatedLearner>>>>>>>>>>>>");
  ///////////////////////////////////////////////////////////////////////////////
  const quantity = paginatedLearner;

  ////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getPaginatedLearners(equipmentId);
    getDistinctSchoolAndLocation();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <Grid container justify="center" style={{ marginTop: "20px" }}>
        <Grid item md={2} sm={2} xs={2} lg={2}>
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              // onChange={locChange}
              label="Select School"
              color="white"
              disableUnderline
              defaultValue={0}
              onChange={(e) => {
                console.log("event target =======>", e.target.value);
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
              }}
            >
              <MenuItem value={0} className="Selectmenustyle" disabled>
                Select a school
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="standard" className="MainPageFCIG">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getDropDownSchools(e.target.value);
              }}
              label="Select Location"
              disableUnderline
              defaultValue={0}
            >
              <MenuItem value={0} className="Selectmenustyle" disabled>
                Select a location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div>
        <div className="ResponsiveTableAL">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="ColHeadALBLue">UID </TableCell>
                <TableCell className="ColHeadALBLue"> Name</TableCell>

                <TableCell className="ColHeadALBLue">Grade </TableCell>
                <TableCell className="ColHeadALBLue"> Allocated On </TableCell>
                <TableCell className="ColHeadALBLue"> Quantity </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedLearner.map((lDeatails) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    {lDeatails.learnerDetail.regNo}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {lDeatails.learnerDetail.fullName}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {lDeatails.academicDetails.grade}{" "}
                  </TableCell>{" "}
                  <TableCell className="Col700WAB"> 28/10/2021 </TableCell>
                  <TableCell className="Col700WAB"> 40 </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

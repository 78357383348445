import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  FormControl,
  makeStyles,
  Input,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { IoIosAddCircle } from "react-icons/io";
//React Icons
import { BsFileEarmark } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";

//core comnponent
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(90),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Add Divison
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddGradeDivision({
  gradeData,
  gradeId,
  getAllDivisons,
}) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const [division, setDivision] = useState("");

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const DialogClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
    setDivision("");
  };

  //   const [gradeData, setGradeData] = useState(null);

  const submitClick = async (e) => {
    if (division == "" || null) {
      setSnackerropen(true);
      setSnackErrMsg("Fill Division Name");

      return;
    }

    // e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/school/grade/addDivision`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            schoolId: gradeData.schoolId,
            grade: gradeData.grade,
            location: gradeData.location,
            schoolName: gradeData.schoolName,
            schoolUid: gradeData.schoolUid,
            division: division,
            gradeId: gradeData.gradeId,
          }),
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        DialogClose();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        console.log("Mah respo =====>", res);
        getAllDivisons(gradeId);
        setDivision("");
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  //   console.log(gradeData, "gradeData>>>>>>>>>>>>>>>>>>>>.");
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Button
        className="ButtonTransparent "
        onClick={handleClickOpen("paper")}
        style={{ paddingTop: "10px" }}
      >
        <IoIosAddCircle
          className="MiniAddIcon IconColor"
          onClick={handleClickOpen("paper")}
        />
        Add New Divison
      </Button>
      {/* <Tooltip title="Grade">
        <IconButton className="ActionButtonSize">
          <BsFileEarmark
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip> */}
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>

        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid item md={6} sm={6} xs={6} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  Grade
                </Typography>
                <Grid item container md={12} sm={12} xs={12}>
                  <Input
                    className="Input1"
                    // value={data.schoolUid}
                    value={gradeData.grade}
                    disabled
                    // onChange={(e) => {
                    //   setSchoolUid(e.target.value);
                    //   fetchUserDetails(e.target.value);
                    // }}
                  ></Input>
                </Grid>

                <Typography className="InputTitleTypography  GreyColorTypography">
                  Division Name <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12}>
                  <Input
                    className="Input1"
                    value={division}
                    onChange={(e) => setDivision(e.target.value)}
                    // disabled
                    // value={"i am divison"}
                    // value={data.organisationDetails.location}
                  ></Input>
                </Grid>
              </Grid>
            </Grid>

            <Grid direction="column" item md={6} sm={6} xs={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                School Name
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Input
                  className="Input1"
                  disabled
                  value={gradeData.schoolName}
                ></Input>
              </Grid>
              {/* <Typography className="InputTitleTypography  GreyColorTypography">
                Grade <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Input
                  className="Input1"
                  onChange={(e) => {
                    setGradeName(e.target.value);
                    // fetchUserDetails(e.target.value);
                  }}
                />
              </Grid> */}
              <DialogActions className="SGradeBottom">
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={() => {
                    // addGrade();
                    submitClick();
                    // setOpen(false);
                  }}
                >
                  Add
                </Button>
                <Button
                  className="GreyButton GradeCancelB"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React from "react";
import {
  Typography,
  Grid,
  TextareaAutosize,
  Input,
  Button,
} from "@material-ui/core";
//React Icons
import { IoIosAddCircle } from "react-icons/io";
export default function ActicityDetail() {
  return (
    <>
      <Grid container>
        <Grid container item md={8} sm={8} xs={8}>
          <Grid container item className="DailogLTRBStyle">
            <Grid container item md={2} sm={2} xs={2}>
              <Typography
                className="BlueColorTypography ALTypoLtp "
                style={{ paddingLeft: "25px" }}
              >
                ACT1.1
              </Typography>
            </Grid>
            <Grid container item md={4} sm={4} xs={4} lg={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography ALTypoLtp">
                Smiley Bounce Standing
              </Typography>
            </Grid>
            <Grid container item md={2} sm={2} xs={2}>
              <Typography className="BlueColorTypography ALTypoLtp">
                Rating 6
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={4}
              sm={4}
              xs={4}
              lg={4}
              sm={4}
              xs={4}
              justify="flex-end"
            >
              <Typography
                className="BlueColorTypography ALTypoLtp"
                style={{ paddingRight: "25px" }}
              >
                PP 50
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Typography className="BlueColorTypography AdDesc">
              Description
            </Typography>
          </Grid>
          <Grid item containermd={12} sm={12} xs={12} lg={12}>
            <TextareaAutosize
              className="APActivityDetailTextArea"
              aria-label="minimum height"
              readOnly
              style={{ cursor: "default" }}
            />
          </Grid>
          <Grid item containermd={12} sm={12} xs={12} lg={12}>
            <Input
              className="APInput1"
              aria-label="minimum height"
              readOnly
              style={{ cursor: "default" }}
            />
          </Grid>
        </Grid>
        <Grid container item md={4} sm={4} xs={4} lg={4}>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={10}
            lg={10}
            style={{
              backgroundColor: "white",
              marginTop: "5.6%",
              borderRadius: "11px",
              marginLeft: "20px ",
            }}
          >
            <Typography className="BlueColorTypography AddLogoTypography">
              Preview
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

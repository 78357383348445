import React from "react";
import {
  Typography,
  Button,
  DialogActions,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
export default function Benefits(props) {
  const {
    states: {
      benefitesState,
      setBenefitesState,
      benefitesValid,
      inputDisable,
      setInputDisable,
    },
    EditSubmitClick,
  } = props;

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Benefits
          </Typography>
          <Grid container>
            <TextareaAutosize
              className={
                benefitesValid
                  ? "textareaauto  width100 border-red  mt-16"
                  : " textareaauto width100   border-grey  mt-16"
              }
              aria-label="maximum height"
              disabled={inputDisable ? true : false}
              value={benefitesState}
              onChange={(e) => setBenefitesState(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Tabs, Tab, Grid } from "@material-ui/core";
//Icons
import SkillsProgress from "./SkillsProgress";
import AssignSkills from "./AssignSkills";
import { FETCH_URL } from "fetchIp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LearnerDialog({ learner }) {
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [skills, setSkills] = useState(null);

  const newArray = learner?.assignedSkills?.map((obj) => ({
    skillId: obj?.skillId,
  }));

  const GetAssignedSkills = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/skill/getSkillBySkillIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ skillIds: newArray }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setSkills(res.msg);
        // //.log("Get SKills Responce   ", res.msg);
      } else {
        //.log("Check Skills Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  useEffect(() => {
    GetAssignedSkills();
  }, []);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        justify="center"
        alignItems="center"
      >
        <Tabs
          value={value}
          onChange={TabChange}
          centered
          aria-label="basic tabs example"
          className="GProcessTabStyle mt-20"
        >
          <Tab
            className="GProceesTabChanges"
            label="Assigned"
            {...a11yProps(0)}
          />
          <Tab
            className="GProceesTabChanges"
            label="FMS Progresss"
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      <TabPanel value={value} index={0} className="TabPanelStyle">
        <AssignSkills skills={skills} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SkillsProgress learner={learner} />
      </TabPanel>
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Button,
  Tab,
} from "@material-ui/core";
import { Link } from "react-router-dom";

//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";

//core component
import ContactTab from "./LearnerTab/LGPAcadmicTab";
import AcademicTab from "./LearnerTab/GProgressTab/LGPogressTab";
import LearnerTab from "./LearnerTab/LGPPersonalDetailsTab";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ ldata }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [datevalue, setDateValue] = React.useState(null);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DialogClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    console.log(open);
    setOpen(false);
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  function TabNext3() {
    setValue(3);
  }

  return (
    <div>
      <Link className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        {ldata.academicDetails.schoolName}
      </Link>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="lg"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Personal Detail"
                {...a11yProps(0)}
              />
              <Tab className="TabStyle" label="Academic " {...a11yProps(1)} />
              <Tab className="TabStyle" label="Progress" {...a11yProps(2)} />
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>{" "}
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <LearnerTab learner={ldata} Tabnext={TabNext} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ContactTab learner={ldata} Tabnext2={TabNext2} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AcademicTab learner={ldata} Tabnext3={TabNext3} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Grid, Typography, Tab, Tabs } from "@material-ui/core";
// core components

import BodyMgtTab from "./GSkillTab/GSBodyMgtTab";
import ObjMgtTab from "./GSkillTab/GSObjMgtTab";
import LocomotorTab from "./GSkillTab/GSLocomotorTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables({ gradeId }) {
  const [value, setValue] = React.useState(0);
  const { userToken, schoolDetail, loadingHandler } = useLogin();

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [skillsData, setSkillsData] = React.useState(null);

  // Get API
  const searchSkill = async (gradeId) => {
    loadingHandler(true);
    try {
      let mainSkill;
      switch (value) {
        case 0:
          mainSkill = "Locomotor";
          break;
        case 1:
          mainSkill = "Body Management";
          break;
        case 2:
          mainSkill = "Object Control";
          break;
        default:
          console.error("Invalid Value");
      }
      const response = await fetch(
        `${FETCH_URL}/api/skill/getPaginatedSkill?mainSkill=${mainSkill}&gradeId=${gradeId}&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        console.log("My response of Skills in paginated skills==>", res);
        setSkillsData(res.msg);
      } else {
        loadingHandler(false);
        setSkillsData([]);
        console.log("Error while fetching Skills", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };

  React.useEffect(() => {
    searchSkill(gradeId);
  }, [value]);
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Locomotor"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Body Manangment "
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Object Managmnet"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <LocomotorTab states={{ value, setValue }} skillsData={skillsData} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BodyMgtTab skillsData={skillsData} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ObjMgtTab skillsData={skillsData} />
      </TabPanel>
    </>
  );
}

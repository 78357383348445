import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

//core Module
import SubSkills from "./BMEdit";
import RubricsTab from "./BMRubricsEdit";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditDialog(props) {
  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
      skill,
      skillsData,
    },
    searchSkill,
    gradeId,
  } = props;
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const { userToken } = useLogin();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    // console.log(open);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  console.log(skill, "skills inside edit skills");
  //////////skills editing api//////////////////////////////////////

  const submitClick = async (e) => {
    // For Editing
    e.preventDefault();
    const response = await fetch(
      `${FETCH_URL}/api/skill/editSkill/${skill._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          mainSkill: mainSkill ? mainSkill : skill.mainSkill,
          subSkillName: subSkillName ? subSkillName : skill.subSkillName,
          subSkillUid: subSkillUid ? subSkillUid : skill.subSkillUid,
          subSkillDesc: subSkillDesc ? subSkillDesc : skill.subSkillDesc,
        }),
      }
    );

    const res = await response.json();
    console.log(res, "yo i am res man");
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      searchSkill(gradeId);
      setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
    }
  };
  //////////skills editing api//////////////////////////////////////
  return (
    <div>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Sub Skill Details"
              {...a11yProps(0)}
            />
            {/* <Tab className="TabStyle" label="Rubrics" {...a11yProps(1)} /> */}
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SubSkills
              states={{
                mainSkill,
                setMainSkill,
                subSkillName,
                setSubSkillName,
                subSkillUid,
                setSubSkillUid,
                subSkillDesc,
                setSubSkillDesc,
                xpAssigned,
                setXpAssigned,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
                skill,
              }}
              TabNext={TabNext}
              searchSkill
              submitClick={submitClick}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <RubricsTab
              states={{
                mainSkill,
                setMainSkill,
                subSkillName,
                setSubSkillName,
                subSkillUid,
                setSubSkillUid,
                subSkillDesc,
                setSubSkillDesc,
                xpAssigned,
                setXpAssigned,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
                skills,
              }}
              submitClick={submitClick}
              searchSkill
            />
          </TabPanel> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  Grid,
  MenuItem,
  IconButton,
  Toolbar,
  Box,
  Typography,
  Input,
  InputAdornment,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Select,
  FormControl,
} from "@material-ui/core";
// // material-ui icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";

// core components
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function ExtendedTables() {
  const { userToken, departmentDetail } = useLogin();
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const [gradeData, setGradeData] = useState(null);

  // API Calling
  const getAllGrades = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?pagination=true&schoolName=${departmentDetail?.departmentName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My  All Grade Get response ==>", res);
        setGradeData(res.msg);
        // history.push("/admin/dashboard");
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  ///////////////////////////GradeSearch//////////////////////////////////////
  const GradeSearch = async (searchValue, pageNum, rowSize) => {
    const response = await fetch(
      `${FETCH_URL}/api/school/grade/searchGrade/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setGradeData(res.msg);
    } else {
      setGradeData([]);
    }
  };
  ///////////////////////////GradeSearch//////////////////////////////////////
  console.log(gradeData, "hey i am grade data");
  useEffect(() => {
    getAllGrades();
  }, []);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Delhi Public School, Pune</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    GradeSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>{" "}
          {/* <IconButton className="TopUploadButton">
            <BsUpload className="UploadIcon" />
          </IconButton> */}
        </div>
      </Toolbar>{" "}
      <Typography className="GreyColorTypography  Title2Typo">
        {" "}
        Active Grades
      </Typography>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">Grade</TableCell>
              <TableCell className="ColHead">School Name </TableCell>
              <TableCell className="ColHead"> Location</TableCell>
              <TableCell className="ColHead"> Learners</TableCell>
              <TableCell className="ColHead"> Trainers</TableCell>
              <TableCell className="ColHead"> Courses</TableCell>
              <TableCell className="ColHead"> Lesson Plans</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {gradeData &&
              gradeData.length > 0 &&
              gradeData.map((grade, index) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link
                      to={{
                        pathname: "/hos/gradeprofile",
                        state: { data: grade },
                      }}
                    >
                      {" "}
                      {grade?.grade}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {grade?.schoolName}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {" "}
                    {grade?.location}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {grade?.assignedLearners &&
                    grade?.assignedLearners?.length > 0
                      ? grade?.assignedLearners?.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Col700WAB">
                    {" "}
                    {grade?.assignedTrainers &&
                    grade?.assignedTrainers?.length > 0
                      ? grade?.assignedTrainers?.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Col700WAB">
                    {grade?.assignedCourses &&
                    grade?.assignedCourses?.length > 0
                      ? grade?.assignedCourses?.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Col700WAB ">
                    {" "}
                    {grade?.assignedLessonPlans &&
                    grade?.assignedLessonPlans?.length > 0
                      ? grade?.assignedLessonPlans?.length
                      : "--"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          // onChange={(value) => setPage(value)}
          onChange={(value) => {
            GradeSearch("undefined", value);
            setPage(value);
          }}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";

//React-Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsUpload } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";

import { IoIosAddCircle } from "react-icons/io";
import learnerSample from "../../../../assets/csv/learner.csv";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Upload Learner
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedDialogs({ getAllLearners, schoolData }) {
  const { userToken } = useLogin();
  const [gradeSelected, setGradeSelected] = useState("");
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);

  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(120),
      // height: theme.spacing(27),
    },
  }));

  const [readgrade, setReadGrade] = useState("");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };

  const [scroll, setScroll] = React.useState("paper");
  const [rowData, setRowData] = useState(null);
  ///////////////////////////////////////////////////////////
  const [SchoolName, setSchoolName] = useState([]);
  // const [grade, setGrade] = useState([]);
  const [divisonData, setDivisonData] = useState(null);
  const [divisionId, setDivisionId] = useState(null);
  const [fileError, setFileError] = useState("");
  // console.log(rowData, "rowData>>>>>>>>>>>>>>>>>");
  let schooluid = rowData?.schoolUid;
  let schoolName = rowData?.organisationDetails.schoolName;
  let location = rowData?.organisationDetails.location;
  let gradename = gradeSelected.grade;
  let schoolId = rowData?._id;
  let gradeId = gradeSelected.gradeId;
  let departmentId = rowData?.departmentDetails?.departmentId;
  let departmentName = rowData?.departmentDetails?.departmentName;

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  // Grade add states
  // useEffect(() => {
  //   handleSchoolSearch();
  // });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRowData(null);
    setDivisonData(null);
    setGradeSelected("");
  };
  const handleSchoolSearch = async (schoolUid) => {
    setRowData(null);
    setDivisonData(null);
    setDivisionId(null);
    setGradeSelected("");
    const response = await fetch(
      `${FETCH_URL}/api/school/getSingleSchool/${schoolUid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My School response from searching ==>", res);
    } else {
      //.log("Error while searching single school", res.err);
    }
  };

  //////////////////////////////////////////////////////////////////////
  const [xlfile, setXlfile] = React.useState();
  const [file, setFile] = React.useState("");

  // const handleEnter = (event) => {
  //   if (event.key.toLowerCase() === "enter") {
  //     const form = event.target.form;
  //     const index = [...form].indexOf(event.target);
  //     form.elements[index + 1].focus();
  //     event.preventDefault();
  //   }
  // };

  const getAllDivisons = async (gradeId) => {
    setDivisionId(null);
    console.log(gradeId, "yo");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setDivisonData(res.msg);
        console.log("My Grade Divison response ==>", res);
      } else {
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      console.log("Error while getting divison =======>", error);
    }
  };

  function FileUploader(e) {
    setFileError("");
    console.log(e.target.files);
    setXlfile(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }

  ///////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////
  const submitClick = async (e) => {
    if (!schooluid && !location) {
      setSnackerropen(true);
      setSnackErrMsg("Select School");
    } else if (!gradeId) {
      setSnackerropen(true);
      setSnackErrMsg("Select Grade");
    } else if (!divisionId || Object.keys(divisionId).length == 0) {
      setSnackerropen(true);
      setSnackErrMsg("Select Division");
    } else if (!file) {
      setSnackerropen(true);
      setSnackErrMsg("Choose File");
      return;
    } else {
      const formdata = new FormData();
      formdata.set("schooluid", schooluid);
      formdata.set("schoolName", schoolName);
      formdata.set("location", location);
      formdata.set("gradename", gradename);
      formdata.set("schoolId", schoolId);
      formdata.set("gradeId", gradeId);
      formdata.set("divisionId", divisionId._id);
      formdata.set("divisionName", divisionId.division);
      formdata.set("departmentId", departmentId);
      formdata.set("departmentName", departmentName);

      // formdata.set("xlfile", xlfile);
      formdata.append("xlfile", file);
      e.preventDefault();
      try {
        const responce = await fetch(
          `${FETCH_URL}/api/learner/createLearnerFromFile`,
          {
            method: "POST",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: formdata,
          }
        );
        let res = await responce.json();
        console.log(res, "res>>>>>>>>>>>>.");
        // let ID = res.msg2._id;

        // if (ID) {
        //   imageUpload(ID);
        // }
        if (responce.ok) {
          console.log("this is my response", res);
          getAllLearners();
          setSnackOpen(true);
          setSnackMsg(res.msg);
          handleClose();
        } else {
          console.log(res.err);
          setFileError(res.err);
          // setSnackerropen(true);
          // setSnackErrMsg("Select Grade");
        }
      } catch (error) {
        console.log("Error while adding Learner =======>", error);
      }
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="mt-4" onClick={handleClickOpen}>
        <BsUpload className="UploadIcon" onClick={handleClickOpen} />
      </IconButton>

      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Typography align="right" className="width100">
              <Button
                className="width180px  mt-10px GreenButton"
                style={{ textTransform: "capitalize" }}
              >
                <a href={learnerSample} download="learnerSample">
                  <BsDownload
                    className="ml-5px fs-15"
                    style={{ marginRight: "5px", color: "white" }}
                  />

                  <span className="WhiteColorTypography">Download Sample</span>
                </a>
              </Button>
            </Typography>
            <Grid item md={3} sm={3} xs={3} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  Enter School UID<span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12} sm={12} xs={12}>
                  {/* <Input
                    className="Input1"
                    onChange={(e) => {
                      console.log("event target =======>", e.target.value);
                      setSchoolName(e.target.value);
                      handleSchoolSearch(e.target.value);
                    }}
                  ></Input> */}
                  <FormControl
                    variant="standard"
                    className="FormControlInput colorwhite"
                  >
                    <Select
                      className=" SkillsSelectStyle "
                      value={rowData?.schoolUid}
                      onChange={(e) => {
                        handleSchoolSearch(e.target.value);
                        // getSchoolDetails(e.target.value);
                      }}
                      color="white"
                      label="Locomotor"
                      disableUnderline
                    >
                      {schoolData && schoolData.length > 0 ? (
                        schoolData.map((school, i) => (
                          <MenuItem
                            key={i}
                            className="Selectmenustyle"
                            value={school.schoolUid}
                          >
                            {school.schoolUid}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="Selectmenustyle"
                          value={0}
                          disabled
                        >
                          No Schools Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "20px" }}>
              <FormControl
                variant="standard"
                className="MainPageFormControl InputTitleTypography"
              >
                <Select
                  className=" Selectdropstyle"
                  value={gradeSelected ? gradeSelected : 0}
                  // onChange={locChange}
                  label="Select Grade"
                  color="white"
                  disableUnderline
                  defaultValue={0}
                  onChange={(e) => {
                    // console.log("event target =======>", e.target.value);
                    // setGrade(e.target.value);
                    setGradeSelected(e.target.value);
                    getAllDivisons(e.target.value._id);
                  }}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Select a Grade
                  </MenuItem>
                  {rowData?.grade.map((gData) => (
                    <MenuItem value={gData} className="Selectmenustyle">
                      {gData.grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "20px" }}>
              <FormControl
                variant="standard"
                className="MainPageFormControl InputTitleTypography"
              >
                <Select
                  className=" Selectdropstyle"
                  value={divisionId ? divisionId : 0}
                  label="Select Divison"
                  color="white"
                  disableUnderline
                  defaultValue={0}
                  onChange={(e) => {
                    console.log("event target =======>", e.target.value);
                    setDivisionId(e.target.value);
                  }}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Select a Division
                  </MenuItem>
                  {divisonData?.map((divData) => (
                    <MenuItem
                      // value={divData._id}
                      value={divData}
                      className="Selectmenustyle"
                    >
                      {divData.division}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <div
                style={{
                  width: "60%",
                  marginTop: "10px",
                }}
              >
                <Typography className=" BlueColorTypography ">
                  Add File{" "}
                </Typography>
                <Grid item className="ChooseFileButtonItem"></Grid>
              </div>
              <label htmlFor="raised-button-file">
                <input
                  // style={{border:"1"}}
                  type="file"
                  accept=".csv,application/vnd.ms-excel"
                  id="raised-button-file"
                  // accept="file/*"
                  onChange={FileUploader}
                />
              </label>{" "}
            </Grid>
          </Grid>

          <Typography className="fileError">{fileError}</Typography>
        </DialogContent>{" "}
        <DialogActions className="">
          <Button
            type="submit"
            className="GreenButton "
            style={{ padding: "3px 20px" }}
            onClick={submitClick}
          >
            Upload
          </Button>
          <Button className="GreyButton GradeCancelB" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { Pagination } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";
import axios from "axios";
// import DeleteDialog from "../SchoolActionDialog.js/DeleteDialog";
// // material-ui icons
import { BsDownload } from "react-icons/bs";
import { BiSort } from "react-icons/bi";
// import UploadDocuments from "./UploadDocuments";

// core components

export default function DocumentDetail({ data, schoolDocs, setSchoolDocs }) {
  return (
    <>
      <div className="ResponsiveTableAL">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="HeadAL ">
              <TableCell className="ColHeadALBLue ">
                Document Name
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>
              <TableCell className="ColHeadALBLue">
                Uploaded on
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>

              <TableCell className="ColHeadALBLue"> Action</TableCell>
            </TableRow>
          </TableHead>

          {schoolDocs?.map((datas, i) => {
            let dataNew = datas?.doc.split("-");
            // dataNew.splice(0, 1);
            dataNew.shift(0, 1);

            dataNew = dataNew.join("-");
            return (
              <TableBody>
                <TableRow>
                  <TableCell className="Col700">
                    {/* {datas?.doc?.split("-")[1]} */}
                    {dataNew}
                  </TableCell>
                  <TableCell className="Col700">
                    {datas?.uploadOn?.split("T")[0]}
                  </TableCell>
                  {/* <TableCell className="Col700"> 2 MB </TableCell> */}
                  <TableCell className="Action">
                    <a href={datas?.doc}>
                      <IconButton className="ActionButtonSize">
                        <BsDownload color="#cddc39" />
                      </IconButton>
                    </a>
                    {/* <IconButton className="ActionButtonSize">
        <DeleteDialog />
      </IconButton> */}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
        {schoolDocs?.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Document Found !
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Box,
  Tab,
  Tabs,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
//core Component
import { FETCH_URL } from "fetchIp";
import OngoingTab from "./OngoingTab";
import CompleteTab from "./LPCompleteTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LgLessonPlan({ learner }) {
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  // const completedLesssonPlan = learner?.assignedLessonPlans?.filter(
  //   (item) => item.completion === true
  // );

  // const OngoingLessonPlan = learner?.assignedLessonPlans?.filter(
  //   (item) => item.completion === false
  // );
  let currentDate = moment().format();

  const OngoingLessonPlan = learner?.assignedLessonPlans?.filter(
    (item) => currentDate <= moment(item?.expireOn).format()
  );
  const completedLesssonPlan = learner?.assignedLessonPlans?.filter(
    (item) => currentDate > moment(item?.expireOn).format()
  );

  const newArray = completedLesssonPlan?.map((obj) => ({
    lessonPlanId: obj?.lessonPlanId,
    newStartDate: obj?.assignedOn,
    newEndDate: obj?.expireOn,
  }));
  const newArrayonGoing = OngoingLessonPlan?.map((obj) => ({
    lessonPlanId: obj?.lessonPlanId,
    newStartDate: obj?.assignedOn,
    newEndDate: obj?.expireOn,
  }));

  const [lessonplan, setLessonPlan] = useState([]);
  const [lessonPlanOnGoing, setLessonPlanOnGoing] = useState([]);
  // const completedCourse = course?.filter((item) => item.completion === true);

  const GetAssignedLessonPlan = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            lessonPlanIds: newArray,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlan(res.msg);
        // //.log(" Get AssignedLessonPlan Responce   ", res.msg);
      } else {
        //.log("Check AssignedCourse Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  const GetAssignedOnGoingLessonPlan = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            lessonPlanIds: newArrayonGoing,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlanOnGoing(res.msg);
        // //.log("Get AssignedOnGoingLessonPlan Responce   ", res.msg);
      } else {
        //.log("Check GetAssignedCourseOngoing Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  useEffect(() => {
    GetAssignedLessonPlan();
    GetAssignedOnGoingLessonPlan();
  }, []);

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Grid item conatiner>
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="GProcessTabStyle"
          >
            <Tab
              className="GProceesTabChanges"
              label="Ongoing"
              {...a11yProps(0)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Expired"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <OngoingTab lessonPlanOnGoing={lessonPlanOnGoing} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CompleteTab lessonplan={lessonplan} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

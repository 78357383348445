import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LgSkill({ learner }) {
  const [locomotorAnalyticsData, setLocomotorAnalyticsData] = useState([]);
  const [bodyManagement, setBodyManagement] = useState([]);
  const [objectControl, setobjectControl] = useState([]);
  const [dates, setAnalyticsDate] = useState([]);
  const [datesForYear, setAnalyticsDateForYear] = useState([]);
  const [dateType, setDateType] = useState("monthly");
  const [datasets, setDataSets] = useState([]);
  const getAllAnalytics = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getProgressFMS/${learner?._id}?yearly=${
          dateType == "yearly" ? true : null
        }&monthly=${dateType == "monthly" ? true : null}&weekly=${
          dateType == "last7days" ? true : null
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLocomotorAnalyticsData(
          res?.msg?.filter((a) => a?._id?.skillName == "Locomotor")
        );
        setBodyManagement(
          res?.msg?.filter((a) => a?._id?.skillName == "Body Management")
        );
        setobjectControl(
          res?.msg?.filter((a) => a?._id?.skillName == "Object Control")
        );
        console.log("response after analytics request", res);
      } else {
        console.log("failed response after analytics request", res);
      }
    } catch (error) {
      console.log(error, "i am error");
    }
  };

  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  }
  useEffect(() => {
    if (dateType === "monthly") {
      let d = new Date(); // today!
      let previousDay = d.setDate(d.getDate() - 1);
      let x = 30; // go back 30 days!
      d.setDate(d.getDate() - x);
      setAnalyticsDate(dateRange(d, previousDay));
    }
    if (dateType === "last7days") {
      let d = new Date(); // today!
      let previousDay = d.setDate(d.getDate() - 1);
      let x = 6; // go back 7 days!
      d.setDate(d.getDate() - x);
      setAnalyticsDate(dateRange(d, previousDay));
    }
    if (dateType === "yearly") {
      let givenDateTime = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );
      let createdDate = new Date(givenDateTime);
      createdDate.setDate(1);
      let currentDate = new Date();
      let dateAndYearList = [
        `${createdDate.getMonth() + 1}_${createdDate.toLocaleString("en", {
          month: "long",
        })}`,
      ];

      while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
        if (
          createdDate.getMonth() !== currentDate.getMonth() ||
          createdDate.getFullYear() !== currentDate.getFullYear()
        ) {
          dateAndYearList.unshift(
            `${createdDate.getMonth() + 1}_${createdDate.toLocaleString("en", {
              month: "long",
            })}`
          );
        }
      }

      setAnalyticsDateForYear(
        dateAndYearList?.reverse()?.map((a) => a?.split("_")?.[1])
      );
      setAnalyticsDate(
        dateAndYearList?.reverse()?.map((a) => Number(a?.split("_")?.[0]))
      );
    }
  }, [dateType]);
  let locomotorData = [];
  let bodyMoniterData = [];
  let objectControlData = [];
  useEffect(() => {
    for (let i = 0; i < dates.length; i++) {
      let index = locomotorAnalyticsData?.findIndex(
        (item) => item?._id?.time === dates[i]
      );
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          total: locomotorAnalyticsData[index].total,
        };
        locomotorData.push(obj);
      } else {
        let obj = { _id: dates[i], total: 0 };
        locomotorData.push(obj);
      }
    }

    for (let i = 0; i < dates.length; i++) {
      let index = bodyManagement?.findIndex(
        (item) => item?._id?.time === dates[i]
      );
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          total: bodyManagement[index].total,
        };
        bodyMoniterData.push(obj);
      } else {
        let obj = { _id: dates[i], total: 0 };
        bodyMoniterData.push(obj);
      }
    }

    for (let i = 0; i < dates.length; i++) {
      let index = objectControl?.findIndex(
        (item) => item?._id?.time === dates[i]
      );
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          total: objectControl[index].total,
        };
        objectControlData.push(obj);
      } else {
        let obj = { _id: dates[i], total: 0 };
        objectControlData.push(obj);
      }
    }
  }, [locomotorAnalyticsData, bodyManagement, objectControl]);

  useEffect(() => {
    getAllAnalytics();
  }, [dateType]);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          stacked: false,
        },
      ],
    },
  };
  const data = {
    labels: dates?.length == 12 ? datesForYear : dates,
    datasets: datasets,
  };
  useEffect(() => {
    setDataSets([
      {
        label: "Locomotor",
        data: locomotorData?.map((a) => a?.total),
        backgroundColor: "rgb(71, 209, 71)",

        borderRadius: 20,
        barThickness: 24,
      },
      {
        label: "Body Management",
        data: bodyMoniterData?.map((a) => a?.total),
        backgroundColor: "rgb(0, 172, 230)",

        borderRadius: 20,
        barThickness: 24,
      },
      {
        label: "Object Control",
        data: objectControlData?.map((a) => a?.total),
        backgroundColor: "rgb(255, 153, 0)",

        borderRadius: 20,
        barThickness: 24,
      },
    ]);
  }, [locomotorAnalyticsData, bodyManagement, objectControl]);

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94 colorwhite">
        <Grid
          item
          container
          className="LGSkillsTab"
          md={2}
          sm={2}
          xs={2}
          lg={2}
        >
          <FormControl className="MainPageFormControl">
            <Select
              className=" SkillSelectdropstyle"
              value={school ? school : "monthly"}
              onChange={(e) => {
                handleChange(e);
                setDateType(e.target.value);
              }}
              label="Monthly"
              color="white"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={"monthly"}>
                Monthly
              </MenuItem>

              <MenuItem className="Selectmenustyle" value={"yearly"}>
                Yearly
              </MenuItem>
              <MenuItem className="Selectmenustyle" value={"last7days"}>
                Last 7 Days
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          container
          md={10}
          sm={10}
          xs={10}
          lg={10}
          style={{ marginLeft: "100px" }}
        >
          <Bar options={options} data={data} />
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  Grid,
  MenuItem,
  IconButton,
  Toolbar,
  Box,
  Typography,
  Input,
  InputAdornment,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Select,
  FormControl,
} from "@material-ui/core";
// // material-ui icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";

// core components
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function ExtendedTables() {
  const { userToken, schoolDetail, loadingHandler } = useLogin();

  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const [gradeData, setGradeData] = useState(null);

  // API Calling
  const getAllGrades = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?pagination=true&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      loadingHandler(false);
      if (response.ok) {
        console.log("My  All Grade Get response ==>", res);
        setGradeData(res.msg);
        setTotal(res.lengthData);
        // history.push("/admin/dashboard");
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  ///////////////////////////GradeSearch//////////////////////////////////////
  const GradeSearch = async (searchValue, pageNum, rowSize) => {
    loadingHandler(true);
    const response = await fetch(
      `${FETCH_URL}/api/school/grade/getFilteredGrades?schoolId=${
        schoolDetail?.schoolId
      }&pagination=true&page=${pageNum ? pageNum : 1}&limit=${
        rowSize ? rowSize : 10
      }&search=${searchValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    loadingHandler(false);
    if (response.ok) {
      console.log(res, "i am grade response");
      setGradeData(res.msg);
      setTotal(res.lengthData);
    } else {
      setGradeData([]);
    }
  };
  ///////////////////////////GradeSearch//////////////////////////////////////

  useEffect(() => {
    getAllGrades();
  }, []);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">
          {`${schoolDetail?.schoolName} ${schoolDetail?.location} `}
        </Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    GradeSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>{" "}
        </div>
      </Toolbar>{" "}
      <Typography className="GreyColorTypography  Title2Typo">
        {" "}
        Active Grades
      </Typography>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">Grade</TableCell>
              <TableCell className="ColHead">School Name </TableCell>
              <TableCell className="ColHead"> Location</TableCell>
              <TableCell className="ColHead"> Learners</TableCell>
              <TableCell className="ColHead"> Trainers</TableCell>
              <TableCell className="ColHead"> Courses</TableCell>
              <TableCell className="ColHead"> Lesson Plans</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {gradeData &&
              gradeData.length > 0 &&
              gradeData.map((data, index) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link
                      to={{
                        pathname: "/principal/gradeprofile",
                        state: { data },
                      }}
                    >
                      {" "}
                      {data.grade}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">{data.schoolName}</TableCell>
                  <TableCell className="Col700WAB"> {data.location}</TableCell>
                  <TableCell className="Col700WAB">
                    {data.assignedLearners && data.assignedLearners.length > 0
                      ? data.assignedLearners.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Col700WAB">
                    {" "}
                    {data.assignedTrainers && data.assignedTrainers.length > 0
                      ? data.assignedTrainers.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Col700WAB">
                    {data.assignedCourses && data.assignedCourses.length > 0
                      ? data.assignedCourses.length
                      : "--"}
                  </TableCell>

                  <TableCell className="Col700WAB ">
                    {" "}
                    {data.assignedLessonPlans &&
                    data.assignedLessonPlans.length > 0
                      ? data.assignedLessonPlans.length
                      : "--"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {gradeData?.length > 0 && (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, pageSize) => {
              GradeSearch("", value, pageSize);
              setPage(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import * as React from "react";
import {
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  Input,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

export default function ExtendTab({
  Tabnext2,
  learner,
  states: {
    setLearnerLocation,
    setLearnerAddress,
    setLearnerZipCode,
    setLearnerCity,

    guardianLocation,
    setLearnerState,
    setlearnerCountry,
    learnerCountry,
    setGuardianFullName,
    guardianFullName,
    setGuardianLocation,
    setGuardianEmail,
    setGuardingPhoneNo,
    guardianEmail,
    guardingPhoneNo,
  },
}) {
  const [error, setError] = React.useState();

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  return (
    <Grid container spacing={4} className="DailogLTRBStyle">
      <Grid item md={6} sm={6} xs={6}>
        <Grid item container direction="column" style={{ marginLeft: "3%" }}>
          <Typography className="InputTitleTypography GreyColorTypography">
            Email ID <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={learner.contactDetail.learnerEmail}
              disabled
              // onChange={(e) => setLearnerEmail(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Mobile Number <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={learner.contactDetail.learnerPhoneNo}
              // onChange={(e) => setLearnerPhoneNo(e.target.value)}
              disabled
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Location <span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={learner.contactDetail.learnerLocation}
              onChange={(e) => setLearnerLocation(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Address <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <TextareaAutosize
              className="ContactTextArea"
              aria-label="minimum height"
              defaultValue={learner.contactDetail.learnerAddress}
              onChange={(e) => setLearnerAddress(e.target.value)}
            />
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            ZipCode
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={learner.contactDetail.learnerZipCode}
              onChange={(e) => setLearnerZipCode(e.target.value)}
              disabled
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            City <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={learner.contactDetail.learnerCity}
              onChange={(e) => setLearnerCity(e.target.value)}
              disabled
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            State <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            {/* <FormControl
              variant="standard"
              className="FormControlInput"
              style={{ backgroundColor: "white" }}
            >
              <Select
                className=" SkillsSelectStyle "
                value={learnerState ? learnerState : 0}
                onChange={setLearnerState}
                color="white"
                label="Locomotor"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  Select State
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Delhi"}>
                  Delhi
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Noida"}>
                  Noida
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"Mumbai"}>
                  Mumbai
                </MenuItem>
              </Select>
            </FormControl>{" "} */}

            <Input
              className="Input1"
              defaultValue={learner.contactDetail.learnerState}
              onChange={(e) => setLearnerState(e.target.value)}
              disabled
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Country <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              defaultValue={
                learnerCountry
                  ? learnerCountry
                  : learner.contactDetail.learnerCountry
              }
              onChange={(e) => setlearnerCountry(e.target.value)}
            >
              {" "}
            </Input>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={6} xs={6} direction="column">
        <Grid item container direction="column">
          <Typography className="InputTitleTypography GreyColorTypography">
            Full Name <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              value={
                guardianFullName
                  ? guardianFullName
                  : learner.contactDetail.guardianFullName
              }
              onChange={(e) => setGuardianFullName(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Location <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              value={
                guardianLocation
                  ? guardianLocation
                  : learner.contactDetail.guardianLocation
              }
              // defaultValue={learner.contactDetail.guardianLocation}
              onChange={(e) => setGuardianLocation(e.target.value)}
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Email ID <span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              // defaultValue={learner.contactDetail.guardianEmail}
              value={
                guardianEmail
                  ? guardianEmail
                  : learner.contactDetail.guardianEmail
              }
              onChange={(e) => {
                if (e.target.value == "") {
                  setError("");
                } else if (!isEmail(e.target.value)) {
                  setError("invalid email");
                } else {
                  setError("");
                }
                setGuardianEmail(e.target.value);
              }}
              // onChange={(e) => setGuardianEmail(e.target.value)}
            >
              {" "}
            </Input>
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {error}
            </span>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Mobile Number <span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item container md={10} sm={10} xs={10}>
            <Input
              className="Input1"
              type="number"
              value={
                guardingPhoneNo
                  ? guardingPhoneNo
                  : learner.contactDetail.guardianPhoneNo
              }
              // defaultValue={learner.contactDetail.guardianPhoneNo}
              onChange={(e) => setGuardingPhoneNo(e.target.value.slice(0, 10))}
            >
              {" "}
            </Input>
          </Grid>
          <DialogActions className="">
            <Button
              className="GreenButton700 NextActionBottom"
              autoFocus
              onClick={Tabnext2}
            >
              Next
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Grid>
  );
}

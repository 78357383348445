import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { FETCH_URL } from "../../../fetchIp";

//React-Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlinePlusCircle } from "react-icons/ai";

import { useLogin } from "Context/ExportContext";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Lesson Plan
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ getFolders }) {
  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(90),
    },
  }));

  const { userToken } = useLogin();

  const classes = useStyles();
  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [scroll, setScroll] = React.useState("paper");

  // folder states:
  const [folderName, setFolderName] = React.useState("");

  const createFolder = async () => {
    if (!folderName) {
      return alert("Please enter the name of the Folder");
    }
    try {
      const response = await fetch(`${FETCH_URL}/api/lessonplan/createFolder`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          folderName,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after creating folder", res);
        handleClose();
        getFolders();
      } else {
        console.log("On resp err on creating folder", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <IconButton className="">
        <AiOutlinePlusCircle
          onClick={handleClickOpen}
          style={{
            fontSize: "4rem",
            color: "#cddc39",
            marginLeft: "16px",
            marginTop: "15px",
          }}
        />{" "}
        {/* <IoIosAddCircleOutline className="AddIcon" /> */}
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="sm"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>

        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid item md={12} sm={12} xs={12} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  Name of Lesson Plan <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12} sm={12} xs={12}>
                  <Input
                    className="Input1"
                    onChange={(e) => {
                      setFolderName(e.target.value);
                    }}
                  ></Input>
                </Grid>
              </Grid>{" "}
              <DialogActions className="SGradeBottom">
                {/* <Link to="/auth/folder"> */}
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={createFolder}
                >
                  Create
                </Button>
                {/* </Link> */}
              </DialogActions>
            </Grid>
          </Grid>{" "}
        </DialogContent>
      </Dialog>
    </div>
  );
}

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  boxShadow,
  defaultFont,
  infoColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const sidebarStyle = (theme) => ({
  drawerPaperRTL: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important",
    },
  },
  drawerPaper: {
    position: "fixed",
    bottom: "0px",
    left: "0",
    zIndex: "1032",
    top: "65px",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
      overflow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      // top: "0",
      // right: "0",
      // left: "auto",
      zIndex: "1032",
      // visibility: "visible",
      overflow: "none",
      // overflowY: "visible",
      borderTop: "none",

      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      ...transition,
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0",
    },
  },
  blackBackground: {
    // color: whiteColor,
    "&:after": {
      background: blackColor,
      opacity: ".8",
    },
  },
  blueBackground: {
    color: whiteColor,
    "&:after": {
      background: infoColor[0],
      opacity: ".93",
    },
  },
  whiteBackground: {
    color: grayColor[2],
    "&:after": {
      background: whiteColor,
      opacity: ".93",
    },
  },
  whiteAfter: {
    "&:after": {
      backgroundColor: "hsla(0,0%,71%,.3) !important",
    },
  },
  drawerPaperMini: {
    [theme.breakpoints.up("md")]: {
      width: drawerMiniWidth + "px!important",
    },
  },

  list: {
    // border: "2px solid red",
    marginTop: "1px",
    paddingLeft: "0",
    paddingBottom: "0px",
    listStyle: "none",
    "&:before,&:after": {
      display: "table",

      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
  },
  item: {
    // border: "1px solid black",
    position: "relative",
    display: "block",
    height: "40%",
    textDecoration: "none",
    margin: "8px",
    padding: "0",
  },
  userItem: {
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
  itemLink: {
    // border: "2px solid grey",
    transition: "all 300ms linear",
    marginLeft: "13px",
    marginRight: "20px",
    margin: "1px 0px 0",
    borderRadius: "3px",
    height: "39px",
    position: "relative",
    display: "block",
    padding: "5px ",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      // outline: "none",
      // backgroundColor: "#90C251",
      // color: "#ffffff",
      // boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      // color: "#90C251",
    },
  },
  itemIcon: {
    // border: "2px solid grey",
    color: "#474747",
    width: "29px",
    height: "25px",
    marginLeft: "5px",

    float: "left",
    position: "inherit",
    // top: "3px",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8",
  },

  itemText: {
    color: "#474747",
    ...defaultFont,
    margin: "4px",
    // lineHeight: "30px",
    fontSize: "14px",
    fontFamily: ["Play", "sans-serif"].join(","),
    fontWeight: 400,
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    position: "relative",
    display: "block",
    height: "auto",
    whiteSpace: "nowrap",
    padding: "0 16px !important",
  },

  caret: {
    // border: "2px solid grey",

    marginTop: "10px",
    position: "absolute",
    right: "1px",
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "2px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    color: "#808080",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  userCaret: {
    marginTop: "10px",
  },
  caretRTL: {
    left: "11px",
    right: "auto",
  },
  caretActive: {
    transform: "rotate(180deg)",
  },

  sidebarWrapper: {
    position: "relative",
    overflow: "auto",
    width: "239x",
    zIndex: "4",
    transitionTimingFunction: "linear, linear, ease",
    color: "white",
    paddingBottom: "72px",
  },
  user: {
    margin: "6px",
  },
});

export default sidebarStyle;

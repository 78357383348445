import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  DialogActions,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
export default function Benefits(props) {
  const {
    states: {
      TabPre3,
      TabNext3,
      editorbenefitsState,
      setEditorBenefitdState,
      benefitesValid,
    },
  } = props;

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container className="p-20">
          <Typography    className={
              benefitesValid
                ? "RedColorTypography width100  fs-18"
                : " DarkBlackColorTypo fs-18 width100"
            }>
            Benefits
          </Typography>
          {/* <div className="mt-16">
            <Editor
              editorState={editorbenefitsState}
              onEditorStateChange={setEditorBenefitdState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
            />
          </div> */}
          <Grid container>
            <TextareaAutosize
              className={
                benefitesValid
                  ? "textareaauto  width100 border-red  mt-16"
                  : " textareaauto width100   border-grey  mt-16"
              }
              aria-label="maximum height"
              value={editorbenefitsState}
              onChange={(e) => setEditorBenefitdState(e.target.value)}
            />
          </Grid>
          <DialogActions className="mt-24  width100">
            <Button onClick={TabPre3} className=" GreenButton-border next-btn">
              Previous
            </Button>
            <Button onClick={TabNext3} className="GreenButton700 next-btn">
              Next
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

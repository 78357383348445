import React, { useState } from "react";

import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Grid,
} from "@material-ui/core";
// ReactIcons

import SkillProfile from "../HosSkillsProfile/SkillProfileMain";
export default function LocomotorTab(props) {
  const { skillsData } = props;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Skill Name</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead">Assigned On</TableCell>
            </TableRow>
          </TableHead>
          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {skillsData &&
              skillsData.length > 0 &&
              skillsData.map((skills, index) => (
                <TableRow key={index}>
                  <TableCell className="Col700WAB">
                    <Link> {skills.subSkillUid}</Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Link>
                      {" "}
                      {/* {skills.subSkillName} */}
                      <SkillProfile skills={skills} />
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {" "}
                      {skills.subSkillDesc}
                    </Typography>
                  </TableCell>{" "}
                  <TableCell className="Col700WAB">22/02/22</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {skillsData?.length > 0 ? (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, rowsPerPage) => {
              setPage(value);
              props.handleSearch("", value, rowsPerPage);
            }}
            pageSize={rowsPerPage}
            total={props.total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      ) : (
        <Grid
          container
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography>
            <Typography className="width100 DarkBlackColorTypo">
              No Skills Found!
            </Typography>
          </Typography>
        </Grid>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Grid,
  Typography,
} from "@material-ui/core";
//core component

/////////

/////////
export default function LearnerTab(props) {
  const { lessonPlans } = props;
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Course Name </TableCell>
              <TableCell className="ColHead"> Description</TableCell>
              <TableCell className="ColHead"> Assigned On</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {lessonPlans &&
              lessonPlans.length > 0 &&
              lessonPlans.map((lp, i) => (
                <TableRow key={lp?.LPUid}>
                  <TableCell className="Col700WAB">
                    <Link>{lp?.LPUid}</Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Link
                      to={{
                        pathname: "/principal/lessonplanprofile",
                        state: { lp },
                      }}
                    >
                      {lp?.LPTitle}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">{lp?.LPDesc}</TableCell>
                  <TableCell className="Col700WAB">
                    {" "}
                    {lp?.LPStartDate?.split("T")?.[0]}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {lessonPlans.length == 0 ? (
        <Grid
          container
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography>
            <Typography className="width100 DarkBlackColorTypo">
              No Data Found!
            </Typography>
          </Typography>
        </Grid>
      ) : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, rowSize) => {
              props.handleSearch("undefined", value, rowSize);
              setPage(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

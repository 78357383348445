import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoaderDialog from "components/Loader/LoaderDialog";
import {
  Box,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  Typography,
  Toolbar,
  Grid,
  Input,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
// material-ui icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { GoSearch } from "react-icons/go";
// core components
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";

import AssigneTab from "./LessonPlanRadio's/AssignedTab";
import PublishedTable from "./LessonPlanRadio's/PublishedTable";

export default function ExtendedTables(props) {
  // const [value, setValue] = React.useState(0);
  const { userToken } = useLogin();
  const [
    assignedLearnerLessonPlan,
    setAssignedLearnerLessonLearnerPlan,
  ] = useState("");
  const [
    assignedLearnerTrainerPlan,
    setAssignedLearnerLessonTrainerPlan,
  ] = useState("");
  const [trainerlessonPlans, setTrainerLessonPlans] = useState([]);
  const [learnerLessonPlans, setLearnerLessonPlans] = useState([]);

  console.log(trainerlessonPlans, "plese delete me after that");
  const [value, setValue] = React.useState(0);
  /////////////////////////////////////////////////////////////////
  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select

  // const TabChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const [foldername, setFolderName] = useState(null);

  /////Pagination State For LearnerLessonplan
  const [pageNoL, setPageNoL] = useState(1);
  const [totalL, setTotalL] = useState(10);
  const [rowsPerPageL, setRowsPerPageL] = useState(10);
  const [searchDataL, setSearchDataL] = useState("");

  /////Pagination State For LearnerLessonplan
  const [pageNoT, setPageNoT] = useState(1);
  const [totalT, setTotalT] = useState(10);
  const [rowsPerPageT, setRowsPerPageT] = useState(10);
  const [searchDataT, setSearchDataT] = useState("");
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState();
  const [pageNo, setPageNo] = useState(1);

  const GetFolder = async (pageNum, rowSize) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getPaginatedFolders?isPublished=true&pagination=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setFolderName(res.msg);
        setTotal(res.lengthData);
        setLoading(false);

        console.log("My Folder response from searching ==>", res);
      } else {
        console.log("Error while searching single school", res.err);
      }
    } catch (error) {
      console.log("This is catch", error);
    }
  };
  useEffect(() => {
    GetFolder();
  }, []);
  const [radiovalue, setRadioValue] = React.useState("Published");

  const RadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  ///////////////////////////////////////////////rwerwe////////////////////

  console.log(" Check Data of Trainers", trainerlessonPlans);
  const getTrainerLessonPlan = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);
        setTrainerLessonPlans(res.msg);
        setTotalT(res.lengthData);
        // console.log("Lesson Plans Skills =======>", res.msg[0]);
      } else {
        setLoading(false);

        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getLearnerLessonPlan = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);

        console.log("res.msg", res.msg);

        setLearnerLessonPlans(res.msg);
        setTotalL(res.lengthData);
        console.log("setLearnerLessonPlans =======>", res.msg);

        console.log("Lesson Plans Skills =======>", res.msg[0]);
      } else {
        setLoading(false);

        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (radiovalue === "Published") {
      // if (searchValue.length === 0) {
      //   GetFolder();
      // }
      setLoading(true);
      let url;

      if (searchValue) {
        setPageNo(1);
        url = `${FETCH_URL}/api/lessonplan/getPaginatedFolders?isPublished=true&search=${searchValue}`;
      } else {
        url = `${FETCH_URL}/api/lessonplan/getPaginatedFolders?isPublished=true&pagination=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setFolderName(res.msg);
        setLoading(false);
        console.log("My response from searching ==>", res);
      } else {
        setLoading(false);
        console.log("Error while searching  ", res.err);
      }
    } else {
      if (value === 0) {
        setAssignedLearnerLessonLearnerPlan("");
        setPageNoL(1);
        setRowsPerPageL(10);
        setSearchDataL(searchValue);
        if (searchValue.length === 0) {
          getLearnerLessonPlan();
        } else {
          const response = await fetch(
            `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&page=${
              pageNum ? pageNum : 1
            }&search=${searchValue}&limit=${rowSize ? rowSize : 10}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            setLearnerLessonPlans(res.msg);
            console.log("My response from searching ==>", res);
          } else {
            console.log("Error while searching  ", res.err);
          }
        }
      } else {
        setAssignedLearnerLessonTrainerPlan("");
        setSearchDataT(searchValue);
        setPageNoT(1);
        setRowsPerPageT(10);
        if (searchValue.length === 0) {
          getTrainerLessonPlan();
        } else {
          const response = await fetch(
            `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&search=${searchValue}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            setTrainerLessonPlans(res.msg);
            console.log("My response from searching ==>", res);
          } else {
            console.log("Error while searching  ", res.err);
          }
        }
      }
    }
  };

  const assignedLearnerLessonPlans = async (assignedValue) => {
    setLoading(true);
    if (assignedValue === 0) {
      console.log("i am running");
      try {
        const response = await fetch(
          `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&pagination=true&page=1&limit=10`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setLoading(false);
          setLearnerLessonPlans(res.msg);
          setTotalL(res.lengthData);
          console.log("Lesson Plans Skills =======>", res.msg[0]);
        } else {
          setLoading(false);

          console.error("API error =====>", res.err);
        }
      } catch (error) {
        console.log("Catch block ====>", error);
      }
    }
    if (assignedValue === 1) {
      console.log("i am running");
      try {
        const response = await fetch(
          `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&assigned=true&pagination=true&page=1&limit=10`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          // const filterData = res.msg.filter((grade) => {
          //   if (
          //     // grade.assignedGrades.length ||
          //     grade.assignedGrades.length > 0 ||
          //     //  grade.assignedLearners ||
          //     grade.assignedDivisions.length > 0 ||
          //     grade.assignedLearners.length > 0
          //   ) {
          //     return grade;
          //   }
          // });
          setLearnerLessonPlans(res.msg);
          setLoading(false);

          setTotalL(res.lengthData);
          console.log("Lesson Plans Skills =======>", res.msg[0]);
        } else {
          setLoading(false);

          console.error("API error =====>", res.err);
        }
      } catch (error) {
        console.log("Catch block ====>", error);
      }
    }
    if (assignedValue === 2) {
      console.log("i am not assigned");
      try {
        const response = await fetch(
          `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&notAssigned=true&pagination=true&page=1&limit=10`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          // const filterData = res.msg.filter((grade) => {
          //   if (
          //     // grade.assignedGrades
          //     grade.assignedLearners.length === 0 &&
          //     grade.assignedGrades.length === 0 &&
          //     grade.assignedDivisions.length === 0
          //   ) {
          //     return grade;
          //   }
          // });
          setLoading(false);

          setLearnerLessonPlans(res.msg);
          setTotalL(res.lengthData);
          console.log("Lesson Plans Skills =======>", res.msg[0]);
        } else {
          setLoading(false);

          console.error("API error =====>", res.err);
        }
      } catch (error) {
        console.log("Catch block ====>", error);
      }
    }
  };

  const assignedTrainerLessonPlans = async (assignedValue) => {
    setLoading(true);

    if (assignedValue === 0) {
      console.log("i am running");
      try {
        const response = await fetch(
          `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setLoading(false);

          setTrainerLessonPlans(res.msg);
          console.log("Lesson Plans Skills =======>", res.msg[0]);
        } else {
          setLoading(false);

          console.error("API error =====>", res.err);
        }
      } catch (error) {
        console.log("Catch block ====>", error);
      }
    }
    if (assignedValue === 1) {
      console.log("i am running");
      try {
        const response = await fetch(
          `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&assigned=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setLoading(false);

          // const filterData = res.msg.filter((grade) => {
          //   if (
          //     // grade.assignedGrades.length ||
          //     grade.assignedTrainers.length > 0
          //   ) {
          //     return grade;
          //   }
          // });
          setTrainerLessonPlans(res.msg);
          console.log("Lesson Plans Skills =======>", res.msg[0]);
        } else {
          setLoading(false);

          console.error("API error =====>", res.err);
        }
      } catch (error) {
        console.log("Catch block ====>", error);
      }
    }
    if (assignedValue === 2) {
      console.log("i am not assigned");
      try {
        const response = await fetch(
          `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&notAssigned=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setLoading(false);

          // const filterData = res.msg.filter((grade) => {
          //   if (
          //     // grade.assignedGrades
          //     grade.assignedTrainers.length === 0
          //   ) {
          //     return grade;
          //   }
          // });
          setTrainerLessonPlans(res.msg);
          console.log("Lesson Plans Skills =======>", res.msg[0]);
        } else {
          setLoading(false);

          console.error("API error =====>", res.err);
        }
      } catch (error) {
        console.log("Catch block ====>", error);
      }
    }
  };

  const LeanerPaginatedData = async (pageNum, rowSize) => {
    let url;
    setLoading(true);
    if (assignedLearnerLessonPlan == 0) {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    } else if (assignedLearnerLessonPlan == 1) {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&assigned=true&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    } else if (assignedLearnerLessonPlan == 2) {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&notAssigned=true&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const res = await response.json();
    if (response.ok) {
      setLoading(false);

      setLearnerLessonPlans(res.msg);
      setTotalL(res.lengthData);
      console.log("My response from searching ==>", res);
    } else {
      setLoading(false);
      console.log("Error while searching  ", res.err);
    }
  };

  const TrainerPaginatedData = async (pageNum, rowSize) => {
    let url;
    setLoading(true);

    if (assignedLearnerLessonPlan == 0) {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    } else if (assignedLearnerLessonPlan == 1) {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&assigned=true&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    } else if (assignedLearnerLessonPlan == 2) {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&notAssigned=true&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const res = await response.json();
    if (response.ok) {
      setLoading(false);

      setTrainerLessonPlans(res.msg);
      setTotalT(res.lengthData);
      console.log("My response from searching ==>", res);
    } else {
      setLoading(false);

      console.log("Error while searching  ", res.err);
    }
  };

  useEffect(() => {
    getTrainerLessonPlan();
    getLearnerLessonPlan();
    // getDistinctSchoolAndLocation();
  }, []);

  const TabChange = (event, newValue) => {
    setValue(newValue);
    // setAssignedLearnerLessonLearnerPlan(0);
  };
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Lesson Plans</Typography>

        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  className="input"
                  disableUnderline
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    // setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>

          <IconButton className="HeaderAddButton">
            <Link to="/admin/addLessonPlan">
              <IoIosAddCircleOutline
                className="AddIcon"
                foldername={foldername}
                setFolderName={setFolderName}
              />
            </Link>
          </IconButton>
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid
        container
        className="RadioStyleSkill"
        style={{
          // paddingTop: " 10px",
          display: "flex",
          justifyContent: "space-between",
          width: "97%",
        }}
      >
        <Grid item md={10}>
          <FormControl component="fieldset">
            <RadioGroup
              defaultValue={radiovalue}
              row
              onChange={(e) => RadioChange(e)}
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Published"
                control={<Radio />}
                label="Published"
                className=" GreyColorTypography RadioStyleTypo"
              />
              <FormControlLabel
                value="Assigned "
                control={<Radio />}
                label="Assign"
                className=" GreyColorTypography RadioStyleTypo"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {radiovalue !== "Published" && value === 0 && (
            <FormControl className="width100">
              <Select
                className="Selectdropstyle hgt-33"
                value={
                  assignedLearnerLessonPlan ? assignedLearnerLessonPlan : 0
                }
                onChange={(e) => {
                  // handleChange(e);
                  setAssignedLearnerLessonLearnerPlan(e.target.value);
                  assignedLearnerLessonPlans(e.target.value);
                }}
                inputProps={{
                  name: "agent",
                  id: "age-simple",
                }}
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  All
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={1}>
                  Assigned
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={2}>
                  Not Assigned
                </MenuItem>
              </Select>
            </FormControl>
          )}

          {radiovalue !== "Published" && value === 1 && (
            <FormControl className="width100">
              <Select
                className="Selectdropstyle hgt-33"
                value={
                  assignedLearnerTrainerPlan ? assignedLearnerTrainerPlan : 0
                }
                onChange={(e) => {
                  // handleChange(e);
                  setAssignedLearnerLessonTrainerPlan(e.target.value);
                  assignedTrainerLessonPlans(e.target.value);
                  // assignedLearnerLessonPlans(e.target.value);
                }}
                inputProps={{
                  name: "agent",
                  id: "age-simple",
                }}
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  All
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={1}>
                  Assigned
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={2}>
                  Not Assigned
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      {radiovalue === "Published" ? (
        <PublishedTable
          states={{ foldername, total, setTotal, pageNo, setPageNo }}
          GetFolder={GetFolder}
        />
      ) : (
        <AssigneTab
          states={{
            foldername,
            total,
            trainerlessonPlans,
            setTrainerLessonPlans,
            learnerLessonPlans,
            setLearnerLessonPlans,
            value,
            setValue,
            pageNoL,
            setPageNoL,
            totalL,
            setTotalL,
            rowsPerPageL,
            setRowsPerPageL,
            pageNoT,
            setPageNoT,
            totalT,
            setTotalT,
            rowsPerPageT,
            setRowsPerPageT,
            searchDataL,
            setSearchDataL,
            searchDataT,
            setSearchDataT,
          }}
          GetFolder={GetFolder}
          getTrainerLessonPlan={getTrainerLessonPlan}
          getLearnerLessonPlan={getLearnerLessonPlan}
          TabChange={TabChange}
          LeanerPaginatedData={LeanerPaginatedData}
          TrainerPaginatedData={TrainerPaginatedData}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import LoaderDialog from "components/Loader/LoaderDialog";
// core components
import Craetor from "./CreatorModeTab/CreatorModeFolder";
import Publieshed from "./PublishedTab/PublisheTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables() {
  const { state } = useLocation();
  console.log("Check State Value", state);
  const [value, setValue] = React.useState(state || 0);
  const [loading, setLoading] = useState(false);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography className="GreyColorTypography mt-20px ml-10px">
        Create Lesson Plan
      </Typography>
      <Box className="mt-20px">
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
        >
          <Tab
            className="ActivityTabChanges"
            label="Creator Mode"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Published"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <LoaderDialog loading={loading} />

      <TabPanel value={value} index={0}>
        <Craetor setLoading={setLoading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Publieshed setLoading={setLoading} />
      </TabPanel>
    </>
  );
}

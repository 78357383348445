import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  MenuItem,
  Select,
  FormControl,
  makeStyles,
  RadioGroup,
  useRadioGroup,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  Radio,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

// ReactIcons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

// Import Files
import AssignEquipment from "../AddLessonPlan/AssignEquipment";
import AssignSkills from "../AddLessonPlan/AssignSkillsDialog";
import AssignActivity from "../AddLessonPlan/AssignActivites";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
const useStyles = makeStyles((theme) => ({}));
function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <FormControlLabel checked={checked} {...props} />;
}

export default function ExtendedTables() {
  const history = useHistory();
  const { userToken } = useLogin();
  const location = useLocation();
  const { GetFolder } = location.data;
  const [value, setValue] = React.useState(null);
  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const top100Films = [
    { title: "Pre-School" },
    { title: "Nursery- UKG" },
    { title: "Grade 1" },
    { title: "Grade 2" },
    { title: "Grade 3" },
    { title: "Grade 4" },
    { title: "Grade 5" },
  ];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { id } = useParams();

  //For CreateInput
  const [LPTitle, setLPTitle] = useState(null);
  const [LPUid, setLPUid] = useState(null);
  // States for dropdowns
  const [LPLevel, setLPLevel] = useState("");
  const [LPXp, setLPXp] = useState("");
  // on dropdown select

  const [LPDesc, setLPDesc] = useState(null);
  const [LPOutcomes, setLPOutcomes] = useState(null);
  //for LessonPlan Duration
  const [LPDuration, setLPDuration] = useState(null);
  //for Date
  const [LPStartDate, setLPStartDate] = useState(null);
  const [LPEndDate, setLPEndDate] = useState(null);
  const [skills, setSkills] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [activity, setActivity] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const submitClick = async (e) => {
    e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/createLessonPlan`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            LPTitle,
            LPUid,
            LPLevel,
            LPXp,
            LPDesc,
            LPOutcomes,
            LPDuration,
            LPStartDate,
            LPEndDate,
            skills,
            equipments,
            activity,
            folderId: id,
          }),
        }
      );
      let res = await responce.json();
      let LPId = res.msg2._id;
      if (LPId) {
        imageUpload(LPId);
        console.log("Response after create >> ", res.msg2._id);
      }
      if (responce.ok) {
        history.goBack("/admin/Lesson");
        console.log("Mah respo =====>", res);
      } else {
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  const imageUpload = async (LPId) => {
    const formdata = new FormData();
    formdata.set("LPId", LPId);
    formdata.append("thumbnail", image);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/uploadLessonPlanThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
        <Typography className="BlueColorTypography  TopTitle">
          New Lesson Plan
        </Typography>
      </div>

      <Grid container spacing={1}>
        <Grid item container md={9} sm={9} xs={9} lg={9}>
          <Grid item container style={{ width: "99.6%" }}>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="DailogLTRBStyle"
            >
              <Grid container item style={{ marginLeft: "20px" }}>
                <Grid container item direction="column">
                  <Typography className="LPTypo BlueColorTypography">
                    Lesson Plan Title
                  </Typography>

                  <Input
                    disableUnderline
                    className="PlanTitleLargeInput"
                    value={LPTitle}
                    onChange={(e) => setLPTitle(e.target.value)}
                    onKeyDown={handleEnter}
                  />
                </Grid>
                <Grid container>
                  <Grid container item md={3} sm={3} xs={3} lg={3}>
                    <Typography className="LPTypo BlueColorTypography">
                      Lesson Plan ID
                    </Typography>
                    <Input
                      disableUnderline
                      className="LessonPlanInputSmall"
                      value={LPUid}
                      onChange={(e) => setLPUid(e.target.value)}
                      onKeyDown={handleEnter}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    md={3}
                    sm={3}
                    xs={3}
                    lg={3}
                    style={{ marginLeft: "20px" }}
                  >
                    <Typography className="LPTypo BlueColorTypography">
                      Lesson Plan Level
                    </Typography>
                    <FormControl
                      variant="standard"
                      className="FormControlInput"
                    >
                      <Select
                        className=" SelectLPStyle"
                        value={LPLevel ? LPLevel : 0}
                        onChange={(e) => {
                          setLPLevel(e.target.value);
                        }}
                        defaultValue={0}
                        disableUnderline
                      >
                        <MenuItem
                          value={0}
                          className="Selectmenustyle"
                          disabled
                        >
                          Select a Level
                        </MenuItem>
                        <MenuItem className="Selectmenustyle" value={10}>
                          Easy
                        </MenuItem>
                        <MenuItem className="Selectmenustyle" value={20}>
                          Medium
                        </MenuItem>
                        <MenuItem className="Selectmenustyle" value={30}>
                          Hard
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    item
                    md={3}
                    sm={3}
                    xs={3}
                    lg={3}
                    style={{ marginLeft: "20px" }}
                  >
                    <Typography className="LPTypo BlueColorTypography">
                      Lesson Plan XP
                    </Typography>
                    <Input
                      disableUnderline
                      className="LessonPlanInputSmall"
                      value={LPXp}
                      onChange={(e) => setLPXp(e.target.value)}
                      onKeyDown={handleEnter}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item style={{ marginLeft: "20px", width: "45%" }}>
                <Typography className="LPTypo BlueColorTypography">
                  Lesson Plan Description
                </Typography>
                <Grid container item>
                  <TextareaAutosize
                    className="LPTextArea"
                    aria-label="minimum height"
                    value={LPDesc}
                    onChange={(e) => setLPDesc(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container item style={{ marginLeft: "15px", width: "45%" }}>
                <Typography className="LPTypo BlueColorTypography">
                  Learning Outcomes
                </Typography>
                <Grid container item>
                  <TextareaAutosize
                    className="LPTextArea"
                    aria-label="minimum height"
                    value={LPOutcomes}
                    onChange={(e) => setLPOutcomes(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{ marginLeft: "20px", marginBottom: "50px" }}
              >
                <Grid container item style={{ width: "19%" }}>
                  <Typography className="LPTypo BlueColorTypography">
                    Lesson Plan Duration
                  </Typography>
                  <Grid container item md={12} sm={12} xs={12} lg={12}>
                    <MuiPickersUtilsProvider
                      className="Calender"
                      utils={DateFnsUtils}
                    >
                      <KeyboardTimePicker
                        margin="normal"
                        className="Calender"
                        id="time-picker"
                        value={LPDuration}
                        onChange={setLPDuration}
                        // onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={4}
                  sm={4}
                  xs={4}
                  lg={4}
                  style={{ marginLeft: "28px" }}
                >
                  <Typography className="LPTypo BlueColorTypography">
                    Start Date
                  </Typography>
                  <Grid container item md={12} sm={12} xs={12} lg={12}>
                    <MuiPickersUtilsProvider
                      className="Calender"
                      utils={DateFnsUtils}
                    >
                      <Grid item md={12} sm={12} xs={12} lg={12}>
                        <KeyboardDatePicker
                          className="Calender"
                          format="MM/dd/yyyy"
                          value={LPStartDate}
                          onChange={setLPStartDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={4}
                  sm={4}
                  xs={4}
                  lg={4}
                  style={{ marginLeft: "42px" }}
                >
                  <Typography className="LPTypo BlueColorTypography">
                    End Date
                  </Typography>
                  <Grid container item md={12} sm={12} xs={12} lg={12}>
                    <MuiPickersUtilsProvider
                      className="Calender"
                      utils={DateFnsUtils}
                    >
                      <Grid item md={12} sm={12} xs={12} lg={12}>
                        <KeyboardDatePicker
                          className="Calender"
                          format="MM/dd/yyyy"
                          value={LPEndDate}
                          onChange={setLPEndDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid container item style={{ marginLeft: "20px" }}>
                <Grid container item md={6}>
                  <Typography className="LPTypo BlueColorTypography">
                    Upload Content
                  </Typography>
                </Grid>
              </Grid> */}
              {/* <Grid container item style={{ marginLeft: "20px" }}>
                <Grid container item className=" " md={5}>
                  <Button className=" GreenButton700 ArticulateButtonCF">
                    <IoIosAddCircle
                      size="1.6em"
                      style={{ marginRight: "4px" }}
                    />
                    Choose Content
                  </Button>
                </Grid>
              </Grid> */}
              {/* <Grid
                container
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                style={{ marginLeft: "22px" }}
              >
                <RadioGroup
                  className="RadioStyle"
                  name="use-radio-group"
                  defaultValue="first"
                >
                  <MyFormControlLabel
                    className=" BlueColorTypography RadioLabel"
                    value="first"
                    label={
                      <Typography>
                        Correct Response Required
                        <span style={{ color: "red" }}> * </span>
                        <span
                          className="GreyColorTypography400"
                          style={{ fontSize: "13px" }}
                        >
                          ( Correct Response Required to Move to Next Lesson )
                        </span>
                      </Typography>
                    }
                    control={<Radio />}
                  />
                </RadioGroup>
              </Grid> */}
              {/* <Grid
                container
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                style={{ marginLeft: "22px" }}
              >
                <RadioGroup
                  className="RadioStyle"
                  name="use-radio-group"
                  defaultValue="first"
                >
                  <MyFormControlLabel
                    className=" BlueColorTypography RadioLabel"
                    value="first"
                    label={
                      <Typography>
                        Tracking Enabled
                        <span style={{ color: "red" }}> * </span>
                      </Typography>
                    }
                    control={<Radio />}
                  />
                </RadioGroup>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container md={3} sm={3} xs={3} lg={3} className="DialogTB">
          <Grid
            item
            style={{
              width: "96%",
              backgroundColor: "white",
            }}
          >
            <Typography
              style={{ marginLeft: "15px", width: "100%" }}
              className="LPTypo BlueColorTypography"
            >
              Add Thumbnail
              <img
                src={file}
                style={{
                  height: "100px",
                  width: "263px",
                  marginTop: "10px",
                }}
              />
            </Typography>{" "}
            <input
              style={{ display: "none" }}
              type="file"
              id="raised-button-file"
              multiple
              accept="image/*"
              onChange={ImageUploader}
            />{" "}
            <label htmlFor="raised-button-file">
              <Button
                style={{ margin: "15px 15px" }}
                className="GreenButton700  "
                variant="raised"
                component="span"
                // className={classes.button}
              >
                <IoIosAddCircle className="AddCircleIcon" />
                Choose File
              </Button>
            </label>
            {/* <input
                className="   LPATChooseButton "
                type="file"
                id="image-input"
                accept="image/jpeg, image/png, image/jpg"
              /> */}
            <Grid
              container
              item
              style={{
                borderBottom: "1px solid #2E3180 ",
              }}
            >
              <Typography
                style={{ marginLeft: "15px" }}
                className="LPTypo BlueColorTypography"
              >
                Add Skill
              </Typography>
              <Grid
                item
                style={{
                  justifyContent: "center",
                  width: "100% ",
                  marginLeft: "2%",
                  marginRight: "2%",
                  marginBottom: "2.8%",
                }}
              >
                <AssignSkills />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                borderBottom: "1px solid #2E3180 ",
              }}
            >
              <Typography
                style={{ marginLeft: "15px" }}
                className="LPTypo BlueColorTypography"
              >
                Add Equipment
              </Typography>
              <Grid
                item
                style={{
                  justifyContent: "center",
                  width: "97% ",
                  marginLeft: "2%",
                  marginRight: "2%",
                  marginBottom: "2.8%",
                }}
              >
                <AssignEquipment />
              </Grid>
            </Grid>
            <Grid container item>
              <Typography
                style={{ marginLeft: "15px" }}
                className="LPTypo BlueColorTypography"
              >
                Add Activity
              </Typography>
              <Grid
                item
                style={{
                  justifyContent: "center",
                  marginBottom: "2.8%",
                  width: "100% ",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
              >
                <AssignActivity />
              </Grid>
            </Grid>
            {/* <Grid container item>
              <Typography
                style={{ marginLeft: "15px" }}
                className="LPTypo BlueColorTypography"
              >
                Recommendation (
                <span
                  className="GreyColorTypography400"
                  style={{ fontSize: "13px" }}
                >
                  for whom
                </span>
                )
              </Typography>
              <Grid container item style={{ marginBottom: "2.8%" }}>
                <Grid container item>
                  <Grid container item direction="row">
                    <Typography
                      className="BlueColorTypography"
                      style={{ marginLeft: "20px" }}
                    >
                      Users
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        className="CheckStyleRecommendation"
                        label="Trainer"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox className="CheckStyleRecommendation" />
                        }
                        className="CheckStyleRecommendation"
                        label="Learner"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    container
                    item
                    style={{ marginTop: " 19px ", paddingLeft: "20px" }}
                  >
                    <Grid container item md={3}>
                      <Typography className="BlueColorTypography">
                        Grade
                      </Typography>
                    </Grid>
                    <Grid container item md={9}>
                      <Autocomplete
                        className="LPAutoComplete GreyColorTypography400"
                        multiple
                        limitTags={1}
                        id="checkboxes-tags-demo"
                        options={top100Films}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                          <React.Fragment style={{ border: "2px solid red" }}>
                            <Checkbox
                              icon={icon}
                              className="LPAutoComplete GreyColorTypography400"
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </React.Fragment>
                        )}
                        style={{
                          width: "90%",
                          paddingLeft: "10px",
                          borderRadius: "10px",

                          border: "2px solid #d4d4d4",
                        }}
                        renderInput={(params) => (
                          <TextField className="LPAutoComplete " {...params} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid container item style={{ justifyContent: "center" }}>
          <Button onClick={submitClick} className="GreenButton700 CLPButton ">
            <IoIosAddCircle size="2em" justifyContent="start" />
            Create Lesson Plan
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import { DropzoneArea } from "material-ui-dropzone";

//React-Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsUpload } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";

import { IoIosAddCircle } from "react-icons/io";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Upload Documents
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UploadDocuments({
  schoolId,
  schoolUId,
  setSchoolDocs,
}) {
  const [docs, setDocs] = useState([]);

  const getSingleSchoolAndUpdateDocsData = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getSingleSchool/${schoolUId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setSchoolDocs(res.msg.schoolDocs);
      }
    } catch (error) {}
  };

  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(120),
      // height: theme.spacing(27),
    },
  }));

  const handleFileChange = (newFiles) => {
    if (newFiles.length > 0) {
      setDocs([...docs, ...newFiles]);
    }
  };

  const handleFileDelete = (file) => {
    const newFiles = [...docs];
    newFiles.splice(newFiles.indexOf(file), 1);
    setDocs(newFiles);
  };

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDocs([]);
  };
  const [scroll, setScroll] = React.useState("paper");
  const [rowData, setRowData] = useState({});
  const { userToken } = useLogin();

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const DocUpload = async () => {
    if (docs.length == 0) {
      setSnackerropen(true);
      setSnackErrMsg("Choose Document File");
    }
    console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("schoolId", schoolId);
    docs.map((data) => {
      formdata.append("file", data);
    });
    // formdata.append("file", docs);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/school/uploadSchoolDocs`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
        getSingleSchoolAndUpdateDocsData();
        console.log("I am the response after Docs upload >>", responce);
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        handleClose();
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Button
        className="ButtonTransparent"
        onClick={handleClickOpen}
        style={{ paddingTop: "10px" }}
      >
        {/* <IconButton> */}
        <BsUpload className="UploadIcon MiniAddIcon" />
        {/* </IconButton> */}
        Upload Documents
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid
            container
            spacing={4}
            justify="center"
            className="DailogLTRBStyle"
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
              lg={6}
              direction="column"
              style={{ marginTop: "1px" }}
            >
              <DropzoneArea
                filesLimit={3}
                maxFileSize={2097152}
                acceptedFiles={["application/pdf", "	application/msword"]}
                onChange={handleFileChange}
                useChipsForPreview={true}
                onDelete={handleFileDelete}
                dropzoneText="Upload Documents"
              />

              <Typography className="ALAddSkilltypography">
                Support only .doc / .pdf format(max file size 2 MB)
              </Typography>
              {docs?.map((data) => (
                <Typography className="ALAddSkilltypography">
                  {data?.path}
                </Typography>
              ))}

              <DialogActions className="">
                <Button
                  className="GreenButton700 SumbitButton"
                  //   onKeyDown={handleEnter}
                  onClick={DocUpload}
                >
                  Upload
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>{" "}
        {/* <DialogActions className="">
          <Button
            type="submit"
            className="GreenButton "
            style={{ padding: "3px 20px" }}
            onClick={submitClick}
          >
            Upload
          </Button>
          <Button className="GreyButton GradeCancelB" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

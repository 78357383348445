import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };
// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export default function AcadmicTab({ trainer }) {
  const [locomotorAnalyticsData, setLocomotorAnalyticsData] = useState([]);
  const [bodyManagement, setBodyManagement] = useState([]);
  const [objectControl, setobjectControl] = useState([]);
  const [dates, setAnalyticsDate] = useState([]);
  const [datesForYear, setAnalyticsDateForYear] = useState([]);
  const [dateType, setDateType] = useState("monthly");
  const [datasets, setDataSets] = useState([]);
  const getAllAnalytics = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getProgressFMS/${trainer?._id}?yearly=${
          dateType == "yearly" ? true : null
        }&monthly=${dateType == "monthly" ? true : null}&weekly=${
          dateType == "last7days" ? true : null
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLocomotorAnalyticsData(
          res?.msg?.filter((a) => a?._id?.skillName == "Locomotor")
        );
        setBodyManagement(
          res?.msg?.filter((a) => a?._id?.skillName == "Body Management")
        );
        setobjectControl(
          res?.msg?.filter((a) => a?._id?.skillName == "Object Control")
        );
        console.log("response after analytics request", res);
      } else {
        console.log("failed response after analytics request", res);
      }
    } catch (error) {
      console.log(error, "i am error");
    }
  };

  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  }
  useEffect(() => {
    if (dateType === "monthly") {
      let d = new Date(); // today!
      let previousDay = d.setDate(d.getDate() - 1);
      let x = 30; // go back 30 days!
      d.setDate(d.getDate() - x);
      setAnalyticsDate(dateRange(d, previousDay));
    }
    if (dateType === "last7days") {
      let d = new Date(); // today!
      let previousDay = d.setDate(d.getDate() - 1);
      let x = 6; // go back 7 days!
      d.setDate(d.getDate() - x);
      setAnalyticsDate(dateRange(d, previousDay));
    }
    if (dateType === "yearly") {
      let givenDateTime = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );
      let createdDate = new Date(givenDateTime);
      createdDate.setDate(1);
      let currentDate = new Date();
      let dateAndYearList = [
        `${createdDate.getMonth() + 1}_${createdDate.toLocaleString("en", {
          month: "long",
        })}`,
      ];

      while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
        if (
          createdDate.getMonth() !== currentDate.getMonth() ||
          createdDate.getFullYear() !== currentDate.getFullYear()
        ) {
          dateAndYearList.unshift(
            `${createdDate.getMonth() + 1}_${createdDate.toLocaleString("en", {
              month: "long",
            })}`
          );
        }
      }

      setAnalyticsDateForYear(
        dateAndYearList?.reverse()?.map((a) => a?.split("_")?.[1])
      );
      setAnalyticsDate(
        dateAndYearList?.reverse()?.map((a) => Number(a?.split("_")?.[0]))
      );
    }
  }, [dateType]);
  let locomotorData = [];
  let bodyMoniterData = [];
  let objectControlData = [];
  useEffect(() => {
    for (let i = 0; i < dates.length; i++) {
      let index = locomotorAnalyticsData?.findIndex(
        (item) => item?._id?.time === dates[i]
      );
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          total: locomotorAnalyticsData[index].total,
        };
        locomotorData.push(obj);
      } else {
        let obj = { _id: dates[i], total: 0 };
        locomotorData.push(obj);
      }
    }

    for (let i = 0; i < dates.length; i++) {
      let index = bodyManagement?.findIndex(
        (item) => item?._id?.time === dates[i]
      );
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          total: bodyManagement[index].total,
        };
        bodyMoniterData.push(obj);
      } else {
        let obj = { _id: dates[i], total: 0 };
        bodyMoniterData.push(obj);
      }
    }

    for (let i = 0; i < dates.length; i++) {
      let index = objectControl?.findIndex(
        (item) => item?._id?.time === dates[i]
      );
      if (index >= 0) {
        let obj = {
          _id: dates[i],
          total: objectControl[index].total,
        };
        objectControlData.push(obj);
      } else {
        let obj = { _id: dates[i], total: 0 };
        objectControlData.push(obj);
      }
    }
  }, [locomotorAnalyticsData, bodyManagement, objectControl]);

  useEffect(() => {
    getAllAnalytics();
  }, [dateType]);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          stacked: false,
        },
      ],
    },
  };
  const data = {
    labels: dates?.length == 12 ? datesForYear : dates,
    datasets: datasets,
  };
  useEffect(() => {
    setDataSets([
      {
        label: "Locomotor",
        data: locomotorData?.map((a) => a?.total),
        backgroundColor: "rgb(71, 209, 71)",

        borderRadius: dateType === "monthly" ? 10 : 20,
        barThickness: dateType === "monthly" ? 11 : 24,
      },
      {
        label: "Body Management",
        data: bodyMoniterData?.map((a) => a?.total),
        backgroundColor: "rgb(0, 172, 230)",
        borderRadius: dateType === "monthly" ? 10 : 20,
        barThickness: dateType === "monthly" ? 11 : 24,
      },
      {
        label: "Object Control",
        data: objectControlData?.map((a) => a?.total),
        backgroundColor: "rgb(255, 153, 0)",

        borderRadius: dateType === "monthly" ? 10 : 20,
        barThickness: dateType === "monthly" ? 11 : 24,
      },
    ]);
  }, [locomotorAnalyticsData, bodyManagement, objectControl]);

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  return (
    <>
      <>
        <Grid
          container
          style={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "20px",
          }}
          className="DailogLTRBStyle-Na94 colorwhite"
        >
          <Grid item container style={{ width: "250px" }}>
            <FormControl className="MainPageFormControl">
              <Select
                className=" SkillSelectdropstyle"
                value={school ? school : "monthly"}
                onChange={(e) => {
                  handleChange(e);
                  setDateType(e.target.value);
                }}
                label="Monthly"
                color="white"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={"monthly"}>
                  Monthly
                </MenuItem>

                <MenuItem className="Selectmenustyle" value={"yearly"}>
                  Yearly
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={"last7days"}>
                  Last 7 Days
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container>
            <Bar options={options} data={data} />
          </Grid>
        </Grid>
      </>
    </>
    // <>
    //   <Grid container className="DailogLTRBStyle-95">
    //     {/* <Grid item className="L-profile-grid width-23">
    //       <Grid item className="width80-LR">
    //         {trainer?.trainerDetail?.trainerImg ? (
    //           <img
    //             style={{
    //               height: "210px",
    //               width: "210px",
    //               borderRadius: "10px",
    //             }}
    //             src={trainer?.trainerDetail?.trainerImg}
    //           />
    //         ) : (
    //           <>
    //             <Avatar
    //               style={{
    //                 height: "210px",
    //                 width: "210px",

    //                 borderRadius: "10px",
    //                 fontSize: "60px",
    //               }}
    //               variant="rounded"
    //             >
    //               {trainer?.trainerDetail?.fullName.slice(" ")[0][0]}
    //             </Avatar>
    //           </>
    //         )}
    //       </Grid>
    //       <Typography align="center" className="BlueColorTypography mt-14">
    //         {trainer?.trainerDetail?.fullName}
    //       </Typography>

    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography "> Role</Typography>
    //         </Grid>
    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography ">
    //             <span> : </span>
    //             <span className="pl-5">Trainer</span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography "> UID</Typography>
    //         </Grid>

    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography ">
    //             <span> : </span>
    //             <span className="pl-5">
    //               {trainer?.trainerDetail?.regNo ? (
    //                 trainer?.trainerDetail?.regNo
    //               ) : (
    //                 <span>N/A</span>
    //               )}
    //             </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography ">
    //             Enrolled on
    //           </Typography>
    //         </Grid>

    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography ">
    //             <span> : </span>
    //             <span className="pl-5">
    //               {trainer?.trainerDetail?.enrollmentDate ? (
    //                 trainer?.trainerDetail?.enrollmentDate.split("T")[0]
    //               ) : (
    //                 <span>N/A</span>
    //               )}
    //             </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography ">
    //             FMS Score
    //           </Typography>
    //         </Grid>
    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography ">
    //             <span> : </span> <span className="pl-5">{fmsScore} </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid
    //         container
    //         className="mt-10 pb-10"
    //         style={{ borderBottom: "2px solid #dddddd" }}
    //       >
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography ">Total PP</Typography>
    //         </Grid>

    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography">
    //             <span> : </span> <span className="pl-5"> {totalPP} </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography ">
    //             Date of birth
    //           </Typography>
    //         </Grid>

    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography">
    //             <span> : </span>
    //             <span className="pl-5">
    //               {trainer?.trainerDetail?.dob ? (
    //                 trainer?.trainerDetail?.dob.split("T")[0]
    //               ) : (
    //                 <span>N/A</span>
    //               )}
    //             </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>

    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography ">
    //             Father’s name
    //           </Typography>
    //         </Grid>

    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography ">
    //             <span> : </span>
    //             <span className="pl-5">
    //               {trainer?.trainerDetail?.fathersName ? (
    //                 trainer?.trainerDetail?.fathersName
    //               ) : (
    //                 <span>N/A</span>
    //               )}
    //             </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid container className="mt-10">
    //         <Grid md={6}>
    //           <Typography className="BlueColorTypography ">
    //             Mother’s name
    //           </Typography>
    //         </Grid>

    //         <Grid md={6}>
    //           <Typography className="GreyColorTypography ">
    //             <span> : </span>
    //             <span className="pl-5">
    //               {trainer?.trainerDetail?.mothersName ? (
    //                 trainer?.trainerDetail?.mothersName
    //               ) : (
    //                 <span>N/A</span>
    //               )}
    //             </span>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </Grid> */}
    //     <Grid container item className="L-profile-grid ">
    //       <Grid container item className="GridStyle-2">
    //         <Box style={{ width: "100%" }}>
    //           <Tabs
    //             value={value}
    //             onChange={TabChange}
    //             centered
    //             aria-label="basic tabs example"
    //           >
    //             {/* <Tab className="TabStyle" label="Courses" {...a11yProps(0)} />
    //             <Tab
    //               className="TabStyle"
    //               label="Lesson Plan "
    //               {...a11yProps(1)}
    //             /> */}
    //             <Tab className="TabStyle" label="Skills" {...a11yProps(0)} />
    //             {/* <Tab className="TabStyle" label="Assesment" {...a11yProps(1)} /> */}
    //           </Tabs>
    //         </Box>
    //         <DialogContent dividers={false} className="TabPanelStyle">
    //           {/* <TabPanel value={value} index={1}>
    //             <AssesmentTab />
    //           </TabPanel> */}
    //           <TabPanel value={value} index={0}>
    //             <SkillsTab trainer={trainer} />
    //           </TabPanel>
    //         </DialogContent>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </>
  );
}

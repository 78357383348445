import React, { useState, useEffect } from "react";

import {
  Grid,
  Typography,
  Tab,
  Box,
  Tabs,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
// React Icons
import LearnerTabPanel from "./AdminTabPanel/Learner";
import TrainerTabPanel from "./AdminTabPanel/Trainer";
import OthersTabPanel from "./AdminTabPanel/Others";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import card1 from "../../assets/img/1stcard (1).png";
import card2 from "../../assets/img/2ndcard.png";
import card3 from "../../assets/img/3rdcard.png";
// tabPanel changes
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
// tabPanel changes
const AdminBoardTab = () => {
  const { userToken } = useLogin();
  //   tab states
  const [value, setValue] = useState(0); // set initial selected tab index to 0
  const [user, setUser] = useState("learners");
  const tabHandleChange = (event, newValue) => {
    setTableData([]);
    setValue(newValue);
    setPage(1);
    if (newValue == 0) {
      getAllTableData(true, "learners", 1, 0);
      setUser("learners");
    }
    if (newValue == 1) {
      getAllTableData(true, "trainers", 1, 1);
      setUser("trainers");
    }
    if (newValue == 2) {
      getAllTableData(true, "others", 1, 2);
      setUser("others");
    }

    onStateResetHandler();
  };

  //   tab states
  //   new changes by k
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [selectYear, setSelectYear] = useState("");
  const [selectPlatForm, setSelectPlatForm] = useState("");

  const [selectDepartment, setSelectDepartment] = useState("");
  const [selectGrade, setSelectGrade] = useState("");
  const [selectDivision, setSelectDivision] = useState("");
  const [tableData, setTableData] = useState([]);
  // department data
  const [departMentDetail, setDepartMentDetails] = useState([]);
  const [selectSchoolLoactionData, setSchoolLoactionData] = useState([]);
  const [selectGradeFromSchool, setSelectGradeFromSchool] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  // sortByDate state
  const [weekly, setSortWeekly] = useState(null);
  const [daily, setDaily] = useState(null);
  // const [plateform, setPlateForm] = useState(null);

  // api states for params
  // const [selectPlatformForApi, setSelectPlatformForApi] = useState(null);
  const [plateformForApi, setPlateformForApi] = useState(null);
  const [selectDepartmentForApi, setSelectDepartmentForApi] = useState(null);
  const [schoolLocationSelectForApi, setSchoolLocationSelectForApi] = useState(
    null
  );
  const [selectGradeForApi, setSelectGradeForApi] = useState(null);
  const [selectDivisionForApi, setSelectDivisionForApi] = useState(null);

  // api states for params
  // function for saving Api states
  const saveStateHandler = () => {
    setPlateformForApi(selectPlatForm ? selectPlatForm : null);
    setSelectDepartmentForApi(selectDepartment ? selectDepartment?._id : null);
    setSchoolLocationSelectForApi(
      schoolLocationSelected ? schoolLocationSelected?._id : null
    );
    setSelectGradeForApi(selectGrade ? selectGrade?._id : null);
    setSelectDivisionForApi(selectDivision ? selectDivision : null);
  };
  // function for saving Api states
  // reset state for tabChange
  const onStateResetHandler = () => {
    // setSelectYear(null);
    setSelectDepartment(null);
    setSchoolLocationSelected(null);
    setSelectGrade(null);
    setSelectDivision(null);
    setSelectDepartmentForApi(null);
    setSchoolLocationSelectForApi(null);
    setSelectGradeForApi(null);
    setSelectDivisionForApi(null);
    setPlateformForApi(null);
    setSelectPlatForm(null);
  };
  // reset state for tabChange
  useEffect(() => {
    if (
      plateformForApi == selectPlatForm &&
      selectDepartmentForApi == selectDepartment?._id &&
      schoolLocationSelectForApi == schoolLocationSelected?._id &&
      selectGradeForApi == selectGrade?._id &&
      selectDivisionForApi == selectDivision
    ) {
      setPage(1);
      setTableData([]);
      getAllTableData(false, user, 1, value);
    }
  }, [
    plateformForApi,
    selectDepartmentForApi,
    schoolLocationSelectForApi,
    selectGradeForApi,
    selectDivisionForApi,
  ]);

  const onSortDateHandler = (e) => {
    setSelectYear(e);
    if (e == "All") {
      setSortWeekly(null);
      setDaily(null);
      return;
    }
    if (e == "Weekly") {
      setSortWeekly("true");
      setDaily(null);
      return;
    }
    if (e == "Daily") {
      setSortWeekly(null);
      setDaily("true");
      return;
    }
  };
  // get department Details api
  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getAllDepartments?pagination=true&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        setDepartMentDetails(res);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  // get department Details api
  // get school by department ID
  const getSchoolByDepartmentId = async (deparMentId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getSchoolByDepartmentId/${deparMentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of schoolData==>", res);
        setSchoolLoactionData(res?.msg);
      } else {
        console.log("Error while fetching SchoolData", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  // get school by department ID
  // get division By grade Id
  const getDivisionByGradeId = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of divison==>", res);
        setDivisionData(res?.msg);
      } else {
        console.log("Error while fetching divison", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  /////////////////////getAllData////////////////////////////////////////////
  const [lengthForScroll, setLengthForScroll] = useState([]);
  const getAllTableData = async (tabChange, user, page, tabValue) => {
    let url = `/api/assessment/leaderboard/total/${user}`;
    if (tabValue === 2) {
      url = `/api/assessment/leaderboard/total/others`;
    }
    try {
      const response = await fetch(
        `${FETCH_URL}${url}?daily=${tabChange ? null : daily}&weekly=${
          tabChange ? null : weekly
        }&plateform=${
          selectPlatForm == "Zoommer"
            ? "zoommerplateform"
            : selectPlatForm == "School"
            ? "school"
            : "all"
        }&departmentId=${tabChange ? null : selectDepartmentForApi}&schoolId=${
          tabChange ? null : schoolLocationSelectForApi
        }&gradeId=${tabChange ? null : selectGradeForApi}&divisionId=${
          tabChange ? null : selectDivisionForApi
        }&pagination=true&page=${page}&limit=12`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLengthForScroll(res?.agreg);
        setTableData((prev) => [...prev, ...res?.agreg]);
        // setTableData(res?.agreg);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectPlatForm !== "School") {
      setSelectDepartment(null);
      setSchoolLocationSelected(null);
      setSelectGrade(null);
      setSelectDivision(null);
    }
  }, [selectPlatForm]);
  useEffect(() => {
    setSchoolLocationSelected(null);
    setSelectGrade(null);
    setSelectDivision(null);
  }, [selectDepartment]);
  useEffect(() => {
    setSelectGrade(null);
    setSelectDivision(null);
  }, [schoolLocationSelected]);
  useEffect(() => {
    setSelectDivision(null);
  }, [selectGrade]);
  useEffect(() => {
    getDepartmentDetails();
  }, []);
  useEffect(() => {
    setPage(1);
    setTableData([]);
    getAllTableData(false, user, 1, value);
  }, [daily, weekly]);

  // infinite loading state
  const [isFetching, setIsFetching] = React.useState(
    Array.from({ length: 12 })
  );
  const [page, setPage] = useState(1);
  const handleisFetching = () => {
    if (lengthForScroll?.length == 0) {
      return;
    }
    setTimeout(() => {
      setIsFetching(isFetching.concat(Array.from({ length: 12 })));
      setPage((prev) => prev + 1);
    }, 1000);
  };
  useEffect(() => {
    if (page === 1) {
      return;
    }
    getAllTableData(false, user, page, value);
  }, [page]);
  useEffect(() => {
    setSelectPlatForm(value == 0 ? "" : "School");
  }, [value]);
  // infinite loading state

  return (
    <>
      <Grid container className="LeaderContainer">
        {" "}
        <Grid item container className="LeaderTitle">
          <Typography className="BlueColorTypography LeaderTitleTypo ">
            Leaderboard
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="LeaderContainer">
        <Grid container item md={8} sm={8} xs={8}>
          <div
            style={{
              width: "100%",
            }}
          >
            <Tabs
              value={value}
              onChange={tabHandleChange}
              className="leaderBoardTabs"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  justifyContent: "flex-start", // align tabs to the start
                  "& > * + *": {
                    marginLeft: "16px", // add space between tabs
                  },
                },
              }}
            >
              <Tab label="Learner" className="leaderBoard_tab" />
              <Tab label="Trainer" className="leaderBoard_tab" />
              <Tab label="Others" className="leaderBoard_tab" />
            </Tabs>
            <div
              id="scrollableDiv"
              style={{
                marginTop: "20px",
                height: "60vh",
                overflowY: "scroll",
              }}
            >
              <Grid
                container
                // justifyContent="space-between"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  item
                  xs={4}
                  style={{
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <div className="position-r">
                    <img src={card2} alt="second-num" />
                    <span className="WhiteColorTypography top_pp_absolute">
                      {tableData?.[1]?.totalPP ? tableData?.[1]?.totalPP : 0} PP
                    </span>
                    <span className="WhiteColorTypography top_pp_absolute_name">
                      {tableData?.[1]?.name ? tableData?.[1]?.name : "--"}
                    </span>
                    <span className="WhiteColorTypography top_pp_absolute_schName fs-12 ">
                      {tableData?.[1]?.schoolName
                        ? tableData?.[1]?.schoolName
                        : "--"}
                    </span>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={4}
                  style={{
                    justifyContent: "center",
                    marginBottom: "50px",
                  }}
                >
                  <div className="position-r">
                    <img src={card1} />
                    <span className="WhiteColorTypography top_pp_absolute">
                      {tableData?.[0]?.totalPP ? tableData?.[0]?.totalPP : 0} PP
                    </span>
                    <span className="WhiteColorTypography top_pp_absolute_name">
                      {tableData?.[0]?.name ? tableData?.[0]?.name : "--"}
                    </span>
                    <span className="WhiteColorTypography top_pp_absolute_schName fs-12 ">
                      {tableData?.[0]?.schoolName
                        ? tableData?.[0]?.schoolName
                        : "--"}
                    </span>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={4}
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <div className="position-r">
                    <img src={card3} alt="third-num" />
                    <span className="WhiteColorTypography top_pp_absolute">
                      {tableData?.[2]?.totalPP ? tableData?.[2]?.totalPP : 0} PP
                    </span>
                    <span className="WhiteColorTypography top_pp_absolute_name">
                      {tableData?.[2]?.name ? tableData?.[2]?.name : "--"}
                    </span>
                    <span className="WhiteColorTypography top_pp_absolute_schName">
                      {tableData?.[2]?.schoolName
                        ? tableData?.[2]?.schoolName
                        : "--"}
                    </span>
                  </div>
                </Grid>
              </Grid>

              <InfiniteScroll
                dataLength={isFetching.length}
                next={handleisFetching}
                hasMore={lengthForScroll == 0 ? false : true}
                scrollableTarget="scrollableDiv"
                className="width100"
                loader={
                  <>
                    <h1>Loading</h1>
                  </>
                }
                endMessage={<h1>No data ahead</h1>}
              >
                <TabPanel value={value} index={0}>
                  <LearnerTabPanel tableData={tableData} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TrainerTabPanel />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <OthersTabPanel
                    tableData={tableData}
                    selectDepartmentForApi={selectDepartmentForApi}
                    schoolLocationSelectForApi={schoolLocationSelectForApi}
                    selectGradeForApi={selectGradeForApi}
                    selectDivisionForApi={selectDivisionForApi}
                  />
                </TabPanel>
              </InfiniteScroll>
            </div>
          </div>
        </Grid>

        <Grid item md={4} sm={4} xs={4}>
          <div style={{ width: "100%" }}>
            <Grid container item style={{ justifyContent: "flex-end" }}>
              <FormControl variant="standard">
                <Select
                  className="Selectdropstyle_leaderBoard"
                  value={selectYear ? selectYear : 0}
                  onChange={(e) => {
                    onSortDateHandler(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                  defaultValue={0}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Sort By
                  </MenuItem>

                  <MenuItem value={"All"} className="Selectmenustyle">
                    All
                  </MenuItem>
                  <MenuItem value={"Daily"} className="Selectmenustyle">
                    Daily
                  </MenuItem>
                  <MenuItem value={"Weekly"} className="Selectmenustyle">
                    Weekly
                  </MenuItem>

                  {/* <MenuItem value={"Yearly"} className="Selectmenustyle">
                    Yearly
                  </MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              container
              item
              style={{
                marginTop: "54px",
                gap: "20px",
              }}
            >
              <Grid container item className="leaderBoard_select_box">
                <Grid container item direction="column" style={{ gap: "10px" }}>
                  <Typography>Platform</Typography>
                  <FormControl variant="standard">
                    <Select
                      className="Selectdropstyle_leaderBoard"
                      value={selectPlatForm ? selectPlatForm : 0}
                      onChange={(e) => {
                        setSelectPlatForm(e.target.value);
                      }}
                      label="Select Location"
                      disableUnderline
                      disabled={value == 0 ? false : true}
                      defaultValue={0}
                    >
                      <MenuItem value={0} className="Selectmenustyle" disabled>
                        Select Platform
                      </MenuItem>

                      <MenuItem value={"School"} className="Selectmenustyle">
                        School
                      </MenuItem>
                      <MenuItem value={"Zoommer"} className="Selectmenustyle">
                        Zoommer
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item direction="column" style={{ gap: "10px" }}>
                  <Typography>Department</Typography>
                  <FormControl variant="standard">
                    <Select
                      className="Selectdropstyle_leaderBoard"
                      value={selectDepartment ? selectDepartment : 0}
                      onChange={(e) => {
                        setSelectDepartment(e.target.value);
                        getSchoolByDepartmentId(e.target.value?._id);
                      }}
                      label="Select Location"
                      disableUnderline
                      disabled={selectPlatForm !== "School" ? true : false}
                      defaultValue={0}
                    >
                      <MenuItem value={0} className="Selectmenustyle" disabled>
                        Select Department
                      </MenuItem>

                      {departMentDetail &&
                      Object.keys(departMentDetail).length > 0 ? (
                        departMentDetail?.msg?.map((dprData, i) => (
                          <MenuItem
                            key={i}
                            className="Selectmenustyle"
                            value={dprData}
                          >
                            {dprData?.departmentName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="Selectmenustyle"
                          value={0}
                          disabled
                        >
                          No Department Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item direction="column" style={{ gap: "10px" }}>
                  <Typography>School Location</Typography>
                  <FormControl variant="standard">
                    <Select
                      className="Selectdropstyle_leaderBoard"
                      value={
                        schoolLocationSelected ? schoolLocationSelected : 0
                      }
                      onChange={(e) => {
                        setSelectGradeFromSchool(e.target.value?.grade);
                        setSchoolLocationSelected(e.target.value);
                      }}
                      label="Select Location"
                      disableUnderline
                      disabled={selectDepartment ? false : true}
                      defaultValue={0}
                    >
                      <MenuItem value={0} className="Selectmenustyle" disabled>
                        Select a School
                      </MenuItem>

                      {selectSchoolLoactionData &&
                      Object.keys(selectSchoolLoactionData).length > 0 ? (
                        selectSchoolLoactionData?.map((slrData, i) => (
                          <MenuItem
                            key={i}
                            className="Selectmenustyle"
                            value={slrData}
                          >
                            {slrData?.organisationDetails?.schoolName +
                              " , " +
                              slrData?.organisationDetails?.location}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="Selectmenustyle"
                          value={1}
                          disabled
                        >
                          No School Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item direction="column" style={{ gap: "10px" }}>
                  <Typography>Grade</Typography>
                  <FormControl variant="standard">
                    <Select
                      className="Selectdropstyle_leaderBoard"
                      value={selectGrade ? selectGrade : 0}
                      onChange={(e) => {
                        setSelectGrade(e.target.value);
                        getDivisionByGradeId(e.target.value?._id);
                      }}
                      label="Select Location"
                      disableUnderline
                      disabled={schoolLocationSelected ? false : true}
                      defaultValue={0}
                    >
                      <MenuItem value={0} className="Selectmenustyle" disabled>
                        Select a Grade
                      </MenuItem>

                      {selectGradeFromSchool &&
                      Object.keys(selectGradeFromSchool)?.length > 0 ? (
                        selectGradeFromSchool?.map((gData, i) => (
                          <MenuItem
                            key={i}
                            className="Selectmenustyle"
                            value={gData}
                          >
                            {gData?.grade}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="Selectmenustyle"
                          value={1}
                          disabled
                        >
                          No Grade Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item direction="column" style={{ gap: "10px" }}>
                  <Typography>Division</Typography>
                  <FormControl variant="standard">
                    <Select
                      className="Selectdropstyle_leaderBoard"
                      value={selectDivision ? selectDivision : 0}
                      onChange={(e) => {
                        setSelectDivision(e.target.value);
                      }}
                      label="Select Location"
                      disableUnderline
                      disabled={selectGrade ? false : true}
                      defaultValue={0}
                    >
                      <MenuItem value={0} className="Selectmenustyle" disabled>
                        Select a Division
                      </MenuItem>

                      {divisionData && Object.keys(divisionData)?.length > 0 ? (
                        divisionData?.map((divData, i) => (
                          <MenuItem
                            key={i}
                            className="Selectmenustyle"
                            value={divData?._id}
                          >
                            {divData?.division}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="Selectmenustyle"
                          value={1}
                          disabled
                        >
                          No division Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  style={{ justifyContent: "space-between" }}
                >
                  <Button
                    className="GreenButton700"
                    onClick={() => onStateResetHandler()}
                  >
                    Reset
                  </Button>
                  <Button
                    className="GreenButton700"
                    onClick={() => saveStateHandler()}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminBoardTab;

import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextareaAutosize,
  Input,
  InputAdornment,
  FormControl,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { GoSearch } from "react-icons/go";
import { MdEdit } from "react-icons/md";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Kit Details
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AEditKit(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [equipmentKitUid, setEquipmentKitUid] = React.useState(null);
  const [equipmentKitName, setEquipmentKitName] = React.useState(null);
  const [equipmentKitDesc, setEquipmentKitDesc] = React.useState(null);
  const [addedEquipment, setAddedEquipment] = React.useState([]);

  const [originalData, setOriginalData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [locmotor, setSchool] = React.useState("");
  const [scroll, setScroll] = React.useState("paper");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { equipmentKit, kit, getAllEquipmentKIT, equipmentData } = props;
  console.log(
    " equipmentKit =================================><",
    equipmentKit
  );
  console.log("kit =================================><", kit);

  function storeLessonPlan(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        equipmentId: x._id,
        equipmentName: x.equipmentName,
        equipmentImg: x.equipmentImg,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  // React.useEffect(() => {
  //   console.log(originalData, "originalData>>>");
  // }, [originalData]);

  const filterdata = originalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });
  console.log(filterdata, "filterdata>>>>>>>>>>>>>>>>>>>>>>>>>");
  const equipmentNames = filterdata.map((a) => a.equipmentNames);

  //For CreateInput
  useEffect(() => {
    setOriginalData(kit.addedEquipment ?? "");
  }, []);

  const submitClick = async (e) => {
    e.preventDefault();
    const sendData = filterdata.map((a) => {
      return {
        equipmentId: a.equipmentId,
        equipmentName: a.equipmentName,
        equipmentImg: a.equipmentImg,
      };
    });
    console.log("fil", sendData);

    const responce = await fetch(
      `${FETCH_URL}/api/equipment/editEquipmentKit/${kit._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          // equipmentKitUid: equipmentKitUid
          //   ? equipmentKitUid
          //   : kit.equipmentKitUid,
          equipmentKitName: equipmentKitName
            ? equipmentKitName
            : kit.equipmentKitName,
          equipmentKitDesc: equipmentKitDesc
            ? equipmentKitDesc
            : kit.equipmentKitDesc,
          // equipmentNames: equipmentNames ? equipmentNames : kit.equipmentNames,
          addedEquipment: sendData,
        }),
      }
    );
    const res = await responce.json();

    if (responce.ok) {
      console.log("Learner has been successfully edited ====>", res);
      setSnackOpen(true);
      setSnackMsg(res.msg);
      getAllEquipmentKIT();
      setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
      setSnackerropen(true);
      setSnackErrMsg(res.err);
    }
  };
  console.log(equipmentNames, "equipmentNames>>>>>>123>>");
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
        scroll={scroll}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container direction="Column" className="DailogLTRBStyle">
            <Grid item container style={{ marginLeft: "25px" }}>
              <Grid item md={3} sm={3} xs={3} lg={3}>
                <Typography className="SkillsInputTitle  GreyColorTypography">
                  Equipment KIT UID <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    defaultValue={kit?.equipmentKitUid}
                    value={equipmentKitUid}
                    disabled
                    onChange={(e) => setEquipmentKitUid(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>
              <Grid
                item
                md={7}
                sm={7}
                xs={7}
                lg={7}
                style={{ marginLeft: "70px" }}
              >
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Equipment KIT Name <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={12} sm={12} xs={12} lg={12}>
                  <Input
                    className="RubicInputs"
                    value={equipmentKitName}
                    defaultValue={kit?.equipmentKitName}
                    onChange={(e) => setEquipmentKitName(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>
              <Typography className="SkillsInputTitle GreyColorTypography">
                Equipment Description <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={11} sm={11} xs={11} lg={11}>
                <TextareaAutosize
                  className="Kit_TextArea"
                  aria-label="minimum height"
                  value={equipmentKitDesc}
                  defaultValue={kit?.equipmentKitDesc}
                  onChange={(e) => setEquipmentKitDesc(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography className="Kit-Title BlueColorTypography">
                Add Equipments to KIT
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <FormControl className="KItSerachInput">
                <Input
                  disableUnderline
                  className="KitInput"
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton style={{ marginRight: "7px" }}>
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </Grid>
            {/* {equipmentKit &&
              equipmentKit.length > 0 &&
              equipmentKit.map((name, i) => {
                return (
                  <Grid
                    container
                    direction="row"
                    style={{ marginBottom: "20px", marginTop: "40px" }}
                  >
                    {name &&
                      name.equipmentNames.map((k, i) => {
                        return (
                          <FormControlLabel
                            control={<Checkbox />}
                            name="equipmentNames"
                            onChange={(e) => storeLessonPlan(e, name, i)}
                            className="CheckLabelStyleGrey  Kit-CheckBox"
                            label={<Typography>{k}</Typography>}
                          />
                        );
                      })}
                  </Grid>
                );
              })} */}

            <Grid
              container
              direction="row"
              style={{ marginBottom: "20px", marginTop: "40px" }}
            >
              {equipmentData &&
                equipmentData.length > 0 &&
                equipmentData.map((name, i) => {
                  let data = false;
                  originalData.map((datas) => {
                    if (datas?.equipmentId == name?._id) {
                      data = true;
                    }
                  });
                  return (
                    <Grid item xs={3} style={{ marginTop: "20px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={data == true ? true : false} />
                        }
                        name="equipmentNames"
                        className="CheckLabelStyleGrey  Kit-CheckBox width100"
                        onChange={(e) => storeLessonPlan(e, name, i)}
                        value={name.equipmentName}
                        label={
                          <Typography className="width100">
                            {name.equipmentName}
                          </Typography>
                        }
                      />
                    </Grid>
                  );
                })}{" "}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="KitDialogActionBottom">
          <Button
            className="GreenButton700 EquipActionBottom"
            style={{ marginRight: "1.3%" }}
            autoFocus
            onClick={submitClick}
          >
            Save
          </Button>
          <Button
            className="GreyButton700  EquipActionBottom"
            style={{ marginRight: "1%" }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Tooltip,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useParams, useLocation } from "react-router-dom";
import Page from "../.../../../../../src/assets/img/page.png";

// Icons
const emails = ["username@gmail.com", "user02@gmail.com"];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
    fontSize: 12,
  },
}));

export default function App() {
  const classes = useStyles();

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const [page, setPage] = useState([{ firstPage: "1" }]);
  const [pages, setPages] = useState([]);
  const { folderId } = useParams();
  const location = useLocation();
  const { foldersData } = location.state;
  console.log("Check Clone Msg location", foldersData);

  const handleAddFields = () => {
    setPage([...page, { firstPage: "" }]);
  };

  console.log("Check No OF PAge", pages);
  const PageID = pages.map((title) => title?._id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    console.log("clicked");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Link to="/admin/CaretorMode">
            <div className="BackHead">
              <IoIosArrowBack className="BackIcon" />
              <Button disableRipple className="Backbutton ">
                Back
              </Button>{" "}
            </div>
          </Link>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Button disableRipple className="Breadcrumbbutton">
                <Link to="/admin/Lesson" className="LinkColor">
                  LessonPlan
                </Link>
              </Button>
              <div className="Breadcrumb2">
                <Link className="LinkColor" to="/admin/CaretorMode">
                  Creator Mode
                </Link>
              </div>
              <div className="Breadcrumb2">
                <Link
                  className="LinkColor"
                  to={{
                    pathname: "/admin/CaretorMode",
                    state: 1,
                  }}
                >
                  Publieshed
                </Link>
              </div>
              <div className="Breadcrumb2">{foldersData?.folderName} </div>
            </Breadcrumbs>
          </div>
          <Typography className="GreyColorTypography ml-25px">
            LessonPlan Details
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="left"
            className=" FolderContainer"
          >
            {foldersData.pages &&
              foldersData.pages.length > 0 &&
              foldersData.pages.map((page, index) => (
                <>
                  <Grid item className="PageGridItem">
                    <Link
                      to={{
                        pathname: `/grapes/editor/${page._id}`,
                        state: { folderId },
                      }}
                    >
                      <div className="Icon2">
                        <img
                          src={Page}
                          style={{
                            width: "44px",
                            height: "55px",
                            marginTop: "10px",
                          }}
                        />
                        <div className="Icon3"></div>
                      </div>
                    </Link>
                    <div className={classes.root}>
                      <div>
                        <Tooltip
                          title={page?.pageName}
                          aria-label={page?.pageName}
                        >
                          <Typography className="GreyColorTypography folderTypostyle   ml-5px">
                            {page?.pageName}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                </>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import {
  TextareaAutosize,
  IconButton,
  Grid,
  Input,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Chip,
  DialogActions,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
//Icons
import { IoIosAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { FiPlus } from "react-icons/fi";

//core component

export default function AboutTab(props) {
  const {
    states: {
      courseUid,
      coursePrice,
      courseDuration,
      durationSlot,
      courseTitle,
      grade,
      sports,
      courseDesc,
      skills,
      open,
      file,
      filterAssignequiment,
      openEquip,
      media,
      mediatype,
      sportsValid,
      imageValid,
      medaiValid,
      Coursedata,
    },

    top100Films,
  } = props;
  const SkillsData = skills?.slice(0, 5);
  const totalCount = skills?.length - 5;
  const EquipmentData =
    filterAssignequiment && filterAssignequiment?.slice(0, 5);
  const totalEquipmentCount =
    filterAssignequiment && filterAssignequiment?.length - 5;

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid container className="ml-18">
            <Grid container md={12} sm={12} xs={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Typography className="InputTitle">Course UID</Typography>
                  <Input
                    value={courseUid}
                    disableUnderline
                    className="InputStyle"
                    disabled={true}
                  />
                </Grid>
                <Grid item md={5}>
                  <Typography className="InputTitle">Duration</Typography>
                  <Grid container className="border-grey b-r10 ">
                    <Grid
                      item
                      md={5}
                      style={{ borderRight: "1px solid #d4d4d4" }}
                    >
                      <Input
                        value={courseDuration}
                        disableUnderline
                        className="InputStyle-no-br width100"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={7}>
                      <FormControl className="MainPageFormControl width100">
                        <Select
                          className="Selectdrop-no hgt-33"
                          value={durationSlot}
                          label="Select School"
                          color="white"
                          disableUnderline
                          disabled={true}
                        >
                          <MenuItem className="Selectmenustyle" value={"Month"}>
                            Month
                          </MenuItem>
                          <MenuItem className="Selectmenustyle" value={"Days"}>
                            Days
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <Typography className="InputTitle">Price</Typography>
                  <Input
                    value={coursePrice}
                    disableUnderline
                    className="InputStyle"
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TextField
                id="outlined-basic"
                label="Course Title"
                variant="outlined"
                size="small"
                className="width90 mt-24 textfield-outline"
                disabled={true}
                value={courseTitle}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                className="autocomplete-course"
                size="small"
                options={top100Films}
                getOptionLabel={(option) => option}
                value={grade}
                disabled={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Grade"
                    className="width90"
                    disabled={true}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Typography
                className={sportsValid ? "InputTitle-warn" : "InputTitle"}
              >
                Sports <span className="ImpColor"> * </span>
              </Typography>
              <TagsInput
                value={sports}
                placeholder="Add a sports tag"
                disabled={true}
                className={
                  sportsValid ? "InputStyle-warn tags" : "InputStyle tags"
                }
              />
            </Grid>

            <Grid item md={12} className="mt-18">
              <div>
                <Typography className="TypographyTitle">About </Typography>
                <div>
                  <TextareaAutosize
                    className="TextArea width90"
                    aria-label="minimum height"
                    value={courseDesc}
                    disabled={true}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography className="TypographyTitle mt-10">
              Add Skills
            </Typography>
            <div className="Thumbnailform  border-grey">
              {open === false ? (
                <>
                  {SkillsData?.map((data) => {
                    console.log("Check map data", data);

                    let icon;

                    if (data.subSkill === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <Chip
                        icon={icon}
                        label={data.subSkill}
                        className="Course-SkillChip"
                        // onDelete={
                        //   data.subSkillName === "React"
                        //     ? undefined
                        //     : handleDelete(data)
                        // }
                      />
                    );
                  })}
                  {skills.length > 5 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography className="TypographyTitle mt-16">
              Add Thumbnail
            </Typography>
            <Grid item className="ChooseFileButtonItem ">
              {file ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    imageValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                >
                  <img src={file} className="image-thumb" />{" "}
                </Grid>
              ) : (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="raised-button-file"
                    accept="image/*"
                  />
                  <Grid
                    item
                    md={12}
                    className={
                      imageValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                  >
                    <label htmlFor="raised-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle className="cursor Icon-Color mt-24 fs-100" />
                      </Typography>
                    </label>
                    <Typography
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12 width80-LR"
                    >
                      Click here to upload image
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography className="TypographyTitle mt-10">
              Add Equipment
            </Typography>
            <div className=" Thumbnailform border-grey ">
              {openEquip === false ? (
                <>
                  {EquipmentData?.map((data) => {
                    let icon;

                    if (data.name === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <Chip
                        icon={icon}
                        label={data.name}
                        className="Course-SkillChip"
                      />
                    );
                  })}
                  {EquipmentData.length > 5 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalEquipmentCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography className="TypographyTitle mt-16">
              Add Hero Conent{" "}
            </Typography>
            <Grid item className="ChooseFileButtonItem ">
              {media && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={
                    medaiValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                >
                  {mediatype === "video/mp4" ||
                  Coursedata?.heroContent?.type === "video" ? (
                    <video
                      className="image-thumb"
                      autoPlay
                      muted
                      src={media}
                      type="video/mp4"
                    />
                  ) : (
                    <img src={media} className="image-thumb" />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import {
  Grid,
  DialogActions,
  MenuItem,
  Menu,
  Typography,
  Tooltip,
} from "@material-ui/core";
import LPDialog from "./CreateFolderDialog";
import { FcFolder } from "react-icons/fc";
import { Link } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { makeStyles } from "@material-ui/core/styles";
import MenuForFolderDialog from "./MenuForFolderDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
    fontSize: 12,
  },
}));
export default function Publieshed({ setLoading }) {
  const { userToken } = useLogin();

  const [folders, setFolders] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    getFolders();
  }, []);
  const getFolders = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllFolders`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);
        console.log("On response arrived after getting folders", res);
        setFolders(res.msg);
      } else {
        setLoading(false);

        console.log("On resp err on getting folders", res.err);
      }
    } catch (error) {
      console.log("On resp err on getting folders", error);
    }
  };
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="left"
        className=" FolderContainer"
      >
        <Grid item className="AddFolderIcon">
          <LPDialog getFolders={getFolders} />
        </Grid>
        {folders &&
          folders.length > 0 &&
          folders.map((folder, index) => (
            <>
              <Grid item className="FolderGridItem" key={index}>
                <Link
                  to={{
                    pathname: `/admin/folder/${folder._id}`,
                    state: { folders, folder },
                  }}
                  // data={{ getFolders: getFolders }}
                >
                  <div className="Icon">
                    <FcFolder fontSize={70} />
                  </div>
                </Link>
                <DialogActions style={{ padding: "0px" }}>
                  <div className={classes.root}>
                    <div>
                      <Tooltip
                        title={folder?.folderName}
                        aria-label={folder?.folderName}
                      >
                        <Typography className="GreyColorTypography folderTypostyle   ml-5px">
                          {folder?.folderName}
                        </Typography>
                      </Tooltip>
                    </div>
                    <MenuForFolderDialog
                      folder={folder}
                      getFolders={getFolders}
                      FolderID={folder._id}
                    />
                  </div>
                </DialogActions>
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
}

import { Pagination } from "antd";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  Select,
  Input,
  MenuItem,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
// React Icon

import { GoSearch } from "react-icons/go";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//core component

export default function ExtendedTables() {
  // context states
  const { userToken, departmentDetail, loadingHandler } = useLogin();
  // context states
  // component states
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [gradeData, setGradeData] = useState([]);
  const [school, setSchool] = React.useState("");
  const [gradeValue, setGradeValue] = useState(null);
  const [searchText, setSearchText] = useState("");
  // component states
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const loadUsers = async (searchValue, pageNum, rowSize) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getRenamedCourses?schoolName=${
          departmentDetail?.departmentName
        }&pagination=true&gradeId=${gradeValue}&search=${
          searchValue ? searchValue : null
        }&page=${pageNum ? pageNum : 1}&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setPosts(res.msg);
        setTotal(res?.lengthData);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  //////////////////////////get grades/////////////////////////////////////////////
  const getAllGrade = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?pagination=true&schoolName=${departmentDetail?.departmentName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setGradeData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  /////////////////////handle search//////////////////////////////////////////////

  useEffect(() => {
    loadUsers();
  }, [gradeValue, departmentDetail]);
  useEffect(() => {
    getAllGrade();
  }, []);

  return (
    <>
      <Grid container className="MT-3 ML-10px">
        <Grid
          container
          item
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography className="BlueColorTypography LPTitle  PL-5">
            {`${departmentDetail?.departmentName} ${departmentDetail?.departmentName} `}
            <FormControl variant="standard" className="ML-20px">
              <Select
                className=" EventDatedropstyle"
                value={gradeValue ? gradeValue : 0}
                defaultValue={0}
                disableUnderline
                onChange={(e) => {
                  setSearchText("");
                  setGradeValue(e.target.value);
                }}
                label="Select grade"
              >
                <MenuItem value={0} className="Eventmenustyle" disabled>
                  Select grade
                </MenuItem>
                {gradeData?.map((a, i) => (
                  <MenuItem key={i} className="Eventmenustyle" value={a?._id}>
                    {a?.grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>

          <Grid item>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Box>
                <div>
                  <FormControl className="">
                    <Input
                      disableUnderline
                      className="input"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setGradeValue(null);
                        setSearchText(e.target.value);
                        loadUsers(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className="HeadSearchButton">
                            <GoSearch className=" HaedAddIcon" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Serach"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="MT-25px">
        <Grid container item md={6} sm={6} xs={6} lg={6}>
          <Typography className="BlueColorTypography LPTitle PL-23px">
            List of Students
          </Typography>
        </Grid>

        {/* <Grid container item md={6} sm={6} xs={6} lg={6} className="">
          <Grid
            container
            item
            md={12}
            sm={12}
            xs={12}
            lg={12}
            style={{ justifyContent: "flex-end" }}
          >
            <Typography className="GreyColorTypography MR-15px  ">
              Sort by
            </Typography>
            <Grid item>
              <FormControl className="MainPageFormControl">
                <Select
                  className=" EventDatedropstyle"
                  value={school ? school : 0}
                  onChange={handleChange}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Eventmenustyle" value={0}>
                    Roll No
                  </MenuItem>
                  <MenuItem className="Eventmenustyle" value={10}>
                    23/12/2021
                  </MenuItem>
                  <MenuItem className="Eventmenustyle" value={20}>
                    24/12/2021
                  </MenuItem>
                  <MenuItem className="Eventmenustyle" value={30}>
                    29/12/2021
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Course Name </TableCell>
              <TableCell className="ColHead"> Description</TableCell>
              <TableCell className="ColHead"> Course Price</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {posts &&
              posts.length > 0 &&
              posts.map((post) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link
                      to={{
                        pathname: `/principal/courseprofile`,
                        state: { post },
                        data: { loadUsers },
                      }}
                    >
                      {" "}
                      {post?.courseUid}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: `/principal/courseprofile`,
                        state: { post },
                        data: { loadUsers },
                      }}
                    >
                      {post?.courseTitle}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">{post.courseDesc}</TableCell>
                  <TableCell className="Col700WAB">
                    {post.coursePrice}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {posts?.length === 0 && (
        <Grid
          container
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography>
            <Typography className="width100 DarkBlackColorTypo">
              No Course Found!
            </Typography>
          </Typography>
        </Grid>
      )}
      {posts?.length > 0 && (
        <div className="paginationStyle">
          <Pagination
            // onChange={(value) => setPage(value)}
            onChange={(value) => {
              loadUsers(null, value);
              setPage(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

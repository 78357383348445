import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Tabs, Tab } from "@material-ui/core";
import { useLocation } from "react-router-dom";

// core component
import Organisation from "./OrganisationDetails";
import Contact from "./ContactDetail";
import Document from "./DocumentDetail";
import Aggrement from "./Aggrement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Schooldetails(props) {
  // State ForTab
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  const { data } = location.state;
  const [schoolDocs, setSchoolDocs] = useState(data?.schoolDocs);
  const [schoolAgreement, setSchoolAgreement] = useState(data?.agreement);

  return (
    <>
      <Box className="SDTBox">
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="TabStyle"
        >
          <Tab
            className="TabStyle"
            label="Orgnasation Detail"
            {...a11yProps(0)}
          />
          <Tab className="TabStyle" label="Contact Detail" {...a11yProps(1)} />
          <Tab className="TabStyle" label="Documents" {...a11yProps(2)} />
          <Tab className="TabStyle" label="Agreement" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Organisation data={data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Contact data={data} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Document
            data={data}
            schoolDocs={schoolDocs}
            setSchoolDocs={setSchoolDocs}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Aggrement
            data={data}
            schoolAgreement={schoolAgreement}
            setSchoolAgreement={setSchoolAgreement}
          />
        </TabPanel>
      </Box>
    </>
  );
}

import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-labels";
//ProgreesBar
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box width="100%" ml={3} mr={1}>
        <LinearProgress
          variant="determinate"
          style={{ color: "#8EBF4D" }}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" style={{ color: "#8EBF4D" }}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});
// ChartJS.register(ArcElement, Tooltip, Legend);

export default function CompletetTab() {
  const options = {
    legend: {
      display: true,
      position: "right",
    },
  };
  const data = {
    datasets: [
      {
        data: [29, 60, 12],
        backgroundColor: [
          "rgb(230, 46, 0)",
          "rgb(102, 153, 255)",
          "rgb(255, 102, 0)",
        ],
        borderWidth: 0,
      },
    ],
  };
  const classes = useStyles();
  const [progress, setProgress] = React.useState(70);

  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid item container className="colorwhite">
          <Grid item container md={2} sm={2} xs={2}>
            <Grid
              item
              container
              className="DailogLTRBStyle"
              style={{ border: "2px solid #C4C4C4", color: "#C4C4C4" }}
            ></Grid>
          </Grid>
          <Grid item container md={7} sm={7} xs={7}>
            <Grid item direction="column" md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                LTP-1
              </Typography>{" "}
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                Learn To Play
              </Typography>
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                1 Hr
              </Typography>
              <Typography className="GreyColorTypography TProgressTypo LTP-1Style">
                Ongoing
              </Typography>
            </Grid>

            <Grid item direction="column" md={4} sm={4} xs={4} align="center">
              <Typography className="GreyColorTypography ProgressTypo">
                PP 500
              </Typography>
            </Grid>
            <Grid item direction="column" md={4} sm={4} xs={4} align="end">
              <Typography className="BlueColorTypography ProgressTypo">
                Assigned
              </Typography>
              <Typography className="GreyColorTypography  ProgressTypo">
                20/9/21
              </Typography>
            </Grid>
            <Grid item container>
              <LinearProgressWithLabel value={progress} />
            </Grid>
          </Grid>
          <Grid item container md={3} sm={3} xs={3}>
            <Grid item md={10} sm={10} xs={10} className="RatingTypo">
              <Typography
                align="center"
                className="BlueColorTypography  QuizTypo  "
              >
                Quizes
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Pie data={data} options={options} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            backgroundColor: "white",
            marginTop: "10px ",
          }}
        >
          <Grid item container md={2} sm={2} xs={2}>
            <Grid
              item
              container
              className="DailogLTRBStyle"
              style={{ border: "2px solid #C4C4C4", color: "#C4C4C4" }}
            ></Grid>
          </Grid>
          <Grid item container md={7} sm={7} xs={7}>
            <Grid item direction="column" md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                LTP-1
              </Typography>{" "}
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                Learn To Play
              </Typography>
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                1 Hr
              </Typography>
              <Typography className="GreyColorTypography TProgressTypo LTP-1Style">
                Ongoing
              </Typography>
            </Grid>

            <Grid item direction="column" md={4} sm={4} xs={4} align="center">
              <Typography className="GreyColorTypography ProgressTypo">
                PP 500
              </Typography>
            </Grid>
            <Grid item direction="column" md={4} sm={4} xs={4} align="end">
              <Typography className="BlueColorTypography ProgressTypo">
                Assigned
              </Typography>
              <Typography className="GreyColorTypography  ProgressTypo">
                20/9/21
              </Typography>
            </Grid>
            <Grid item container>
              <LinearProgressWithLabel value={progress} />
            </Grid>
          </Grid>
          <Grid item container md={3} sm={3} xs={3}>
            <Grid item md={10} sm={10} xs={10} className="RatingTypo">
              <Typography
                align="center"
                className="BlueColorTypography  QuizTypo  "
              >
                Quizes
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Pie data={data} options={options} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            backgroundColor: "white",
            marginTop: "10px ",
          }}
        >
          <Grid item container md={2} sm={2} xs={2}>
            <Grid
              item
              container
              className="DailogLTRBStyle"
              style={{ border: "2px solid #C4C4C4", color: "#C4C4C4" }}
            ></Grid>
          </Grid>
          <Grid item container md={7} sm={7} xs={7}>
            <Grid item direction="column" md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                LTP-1
              </Typography>{" "}
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                Learn To Play
              </Typography>
              <Typography className="BlueColorTypography ProgressTypo LTP-1Style">
                1 Hr
              </Typography>
              <Typography className="GreyColorTypography TProgressTypo LTP-1Style">
                Ongoing
              </Typography>
            </Grid>

            <Grid item direction="column" md={4} sm={4} xs={4} align="center">
              <Typography className="GreyColorTypography ProgressTypo">
                PP 500
              </Typography>
            </Grid>
            <Grid item direction="column" md={4} sm={4} xs={4} align="end">
              <Typography className="BlueColorTypography ProgressTypo">
                Assigned
              </Typography>
              <Typography className="GreyColorTypography  ProgressTypo">
                20/9/21
              </Typography>
            </Grid>
            <Grid item container>
              <LinearProgressWithLabel value={progress} />
            </Grid>
          </Grid>
          <Grid item container md={3} sm={3} xs={3}>
            <Grid item md={10} sm={10} xs={10} className="RatingTypo">
              <Typography
                align="center"
                className="BlueColorTypography  QuizTypo  "
              >
                Quizesv
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Pie data={data} options={options} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

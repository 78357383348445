import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Pagination } from "antd";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function ExtendedTables({ kit }) {
  const [access, setAccess] = useState(1);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [gradeData, setGradeData] = useState(null);
  const { userToken } = useLogin();
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  // console.log(kitDetails, "kitDetails>>>>>in >>grade");
  const equipmentKitId = kit._id;
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const getAllPaginatedGrades = async (equipmentKitId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?equipmentKit=${equipmentKitId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My  All Grade Get response ==>", res);
        setGradeData(res.msg);
      } else {
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  console.log(gradeData, "gradeData>>>>>>>>>>>.hu mai");
  console.log(equipmentKitId, "equipmentKitId>>>>>>>>>>>.hu mai");
  useEffect(() => {
    getAllPaginatedGrades(equipmentKitId);
  }, []);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <div>
        <div className="ResponsiveTableAL">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="ColHeadALBLue">UID </TableCell>
                <TableCell className="ColHeadALBLue">School Name</TableCell>
                <TableCell className="ColHeadALBLue">Location </TableCell>{" "}
                <TableCell className="ColHeadALBLue">Grade </TableCell>
                <TableCell className="ColHeadALBLue"> Allocated On </TableCell>
                <TableCell className="ColHeadALBLue"> Quantity </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {gradeData?.map((gData) => (
                <TableRow>
                  <TableCell className="Col700WAB">{gData.schoolUid}</TableCell>
                  <TableCell className="Col700WAB">
                    {gData.schoolName}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {" "}
                    {gData.location}{" "}
                  </TableCell>{" "}
                  <TableCell className="Col700WAB"> {gData.grade} </TableCell>{" "}
                  <TableCell className="Col700WAB"> 11/1/1998 </TableCell>
                  <TableCell className="Col700WAB"> 40 </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

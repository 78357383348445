import * as React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  DialogContent,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import paddleImg from "assets/img/paddleImg.jpg";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Equipment Details
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog({ equipment }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Link className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        {equipment.equipmentName}{" "}
      </Link>
      <Dialog
        PaperProps={{
          className: "BootstrapReportCardDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle">
            <Grid container item className="PT-10px">
              <Grid item md={3} xs={3} sm={3}>
                <Typography className="GreyColorTypography PL-20px  ">
                  {" "}
                  Name
                </Typography>{" "}
              </Grid>

              <Grid md={9} xs={9} sm={9} item>
                <Typography className="GreenColorTypography">
                  {equipment.equipmentName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid md={3} xs={3} sm={3} item>
                <Typography className="GreyColorTypography PL-20px">
                  Description
                </Typography>{" "}
              </Grid>
              <Grid md={9} xs={9} sm={9} item>
                <Typography className="GreyColorTypography">
                  {equipment.equipmentDesc}{" "}
                </Typography>{" "}
              </Grid>
            </Grid>
            <Grid container item md={12} className="GPCEqupImage ">
              <Grid item xs={6}>
                <img style={{ width: "100%" }} src={equipment.equipmentImg} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

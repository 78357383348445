import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
  DialogContent,
} from "@material-ui/core";
// ReactIcons
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
//core Component

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Delete Page
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const { GetFolder, folderId } = props;
  const [open, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = async () => {
    setOpenDialog(false);
  };
  const DeleteFolder = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/lessonplan/deleteFolder`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ folderId: folderId }),
      });
      const res = await response.json();
      if (response.ok) {
        GetFolder();
        handleClose();
      } else {
        console.log("Check");
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  return (
    <div>
      <IconButton className="publisehd-D-I" onClick={handleClickOpen}>
        <MdOutlineDeleteOutline fontSize={25} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent style={{ margin: "5px 0px" }}>
          <Typography className="GreyColorTypography">
            Are you sure you want to delete this item?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className="GreyButton DeleteCancelB"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="GreenButton  DeleteOkB"
            autoFocus
            onClick={DeleteFolder}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";

import {
  IconButton,
  FormControl,
  Toolbar,
  Box,
  Typography,
  Input,
  InputAdornment,
} from "@material-ui/core";
// React Icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
import { useEffect } from "react";
import LoaderDialog from "components/Loader/LoaderDialog";
// core  component
import UserAdd from "./AddUser/AddUserDialog";
import DepartmentAdd from "./AddDepartment/DepartAdd";
import ComponentTabs from "./ComponentTabs/ComponentTabs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Conversion(props) {
  const [value, setValue] = React.useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const [departMentDetail, setDepartMentDetails] = useState([]);
  const [totalU, setTotalU] = useState(); //
  const [pageU, setPageU] = useState(1);
  const [searchU, setSearchU] = useState("");
  const [totalO, setTotalO] = useState(); //
  const [pageO, setPageO] = useState(1);
  const [searchO, setSearchO] = useState("");
  const [loading, setLoading] = useState(true);
  const { userToken } = useLogin();
  ////////user get request//////////////////////////
  const getUserDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getPaginatedUsers?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of UserDetails==>", res);
        setUserDetails(res);
        setTotalU(res.lengthData);
      } else {
        console.log("Error while fetching UserDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  ////////////////////get department/////////////////
  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getAllDepartments?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        setDepartMentDetails(res);
        setTotalO(res.lengthData);
        setLoading(false);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ///////////////////////////searh users and department///////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    setLoading(true);
    if (value === 0) {
      let url;
      if (searchValue) {
        setPageU(1);
        // getUserDetails();
        url = `${FETCH_URL}/api/auth/getPaginatedUsers?search=${searchValue}`;
      } else {
        url = `${FETCH_URL}/api/auth/getPaginatedUsers?pagination=true&page=${pageNum}&limit=${rowSize}`;
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLoading(false);
        setUserDetails(res);

        console.log("My searching users response ==>", res);
      } else {
        setLoading(false);

        console.log("Error while searching users", res.err);
      }
    } else {
      let url;
      if (searchValue) {
        setPageO(1);
        // getDepartmentDetails();
        url = `${FETCH_URL}/api/auth/getAllDepartments?search=${searchValue}`;
      } else {
        url = `${FETCH_URL}/api/auth/getAllDepartments?pagination=true&page=${pageNum}&limit=${rowSize}`;
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setDepartMentDetails(res);
        setLoading(false);

        console.log("My searching users response ==>", res);
      } else {
        setLoading(false);

        console.log("Error while searching users", res.err);
      }
    }
  };
  ///////////////////////////searh users and department///////////////////////
  useEffect(() => {
    getUserDetails();
    getDepartmentDetails();
  }, []);

  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Users</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                {value == 0 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); //
                      setSearchU(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); //
                      setSearchO(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                )}
              </FormControl>
            </div>
          </Box>
          {value == 0 ? (
            <UserAdd getUserDetails={getUserDetails} />
          ) : (
            <DepartmentAdd getDepartmentDetails={getDepartmentDetails} />
          )}
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <ComponentTabs
        states={{
          value,
          setValue,
          userDetails,
          setUserDetails,
          departMentDetail,
          setDepartMentDetails,
          searchU,
          setSearchU,
          pageU,
          setPageU,
          totalU,
          setTotalU,
          searchO,
          setSearchO,
          pageO,
          setPageO,
          totalO,
          setTotalO,
        }}
        getUserDetails={getUserDetails}
        getDepartmentDetails={getDepartmentDetails}
        handleSearch={handleSearch}
      />
    </>
  );
}

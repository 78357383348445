import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Switch,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";

// React Icons
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
//core Component
import OrgnisationTab from "./OrgCompoTab";
import UserTab from "./UserCompoTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  userDetails,
  getUserDetails,
  departMentDetail,
  setDepartMentDetails,
  value,
  setValue,
}) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
        >
          <Tab className="ActivityTabChanges" label="Users" {...a11yProps(0)} />
          <Tab
            className="ActivityTabChanges"
            label="Orgnasation"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <UserTab userDetails={userDetails} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrgnisationTab departMentDetail={departMentDetail} />
      </TabPanel>
    </>
  );
}

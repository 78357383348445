import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  Input,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import EditDialog from "./SchoolActionDialog.js/EditDialog/EditDialog.js";
import AddDialog from "./SchoolAddDialog/AddDialog";
import { schoolDeleted, tableData } from "redux/action/TableAction";
import { useLogin } from "Context/ExportContext";
import GradeDialog from "./SchoolActionDialog.js/GradeDialog";
import SwitchDialog from "./SchoolActionDialog.js/SwitchDialog";
import { FETCH_URL } from "../../../fetchIp";
// @material-ui icons
import { GoSearch } from "react-icons/go";
import { BiSort } from "react-icons/bi";
import LoaderDialog from "components/Loader/LoaderDialog.js";
import Search from "antd/lib/transfer/search.js";
import DeleteDialog from "./SchoolActionDialog.js/DeleteDialog.js";
export default function SchoolTables() {
  const { userToken } = useLogin();
  //Pagination
  const [posts, setPosts] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRowData] = useState(null);
  const [serachData, setSerachData] = useState(null);
  const [loading, setLoading] = useState(true);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return orignalElement;
  };
  //Redux Implemnet
  const dispatch = useDispatch();
  const Row = useSelector((state) => state.tableData.table);
  const Deleted = useSelector((state) => state.tableData.schoolDeleted);

  const fetchUserDetails = useCallback(async () => {
    try {
      await dispatch(tableData(userToken));
    } catch (e) {}
  }, []);

  // API Calling
  const getAllSchool = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getAllSchools?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of getAllSchool==>", res);
        setRowData(res.msg);
        setTotal(res.lengthData);
      } else {
        console.log("Error while fetching single school", res.err);
      }
      setLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
      setLoading(false);
    }
  };

  // For Search API
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    setLoading(true);

    let url;
    if (searchValue) {
      setPageNo(1);
      setRowsPerPage(10);
      url = `${FETCH_URL}/api/school/getAllSchools?search=${searchValue}`;
    } else {
      url = `${FETCH_URL}/api/school/getAllSchools?pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    }
    const response = await fetch(
      // `${FETCH_URL}/api/school/getAllSchools?search=${searchValue}&pagination=true&page=${
      //   pageNum ? pageNum : 1
      // }&limit=${rowSize ? rowSize : 10}`,
      // `${FETCH_URL}/api/school/getAllSchools?search=${searchValue}&pagination=true&page=${
      //   pageNum ? pageNum : 1
      // }&limit=${rowSize ? rowSize : 10}`,
      url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLoading(false);

      setRowData(res.msg);

      console.log("myData", res.msg);
      console.log("url", url);
      console.log("serachData", serachData);
      console.log("searchValue", searchValue);
    } else {
      setLoading(false);
    }
  };

  const [sort, setSort] = useState(true);

  const sortValue = !sort ? "asc" : "desc";

  console.log("Check filter data", sortValue);

  const sortingSchool = async (sortValue) => {
    const response = await fetch(
      `${FETCH_URL}/api/school/getAllSchools?sort=organisationDetails.schoolName&sortOrder=${sortValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My  response from sorting School ==>", res);
      // getAllGrades();
    } else {
      //.log("Error while searching single school", res.err);
    }
  };

  const sortingLocation = async (sortValue) => {
    const response = await fetch(
      `${FETCH_URL}/api/school/getAllSchools?sort=organisationDetails.location&sortOrder=${sortValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);

      console.log("My  response from sorting location name==>", res);
      // getAllGrades();
    } else {
      //.log("Error while searching single school", res.err);
    }
  };

  useEffect(() => {
    getAllSchool();
  }, []);
  console.log("ser", serachData);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">
          Manage Schools
          {/* {rowData && rowData.length > 0
            ? rowData[0].organisationDetails.schoolName
            : "N/A"}{" "} */}
        </Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  value={serachData}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSerachData(e.target.value);
                  }}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          <AddDialog getAllSchool={getAllSchool} />
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID </TableCell>
              <TableCell className="ColHead">
                School Name
                <IconButton disableRipple style={{ height: "10px" }}>
                  <BiSort
                    onClick={() => {
                      sortingSchool(sortValue);
                      setSort(!sort);
                    }}
                    color="#FFF"
                    size=".8em"
                  />
                </IconButton>
              </TableCell>
              <TableCell className="ColHead">
                Location
                <IconButton disableRipple style={{ height: "10px" }}>
                  <BiSort
                    onClick={() => {
                      sortingLocation(sortValue);
                      setSort(!sort);
                    }}
                    color="#FFF"
                    size=".8em"
                  />
                </IconButton>
              </TableCell>
              <TableCell className="ColHead">Contact </TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData &&
              rowData.length > 0 &&
              rowData.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="Col">{data.schoolUid}</TableCell>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: "/admin/schooldetails",
                          state: { data },
                          data: { fetchData: fetchUserDetails },
                        }}
                      >
                        {data.organisationDetails.schoolName}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      {data.organisationDetails.location}
                    </TableCell>
                    <TableCell className="Col">
                      {data.contactDetail.representativeMobilePhoneNo}
                    </TableCell>
                    <TableCell className="Action">
                      <EditDialog
                        data={data}
                        handleSearch={handleSearch}
                        getAllSchool={getAllSchool}
                      />
                      <GradeDialog
                        getAllSchool={getAllSchool}
                        data={data}
                        fetchUserDetails={fetchUserDetails}
                      />
                      {/* <SwitchDialog
                        getAllSchool={getAllSchool}
                        handleSearch={handleSearch}
                        Row={Row}
                        access={data.access}
                        schoolId={data._id}
                      /> */}
                      <DeleteDialog schoolId={data._id} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {rowData?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No School Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {serachData || rowData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              // console.log(value, "hey i am value");
              setPageNo(value);
              handleSearch("", value, value2);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={pageNo}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

// core components
import EquipmentTab from "./GEquipmentTabs";

export default function Equipment() {
  return (
    <>
      <EquipmentTab />
    </>
  );
}

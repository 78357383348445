import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  Grid,
  MenuItem,
  IconButton,
  Toolbar,
  Box,
  Typography,
  Input,
  InputAdornment,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Select,
  FormControl,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import LoaderDialog from "components/Loader/LoaderDialog";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
// // material-ui icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";

// core components
import AddGrade from "./AddDialog/AddGrade";
import EditDialog from "./ActionDialog/EditDialog";
import DeleteDialog from "./ActionDialog/DeleteDialog";
import SwitchDialog from "./ActionDialog/SwitchDialog";
import CourseDialog from "./ActionDialog/CourseDialog/CourseDialogMain";
import LessonPlan from "./ActionDialog/GLessonPlanDialog/LPDialogMain";
import SkillDialog from "./ActionDialog/GSkillDialog";
import GEquipment from "./ActionDialog/GEquipmentDialog";
import { useParams } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { schoolDeleted, tableData } from "redux/action/TableAction";
import UploadExcel from "./UploadExcel/AddGradeExcel";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ExtendedTables({ handleSearch }) {
  const { userToken } = useLogin();
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [serachData, setSerachData] = useState(null);

  // Open states for addGrade:

  const [open, setOpen] = React.useState(false);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  //Redux Implemnet

  const Row = useSelector((state) => state.tableData.table);
  const Deleted = useSelector((state) => state.tableData.schoolDeleted);
  const history = useHistory();
  const { id } = useParams();

  // Used states
  const [schoolUid, setSchoolUid] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [schoolLocation, setSchoolLocation] = useState(null);
  const [gradeName, setGradeName] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [gradeData, setGradeData] = useState(null);
  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log("ia m here");
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  //  All API Calling
  // For DropDown

  /////////////////////getDistinctSchoolAndLocation///////////////////////////

  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          //.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          console.log("sccccccc", res.msg);
          setSchoolNames(res.msg);
        }
      } else {
        //.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  /////////////////////getDistinctSchoolAndLocation///////////////////////////

  ////////////////////////getDropDownSchools/////////////////////////////////
  const getDropDownSchools = async (location) => {
    try {
      setLoading(true);
      setPageNo(1);
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?pagination=true&schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      setLoading(false);

      if (response.ok) {
        //.log("My DropDown schools ============>", res);
        setGradeData(res.msg);
        setTotal(res.lengthData);
      } else {
        //.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  ////////////////////////getDropDownSchools/////////////////////////////////
  const getFilterGradeBySchool = async (school) => {
    try {
      setLoading(true);
      setPageNo(1);
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?pagination=true&schoolName=${school}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      setLoading(false);

      if (response.ok) {
        //.log("My DropDown schools ============>", res);
        setGradeData(res.msg);
        setTotal(res.lengthData);
      } else {
        //.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  ///////////////////////////GradeSearch//////////////////////////////////////
  const GradeSearch = async (searchValue, pageNum, rowSize) => {
    setLoading(true);
    let url;
    if (searchValue) {
      setPageNo(1);
      setRowsPerPage(10);
      setSchoolSelected("");
      setSchoolLocationSelected("");
      url = `${FETCH_URL}/api/school/grade/getFilteredGrades?search=${searchValue}`;
    } else {
      url = `${FETCH_URL}/api/school/grade/getFilteredGrades?schoolName=${schoolSelected}&location=${schoolLocationSelected}&pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}&search=${searchValue}`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const res = await response.json();
    if (response.ok) {
      setLoading(false);

      setGradeData(res.msg);
      console.log("myData", res.msg);
      console.log("url", url);
      console.log("serachData", serachData);
      console.log("searchValue", searchValue);
    } else {
      setGradeData([]);
      setLoading(false);

      //.log("Error while searching single school", res.err);
    }
  };
  ///////////////////////////GradeSearch//////////////////////////////////////

  /////////////////////////For Grade Details//////////////////////////////////
  const getAllGrades = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My  All Grade Get response ==>", res);
        setGradeData(res.msg);
        setTotal(res.lengthData);
        setLoading(false);
      } else {
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  /////////////////////////For Grade Details//////////////////////////////////
  /////////////////////// For GetSchool Details////////////////////////////////
  const getSchoolDetails = async (schoolUId) => {
    console.log(schoolUId, "i am schoolUId");
    if (!schoolUId) {
      setSchoolName("");
      setSchoolLocation("");
      setSchoolId("");
      return;
    }
    setSchoolUid(schoolUId);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getSingleSchool/${schoolUId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My Grade Get response ==>", res);
        setSchoolName(res.msg.organisationDetails.schoolName);
        setSchoolLocation(res.msg.organisationDetails.location);
        setSchoolId(res.msg._id);
      } else {
        //.log("Error while fetching single school", res.err);
        setSchoolName("");
        setSchoolLocation("");
        setSchoolId("");
      }
    } catch (e) {
      //.log("Catch block error =>", e);
    }
  };
  /////////////////////// For GetSchool Details////////////////////////////////
  const submitClick = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${FETCH_URL}/api/school/grade/addGrade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          schoolId,
          grade: gradeName,
          location: schoolLocation,
          schoolName,
          schoolUid,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        // //.log("My Grade  Post response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllGrades();
        setOpen(false);
        setSchoolUid("");
        setSchoolName("");
        setSchoolLocation("");
        setSchoolId("");
        setGradeName("");
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        // //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  const deleteGrade = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/deleteGrade/${gradeId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My Grade Delete response ==>", res);
        getAllGrades();
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        //.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  const changeGradeStatus = async (gradeId, access) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/changeGradeStatus/${gradeId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ access: access === 0 ? 1 : 0 }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Grade status response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllGrades();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  const editGrade = async (gradeId, schoolUid, setOpen) => {
    if (!gradeName) {
      alert("No changes were made to grade");
      return;
    }
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/editGrade/${gradeId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            grade: gradeName,
            schoolUid,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        // getAllGrades();
        setOpen(false);
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  // Learner API Calling

  useEffect(() => {
    getAllGrades();
    getDistinctSchoolAndLocation(); // for dropdowns
  }, []);

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Grade</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  value={serachData}
                  onChange={(e) => {
                    GradeSearch(e.target.value);
                    setSerachData(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          <AddGrade
            schoolUid={schoolUid}
            setSchoolUid={setSchoolUid}
            schoolName={schoolName}
            setSchoolName={setSchoolName}
            schoolLocation={schoolLocation}
            setSchoolLocation={setSchoolLocation}
            gradeName={gradeName}
            setGradeName={setGradeName}
            getSchoolDetails={getSchoolDetails}
            submitClick={submitClick}
            gradeData={gradeData}
            setOpen={setOpen}
            open={open}
          />
          <UploadExcel getAllGrades={getAllGrades} />
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid container>
        <Grid item xs={3}>
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              label="Select School"
              color="white"
              disableUnderline
              defaultValue={0}
              onChange={(e) => {
                console.log("state", e.target.value);
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
                getFilterGradeBySchool(e.target.value);
                setSchoolLocationSelected("");
                if (e.target.value == 0) {
                  getAllGrades();
                  setSchoolLocationSelected("");
                  setSchoolLoc([]);
                }
              }}
            >
              <MenuItem value={0} className="Selectmenustyle">
                All School
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="standard" className="MainPageFCIG">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getDropDownSchools(e.target.value);
              }}
              label="Select Location"
              disableUnderline
              defaultValue={0}
            >
              <MenuItem value={0} className="Selectmenustyle" disabled>
                Select a location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">Grade</TableCell>
              <TableCell className="ColHead">School Name </TableCell>
              <TableCell className="ColHead"> Location</TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {gradeData &&
              gradeData.length > 0 &&
              gradeData.map((data, index) => (
                <TableRow ikey={index}>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: `/admin/gradeprofile/${data._id}`,
                        state: {
                          data,
                          rowData,
                        },
                        data: {
                          setGradeName: { setGradeName },

                          editGrade: { editGrade },
                          getAllGrades: { getAllGrades },
                        },
                      }}
                    >
                      {data.grade}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">{data.schoolName}</TableCell>
                  <TableCell className="Col"> {data.location}</TableCell>
                  <TableCell className="Action">
                    <EditDialog
                      GradeSearch={getAllGrades}
                      data={data}
                      setGradeName={setGradeName}
                      editGrade={editGrade}
                      setSnackOpen={setSnackOpen}
                      setSnackMsg={setSnackMsg}
                      setSnackerropen={setSnackerropen}
                      setSnackErrMsg={setSnackErrMsg}
                    />
                    <CourseDialog
                      data={data}
                      gradeId={data._id}
                      setSnackOpen={setSnackOpen}
                      setSnackMsg={setSnackMsg}
                      setSnackerropen={setSnackerropen}
                      setSnackErrMsg={setSnackErrMsg}
                    />
                    <LessonPlan
                      data={data}
                      setSnackOpen={setSnackOpen}
                      setSnackMsg={setSnackMsg}
                      setSnackerropen={setSnackerropen}
                      setSnackErrMsg={setSnackErrMsg}
                    />
                    <SkillDialog
                      data={data}
                      gradeData={data}
                      setSnackOpen={setSnackOpen}
                      setSnackMsg={setSnackMsg}
                      setSnackerropen={setSnackerropen}
                      setSnackErrMsg={setSnackErrMsg}
                    />
                    <GEquipment
                      data={data}
                      setSnackOpen={setSnackOpen}
                      setSnackMsg={setSnackMsg}
                      setSnackerropen={setSnackerropen}
                      setSnackErrMsg={setSnackErrMsg}
                    />
                    {/* <SwitchDialog
                      changeGradeStatus={changeGradeStatus}
                      setSnackOpen={setSnackOpen}
                      setSnackMsg={setSnackMsg}
                      setSnackerropen={setSnackerropen}
                      setSnackErrMsg={setSnackErrMsg}
                      gradeId={data._id}
                      access={data.access}
                    /> */}
                    {/* <DeleteDialog deleteGrade={deleteGrade} data={data._id} /> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {gradeData?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Grade Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {serachData || gradeData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, pageSize) => {
              GradeSearch("", value, pageSize);
              setPageNo(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={pageNo}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { BsBook } from "react-icons/bs";

//core Module
import SelectCourse from "./TCourseTab/TSelectCourse";
import RenameTab from "./TCourseTab/TRenameTab";

///////////////////////////////////////////////////
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
/////////////////////////////////////////////////
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CommonPagination from "components/CommonPagination/CommonPagination";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ trainerId }) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const [course, setAllCourses] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [originalData, setOriginalData] = React.useState([]);
  //////////////////////////////////////////////
  const [courseUid, setCourseUid] = React.useState("");
  const [courseTitle, setCourseTitle] = React.useState("");
  const [courseStartDate, setCourseStartDate] = React.useState(null);
  const [courseEndate, setCourseEndDate] = React.useState(null);
  /////////////////////////////////
  const [selectCourseText, setSelectCourseText] = React.useState("");
  const [courseUidText, setCourseUidText] = React.useState("");
  const [courseRenameText, setCourseRenameText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [courseId, setCourseId] = React.useState("");
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  function getDataFromPagination(a) {
    setPage(a);
  }
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const { userToken } = useLogin();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setCourseId("");

    setCourseUid("");
    setCourseTitle("");
    setSelectCourseText("");
    setCourseUidText("");
    setCourseRenameText("");
    setCourseStartDate(null);
    setCourseEndDate(null);
    setValue(0);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    // if (filterdata.length === 0) {
    //   setSelectCourseText("please select a course!");
    //   return;
    // }
    if (!courseId) {
      setSelectCourseText("please select a course!");
      return;
    }
    setSelectCourseText("");
    setCourseUidText("");
    setCourseRenameText("");
    setValue(newValue);
  };
  function TabNext() {
    // if (filterdata.length === 0) {
    //   setSelectCourseText("please select a course!");
    //   return;
    // }
    if (!courseId) {
      setSelectCourseText("please select a course!");
      return;
    }
    setSelectCourseText("");
    setCourseUidText("");
    setCourseRenameText("");
    setValue(1);
  }
  ////////////////////////////////////////////////////////////////

  function storeCourse(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  console.log(
    filterdata.map((a) => a._id),
    "originalData>>12>>>12>>>"
  );
  // const courseId = filterdata.map((a) => a._id);
  // console.log(trainerId, "trainerId");

  /////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////

  const getAllCourses = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getAllCourses?pagination=true&limit=10&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of all courses==>", res.msg);
        setTotal(res?.lengthData);
        setAllCourses(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  React.useEffect(() => {
    getAllCourses();
  }, [page]);
  // console.log(course, "course>>>>>>>>>>>>>>>>>>>>>is in tha house");

  /////////////////////////////////////////////////////////////////////
  const submitClick = async (e) => {
    e.preventDefault();
    if (
      courseUid === null ||
      courseUid.length === 0 ||
      (courseUid === undefined && courseTitle === null) ||
      courseTitle === undefined ||
      courseTitle.length === 0
    ) {
      if (courseUid.length === 0) {
        setCourseUidText("please enter course uid !");
      }

      if (courseTitle.length === 0) {
        setCourseRenameText("please enter course title !");
      }
      return;
    }
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/assignCourseToTrainerFromTrainer/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            trainerId,
            courseUid: courseUid,
            courseTitle: courseTitle,
            // courseDuration: [courseStartDate, courseEndate],
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My assign course to trainer ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // const submitClick = async (e) => {
  //   console.log("StudentUID----------");
  //   e.preventDefault();
  // };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Course">
        <IconButton className="ActionButtonSize">
          <BsBook className="ActionButtonIcon" onClick={handleClickOpen} />{" "}
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Select Course" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectCourse
              states={{ course, selectCourseText, courseId, setCourseId }}
            />
            <CommonPagination
              getDataFromPagination={getDataFromPagination}
              total={total}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenameTab
              states={{
                courseUid,
                setCourseUid,
                courseTitle,
                setCourseTitle,
                courseStartDate,
                setCourseStartDate,
                courseEndate,
                setCourseEndDate,
                courseUidText,
                courseRenameText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

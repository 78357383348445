import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LocoRubicsEdit from "./EditRubricsActionDialog/LocoRubicsEdit";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import axios from "axios";
import { useLocation } from "react-router-dom";

//core component
import DeleteDialog from "../SPLocoTab/DeleteRubrics/DeleteRubrics";
import SwitchDailog from "../../ActionButtonDialog's/LocomotorActionB/SwitchLocoDialog";
import EditDialog from "../../ActionButtonDialog's/LocomotorActionB/EdiLocomotor/LocoEditMain";
// import AddProfile from "./AddSkillProfile/SkillAdd";

import AddRubricsDialog from "./AddRubrics/AddRubricsTab";
export default function SubSkills(props) {
  const [open, setOpen] = React.useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(); //
  const location = useLocation();
  const { getAllSkill } = location.data;

  const {
    states: { rubricsQts, setRubricsQts, skills, skillsData, searchSkill },
  } = props;
  const rubricsQus = [];
  rubricsQus.push(skills);

  console.log(rubricsQus, "i am rubrics");
  return (
    <>
      <Grid container>
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          item
          className="SkillRubDialofGrid"
        >
          <Grid
            container
            md={12}
            sm={12}
            xs={12}
            lg={12}
            item
            className="DiviStyle "
          >
            <Typography></Typography>

            <AddRubricsDialog
              rubricsQus={rubricsQus}
              skills={skills}
              searchSkill={searchSkill}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className="LargeDialogLRBStyle">
        <div
          className="ResponsiveTableHaedBlack"
          style={{ marginRight: "22px" }}
        >
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="ColHead">Q.no</TableCell>
                <TableCell className="ColHead">Question </TableCell>
                <TableCell className="ColHead"> Total Marks</TableCell>
                <TableCell className="ColHead"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rubricsQus.map((rbsqs) =>
                rbsqs.rubricsQts.map((qs) => (
                  <TableRow>
                    <TableCell className="Col">{qs.quesNo}</TableCell>
                    <TableCell className="Col">{qs.quesDesc}</TableCell>
                    <TableCell className="Col">{qs.quesMarks}</TableCell>
                    <TableCell className="Action">
                      <IconButton className="ActionButtonSize">
                        <LocoRubicsEdit
                          states={{
                            rubricsQts,
                            setRubricsQts,
                            qs,
                          }}
                          searchSkill={searchSkill}
                          skills={skills}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <DeleteDialog
                          qs={qs}
                          skills={skills}
                          searchSkill={searchSkill}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>{" "}
      </div>
    </>
  );
}

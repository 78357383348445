import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";

export default function AssesmentTab({ assesmentResult }) {
  return (
    <>
      <Grid container style={{ minHeight: "70vh" }}>
        <div className="ResponsiveTable width100">
          <Table className="Tableroot">
            <TableHead>
              <TableCell className="Head"> UID </TableCell>
              <TableCell className="Head">Assessment’s Name </TableCell>
              <TableCell className="Head">Assigned PP</TableCell>{" "}
              <TableCell className="Head">Scored PP</TableCell>
              <TableCell className="Head"> Date</TableCell>
            </TableHead>

            <TableBody>
              {assesmentResult &&
                assesmentResult?.length > 0 &&
                assesmentResult?.map((Data) => {
                  return (
                    <TableRow>
                      <TableCell className="Col">
                        {Data?.assessmentId?.uid}
                      </TableCell>
                      <TableCell className="Col">
                        {Data?.assessmentId?.assessmentName}
                      </TableCell>
                      <TableCell className="Col ActionButtonSize">
                        {/* {Data?.assessmentId?.assignedXp} */}
                        {Data?.assessmentId?.assignedXp}
                      </TableCell>
                      <TableCell className="Col ActionButtonSize">
                        {Data?.totalScore}
                      </TableCell>{" "}
                      <TableCell className="Col">
                        {Data?.createdAt?.split("T")[0]}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {assesmentResult?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Assesment Assign!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

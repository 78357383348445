import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

export default function LgSkill({ assesmentResult }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = assesmentResult?.map((data) => {
    return data?.assessmentId?.uid;
  });
  const assesmentResultPP = assesmentResult?.map((data) => {
    return {
      data: data?.totalScore,
    };
  });

  const dataScore = assesmentResultPP?.map((data) => {
    return data.data;
  });

  const data = {
    labels,
    datasets: [
      {
        label: "PlayPoint",
        data: dataScore,
        backgroundColor: "rgb(71, 209, 71)",
        borderRadius: 20,
        barThickness: 24,
        stack: "Stack 0",
      },
    ],
  };

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94 colorwhite">
        {/* <Grid
          item
          container
          className="LGSkillsTab"
          md={2}
          sm={2}
          xs={2}
          lg={2}
        >
          <FormControl className="MainPageFormControl">
            <Select
              className=" SkillSelectdropstyle"
              value={school ? school : 0}
              onChange={handleChange}
              label="Monthly"
              color="white"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Monthly
              </MenuItem>

              <MenuItem className="Selectmenustyle" value={10}>
                Yearly
              </MenuItem>
              <MenuItem className="Selectmenustyle" value={20}>
                Last 7 Days{" "}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid
          item
          container
          md={10}
          sm={10}
          xs={10}
          lg={10}
          style={{ marginLeft: "100px" }}
        >
          <Bar options={options} data={data} />
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Typography, styled, Box, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Organisation from "./OrganisationDetails";
import Contact from "./ContactDetail";
import Document from "./DocumentDetail";
import Aggrement from "./Aggrement";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TabPanelStyle = styled(TabPanel)(() => ({
  "& .MuiBox-root": {
    padding: "2px",
  },
}));
const TabStyle = withStyles((theme) => ({
  root: {
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#5FA12F",
      fontWeight: theme.typography.fontWeightMedium,
      borderBottom: "2px solid #5FA12F",
    },
    "& .MuiButtonBase-root-MuiTab-root.Mui-selected": {
      textColor: "#5FA12F",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default function Schooldetails() {
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: "97%",
          backgroundColor: "white",
          marginLeft: "20px",
          height: "39px",
          marginTop: "11px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="grey"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          style={{
            color: "grey",
          }}
        >
          <TabStyle
            style={{
              marginRight: "20px",
              textTransform: "none",
              minHeight: "10px",
              maxHeight: "38px",
            }}
            label="Orgnasation Detail"
            {...a11yProps(0)}
          />
          <TabStyle
            style={{
              marginRight: "20px",
              textTransform: "none",
              minHeight: "10px",
              maxHeight: "38px",
            }}
            label="Contact Detail"
            {...a11yProps(1)}
          />
          <TabStyle
            style={{
              marginRight: "20px",
              textTransform: "none",
              minHeight: "10px",
              maxHeight: "38px",
            }}
            label="Documents"
            {...a11yProps(2)}
          />
          <TabStyle
            style={{
              marginRight: "20px",
              textTransform: "none",
              minHeight: "10px",
              maxHeight: "38px",
            }}
            label="Aggrement"
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanelStyle value={value} index={0}>
          <Organisation />
        </TabPanelStyle>
        <TabPanelStyle value={value} index={1}>
          <Contact />
        </TabPanelStyle>
        <TabPanelStyle value={value} index={2}>
          <Document />
        </TabPanelStyle>
        <TabPanelStyle value={value} index={3}>
          {" "}
          <Aggrement />
        </TabPanelStyle>
      </Box>
    </>
  );
}

import React from "react";
import {
  Typography,
  Grid,
  Tabs,
  Button,
  Tab,
  Box,
  DialogContent,
} from "@material-ui/core";
import PropTypes from "prop-types";

//core comonenet '
import SkillsTab from "./TPCourseTab";
import CourseTab from "./TPLessonPlansTab";
import LessonPlanTab from "./GPSkillTab";
import ManImage from "../../../../../../assets/img/Man.png";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AcadmicTab(props) {
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const { TrainerId, trainer } = props;
  console.log(trainer, "trainer>>>>>>>>>>>>>>>>>>>>>.");
  return (
    <>
      <Grid container>
        <Grid
          container
          item
          direction="column"
          md={2}
          sm={2}
          xs={2}
          lg={2}
          style={{
            backgroundColor: "#ffffff",
            marginTop: "5px",
          }}
        >
          <Grid
            container
            item
            className="DetailsTypo-3"
            style={{ marginTop: "24px " }}
          >
            <img
              className="GTSidebarImg"
              src={trainer.trainerDetail.trainerImg}
            />
          </Grid>
          <Typography align="center" className="BlueColorTypography">
            {trainer.trainerDetail.fullName}
          </Typography>
          <Typography align="center" className="BlueColorTypography">
            Principal
          </Typography>
          <Typography align="center" className="GreenColorTypography">
            Active
          </Typography>
          <Typography align="center" className="BlueColorTypography">
            enrollment Date :{" "}
            <span className="GreenColorTypography">
              {trainer.trainerDetail.enrollmentDate.split("T")[0]}{" "}
            </span>
          </Typography>
          <Grid container item style={{ marginTop: "15px" }}>
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <AiOutlineMail className=" ActionButtonIcon GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography className="BlackColorTypography  GtSideGridStyle  ">
                {trainer.contactDetail.trainerEmail}
              </Typography>
            </Grid>
          </Grid>{" "}
          <Grid container item style={{ marginTop: "15px" }}>
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <MdPhone className="  ActionButtonIcon GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography a className="BlackColorTypography   ">
                {trainer.contactDetail.trainerPhoneNo}
              </Typography>
            </Grid>
          </Grid>{" "}
          <Grid container item style={{ marginTop: "15px" }}>
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <MdLocationPin className="   ActionButtonIcon  GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography className="BlackColorTypography   ">
                {trainer.contactDetail.trainerLocation}
              </Typography>
            </Grid>
          </Grid>{" "}
          <Grid container item className=" DetailsTypo-3 GtSideGridRating">
            <Typography
              className="BlueColorTypography  GTrainerSideTypo"
              align="center"
            >
              Avg Grade rating
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={10} sm={10} xs={10} lg={10}>
          <Grid container item className="GridStyle-2">
            <Box style={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={TabChange}
                centered
                aria-label="basic tabs example"
              >
                <Tab className="TabStyle" label="Courses" {...a11yProps(0)} />
                <Tab
                  className="TabStyle"
                  label="Lesson Plan "
                  {...a11yProps(1)}
                />
                <Tab className="TabStyle" label="Skills" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <DialogContent dividers={false} className="TabPanelStyle">
              <TabPanel value={value} index={0}>
                <SkillsTab />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CourseTab />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <LessonPlanTab />
              </TabPanel>
            </DialogContent>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";

//core component
import KitDeleteDialog from "../ActionDialog/KitActionDialog's/KitDeleteDialog";
import KitSwitchDailog from "../ActionDialog/KitActionDialog's/KitSwitchDialog";
import KitEditDialog from "../ActionDialog/KitActionDialog's/KitEdit";
import AssignGrade from "../ActionDialog/KitActionDialog's/KAssignGradeDialog";
import AssignDivision from "../ActionDialog/KitActionDialog's/KAssignDivisionDialog";
import AssignLearner from "../ActionDialog/KitActionDialog's/KAssignLearner";
import AssignSchool from "../ActionDialog/KitActionDialog's/kAssignSchool";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function RenameTab(props) {
  const { userToken } = useLogin();

  const [value, setValue] = React.useState(0);
  const [access, setAccess] = useState(1);
  // const [total, setTotal] = useState();
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPageEK(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    states: {
      equipmentKit,
      equipmentData,
      schoolSelected,
      totalEquipmentData,
      searchDataEK,
      pageNoEK,
      setPageNoEK,
      totalEK,
      setTotalEK,
      rowsPerPageEK,
      setRowsPerPageEK,
    },
    getAllEquipmentKIT,
    handleSearchEK,
  } = props;

  const deleteKit = async (KitId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/deleteEquipmentsKit/${KitId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response ==>", res);
        getAllEquipmentKIT();
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID</TableCell>
              <TableCell className="Head">Equipment KIT Name </TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {equipmentKit &&
              equipmentKit.length > 0 &&
              equipmentKit.map((kit) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {kit.equipmentKitUid}</Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: `/admin/equipmentkitprofile/${kit._id} `,
                        state: { kit },
                      }}
                    >
                      {kit.equipmentKitName}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {kit.equipmentKitDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Action">
                    <KitEditDialog
                      kit={kit}
                      equipmentKit={equipmentKit}
                      getAllEquipmentKIT={getAllEquipmentKIT}
                      equipmentData={totalEquipmentData}
                    />
                    <IconButton className="ActionButtonSize">
                      <AssignGrade kitId={kit._id} />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <AssignDivision kitId={kit._id} />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <AssignLearner kit={kit} kitId={kit._id} />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <AssignSchool kit={kit} kitId={kit._id} />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <KitSwitchDailog
                        kitId={kit._id}
                        access={kit.access}
                        getAllEquipmentKIT={getAllEquipmentKIT}
                      />
                    </IconButton> */}
                    {/* <KitDeleteDialog deleteKit={deleteKit} KitId={kit._id} /> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {equipmentKit?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Equipment Kit Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected || searchDataEK || equipmentKit?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              handleSearchEK(value, value2);
              setPageNoEK(value);
            }}
            pageSize={rowsPerPageEK}
            total={totalEK}
            current={pageNoEK}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

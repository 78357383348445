import * as React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
//core component
import ManImage from "assets/img/Man.png";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="LPDialogTitleReport" {...other}>
      <Grid container>
        <Grid container item md={12} sm={12} xs={12}>
          <Grid container item md={1} sm={1} xs={1} justify="flex-end">
            <img className="LPReportImg" src={ManImage} />
            <Typography className="BlueColorTypography"> 11405079 </Typography>
          </Grid>
          <Grid
            container
            item
            md={3}
            sm={3}
            xs={3}
            lg={3}
            className="UserGridStyle"
            direction="column"
          >
            <Typography className="BlueColorTypography UserNameTypo">
              Abhisekh Sharma
            </Typography>
            <Typography className="GreyColorTypography ">A01</Typography>
            <Typography className="GreyColorTypography ">Grade 1</Typography>
            <Typography className="GreyColorTypography ">
              Zoomer Public School
            </Typography>
            <Typography className="GreyColorTypography ">Pune</Typography>
          </Grid>
          <Grid
            container
            item
            md={4}
            sm={4}
            xs={4}
            lg={4}
            direction="column"
            className="LTPCenterGridStyle"
          >
            <Typography className="BlueColorTypography RCLearnerNameTypo">
              LTP-1
            </Typography>
            <Typography className="BlueColorTypography RCLearnerNameTypo">
              LEARN TO PLAY
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={3}
            sm={3}
            xs={3}
            lg={3}
            direction="column"
            className="DownLGridStyle"
          >
            <Typography className="GreyColorTypography AttempTypo">
              Attempted on : 19/10/21
            </Typography>
            <Typography className="WhiteColorTypography DownLTypo">
              Download Report Card
            </Typography>
            <IconButton className="ActionButtonSize">
              <BsDownload className="LPReportCIcon" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        Learn To Play
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapReportCardDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent>
          <Grid container className="DailogLTRBStyle">
            <Grid container item className="ReportBorder">
              <Grid
                container
                item
                md={8}
                sm={8}
                xs={8}
                lg={8}
                direction="column"
                className="Grid3Style"
              >
                <Typography className="GreyColorTypography LearnerDNTypo">
                  Name :
                  <span className="BlueColorTypography LearnerDNTypo">
                    Learn to play
                  </span>
                </Typography>
                <Typography className="GreyColorTypography LearnerDNTypo">
                  Activities :
                  <span className="BlueColorTypography LearnerDNTypo">6 </span>
                </Typography>
                <Typography className="GreyColorTypography LearnerDNTypo">
                  Locomotor Skill :
                  <span className="BlueColorTypography LearnerDNTypo"> 6 </span>
                </Typography>
                <Typography className="GreyColorTypography LearnerDNTypo">
                  Body Management Skill :
                  <span className="BlueColorTypography LearnerDNTypo">6 </span>
                </Typography>
                <Typography className="GreyColorTypography LearnerDNTypo">
                  Object Control Skill :
                  <span className="BlueColorTypography LearnerDNTypo"> </span> 6
                </Typography>
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
                className="UIDGridStyle"
              >
                <Typography className="GreyColorTypography LearnerDNTypo">
                  UID :
                  {/* <span className="BlueColorTypography LearnerDNTypo"> </span> 6 */}
                </Typography>
                <Typography className="GreyColorTypography LearnerDNTypo">
                  Score :
                  <span className="BlueColorTypography LearnerDNTypo">
                    2182 l
                  </span>
                </Typography>
              </Grid>
              <Grid container item className="DetailsGridStyle">
                <Typography className="   DetailsTypo "> Details</Typography>{" "}
              </Grid>
            </Grid>
            <Grid container item className=" ScoreGridStyle">
              <Typography className="GreenColorTypography">
                {" "}
                Quiz 121
              </Typography>
              <Typography className="GreyColorTypography  ScoreTypo ">
                Score : <span className="BlueColorTypography ">120</span>
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid container item md={6} sm={6} xs={6} lg={6}>
                <Grid container item className="ReportBorder">
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    direction="column"
                    className="Card2GridStyle"
                  >
                    {" "}
                    <Typography className="BlueColorTypography LearnerDNTypo">
                      {" "}
                      Fill in the blanks
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Total Marks :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        50
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Marks Obtained :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        30
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    className="Grid2Style-2"
                    direction="column"
                  >
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Percentage :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        60%
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Attempts :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        1
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={6} sm={6} xs={6} lg={6}>
                <Grid container item className="ReportBorder">
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    direction="column"
                    className="Grid3Style"
                  >
                    <Typography className="BlueColorTypography LearnerDNTypo">
                      {" "}
                      Fill in the blanks
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Total Marks :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        50
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Marks Obtained :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        30
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    className="Grid2Style-2"
                    direction="column"
                  >
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Percentage :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        60%
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Attempts :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        1
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} className="FillGridStyle">
              <Grid container item md={6} sm={6} xs={6} lg={6}>
                <Grid container item className="ReportBorder">
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    direction="column"
                    className="Card2GridStyle"
                  >
                    {" "}
                    <Typography className="BlueColorTypography LearnerDNTypo">
                      {" "}
                      Fill in the blanks
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Total Marks :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        50
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Marks Obtained :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        30
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    className="Grid2Style-2"
                    direction="column"
                  >
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Percentage :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        60%
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Attempts :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        1
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={6} sm={6} xs={6} lg={6}>
                <Grid container item className="ReportBorder">
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    direction="column"
                    className="Grid3Style"
                  >
                    <Typography className="BlueColorTypography LearnerDNTypo">
                      {" "}
                      Fill in the blanks
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Total Marks :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        50
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Marks Obtained :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        30
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    md={6}
                    sm={6}
                    xs={6}
                    lg={6}
                    className="Grid2Style-2"
                    direction="column"
                  >
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Percentage :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        60%
                      </span>
                    </Typography>
                    <Typography className="GreyColorTypography LearnerDNTypo">
                      Attempts :
                      <span className="GreenColorTypography  LearnerDNTypo">
                        1
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item className="ScoreGridStyle">
              <Typography className="GreenColorTypography">
                Level (06)
              </Typography>
              <Typography className="GreyColorTypography ScoreTypo">
                Score : <span className="BlueColorTypography ">120</span>
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="ReportBorder  BoxShadow"
            >
              <Typography className="BlueColorTypography Card4LearnerTypo">
                1. Loreum ipsum
              </Typography>
              <Grid container item>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      UID : <span className="GreenColorTypography ">A01</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Difficulty :
                      <span className="GreenColorTypography "> 12 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      LS : <span className="GreenColorTypography ">80 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      OCS : <span className="GreenColorTypography "> 45</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="Grid4Style">
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Attempts :<span className="GreenColorTypography ">2</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Score :<span className="GreenColorTypography "> 95 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      BMS :<span className="GreenColorTypography "> 12</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="ReportBorder    LevelCard BoxShadow"
            >
              <Typography className="BlueColorTypography Card4LearnerTypo">
                1. Loreum ipsum
              </Typography>
              <Grid container item>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      UID : <span className="GreenColorTypography ">A01</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Difficulty :
                      <span className="GreenColorTypography "> 12 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      LS : <span className="GreenColorTypography ">80 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      OCS : <span className="GreenColorTypography "> 45</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="Grid4Style">
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Attempts :<span className="GreenColorTypography ">2</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Score :<span className="GreenColorTypography "> 95 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      BMS :<span className="GreenColorTypography "> 12</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="ReportBorder    LevelCard BoxShadow"
            >
              <Typography className="BlueColorTypography Card4LearnerTypo">
                1. Loreum ipsum
              </Typography>
              <Grid container item>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      UID : <span className="GreenColorTypography ">A01</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Difficulty :
                      <span className="GreenColorTypography "> 12 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      LS : <span className="GreenColorTypography ">80 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      OCS : <span className="GreenColorTypography "> 45</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="Grid4Style">
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Attempts :<span className="GreenColorTypography ">2</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Score :<span className="GreenColorTypography "> 95 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      BMS :<span className="GreenColorTypography "> 12</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="ReportBorder    LevelCard BoxShadow"
            >
              <Typography className="BlueColorTypography Card4LearnerTypo">
                1. Loreum ipsum
              </Typography>
              <Grid container item>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      UID : <span className="GreenColorTypography ">A01</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Difficulty :
                      <span className="GreenColorTypography "> 12 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      LS : <span className="GreenColorTypography ">80 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      OCS : <span className="GreenColorTypography "> 45</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="Grid4Style">
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Attempts :<span className="GreenColorTypography ">2</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Score :<span className="GreenColorTypography "> 95 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      BMS :<span className="GreenColorTypography "> 12</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="ReportBorder    LevelCard BoxShadow"
            >
              <Typography className="BlueColorTypography Card4LearnerTypo">
                1. Loreum ipsum
              </Typography>
              <Grid container item>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      UID : <span className="GreenColorTypography ">A01</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Difficulty :
                      <span className="GreenColorTypography "> 12 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      LS : <span className="GreenColorTypography ">80 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      OCS : <span className="GreenColorTypography "> 45</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="Grid4Style">
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Attempts :<span className="GreenColorTypography ">2</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Score :<span className="GreenColorTypography "> 95 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      BMS :<span className="GreenColorTypography "> 12</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              sm={12}
              xs={12}
              lg={12}
              className="ReportBorder    LevelCard BoxShadow"
            >
              <Typography className="BlueColorTypography Card4LearnerTypo">
                1. Loreum ipsum
              </Typography>
              <Grid container item>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      UID : <span className="GreenColorTypography ">A01</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Difficulty :
                      <span className="GreenColorTypography "> 12 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      LS : <span className="GreenColorTypography ">80 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      OCS : <span className="GreenColorTypography "> 45</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item className="Grid4Style">
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Attempts :<span className="GreenColorTypography ">2</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      Score :<span className="GreenColorTypography "> 95 </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item md={3} sm={3} xs={3} lg={3}>
                  <Grid container item md={4} sm={4} xs={4} lg={4}></Grid>
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <Typography className="GreyColorTypography ">
                      BMS :<span className="GreenColorTypography "> 12</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item className="ReportCloseButton">
              <Button className="GreyButton700"> Close</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Button,
  Tab,
} from "@material-ui/core";
import { Link } from "react-router-dom";
//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";
import { useLogin } from "Context/ExportContext";

//core component
import PersonalDetailTab from "./GTrainerTab/GTPersonalTab";
import AcademicTab from "./GTrainerTab/GTAcademicTab/TAcademic";
import PogressTab from "./GTrainerTab/GTPorgressTab/GPProgressTab";
import StatesTab from "./GTrainerTab/GTStates";
import { FETCH_URL } from "fetchIp";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function GTProfileTab(props) {
  const classes = useStyles();
  const { trainer, TrainerId, data } = props;
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [datevalue, setDateValue] = React.useState(null);
  const [fms, setFms] = React.useState(0);
  const [totalPP, setTotalPP] = React.useState(0);

  const getFMSscore = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getFMSScoreByUserId/${TrainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of FMS score==>", res);
        setFms(res.msg);
      } else {
        console.log("Error while fetching FMS score", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getTotalPP = async () => {
    try {
      const responce1 = await fetch(
        `${FETCH_URL}/api/assessment/leaderboard/total/learner/totalPP/${TrainerId}`,
        {
          method: "GET",
          headers: {
            "Content-type": "applicaton/json",
          },
        }
      );
      const resp = await responce1.json();
      // console.clear();
      console.log("PPP DATAAAAAAAAAAA", resp);
      if (resp?.agreg?.length <= 0) {
        try {
          const responce = await fetch(
            `${FETCH_URL}/api/assessment/leaderboard/total/getOtherPP/${TrainerId}
          `,
            {
              method: "GET",
              headers: {
                "Content-type": "application/json",
              },
            }
          );
          if (responce.ok) {
            const res = await responce.json();
            console.log("Get PP is ", res);

            setTotalPP(res?.msg);
          }
        } catch (error) {}
      } else {
        setTotalPP(resp?.agreg?.[0]?.sumtotalPP);
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    getFMSscore();
    getTotalPP();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DialogClose = () => {
    console.log(open);
    setOpen(false);
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Button className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        {trainer?.trainerDetail?.fullName}
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="lg"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Personal Detail"
                {...a11yProps(0)}
              />
              <Tab className="TabStyle" label="Academic " {...a11yProps(1)} />
              <Tab className="TabStyle" label="Progress" {...a11yProps(2)} />
              <Tab className="TabStyle" label="Stats" {...a11yProps(3)} />

              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>{" "}
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <PersonalDetailTab
              data={data}
              trainer={trainer}
              fmsScore={fms}
              totalPP={totalPP}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <AcademicTab
              data={data}
              trainer={trainer}
              TrainerId={TrainerId}
              fmsScore={fms}
              totalPP={totalPP}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PogressTab
              data={data}
              trainer={trainer}
              fmsScore={fms}
              totalPP={totalPP}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <StatesTab
              data={data}
              trainer={trainer}
              fmsScore={fms}
              totalPP={totalPP}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export const TABLE_DATA_SUCCESS = "TABLE_DATA_SUCCESS";
export const TABLE_DATA_REQUEST = "TABLE_DATA_REQUEST";
export const TABLE_DATA_FAILS = "TABLE_DATA_FAILS";

export const CREATE_SCHOOL_SUCCESS = "CREATE_SCHOOL_SUCCESS";
export const CREATE_SCHOOL_REQUEST = "CREATE_SCHOOL_REQUEST";
export const CREATE_SCHOOL_FAILS = "CREATE_SCHOOL_FAILS";

// School

export const EDIT_SCHOOL_DETAILS = "EDIT_SCHOOL_DETAILS";
export const SCHOOL_EDITED = "SCHOOL_EDITED";
export const SCHOOL_DELETED = "SCHOOL_DELETED";
export const SCHOOL_IS_DELETED = "SCHOOL_IS_DELETED";

export const CREATE_GRADE_SUCCESS = "CREATE_GRADE_SUCCESS";
export const CREATE_GRADE_REQUEST = "CREATE_GRADE_REQUEST";
export const CREATE_GRADE_FAILS = "CREATE_GRADE_FAILS";
export const SAVE_LEARNER_TRAINER_VALUE = "SAVE_LEARNER_TRAINER_VALUE";
export const SAVE_ASSESSMENT_RESULT_VALUE = "SAVE_ASSESSMENT_RESULT_VALUE";
export const SAVE_SCHOOL_DATA = "SAVE_SCHOOL_DATA";
export const SAVE_GRADE_DATA = "SAVE_GRADE_DATA";
export const SAVE_LEARNER_DATA = "SAVE_LEARNER_DATA";
export const SAVE_TRAINER_DATA = "SAVE_TRAINER_DATA";

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { tableDataReducer } from "./redux/reducers/Tablereducer";
import { CreateSchoolReducer } from "./redux/reducers/CreateSchool";
import { CreateGradeReducer } from "redux/reducers/CreateSchool";
import { getTabValuesReducer } from "redux/reducers/SaveTabValue";
const reducer = combineReducers({
  tableData: tableDataReducer,
  CreateSchool: CreateSchoolReducer,
  CreateGradeReducer: CreateGradeReducer,
  GetTabvalue: getTabValuesReducer,
});
const middleware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  Grid,
  Input,
  Checkbox,
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  DialogTitle,
  TextareaAutosize,
  Typography,
  IconButton,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";
import { FaPercent } from "react-icons/fa";
// @matertial-ui/core
// import EquipmentAdd from "../EditSkillTabDialog/EditEquipmentDialog";

export default function SkillTab() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const TabOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const LabelName = [
    {
      labelname: "Skipping",
    },
    {
      labelname: "Skipping",
    },
    {
      labelname: "Skipping",
    },
  ];
  const LabelRow2 = [
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
  ];
  const LabelRow3 = [];
  const LabelRow4 = [
    {
      labelEquip4: "Smiley Ball",
    },
    {
      labelEquip4: "Jumping Rope",
    },
    {
      labelEquip4: "Magic Wand",
    },
  ];
  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid
          item
          container
          md={3}
          sm={3}
          xs={3}
          lg={3}
          direction="column"
          style={{
            minHeight: "380px",
            backgroundColor: "white",
          }}
        >
          <Grid item container>
            <Grid
              item
              container
              md={7}
              sm={7}
              xs={7}
              lg={7}
              className=" CheckHeadTitle"
            >
              <Typography className=" BlueColorTypography ALAddSkillTabTypo APTopTypo">
                Locomotor Skills
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={5}
              xs={5}
              lg={5}
              className=" CheckHeadTitle"
            >
              <Typography className="GreenColorTypography APPercTypo APTopTypo">
                90%
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelName.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={3}
          sm={3}
          xs={3}
          lg={3}
          style={{
            minHeight: "380px",
            backgroundColor: "white",
            marginLeft: "10px",
          }}
          direction="column"
        >
          <Grid
            item
            container
            style={{
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              md={7}
              sm={7}
              xs={7}
              lg={7}
              className=" CheckHeadTitle"
            >
              <Typography className=" BlueColorTypography ALAddSkillTabTypo APTopTypo">
                Body Management
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={5}
              xs={5}
              lg={5}
              className=" CheckHeadTitle"
            >
              <Typography className="GreenColorTypography APPercTypo APTopTypo ">
                {" "}
                10%
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelRow2.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname2}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={3}
          sm={3}
          xs={3}
          lg={3}
          style={{
            minHeight: "380px",
            backgroundColor: "white",
            marginLeft: "10px",
          }}
          direction="column"
        >
          <Grid item container>
            <Grid
              item
              container
              md={7}
              sm={7}
              xs={7}
              lg={7}
              className=" CheckHeadTitle"
            >
              <Typography className=" BlueColorTypography ALAddSkillTabTypo APTopTypo">
                Object Control
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={5}
              xs={5}
              lg={5}
              className=" CheckHeadTitle"
            >
              <Typography className="GreenColorTypography APPercTypo  APTopTypo ">
                {" "}
                0%
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelRow3.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname3}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            minHeight: "380px",
            backgroundColor: "white",
            marginLeft: "10px",
            width: "21%",
          }}
          direction="column"
        >
          <Grid item container>
            <Grid item container xs={12} className=" CheckHeadTitle">
              <Typography className=" BlueColorTypography ALAddSkillTabTypo APTopTypo">
                Equipment
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              maxHeight: "340px",
              overflow: "auto",
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              lg={12}
              justify="center"
            >
              {LabelRow4.map((user, key) => (
                <Grid
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  item
                  container
                  justify="center"
                >
                  <Chip
                    label={user.labelEquip4}
                    className="GreenButton700  APChipStyle"
                    style={{ width: "60%" }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
// ReactIcons
import { GoSearch } from "react-icons/go";

export default function SelectCourseTab(props) {
  const {
    states: { assignlessonplandata, lessonplan },
    storeLessonPlan,
  } = props;
  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid
          item
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <FormControl className="CourseSerachInput">
            <Input
              disableUnderline
              className="GCourseInput"
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton style={{ marginRight: "7px" }}>
                    <GoSearch className=" HaedAddIcon" />
                  </IconButton>
                </InputAdornment>
              }
              label="Serach LessonPlan"
            />
          </FormControl>
        </Grid>
        {lessonplan &&
          lessonplan.length > 0 &&
          lessonplan.map((data, index) => (
            <Grid md={6} sm={6} xs={6} lg={6}>
              <FormGroup className="GCourseFromGroup AssignActivitesGridPaper">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        assignlessonplandata?.length > 0 &&
                        assignlessonplandata[index] !== null &&
                        assignlessonplandata[index] !== undefined
                          ? true
                          : false
                      }
                    />
                  }
                  onChange={(e) => storeLessonPlan(e, data, index)}
                  value={data.LPTitle}
                  className="CheckLabelStyleBlue"
                  style={{ justifyContent: "space-between" }}
                  label={
                    <div className="GCourseLabel">
                      <div>
                        <Typography>{data.LPTitle}</Typography>
                        <Typography className="GreyColorTypography">
                          {data.LPUid}
                        </Typography>
                      </div>
                      <div>
                        <Typography className="GreyColorTypography">
                          {/* {data.LPDuration.split("T")[0]} */}
                          {data.LPDuration}
                        </Typography>
                        <Typography className="GreyColorTypography">
                          {data.LPXp}
                        </Typography>
                      </div>
                    </div>
                  }
                />
              </FormGroup>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

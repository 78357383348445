import React, { useState, useEffect } from "react";

import { Typography, Button, DialogActions, Grid } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function Benefits(props) {
  const {
    states: {
      benefitesState,
      setBenefitesState,
      benefitesValid,
      inputDisable,
      setInputDisable,
    },
    EditSubmitClick,
  } = props;

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Benefits
          </Typography>
          {/* <div className="mt-16">
            <Editor
              disabled={inputDisable ? true : false}
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
            />
          </div> */}
          <Grid container>
            <TextareaAutosize
              className={
                benefitesValid
                  ? "textareaauto  width100 border-red  mt-16"
                  : " textareaauto width100   border-grey  mt-16"
              }
              aria-label="maximum height"
              disabled={inputDisable ? true : false}
              value={benefitesState}
              onChange={(e) => setBenefitesState(e.target.value)}
            />
          </Grid>
          <DialogActions className="mt-24 mb-20 width100">
            {inputDisable ? (
              <Button
                className="GreenButton700 next-btn"
                onClick={() => setInputDisable(false)}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  className="GreenButton-border next-btn"
                  onClick={() => setInputDisable(true)}
                >
                  Cancel
                </Button>
                <Button
                  className="GreenButton700 next-btn"
                  onClick={() => EditSubmitClick()}
                >
                  Save
                </Button>
              </>
            )}
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Typography,
} from "@material-ui/core";

import { Pagination } from "antd";
import { Link } from "react-router-dom";
import SwitchDialog from "./CourseActionDialog.js/SwitchDialog";
export default function AssignedTab(props) {
  const {
    states: {
      rowsPerPageCa,
      totalCA,
      pageCA,
      schoolSelected,
      searchCA,
      assignedCourse,
      changeCourseStatus,
    },
    ForAssignedCourse,
    itemRender,
    onShowSizeChangeCA,
  } = props;

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Subject </TableCell>
              <TableCell className="ColHead"> Description</TableCell>
              {/* <TableCell className="ColHead"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {assignedCourse &&
              assignedCourse.length > 0 &&
              assignedCourse.map((post) => (
                <TableRow>
                  <TableCell className="ActionButtonSize Col">
                    <Link
                      to={{
                        pathname: `/admin/courseprofile`,
                        state: { post },
                        // data: { loadUsers },
                      }}
                    >
                      {post.courseUid}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: `/admin/courseprofile`,
                        state: { post },
                        // data: { loadUsers },
                      }}
                    >
                      {post.courseTitle}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Typography align="center" className="width100">
                      <Typography className="GreyColorTypography500 overflow-style">
                        {post.courseDesc}
                      </Typography>
                    </Typography>
                  </TableCell>
                  {/* <TableCell className="Action">
                    <SwitchDialog
                      changeCourseStatus={changeCourseStatus}
                      // loadUsers={loadUsers}
                      courseId={post._id}
                      access={post.access}
                      snackopen={snackopen}
                      snackmsg={snackmsg}
                      snackErrMsg={snackErrMsg}
                      snackerropen={snackerropen}
                      SnanbarClose={SnanbarClose}
                      SnackbarErrorClose={SnackbarErrorClose}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {assignedCourse?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Course Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected || searchCA || assignedCourse?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              ForAssignedCourse(searchCA, value, value2);
            }}
            pageSize={rowsPerPageCa}
            total={totalCA}
            current={pageCA}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChangeCA}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React from "react";
import { Typography, Grid } from "@material-ui/core";
//Ract Icons
import ManImage from "assets/img/Man.png";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import Badges from "../../../../../assets/img/Badges.png";

export default function StatesTab(props) {
  const { TrainerId, trainer } = props;
  console.log(trainer, "trainer>>>>>>>>>>>>>>>>>>>>>.");
  return (
    <>
      <Grid container>
        <Grid
          container
          item
          direction="column"
          md={2}
          sm={2}
          xs={2}
          lg={2}
          style={{
            backgroundColor: "#ffffff",
            marginTop: "5px",
          }}
        >
          <Grid
            container
            item
            className="DetailsTypo-3"
            style={{ marginTop: "24px " }}
          >
            <img
              className="GTSidebarImg"
              src={trainer.trainerDetail.trainerImg}
            />
          </Grid>
          <Typography align="center" className="BlueColorTypography">
            {trainer.trainerDetail.fullName}
          </Typography>
          <Typography align="center" className="BlueColorTypography">
            Principal
          </Typography>
          <Typography align="center" className="GreenColorTypography">
            Active
          </Typography>
          <Typography align="center" className="BlueColorTypography">
            enrollment Date :{" "}
            <span className="GreenColorTypography">
              {trainer.trainerDetail.enrollmentDate.split("T")[0]}{" "}
            </span>
          </Typography>
          <Grid container item style={{ marginTop: "15px" }}>
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <AiOutlineMail className=" ActionButtonIcon GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography className="BlackColorTypography  GtSideGridStyle  ">
                {trainer.contactDetail.trainerEmail}
              </Typography>
            </Grid>
          </Grid>{" "}
          <Grid container item style={{ marginTop: "15px" }}>
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <MdPhone className="  ActionButtonIcon GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography a className="BlackColorTypography   ">
                {trainer.contactDetail.trainerPhoneNo}
              </Typography>
            </Grid>
          </Grid>{" "}
          <Grid container item style={{ marginTop: "15px" }}>
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <MdLocationPin className="   ActionButtonIcon  GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography className="BlackColorTypography   ">
                {trainer.contactDetail.trainerLocation}
              </Typography>
            </Grid>
          </Grid>{" "}
          <Grid container item className=" DetailsTypo-3 GtSideGridRating">
            <Typography
              className="BlueColorTypography  GTrainerSideTypo"
              align="center"
            >
              Avg Grade rating
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={10} sm={10} xs={10}>
          <Grid item md={5} sm={5} xs={5} className="DailogLTRBStyle-Na94 ">
            <Typography align="center" className="BlueColorTypography">
              Badges
            </Typography>

            <Grid item className="colorwhite" style={{ marginTop: "18px" }}>
              {" "}
              <img src={Badges} style={{ width: "100%", height: "396px" }} />
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6} className="DailogLTRBStyle-Na94">
            {" "}
            <Typography align="center" className="BlueColorTypography">
              Ranking
            </Typography>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";

const GradeDivision = ({ data }) => {
  return (
    <>
      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">Division Name</TableCell>
              <TableCell className="ColHead">Grade</TableCell>
              <TableCell className="ColHead">Learner </TableCell>
            </TableRow>
          </TableHead>
          {data?.divisions.length > 0 && (
            <TableBody>
              {data &&
                data?.divisions?.length > 0 &&
                data?.divisions?.map((divdata) => (
                  <>
                    <TableRow>
                      <TableCell className="Col">{divdata.division}</TableCell>
                      <TableCell className="Col">{divdata.gradeName}</TableCell>
                      <TableCell className="Col ActionButtonSize">
                        {divdata.assignedLearners &&
                        divdata.assignedLearners.length > 0
                          ? divdata.assignedLearners.length
                          : "--"}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

export default GradeDivision;

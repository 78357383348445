import React from "react";
import { Typography, Grid } from "@material-ui/core";
//Ract Icons
import ManImage from "../../../../../../../../assets/img/Man.png";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
export default function StatesTab() {
  return (
    <>
      <Grid container>
        <Grid
          container
          item
          direction="column"
          md={2}
          sm={2}
          xs={2}
          style={{
            backgroundColor: "#ffffff",
            marginTop: "5px",
          }}
        >
          <Grid
            container
            item
            className="DetailsTypo-3"
            style={{ marginTop: "24px " }}
          >
            <img className="GTSidebarImg" src={ManImage} />
          </Grid>
          <Typography align="center" className="BlueColorTypography">
            Anurag Yadav
          </Typography>
          <div className="GtSideGridStyle">
            <div>
              <AiOutlineMail className="GTSideBArIconGrid ActionButtonIcon GTMailIcon " />
            </div>
            <div>
              <Typography className="BlackColorTypography  GTrainerSideTypo ">
                Anuragyadav@gmail.com
              </Typography>
            </div>
          </div>
          <div className="GtSideGridStyle">
            <div>
              <MdPhone className=" GTSideBArIconGrid ActionButtonIcon GTMailIcon " />
            </div>
            <div>
              <Typography className="BlackColorTypography  GTrainerSideTypo">
                09292626262
              </Typography>
            </div>
          </div>
          <div className="GtSideGridStyle">
            <div>
              <MdLocationPin className="GTSideBArIconGrid ActionButtonIcon GTMailIcon " />
            </div>
            <div>
              <Typography className="BlackColorTypography GTrainerSideTypo  ">
                Abcdefghijkl, abcdefgh Noida, UP, India
              </Typography>
            </div>
          </div>
          <Grid container item className=" DetailsTypo-3 GtSideGridRating">
            <Typography
              className="BlueColorTypography  GTrainerSideTypo"
              align="center"
            >
              Avg Grade rating
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={10} sm={10} xs={10}>
          <Grid item md={5} sm={5} xs={5} className="DailogLTRBStyle-Na94 ">
            <Typography align="center" className="BlueColorTypography">
              Badges
            </Typography>

            <Grid item className="colorwhite"></Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6} className="DailogLTRBStyle-Na94">
            {" "}
            <Typography align="center" className="BlueColorTypography">
              Ranking
            </Typography>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import FileSaver from "file-saver";
import {
  Button,
  Breadcrumbs,
  Typography,
  Grid,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
//ReactIcons
import { BsDownload } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { GrView } from "react-icons/gr";
import { FaFileDownload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
//core component
import LPReportCard from "./LPReportCard";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function EquipmentProfile() {
  const { userToken } = useLogin();
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectGradeId, setSelectGradeId] = useState("");
  const [selected, setSelected] = useState("");
  const location = useLocation();
  const [learnerResult, setLearnerReasult] = useState([]);

  const learnerResultData = location.state.lAresult;
  console.log(learnerResultData, "learnerResultData>>>>>>>>.");
  const assessmentId = learnerResultData._id;
  const isLearner =
    learnerResultData?.assessmentData[0].isLearner === true ? "true" : "";
  const isTrainer =
    learnerResultData?.assessmentData[0].isTrainer === true ? "true" : "";

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const newNewGrade = learnerResultData?.assessmentData[0].assignedGrades.filter(
    (f) => f._id === selectGradeId
  );
  console.log(newNewGrade, "newNewGrade");

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  ////////////////////////APi////////////////////////////////////
  const getAssesmentLearnerByGrade = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getLearnerResultbyAssessment?assessmentId=${assessmentId}&gradeId=${gradeId}&isLearner=${isLearner}&isTrainer=${isTrainer}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("learnerAssesmentResult", res);
        console.log(res, "i am response yo");
        setLearnerReasult(res.msg);
      } else {
        console.log("Error while fetching AssesmentLeaener result", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  console.log(learnerResult, "learnerResultlearnerResult");
  console.log(selectGradeId, "selectGradeId");
  console.log(selected, "selected");
  ////////////////////////APi////////////////////////////////////
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const downloadLearnerResult = async () => {
    try {
      axios
        .post(
          `${FETCH_URL}/api/assessment/getGradeLearnerReport`,
          {
            assessmentId: assessmentId,
            isLearner: isLearner,
            isTrainer: isTrainer,
            gradeId: newNewGrade[0]._id,
            grade: newNewGrade[0].grade,
            location: newNewGrade[0].location,
            schoolName: newNewGrade[0].schoolName,
            schoolId: newNewGrade[0].schoolId,
            assessmentName: learnerResultData?.assessmentData[0].assessmentName,
            skills: learnerResultData?.assessmentData[0].skills,
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          console.log("response===========>", response);
          const dirtyFileName = response.headers["content-disposition"].split(
            "filename="
          )[1];
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, dirtyFileName);
        });
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Assesments
          </Button>
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton2"
          >
            Result
          </Button>
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton3"
          >
            Lesson Plan
          </Button>
          <div className="Breadcrumb2">LTP1</div>
        </Breadcrumbs>
      </div>
      <Grid container>
        <Grid container item className="   colorwhite LPDialogGrid  ">
          <Grid container item>
            <Grid container item md={5} sm={5} xs={5}>
              {/* <Typography className="GreenColorTypography Grade1Typo">
                Grade 1
                <span className="GreyColorTypography SchoolTypo">
                  Zoomer Public School, Pune
                </span>
              </Typography> */}
              {/*  */}
              <Grid container item md={5} sm={5} xs={5}>
                <FormControl style={{ minWidth: "135px" }}>
                  <Select
                    style={{
                      border: "1px solid #dddddd",
                      padding: "5px",
                      borderRadius: "8px",
                    }}
                    value={selected ? selected : 0}
                    onChange={(e) => {
                      setSelected(e.target.value);
                      setSelectGradeId(e.target.value);
                      getAssesmentLearnerByGrade(e.target.value);
                    }}
                    inputProps={{
                      name: "agent",
                      id: "age-simple",
                    }}
                  >
                    <MenuItem className="BlueColorTypography" value={0}>
                      Select Grade
                    </MenuItem>
                    {learnerResultData?.assessmentData[0].assignedGrades.map(
                      (gData, index) => {
                        return (
                          <MenuItem key={index} value={gData._id}>
                            {gData.grade}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/*  */}
            </Grid>
            <Grid container item md={4} sm={4} xs={4}>
              <Typography className="GreenColorTypography">
                LTP1 - Learn To Play
              </Typography>
            </Grid>
            <Grid container item md={1} sm={1} xs={1} justify="flex-end">
              <IconButton
                className="DownIconButton"
                onClick={downloadLearnerResult}
              >
                <FaFileDownload className="DownIcon" />{" "}
              </IconButton>
            </Grid>{" "}
            <Grid container item md={2} sm={2} xs={2}>
              <Typography className="GreyColorTypography DownTypo">
                Download Grade Report
              </Typography>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">Uid </TableCell>
              <TableCell className="Head">Name </TableCell>
              <TableCell className="Head"> Division </TableCell>
              <TableCell className="Head"> Grade </TableCell>

              <TableCell className="Head"> School Name </TableCell>
              <TableCell className="Head"> Location </TableCell>
              <TableCell className="Head"> Total Score </TableCell>

              {/* <TableCell className="Head"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {learnerResult.map((lResult) => (
              <TableRow>
                <TableCell className="Col ActionButtonSize">
                  {lResult.leanerUid}
                </TableCell>
                <TableCell className="Col">{lResult.name}</TableCell>
                <TableCell className="Col">
                  {/* <LPReportCard /> */}
                  {lResult.divisionName}
                </TableCell>
                <TableCell className="Col">{lResult.grade}</TableCell>
                <TableCell className="Col">{lResult.schoolName}</TableCell>
                <TableCell className="Col">{lResult.location}</TableCell>
                <TableCell className="Col">{lResult.totalScore}</TableCell>
                {/* <TableCell className="Action">
                  <IconButton className="ActionButtonSize">
                    <BsDownload className="ActionButtonIcon" />
                  </IconButton>

                  <IconButton className="ActionButtonSize">
                    <GrView />
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

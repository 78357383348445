import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { IoSchoolOutline } from "react-icons/io5";

//core Module
import SelectSchool from "./LASchoolTab/LASelectSchoolTab";
import CRenameDialog from "./LASchoolTab/LARenameTab";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog() {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  const submitClick = async (e) => {
    console.log("StudentUID----------");
    e.preventDefault();
  };
  return (
    <div>
      <Tooltip title="School">
        <IconButton className="ActionButtonSize">
          <IoSchoolOutline
            className="ActionButtonIcon"
            // onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Select School" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectSchool />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CRenameDialog />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

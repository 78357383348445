import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
  Typography,
} from "@material-ui/core";

//Core Component

export default function LocomotorTab(props) {
  const { skillsData } = props;
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Name of Skill</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead"> Assigned On </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {skillsData &&
              skillsData.length > 0 &&
              skillsData.map((skills) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link> {skills.subSkillUid} </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Link>
                      {" "}
                      {skills.subSkillName}
                      {/* <GSkillProfile /> */}{" "}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {" "}
                      {skills.subSkillDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Col700WAB">14/09/2021</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

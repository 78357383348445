import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import EditRubrics from "./EditRubrics";
//core Module
// import SubSkills from "./LocoEdit";
// import RubricsTab from "./LocoRubricsEdit";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditDialog(props) {
  const {
    states: { qs },
    searchSkill,
    skillsData,
    skills,
  } = props;
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const { userToken } = useLogin();
  const [open, setOpen] = React.useState(false);
  const [quesNo, setQuesNo] = React.useState(null);
  const [quesDesc, setQuesDesc] = React.useState(null);
  const [quesMarks, setQuesMarks] = React.useState(null);
  const [quesType, setQuesType] = React.useState(qs.quesType);
  const [minValue, setMinValue] = React.useState(null);
  console.log(quesType, "qwertyui");
  React.useEffect(() => {
    if (quesType === 2 || quesType === 3) {
      setMinValue(0);
      qs.minValue = 0;
    }
  }, [quesType]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    // console.log(open);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  console.log(skills, "skills inside edit skills");
  //////////Rubrics skills editing api//////////////////////////////////////

  const submitClick = async (e) => {
    // For Editing
    // console.log(quesDesc, "quesDesc");
    // console.log(quesMarks, "quesMarks");
    // console.log(quesNo, "quesNo");
    // console.log(quesType, "ok done");

    e.preventDefault();
    const response = await fetch(`${FETCH_URL}/api/skill/editRubricDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        rubricId: qs._id,
        quesDesc: quesDesc ? quesDesc : qs.quesDesc,
        quesMarks: quesMarks ? quesMarks : qs.quesMarks,
        quesNo: quesNo ? quesNo : qs.quesNo,
        quesType: quesType ? quesType : qs.quesType,
        minValue: minValue ? minValue : qs.minValue,
      }),
    });

    const res = await response.json();
    console.log(res, "yo i am res man");
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      console.log(skills._id, "hohoho");
      searchSkill(skills._id);
      setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
    }
  };
  console.log(qs, " iam the qs");
  //////////skills editing api//////////////////////////////////////
  return (
    <div>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Rubrics" {...a11yProps(0)} />
            {/* <Tab className="TabStyle" label="Rubrics" {...a11yProps(1)} /> */}
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <EditRubrics
              states={{
                qs,
                quesDesc,
                setQuesDesc,
                quesMarks,
                setQuesMarks,
                quesNo,
                setQuesNo,
                quesType,
                setQuesType,
                minValue,
                setMinValue,
              }}
              TabNext={TabNext}
              searchSkill
              submitClick={submitClick}
              skillsData={skillsData}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React from "react";

import {
  AppBar,
  MenuItem,
  Select,
  FormControl,
  styled,
  InputBase,
  Toolbar,
  IconButton,
  makeStyles,
  Grid,
  InputAdornment,
  Input,
  Box,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import cx from "classnames";
import { Button, Typography } from "antd";
// material-ui icons
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
// core components
import { useLogin } from "../../Context/ExportContext";
import logo from "../../assets/img/logo-white.png";

const useStyles = makeStyles();
export default function AdminNavbar(props) {
  const history = useHistory();
  const role = localStorage.getItem("userrole");
  const classes = useStyles();
  const [age, setAge] = React.useState(null);
  const { color, rtlActive, brandText } = props;

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  const { authHelper } = useLogin();
  const { signOut } = authHelper;
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <AppBar className="AppBar">
      <Toolbar className="AppToolbar">
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push(
              role == 0
                ? "/admin"
                : role == 2
                ? "/hos"
                : role == 3
                ? "/principal"
                : "/auth"
            )
          }
        >
          {/* <Link to="/"> */}{" "}
          <img src={logo} alt="logo" className="ZoomerImg" />
          {/* </Link> */}
        </div>
        <Grid container style={{}}>
          <Grid container item style={{ width: "20% " }}>
            {/* <FormControl className="MainPageFormControl">
              <Input
                disableUnderline
                className="NavBarSearchEngine"
                placeholder="Serach Schools, Subject, Trianer,Skills...."
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton style={{ marginRight: "2px" }}>
                      <GoSearch className="SearchIcon" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Serach"
              />
            </FormControl> */}
          </Grid>
        </Grid>

        <div style={{ display: "flex" }}>
          <Button className=" CreateButton">
            <Link to="/admin/CaretorMode">
              <Typography
                className="WhiteColorTypography"
                style={{
                  border: "0.1rem solid #ffffff",
                  padding: "4px",
                  boxShadow: "0px 1px 1px  0px",
                }}
              >
                Creator Mode{" "}
              </Typography>{" "}
            </Link>
          </Button>

          <Button className="NotificationButton">
            <IoIosNotificationsOutline className="NotificationIcon" />
          </Button>
          <Button className="ProfileButton">
            <FaRegUserCircle className="ProfileIcon" />
          </Button>
          <FormControl className="NavSelect">
            <Select
              className="NavbarSelectStyle"
              disableUnderline
              variant="standard"
              onChange={handleChange}
              displayEmpty
              value={age ? age : "Admin"}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="Admin" className="Selectmenustyle NavSelect">
                <em
                  className="Selectmenustyleem"
                  style={{ fontStyle: "normal" }}
                >
                  Admin
                </em>
              </MenuItem>
              <MenuItem className="NavbarSelectMenu" value={10}>
                My Profile
              </MenuItem>
              <MenuItem className="NavbarSelectMenu" value={20}>
                Setting
              </MenuItem>
              <MenuItem
                className="NavbarSelectMenu"
                onClick={signOut}
                value={30}
              >
                Log Out
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </Toolbar>
    </AppBar>
  );
}

// import React from "react";
// // nodejs library to set properties for components
// import PropTypes from "prop-types";
// import cx from "classnames";

// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Hidden from "@material-ui/core/Hidden";

// // material-ui icons
// import Menu from "@material-ui/icons/Menu";
// import MoreVert from "@material-ui/icons/MoreVert";
// import ViewList from "@material-ui/icons/ViewList";

// // core components
// import AdminNavbarLinks from "./AdminNavbarLinks";
// import Button from "components/CustomButtons/Button.js";

// import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

// const useStyles = makeStyles(styles);

// export default function AdminNavbar(props) {
//   const classes = useStyles();
//   const { color, rtlActive, brandText } = props;
//   const appBarClasses = cx({
//     [" " + classes[color]]: color,
//   });
//   const sidebarMinimize =
//     classes.sidebarMinimize +
//     " " +
//     cx({
//       [classes.sidebarMinimizeRTL]: rtlActive,
//     });
//   return (
//     <AppBar className={classes.appBar + appBarClasses}>
//       <Toolbar className={classes.container}>
//         <Hidden smDown implementation="css">
//           <div className={sidebarMinimize}>
//             {props.miniActive ? (
//               <Button
//                 justIcon
//                 round
//                 color="white"
//                 onClick={props.sidebarMinimize}
//               >
//                 <ViewList className={classes.sidebarMiniIcon} />
//               </Button>
//             ) : (
//               <Button
//                 justIcon
//                 round
//                 color="white"
//                 onClick={props.sidebarMinimize}
//               >
//                 <MoreVert className={classes.sidebarMiniIcon} />
//               </Button>
//             )}
//           </div>
//         </Hidden>
//         <div className={classes.flex}>
//           {/* Here we create navbar brand, based on route name */}
//           <Button href="#" className={classes.title} color="transparent">
//             {brandText}
//           </Button>
//         </div>
//         <Hidden smDown implementation="css">
//           <AdminNavbarLinks rtlActive={rtlActive} />
//         </Hidden>
//         <Hidden mdUp implementation="css">
//           <Button
//             className={classes.appResponsive}
//             color="transparent"
//             justIcon
//             aria-label="open drawer"
//             onClick={props.handleDrawerToggle}
//           >
//             <Menu />
//           </Button>
//         </Hidden>
//       </Toolbar>
//     </AppBar>
//   );
// }

// AdminNavbar.propTypes = {
//   color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
//   rtlActive: PropTypes.bool,
//   brandText: PropTypes.string,
//   miniActive: PropTypes.bool,
//   handleDrawerToggle: PropTypes.func,
//   sidebarMinimize: PropTypes.func,
// };

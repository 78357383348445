import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, DialogContent, Grid } from "@material-ui/core";
import { FETCH_URL } from "fetchIp";

//core Component
import OngoingTab from "./OngoingTab";
import CompleteTab from "./CompleteTab";
import AnalyticsTab from "./AnalyticsTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LgCourse({ learner }) {
  const [value, setValue] = React.useState(0);
  const LearnerID = learner?._id;

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [assesmentOnGoing, setAssesmentonGoing] = useState(null);
  const [assesmentResult, setAssesmentResult] = useState(null);

  const OngoingAssement = learner?.assignedAssessments?.filter(
    (item) => item.completion === false
  );

  const newArrayonGoing = OngoingAssement?.map((obj) => ({
    _id: obj?._id,
    assignedOn: obj?.assignedOn,
  }));

  const GetAssignedAssesmentOnGoing = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAssessmentByAssessmentIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ assessmentIds: newArrayonGoing }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setAssesmentonGoing(res.msg);
        console.log("GetAssignedAssesmentOnGoing    ", res.msg);
      } else {
        //.log("Check Assesment Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  const GetAssignedAssesmentResult = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAssessmentResultByServiceUserId/${LearnerID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setAssesmentResult(res.msg);
        //.log("GetAssignedAssesmentResult    ", res.msg);
      } else {
        //.log("Check Assesment Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  useEffect(() => {
    GetAssignedAssesmentOnGoing();
  }, []);
  useEffect(() => {
    GetAssignedAssesmentResult();
  }, [LearnerID]);

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Grid item conatiner>
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="GProcessTabStyle"
          >
            <Tab
              className="GProceesTabChanges"
              label="Ongoing"
              {...a11yProps(0)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Completed"
              {...a11yProps(1)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Analytics"
              {...a11yProps(2)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <OngoingTab assesmentOnGoing={assesmentOnGoing} learner={learner} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CompleteTab learner={learner} assesmentResult={assesmentResult} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AnalyticsTab learner={learner} assesmentResult={assesmentResult} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import {
  IconButton,
  Button,
  Breadcrumbs,
  Grid,
  Typography,
  Select,
  Input,
  FormControl,
  MenuItem,
  Toolbar,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

//ReactIcons
import { IoIosArrowBack } from "react-icons/io";
import { GoSearch } from "react-icons/go";

//core component
import GradeProfileTab from "./GradePMainPageTab";
import { useLocation } from "react-router-dom";

export default function GradeProfile() {
  const location = useLocation();
  console.log(" Location ", location);
  const [searchlocation, setSerachLocation] = React.useState("");
  const [gradeDetail, setGradeDetail] = React.useState("");
  const locChange = (event) => {
    setSerachLocation(event.target.value);
  };

  const history = useHistory();
  const [cousedrop, setCouseDrop] = React.useState(false);

  useEffect(() => {
    if (location?.state) {
      setGradeDetail(location.state.data);
    }
  }, [location?.state]);
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div>
      <Grid
        container
        className="GPContainer"
        style={{ justifyContent: "center" }}
      >
        <Typography className="BlueColorTypography GPTitleTypo">
          {`${gradeDetail?.schoolName} ,${gradeDetail?.location}`}
        </Typography>{" "}
        {/* <Grid item md={2} sm={2} xs={2} lg={2}>
          <Grid item xs={9}>
            <FormControl variant="standard" className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={searchlocation ? searchlocation : 0}
                onChange={locChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Grade 1
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  Blue Ridge Public School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>{" "}
        </Grid> */}
        {/* <Grid item md={2} sm={2} xs={2} lg={2}>
          <Grid item xs={9}>
            <FormControl variant="standard" className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={searchlocation ? searchlocation : 0}
                onChange={locChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Division A
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  Blue Ridge Public School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>{" "} */}
      </Grid>
      <GradeProfileTab
        states={{ data: gradeDetail, cousedrop, setCouseDrop }}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//Core component
import GLessonPlanAdd from "./GAddLessonPlan/GLessonPlanDialogMain";
// 1import GTDeleteDialog from "../../../ActionDialog/DeleteDialog";
// import GTSwitchDialog from "./GCourseActionTab/SwitchDialog";
// import GCourseProfile from "./GCourseProfile/GCProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { useLocation } from "react-router-dom";
export default function GTrainerTab({ data, setLoading }) {
  const [paginatedLessonPlan, setPaginatedLessonPlan] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [access, setAccess] = useState(0);
  const { userToken } = useLogin();

  const location = useLocation();
  console.log(" Check Grade  Data", location.state.data);
  const gradeId = location.state.data._id;

  const getPaginatedLessonPlan = async (gradeId) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);
        console.log("My response of PaginatedCourses==>", res.msg);
        setPaginatedLessonPlan(res.msg);
        setTotal(res.msg.length);
      } else {
        setLoading(false);
        console.log("Error while fetching PaginatedCourses", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log(
    paginatedLessonPlan,
    "paginatedLessonPlan>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
  );

  useEffect(() => {
    getPaginatedLessonPlan(gradeId);
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  console.log(paginatedLessonPlan, "paginatedLessonPlan>>>>>>>>>>>>>>>>..");
  const newProp = 1;
  return (
    <>
      <Grid container>
        <Grid container item className="  DivisionStyleGridSkill ">
          <Grid container item className="DiviStyle ">
            <Typography></Typography>

            <GLessonPlanAdd
              data={data}
              getPaginatedLessonPlan={getPaginatedLessonPlan}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <div className="DivisionStyleGrid"> */}
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Lesson Plan Name </TableCell>
              <TableCell className="ColHead"> Description</TableCell>
              <TableCell className="ColHead"> Assigned On</TableCell>
              {/* <TableCell className="ColHead"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedLessonPlan?.map((lessonplan) => (
              <TableRow>
                <TableCell className="Col ActionButtonSize">
                  {lessonplan.LPUid}
                </TableCell>
                <TableCell className="Col">
                  {lessonplan.LPTitle}
                  {/* <GCourseProfile /> */}
                </TableCell>
                <TableCell className="Col">
                  <Typography align="center" className="width100">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {lessonplan.LPDesc}
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell className="Col">
                  {" "}
                  {lessonplan.createdOn.split("T")[0]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {paginatedLessonPlan?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No LessonPlan Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {/* <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div> */}
      {/* </div> */}
    </>
  );
}

import * as React from "react";
import {
  Grid,
  Input,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp.js";
import { propTypes } from "react-bootstrap/esm/Image";

export default function ExtendTab(props) {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const { userToken } = useLogin();

  const [locmotor, setSchool] = React.useState("");
  const [singleGrade, setSingleGrade] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [apiSchoolNames, setApiSchoolNames] = React.useState(null);
  const [uniqueSchools, setUniqueSchools] = React.useState(null);
  const [filteredLocations, setFilteredLocations] = React.useState(null);
  const [filteredGrades, setFilteredGrades] = React.useState(null);
  const [divisionNames, setDivisionNames] = React.useState(null);
  const {
    states: {
      schoolName,
      setSchoolName,
      grade,
      setGrade,
      location,
      setLocation,
      firstTermStartDate,
      setFirstTermStartDate,
      secondTermStartDate,
      setsecondTermStartDate,
      firstTermEndDate,
      setfirstTermEndDate,
      secondTermEndDate,
      setsecondTermEndDate,
      thirdTermStartDate,
      setThirdTermStartDate,
      thirdTermEndDate,
      setThirdTermEndDate,
      rollNumber,
      setRollNumber,
      division,
      setDivision,
      departmentId,
      setDepartmentId,
      setDepartmentName,
    },
    Tabnext3,
    submitClick,
  } = props;

  React.useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      //.log("Fetching Schools from AcademicTab.js ==>");
      const response = await fetch(`${FETCH_URL}/api/school/getAllSchools`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const res = await response.json();

      if (response.ok) {
        //.log("School Response ====>", res);
        setApiSchoolNames(res.msg);
        setUniqueSchools([
          ...new Set(
            res.msg.map((school, i) => school.organisationDetails.schoolName)
          ),
        ]); // Setting unique schools only
      } else {
        //.log("Error from schollGET =====>", res);
      }
    } catch (error) {
      //.log("Error from fetch school", error);
    }
  };

  const getSchoolLocation = async (schoolName) => {
    //.log("I worked");

    if (!schoolName) {
      setFilteredLocations([]);
      return;
    }

    setFilteredLocations(
      apiSchoolNames
        .filter(
          (school) => school.organisationDetails.schoolName === schoolName
        )
        .map((schoolData) => schoolData.organisationDetails.location)
    );
  };

  console.log("I worked departmentId", departmentId);
  console.log("I worked apiSchoolNames", apiSchoolNames);

  console.log("I  apiSchoolNames", apiSchoolNames);

  const getSchoolGrades = async (schlLocation) => {
    console.log("Check schlLocationschlLocation", schlLocation);
    if (!schlLocation) {
      setFilteredGrades([]);
      return;
    }
    // location and schoolName
    let filters = apiSchoolNames
      .filter((school) => school.organisationDetails.schoolName === schoolName)
      .filter(
        (locationFilter) =>
          locationFilter.organisationDetails.location === schlLocation
      )
      .map((filteredData) => filteredData.grade)[0];

    let DpId = apiSchoolNames
      .filter((school) => school.organisationDetails.schoolName === schoolName)
      .filter(
        (locationFilter) =>
          locationFilter.organisationDetails.location === schlLocation
      )
      .map((data) => ({
        id: data?.departmentDetails?.departmentId,
        D_name: data?.departmentDetails?.departmentName,
      }));

    setDepartmentId(DpId[0].id);
    setDepartmentName(DpId[0].D_name);

    setFilteredGrades(filters);
    //.log("I set filters here =====>", filters);
  };

  const changeValue = (e) => {
    console.log("eeeeeeeeeeechangeValue", e);
    const grade = filteredGrades.find((data) => data.grade === e);
    setSingleGrade(grade);
    //.log(grade, "yahooooooooo");
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  const fetchDivisons = async (gradeName) => {
    try {
      // //.log("Fetching Schools from AcademicTab.js ==>");
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeName=${gradeName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();

      if (response.ok) {
        //.log("divison Response ====>", res);
        setDivisionNames(res.msg);
      } else {
        //.log("Error from divison =====>", res);
      }
    } catch (error) {
      //.log("Error from fetch divison", error);
    }
  };

  props.getSchollIdAndGradeId(singleGrade);
  return (
    <form>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          Grid
          item
          md={6}
          sm={6}
          xs={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <Grid container direction="column" item style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography GreyColorTypography">
              School Name
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid container item md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput colorwhite"
              >
                <Select
                  className=" SkillsSelectStyle "
                  value={schoolName}
                  onChange={(e) => {
                    setSchoolName(e.target.value);
                    getSchoolLocation(e.target.value);
                  }}
                  onKeyDown={handleEnter}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  {uniqueSchools && uniqueSchools.length > 0 ? (
                    uniqueSchools.map((schoolName, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        value={schoolName}
                      >
                        {schoolName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      No Schools Available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className=" SkillsSelectStyle "
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    getSchoolGrades(e.target.value);
                  }}
                  onKeyDown={handleEnter}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  {filteredLocations && filteredLocations.length > 0 ? (
                    filteredLocations.map((locationName, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        value={locationName}
                      >
                        {locationName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      Please select a school first
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Grade
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput colorwhite"
              >
                <Select
                  className=" SkillsSelectStyle "
                  value={grade}
                  onChange={(e) => {
                    changeValue(e.target.value);
                    setGrade(e.target.value);
                    fetchDivisons(e.target.value);
                  }}
                  onKeyDown={handleEnter}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  {filteredGrades && filteredGrades.length > 0 ? (
                    filteredGrades.map((mapGrade, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        value={mapGrade.grade}
                      >
                        {mapGrade.grade}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      No Grades available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/*  */}
            <Typography className="InputTitleTypography GreyColorTypography">
              Divisions
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput colorwhite"
              >
                <Select
                  className=" SkillsSelectStyle "
                  value={division}
                  onChange={(e) => {
                    // changeValue(e.target.value);
                    // setDivisionName(e.target.value.division);
                    // setDivision(e.target.value._id);
                    setDivision(e.target.value);
                  }}
                  onKeyDown={handleEnter}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  {divisionNames && divisionNames.length > 0 ? (
                    divisionNames.map((mapDivison, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        // value={mapDivison._id}
                        value={mapDivison}
                      >
                        {mapDivison.division}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      No Divisons available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/*  */}
            <Typography className="InputTitleTypography GreyColorTypography">
              Roll Number
              {/* <span className="ImpColor"> *</span> */}
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={rollNumber}
                onChange={(e) => setRollNumber(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <Typography className="InputTitleTypography GreyColorTypography">
            1st Term Start date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={firstTermStartDate}
                  onChange={(e) => {
                    setFirstTermStartDate(e);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            1st Term End Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={firstTermEndDate}
                  onChange={(e) => {
                    setfirstTermEndDate(e);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            2nd Term Start Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={secondTermStartDate}
                  minDate={new Date("2017-01-01")}
                  onChange={(e) => {
                    setsecondTermStartDate(e);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            2nd Term End Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={secondTermEndDate}
                  onChange={(newValue) => {
                    setsecondTermEndDate(newValue);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            3nd Term Start Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={thirdTermStartDate}
                  minDate={new Date("2017-01-01")}
                  onChange={(e) => {
                    setThirdTermStartDate(e);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            3nd Term End Date
            {/* <span className="ImpColor"> *</span> */}
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={thirdTermEndDate}
                  onChange={(newValue) => {
                    setThirdTermEndDate(newValue);
                  }}
                  onKeyDown={handleEnter}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>

      <DialogActions
        style={{
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {/* <Button
          className="GreenButton700 ContactNextBottom"
          autoFocus
          onClick={Tabnext3}
        >
          Next
        </Button> */}
        <Button className="DocumnetSubmit" onClick={submitClick}>
          Submit
        </Button>
      </DialogActions>
    </form>
  );
}

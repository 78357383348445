import {
  SAVE_LEARNER_TRAINER_VALUE,
  SAVE_ASSESSMENT_RESULT_VALUE,
} from "../constants/Constants.js";

const initialState = {
  LTValue: 0,
  ARValue: 0,
};

export const getTabValuesReducer = (state = initialState, action) => {
  if (action.type === SAVE_LEARNER_TRAINER_VALUE) {
    return { ...state, LTValue: action.payload };
  } else if (action.type === SAVE_ASSESSMENT_RESULT_VALUE) {
    return { ...state, ARValue: action.payload };
  } else {
    return state;
  }
};

import { Landscape } from "@material-ui/icons";

import { IoTabletLandscapeOutline } from "react-icons/io";
const swv = "sw-visibility";
const expt = "export-template";
const prv = "preview";
const ful = "fullscreen";
const deviceTablet = "set-device-tablet";
const deviceMobile = "set-device-mobile";
const deviceDesktop = "set-device-desktop";
const deviceLandscape = "set-device-landscape";

export const layerManager = {
  appendTo: "#layers-container",
};
export const traitManager = {
  appendTo: "#traits-container",
};
export const selectorManager = {
  appendTo: "#styles-container",
};
export const panels = {
  defaults: [
    {
      id: "editor-actions",
      el: ".panel__editor",
      buttons: [
        {
          id: swv,
          command: swv,
          className: "fa fa-square-o",
          active: true,
        },
        {
          id: prv,
          context: prv,
          command: (e) => e.runCommand(prv),
          className: "fa fa-eye",
        },
        {
          id: ful,
          command: ful,
          className: "fa fa-arrows-alt",
        },
        {
          id: expt,
          className: "fa fa-code",
          command: (e) => e.runCommand(expt),
        },
        {
          id: "undo",
          className: "fa fa-undo",
          command: (e) => e.runCommand("core:undo"),
          attributes: { title: "Undo last action" },
        },
        {
          id: "redo",
          className: "fa fa-repeat",
          command: (e) => e.runCommand("core:redo"),
          attributes: { title: "Redo last action" },
        },
      ],
    },
    {
      id: "panel-devices",
      el: ".panel__devices",
      buttons: [
        {
          id: deviceDesktop,
          command: deviceDesktop,
          className: "fa fa-desktop",
          // active: 1,
          active: true,
          togglable: false,
        },
        {
          id: deviceTablet,
          command: deviceTablet,
          className: "fa fa-tablet",
          togglable: false,
        },
        {
          id: deviceLandscape,
          command: deviceLandscape,
          className: "fa-light fa-rectangle-wide",
          label: "L",
        },
        {
          id: deviceMobile,
          command: deviceMobile,
          className: "fa fa-mobile",
          togglable: false,
        },
      ],
    },
  ],
};

// widthMedia: "480px",
export const deviceManager = {
  mediaCondition: "min-width",
  devices: [
    // {
    //   name: "Mobile",
    //   width: "18.75vw",
    //   // width: "320",
    //   widthMedia: "",
    //   height: "800px",
    // },
    {
      name: "Mobile",
      width: "320px",
      widthMedia: "480px",
    },
    {
      name: "Tablet",
      width: "768px",
      widthMedia: "810px",
    },
    {
      name: "landscape",
      width: "800px",
      height: "360px",
    },
    {
      name: "Desktop",
      width: "",
      // height: "1024px",
    },
  ],
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  Typography,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function SelectSchoolTab({
  gradedata,
  storeSkill,
  setGradeData,
  selectSkillText,
  filterdata,
}) {
  console.log("Garde Data", gradedata);
  ////////////////////////////////////////////////////////////////
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState([]);
  const { userToken } = useLogin();
  // const [selectGrade, setSelectGrade] = useState([]);
  // const [gradeSelected, setGradeSelected] = useState([]);
  ////////////////////////////////////////////////////////////
  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getFilteredGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////
  React.useEffect(() => {
    getDistinctSchoolAndLocation();
  }, []);
  return (
    <>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{ marginLeft: "2%" }}
        >
          <Grid item container>
            <Grid item container className="ParticipantStyleSelect">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" DialogSelectdropstyle"
                  value={schoolSelected ? schoolSelected : 0}
                  // onChange={handleChange}
                  onChange={(e) => {
                    getDistinctSchoolAndLocation(e.target.value);
                    setSchoolSelected(e.target.value);
                  }}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select school
                  </MenuItem>
                  {schoolNames &&
                    schoolNames.length > 0 &&
                    schoolNames.map((school) => (
                      <MenuItem value={school._id} className="Selectmenustyle">
                        {school._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="ParticipantStyleSelect1">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={schoolLocationSelected ? schoolLocationSelected : 0}
                  onChange={(e) => {
                    setSchoolLocationSelected(e.target.value);
                    getGrades(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Location
                  </MenuItem>
                  {schoolLoc &&
                    schoolLoc.length > 0 &&
                    schoolLoc.map((location) => (
                      <MenuItem
                        value={location._id}
                        className="Selectmenustyle"
                      >
                        {location._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item container className="ParticipantStyleSelect1">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  //   value={location ? location : 0}
                  //   onChange={locChange}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Location
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={10}>
                    Pune
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={20}>
                    Delhi
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={30}>
                    Mumbai
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
          {gradedata &&
            gradedata.length > 0 &&
            gradedata.map((x, i) => (
              <Grid
                container
                item
                md={4}
                direction="row"
                style={{ marginTop: "15px" }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  value={x.schoolName}
                  onChange={(e) => storeSkill(e, x, i)}
                  className=" AssignActivitesGridPaper  AssignActivites  CheckLabelStyleBlue "
                  label={
                    <Typography style={{ paddingLeft: "15px" }}>
                      {x.schoolName}
                      <span style={{ paddingLeft: "15px" }}>{x.grade}</span>
                    </Typography>
                  }
                />
              </Grid>
            ))}
          {filterdata.length === 0 && (
            <Grid container style={{ marginTop: "10px" }}>
              <Typography className="warningTypography">
                {selectSkillText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Input,
  TextareaAutosize,
  FormGroup,
  Select,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import ManImage from "assets/img/Man.png";
//React Icons
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";

export default function EquipmentProfile() {
  const [value, setValue] = React.useState(0);

  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const [grade, setGrade] = React.useState("");
  const GradeChange = (event) => {
    setGrade(event.target.value);
  };

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };
  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const [checked, setChecked] = React.useState(true);

  const CheckChange = (event) => {
    setChecked(event.target.checked);
  };
  const LabelName = [
    {
      labelname: "View Users",
    },
    {
      labelname: "Edit Users",
    },
    {
      labelname: "Delete Users",
    },
  ];
  const LabelName2 = [
    {
      labelname2: "View ",
    },
    {
      labelname2: "Edit ",
    },
    {
      labelname2: "Delete ",
    },
  ];
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div>

      <Grid container className="ContainerStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} className="RowBackColor2">
          <Grid item className="RowGridStyle2">
            <Grid item container>
              <Grid item container>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={3}
                  className="InputGridStyle"
                  alignItems="baseline"
                >
                  <Typography className="GreyColorTypography ">
                    Organisation
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sm={5}
                  xs={5}
                  className="InputGridStyle"
                  alignItems="baseline"
                >
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" USerDropDownStyle"
                      value={school ? school : 0}
                      onChange={handleChange}
                      label="Select School"
                      color="white"
                      disableUnderline
                    >
                      <MenuItem className="USerDropmenustyle" value={0}>
                        Select Organisation
                      </MenuItem>

                      <MenuItem className="USerDropmenustyle" value={10}>
                        Blue Ridge Public School
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={20}>
                        Zoomer Public School
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={30}>
                        Ridge Public School
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={3}
                  className="InputGridStyle"
                  alignItems="baseline"
                >
                  <Typography className="GreyColorTypography ">
                    Branch{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sm={5}
                  xs={5}
                  className="InputGridStyle"
                  alignItems="baseline"
                >
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" USerDropDownStyle"
                      value={location ? location : 0}
                      onChange={locChange}
                      label="Select Location"
                      disableUnderline
                    >
                      <MenuItem className="USerDropmenustyle" value={0}>
                        Select Branch
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={10}>
                        Pune
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={20}>
                        Delhi
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={30}>
                        Mumbai
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={3}
                  className="InputGridStyle"
                  alignItems="baseline"
                >
                  <Typography className="GreyColorTypography ">
                    Role Copy
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sm={5}
                  xs={5}
                  className="InputGridStyle"
                  alignItems="baseline"
                >
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" USerDropDownStyle"
                      value={location ? location : 0}
                      onChange={locChange}
                      label="Select Location"
                      disableUnderline
                    >
                      <MenuItem className="USerDropmenustyle" value={0}>
                        Select Role
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={10}>
                        XI
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={20}>
                        X
                      </MenuItem>
                      <MenuItem className="USerDropmenustyle" value={30}>
                        XXI
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>{" "}
              </Grid>
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Typography className="GreyColorTypography ">UID</Typography>
              </Grid>
              <Grid
                item
                md={8}
                sm={8}
                xs={8}
                sm={8}
                xs={8}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Input className="UPInput"> </Input>
              </Grid>{" "}
            </Grid>
            <Grid item container>
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Typography className="GreyColorTypography ">
                  Full Name
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                sm={8}
                xs={8}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Input className="UPInput"> </Input>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Typography className="GreyColorTypography ">
                  Mobile Number
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                sm={8}
                xs={8}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Input className="UPInput"> </Input>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                item
                md={3}
                sm={3}
                xs={3}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Typography className="GreyColorTypography ">
                  Email ID
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                sm={8}
                xs={8}
                className="InputGridStyle"
                alignItems="baseline"
              >
                <Input className="UPInput"> </Input>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                md={3}
                sm={3}
                xs={3}
                alignItems="baseline"
                className="InputGridStyle"
              >
                <Typography className="GreyColorTypography ">
                  Addresss
                </Typography>
              </Grid>
              <Grid
                item
                container
                md={8}
                sm={8}
                xs={8}
                sm={8}
                xs={8}
                lg={8}
                className="InputGridStyle"
              >
                <TextareaAutosize
                  className="UserTextArea"
                  aria-label="minimum height"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="RowBackColor2" item md={5} sm={5} xs={5} lg={5}>
          <Grid item container className="RowGridStyle2">
            <Grid container item className="PersonalDetailGrid">
              <Typography className="GreenColorTypography PersonalDetailTypo">
                User Access
              </Typography>
            </Grid>
            <Grid container item>
              <Typography className="BlueColorTypography AccessTypo">
                Access
              </Typography>
            </Grid>
            <Grid container item className="UserControlGrid">
              <Grid item md={4} sm={4} lg={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  User Control
                </Typography>
                {LabelName.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid item md={8} sm={8} lg={8} xs={8} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Organisation Control
                </Typography>
                {LabelName.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      checked={checked}
                      onChange={CheckChange}
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Typography className="BlueColorTypography ModuleCTypo">
                Module Control
              </Typography>
            </Grid>

            <Grid container item>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  School Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Course Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Typography className="GreyColorTypography UserControlTypo">
                  Lesson Plan Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
            </Grid>
            <Grid container item className="ActivityModuleTypo">
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Activity Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      checked={checked}
                      onChange={CheckChange}
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Skills Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Equipment Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import { Grid, Typography } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";

// React Icons
import { IoIosAddCircle } from "react-icons/io";
import ManImage from "assets/img/Man.png";

export default function LearnerTab(props) {
  const { learner, fmsScore, totalPP } = props;

  return (
    <>
      <Grid container className="DialogLTB">
        <Grid item md={2.8} className="L-profile-grid">
          <Grid item>
            {learner?.learnerDetail?.learnerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={learner?.learnerDetail?.learnerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",
                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {learner?.learnerDetail?.fullName?.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {learner?.learnerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                Learner
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.regNo ? (
                  learner?.learnerDetail?.regNo
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {/* {learner?.learnerDetail?.enrollmentDate ?
                 (
                  learner?.learnerDetail?.enrollmentDate.split("T")[0]
                )  */}
                {learner?.createdAt ? (
                  learner?.createdAt.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {fmsScore?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={5}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {totalPP}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.dob ? (
                  learner?.learnerDetail?.dob.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Gender</Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.gender ? (
                  learner?.learnerDetail?.gender
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.fathersName ? (
                  learner?.learnerDetail?.fathersName
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.mothersName ? (
                  learner?.learnerDetail?.mothersName
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9} className="ml-12 L-profile-grid">
          <Grid container>
            <Typography className="width100 BlueColorTypography mb-10">
              School Detail
            </Typography>

            <Grid item md={5} className="mb-10">
              <Grid container>
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    School Name
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.academicDetails?.schoolName ? (
                      learner?.academicDetails?.schoolName
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Location
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    {learner?.academicDetails?.location ? (
                      learner?.academicDetails?.location
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Grade
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.academicDetails?.grade ? (
                      learner?.academicDetails?.grade
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Division
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.academicDetails?.divisionName ||
                    learner?.academicDetails?.division ? (
                      learner?.academicDetails?.divisionName ||
                      learner?.academicDetails?.division
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>{" "}
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Roll Number
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    <Typography className="DarkBlackColorTypo">
                      {learner?.academicDetails?.rollNumber ? (
                        learner?.academicDetails?.rollNumber
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={5} className="mb-10">
              <Grid container>
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    1st Term Star Date
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {learner?.academicDetails?.firstTermStartDate ? (
                      learner?.academicDetails?.firstTermStartDate.split("T")[0]
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    1st Term End Date
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {learner?.academicDetails?.firstTermEndDate ? (
                      learner?.academicDetails?.firstTermEndDate.split("T")[0]
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    2nd Term Start Date
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {learner?.academicDetails?.secondTermStartDate ? (
                      learner?.academicDetails?.secondTermStartDate.split(
                        "T"
                      )[0]
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    2nd Term End Date
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {learner?.academicDetails?.secondTermEndDate ? (
                      learner?.academicDetails?.secondTermEndDate.split("T")[0]
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    3rd Term Start Date
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {learner?.academicDetails?.thirdTermStartDate ? (
                      learner?.academicDetails?.thirdTermStartDate.split("T")[0]
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    3rd Term End Date
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {learner?.academicDetails?.thirdTermEndDate ? (
                      learner?.academicDetails?.thirdTermEndDate.split("T")[0]
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ borderTop: "2px solid #dddddd" }}>
              <Typography className="width100 BlueColorTypography mb-10 mt-10">
                Contact Details
              </Typography>
              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Mobile Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.learnerPhoneNo ? (
                        learner?.contactDetail?.learnerPhoneNo
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Email ID
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.learnerEmail ? (
                        learner?.contactDetail?.learnerEmail
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Full Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.learnerAddress ? (
                        learner?.contactDetail?.learnerAddress
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Pincode
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.learnerZipCode ? (
                        learner?.contactDetail?.learnerZipCode
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>{" "}
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      City
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.learnerCity ? (
                        learner?.contactDetail?.learnerCity
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      State
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {" "}
                      {learner?.contactDetail?.learnerState ? (
                        learner?.contactDetail?.learnerState
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Country
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.learnerCountry ? (
                        learner?.contactDetail?.learnerCountry
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>

              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Name
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.guardianFullName ? (
                        learner?.contactDetail?.guardianFullName
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Location
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.guardianLocation ? (
                        learner?.contactDetail?.guardianLocation
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Email
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.guardianEmail ? (
                        learner?.contactDetail?.guardianEmail
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Mob No.
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {learner?.contactDetail?.guardianPhoneNo ? (
                        learner?.contactDetail?.guardianPhoneNo
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import {
  Input,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  useRadioGroup,
  DialogActions,
  RadioGroup,
  Radio,
  MyFormControlLabel,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { FETCH_URL } from "fetchIp";
import { useEffect } from "react";
import { useLogin } from "Context/ExportContext";
export default function ExtendTab({
  Tabnext3,
  originalData,
  storeSkill,
  submitClick,
}) {
  const { userToken } = useLogin();
  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [schoolData, setSchoolData] = useState([]);
  const [location, setLocation] = React.useState("");
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState([]);

  const locChange = (event) => {
    setLocation(event.target.value);
  };
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <FormControlLabel checked={checked} {...props} />;
  }

  ////////////////////////////////////////////////////////////////////

  // setParticipation(storeSchoolId);

  ///////////////////////////////////////////////////////////////////
  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getSchool = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getAllSchools?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSchoolData(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  const getAllSchool = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/school/getAllSchools`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setSchoolData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllSchool();
    getDistinctSchoolAndLocation();
  }, []);

  return (
    <>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid container style={{ marginLeft: "2%" }} className="mt-10">
          <Grid item className="ParticipantStyleSelect">
            <FormControl className="MainPageFormControl">
              <Select
                className=" DialogSelectdropstyle"
                value={schoolSelected ? schoolSelected : 0}
                // onChange={handleChange}
                onChange={(e) => {
                  getDistinctSchoolAndLocation(e.target.value);
                  setSchoolSelected(e.target.value);
                }}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  Select school
                </MenuItem>

                {schoolNames &&
                  schoolNames.length > 0 &&
                  schoolNames.map((school) => (
                    <MenuItem value={school._id} className="Selectmenustyle">
                      {school._id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item container className="ParticipantStyleSelect1">
            <FormControl className="MainPageFormControl">
              <Select
                className=" Selectdropstyle"
                value={schoolLocationSelected ? schoolLocationSelected : 0}
                onChange={(e) => {
                  setSchoolLocationSelected(e.target.value);
                  getSchool(e.target.value);
                }}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  Select Location
                </MenuItem>
                {schoolLoc &&
                  schoolLoc.length > 0 &&
                  schoolLoc.map((location) => (
                    <MenuItem value={location._id} className="Selectmenustyle">
                      {location._id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Typography className="GreyColorTypography   OrStyleTypography">
              OR
            </Typography>
            <RadioGroup
              className="EventRadioStyle"
              name="use-radio-group"
              defaultValue="first"
            >
              <MyFormControlLabel
                className=" GreenColorTypography RadioLabel"
                value="first"
                label={<Typography>Select ALL</Typography>}
                control={<Radio />}
              />
            </RadioGroup> */}
        <Grid container className="mt-10">
          <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
            {schoolData.map((sData, i) => (
              <Grid
                container
                item
                key={i}
                md={10}
                sm={10}
                xs={10}
                lg={10}
                className=" AssignActivitesGridPaper  AssignActivites  "
              >
                <Grid container item md={12} sm={12} xs={12} lg={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            originalData.length > 0 &&
                            originalData[i] !== null &&
                            originalData[i] !== undefined
                              ? true
                              : false
                          }
                        />
                      }
                      className="CheckLabelStyleBlue"
                      onChange={(e) => storeSkill(e, sData, i)}
                      value={sData.organisationDetails.schoolName}
                      label={
                        <Typography>
                          {" "}
                          {sData.organisationDetails.schoolName}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container>
          <DialogActions className="width100">
            <Typography align="right" className="width100">
              <Button
                className="GreenButton700 hgt-33 b-r10 width-90px mt-10 "
                onClick={submitClick}
              >
                Save
              </Button>{" "}
            </Typography>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

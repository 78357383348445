import React, { useState } from "react";
import { Tab, Box, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";

//core compoenent
import EaSchoolTab from "./KATab's/KaSchool";
import EaCourseTab from "./KATab's/KaGrade";
import EaLearner from "./KATab's/KaLearner";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function EquipmentProfile({ kit }) {
  const [value, setValue] = React.useState(0);
  console.log(kit, "kitkit");
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          {/* <Tab
            className="ActivityTabChanges"
            label="School"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Grade "
            {...a11yProps(1)}
          />{" "} */}
          <Tab
            className="ActivityTabChanges"
            label="Learners "
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>

      {/* <TabPanel value={value} index={0}>
        <EaSchoolTab kitDetails={kitDetails} />
      </TabPanel> */}

      {/* <TabPanel value={value} index={1}>
        <EaCourseTab kitDetails={kitDetails} />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <EaLearner kitDetails={kit} />
      </TabPanel>
    </>
  );
}

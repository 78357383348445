import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@material-ui/core";

//core component
import KitDeleteDialog from "../GEActionDialog/KitActionDialog's/KitDeleteDialog";
import KitSwitchDailog from "../GEActionDialog/KitActionDialog's/KitSwitchDialog";
import GKitProfileDialog from "../GEquipProfile/GKitProfile";

export default function RenameTab(props) {
  const [value, setValue] = React.useState(0);

  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    states: { equipmentKit },
    getAllEquipmentKIT,
  } = props;

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID</TableCell>
              <TableCell className="Head">Equipment KIT Name </TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> Assigned On </TableCell>
              <TableCell className="Head"> Quantity</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {equipmentKit &&
              equipmentKit.length &&
              equipmentKit.map((kit) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link> {kit.equipmentKitUid} </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <Link>
                      {" "}
                      <GKitProfileDialog
                        kit={kit}
                        to="/admin/equipmentkitprofile"
                      />{" "}
                      {/* {kit.equipmentKitName} */}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {kit.equipmentKitDesc}
                  </TableCell>
                  <TableCell className="Col700WAB">14/09/2021 </TableCell>
                  <TableCell className="Col700WAB"> 30 </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

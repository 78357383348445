import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Grid,
  Checkbox,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";

//core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Allocate Equipment Kit
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Equip(props) {
  const {
    states: { assignequipment, openEquip, setOpenEquip },
    storeEquipment,
  } = props;
  const classes = useStyles();
  const { userToken } = useLogin();
  const [scroll, setScroll] = React.useState("paper");
  const [equipments, setEquipmentKit] = useState(null);
  const handleClickOpen = () => {
    setOpenEquip(true);
  };
  const handleClose = () => {
    setOpenEquip(false);
  };
  const getAllEquipmentKIT = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipmentKits`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setEquipmentKit(res.msg);
      } else {
        // console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllEquipmentKIT();
  }, []);

  return (
    <>
      <div style={{ position: "absolute", right: 6, bottom: 0 }}>
        <IoIosAddCircle
          className="fs-28 ActionIcon cursor"
          onClick={handleClickOpen}
        />{" "}
      </div>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={openEquip}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent
          dividers={false}
          className="TabPanelStyle"
          style={{ height: "50vh" }}
        >
          <Grid container className="DailogLTRBStyle-Na">
            {equipments &&
              equipments.length > 0 &&
              equipments.map((name, i) => {
                return (
                  <>
                    <Grid item md={4}>
                      <Grid
                        item
                        md={10}
                        direction="row"
                        className="AssignGridPaper AssignEquipment"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                assignequipment.length > 0 &&
                                assignequipment[i] !== null &&
                                assignequipment[i] !== undefined
                                  ? true
                                  : false
                              }
                            />
                          }
                          className="CheckLabelStyleBlue  BlueColorTypography"
                          onChange={(e) => storeEquipment(e, name, i)}
                          value={name.equipmentKitName}
                          label={
                            <Typography
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "-10px",
                              }}
                            >
                              {name.equipmentKitName}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="GreenButton   CourseEquipSB" onClick={handleClose}>
            Save
          </Button>
          <Button
            className=" GreyButton   CourseEquipCB "
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
//core Component
import OngoingTab from "./OngoingTab";
import CompleteTab from "./CompleteTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LgCourse() {
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Grid item conatiner>
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="GProcessTabStyle"
          >
            <Tab
              className="GProceesTabChanges"
              label="Completed"
              {...a11yProps(0)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Ongoing"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <CompleteTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OngoingTab />
        </TabPanel>
      </DialogContent>
    </>
  );
}

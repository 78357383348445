import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
// React Icons
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";

//core Module
import SelectLearner from "./LearLearnerTab/LSelectLearnerTab";
import LPRename from "./LearLearnerTab/LRenameTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ CourseId, loadUsers }) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);
  const [learnerData, setLearenerData] = React.useState(null);
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data
  const [assignlearnerid, setAssignLearnerId] = React.useState([]);
  const [LPUid, setLPUid] = React.useState("");
  const [LPStartDate, setLPStartDate] = React.useState(null);
  const [LPEndDate, setLPEndDate] = React.useState(null);
  const [LPTitle, setLPTitle] = React.useState("");

  //////////////////////////////////////////api for learner ///////////////////////////
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState([]);
  const [selectGrade, setSelectGrade] = useState([]);
  const [gradeSelected, setGradeSelected] = useState("");
  ///////////////////////////////////////////////////////////////////
  const [selectLearnerText, setSelectLearnerText] = React.useState("");
  const [courseUidText, setCourseUidText] = React.useState("");
  const [courseTitleText, setCourseTitleText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  /////getPaginatedLearner
  const getPaginatedLearner = async (grade) => {
    console.log(schoolSelected, "schoolSelected>>>>>>>>>>>>>>>>>>");
    console.log(
      schoolLocationSelected,
      "schoolLocationSelected>>>>>>>>>>>>>>>>>>"
    );
    console.log(grade, "grade>>>>>>>>>>>>>>>>>>");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated learners==>", res);
        setLearenerData(res.msg);
        // setAssignLearnerId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////

  /////////////////////handle search//////////////////////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      getAllLearner();
    }
    console.log("My handling of search ========>", rowSize);
    const response = await fetch(
      `${FETCH_URL}/api/learner/searchLearner/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLearenerData(res.msg);
      console.log("My learner response from searching ==>", res);

      // getAllGrades();
    } else {
      console.log("Error while searching single school", res.err);
    }
  };
  /////////////////////handle search//////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setLPUid("");
    setLPStartDate(null);
    setLPEndDate(null);
    setLPTitle("");
    setSelectLearnerText("");
    setCourseUidText("");
    setCourseTitleText("");
    setValue(0);
  };

  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    if (filterdata.length === 0) {
      setSelectLearnerText("please select a learner!");
      console.log(selectLearnerText, "selectLearnerText");
      return;
    } else {
      setSelectLearnerText("");
    }
    setValue(newValue);
  };
  function TabNext() {
    if (filterdata.length === 0) {
      setSelectLearnerText("please select a learner!");
      console.log(selectLearnerText, "selectLearnerText");
      return;
    } else {
      setSelectLearnerText("");
    }
    setValue(1);
  }
  /////////////////////////////////////////////////////////////
  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  ////////////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  const learnerId = filterdata.map((a) => ({
    learnerId: a._id,
    notificationToken: a.notificationToken,
    gradeId: a.assignedGrade,
    schoolId: a.assignedSchool,
  }));
  console.log(filterdata, "filterdata >>>in assign Learner>>>");
  console.log(learnerId, "learnerId>>>>> in assign learner>>>>");

  const getAllLearner = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/learner/getAllLearners`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setLearenerData(res.msg);
        setAssignLearnerId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllLearner();
  }, []);
  const courseDuration = [LPStartDate, LPEndDate];
  const submitAssignLearner = async (e) => {
    e.preventDefault();
    ///////
    if (
      LPUid === null ||
      LPUid.length === 0 ||
      (LPUid === undefined && LPTitle === null) ||
      LPTitle === undefined ||
      LPTitle.length === 0
    ) {
      if (LPUid.length === 0) {
        setCourseUidText("please enter course uid !");
      }

      if (LPUid.length === 0) {
        setCourseTitleText("please enter course title !");
      }
      return;
    }
    /////
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/assignCourseToLearner/${CourseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            learnerId: learnerId,
            courseUid: LPUid,
            courseTitle: LPTitle,
            // courseDuration: courseDuration,
          }),
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My Trainer   Post response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
        loadUsers();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Learner">
        <IconButton className="ActionButtonSize">
          <AiOutlineUser
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Select Learner"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectLearner
              states={{
                learnerData,
                setLearenerData,
                schoolLoc,
                setSchoolLoc,
                schoolNames,
                setSchoolNames,
                schoolSelected,
                setSchoolSelected,
                schoolLocationSelected,
                setSchoolLocationSelected,
                locationForGrade,
                setLocationForGrade,
                selectGrade,
                setSelectGrade,
                gradeSelected,
                setGradeSelected,
                selectLearnerText,
              }}
              storeSkill={storeSkill}
              getDistinctSchoolAndLocation={getDistinctSchoolAndLocation}
              getGrades={getGrades}
              getPaginatedLearner={getPaginatedLearner}
              handleSearch={handleSearch}
              originalData={originalData}
              filterdata={filterdata}
              // storeSkill,getDistinctSchoolAndLocation,getGrades,getPaginatedLearner
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LPRename
              states={{
                LPUid,
                setLPUid,
                LPStartDate,
                setLPStartDate,
                LPEndDate,
                setLPEndDate,
                LPTitle,
                setLPTitle,
                courseUidText,
                courseTitleText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={
              value == 0 ? TabNext : value == 1 ? submitAssignLearner : null
            }
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

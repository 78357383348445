import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { IoSchoolOutline } from "react-icons/io5";

//React Icons
import { MdOutlinePayments } from "react-icons/md";
//core Module
// import SelectGrade from "./LPGradeTab/LPSelectGradeTab";
// import LPRename from "./LPGradeTab/LPGRenameTab";

import SelectDivision from "./DivisionTab/selectDivisionTab";
import RenameTab from "./DivisionTab/renameTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ CourseId, loadUsers }) {
  const classes = useStyles();
  const { userToken } = useLogin();
  console.log(" Course ID", CourseId);
  const [scroll, setScroll] = React.useState("paper");

  const [open, setOpen] = React.useState(false);
  const [gradeData, setGradeData] = React.useState(null);
  const [divisionData, setDivisionData] = React.useState(null);

  const [assigngradeid, setAssignGradeId] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data

  const [courseUid, setCourseUid] = React.useState("");
  const [courseTitle, setCourseTitle] = React.useState("");
  const [couseStartDate, setCourseStartDate] = React.useState(null);
  const [courseEndate, setCourseEndDate] = React.useState(null);

  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolGrade, setSchoolGrade] = useState([]);

  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [schoolGradeSelected, setschoolGradeSelected] = useState("");
  ///////////
  const [selectDivisionText, setSelectDivisionText] = React.useState("");
  const [courseUidText, setCourseUidText] = React.useState("");
  const [courseTitleText, setCourseTitleText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setCourseUid("");
    setCourseTitle("");
    setSelectDivisionText("");
    setCourseUidText("");
    setCourseTitleText("");
    setCourseStartDate(null);
    setCourseEndDate(null);
    setSchoolSelected("");
    setSchoolLocationSelected("");
    setschoolGradeSelected("");
    setSchoolNames([]);
    setSchoolLoc([]);
    setSchoolGrade([]);
    setDivisionData(null);
    setValue(0);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    if (filterdata.length === 0) {
      setSelectDivisionText("please select a division!");

      return;
    } else {
      setSelectDivisionText("");
    }
    setValue(newValue);
  };
  function TabNext() {
    if (filterdata.length === 0) {
      setSelectDivisionText("please select a division!");

      return;
    } else {
      setSelectDivisionText("");
    }
    setValue(1);
  }
  useEffect(() => {
    getAllGrade();
    getDistinctSchoolAndLocation(); // for dropdowns
  }, []);
  const getAllGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setGradeData(res.msg);
        setAssignGradeId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  function storeDivision(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  /////////////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  //////////////////////////////////////////////////////////////////////
  console.log(filterdata, "filter data");

  // const assignedLearners = filterdata.map((a) => a.assignedLearners).flat();
  const assignedLearners = filterdata
    .map((a) => a.assignedLearnersWithPushNotificationTokens)
    .flat();
  console.log("assignedLearners123", assignedLearners);
  const dataId = filterdata.map((dId) => {
    return {
      _id: dId._id,
      schoolId: dId.school,
      gradeId: dId.grade,
      schoolName: dId.schoolName,
      location: dId.location,
      grade: dId.gradeName,
    };
  });
  const sendData = {
    divisions: dataId,
    assignedLearners: assignedLearners,
    courseUid: courseUid,
    courseTitle: courseTitle,
    courseDuration: [couseStartDate, courseEndate],
  };

  const AssignGradestore = dataId.flat();
  const submitAssignDivision = async (e) => {
    e.preventDefault();
    ///////
    if (
      courseUid === null ||
      courseUid.length === 0 ||
      (courseUid === undefined && courseTitle === null) ||
      courseTitle === undefined ||
      courseTitle.length === 0
    ) {
      if (courseUid.length === 0) {
        setCourseUidText("please enter course uid !");
      }

      if (courseTitle.length === 0) {
        setCourseTitleText("please enter course title !");
      }
    }
    console.log("sendData111111111", sendData);
    /////
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/assignCourseToDivision/${CourseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(sendData),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My course assign grade Post response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        loadUsers();
        handleClose();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getDivisions = async (gradeName) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDivisionByScNameLocGrade?schoolName=${schoolSelected}&location=${schoolLocationSelected}&gradeName=${gradeName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My DropDown schools ============>", res);
        // setGradeData(res.msg);
        setDivisionData(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      // .error("Catch block ====>", error);
    }
  };
  // const getGrades = async (location) => {
  //   setLocationForGrade(location);
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response for grade 123 ============>", res);
  //       setSelectGrade(res.msg);
  //     } else {
  //       console.log("Error while fetching dropdown data", res.err);
  //     }
  //   } catch (error) {
  //     console.error("Catch block ====>", error);
  //   }
  // };

  const getDropDownSchools = async (location) => {
    try {
      const response = await fetch(
        // `${FETCH_URL}/api/school/grade/getFilteredGrades?schoolName=${schoolSelected}&location=${location}`,
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My DropDown schools ============>", res);
        // setGradeData(res.msg);
        setSchoolGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      // .error("Catch block ====>", error);
    }
  };
  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
          setSchoolGrade([]);
          setSchoolLocationSelected("");
          setschoolGradeSelected("");
          setDivisionData(null);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
          setSchoolGrade([]);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Division">
        <IconButton className="ActionButtonSize">
          <MdOutlinePayments
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Select Division"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectDivision
              states={{
                divisionData,
                schoolLocationSelected,
                setSchoolLocationSelected,
                schoolLoc,
                schoolNames,
                schoolGrade,
                setSchoolSelected,
                schoolGradeSelected,
                setschoolGradeSelected,
                schoolSelected,
                selectDivisionText,
              }}
              originalData={originalData}
              storeDivision={storeDivision}
              getDropDownSchools={getDropDownSchools}
              filterdata={filterdata}
              getDistinctSchoolAndLocation={getDistinctSchoolAndLocation}
              getDivisions={getDivisions}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenameTab
              states={{
                courseUid,
                setCourseUid,
                couseStartDate,
                setCourseStartDate,
                courseEndate,
                setCourseEndDate,
                courseTitle,
                setCourseTitle,
                courseUidText,
                courseTitleText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={
              value == 0 ? TabNext : value == 1 ? submitAssignDivision : null
            }
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

export default function LearnerTab(props) {
  const { learner, fmsScore, pp, totalPP } = props;
  const [locopp, setLocoPP] = React.useState(0);
  const [objpp, setObjPP] = React.useState(0);
  const [bodypp, setBodyPP] = React.useState(0);
  React.useEffect(() => {
    if (pp?.length > 0) {
      pp.map((data) => {
        if (data?._id?.skillName == "Locomotor") {
          setLocoPP(data?.total);
        }
        if (data?._id?.skillName == "Object Control") {
          setObjPP(data?.total);
        }
        if (data?._id?.skillName == "Body Management") {
          setBodyPP(data?.total);
        }
      });
    }
  }, [learner]);

  return (
    <>
      <Grid container className="DialogLTB">
        <Grid item md={2.8} className="L-profile-grid">
          <Grid item>
            {learner?.learnerDetail?.learnerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={learner?.learnerDetail?.learnerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",
                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {learner?.learnerDetail?.fullName?.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {learner?.learnerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                Learner
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.regNo ? (
                  learner?.learnerDetail?.regNo
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {/* {" "}
                {learner?.learnerDetail?.enrollmentDate ? (
                  learner?.learnerDetail?.enrollmentDate.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )} */}
                {learner?.createdAt ? (
                  learner?.createdAt.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {fmsScore?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={5}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {totalPP}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.dob ? (
                  learner?.learnerDetail?.dob.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Gender</Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.gender ? (
                  learner?.learnerDetail?.gender
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.fathersName ? (
                  learner?.learnerDetail?.fathersName
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.mothersName ? (
                  learner?.learnerDetail?.mothersName
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9} className="ml-12 L-profile-grid ">
          <Grid container>
            <Typography className="width100 BlueColorTypography mb-10">
              Academics
            </Typography>

            <Grid item md={5} className="mb-24">
              <Grid container>
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Courses
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedCourses?.length ? (
                      learner?.assignedCourses?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Lessonplan
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    {learner?.assignedLessonPlans?.length ? (
                      learner?.assignedLessonPlans?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Assessments
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedAssessments?.length ? (
                      learner?.assignedAssessments?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={5} className="mb-24">
              <Grid container>
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Equipment
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    {learner?.assignedEquipments?.length ? (
                      learner?.assignedEquipments?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Equipment KIT
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedEquipmentKits?.length ? (
                      learner?.assignedEquipmentKits?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Skills
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedSkills?.length ? (
                      learner?.assignedSkills?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ borderTop: "2px solid #dddddd" }}>
              <Typography className="width100 BlueColorTypography mb-10 mt-10">
                FMS Skill
              </Typography>
              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={7}>
                    <Typography className="BlackColorTypography">
                      Locomotor Skill PP
                    </Typography>
                  </Grid>
                  <Grid item md={4} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {locopp}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={7}>
                    <Typography className="BlackColorTypography">
                      Body Management Skill PP
                    </Typography>
                  </Grid>
                  <Grid item md={4} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {bodypp}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={7}>
                    <Typography className="BlackColorTypography">
                      Object Control Skill
                    </Typography>
                  </Grid>
                  <Grid item md={4} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {objpp}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

//core component
import ContactTab from "./ContactTab";
import AcademicTab from "./AcademicTab";
import DocumentTab from "./DocumentTAb";
import LearnerTab from "./LearnerTab";
import { FETCH_URL } from "fetchIp.js";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function handleEnter(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}
export default function AddDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [datevalue, setDateValue] = React.useState(null);

const [schoolID,setApiSchoolNames] = React.useState(null);
const [gradeID,setFilteredGrades] = React.useState(null);

  const getSchollIdAndGradeId = (newData)=>{
    console.log(newData);
    setApiSchoolNames(newData.apiSchoolNames);
    setFilteredGrades(newData.filteredGrades);
    return newData;
   }
 
 
const schoolId =schoolID && schoolID.length>0 && schoolID.map((scId)=>scId._id);
const gradeId = gradeID && gradeID.length > 0 && gradeID[0]._id;

console.log(schoolId, 'schoolId>>>>>>>in Learner')
console.log(gradeId, 'schoolId>>>>>>>in Learner')


  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DialogClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    console.log(open);
    setOpen(false);
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  function TabNext3() {
    setValue(3);
  }

  const { getAllLearners } = props;
  //For CreateInput
  const [fullName, setFullName] = useState(null);
  const [dob, setDob] = useState(null);
  const [regNo, setRegNo] = useState(null);
  const [enrollmentDate, setEnrollmentDate] = useState(null);
  const [fathersName, setFathersName] = useState(null);
  const [mothersName, setMothersName] = useState(null);
  const [learnerEmail, setLearnerEmail] = useState(null);
  const [learnerPhoneNo, setLearnerPhoneNo] = useState(null);
  const [learnerLocation, setLearnerLocation] = useState(null);
  const [learnerAddress, setLearnerAddress] = useState(null);
  const [learnerZipCode, setLearnerZipCode] = useState(null);
  const [learnerCity, setLearnerCity] = useState(null);
  const [learnerState, setLearnerState] = useState(null);
  const [learnerCountry, setlearnerCountry] = useState(null);
  const [guardianFullName, setGuardianFullName] = useState(null);
  const [guardianLocation, setGuardianLocation] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState(null);
  const [guardingPhoneNo, setGuardingPhoneNo] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [grade, setGrade] = useState(null);
  const [location, setLocation] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [firstTermStartDate, setFirstTermStartDate] = useState(null);
  const [secondTermStartDate, setsecondTermStartDate] = useState(null);
  const [firstTermEndDate, setfirstTermEndDate] = useState(null);
  const [secondTermEndDate, setsecondTermEndDate] = useState(null);

  //Api Calling
  const submitClick = async (e) => {
    e.preventDefault();

    console.log(
      "States incoming =====>",
      fullName,
      dob,
      regNo,
      enrollmentDate,
      fathersName,
      mothersName,
      learnerEmail,
      learnerPhoneNo,
      learnerLocation,
      learnerAddress,
      learnerZipCode,
      learnerCity,
      learnerState,
      learnerCountry,
      guardianFullName,
      guardianLocation,
      guardianEmail,
      guardingPhoneNo,
      schoolName,
      grade,
      location,
      firstTermStartDate,
      secondTermStartDate,
      firstTermEndDate,
      secondTermEndDate,
      rollNumber,
      schoolId,
      gradeId
    );

    try {
      const responce = await fetch(`${FETCH_URL}/api/learner/createLearner`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          fullName,
          dob,
          regNo,
          enrollmentDate,
          fathersName,
          mothersName,
          learnerEmail,
          learnerPhoneNo,
          learnerLocation,
          learnerAddress,
          learnerZipCode,
          learnerCity,
          learnerState,
          learnerCountry,
          guardianFullName,
          guardianLocation,
          guardianEmail,
          guardingPhoneNo,
          schoolName,
          grade,
          location,
          firstTermStartDate,
          secondTermStartDate,
          firstTermEndDate,
          secondTermEndDate,
          rollNumber,
          // schoolId,
          // gradeId

        }),
      });
      let res = await responce.json();
      if (responce.ok) {
        getAllLearners();
        DialogClose();
        console.log("Mah respo =====>", res);
      } else {
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  return (
    <div>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline
          className="AddIcon"
          onClick={handleClickOpen("paper")}
        />
      </IconButton>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Learner Detail"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Contact Detail"
                {...a11yProps(1)}
              />
              <Tab
                className="TabStyle"
                label="Academic Detail"
                {...a11yProps(2)}
              />
              <Tab className="TabStyle" label="Documents" {...a11yProps(2)} />
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>{" "}
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <LearnerTab
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                learnerEmail,
                setLearnerEmail,
                learnerPhoneNo,
                setLearnerPhoneNo,
                learnerLocation,
                setLearnerLocation,
                learnerAddress,
                setLearnerAddress,
                learnerZipCode,
                setLearnerZipCode,
                learnerCity,
                setLearnerCity,
                learnerState,
                setLearnerState,
                learnerCountry,
                setlearnerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
                schoolName,
                setSchoolName,
                grade,
                setGrade,
                location,
                setLocation,
              }}
              Tabnext={TabNext}
              handleEnter={handleEnter}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ContactTab
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                learnerEmail,
                setLearnerEmail,
                learnerPhoneNo,
                setLearnerPhoneNo,
                learnerLocation,
                setLearnerLocation,
                learnerAddress,
                setLearnerAddress,
                learnerZipCode,
                setLearnerZipCode,
                learnerCity,
                setLearnerCity,
                learnerState,
                setLearnerState,
                learnerCountry,
                setlearnerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
                schoolName,
                setSchoolName,
                grade,
                setGrade,
                location,
                setLocation,
              }}
              Tabnext2={TabNext2}
              handleEnter={handleEnter}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AcademicTab
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                learnerEmail,
                setLearnerEmail,
                learnerPhoneNo,
                setLearnerPhoneNo,
                learnerLocation,
                setLearnerLocation,
                learnerAddress,
                setLearnerAddress,
                learnerZipCode,
                setLearnerZipCode,
                learnerCity,
                setLearnerCity,
                learnerState,
                setLearnerState,
                learnerCountry,
                setlearnerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
                schoolName,
                setSchoolName,
                grade,
                setGrade,
                location,
                setLocation,
                firstTermStartDate,
                setFirstTermStartDate,
                secondTermStartDate,
                setsecondTermStartDate,
                firstTermEndDate,
                setfirstTermEndDate,
                secondTermEndDate,
                setsecondTermEndDate,
                rollNumber,
                setRollNumber,
              }}
              getSchollIdAndGradeId={getSchollIdAndGradeId}
              Tabnext3={TabNext3}
              handleEnter={handleEnter}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DocumentTab submitClick={submitClick} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
//core Component
import ContactTab from "./Details";

import EventOverviewTab from "./EventOverview.js";
import DetailsTab from "./Details.js";
import ParticipantsTab from "./ParticipantsDetails.js";
import RewardTab from "./RewardTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);

  //For CreateInput
  const [eventUid, setEventUid] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [assignedPP, setAssignedPP] = useState(null);

  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [enrolledOn, setEnrolledOn] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [overview, setOverview] = useState(null);
  const [board, setBoard] = useState(null);
  const [eventGuidelines, setEventGuidelines] = useState(null);
  const [eventRules, setEventRules] = useState(null);

  // const [participation, setParticipation] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [dateOfCreation, setDateOfCreation] = useState(null);
  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  const [createdBy, setCreatedBy] = useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  console.log(originalData, "originalData>>>>>>");
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      const storeSchoolId = a?._id;
      const newdata = [];
      newdata.push(storeSchoolId);
      const newFilterData = newdata.filter(
        (a) => a !== null && a !== undefined
      );
      console.log(newFilterData, "123567");
      return newFilterData;
    }
  });
  const participation = filterdata.filter((pData) => {
    if (pData !== undefined && pData !== null) {
      return pData._id;
    }
  });

  const DialogClose = () => {
    console.log(open);
    setOpen(false);
    setValue(0);
    setEventUid(null);
    setEventName(null);
    setLocation(null);
    setAddress(null);
    setEnrolledOn(null);
    setStartDate(null);
    setEndDate(null);
    setEventDetails(null);
    setOverview(null);
    setBoard(null);
    setEventGuidelines(null);
    setEventRules(null);
    setRewards(null);
    setDateOfCreation(null);
    setImage(null);
    setCreatedBy(null);
    setOriginalData([]);
    setAssignedPP(null);
  };
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //Events
  const DialogClickOpen = () => {
    setOpen(true);
  };

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function Tabnext2() {
    setValue(2);
  }
  function Tabnext3() {
    setValue(3);
  }
  const {
    states: { eventData },
    getAllEvent,
  } = props;

  //Api Calling

  ////////////// Image Api //////////////////////////
  ////////////// Image Api //////////////////////////

  function formatDate(date) {
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    if (d < 10) d = "0" + d;
    if (m < 10) m = "0" + m;
    return y + "-" + m + "-" + d;
  }

  const submitClick = async (e) => {
    e.preventDefault();
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    // const dOfCreation = new Date(dateOfCreation);
    // const participation = participationDetail();
    // console.log(participation, "i qm");
    try {
      const responce = await fetch(`${FETCH_URL}/api/school/event/addEvent`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          eventName,
          // dateOfCreation: formatDate(dOfCreation),
          assignedPP,
          overview,
          eventUid,
          startDate: formatDate(sDate),
          endDate: formatDate(eDate),
          // image,
          board,
          location,
          eventDetails,
          eventGuidelines,
          eventRules,
          participation: participation.map((a) => a._id),
          rewards,
        }),
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("Mah respo =====>", res);
        // fetchUserDetails();
        getAllEvent();
        DialogClose();
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={DialogClickOpen} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          {/* <h5>{uid}</h5>
          <h3>{secondTermEndDate}</h3> */}
          <Tabs
            value={value}
            onChange={TabChange}
            centered
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Event Overview "
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="  Details" {...a11yProps(1)} />
            <Tab
              className="TabStyle"
              label="Participants Detail"
              {...a11yProps(2)}
            />
            {/* <Tab className="TabStyle" label="Rewards" {...a11yProps(3)} /> */}

            <BootstrapDialogTitle onClose={DialogClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}

        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <EventOverviewTab
              states={{
                eventUid,
                setEventUid,
                eventName,
                setEventName,
                location,
                setLocation,
                address,
                setAddress,
                enrolledOn,
                setEnrolledOn,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                overview,
                setOverview,
                eventDetails,
                setEventDetails,
                board,
                setBoard,
                dateOfCreation,
                setDateOfCreation,
                eventRules,
                setEventRules,
                image,
                setImage,
                file,
                setFile,
                createdBy,
                setCreatedBy,
                assignedPP,
                setAssignedPP,
              }}
              TabNext={TabNext}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DetailsTab
              states={{
                eventUid,
                setEventUid,
                eventName,
                setEventName,
                location,
                setLocation,
                address,
                setAddress,
                enrolledOn,
                setEnrolledOn,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                overview,
                setOverview,
                eventDetails,
                setEventDetails,
                eventGuidelines,
                setEventGuidelines,
                eventRules,
                setEventRules,
              }}
              Tabnext2={Tabnext2}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ParticipantsTab
              Tabnext3={Tabnext3}
              storeSkill={storeSkill}
              originalData={originalData}
              submitClick={submitClick}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <RewardTab
              states={{ rewards, setRewards }}
              submitClick={submitClick}
            />
          </TabPanel> */}
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

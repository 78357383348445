import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoCloseSharp } from "react-icons/io5";
import { BsBook } from "react-icons/bs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import CommonPagination from "components/CommonPagination/CommonPagination";
//core Module
import SelectCourse from "./GCourseTab/SelectCourse";
import RenameTab from "./GCourseTab/RenameTab";
import { useState } from "react";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({
  data,
  newProp,
  setSnackOpen,
  setSnackMsg,
  setSnackerropen,
  setSnackErrMsg,
}) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const { userToken } = useLogin();
  const [course, setAllCourses] = React.useState([]);
  //////////////////////////////////////////////////////
  const [originalData, setOriginalData] = React.useState([]);
  ///////////////////////////////////////////////////////
  const [courseUid, setCourseUid] = React.useState("");
  const [courseTitle, setCourseTitle] = React.useState("");
  const [courseStartDate, setCourseStartDate] = React.useState(null);
  const [courseEndate, setCourseEndDate] = React.useState(null);
  /////////////////////////////////////////////////////////////////
  const [open, setOpen] = React.useState(false);
  const [selectCourseText, setSelectCourseText] = React.useState("");
  const [courseUidText, setCourseUidText] = React.useState("");
  const [courseRenameText, setCourseRenameText] = React.useState("");
  const [courseId, setCourseId] = React.useState("");
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  function getDataFromPagination(a) {
    setPage(a);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setCourseId("");
    setCourseUid("");
    setCourseTitle("");
    setCourseStartDate(null);
    setCourseEndDate(null);
    setSelectCourseText("");
    setCourseUidText("");
    setCourseRenameText("");
    setValue(0);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    // if (filterdata.length === 0) {
    //   setSelectCourseText("please select a course!");
    //   return;
    // }
    if (!courseId) {
      setSelectCourseText("please select a course!");
      return;
    }
    setValue(newValue);
    setSelectCourseText("");
    setCourseUidText("");
    setCourseRenameText("");
  };
  function TabNext() {
    // if (filterdata.length === 0) {
    //   setSelectCourseText("please select a course!");
    //   return;
    // }
    if (!courseId) {
      setSelectCourseText("please select a course!");
      return;
    }
    setValue(1);
    setSelectCourseText("");
    setCourseRenameText("");
    setCourseUidText("");
  }

  function storeCourse(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  console.log(filterdata, "filterdata");
  console.log(
    filterdata.map((a) => a._id),
    "originalData>>12>>>12>>>"
  );
  // const courseId = filterdata.map((a) => a._id);

  ////////////////////////////////////////////////////////////////////////////

  const getAllCourses = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getAllCourses?pagination=true&limit=10&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const filterData = res.msg.filter((courseData) => {
          if (
            // grade.assignedGrades.length ||
            courseData.assignedGrades.length == 0 &&
            //  grade.assignedLearners ||
            courseData.assignedLearners.length == 0 &&
            courseData.assignedDivisions.length == 0 &&
            courseData.assignedTrainers.length == 0
          ) {
            return courseData;
          }
        });
        console.log("My response of all courses==>", res);
        setTotal(res?.lengthData);
        setAllCourses(filterData);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  React.useEffect(() => {
    getAllCourses();
  }, [page]);

  const gradeId = {
    _id: data._id,
    schoolId: data.schoolId,
    schoolName: data.schoolName,
    location: data.location,
    grade: data.grade,
  };

  // const assignedLearners = data.assignedLearners;
  const assignedLearners = data.assignedLearnersWithPushNotificationTokens;
  console.log("assignedLearners", assignedLearners);
  const submitClick = async (e) => {
    e.preventDefault();
    if (
      courseUid === null ||
      courseUid.length === 0 ||
      (courseUid === undefined && courseTitle === null) ||
      courseTitle.length === 0
    ) {
      if (courseUid.length === 0) {
        setCourseUidText("please enter course uid !");
      }

      if (courseTitle.length === 0) {
        setCourseRenameText("please enter course title !");
      }
      return;
    }

    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/assignCourseToGradeFromGrade/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            gradeId: gradeId,
            assignedLearners: assignedLearners,
            courseUid: courseUid,
            courseTitle: courseTitle,
            // courseDuration: [courseStartDate, courseEndate],
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My assign course to trainer ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);

        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////////////////

  return (
    <div>
      <Tooltip title="Course">
        <IconButton className="ActionButtonSize">
          {newProp === 1 && (
            <Typography className="BlueColorTypogarphy">Add Course</Typography>
          )}
          <BsBook className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Select Course" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectCourse
              states={{ course, selectCourseText, courseId, setCourseId }}
              // storeCourse={storeCourse}
              // filterdata={filterdata}
              // originalData={originalData}
            />

            <CommonPagination
              getDataFromPagination={getDataFromPagination}
              total={total}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenameTab
              states={{
                courseUid,
                setCourseUid,
                courseTitle,
                setCourseTitle,
                courseStartDate,
                setCourseStartDate,
                courseEndate,
                setCourseEndDate,
                courseUidText,
                courseRenameText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Switch,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
// React Icons
import { MdEdit } from "react-icons/md";
//core component

export default function OrgnasationTab({ departMentDetail }) {
  const [access, setAccess] = useState(1);

  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log(departMentDetail, "departMentDetail its me yo");

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">Organisation</TableCell>
              <TableCell className="Head">Number of Users</TableCell>
              <TableCell className="Head">Point of Contact</TableCell>
              <TableCell className="Head">Assigned On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departMentDetail.map((dpDetail) => (
              <TableRow>
                <TableCell className="Col700WAB">
                  <Link>{dpDetail.departmentName} </Link>
                </TableCell>
                <TableCell className="Col700WAB">
                  {dpDetail && dpDetail.length > 0 ? dpDetail.length : "--"}
                </TableCell>
                <TableCell className="Col700WAB">
                  <Link>{dpDetail.pointOfContact} </Link>
                </TableCell>
                <TableCell className="Col700WAB">
                  {dpDetail.createdOn.split("T")[0]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import MuiAlert from "@material-ui/lab/Alert";
//React-Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Add Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function handleEnter(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}
export default function CustomizedDialogs({
  schoolUid,
  setSchoolUid,
  schoolName,
  setSchoolName,
  schoolLocation,
  setSchoolLocation,
  gradeName,
  setGradeName,
  getSchoolDetails,
  submitClick,
  open,
  setOpen,
  setSchoolId,
  // gradeData,
}) {
  const { userToken } = useLogin();
  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(90),
      height: theme.spacing(37),
    },
  }));
  const [readgrade, setReadGrade] = useState("");
  const [schoolData, setSchoolData] = useState([]);
  const getAllSchool = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/school/getAllSchools`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setSchoolData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllSchool();
  }, []);
  // useEffect(() => {
  //   if (gradeData) {
  //     setReadGrade(gradeData);
  //   }
  // }, []);
  // let gradeData1 = gradeData.map((item) => Object.value(item));
  // console.log("grdeData ==> ", gradeData1);
  const classes = useStyles();
  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSchoolUid("");
    setSchoolLocation("");
    setSchoolName("");
    setGradeName("");
  };
  const [scroll, setScroll] = React.useState("paper");

  // Grade add states
  return (
    <div>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={handleClickOpen} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid item md={6} sm={6} xs={6} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  School UID <span className="ImpColor"> *</span>
                </Typography>
                {/* <Grid item container md={12} sm={12} xs={12}>
                  <Input
                    className="Input1"
                    value={schoolUid}
                    onChange={(e) => {
                      setSchoolUid(e.target.value);
                      getSchoolDetails(e.target.value);
                    }}
                  ></Input>
                </Grid> */}
                <Grid container item md={12} sm={12} xs={12}>
                  <FormControl
                    variant="standard"
                    className="FormControlInput colorwhite"
                  >
                    <Select
                      className=" SkillsSelectStyle "
                      value={schoolUid}
                      onChange={(e) => {
                        getSchoolDetails(e.target.value);
                      }}
                      color="white"
                      label="Locomotor"
                      disableUnderline
                    >
                      {schoolData && schoolData.length > 0 ? (
                        schoolData.map((school, i) => (
                          <MenuItem
                            key={i}
                            className="Selectmenustyle"
                            value={school.schoolUid}
                          >
                            {school.schoolUid}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          className="Selectmenustyle"
                          value={0}
                          disabled
                        >
                          No Schools Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  Location <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12}>
                  <Input
                    className="Input1"
                    disabled
                    value={schoolLocation}
                  ></Input>
                </Grid>
              </Grid>
            </Grid>
            <Grid direction="column" item md={6} sm={6} xs={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                School Name <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Input className="Input1" disabled value={schoolName}></Input>
              </Grid>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Grade <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Input
                  className="Input1"
                  onChange={(e) => setGradeName(e.target.value)}
                  value={gradeName}
                ></Input>
              </Grid>
              <DialogActions className="SGradeBottom">
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={submitClick}
                >
                  Save
                </Button>
                <Button
                  className="GreyButton GradeCancelB"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Grid,
  Input,
  Checkbox,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { GiSoccerBall } from "react-icons/gi";
//core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import CommonPagination from "components/CommonPagination/CommonPagination";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Allocate Equipment KIT
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Equip(props) {
  const {
    data,
    setSnackOpen,
    setSnackMsg,
    setSnackerropen,
    setSnackErrMsg,
  } = props;

  // const { storeEquipment } = props;
  const [originalData, setOriginalData] = React.useState([]);
  const classes = useStyles();
  const { userToken } = useLogin();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [equipments, setEquipmentKit] = useState(null);
  const [selectEquipmentText, setSelectEquipmentText] = React.useState("");
  // console.log(" Check equipment Data", equipments);
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  function getDataFromPagination(a) {
    setPage(a);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectEquipmentText("");
    setOriginalData([]);
  };
  const getAllEquipmentKIT = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getAllEquipmentKits?pagination=true&limit=10&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTotal(res?.lengthData);
        setEquipmentKit(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllEquipmentKIT();
  }, [page]);
  // console.log("data>>>>>>>>>>>>>>>>>>>>>>>>i am here", data);

  let gradeId = {
    gradeId: data._id,
    schoolId: data.schoolId,
    schoolName: data.schoolName,
    location: data.location,
    grade: data.grade,
    assignedLearners: data.assignedLearners,
  };
  // console.log("gradeId>>>>>>>>>>>>>>>>>>>>>>>>i am here", gradeId);

  const [disabled, setDisabled] = React.useState(true);

  function storeEquipment(e, x, i, quantity) {
    let storeArr = [...originalData];

    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
      setDisabled(false);
    } else {
      storeArr[i] = null;
    }

    setOriginalData(storeArr);
  }

  //////////////////////////////////////////////////////
  const storePercentage = (e, i) => {
    let storeArr = [...originalData];
    // console.log(i);
    if (storeArr[i].isChecked) {
      storeArr[i].quantity = +e;
    } else {
      storeArr[i] = null;
      storeArr[i].quantity = null;
    }
    // console.log(storeArr);
    setOriginalData(storeArr);
  };
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  const assignedEquipmentKit = filterdata.map((a) => {
    return {
      _id: a._id,
      equipmentIds: a.equipmentIds,
      quantity: a.quantity,
    };
  });

  const submitAssignEquipment = async (e) => {
    e.preventDefault();
    if (assignedEquipmentKit.length === 0) {
      setSelectEquipmentText("please select an equipment!");
      return;
    }

    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/assignEquipmentKitToGradeFromGrade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            gradeId: gradeId,
            assignedEquipmentKit: assignedEquipmentKit,
          }),
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("i am the response after equipment assigning", res);
        getAllEquipmentKIT();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        setOriginalData([]);
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log("Error while assigning equipments", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <>
      <Tooltip title="Equipment">
        <IconButton className="ActionButtonSize">
          <GiSoccerBall
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />{" "}
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle-Na">
            {equipments &&
              equipments.length > 0 &&
              equipments.map((name, i) => {
                return (
                  <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
                    <Grid
                      container
                      item
                      md={10}
                      sm={10}
                      xs={10}
                      lg={10}
                      className="AssignGridPaper AssignEquipment"
                    >
                      <Grid container item md={8} sm={8} xs={8} lg={8}>
                        <FormControlLabel
                          control={<Checkbox />}
                          className="CheckLabelStyleBlue  BlueColorTypography"
                          onChange={(e) => storeEquipment(e, name, i)}
                          value={name.equipmentKitName}
                          label={
                            <Typography
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "-10px",
                              }}
                            >
                              {name.equipmentKitName}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid container direction="column" item md={1}>
                        <IoCloseSharp className="EquiCloseIcon" />
                      </Grid>
                      <Grid container direction="column" item md={3}>
                        <Input
                          className="EquipmentInputCheck"
                          disabled={disabled}
                          onChange={(e) => storePercentage(e.target.value, i)}
                        >
                          {" "}
                        </Input>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          {filterdata.length === 0 && (
            <Grid container style={{ marginTop: "10px", marginLeft: "30px" }}>
              <Typography className="warningTypography">
                {selectEquipmentText}
              </Typography>
            </Grid>
          )}
          <CommonPagination
            getDataFromPagination={getDataFromPagination}
            total={total}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="GreenButton   CourseEquipSB"
            onClick={submitAssignEquipment}
          >
            Save
          </Button>
          <Button
            className=" GreyButton   CourseEquipCB "
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

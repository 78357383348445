import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import {
  Box,
  Tab,
  Tabs,
  Radio,
  RadioGroup,
  DialogContent,
  FormControlLabel,
  Typography,
  Grid,
  FormControl,
  Button,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
// React Icons
//Core Component
import GradeProfile from "./GradeProfileTab's/GradeDetailsTab/GradeDetailTab";
import LearnerRadioA from "./GradeProfileTab's/GLearnerTab/GLearnerTable's/LearnerRadioA";
import LearnerRadioB from "./GradeProfileTab's/GLearnerTab/GLearnerTable's/LEarnerRadioB";
import AddGLearner from "./GradeProfileTab's/GLearnerTab/GAddLearner/GLearnerAdd";
import GTrainer from "./GradeProfileTab's/GTrainerTab/GTrainer";
import GCourse from "./GradeProfileTab's/GCourseTab/GCourse";
import GradeDivision from "./GradeProfileTab's/GradeDivision/GradeDivision";
import GSkill from "./GradeProfileTab's/Skills/GSkills";
import GEquipment from "./GradeProfileTab's/GEquipmentsTab/GEqipments";
import GLessonPlan from "./GradeProfileTab's/GLessonPlanTab/GlessonPlan";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import AddDivisonDialogs from "../AddDialog/AddDivisonDialog";
import GLearnerUploadExcel from "../GradeProfile's/GradeProfileTab's/GLearnerTab/GLearnerUploadExcel";
import LoaderDialog from "components/Loader/LoaderDialog";
function TabPanel(props) {
  const [loading, setLoading] = useState(true);
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function BasicTabs({ gradeId, gradeData, getAllGrades }) {
  const { userToken } = useLogin();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [divisionName, setDivisionName] = React.useState(0);

  const location = useLocation();
  const { learnerData, getAllLearners, data } = location.state;

  const [rowData, setRowData] = useState(null);
  const [divisonData, setDivisonData] = useState([]);
  const [learnerDivisonData, setLearnerDivisonData] = useState([]);
  ////////////////////////////////////////////////////
  // const [values, setValues] = React.useState([]);
  const [selected, setSelected] = useState("");
  const [divisionDataId, setDivisionDataId] = useState("");
  // console.log(gradeId, "gradeId>>>>>>>>>>>>>>>>>asd>>>>>>>>>>>>>>>asd");
  //////////////////////////////////////////////////////
  useEffect(() => {
    if (data) {
      handleSchoolSearch();
    }
  }, [data]);

  const handleSchoolSearch = async () => {
    console.log("Check Data schoolUid ", data?.schoolUid);
    const response = await fetch(
      `${FETCH_URL}/api/school/getSingleSchool/${data?.schoolUid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My School response from searching ==>", res);
    } else {
    }
  };

  const getAllDivisons = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setDivisonData(res.msg);
        console.log("My Grade Divison response ==>", res);
      } else {
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      console.log("Error while getting divison =======>", error);
    }
  };

  /////////////////////////////get paginate learner////////////////////////////

  const getPaginatedLearner = async (divisionId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedLearner?division=${divisionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLearnerDivisonData(res.msg);
        console.log("My learner Divison response ==>", res);
      } else {
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      console.log("Error while getting divison =======>", error);
    }
  };

  /////////////////////////////get paginate learner////////////////////////////
  useEffect(() => {
    getAllDivisons(gradeId);
  }, []);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleChange(e, datas) {
    setSelected(e.target.value);
    setDivisionName(datas.props.children);
  }

  /////////////////////////////////////////////////////////////
  // const defaultDivison = divisonData.map((a) => a.division);
  // console.log(defaultDivison[0], "defaultDivison>>>");
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            className="ActivityTabChanges"
            label="Grade Detail"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Divisions"
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Learner"
            {...a11yProps(2)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Trainer"
            {...a11yProps(3)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Course"
            {...a11yProps(4)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Lesson Plans"
            {...a11yProps(5)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Skills"
            {...a11yProps(6)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Equipment"
            {...a11yProps(7)}
          />
        </Tabs>
      </Box>
      <LoaderDialog loading={loading} />

      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <GradeProfile
            rowData={rowData}
            data={data}
            divisonData={divisonData}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GradeDivision
            rowData={rowData}
            data={data}
            divisonData={divisonData}
            gradeId={gradeId}
            getAllDivisons={getAllDivisons}
            gradeData={gradeData}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container className="  colorwhite DivisionStyleGridRadio ">
            <Grid container className="DiviStyle">
              <Grid item>
                <AddDivisonDialogs
                  gradeId={gradeId}
                  getAllDivisons={getAllDivisons}
                  gradeData={gradeData}
                />
              </Grid>
              <Grid item>
                <FormControl>
                  {/* <Typography className="BlueColorTypography">
                  {" "}
                  Please Select Divisons{" "}
                </Typography> */}
                  <Select
                    style={{
                      border: "1px solid #dddddd",
                      padding: "5px",
                      borderRadius: "8px",
                    }}
                    defaultValue={0}
                    value={selected ? selected : 0}
                    onChange={(e, datas) => {
                      handleChange(e, datas);
                      setDivisionDataId(e.target.value);
                      getPaginatedLearner(e.target.value);
                    }}
                  >
                    <MenuItem className="BlueColorTypography" value={0}>
                      Select Divisons
                    </MenuItem>

                    {divisonData?.map((data, index) => {
                      return (
                        <MenuItem key={index} value={data._id}>
                          {data.division}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                // className="DiviStyle"
              >
                <Grid container>
                  <AddGLearner
                    getPaginatedLearner={getPaginatedLearner}
                    gradeIds={gradeId}
                    divisionDataId={divisionDataId}
                    gradeData={gradeData}
                    rowData1={rowData}
                    getAllLearners={getAllLearners}
                  />
                  <GLearnerUploadExcel
                    gradeData={gradeData}
                    gradeId={gradeId}
                    rowData1={rowData}
                    divisonData1={divisonData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <LearnerRadioA
            gradeId={gradeId}
            learnerDivisonData={learnerDivisonData}
            divisionDataId={divisionDataId}
            getPaginatedLearner={getPaginatedLearner}
            divisionName={divisionName}
          />
          {/* {radiovalue === "A" ? (
            <LearnerRadioA gradeId={gradeId} learnerData={learnerData} />
          ) : (
            <LearnerRadioB gradeId={gradeId} learnerData={learnerData} />
          )} */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GTrainer data={data} gradeId={gradeId} setLoading={setLoading} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <GCourse data={data} setLoading={setLoading} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <GLessonPlan data={data} setLoading={setLoading} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <GSkill data={data} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <GEquipment data={data} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

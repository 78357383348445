import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//Core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import GCourseProfile from "./GCourseProfile/GCProfile";
export default function GTrainerTab({ data, gradeId }) {
  const [paginatedCourse, setPaginatedCourse] = useState([]);
  const { userToken } = useLogin();

  const getPaginatedCourses = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getPaginatedCourse?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of PaginatedCourses==>", res.msg);
        setPaginatedCourse(res.msg);
      } else {
        console.log("Error while fetching PaginatedCourses", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getPaginatedCourses(gradeId);
  }, []);

  return (
    <>
      {/* <div className="DivisionStyleGrid"> */}
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Course Name </TableCell>
              <TableCell className="ColHead"> Description</TableCell>
              <TableCell className="ColHead"> Assigned On</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedCourse?.map((pCourse, i) => (
              <TableRow key={i}>
                <TableCell className="Col700WAB">{pCourse.courseUid}</TableCell>
                <TableCell className="Col700WAB">
                  {pCourse?.courseTitle}
                  {/* <Link>
                    <GCourseProfile post={pCourse} />
                  </Link> */}
                </TableCell>
                <TableCell className="Col700WAB">
                  {pCourse?.courseDesc}
                </TableCell>
                <TableCell className="Col700WAB">
                  {" "}
                  {pCourse?.assignedOn.split("T")[0]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {/* <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div> */}
      {/* </div> */}
    </>
  );
}

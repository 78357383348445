import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Input,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
//Icons
import { IoIosAddCircle } from "react-icons/io";
export default function ExtendTab(props) {
  const {
    states: {
      schoolName,
      setSchoolName,
      location,
      setLocation,
      address,
      setAddress,
      zipCode,
      setZipCode,
      city,
      setCity,
      state,
      setState,
      country,
      setCountry,
      representativeName,
      setRepresentativeName,
      representativeEmailId,
      setRepresentativeEmailId,
      representativeMobilePhoneNo,
      setRepresentativeMobilePhoneNo,
      representativeAddress,
      setRepresentativeAddress,
      rimage,
      setRImage,
      rfile,
      setRFile,
    },
    Tabnext2,
    handleEnter,
  } = props;
  const [postalData, setPostalData] = React.useState([]);
  const [error, setError] = useState();

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  function RImageUploader(e) {
    console.log(e.target.files);
    setRFile(URL.createObjectURL(e.target.files[0]));
    setRImage(e.target.files[0]);
  }
  const [postCodeMsg, setPostCodeMsg] = React.useState("");

  const searchByPostalCode = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    console.log(response, "response");
    if (response.ok) {
      const Postaldata = res;
      console.log(res, "resres");
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setPostCodeMsg("please enter a valid post code!");
        setCity("");
        setState("");
        setCountry("");
        return;
      }
      setPostCodeMsg("");
      setPostalData(Postaldata);
      setCity(Postaldata?.map((a) => a?.PostOffice[0].District)[0]);
      setState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setCountry(Postaldata?.map((a) => a?.PostOffice[0].Country)[0]);
    }
  };
  React.useEffect(() => {
    if (String(zipCode).length === 6) {
      searchByPostalCode(zipCode);
    } else {
      console.log("iiiiiiiiiiiiiiiiiii");
    }
    if (String(zipCode).length < 6) {
      setCity("");
      setState("");
      setCountry("");
    }
  }, [zipCode]);

  return (
    <form>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6}>
          <Grid direction="column" item style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography GreyColorTypography">
              School Name <span className="ImpColor"> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                onKeyDown={handleEnter}
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location <span className="ImpColor"> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                onKeyDown={handleEnter}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                {" "}
              </Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Address <span className="ImpColor"> *</span>
            </Typography>{" "}
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <TextareaAutosize
                className="ContactTextArea"
                aria-label="minimum height"
                onKeyDown={handleEnter}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></TextareaAutosize>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Zip Code <span className="ImpColor"> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                type="number"
                onKeyDown={handleEnter}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value.slice(0, 6))}
              >
                {" "}
              </Input>
              {postCodeMsg.length > 0 && (
                <Typography className="warningTypography">
                  {postCodeMsg}
                </Typography>
              )}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              City <span className="ImpColor"> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                onKeyDown={handleEnter}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled
              >
                {" "}
              </Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              State <span className="ImpColor"> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                onKeyDown={handleEnter}
                value={state}
                onChange={(e) => setState(e.target.value)}
                disabled
              >
                {" "}
              </Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Country <span className="ImpColor"> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                onKeyDown={handleEnter}
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                disabled
              >
                {" "}
              </Input>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid item direction="column">
            <Typography className="InputTitleTypography GreyColorTypography">
              Representative Name
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                onKeyDown={handleEnter}
                value={representativeName}
                onChange={(e) => setRepresentativeName(e.target.value)}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Representative Email id
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                type="email"
                onKeyDown={handleEnter}
                value={representativeEmailId}
                onChange={(e) => {
                  if (e.target.value == "") {
                    setError("");
                  } else if (!isEmail(e.target.value)) {
                    setError("invalid email");
                  } else {
                    setError("");
                  }
                  setRepresentativeEmailId(e.target.value);
                }}
                // onChange={(e) => setRepresentativeEmailId(e.target.value)}
              >
                {" "}
              </Input>
              <span
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                {error}
              </span>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Representative Mobile Number
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                type="number"
                onKeyDown={handleEnter}
                value={representativeMobilePhoneNo}
                onChange={(e) =>
                  setRepresentativeMobilePhoneNo(e.target.value.slice(0, 10))
                }
              >
                {" "}
              </Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Representative Address
              {/* <span className="ImpColor"> *</span> */}
            </Typography>{" "}
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <TextareaAutosize
                className="ContactTextArea"
                aria-label="minimum height"
                onKeyDown={handleEnter}
                value={representativeAddress}
                onChange={(e) => setRepresentativeAddress(e.target.value)}
              />
            </Grid>
            <Grid item md={10} sm={10} xs={10} lg={10} c>
              <Typography className=" BlueColorTypography ">
                Add Photo
              </Typography>
              <Typography className="width-100  ">
                <img
                  src={rfile}
                  style={{
                    height: "250px",
                    width: "100%",
                    objectFit: "contain",
                    marginTop: "10px",
                  }}
                />
              </Typography>
              <Grid
                item
                style={{ margin: "10px 5px" }}
                className="ChooseFileButtonItem"
              >
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/*"
                  onChange={RImageUploader}
                />{" "}
                <label htmlFor="raised-button-file">
                  <Button
                    className="GreenButton "
                    variant="raised"
                    component="span"
                  >
                    <IoIosAddCircle className="AddCircleIcon" />
                    Choose File
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
          <DialogActions className="DialogActionButton">
            <Button
              className="GreenButton700 ACNextButton  "
              onKeyDown={handleEnter}
              onClick={Tabnext2}
            >
              Next
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </form>
  );
}

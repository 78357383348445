import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
// ReactIcons
import { IoCloseSharp } from "react-icons/io5";
import { BsFileEarmark } from "react-icons/bs";
import { FETCH_URL } from "fetchIp";

import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(90),
    height: theme.spacing(37),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Add Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  trainerId,
  setSchoolNamesForAddGrade,
  schoolNamesForAddGrade,
  setLocationsForAddGrade,
  locationsForAddGrade,
  setSelectedGradeIndex,
  selectedGradeIndex,
  getDistinctSchoolAndLocation, // props from Trainer.js
  getAllTrainers,
}) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [locmotor, setSchool] = useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };
  const [open, setOpen] = useState(false);

  const [selectedSchoolName, setSelectedSchoolName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [grades, setGrades] = useState([]);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [selectSchoolNameText, setSelectSchoolNameText] = useState("");
  const [selectSchoolLocationText, setSelectSchoolLocationText] = useState("");
  const [selectSchoolGradeText, setSelectSchoolGradeText] = useState("");
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // Get distinct school and location
  useEffect(() => {
    if (!open) {
      setSelectedSchoolName("");
      setSelectedLocation("");
      setSelectedGradeIndex("");
      setGrades([]);
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedSchoolName("");
    setSelectedLocation("");
    setSelectedGradeIndex("");
    setSelectSchoolNameText("");
    setSelectSchoolLocationText("");
    setSelectSchoolGradeText("");
    setGrades([]);
  };

  const getAllGradesOfSchool = async (location) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?schoolName=${selectedSchoolName}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("Grades incoming ======>", res.msg);
        setGrades(res.msg);
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  // Assigning Trainer to a grade

  const assignTrainerToGrade = async () => {
    if (
      selectedSchoolName.length === 0 ||
      selectedLocation.length === 0 ||
      selectedGradeIndex.length === 0
    ) {
      if (selectedSchoolName.length === 0) {
        setSelectSchoolNameText("please select a school name!");
      } else {
        setSelectSchoolNameText("");
      }
      if (selectedLocation.length === 0) {
        setSelectSchoolLocationText("please select school location!");
      } else {
        setSelectSchoolLocationText("");
      }
      if (selectedGradeIndex.length === 0) {
        setSelectSchoolGradeText("please select a grade!");
      } else {
        setSelectSchoolGradeText("");
      }
    }

    try {
      const response1 = await fetch(
        `${FETCH_URL}/api/school/grade/getSingleGrade/${grades[selectedGradeIndex]._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res1 = await response1.json();

      const response = await fetch(
        `${FETCH_URL}/api/learner/assignTrainerToGrade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            trainerId,
            location: selectedLocation,
            schoolId: grades[selectedGradeIndex].schoolId,
            schoolName: selectedSchoolName,
            gradeId: grades[selectedGradeIndex]._id,
            gradeName: grades[selectedGradeIndex].grade,
            departmentId: res1?.msg?.schoolId?.departmentDetails?.departmentId,
            departmentName:
              res1?.msg?.schoolId?.departmentDetails?.departmentName,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("Trainer Assigned ======>", res.msg);
        // Apply SnackBar here
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllTrainers();
        handleClose();
      } else {
        console.log("Error while fetching distinct school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Grade">
        <IconButton className="ActionButtonSize">
          <BsFileEarmark
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  School Name <span className="ImpColor"> *</span>
                </Typography>
                <Grid container item md={12} sm={12} xs={12} lg={12}>
                  <FormControl
                    variant="standard"
                    className="FormControlInput"
                    style={{ backgroundColor: "white" }}
                  >
                    <Select
                      className=" SkillsSelectStyle "
                      color="white"
                      disableUnderline
                      value={selectedSchoolName ? selectedSchoolName : 0}
                      onChange={(e) => {
                        getDistinctSchoolAndLocation(e.target.value, true);
                        setSelectedSchoolName(e.target.value);
                      }}
                    >
                      <MenuItem className="Selectmenustyle" value={0} disabled>
                        Select School Name
                      </MenuItem>
                      {schoolNamesForAddGrade &&
                        schoolNamesForAddGrade.length > 0 &&
                        schoolNamesForAddGrade.map((schoolName) => (
                          <MenuItem
                            className="Selectmenustyle"
                            value={schoolName._id}
                          >
                            {schoolName._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {selectedSchoolName.length === 0 && (
                    <Typography className="warningTypography">
                      {selectSchoolNameText}
                    </Typography>
                  )}
                </Grid>

                <Typography className="InputTitleTypography  GreyColorTypography">
                  Grade<span className="ImpColor"> *</span>
                </Typography>
                <Grid container item md={12} sm={12} xs={12} lg={12}>
                  <FormControl
                    variant="standard"
                    className="FormControlInput"
                    style={{ backgroundColor: "white" }}
                  >
                    <Select
                      className=" SkillsSelectStyle "
                      value={
                        selectedGradeIndex || selectedGradeIndex === 0
                          ? selectedGradeIndex
                          : "selectGrade"
                      }
                      onChange={(e) => {
                        setSelectedGradeIndex(e.target.value);
                        console.log(
                          "My event's target =======>",
                          e.target.value
                        );
                      }}
                      color="white"
                      disableUnderline
                    >
                      <MenuItem
                        className="Selectmenustyle"
                        value={"selectGrade"}
                        disabled
                      >
                        Select Grade
                      </MenuItem>
                      {grades &&
                        grades.length > 0 &&
                        grades.map((grade, index) => (
                          <MenuItem
                            className="Selectmenustyle"
                            value={index}
                            key={index}
                          >
                            {grade.grade}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {selectedGradeIndex.length === 0 && (
                    <Typography className="warningTypography">
                      {selectSchoolGradeText}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid direction="column" item md={6} sm={6} xs={6} lg={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Location<span className="ImpColor"> *</span>
              </Typography>
              <Grid container item md={12} sm={12} xs={12} lg={12}>
                <FormControl
                  variant="standard"
                  className="FormControlInput"
                  style={{ backgroundColor: "white" }}
                >
                  <Select
                    className=" SkillsSelectStyle "
                    value={selectedLocation ? selectedLocation : 0}
                    onChange={(e) => {
                      getAllGradesOfSchool(e.target.value);
                      setSelectedLocation(e.target.value);
                    }}
                    color="white"
                    disableUnderline
                  >
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      Select Location
                    </MenuItem>
                    {locationsForAddGrade &&
                      locationsForAddGrade.length > 0 &&
                      locationsForAddGrade.map((location) => (
                        <MenuItem
                          className="Selectmenustyle"
                          value={location._id}
                        >
                          {location._id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {selectedLocation.length === 0 && (
                  <Typography className="warningTypography">
                    {selectSchoolLocationText}
                  </Typography>
                )}
              </Grid>

              <DialogActions style={{ marginTop: "65px " }}>
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={assignTrainerToGrade}
                >
                  Save
                </Button>
                <Button
                  className="GreyButton GradeCancelB"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

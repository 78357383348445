import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";

export default function CompletetTab({ courseOnGoing, totalAssignedPP }) {
  return (
    <>
      <Grid container style={{ minHeight: "70vh" }}>
        <div className="ResponsiveTable width100">
          <Table className="Tableroot">
            <TableHead>
              <TableCell className="Head"> UID </TableCell>
              <TableCell className="Head">Course name</TableCell>
              <TableCell className="Head">Start Date</TableCell>{" "}
              <TableCell className="Head">End Date</TableCell>
              <TableCell className="Head"> Total PP</TableCell>
              <TableCell className="Head"> Price</TableCell>
            </TableHead>

            <TableBody>
              {courseOnGoing &&
                courseOnGoing?.length > 0 &&
                courseOnGoing?.map((Data) => {
                  let total = 0;
                  Data.lessonPlans.map((datas) => {
                    total = total + datas.assignedPP;
                  });
                  return (
                    <TableRow>
                      <TableCell className="Col">{Data?.courseUid}</TableCell>
                      <TableCell className="Col">{Data?.courseTitle}</TableCell>
                      <TableCell className="Col ActionButtonSize">
                        {/* {Data?.courseDuration[0]?.split("T")[0]} */}
                        {Data?.newStartDate?.split("T")[0]}
                      </TableCell>
                      <TableCell className="Col">
                        {/* {Data?.courseDuration[1]?.split("T")[0]} */}
                        {Data?.newEndDate?.split("T")[0]}
                      </TableCell>
                      <TableCell className="Col">{total} </TableCell>

                      <TableCell className="Col">
                        {Data?.coursePrice}{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {courseOnGoing?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Course Assign!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

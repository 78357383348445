import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
//core component
import GTSwitchDialog from "../../../../ActionDialog/SwitchDialog";
import GTDeleteDialog from "../../../../ActionDialog/DeleteDialog";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="" {...other}>
      {/* <Typography className="DialogTitleTypographyMediuem">
        Equipment Details
      </Typography> */}
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(); //
  const [access, setAccess] = useState(1);

  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <div>
      <Button className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        Zoomer Sport Program
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <Grid container className="DailogLTRBStyle-Na">
          <Grid item md={8} sm={8} xs={8} direction="column">
            <Grid
              container
              sm={12}
              md={12}
              xs={12}
              item
              style={{
                backgroundColor: "white",
              }}
            >
              <Grid
                container
                item
                sm={2}
                md={2}
                xs={2}
                style={{ paddingLeft: "17px" }}
              >
                <Typography className="BlueColorTypography GCourseTypoLtp">
                  PTL-101
                </Typography>
              </Grid>
              <Grid container item md={3} sm={3} xs={3}>
                <Typography className="BlueColorTypography GCourseTypoLtp">
                  Play To Learn
                </Typography>
              </Grid>
              <Grid container itemmd={4} sm={4} xs={4} sm={4} xs={4}>
                <Typography className="BlueColorTypography GCourseTypoLtp">
                  Price:
                  <span className="GreenColorTypography GCourseTypoLtp">
                    ₹ 6000/-
                  </span>
                </Typography>
              </Grid>
              <Grid container item md={3} sm={3} xs={3} justify="center">
                <Typography className="BlackColorTypography GCourseTypoLtp">
                  Duration : 3 Months
                </Typography>
              </Grid>
            </Grid>
            <Typography
              className="BlackColorTypography  "
              style={{
                marginTop: "10px ",
                marginBottom: "10px ",
                marginLeft: "17px",
                fontSize: "17px",
              }}
            >
              Description
            </Typography>
            <Grid
              container
              item
              style={{
                backgroundColor: "white",
                paddingLeft: "17px",
                paddingTop: "10px ",
                paddingBottom: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography className="">
                {" "}
                Through the program and with the help of the Multi-Skill Kit
                your child will be able to better these following skills:
                <Typography className="BlueColorTypography">
                  Interception, Prediction, Dynamic Balance, Striking, Throwing,
                  Catching.{" "}
                </Typography>{" "}
                The program has been aptly designed by international epperts in
                the early childhood physical education space to ensure that
                limitations of age and ability are strictly taken into mind
                while delivering the course{" "}
              </Typography>
            </Grid>
            <Typography
              className="BlackColorTypography"
              style={{
                marginTop: "10px ",
                marginBottom: "10px ",
                marginLeft: "17px",
                fontSize: "17px",
              }}
            >
              Lesson PLans{" "}
            </Typography>

            <div
              className="ResponsiveTableHaedBlack"
              style={{ marginRight: "22px" }}
            >
              <Table className="Tableroot">
                <TableHead>
                  <TableRow className="HeadAL">
                    <TableCell className="ColHead">ID</TableCell>
                    <TableCell className="ColHead">Name </TableCell>
                    <TableCell className="ColHead"> Created on</TableCell>
                    <TableCell className="ColHead"> Action </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {posts.slice(page, rowsPerPage).map((post) => (
                    <TableRow>
                      <TableCell className="Col">LP-PTL01</TableCell>
                      <TableCell className="Col">Loreum Ipsum </TableCell>
                      <TableCell className="Col">11/09/2021</TableCell>
                      <TableCell className="Action">
                        <IconButton className="ActionButtonSize">
                          <GTSwitchDialog accessCheck={access} />
                        </IconButton>
                        <GTDeleteDialog />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Grid item md={4} sm={4} xs={4} direction="column"></Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Box,
  Select,
  FormControl,
  makeStyles,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";

//Recact Icons

import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

//core compoent
import OraganisationTab from "./EditOrgTab";
import UserTab from "./EditUserTab";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({
  dptData,
  departmentId,
  getDepartmentDetails,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
        scroll={scroll}
      >
        <Box>
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label=" Organisation" {...a11yProps(0)} />
            {/* <Tab className="TabStyle" label="Organisation" {...a11yProps(1)} /> */}
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </Box>
        {/* TabsClose       */}
        <DialogContent dividers={false}>
          <TabPanel value={value} index={0}>
            <OraganisationTab
              getDepartmentDetails={getDepartmentDetails}
              dptData={dptData}
              dePartmentId={departmentId}
              open={open}
              setOpen={setOpen}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
// ReactIcons
import { MdEdit } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
//Core Component
// import LPDelete from "../ActionDialogs/LearnerActionButton's/LearnerDelete";
import LPSwitch from "../ActionDialogs/LearnerActionButton's/LearnerSwitch";
import AssignLearner from "../ActionDialogs/LearnerActionButton's/AssignLearner/LearnerMainDialog";
import AssignSchool from "../ActionDialogs/LearnerActionButton's/LearnerAssignSchool/LASchoolMainDialog";
import AssignGrade from "../ActionDialogs/LearnerActionButton's/LearnerGradeDialog/LPGradeMainDialog";
import AssignDivision from "../ActionDialogs/LearnerActionButton's/LearnerDivisionDialog/LPDivisionMainDialog";
import AddToMarketPlace from "../ActionDialogs/LearnerActionButton's/AddLearnerToMarketPlace";
export default function LearnerTable(props) {
  const {
    states: {
      foldername,
      learnerLessonPlans,
      setLearnerLessonPlans,
      total,
      pageNoL,
      setPageNoL,
      totalL,
      setTotalL,
      rowsPerPageL,
      setRowsPerPageL,
      searchDataL,
      setSearchDataL,
    },
    GetFolder,
    getLearnerLessonPlan = { getLearnerLessonPlan },
    LeanerPaginatedData = { LeanerPaginatedData },
  } = props;

  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  // const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);

  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [radiovalue, setRadioValue] = React.useState("Skill");

  const RadioChange = (event) => {
    console.log("Radio Check", event.target.value);
    setRadioValue(event.target.value);
  };
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPageL(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  // useEffect(() => {
  //   getLearnerLessonPlan();
  // }, []);

  /**
   * The function TabChange is a function that takes in an event and a newValue and sets the value to the
   * newValue.
   * @param event - The event that triggered the change.
   * @param newValue - The new value of the selected tab.
   */

  /////////////////////////////////////////////////////// Status Chnage Api //////////////////////////
  console.log("learnerLessonPlans", learnerLessonPlans);

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID </TableCell>
              <TableCell className="Head"> Lesson Plan Name </TableCell>
              <TableCell className="Head"> Assigned PP</TableCell>
              <TableCell className="Head"> Created On</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>

          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {learnerLessonPlans &&
              learnerLessonPlans.length > 0 &&
              learnerLessonPlans.map((lplearner) => {
                let newDate = new Date(lplearner.createdOn);

                return (
                  <TableRow>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: "/admin/lessonplanprofile",
                          state: { lplearner },
                        }}
                      >
                        {lplearner.LPUid}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: "/admin/lessonplanprofile",
                          state: { lplearner },
                        }}
                      >
                        {lplearner.LPTitle}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">{lplearner.LPXp}</TableCell>

                    <TableCell className="Col">
                      {newDate.getDate()} /{newDate.getMonth() + 1}/{" "}
                      {newDate.getFullYear()}
                    </TableCell>
                    <TableCell className="Action">
                      <AddToMarketPlace
                        getLearnerLessonPlan={getLearnerLessonPlan}
                        lplearner={lplearner}
                      />
                      <IconButton className="ActionButtonSize">
                        <AssignLearner
                          getLearnerLessonPlan={getLearnerLessonPlan}
                          LearnerId={lplearner._id}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <AssignGrade
                          getLearnerLessonPlan={getLearnerLessonPlan}
                          LearnerId={lplearner._id}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <AssignDivision
                          getLearnerLessonPlan={getLearnerLessonPlan}
                          LpId={lplearner._id}
                        />
                      </IconButton>

                      {/* <IconButton className="ActionButtonSize">
                        <LPSwitch
                          getLearnerLessonPlan={getLearnerLessonPlan}
                          lessonplanId={lplearner._id}
                          access={lplearner.access}
                        />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {learnerLessonPlans?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No LessonPlan Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {searchDataL || learnerLessonPlans?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              LeanerPaginatedData(value, value2);
              setPageNoL(value);
              // getLearnerLessonPlan(value, value2);
            }}
            pageSize={rowsPerPageL}
            total={totalL}
            current={pageNoL}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

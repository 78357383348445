import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(100),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitle pt-22 hgt-40" {...other}>
      <Typography className="DialogTitleTypographyBlue fs-16  pt-3 ">
        Feedback
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SkilsDialog(props) {
  //   const {
  //     states: { d },
  //   } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  // const [originalData, setOriginalData] = React.useState([]); // original array with undefined data

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = useState([
    { type: "Star" },
    { type: "Theortical" },
  ]);

  return (
    <div>
      <Typography
        align="center"
        className="GreenColorTypography mt-5px  cursor"
        onClick={handleClickOpen}
      >
        View Feedback
      </Typography>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <>
            {value.map((data, index) => {
              return (
                <>
                  {data.type === "Star" ? (
                    <Grid
                      container
                      spacing={2}
                      className={
                        index === 0
                          ? "feadbacktypo-Grid"
                          : " mt-20 feadbacktypo-Grid"
                      }
                    >
                      <Grid item md={2}>
                        <Typography className="DarkBlackColorTypo width100">
                          Type
                        </Typography>

                        <Typography className="GreyColorTypography500 mt-10  feadbacktypo-style">
                          Star
                        </Typography>
                      </Grid>
                      <Grid item md={10}>
                        <Typography className="DarkBlackColorTypo width100">
                          Question
                        </Typography>
                        <Typography className="GreyColorTypography500 mt-10 feadbacktypo-style ">
                          How will you rate this course? How will you rate this
                          course?
                        </Typography>
                        <Typography className="GreyColorTypography500 width-90px  mt-10  feadbacktypo-style">
                          Rating : 3
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      className={
                        index === 0
                          ? "feadbacktypo-Grid"
                          : " mt-20 feadbacktypo-Grid"
                      }
                    >
                      <Grid item md={2}>
                        <Typography className="DarkBlackColorTypo width100">
                          Type
                        </Typography>

                        <Typography className="GreyColorTypography500 mt-10  feadbacktypo-style">
                          Theortical
                        </Typography>
                      </Grid>
                      <Grid item md={10}>
                        <Typography className="DarkBlackColorTypo width100">
                          Question
                        </Typography>
                        <Typography className="GreyColorTypography500 mt-10 feadbacktypo-style ">
                          How will you rate this course? How will you rate this
                          course?
                        </Typography>
                        <Typography className="DarkBlackColorTypo width100 mt-10 ">
                          Answer
                        </Typography>
                        <Typography className="GreyColorTypography500 mt-10 feadbacktypo-style ">
                          It was pathetic.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  MenuItem,
  Input,
  Select,
  InputAdornment,
  FormControl,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import LoaderDialog from "components/Loader/LoaderDialog";
// // material-ui icons
import { GoSearch } from "react-icons/go";
// core components
import AddSkillDialog from "./AddSkills/SkillAdd";
import BodyMgtTab from "./SkillTab/BodyMgtTab";
import ObjMgtTab from "./SkillTab/ObjMgtTab";
import LocomotorTab from "./SkillTab/LocomotorTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables() {
  const { userToken } = useLogin();
  const [skillsData, setSkillsData] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [trainer, setTrainer] = React.useState("");
  const [opne, setOpen] = React.useState(true);
  const [mainSkill, setMainSkill] = React.useState(null);
  const [subSkillName, setSubSkillName] = React.useState(null);
  const [subSkillUid, setSubSkillUid] = React.useState(null);
  const [subSkillDesc, setSubSkillDesc] = React.useState(null);
  const [xpAssigned, setXpAssigned] = React.useState(null);
  const [level, setLevel] = React.useState(null);
  const [searchLoco, setSearchLoco] = useState("");
  const [pageNoLoco, setPageNoLoco] = useState(1);
  const [totalLoco, setTotalLoco] = useState(10);
  const [searchBody, setSearchBody] = useState("");
  const [pageNoBody, setPageNoBody] = useState(1);
  const [totalBody, setTotalBody] = useState(10);
  const [searchObj, setSearchObj] = useState("");
  const [pageNoObj, setPageNoObj] = useState(1);
  const [totalObj, setTotalObj] = useState(10);
  const [rubricsQts, setRubricsQts] = React.useState([
    {
      quesNo: "",
      quesDesc: "",
      quesMarks: "",
      quesType: "",
      minValue: "",
    },
  ]);
  ////////////////////////////////////////////////////////////////
  const [schoolLoc, setSchoolLoc] = useState("");
  const [schoolNames, setSchoolNames] = useState("");
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState("");
  const [selectGrade, setSelectGrade] = useState("");
  const [gradeSelected, setGradeSelected] = useState("");
  const [loading, setLoading] = useState(true);
  ////////////////////////////////////////////////////////////
  const getAllSkill = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/skill/viewAllSkills`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setSkillsData(res.msg);
        setLoading(false);
      } else {
        console.log("Error while fetching trainers", res.err);
        setLoading(false);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const searchSkill = async (skillId) => {
    try {
      let mainSkill;
      switch (value) {
        case 0:
          mainSkill = "Locomotor";
          break;
        case 1:
          mainSkill = "Body Management";
          break;
        case 2:
          mainSkill = "Object Control";
          break;
        default:
          console.error("Invalid Value");
      }
      const response = await fetch(
        `${FETCH_URL}/api/skill/getPaginatedSkill?mainSkill=${mainSkill}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Skills==>", res);
        if (value == 0) {
          setTotalLoco(res.lengthData);
        } else if (value == 1) {
          setTotalBody(res.lengthData);
        } else if (value == 2) {
          setTotalObj(res.lengthData);
        }
        setSkillsData(res.msg);
      } else {
        // setSkillsData([]);
        console.log("Error while fetching Skills", res.err);
      }
      setLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // // Get Single API
  // const getSingleSkill = async () => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/skill/getSingleSkill/${schoolUId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response of Trainers==>", res);
  //       setSkillsData(res.msg);
  //     } else {
  //       console.log("Error while fetching trainers", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };

  //Delete API
  const deleteSkills = async (SkillsId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/skill/deleteSkill/${SkillsId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response ==>", res);
        // getSingleSkill();
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  //Edit API
  const submitClick = async (e, SkillsId) => {
    // For Editing
    e.preventDefault();

    const response = await fetch(
      `${FETCH_URL}/api/skills/editSkills/${SkillsId._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          mainSkill: mainSkill ? mainSkill : trainer.mainSkill,
          subSkillName: subSkillName ? subSkillName : trainer.subSkillName,
          subSkillUid: subSkillUid ? subSkillUid : trainer.subSkillUid,
          subSkillDesc: subSkillDesc ? subSkillDesc : trainer.subSkillDesc,
          xpAssigned: xpAssigned ? xpAssigned : trainer.xpAssigned,
          level: level ? level : trainer.level,
          rubricsQts: rubricsQts
            ? rubricsQts
            : trainer.contactDetail.rubricsQts,
        }),
      }
    );
    const res = await response.json();
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      searchSkill();
      setOpen(false);
      // setTabValue(0);
    } else {
      console.log("Error from learnerEdited =====>", res);
    }
  };

  //Event function

  const TabChange = (event, newValue) => {
    setValue(newValue);
    setSchoolSelected("");
    setSchoolLocationSelected("");
    setGradeSelected("");
  };

  //////////////////////////////////////////////////////////////////

  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////
  const getPaginatedSkills = async (grade) => {
    try {
      let mainSkill;
      switch (value) {
        case 0:
          mainSkill = "Locomotor";
          break;
        case 1:
          mainSkill = "Body Management";
          break;
        case 2:
          mainSkill = "Object Control";
          break;
        default:
          console.error("Invalid Value");
      }
      const response = await fetch(
        `${FETCH_URL}/api/skill/viewAllSkills?mainSkill=${mainSkill}&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated Skills @123==>", res);
        setSkillsData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  /////////////////////////////////////////////////////////////////////////

  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      searchSkill();
      return;
    }
    let mainSkill;
    switch (value) {
      case 0:
        mainSkill = "Locomotor";
        break;
      case 1:
        mainSkill = "Body Management";
        break;
      case 2:
        mainSkill = "Object Control";
        break;
      default:
        console.error("Invalid Value");
    }
    const response = await fetch(
      `${FETCH_URL}/api/skill/getPaginatedSkill?search=${searchValue.trim()}&mainSkill=${mainSkill}&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setSkillsData(res.msg);

      console.log("My skill response from searching ==>", res);
      // getAllGrades();
    } else {
      console.log("Error while searching skills", res.err);
    }
  };

  useEffect(() => {
    getDistinctSchoolAndLocation();
    searchSkill();
  }, [value]);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Skill Library</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                {value == 0 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search Locomotor Skill"
                    value={searchLoco}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      setSearchLoco(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : value == 1 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search Body Management Skill"
                    value={searchBody}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      setSearchBody(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : value == 2 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search Object Control"
                    value={searchObj}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      setSearchObj(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : null}
              </FormControl>
            </div>
          </Box>
          <AddSkillDialog
            skillsData={skillsData}
            // getAllSkill={getAllSkill}
            // CreateSkills={CreateSkills}
            searchSkill={searchSkill}
            states={{
              mainSkill,
              setMainSkill,
              subSkillName,
              setSubSkillName,
              subSkillUid,
              setSubSkillUid,
              subSkillDesc,
              setSubSkillDesc,
              xpAssigned,
              setXpAssigned,
              level,
              setLevel,
              rubricsQts,
              setRubricsQts,
            }}
          />
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid container>
        <Grid item md={3} sm={3} xs={3} lg={3}>
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              // onChange={handleChange}
              label="Select School"
              color="white"
              disableUnderline
              onChange={(e) => {
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
              }}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select school
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getGrades(e.target.value);
              }}
              label="Select Location"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={gradeSelected ? gradeSelected : 0}
              label="Select Location"
              onChange={(e) => {
                getPaginatedSkills(e.target.value);
                setGradeSelected(e.target.value);
              }}
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Grade
              </MenuItem>
              {selectGrade &&
                selectGrade.length > 0 &&
                selectGrade.map((a) => (
                  <MenuItem className="Selectmenustyle" value={a._id}>
                    {a._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Locomotor"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Body Management "
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label=" Object Control "
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <LocomotorTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            rubricsQts,
            setRubricsQts,
            searchLoco,
            setSearchLoco,
            pageNoLoco,
            setPageNoLoco,
            totalLoco,
            setTotalLoco,
          }}
          getAllSkill={getAllSkill}
          skillsData={skillsData}
          deleteSkills={deleteSkills}
          submitClick={submitClick}
          searchSkill={searchSkill}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BodyMgtTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            rubricsQts,
            setRubricsQts,
            searchBody,
            setSearchBody,
            pageNoBody,
            setPageNoBody,
            totalBody,
            setTotalBody,
          }}
          getAllSkill={getAllSkill}
          skillsData={skillsData}
          deleteSkills={deleteSkills}
          submitClick={submitClick}
          searchSkill={searchSkill}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ObjMgtTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            rubricsQts,
            setRubricsQts,
            searchObj,
            setSearchObj,
            pageNoObj,
            setPageNoObj,
            totalObj,
            setTotalObj,
          }}
          getAllSkill={getAllSkill}
          skillsData={skillsData}
          deleteSkills={deleteSkills}
          searchSkill={searchSkill}
        />
      </TabPanel>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Box,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  IconButton,
  FormControl,
  DialogActions,
  Select,
} from "@material-ui/core";
//Recact Icons
import { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";

import { useHistory } from "react-router-dom";

export default function User({
  usrData,
  getUserDetails,
  setOpen,
  open,
  setSnackOpen,
  setSnackMsg,
  setSnackerropen,
  setSnackErrMsg,
}) {
  const { userToken } = useLogin();
  const history = useHistory();
  // const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  const [locmotor, setSchool] = React.useState("");
  const [uid, setUid] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [role, setRole] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [address, setAddress] = useState(null);
  const [location, setLocation] = useState(null);
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [email, setEmail] = useState(null);
  const [state, setState] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState(usrData?.imageUrl);
  const [departMentDetail, setDepartMentDetails] = useState();
  const [departMent, setDepartMent] = useState([]);

  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/auth/getAllDepartments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        setDepartMentDetails(res);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const imageUpload = async (userId) => {
    console.log(" User Id Check", userId);

    const formdata = new FormData();
    formdata.set("userId", userId);
    formdata.set("uid", uid);
    formdata.append("file", image);

    // console.log("I am the form data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/auth/addUserImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", res);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      console.error("imgError>>>>>>>>>>>>", error);
    }
  };

  const submitClick = async (e) => {
    // For Editing
    e.preventDefault();

    console.log(
      JSON.stringify({
        // courseUid: courseUid ? courseUid : courseData.courseUid,
        fullName: fullName ? fullName : usrData.fullName,
        role: role ? role : usrData.role,
        phoneNo: phoneNo ? phoneNo : usrData.phoneNo,
        address: address ? address : usrData.address,
        dateOfJoining: dateOfJoining ? dateOfJoining : usrData.dateOfJoining,
        state: state ? state : usrData.state,
        zipCode: zipCode ? zipCode : usrData.zipCode,
      })
    );
    // return;
    const response = await fetch(
      `${FETCH_URL}/api/auth/editUserDetails/${usrData._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          // courseUid: courseUid ? courseUid : courseData.courseUid,
          fullName: fullName ? fullName : usrData.fullName,
          role: role ? role : usrData.role,
          phoneNo: phoneNo ? phoneNo : usrData.phoneNo,
          address: address ? address : usrData.address,
          dateOfJoining: dateOfJoining ? dateOfJoining : usrData.dateOfJoining,
          state: state ? state : usrData.state,
          zipCode: zipCode ? zipCode : usrData.zipCode,
        }),
      }
    );
    const res = await response.json();
    console.log(res, "yo i am res man");
    let userId = usrData._id;
    if (userId) {
      imageUpload(userId);
      console.log("Response after create >> ", userId);
      //
    }
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      setSnackOpen(true);
      setSnackMsg(res.msg);
      getUserDetails();
      setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
      setSnackerropen(true);
      setSnackErrMsg(res.err);
    }
  };

  ////////  api call for Deparment details ////////

  React.useEffect(() => {
    getDepartmentDetails();
    setRole(usrData.role);
  }, []);

  console.log(usrData, "usrData>>>>>>>>>>>>>>>");
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid container spacing={4} justify="flex-start">
          <Grid item md={6} sm={6} xs={6} lg={6}>
            <Grid
              container
              direction="column"
              item
              style={{ marginLeft: "8%" }}
            >
              <Typography className="UserTitleTypography GreyColorTypography">
                First Name <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={usrData.fullName}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Role <span style={{ color: "red" }}> *</span>
              </Typography>
              {/* <Typography className="UserTitleTypography GreyColorTypography">
                Role <span className="ImpColor"> *</span>
              </Typography> */}
              <Grid container item style={{ width: "83.8%" }}>
                <FormControl variant="standard" className="FormControlInput">
                  <Select
                    className=" SkillsSelectStyle "
                    value={role}
                    defaultValue={usrData.role}
                    onChange={(e) => setRole(e.target.value)}
                    color="white"
                    label="Locomotor"
                    disableUnderline
                  >
                    {/* <MenuItem className="Selectmenustyle" value={0}>
                      Superadmin
                    </MenuItem> */}
                    <MenuItem className="Selectmenustyle" value={1}>
                      Sub-Admin
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={2}>
                      Hos
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={3}>
                      Principal
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1" 
                value={role}
                onChange={(e)=>setRole(e.target.value)}> </Input>
              </Grid> */}

              <Typography className="UserTitleTypography GreyColorTypography">
                Mobile Number <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={usrData.phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  value={phoneNo}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Address <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="UserTextArea"
                  aria-label="minimum height"
                  onChange={(e) => setAddress(e.target.value)}
                  defaultValue={usrData.address}
                  value={address}
                />
              </Grid>
              <Grid
                item
                style={{
                  borderBottom: "1px solid #2E3180",
                  backgroundColor: "white",
                  width: "96%",
                }}
              >
                <Typography
                  style={{ marginLeft: "15px", width: "100%" }}
                  className="LPTypo BlueColorTypography"
                >
                  Add Thumbnail
                  <img
                    src={file}
                    style={{
                      height: "100%",
                      width: "340px",
                      objectFit: "contain",
                      marginTop: "10px",
                    }}
                  />
                </Typography>{" "}
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  multiple
                  accept="image/*"
                  onChange={ImageUploader}
                />{" "}
                <label htmlFor="raised-button-file">
                  <Button
                    style={{ margin: "15px 15px" }}
                    className="GreenButton700  "
                    variant="raised"
                    component="span"
                    // className={classes.button}
                  >
                    <IoIosAddCircle className="AddCircleIcon" />
                    Choose File
                  </Button>
                </label>
                {/* <input
                className="   LPATChooseButton "
                type="file"
                id="image-input"
                accept="image/jpeg, image/png, image/jpg"
              /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={6}
            lg={6}
            direction="column"
            style={{ marginTop: "1px" }}
          >
            <Grid container direction="column" item>
              {/* <Typography className="UserTitleTypography GreyColorTypography">
                Location
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={usrData.location}
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                ></Input>
              </Grid> */}
              <Typography className="InputTitleTypography GreyColorTypography">
                DepartMent<span className="ImpColor"> *</span>
              </Typography>
              <Grid container item md={10} sm={10} xs={10}>
                {/* <FormControl
                  variant="standard"
                  className="FormControlInput colorwhite"
                >
                  <Select
                    className=" SkillsSelectStyle "
                    value={departMent}
                    // onChange={(e) => {
                    //   setDepartMent(e.target.value);
                    //   setSchoolName(e.target.value.departmentName);
                    //   getSchoolLocationBySchoolName(
                    //     e.target.value.departmentName
                    //   );
                    //   // getSchoolLocation(e.target.value);
                    // }}
                    onKeyDown={handleEnter}
                    color="white"
                    label="Locomotor"
                    disableUnderline
                  >
                    {departMentDetail &&
                    Object.keys(departMentDetail).length > 0 ? (
                      departMentDetail.msg.map((dprData, i) => (
                        <MenuItem
                          key={i}
                          className="Selectmenustyle"
                          value={dprData}
                        >
                          {dprData.departmentName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem className="Selectmenustyle" value={0} disabled>
                        No Department Available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl> */}
                <Input
                  className="Input1"
                  value={usrData.departmentId.departmentName}
                  disabled
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Date of joining
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={usrData.dateOfJoining}
                  onChange={(e) => setDateOfJoining(e.target.value)}
                  value={dateOfJoining}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Email Id
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={usrData.email}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                State
                <span style={{ color: "red" }}> *</span>
              </Typography>{" "}
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={usrData.state}
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Zip Code
                <span style={{ color: "red" }}> *</span>
              </Typography>{" "}
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                {" "}
                <Input
                  className="Input1"
                  onChange={(e) => setZipCode(e.target.value)}
                  defaultValue={usrData.zipCode}
                  value={zipCode}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                UID
                <span style={{ color: "red" }}> *</span>
              </Typography>{" "}
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                {" "}
                <Input
                  className="Input1"
                  defaultValue={usrData.uid}
                  disabled
                  onChange={(e) => setUid(e.target.value)}
                  value={uid}
                >
                  {" "}
                </Input>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions className="">
        <Button
          className="GreenButton700  EquipActionBottom"
          style={{ marginRight: "10%" }}
          onClick={(e) => submitClick(e)}
        >
          Save
        </Button>
        {/* <Button
          className="GreyButton700 EquipActionBottom"
          style={{ marginRight: "2.7%" }}
          // onClick={handleClose}
        >
          Cancel
        </Button> */}
      </DialogActions>
    </>
  );
}

// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Box, Tab, Tabs, DialogContent, Grid } from "@material-ui/core";
// //core module
// import TrainerLessonPlanResult from "./ResultTab/RActivity";
// import LearnerLessonPlanResult from "./ResultTab/RLessonPlan";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// export default function BasicTabs(props) {
//   const [value, setValue] = React.useState(0);
//   const {
//     states: { learnerAssesmentResult, setLearnerAssesmentResult },
//     getAssesmentResults,
//   } = props;

//   const TabChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const label = { inputProps: { "aria-label": "Switch demo" } };
//   console.log(learnerAssesmentResult, "learnerAssesmentResult>>>>>>>>>>>");
//   return (
//     <>
//       <Grid
//         container
//         style={{
//           marginTop: "20px",
//         }}
//       >
//         <Grid item conatiner>
//           <Tabs
//             value={value}
//             onChange={TabChange}
//             aria-label="basic tabs example"
//             className="AResultTabStyle"
//           >
//             <Tab
//               className="AResultTabChanges"
//               label="Learner"
//               {...a11yProps(0)}
//             />
//             {/* <Tab
//               className="AResultTabChanges"
//               label="trainer"
//               {...a11yProps(1)}
//             />{" "} */}
//           </Tabs>
//         </Grid>
//       </Grid>
//       <DialogContent dividers={false} className="TabPanelStyle">
//         <TabPanel value={value} index={0}>
//           <LearnerLessonPlanResult
//             states={{ learnerAssesmentResult, setLearnerAssesmentResult }}
//             getAssesmentResults={getAssesmentResults}
//           />
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           <TrainerLessonPlanResult />
//         </TabPanel>
//         {/* <TabPanel value={value} index={2}>
//           <Skills />
//         </TabPanel> */}
//       </DialogContent>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";

//Core Component
import ResultDelete from "../AssesActionDialog/ResultActionDialog/ResultDeleteDialog";
import ResultSwitch from "../AssesActionDialog/ResultActionDialog/ResultSwitchDialog";
import ResultEdit from "../AssesActionDialog/ResultActionDialog/ResultEditDialog/ResultEdit";
import AssignGrade from "../AssesActionDialog/ResultActionDialog/ResultAssignGrade/ARGradeMainDialog";

export default function BasicTabs(props) {
  const {
    states: {
      learnerAssesmentResult,
      schoolSelected,
      setLearnerAssesmentResult,
      totalR,
      pageR,
      searchR,
    },
    getAssesmentResults,
    handleSearch,
  } = props;
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [value, setValue] = React.useState(0);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  // const loadUsers = async () => {
  //   const res = await axios.get(
  //     "https://jsonplaceholder.typicode.com/comments "
  //   );
  //   setPosts(res.data);
  //   setTotal(res.data.length);
  // };

  useEffect(() => {
    // loadUsers();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">Assessment Uid</TableCell>
              <TableCell className="Head">Assessment Name</TableCell>

              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> PP</TableCell>
              {/* <TableCell className="Head"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {learnerAssesmentResult.map((lAresult, i) => (
              <TableRow key={i}>
                <TableCell className="Col ActionButtonSize">
                  <Link
                    to={{
                      pathname: "/admin/resultlessonplanprofile",
                      state: {
                        lAresult,
                      },
                    }}
                  >
                    {lAresult.assessmentData[0].uid}{" "}
                  </Link>
                </TableCell>
                <TableCell className="Col">
                  {lAresult.assessmentData[0].assessmentName}
                </TableCell>
                <TableCell className="Col">
                  <Typography className="GreyColorTypography500 overflow-style">
                    {lAresult.assessmentData[0].assessmentDesc}
                  </Typography>
                </TableCell>
                <TableCell className="Col">
                  {lAresult.assessmentData[0].assignedXp}
                </TableCell>

                {/* <TableCell className="Col">
                  loreum ipsum loreum ipsum loreum ipsum
                </TableCell> */}
                {/* <TableCell className="Action"> */}
                {/* <ResultEdit /> */}
                {/* <IconButton className="ActionButtonSize">
                    <AssignGrade />
                  </IconButton> */}
                {/* <ResultSwitch accessCheck={access} /> */}

                {/* <IconButton className="ActionButtonSize">
                    <ResultDelete />
                  </IconButton> */}
                {/* </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {learnerAssesmentResult?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Result Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected ||
      searchR ||
      learnerAssesmentResult?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => handleSearch("", value, value2)}
            pageSize={rowsPerPage}
            total={totalR}
            current={pageR}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
export default function ExtendTab(props) {
  const {
    states: { gradeValue, setGradeValue, gradeData, schoolDetail },
    submitClick,
  } = props;

  return (
    <form>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          Grid
          item
          md={6}
          sm={6}
          xs={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <Grid container direction="column" item style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography GreyColorTypography">
              School Name
            </Typography>
            <Grid container item md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput colorwhite"
              >
                <Select
                  className=" SkillsSelectStyle "
                  value={schoolDetail?.schoolName}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                  disabled
                >
                  <MenuItem
                    className="Selectmenustyle"
                    value={schoolDetail?.schoolName}
                    disabled
                  >
                    {schoolDetail?.schoolName}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <FormControl variant="standard" className="FormControlInput">
                <Select
                  className=" SkillsSelectStyle "
                  value={schoolDetail?.location}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                  disabled
                >
                  <MenuItem
                    disabled
                    className="Selectmenustyle"
                    value={schoolDetail?.location}
                  >
                    {schoolDetail?.location}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Grade
              {/* <span className="ImpColor"> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <FormControl
                variant="standard"
                className="FormControlInput colorwhite"
              >
                <Select
                  className=" SkillsSelectStyle "
                  value={gradeValue ? gradeValue : 0}
                  onChange={(e) => {
                    setGradeValue(e.target.value);
                  }}
                  color="white"
                  label="Locomotor"
                  disableUnderline
                >
                  <MenuItem value={0} className="Eventmenustyle" disabled>
                    Select grade
                  </MenuItem>
                  {gradeData && gradeData.length > 0 ? (
                    gradeData?.map((mapGrade, i) => (
                      <MenuItem
                        key={i}
                        className="Selectmenustyle"
                        value={mapGrade}
                      >
                        {mapGrade?.grade}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="Selectmenustyle" value={0} disabled>
                      No Grades available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container md={10} sm={10} xs={10}>
              <Button className="DocumnetSubmit" onClick={submitClick}>
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Toolbar, Box, Typography, Tab, Tabs } from "@material-ui/core";
import CourseTab from "./CourseTab";
import LessonPlanTab from "./LessonPlanTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tables() {
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Marketplace</Typography>
      </Toolbar>

      <Box className="mt-16">
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="TabsCustom-Style   "
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Course"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Lessonplan"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="width100">
        <CourseTab />
      </TabPanel>
      <TabPanel value={value} index={1} className="width100">
        <LessonPlanTab />
      </TabPanel>
    </>
  );
}

import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IconButton, Button, Breadcrumbs, Grid } from "@material-ui/core";

// Core components
import SchoolTab from "./SchoolTab";
import Edit from "../SchoolActionDialog.js/EditDialog/EditDialog";
import Delete from "../SchoolActionDialog.js/DeleteDialog";
import Switch from "../SchoolActionDialog.js/SwitchDialog";

// material-ui icons
import { IoIosArrowBack } from "react-icons/io";

export default function SchoolDetails(props) {
  const [access, setAccess] = useState(1);
  const history = useHistory();

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const location = useLocation();

  const { data } = location.state;
  const { fetchData } = location.data;
  console.log("profileData ", data._id);
  return (
    <>
      {/* <div className="SDDiv">
        <IoIosArrowBack
          className="SDArrowIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          variant="h6"
          component="div"
          className="SDArrowButton BlueButton700"
        >
          Back
        </Button>
      </div> */}

      <div className="SDDiv">
        <Grid container>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Button
                onClick={() => history.goBack()}
                disableRipple
                variant="h6"
                component="div"
                className="SDBreadC"
              >
                <div className="SDBreadCTitle1">School</div>
              </Button>

              <div className="BreadcrumbColor   SDTitle2Style  ">
                School Detail
              </div>
            </Breadcrumbs>
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            item
            lg={8}
            md={6}
            xs={12}
          >
            <div className="SDSchoolNameTitle BlueColorTypography ">
              {data.organisationDetails.schoolName}
              {/* <IconButton className="ActionButtonSize">
                <Edit data={data} fetchUserDetails={fetchData} />
              </IconButton>
              <IconButton className="ActionButtonSize">
                <Switch accessCheck={access} />
              </IconButton>
              <IconButton className="ActionButtonSize">
                <Delete schoolId={data._id} />
              </IconButton> */}
            </div>
          </Grid>
        </Grid>
      </div>

      <SchoolTab />
    </>
  );
}

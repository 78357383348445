import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Grid,
  Input,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  Box,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsBarChartLine } from "react-icons/bs";
import { FaPercent } from "react-icons/fa";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import CommonPagination from "components/CommonPagination/CommonPagination";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem" style={{}}>
        Assign Skill
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SkillDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const {
    data,
    gradeData,
    setSnackOpen,
    setSnackMsg,
    setSnackerropen,
    setSnackErrMsg,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    setOpen(false);
    setSelectSkillText("");
    setOriginalData([]);
    setBMOriginalData([]);
    setOCOriginalData([]);
  };
  const [value, setValue] = React.useState(0);
  const [locomotor, setLocomotor] = React.useState([]);
  const [locomotorP, setLocomotorP] = useState([]);
  const [bodyManagementP, setBodyManagementP] = useState([]);
  const [objectControlP, setObjectControlP] = useState([]);
  const [BodyMgt, setBodyMgt] = React.useState([]);
  const [objcontrol, setObjectControl] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [selectSkillText, setSelectSkillText] = React.useState("");
  // /////////////////////////////////////////////////////////////
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  function getDataFromPagination(a) {
    setPage(a);
  }
  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkillName: x.subSkillName,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  // ////////////////////////////////////////////////////////////////
  const [bmoriginalData, setBMOriginalData] = React.useState([]);
  function BMstoreSkill(e, x, i) {
    let storeArr = [...bmoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkillName: x.subSkillName,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setBMOriginalData(storeArr);
  }
  const filterdata2 = bmoriginalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  // //////////////////////////////////////////////////////
  const [ocoriginalData, setOCOriginalData] = React.useState([]);
  function OCstoreSkill(e, x, i) {
    let storeArr = [...ocoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        mainSkill: x.mainSkill,
        subSkillName: x.subSkillName,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOCOriginalData(storeArr);
  }
  const filterdata3 = ocoriginalData?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  const GradeObj = gradeData;

  // Get API
  const getAllSkill = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/skill/viewAllSkills?pagination=true&limit=10&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLocomotor(
          res.msg.filter((skill) => skill.mainSkill === "Locomotor")
        );
        setBodyMgt(
          res.msg.filter((skill) => skill.mainSkill === "Body Management")
        );
        setObjectControl(
          res.msg.filter((skill) => skill.mainSkill === "Object Control")
        );
        setTotal(res?.length);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllSkill();
    // searchSkill();
  }, [value, page]);

  const submitAssignSkills = async (e) => {
    e.preventDefault();
    const d = [...filterdata, ...filterdata2, ...filterdata3];
    console.log("ddddd", d);
    console.log("GradeObj", GradeObj);
    console.log("locomotor", locomotor);
    console.log("BodyMgt", BodyMgt);
    console.log("objcontrol", objcontrol);
    console.log("gradeData", gradeData);

    if (d?.length === 0) {
      setSelectSkillText("please select a skill !");
      return;
    }

    try {
      const response = await fetch(
        `${FETCH_URL}/api/skill/assignSkillToGradeFromGrade`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            gradeId: GradeObj,
            assignedLearners: data.assignedLearners,
            assignedSkills: d,
          }),
        }
      );

      const res = await response.json();
      if (response.ok) {
        getAllSkill();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        DialogClose();
        setOriginalData([]);
        setBMOriginalData([]);
        setOCOriginalData([]);
        console.log("My assign skills ==>", response);
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <div>
      <Tooltip title="Skill">
        <IconButton className="ActionButtonSize">
          <BsBarChartLine
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={DialogClose}></BootstrapDialogTitle>
        <DialogContent dividers={false}>
          <Grid container className="DialogLTRStyle">
            <Grid container item>
              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid container item>
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <Grid
                      item
                      container
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Locomotor Skills
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    md={10}
                    sm={10}
                    xs={10}
                    lg={10}
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      maxHeight: "340px",
                      overflow: "auto",
                    }}
                  >
                    {locomotor &&
                      // locomotor?.length > 0 &&
                      locomotor?.map((x, i) => (
                        <Grid
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          className="SECheckBox  "
                          item
                          container
                          direction="column"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              className="CheckLabelStyleGrey  "
                              value={x.subSkillName}
                              onChange={(e) => storeSkill(e, x, i, locomotorP)}
                              label={x.subSkillName}
                            />
                          </FormGroup>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid
                  item
                  container
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  style={{ marginLeft: "2%", flexBasis: "18.5%" }}
                >
                  <Grid item container>
                    <Grid
                      item
                      container
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Body Management
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      maxHeight: "350px",
                      overflow: "auto",
                    }}
                  >
                    {BodyMgt &&
                      // BodyMgt?.length > 0 &&
                      BodyMgt?.map((x, i) => (
                        <Grid
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          className="SECheckBox"
                          item
                          container
                          direction="column"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              value={x.subSkillName}
                              onChange={(e) =>
                                BMstoreSkill(e, x, i, bodyManagementP)
                              }
                              label={x.subSkillName}
                              className="CheckLabelStyleGrey  "
                            />
                          </FormGroup>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid
                  item
                  container
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  style={{ marginLeft: "5%", flexBasis: "16%" }}
                >
                  <Grid item container>
                    <Grid
                      item
                      container
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Object Control
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      overflow: "auto",
                    }}
                  >
                    {
                      // objcontrol?.length > 0 &&
                      objcontrol?.map((x, i) => (
                        <Grid
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          className="SECheckBox  "
                          item
                          container
                          direction="column"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              className="CheckLabelStyleGrey"
                              value={x.subSkillName}
                              onChange={(e) =>
                                OCstoreSkill(e, x, i, objectControlP)
                              }
                              label={x.subSkillName}
                            />
                          </FormGroup>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {filterdata.length === 0 &&
            filterdata2.length === 0 &&
            filterdata3.length === 0 && (
              <Grid
                container
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                }}
              >
                <Typography
                  className="warningTypography"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  {selectSkillText}
                </Typography>
              </Grid>
            )}
          <CommonPagination
            getDataFromPagination={getDataFromPagination}
            total={total}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="GreenButton   CourseEquipSB"
            onClick={submitAssignSkills}
          >
            Save
          </Button>
          <Button
            className=" GreyButton   CourseEquipSB "
            onClick={DialogClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

//core Module
import SubSkills from "./LocoEdit";
import RubricsTab from "./LocoRubricsEdit";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditDialog(props) {
  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
      skills,
      skillsData,
    },
    searchSkill,
    gradeId,
  } = props;
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const { userToken } = useLogin();
  const [open, setOpen] = React.useState(false);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    // console.log(open);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  console.log(skills, "skills inside edit skills");
  //////////skills editing api//////////////////////////////////////

  const submitClick = async (e) => {
    // For Editing
    e.preventDefault();
    const response = await fetch(
      `${FETCH_URL}/api/skill/editSkill/${skills._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          mainSkill: mainSkill ? mainSkill : skills.mainSkill,
          subSkillName: subSkillName ? subSkillName : skills.subSkillName,
          subSkillUid: subSkillUid ? subSkillUid : skills.subSkillUid,
          subSkillDesc: subSkillDesc ? subSkillDesc : skills.subSkillDesc,
        }),
      }
    );

    const res = await response.json();
    console.log(res, "yo i am res man");
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      // setSnackOpen(true);
      // setSnackMsg(res.msg);
      searchSkill(gradeId);
      setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
      // setSnackerropen(true);
      // setSnackErrMsg(res.err);
    }
  };
  //////////skills editing api//////////////////////////////////////
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Sub Skill Details"
              {...a11yProps(0)}
            />
            {/* <Tab className="TabStyle" label="Rubrics" {...a11yProps(1)} /> */}
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SubSkills
              states={{
                mainSkill,
                setMainSkill,
                subSkillName,
                setSubSkillName,
                subSkillUid,
                setSubSkillUid,
                subSkillDesc,
                setSubSkillDesc,
                xpAssigned,
                setXpAssigned,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
                skills,
              }}
              TabNext={TabNext}
              searchSkill
              submitClick={submitClick}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <RubricsTab
              states={{
                mainSkill,
                setMainSkill,
                subSkillName,
                setSubSkillName,
                subSkillUid,
                setSubSkillUid,
                subSkillDesc,
                setSubSkillDesc,
                xpAssigned,
                setXpAssigned,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
                skills,
              }}
              submitClick={submitClick}
              searchSkill
            />
          </TabPanel> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import { IoIosAddCircle } from "react-icons/io";

//core Module
import AssignLessonPlan from "./CAssignLP";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { IoCloseSharp } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem-noM">
        Assign LessonPlan
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function AssignLPDialog(props) {
  const {
    assignlessonplandata,
    storeLessonPlan,
    open,
    handleClickOpen,
    handleClose,
  } = props;
  const classes = useStyles();
  const { userToken } = useLogin();
  const [lessonplan, setLessonPlan] = React.useState(null);
  const [assigntrainerid, setAssignTrainerId] = React.useState([]);
  const [scroll, setScroll] = React.useState("paper");

  const getAllLessonPlan = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Lesson Plan==>", res);
        setLessonPlan(res.msg);
        setAssignTrainerId();
      } else {
        console.log("Error while fetching LessonPlan", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllLessonPlan();
  }, []);

  return (
    <div>
      <Button
        className=" GreenButton700 width-90px  b-r10  fs-16"
        onClick={handleClickOpen}
      >
        <IoIosAddCircle className="mr-8 fs-20" />
        Add
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        </DialogTitle>

        <AssignLessonPlan
          states={{ assignlessonplandata, lessonplan }}
          storeLessonPlan={storeLessonPlan}
        />

        <DialogActions className=" ACourseAction">
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={handleClose}
          >
            {"Cancel"}
          </Button>
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={handleClose}
          >
            {"Done"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

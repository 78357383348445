import React, { useState } from "react";
import {
  DialogActions,
  Input,
  TextField,
  Button,
  Grid,
  Typography,
  TextareaAutosize,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

//Icons
import { IoIosAddCircle } from "react-icons/io";

export default function ExtendTab(props) {
  const {
    states: {
      eventUid,
      setEventUid,
      eventName,
      setEventName,
      location,
      setLocation,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      overview,
      setOverview,
      board,
      setBoard,
      dateOfCreation,
      setDateOfCreation,
      image,
      setImage,
      file,
      setFile,
      createdBy,
      setCreatedBy,
      assignedPP,
      setAssignedPP,
    },
    TabNext,
  } = props;

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  return (
    <Grid container spacing={4} className="DailogLTRBStyle">
      <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
        <Grid direction="column" item style={{ marginLeft: "7%" }}>
          <Typography className="InputTitleTypography  GreyColorTypography">
            Name <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              className="Input1"
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            PP <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              className="Input1 "
              type="number"
              // value={dateOfCreation}
              // onChange={(e) => setDateOfCreation(e.target.value)
              value={assignedPP}
              onChange={(e) => setAssignedPP(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Created By<span className="ImpColor"> *</span>
          </Typography>

          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              className="Input1"
              value={createdBy}
              onChange={(e) => setCreatedBy(e.target.value)}
            ></Input>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
        <Grid direction="column" item style={{ marginLeft: "9%" }}>
          <Typography className="InputTitleTypography  GreyColorTypography">
            UID <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <Input
              value={eventUid}
              onChange={(e) => setEventUid(e.target.value)}
              className="Input1"
            ></Input>
          </Grid>

          <Typography className="InputTitleTypography GreyColorTypography">
            Start Date<span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="yyyy-MM-dd"
                  minDate={moment().format("YYYY-MM-DD")}
                  value={startDate}
                  onChange={setStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            End Date<span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <KeyboardDatePicker
                  className="Calender"
                  // id="date-picker-dialog"
                  format="yyyy-MM-dd"
                  minDate={moment(moment(startDate).format("YYYY-MM-DD")).add(
                    1,
                    "days"
                  )}
                  value={endDate}
                  onChange={setEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} sm={4} xs={4} lg={4}>
        <Grid
          item
          style={{
            borderBottom: "1px solid #2E3180",
            backgroundColor: "white",
            width: "96%",
          }}
        >
          <Typography
            style={{ marginLeft: "15px", width: "100%" }}
            className="LPTypo BlueColorTypography"
          >
            Add Image
            <img
              src={file}
              style={{
                height: "100px",
                width: "100%",
                marginTop: "10px",
              }}
            />
          </Typography>{" "}
          <input
            style={{ display: "none" }}
            type="file"
            id="raised-button-file"
            multiple
            accept="image/*"
            onChange={ImageUploader}
          />{" "}
          <label htmlFor="raised-button-file">
            <Button
              style={{ margin: "15px 15px" }}
              className="GreenButton700  "
              variant="raised"
              component="span"
            >
              <IoIosAddCircle className="AddCircleIcon" />
              Choose File
            </Button>
          </label>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          lg={6}
          style={{
            marginLeft: "4.7%",
            padding: "0px ",
            // border: "1px solid red ",
          }}
        >
          <Typography className="InputOverViewTypography  GreyColorTypography">
            Overview <span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={12} sm={12} xs={12} lg={12}>
            <TextareaAutosize
              className="OverviewTextArea"
              aria-label="minimum height"
              value={overview}
              onChange={(e) => setOverview(e.target.value)}
            />
          </Grid>
        </Grid>
        {/* <Grid
        item
        style={{
          padding: "2px ",
          width: "13%",
          marginLeft: "21px",
        }}
      >
        <Typography className="GreyColorTypography  ">Board</Typography>
        <FormControl variant="standard" className="FormControlEvent">
          <Select
            className=" SkillsSelectStyle"
            value={board}
            onChange={(e) => setBoard(e.target.value)}
            color="white"
            disableUnderline
          >
            <MenuItem className="Selectmenustyle" value={10}>
              XI
            </MenuItem>
            <MenuItem className="Selectmenustyle" value={20}>
              X
            </MenuItem>
            <MenuItem className="Selectmenustyle" value={30}>
              Vi
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item style={{ padding: "2px ", width: "13%" }}>
        <Typography className="GreyColorTypography  ">Mode</Typography>

        <FormControl variant="standard" className="FormControlEvent">
          <Select
            className=" SkillsSelectStyle"
            // value={level ? level : 0}
            // onChange={setLevel}
            color="white"
            disableUnderline
          >
            <MenuItem className="Selectmenustyle" value={10}>
              XI
            </MenuItem>
            <MenuItem className="Selectmenustyle" value={20}>
              X
            </MenuItem>
            <MenuItem className="Selectmenustyle" value={30}>
              Vi
            </MenuItem>
          </Select>
        </FormControl>
      </Grid> */}
        <Grid item md={5}>
          {/* <Typography className="GreyColorTypography  ">Location</Typography>

        <FormControl variant="standard" className="FormControlEvent">
          <Select
            className=" SkillsSelectStyle"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            color="white"
            disableUnderline
          >
            <MenuItem className="Selectmenustyle" value={10}>
              XI
            </MenuItem>
            <MenuItem className="Selectmenustyle" value={20}>
              X
            </MenuItem>
            <MenuItem className="Selectmenustyle" value={30}>
              Vi
            </MenuItem>
          </Select>
        </FormControl> */}
          <DialogActions className="width100">
            <Typography align="right" className="width100">
              <Button
                className="GreenButton700 EventNextButton"
                onClick={TabNext}
              >
                Next
              </Button>
            </Typography>
          </DialogActions>
        </Grid>
      </Grid>
    </Grid>
  );
}

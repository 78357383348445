import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component

import { FETCH_URL } from "fetchIp";

export default function AssignLPSchool(props) {
  const { TrainerId } = props;
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  const [lpaschool, setLPASchool] = useState([]);
  console.log("AssignLPS", lpaschool);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const getAllAssignLPSchool = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getTrainerSchools/${TrainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLPASchool(res.msg);
        console.log(" School Assign Lesson Plans =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getAllAssignLPSchool();
  }, []);
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead"> School Name </TableCell>
                {/* <TableCell className="ColHead"> Address </TableCell> */}
                <TableCell className="ColHead"> Location</TableCell>
                {/* <TableCell className="ColHead"> Enrolled On</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {lpaschool &&
                lpaschool.length > 0 &&
                lpaschool.map((lp) => (
                  <TableRow>
                    <TableCell className="Col">{lp.schoolUid}</TableCell>
                    <TableCell className="Col ActionButtonSize">
                      {lp.organisationDetails.schoolName}
                    </TableCell>
                    {/* <TableCell className="Col ActionButtonSize">
                      {lp.organisationDetails.address}
                    </TableCell> */}
                    <TableCell className="Col">
                      {lp.organisationDetails.location}
                    </TableCell>
                    {/* <TableCell className="Col">
                      {lp.organisationDetails.enrolledOn}
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
export default function Curriculum(props) {
  const {
    states: {
      inputDisable,
      setInputDisable,
      curriculumState,
      setCurriculumState,
      curriculumValid,
    },
    EditSubmitClick,
  } = props;
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );

  // useEffect(() => {
  //   let html = convertToHTML(editorState.getCurrentContent());
  //   setConvertedCurriculumContent(html);
  // }, [editorState]);
  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Curriculum
          </Typography>
          <Grid container>
            <TextareaAutosize
              className={
                curriculumValid
                  ? "textareaauto  width100 border-red  mt-16"
                  : " textareaauto width100   border-grey  mt-16"
              }
              aria-label="maximum height"
              disabled={inputDisable ? true : false}
              value={curriculumState}
              onChange={(e) => setCurriculumState(e.target.value)}
            />
          </Grid>

          <DialogActions className="mt-24 mb-20 width100">
            {inputDisable ? (
              <Button
                className="GreenButton700 next-btn"
                onClick={() => setInputDisable(false)}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  className="GreenButton-border next-btn"
                  onClick={() => setInputDisable(true)}
                >
                  Cancel
                </Button>
                <Button
                  className="GreenButton700 next-btn"
                  onClick={() => EditSubmitClick()}
                >
                  Save
                </Button>
              </>
            )}
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@material-ui/core";
//React Icons

import { BsFileEarmark } from "react-icons/bs";
//core Component
import EquipDeleteDialog from "../../../../../../Equipments/ActionDialog/EquipmentActionDialog's/EquipDeleteDialog";
import EquipSwitchDialog from "../GEActionDialog/EquipmentActionDialog's/EquipSwitchDialog";
import GEquipmnetProfile from "../GEquipProfile/GPEquipmentProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function RenameTab(props) {
  const { equipmentData, getAllEquipment, gradeId } = props;
  const [value, setValue] = React.useState(0);
  const { userToken } = useLogin();

  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  // API Calling

  const deleteEquipment = async (EquipmentId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/deleteEquipment/${EquipmentId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response ==>", res);
        getAllEquipment();
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID</TableCell>
              <TableCell className="Head">Equipment Name </TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> Assigned Grade</TableCell>
              <TableCell className="Head"> Assigned Learner</TableCell>
              {/* <TableCell className="Head"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {equipmentData &&
              equipmentData.length &&
              equipmentData.map((equipment) => (
                <TableRow>
                  <TableCell className="Col">
                    {equipment.equipmentUid}
                  </TableCell>
                  <TableCell className="Col BlueColorTypogarphy">
                    <GEquipmnetProfile equipmentData={equipment} />
                  </TableCell>
                  <TableCell className="Col">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {equipment.equipmentDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Col">
                    {equipment.assignedGrade &&
                    equipment.assignedGrade.length > 0
                      ? equipment.assignedGrade.length
                      : "--"}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {equipment.assignedLearner &&
                    equipment.assignedLearner.length > 0
                      ? equipment.assignedLearner.length
                      : "--"}
                  </TableCell>
                  <TableCell className="Action">
                    {/* <IconButton className="ActionButtonSize">
                      <EquipSwitchDialog
                        equipmentId={equipment._id}
                        gradeId={gradeId}
                        getAllEquipment={getAllEquipment}
                        access={equipment.access}
                      />
                    </IconButton> */}
                    {/* <EquipDeleteDialog
                      deleteEquipment={deleteEquipment}
                      EquipmentId={equipment._id}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

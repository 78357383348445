import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
} from "@material-ui/core";

//Core Component
import ObjectDelete from "../ActionButtonDialog's/ObjMgtActionB/OMDeleteDialog";
import ObjectEdit from "../ActionButtonDialog's/ObjMgtActionB/OMEdit/OMEditMain";
import ObjectSwitch from "../ActionButtonDialog's/ObjMgtActionB/OMSwitchDialog";

export default function ObjMgtTab(props) {
  const { TabNext } = props;
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    skillsData,
    getAllSkill,
  } = props;

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  // const loadUsers = async () => {
  //   const res = await axios.get(
  //     "https://jsonplaceholder.typicode.com/comments "
  //   );
  //   setPosts(res.data);
  //   setTotal(res.data.length);
  // };

  // useEffect(() => {
  //   loadUsers();
  // }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Name of Skill</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead"> Assigned On </TableCell>
            </TableRow>
          </TableHead>
          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {skillsData &&
              skillsData.length &&
              skillsData.map((skills,i)  => (
              <TableRow key ={i}>
                <TableCell className="Col700WAB">
                  <Link> {skills.subSkillUid} </Link>
                </TableCell>
                <TableCell className="Col700WAB">
                  <Link> {skills.subSkillName} </Link>
                </TableCell>
                <TableCell
                  className="Col700WAB"
                  style={{ wordBreak: "break-word" }}
                >
                  {skills.subSkillDesc}
                </TableCell>
                <TableCell className="Col700WAB">14/09/2021</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Grid,
  Input,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { FaPercent } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem" style={{}}>
        Allocate Equipment KIT
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const LabelName = [
    {
      labelname: "Skipping",
    },
    {
      labelname: "Walking",
    },
    {
      labelname: "Running",
    },
    {
      labelname: "Jumping",
    },
    {
      labelname: "Hopping",
    },
    {
      labelname: "Leaping",
    },
    {
      labelname: "Skipping",
    },
    {
      labelname: "Swinging",
    },
    {
      labelname: "Side Stepping ",
    },
    {
      labelname: "Dodging",
    },
    {
      labelname: "Galloping",
    },
    {
      labelname: "Climbing",
    },
    {
      labelname: "Crawling",
    },
  ];
  const LabelRow2 = [
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
    {
      labelname2: "Rolling",
    },
    {
      labelname2: "Rotatin",
    },
    {
      labelname2: "Bending",
    },
    {
      labelname2: "Stretching",
    },
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
  ];
  const LabelRow3 = [
    {
      labelname3: "Catching",
    },
    {
      labelname3: "Overhand Throwing",
    },
    {
      labelname3: "Underhand Throwing",
    },
    {
      labelname3: "Foot Dribbling",
    },
    {
      labelname3: "Balancing Wand",
    },
    {
      labelname3: "Hand Dribbling",
    },
    {
      labelname3: "Chest Passing",
    },
    {
      labelname3: "Catching",
    },
  ];
  return (
    <div>
      <div className="CCFB">
        <Button
          className=" GreenButton700 CourseSkillB"
          onClick={handleClickOpen}
        >
          Click here to assign Skills
        </Button>
      </div>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false}>
          <Grid container className="DialogLTRStyle">
            <Grid container item>
              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid container item>
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <Grid
                      item
                      container
                      md={7}
                      sm={7}
                      xs={7}
                      lg={7}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Locomotor Skills
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={5}
                      sm={5}
                      xs={5}
                      lg={5}
                      className=" CheckHeadTitle"
                    >
                      <span>
                        <Input className="PercentInput"></Input>
                      </span>
                      <FaPercent className="PercenIcon  " />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    md={10}
                    sm={10}
                    xs={10}
                    lg={10}
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      maxHeight: "340px",
                      overflow: "auto",
                    }}
                  >
                    {LabelName.map((user, key) => (
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        className="SECheckBox  "
                        item
                        container
                        direction="column"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            className="CheckLabelStyleGrey  "
                            label={user.labelname}
                          />
                        </FormGroup>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid
                  item
                  container
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  style={{ marginLeft: "2%", flexBasis: "18.5%" }}
                >
                  <Grid item container>
                    <Grid
                      item
                      container
                      md={7}
                      sm={7}
                      xs={7}
                      lg={7}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Body Management
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={5}
                      sm={5}
                      xs={5}
                      lg={5}
                      className=" CheckHeadTitle"
                    >
                      <span>
                        <Input className="PercentInput"></Input>
                      </span>
                      <FaPercent className="PercenIcon  " />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      maxHeight: "350px",
                      overflow: "auto",
                    }}
                  >
                    {LabelRow2.map((user, key) => (
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        className="SECheckBox  "
                        item
                        container
                        direction="column"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label={user.labelname2}
                            className="CheckLabelStyleGrey  "
                          />
                        </FormGroup>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Grid
                  item
                  container
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  style={{ marginLeft: "5%", flexBasis: "16%" }}
                >
                  {" "}
                  <Grid item container>
                    <Grid
                      item
                      container
                      md={7}
                      sm={7}
                      xs={7}
                      lg={7}
                      className=" CheckHeadTitle"
                    >
                      <Typography className=" GreenColorTypography ALAddSkilltypography">
                        Object Control
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      md={5}
                      sm={5}
                      xs={5}
                      lg={5}
                      className=" CheckHeadTitle"
                    >
                      <span>
                        <Input className="PercentInput"></Input>
                      </span>
                      <FaPercent className="PercenIcon  " />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{
                      borderTop: "2px solid #8EBF4D",
                      overflow: "auto",
                    }}
                  >
                    {LabelRow3.map((user, key) => (
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        className="SECheckBox  "
                        item
                        container
                        direction="column"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            className="CheckLabelStyleGrey  "
                            label={user.labelname3}
                          />
                        </FormGroup>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="GreenButton   CourseEquipSB" onClick={handleClose}>
            Save
          </Button>
          <Button
            className=" GreyButton   CourseEquipSB "
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

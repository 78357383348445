import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "antd";
import AddGradeDivision from "./AddGradeDivision/AddGradeDivision";
import UploadDivisonExcel from "./AddGradeDivision/UploadDivisonExcel";
import React, { useState, useEffect } from "react";

const GradeDivision = ({ divisonData, gradeId, getAllDivisons, gradeData }) => {
  useEffect(() => {
    getAllDivisons(gradeId);
  }, []);

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "space-between",
          marginTop: "22px",
        }}
      >
        <Grid item xs={4} style={{ paddingLeft: "15px" }}>
          <AddGradeDivision
            gradeId={gradeId}
            getAllDivisons={getAllDivisons}
            gradeData={gradeData}
          />
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: "end" }}>
            <UploadDivisonExcel
              gradeData={gradeData}
              gradeId={gradeId}
              getAllDivisons={getAllDivisons}
            />
          </div>
        </Grid>
      </Grid>

      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">Division Name</TableCell>
              <TableCell className="ColHead">Grade</TableCell>
              <TableCell className="ColHead">Learner </TableCell>
              {/* <TableCell className="ColHead"> Action </TableCell> */}
            </TableRow>
          </TableHead>
          {divisonData.length > 0 && (
            <TableBody>
              {divisonData &&
                divisonData.length > 0 &&
                divisonData.map((divdata) => (
                  <>
                    <TableRow>
                      <TableCell className="Col">{divdata.division}</TableCell>
                      <TableCell className="Col">{divdata.gradeName}</TableCell>
                      <TableCell className="Col ActionButtonSize">
                        {divdata.assignedLearners &&
                        divdata.assignedLearners.length > 0
                          ? divdata.assignedLearners.length
                          : "--"}
                      </TableCell>
                      {/* <TableCell className="Action">
                        <IconButton className="ActionButtonSize"> */}
                      {/* <TrainerEdit
                        trainer={trainer}
                        gradeId={gradeId}
                        paginatedTrainer={paginatedTrainer}
                        getAllTrainers={getAllTrainers}
                      /> */}
                      {/* </IconButton>
                        <IconButton className="ActionButtonSize"> */}
                      {/* <TrainerSwitch
                        access={trainer.access}
                        TrainerId={trainer._id}
                        paginatedTrainer={paginatedTrainer}
                        gradeId={gradeId}
                        getAllTrainers={getAllTrainers}
                      /> */}
                      {/* </IconButton>
                      </TableCell> */}
                    </TableRow>
                  </>
                ))}
            </TableBody>
          )}
        </Table>
        {divisonData.length === 0 && (
          <Typography
            style={{
              marginTop: "27px",
              textAlign: "center",
            }}
            className="ColHead"
          >
            No Division Found
          </Typography>
        )}
      </div>
      {/* {divisonData.length > 0 && (
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )} */}
    </>
  );
};

export default GradeDivision;

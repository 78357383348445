import React, { useState, useEffect } from "react";

import { Typography, Button, DialogActions, Grid } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function OverviewTab(props) {
  const {
    states: { overviewState, overviewValid },
  } = props;

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Overview
          </Typography>
          <Grid container>
            <TextareaAutosize
              aria-label="maximum height "
              disabled
              className={
                overviewValid
                  ? "textareaauto width100  border-red  mt-16 "
                  : " textareaauto  width100 border-grey mt-16"
              }
              value={overviewState}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import {
  DialogActions,
  MenuItem,
  Select,
  Input,
  TextField,
  Button,
  Grid,
  FormControl,
  Typography,
  // Snackbar,
  // Alert,
} from "@material-ui/core";
import "date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "../../../../Context/ExportContext";
//Icons
import { IoIosAddCircle } from "react-icons/io";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ExtendTab(props) {
  const handleEnter = (event) => {
    console.log("events", event);
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  const {
    states: {
      departmentSelect,
      setDepartmentSelect,
      schoolUid,
      setSchoolUid,
      schoolName,
      setSchoolName,
      location,
      setLocation,
      enrolledOn,
      setEnrolledOn,
      firstTermStartDate,
      setFirstTermStartDate,
      secondTermStartDate,
      setsecondTermStartDate,
      firstTermEndDate,
      setfirstTermEndDate,
      secondTermEndDate,
      setsecondTermEndDate,
      image,
      setImage,
      file,
      setFile,
      departMentDetail,
      thirdTermEndDate,
      setThirdTermEndDate,
      thirdTermStartDate,
      setThirdTermStartDate,
    },
    TabNext,
    imageUpload,
  } = props;
  console.log("firstTermStartDate", firstTermStartDate);
  console.log(
    "firstTermStartDatewithmoment",
    moment(moment(firstTermStartDate).format("YYYY-MM-DD")).add(1, "days")
  );

  const [open, setOpen] = React.useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const handleClick = () => {
    setSnackOpen(true);
  };

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  console.log("School ====>", { schoolUid });
  console.log("I am the image >>>> ", image);
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <form>
        <Grid container spacing={4} className="DailogLTRBStyle">
          <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
            <Grid direction="column" item style={{ marginLeft: "3%" }}>
              <Typography className="InputTitleTypography GreyColorTypography">
                DepartMent<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12} lg={12}>
                <FormControl
                  variant="standard"
                  className="FormControlInput colorwhite"
                >
                  <Select
                    className=" SkillsSelectStyle "
                    value={departmentSelect}
                    onChange={(e) => {
                      setDepartmentSelect(e.target.value);
                      setSchoolName(e.target.value.departmentName);
                      console.log("e.target.value", e.target.value);
                      // getSchoolLocation(e.target.value);
                    }}
                    onKeyDown={handleEnter}
                    color="white"
                    label="Locomotor"
                    disableUnderline
                  >
                    {departMentDetail &&
                    Object.keys(departMentDetail).length > 0 ? (
                      departMentDetail.msg.map((dprData, i) => (
                        <MenuItem
                          key={i}
                          className="Selectmenustyle"
                          value={dprData}
                        >
                          {dprData.departmentName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem className="Selectmenustyle" value={0} disabled>
                        No Department Available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                School Name <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12} lg={12}>
                <Input
                  className="Input1 "
                  type="text"
                  onKeyDown={handleEnter}
                  required
                  value={schoolName}
                  disabled
                  // onChange={(e) => setSchoolName(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="InputTitleTypography  GreyColorTypography">
                School UID <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12} lg={12}>
                <Input
                  onKeyDown={handleEnter}
                  value={schoolUid}
                  required
                  onChange={(e, index) => setSchoolUid(e.target.value)}
                  className="Input1"
                ></Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Location<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12} lg={12}>
                <Input
                  className="Input1"
                  type="text"
                  onKeyDown={handleEnter}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  {" "}
                </Input>
              </Grid>

              <Typography className="InputTitleTypography GreyColorTypography">
                Enrolled on<span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog".
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      value={enrolledOn}
                      onChange={setEnrolledOn}
                      placeholder="DD/MM/YYYY"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                1st Term Start Date<span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog".
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={firstTermStartDate}
                      onChange={setFirstTermStartDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                1st Term End Date<span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      minDate={moment(
                        moment(firstTermStartDate).format("YYYY-MM-DD")
                      ).add(1, "days")}
                      value={firstTermEndDate}
                      onChange={setfirstTermEndDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                2nd Term Start Date <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={secondTermStartDate}
                      minDate={moment(
                        moment(firstTermEndDate).format("YYYY-MM-DD")
                      ).add(1, "days")}
                      onChange={setsecondTermStartDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                2nd Term End Date <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={secondTermEndDate}
                      minDate={moment(
                        moment(secondTermStartDate).format("YYYY-MM-DD")
                      ).add(1, "days")}
                      onChange={setsecondTermEndDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Typography className="InputTitleTypography GreyColorTypography">
                3rd Term Start Date <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={thirdTermStartDate}
                      minDate={moment(
                        moment(secondTermEndDate).format("YYYY-MM-DD")
                      ).add(1, "days")}
                      onChange={setThirdTermStartDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                3rd Term End Date <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12} lg={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      onKeyDown={handleEnter}
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={thirdTermEndDate}
                      minDate={moment(
                        moment(thirdTermStartDate).format("YYYY-MM-DD")
                      ).add(1, "days")}
                      onChange={setThirdTermEndDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6} lg={6}>
            <Grid container className="AddLogoTypography">
              <Typography className=" BlueColorTypography width-60">
                Add Logo <br />
                <img
                  src={file}
                  style={{
                    height: "250px",
                    width: "400px",
                    marginTop: "10px",
                    objectFit: "contain",
                  }}
                />
              </Typography>
              <Grid item className="ChooseFileButtonItem">
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  accept="image/*"
                  onChange={ImageUploader}
                />{" "}
                <label htmlFor="raised-button-file">
                  <Button
                    className="GreenButton "
                    variant="raised"
                    component="span"
                    style={{ marginTop: "15px" }}
                  >
                    <IoIosAddCircle className="AddCircleIcon" />
                    Choose File
                  </Button>
                </label>
              </Grid>
            </Grid>
            <DialogActions className="OrgDialogActionButton">
              <Button
                className="GreenButton700 ASNextButton"
                onKeyDown={handleEnter}
                onClick={TabNext}
              >
                Next
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { Pagination } from "antd";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Switch,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core";
// React Icons

// core Component

export default function OrgnasationTab(props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [userDetails, setUserDetails] = useState([]);
  const {
    states: { searchU, pageU, setPageU, totalU, setTotalU },
    userDetails,

    handleSearch,
  } = props;

  const onShowSizeChange = (current, pageSize) => {
    console.log("pageSize111111", pageSize);
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">User Name</TableCell>
              <TableCell className="Head">Department Name</TableCell>
              <TableCell className="Head">Role</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetails &&
              Object.keys(userDetails).length > 0 &&
              userDetails.msg.map((usrData, i) => {
                return (
                  // usrData.role !== 0 && (
                  <TableRow key={i}>
                    <TableCell className="Col">
                      <Link> {usrData.fullName} </Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: "/admin/userprofile",
                          state: { usrData },
                        }}
                      >
                        {" "}
                        {usrData.departmentId?.departmentName}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      {" "}
                      {/* {usrData.userStrRole} */}
                      {usrData.role == 1
                        ? "Sub-Admin"
                        : usrData.role == 2
                        ? "HOS"
                        : usrData.role == 3
                        ? "Principal"
                        : "----"}
                    </TableCell>
                    <TableCell className="Action">
                      {/* <IconButton className="ActionButtonSize">
                          <UserSwicth
                            getUserDetails={getUserDetails}
                            departmentId={usrData.departmentId}
                            userId={usrData._id}
                            access={usrData.access}
                            accessCheck={access}
                          />
                        </IconButton> */}
                    </TableCell>
                  </TableRow>
                  // )
                );
              })}
          </TableBody>
        </Table>
        {userDetails?.msg?.length == 0 || userDetails.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No User Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>

      {searchU ||
      userDetails.length == 0 ||
      userDetails?.msg?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              setPageU(value);
              handleSearch("", value, value2);
            }}
            pageSize={rowsPerPage}
            total={totalU}
            current={pageU}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Input,
  InputAdornment,
} from "@material-ui/core";
// material-ui icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";

// core components

import TrainerProfile from "./TrainerProfile/TrainerProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { CgClipboard } from "react-icons/cg";
import { IoAnalyticsSharp } from "react-icons/io5";
import { BsEye } from "react-icons/bs";

export default function Pages() {
  const { userToken } = useLogin();
  const [trainerData, setTrainerData] = React.useState(null);
  const [access, setAccess] = useState(1);
  const [school, setSchool] = React.useState("");
  const [value, setValue] = React.useState(false);

  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const [grade, setGrade] = React.useState("");
  const GradeChange = (event) => {
    setGrade(event.target.value);
  };

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");

  // States for addGrade
  const [schoolNamesForAddGrade, setSchoolNamesForAddGrade] = useState([]);
  const [locationsForAddGrade, setLocationsForAddGrade] = useState([]);
  const [locationForGrade, setLocationForGrade] = useState([]);
  ///////////////////////////////////////////////////////////////
  const [selectGrade, setSelectGrade] = useState([]);
  const [gradeSelected, setGradeSelected] = useState("");

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  // For DropDown
  const getDistinctSchoolAndLocation = async (
    nameOfSchool,
    isForGradeDialog
  ) => {
    console.log("Is this coming from TgradeDialog ====>", isForGradeDialog); // This here is undefined

    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      console.log(res, "i am the greatest res>>>>>>>>>>>");
      if (response.ok) {
        // make a check if it's for the dialog then save it in different state
        if (isForGradeDialog === undefined) {
          console.log(
            "My Distinct schools when isForGradeDialog is undefined ============>",
            res
          );

          if (nameOfSchool) {
            console.log("If condition coming", nameOfSchool);
            setSchoolLoc(res.msg);
            setLocationsForAddGrade(res.msg);
          } else {
            console.log("Else condition coming", nameOfSchool);
            setSchoolNames(res.msg);
            setSchoolNamesForAddGrade(res.msg);
          }
        } else {
          console.log(
            "My Distinct schools when isForGradeDialog is true ============>",
            res
          );
          if (nameOfSchool) {
            console.log("If condition coming", nameOfSchool);
            setLocationsForAddGrade(res.msg);
          } else {
            console.log("Else condition coming", nameOfSchool);
            setSchoolNamesForAddGrade(res.msg);
          }
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      getAllTrainers();
    }
    const response = await fetch(
      `${FETCH_URL}/api/learner/searchTrainer/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setTrainerData(res.msg);
      console.log("My School response from searching ==>", res);
      // getAllGrades();
    } else {
      console.log("Error while searching single school", res.err);
    }
  };

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  const getPaginatedTrainers = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated Trainers==>", res);

        setTrainerData(res.msg);
      } else {
        console.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getAllTrainers = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/learner/getAllTrainers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTrainerData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllTrainers();
    getDistinctSchoolAndLocation();
  }, []);

  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Trainer</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    // setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          {/* <TrainerAdd getAllTrainers={getAllTrainers} /> */}
          {/* <IconButton className="HeaderAddButton">
            <BsUpload size="1.1em" color="#5FA12F" />
          </IconButton> */}
        </div>
      </Toolbar>
      <Grid container>
        <Grid item xs={3}>
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              // onChange={locChange}
              label="Select School"
              color="white"
              disableUnderline
              defaultValue={0}
              onChange={(e) => {
                console.log("event target =======>", e.target.value);
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
              }}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select School
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} className="MainPageFCIG">
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getGrades(e.target.value);
              }}
              label="Select Location"
              disableUnderline
              defaultValue={0}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} className="MainPageFCIG">
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={gradeSelected ? gradeSelected : 0}
              label=" Select Grade"
              color="white"
              onChange={(e) => {
                getPaginatedTrainers(e.target.value);
                setGradeSelected(e.target.value);
              }}
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Grade
              </MenuItem>
              {selectGrade &&
                selectGrade.length > 0 &&
                selectGrade.map((a) => (
                  <MenuItem className="Selectmenustyle" value={a._id}>
                    {a._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Trainer’s Name </TableCell>
              <TableCell className="ColHead"> School Name</TableCell>
              <TableCell className="ColHead"> Location</TableCell>
              <TableCell className="ColHead"> Grade </TableCell>
              <TableCell className="ColHead"> Division </TableCell>
              <TableCell className="Head"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {trainerData &&
              trainerData.length &&
              trainerData.map((trainer, i) => (
                <TableRow key={i}>
                  <TableCell className="Col">
                    <Link> {trainer.trainerDetail.regNo} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <TrainerProfile trainer={trainer} />
                  </TableCell>
                  <TableCell className="Col">
                    {trainer && trainer.assignedSchool.length > 0
                      ? trainer.assignedSchool.length
                      : "--"}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {trainer.contactDetail.trainerLocation}
                  </TableCell>
                  <TableCell className="Col"> Grade 1</TableCell>
                  <TableCell className="Col"> A</TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <BsEye className="ActionButtonIconGrey" />
                    </IconButton>
                    <IconButton
                      className="ActionButtonSize"
                      onClick={() => setValue(!value)}
                    >
                      {/* <TrianerProfileDailog i={value ? 2 : 0} /> */}
                      <IoAnalyticsSharp
                        onClick={() => setValue(!value)}
                        className="ActionButtonIconGrey"
                      />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <CgClipboard className="ActionButtonIconGrey" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

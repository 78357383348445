import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { Button, Tab, Tabs, Box, Breadcrumbs, Grid } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// ReactIcons
// Import Files
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { Typography } from "antd";
//core Component

import AboutTab from "./LessonplanProfileTab/AboutLessonplanTab";
import Overview from "./LessonplanProfileTab/OverviewTab";
import Curriculum from "./LessonplanProfileTab/CurriculumTab";
import Benefits from "./LessonplanProfileTab/BenefitsTab";
import Feedback from "./LessonplanProfileTab/FeedbackTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ExtendedTables() {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { userToken } = useLogin();
  const location = useLocation();
  const { lp: lplearner } = location.state;
  console.log("Lesson Plan for profile data ", lplearner);
  // const {  foldername } = location.data;
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  //Dialog State
  const [open, setOpen] = React.useState(false);
  const [openEquip, setOpenEquip] = React.useState(false);
  // About Tab State

  const top100Films = [
    "Nursery",
    "LKG",
    "UKG",
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
  ];

  const [inputDisable, setInputDisable] = useState(true);
  const [uid, setUid] = useState(null);
  const [duration, setDuration] = useState(null);
  const [durationSlot, setDurationSlot] = useState(null);
  const [price, setPrice] = useState(null);
  const [folderSelected, setFolderSelected] = useState(null);
  const [playpoints, setPlayPoints] = useState(null);
  const [isForTrainer, setIsForTrainer] = React.useState(false);
  const [isForLearner, setIsForLearner] = React.useState(false);
  const [title, setTitle] = useState(null);
  const [grade, setGrade] = useState([]);
  const [sports, setSports] = useState([]);
  const [about, setAbout] = useState(null);
  const [locomotorP, setLocomotorP] = useState("");
  const [bodyManagementP, setBodyManagementP] = useState("");
  const [objectControlP, setObjectControlP] = useState("");
  const [originalData, setOriginalData] = React.useState([]);
  const [bmoriginalData, setBMOriginalData] = React.useState([]);
  const [ocoriginalData, setOCOriginalData] = React.useState([]);
  const [assignequipment, setAssignQuipment] = React.useState([]);

  const handleChangeDuration = (e) => {
    setDurationSlot(e.target.value);
  };
  //Assign Skills

  function storeSkill(e, x, i, dataPercent) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      let data = x;
      data.subSkill = x.subSkillName;
      storeArr[i] = data;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);

    setOriginalData(storeArr);
  }
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  function BMstoreSkill(e, x, i, dataPercent) {
    let storeArr = [...bmoriginalData];

    console.log(x, i);
    if (e.target.checked) {
      // storeArr[i] = x;
      let data = x;
      data.subSkill = x.subSkillName;
      storeArr[i] = data;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setBMOriginalData(storeArr);
  }
  const filterdata2 = bmoriginalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  function OCstoreSkill(e, x, i, dataPercent) {
    let storeArr = [...ocoriginalData];
    console.log(x, i);
    if (e.target.checked) {
      // storeArr[i] = x;
      let data = x;
      data.subSkill = x.subSkillName;
      storeArr[i] = data;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOCOriginalData(storeArr);
  }
  const filterdata3 = ocoriginalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });

  let skillstore = [...filterdata, ...filterdata2, ...filterdata3];

  const [skillShow, setSkillShow] = useState(lplearner?.skills);
  console.log("Check Equiomentstore", skillShow);

  const skillsStore = skillstore?.map((a) => {
    return {
      _id: a._id,
      mainSkill: a.mainSkill,
      // subSkill: a.subSkillName,
      subSkill: a.subSkill,
      percent: a.percent,
      skillImg: a?.skillImg,
      rubricsQts: a.rubricsQts,
    };
  });
  const totaldata =
    parseInt(locomotorP ? locomotorP : 0) +
    parseInt(bodyManagementP ? bodyManagementP : 0) +
    parseInt(objectControlP ? objectControlP : 0);
  //Assign Equipment
  function storeEquipment(e, x, i) {
    let storeArr = [...assignequipment];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = {
        _id: x._id,
        // name: x.equipmentName,
        name: x.equipmentKitName,
        addedEquipment: x.addedEquipment,
      };
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setAssignQuipment(storeArr);
  }
  const Equiomentstore = assignequipment?.filter((a) => {
    if (a !== undefined || a !== null) {
      return a;
    }
  });

  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const reuploadClick = () => {
    setFile(false);
  };

  // Upload Hero Content
  const [media, setMedia] = useState();
  const [mediatype, setMediaType] = useState("video/mp4");
  const [mediaPost, setMediaPost] = useState();

  const MediaUploadClick = () => {
    setMedia(false);
  };
  function MediaUploader(e) {
    setMedia(URL.createObjectURL(e.target.files[0]));
    setMediaType(e.target.files[0].type);
    setMediaPost(e.target.files[0]);
  }
  const MediaUploadHeroImage = async (ID) => {
    const formdata = new FormData();
    formdata.append("lessonPlanId", lplearner?._id);
    formdata.append("file", mediaPost);

    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/lessonPlanUploadHeroImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const MediaUploadHeroVideo = async (ID) => {
    const formdata = new FormData();
    formdata.set("lessonPlanId", lplearner?._id);
    formdata.append("file", mediaPost);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/lessonPlanUploadHeroVideo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  // Overview Tab
  const [overviewState, setOverviewState] = useState(null);

  // Curriculum Tab
  const [curriculumState, setCurriculumState] = useState(null);

  // Benefits Tab
  const [benefitesState, setBenefitesState] = useState(null);

  // Feadback Tab
  const [FeadbackQuestion, setFeadBackQuestion] = useState([
    {
      feedbackQuery: "",
      feedbackType: "",
    },
  ]);

  ///////////////////////////////////////////////////////////////////// Assign Skill Store In Array //////////////////////

  /////////////////////////////////////////////////Image Upload Api///////////////////////////////////

  const imageUpload = async (LPId) => {
    const formdata = new FormData();
    formdata.set("LPId", lplearner?._id);
    formdata.append("thumbnail", image);
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/uploadLessonPlanThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        // console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  ////////////////////////////////////////////////////////Get Folder  Api/////////////////////////////////
  const [foldername, setFolderName] = useState(null);
  const GetFolder = async (pageNum, rowSize) => {
    const response = await fetch(
      `${FETCH_URL}/api/lessonplan/getPaginatedFolders?isPublished=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setFolderName(res.msg);
      // console.log("My Folder response from searching ==>", res);
    } else {
      console.log("Error while searching single school", res.err);
    }
  };

  useEffect(() => {
    GetFolder();
  }, []);

  const calculateLPXp = async (value) => {
    try {
      console.log("value", value);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getSingleFolder/${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      console.log(res);
      if (response.ok) {
        let marks = 0;
        res.msg.pages.map((data) => {
          marks = marks + parseInt(data.marks);
        });
        console.log("marks", marks);
        // setLPXp(marks);
        setPlayPoints(marks);
      } else {
        console.log("Error while searching single school", res.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (lplearner) {
      let locoSkill = [];
      let bodySkill = [];
      let objSkill = [];
      setUid(lplearner?.LPUid ?? " ");
      setTitle(lplearner?.LPTitle ?? "");
      setPlayPoints(lplearner?.LPXp ?? " ");
      setDuration(lplearner?.duration?.courseDuration ?? " ");
      setDurationSlot(lplearner?.duration?.durationSlot ?? " ");
      setPrice(lplearner?.Price ?? " ");
      setFolderSelected(lplearner?.assignedFolder?._id ?? " ");
      setIsForTrainer(lplearner?.isForTrainer ?? " ");
      setIsForLearner(lplearner?.isForLearner ?? " ");
      setGrade(lplearner?.grade ?? " ");
      setSports(lplearner?.sports ?? " ");
      setAbout(lplearner?.LPDesc ?? " ");
      setFile(lplearner?.LPThumbnail ?? "");
      setMedia(lplearner?.heroContent?.url ?? "");
      setOverviewState(lplearner?.overview ?? " ");
      setBenefitesState(lplearner?.benefits ?? " ");
      setCurriculumState(lplearner?.curriculum ?? " ");
      setAssignQuipment(lplearner?.equipments ?? " ");
      // setSkillShow(lplearner?.skills ?? "");
      setFeadBackQuestion(lplearner?.feedback ?? " ");
      if (lplearner.skills != undefined) {
        lplearner.skills.map((x, i) => {
          if (x.mainSkill == "Locomotor") {
            locoSkill.push(x);
            setLocomotorP(x.percent);
          } else if (x.mainSkill == "Body Management") {
            bodySkill.push(x);
            setBodyManagementP(x.percent);
          } else if (x.mainSkill == "Object Control") {
            objSkill.push(x);
            setObjectControlP(x.percent);
          }
        });
      }

      setOriginalData(locoSkill);
      setBMOriginalData(bodySkill);
      setOCOriginalData(objSkill);
    }
  }, [lplearner]);
  /////////////////////////////////Grade Store In Array ////////////////////////////////////////////

  const EditSubmitClick = async (e) => {
    console.log("updateHere");
    if (
      skillstore.length === 0 ||
      totaldata !== 100 ||
      Equiomentstore.length === 0
    ) {
      console.log("er");
      return;
    }
    if (filterdata.length > 0) {
      for (let key of filterdata) {
        key.percent = locomotorP;
      }
    }
    if (filterdata2.length > 0) {
      for (let key of filterdata2) {
        key.percent = bodyManagementP;
      }
    }
    if (filterdata3.length > 0) {
      for (let key of filterdata3) {
        key.percent = objectControlP;
      }
    }

    let NewUpdatedData = [...filterdata, ...filterdata2, ...filterdata3];

    const updatedSkills = NewUpdatedData?.map((a) => {
      return {
        _id: a._id,
        mainSkill: a.mainSkill,
        // subSkill: a.subSkillName,
        subSkill: a.subSkill,

        percent: a.percent,
        skillImg: a?.skillImg,
        rubricsQts: a.rubricsQts,
      };
    });
    let LearnerId = lplearner?._id;

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/editLessonPlan/${LearnerId}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            LPTitle: title,
            LPXp: playpoints,
            LPDesc: about,
            duration: { courseDuration: +duration, durationSlot },
            grade: grade,
            Price: +price,
            sports,
            overview: overviewState,
            curriculum: curriculumState,
            benefits: benefitesState,
            skills: updatedSkills,
            equipments: Equiomentstore,
            isForTrainer: isForTrainer,
            isForLearner: isForLearner,
            feedback: FeadbackQuestion,
            folderId: folderSelected,
          }),
        }
      );
      let res = await responce.json();
      console.log("updateHere2");
      if (responce.ok) {
        if (file) {
          imageUpload(file);
        }
        setInputDisable(true);
        MediaUploadHeroImage();
        MediaUploadHeroVideo();
        setSnackOpen(true);
        setSnackMsg(res.msg);
      }
      if (responce.ok) {
        console.log("Mah respo =====>", res);
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log(res.err);
      }
      let LPId = res.msg2._id;
      if (LPId) {
        imageUpload(LPId);
        // console.log("Response after create >> ", res.msg2._id);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <div className="bodyStyle">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb" className="mt-16">
            <Link to="/admin/Lesson">
              <Button disableRipple className="Breadcrumb">
                Lesson Plan
              </Button>
            </Link>
            <div className="Breadcrumb-child">Learner</div>
          </Breadcrumbs>
        </div>
        <Grid
          container
          className="mt-16"
          justify="space-between"
          direction="row"
        >
          <Typography className="BlueColorTypography fs-18">
            {lplearner?.LPTitle}
          </Typography>
        </Grid>

        <Box className="mt-16">
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="TabsCustom-Style   "
            centered
          >
            <Tab
              className="ActivityTabChanges"
              label="About Lessonplan"
              {...a11yProps(0)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Overview"
              {...a11yProps(1)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Curriculum"
              {...a11yProps(2)}
            />
            <Tab
              className="ActivityTabChanges"
              label="Benefits"
              {...a11yProps(3)}
            />

            <Tab
              className="ActivityTabChanges"
              label="Feedback"
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <AboutTab
            states={{
              inputDisable,
              setInputDisable,
              uid,
              setUid,
              duration,
              setDuration,
              durationSlot,
              setDurationSlot,
              price,
              setPrice,
              folderSelected,
              setFolderSelected,
              playpoints,
              setPlayPoints,
              isForLearner,
              setIsForLearner,
              isForTrainer,
              setIsForTrainer,
              title,
              setTitle,
              grade,
              setGrade,
              sports,
              setSports,
              about,
              setAbout,
              id,
              open,
              setOpen,
              openEquip,
              setOpenEquip,
              calculateLPXp,
              foldername,
              file,
              setFile,
              media,
              setMedia,
              mediatype,
              setMediaType,
              originalData,
              bmoriginalData,
              ocoriginalData,
              locomotorP,
              setLocomotorP,
              bodyManagementP,
              setBodyManagementP,
              objectControlP,
              setObjectControlP,
              skillstore: skillsStore,
              assignequipment,
              Equiomentstore,
              handleChangeDuration,
            }}
            lplearner={lplearner}
            ImageUploader={ImageUploader}
            MediaUploadClick={MediaUploadClick}
            MediaUploader={MediaUploader}
            reuploadClick={reuploadClick}
            storeSkill={storeSkill}
            BMstoreSkill={BMstoreSkill}
            OCstoreSkill={OCstoreSkill}
            storeEquipment={storeEquipment}
            top100Films={top100Films}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Overview
            states={{
              inputDisable,
              setInputDisable,
              overviewState,
              setOverviewState,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Curriculum
            states={{
              inputDisable,
              setInputDisable,
              curriculumState,
              setCurriculumState,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Benefits
            states={{
              inputDisable,
              setInputDisable,
              benefitesState,
              setBenefitesState,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Feedback
            states={{
              inputDisable,
              setInputDisable,
              FeadbackQuestion,
              setFeadBackQuestion,
            }}
            EditSubmitClick={EditSubmitClick}
          />
        </TabPanel>
      </div>
    </>
  );
}

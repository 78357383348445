import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  Box,
  Tab,
  Tabs,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
//core Component
import { FETCH_URL } from "fetchIp";
// import OngoingTab from "./OngoingTab";
// import CompleteTab from "./LPCompleteTab";
import TAOngoingCourseTab from "./TAOngoingCourseTab";
import TAExpiredCourseTab from "./TAExpiredCourseTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TACourseTab(props) {
  const [value, setValue] = React.useState(0);
  const { trainer, TrainerId } = props;
  console.log("tttttt", trainer);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const expiredCourses = trainer?.assignedCourses?.filter(
  //   (item) => item.completion === true
  // );

  // const OngoingCourses = trainer?.assignedCourses?.filter(
  //   (item) => item.completion === false
  // );

  let currentDate = moment().format();
  const OngoingCourses = trainer?.assignedCourses?.filter(
    (item) => currentDate <= moment(item?.expireOn).format()
  );

  // const expiredCourses = trainer?.assignedCourses?.filter(
  //   (item) => item.completion === true
  // );
  const expiredCourses = trainer?.assignedCourses?.filter(
    (item) => currentDate > moment(item?.expireOn).format()
  );
  const newArrayonGoing = OngoingCourses?.map((obj) => ({
    courseId: obj?.courseId,
  }));

  // console.log("OngoingCourses", OngoingCourses);

  const newArray = expiredCourses?.map((obj) => ({
    courseId: obj?.lessonPlanId,
  }));

  // console.log("newArrayonGoing1", newArrayonGoing);
  useEffect(() => {
    // GetAssignedOnGoingCourses(newArrayonGoing);
    // GetExpiredCourses();
  }, []);
  const [courseExpired, setCourseExpired] = useState([]);

  // const completedCourse = course?.filter((item) => item.completion === true);

  // const GetExpiredCourses = async () => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/lessonplan/course/getCoursesByCourseids`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //         body: JSON.stringify({
  //           CourseIds: expiredCourses,
  //         }),
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       setCourseExpired(res.msg);
  //       // //.log(" Get AssignedLessonPlan Responce   ", res.msg);
  //     } else {
  //       //.log("Check AssignedCourse Error ", res.err);
  //     }
  //   } catch (error) {
  //     //.error("Catch block ====>", error);
  //   }
  // };

  console.log("value == 0", value == 0);
  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Grid item conatiner>
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="GProcessTabStyle"
          >
            <Tab
              className="GProceesTabChanges"
              label="Ongoing"
              {...a11yProps(0)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Expired"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <TAOngoingCourseTab newArrayonGoing={newArrayonGoing} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TAExpiredCourseTab courseExpired={newArray} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

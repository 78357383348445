import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";

export default function Curriculum(props) {
  const {
    states: {
      inputDisable,
      setInputDisable,
      curriculumState,
      setCurriculumState,
      curriculumValid,
    },
    EditSubmitClick,
  } = props;
  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Curriculum
          </Typography>
          <Grid container>
            <TextareaAutosize
              className={
                curriculumValid
                  ? "textareaauto  width100 border-red  mt-16"
                  : " textareaauto width100   border-grey  mt-16"
              }
              aria-label="maximum height"
              disabled={inputDisable ? true : false}
              value={curriculumState}
              onChange={(e) => setCurriculumState(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

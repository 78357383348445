import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IconButton, Button, Breadcrumbs, Grid } from "@material-ui/core";
// Core components
import { FETCH_URL } from "fetchIp";
import SchoolTab from "./SchoolTab";
import { useLogin } from "Context/ExportContext";
import LoaderDialog from "components/Loader/LoaderDialog.js";
export default function SchoolDetails(props) {
  const [access, setAccess] = useState(1);
  const { schoolDetail, userToken } = useLogin();
  const [schoolData, setSchoolData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const fetchSchoolDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getSingleSchool/${schoolDetail.schoolUid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("sssssssss", res.msg);
        setSchoolData(res.msg);
      } else {
      }
      setLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchoolDetails();
  }, []);
  // const location = useLocation();

  // const { data } = location.state;
  // const { fetchData } = location.data;
  // console.log("profileData ", data._id);
  return (
    <>
      <LoaderDialog loading={loading} />
      {/* <div className="SDDiv">
        <IoIosArrowBack
          className="SDArrowIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          variant="h6"
          component="div"
          className="SDArrowButton BlueButton700"
        >
          Back
        </Button>
      </div> */}
      <div className="SDDiv">
        <Grid container>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Button
                onClick={() => history.goBack()}
                disableRipple
                variant="h6"
                component="div"
                className="SDBreadC"
              >
                <div className="SDBreadCTitle1">School</div>
              </Button>

              <div className="BreadcrumbColor   SDTitle2Style  ">
                School Detail
              </div>
            </Breadcrumbs>
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            item
            lg={8}
            md={6}
            xs={12}
          >
            <div className="SDSchoolNameTitle BlueColorTypography ">
              {schoolData?.organisationDetails?.schoolName}{" "}
              {schoolData?.organisationDetails?.location}
              {/* <IconButton className="ActionButtonSize">
                <Edit data={data} fetchUserDetails={fetchData} />
              </IconButton>
              <IconButton className="ActionButtonSize">
                <Switch accessCheck={access} />
              </IconButton>
              <IconButton className="ActionButtonSize">
                <Delete schoolId={data._id} />
              </IconButton> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <SchoolTab data={schoolData} />
    </>
  );
}

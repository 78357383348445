import axios from "axios";
import { FETCH_URL } from "fetchIp.js";
import {
  TABLE_DATA_SUCCESS,
  TABLE_DATA_REQUEST,
  TABLE_DATA_FAILS,
  EDIT_SCHOOL_DETAILS,
  SCHOOL_EDITED,
  SCHOOL_DELETED,
  SCHOOL_IS_DELETED,
  SAVE_LEARNER_TRAINER_VALUE,
  SAVE_ASSESSMENT_RESULT_VALUE,
} from "../constants/Constants.js";

export const tableData = (userToken) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_DATA_REQUEST });
    console.log("So many requests loooooooool");
    const response = await fetch(`${FETCH_URL}/api/school/getAllSchools`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });

    const res = await response.json();

    if (response.ok) {
      console.log("School Response ====>", res);
    } else {
      console.log("Error from schollGET =====>", res);
    }

    dispatch({
      type: TABLE_DATA_SUCCESS,
      payload: res.msg,
    });
  } catch (error) {
    dispatch({
      type: TABLE_DATA_FAILS,
      payload:
        error.responce && error.responce.data.message
          ? error.responce.data.message
          : error.message,
    });
  }
};

export const editSchool = (
  userToken,
  schoolId,
  schoolName,
  location,
  address,
  enrolledOn,
  firstTermStartDate,
  firstTermEndDate,
  secondTermStartDate,
  secondTermEndDate,
  thirdTermStartDate,
  thirdTermEndDate,
  zipCode,
  city,
  state,
  country,
  representativeName,
  representativeEmailId,
  representativeMobilePhoneNo,
  representativeAddress,
  file,
  rfile,
  imageUpload
) => async (dispatch) => {
  try {
    const response = await fetch(
      `${FETCH_URL}/api/school/editSchool/${schoolId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          schoolId,
          schoolName,
          location,
          address,
          enrolledOn,
          firstTermStartDate,
          secondTermStartDate,
          firstTermEndDate,
          secondTermEndDate,
          thirdTermStartDate,
          thirdTermEndDate,
          zipCode,
          city,
          state,
          country,
          representativeName,
          representativeEmailId,
          representativeMobilePhoneNo,
          representativeAddress,
        }),
      }
    );

    const res = await response.json();
    let ID = res.msg2._id;
    if (file || rfile) {
      imageUpload(schoolId);
    }
    if (response.ok) {
      console.log("School Response ====>", res);
      dispatch({
        type: EDIT_SCHOOL_DETAILS,
        payload: res.msg,
        isEdited: true,
      });
    } else {
      console.log("Error from schollGET =====>", res);
    }
  } catch (error) {
    // const res = await response.json();
    console.log("Errors =====>", error);
    // console.log("server side error ==>", res);
  }
};

// After School is edited

export const schoolEdited = () => async (dispatch) => {
  dispatch({
    type: SCHOOL_EDITED,
    isEdited: false,
  });
};

// Delete School

export const deleteSchool = (userToken, schoolId) => async (dispatch) => {
  try {
    const response = await fetch(
      `${FETCH_URL}/api/school/deleteSchool/${schoolId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    let res = await response.json();
    console.log("Deletion response =====>", res);
    dispatch({
      type: SCHOOL_DELETED,
      payload: res.msg,
    });
  } catch (error) {
    console.log("Error while calling ===>", error);
  }
};

export const schoolDeleted = () => async (dispatch) => {
  dispatch({
    type: SCHOOL_IS_DELETED,
    payload: false,
  });
};

export const saveLearnerTrainerTabValue = (tabValue) => {
  return {
    type: SAVE_LEARNER_TRAINER_VALUE,
    payload: tabValue,
  };
};

export const saveAssestmentResultTabValue = (tabValue) => {
  return {
    type: SAVE_ASSESSMENT_RESULT_VALUE,
    payload: tabValue,
  };
};

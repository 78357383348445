import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Input,
  TextareaAutosize,
  IconButton,
  Tab,
  Box,
  Tabs,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import DeleteDialog from "../ActionDialog/EquipmentActionDialog's/EquipDeleteDialog";
import SwitchDialog from "../ActionDialog/EquipmentActionDialog's/EquipSwitchDialog";
import EditDialog from "../ActionDialog/EquipmentActionDialog's/EquipEditDialog";
import EquipmentDetails from "./EProfileTab/PEquipmentDetails";
import EAssignedto from "./EProfileTab/EAssignedTab/EAssignedToTab";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function EquipmentProfile() {
  const location = useLocation();
  const equipmentDetails = location.state;
  console.log(equipmentDetails, "equipmentDetails>>>>>>>>>>>>>");
  const [access, setAccess] = useState(1);
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const [singleEquip, setSingleEquip] = useState("");
  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const { userToken } = useLogin();

  const getSingleEquipment = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getSingleEquipment/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setSingleEquip(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getSingleEquipment();
  }, []);
  return (
    <>
      {/* <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div> */}
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Equipments
          </Button>
          <div className="Breadcrumb2">
            {equipmentDetails.equipment.equipmentName}
          </div>
        </Breadcrumbs>
      </div>
      <Grid container>
        <Grid item container className="ZoomerStyle">
          <Typography className="GreyColorTypography zoomerTypo ">
            {singleEquip.equipmentName}
          </Typography>
          {/* <IconButton className="ActionButtonSize">
            <EditDialog />
          </IconButton>
          <IconButton className="ActionButtonSize">
            <SwitchDialog accessCheck={access} />
          </IconButton>
          <DeleteDialog />{" "} */}
        </Grid>
      </Grid>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Equipment Details"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Assigned To "
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <EquipmentDetails
          equipmentDetails={equipmentDetails}
          singleEquip={singleEquip}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <EAssignedto equipmentDetails={equipmentDetails} />
      </TabPanel>
    </>
  );
}

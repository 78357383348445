import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// ReactIcons
import { MdEdit } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(90),
    height: theme.spacing(37),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Add Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  data,
  setGradeName,
  editGrade,
  GradeSearch,
}) {
  const classes = useStyles();
  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setGradeName(null);
    setOpen(false);
  };
  //.log("Grade Edit Data ", data);
  return (
    <div>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false}>
          <Grid container spacing={4} className="GridConatinerStyle">
            <Grid item md={6} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  School UID <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12}>
                  <Input
                    className="Input1"
                    value={data.schoolUid}
                    disabled
                  ></Input>
                </Grid>

                <Typography className="InputTitleTypography  GreyColorTypography">
                  Location <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12}>
                  <Input
                    className="Input1"
                    disabled
                    value={data.location}
                  ></Input>
                </Grid>
              </Grid>
            </Grid>

            <Grid direction="column" item md={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                School Name <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12}>
                <Input
                  className="Input1"
                  value={data.schoolName}
                  disabled
                ></Input>
              </Grid>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Grade <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12}>
                <Input
                  className="Input1"
                  defaultValue={data.grade}
                  onChange={(e) => {
                    setGradeName(e.target.value);
                  }}
                ></Input>
              </Grid>
              <DialogActions className="SGradeBottom">
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={() =>
                    editGrade(data.gradeId, data.schoolUid, setOpen)
                  }
                >
                  Save
                </Button>
                <Button
                  className="GreyButton GradeCancelB"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";

export default function AssesmentTab({ equipment }) {
  return (
    <>
      <Grid container style={{ minHeight: "70vh" }}>
        <div className="ResponsiveTable width100">
          <Table className="Tableroot">
            <TableHead>
              <TableRow>
                <TableCell className="Head"> UID </TableCell>
                <TableCell className="Head">Equipment Name </TableCell>
                <TableCell className="Head">Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {equipment &&
                equipment.length > 0 &&
                equipment.map((data) => (
                  <TableRow>
                    <TableCell className="Col">{data?.equipmentUid}</TableCell>
                    <TableCell className="Col">{data?.equipmentName}</TableCell>
                    <TableCell className="Col ActionButtonSize">
                      <Typography className="GreyColorTypography500 overflow-style">
                        {data?.equipmentDesc}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {equipment?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No equipment Assign!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

// ReactIcons
import { MdEdit } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(90),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Edit Course
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  marketPlaceCourseId,
  MarketPlaceCourses,
}) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  const [snackopen, setSnackOpen] = React.useState(false);
  const [snackmsg, setSnackMsg] = React.useState("");
  const [snackErrMsg, setSnackErrMsg] = React.useState();
  const [snackerropen, setSnackerropen] = React.useState(false);

  const [courseTitle, setCourseTitle] = React.useState("");
  const [coursePrice, setCoursePrice] = React.useState("");
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const submitClick = async (e) => {
    if (!courseTitle) {
      setSnackerropen(true);
      setSnackErrMsg("Fill Name of Course");
      return;
    }

    if (!coursePrice) {
      setSnackerropen(true);
      setSnackErrMsg("fill Price");
      return;
    }
    e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/editMarketPlaceCourse/${marketPlaceCourseId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            courseTitle,
            coursePrice,
          }),
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        MarketPlaceCourses();
        handleClose();

        //.log("Mah respo =====>", res);
      } else {
        //.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      //.log("Error while adding Learner =======>", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCoursePrice("");
    setCourseTitle("");
  };
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers={false}>
          <Grid container spacing={4} className="GridConatinerStyle">
            <Grid item md={6} direction="column">
              <Typography className="InputTitleTypography  GreyColorTypography">
                Name <span className="ImpColor">*</span>
              </Typography>
              <Input
                className="Input1 width100"
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
              >
                {" "}
              </Input>
            </Grid>

            <Grid direction="column" item md={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Price <span className="ImpColor">*</span>
              </Typography>
              <Input
                className="Input1"
                value={coursePrice}
                onChange={(e) => setCoursePrice(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="GreenButton GradeSaveB" onClick={submitClick}>
            Save
          </Button>
          <Button className="GreyButton GradeCancelB" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";

import {
  DialogActions,
  Input,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
//Icons
import { IoIosAddCircle } from "react-icons/io";

export default function ExtendTab(props) {
  const {
    states: {
      schoolName,
      setSchoolName,
      location,
      setLocation,
      address,
      setAddress,
      zipCode,
      setZipCode,
      city,
      setCity,
      state,
      setState,
      country,
      setCountry,
      representativeName,
      setRepresentativeName,
      representativeEmailId,
      setRepresentativeEmailId,
      representativeMobilePhoneNo,
      setRepresentativeMobilePhoneNo,
      representativeAddress,
      setRepresentativeAddress,
      rimage,
      setRImage,
      rfile,
      setRFile,
    },
    TabNext2,
    data,
    submitClick,
  } = props;
  console.log(" Tab Next 2========>", { TabNext2 });

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const [error, setError] = useState();

  /////////////////////////////////////////////////////////////
  function RImageUploader(e) {
    console.log(e.target.files);
    setRFile(URL.createObjectURL(e.target.files[0]));
    setRImage(e.target.files[0]);
  }
  /////////////////////////////////////////////////////////////////

  return (
    <Grid container spacing={4} className="DailogLTRBStyle">
      <Grid item md={6} sm={6} xs={6} lg={6}>
        <Grid direction="column" item style={{ marginLeft: "3%" }}>
          <Typography className="InputTitleTypography GreyColorTypography">
            School Name <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.organisationDetails.schoolName}
              disabled
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Location <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.organisationDetails.location}
              disabled
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Address <span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <TextareaAutosize
              className="ContactTextArea"
              aria-label="minimum height"
              defaultValue={data.contactDetail.address}
              onChange={(e) => setAddress(e.target.value)}
            ></TextareaAutosize>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Zip Code <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.contactDetail.zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              disabled
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            City <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.contactDetail.city}
              onChange={(e) => setCity(e.target.value)}
              disabled
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            State <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.contactDetail.state}
              onChange={(e) => setState(e.target.value)}
              disabled
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Country <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.contactDetail.country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {" "}
            </Input>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
        <Grid item direction="column">
          <Typography className="InputTitleTypography GreyColorTypography">
            Representative Name <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.contactDetail.representativeName}
              onChange={(e) => setRepresentativeName(e.target.value)}
            ></Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Representative Email id <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              defaultValue={data.contactDetail.representativeEmailId}
              // onChange={(e) => setRepresentativeEmailId(e.target.value)}
              onChange={(e) => {
                if (e.target.value == "") {
                  setError("");
                } else if (!isEmail(e.target.value)) {
                  setError("invalid email");
                } else {
                  setError("");
                }
                setRepresentativeEmailId(e.target.value);
              }}
            >
              {" "}
            </Input>
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {error}
            </span>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Representative Mobile Number
            <span className="ImpColor"> *</span>
          </Typography>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              type="number"
              defaultValue={data.contactDetail.representativeMobilePhoneNo}
              onChange={(e) =>
                setRepresentativeMobilePhoneNo(e.target.value.slice(0, 10))
              }
            >
              {" "}
            </Input>
          </Grid>
          <Typography className="InputTitleTypography GreyColorTypography">
            Representative Address <span className="ImpColor"> *</span>
          </Typography>{" "}
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <TextareaAutosize
              className="ContactTextArea"
              aria-label="minimum height"
              defaultValue={data.contactDetail.representativeAddress}
              onChange={(e) => setRepresentativeAddress(e.target.value)}
            />
          </Grid>
          <Grid item md={10} sm={10} xs={10} lg={10} className="AddphotoItem">
            <Typography className=" BlueColorTypography AddLogoTypography">
              Add Photo
            </Typography>
            <Typography className="  AddLogoTypography">
              <img
                src={rfile}
                style={{
                  height: "100%",
                  width: "280px",
                  objectFit: "contain",
                }}
              />{" "}
            </Typography>

            <Grid
              item
              style={{ margin: "10px 0px" }}
              className="ChooseFileButtonItem"
            >
              <input
                style={{ display: "none" }}
                type="file"
                id="raised-button-file"
                accept="image/*"
                onChange={RImageUploader}
              />{" "}
              <label htmlFor="raised-button-file">
                <Button
                  className="GreenButton "
                  variant="raised"
                  component="span"
                >
                  <IoIosAddCircle className="AddCircleIcon" />
                  Choose File
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions className="DialogActionButton">
          {/* <Button className="GreenButton700 ACNextButton " onClick={TabNext2}>
            Next
          </Button> */}
          <Button type="submit" className="SumbitButton" onClick={submitClick}>
            Submit
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
}

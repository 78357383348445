import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import { FETCH_URL } from "fetchIp";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
// React Icons
import TrianerProfileDailog from "./GTrainerProfile/GTrainerProfile";
import PersonalDetails from "./GTrainerProfile/GTrainerTab/GTPersonalTab";
//React Icons

import { BsEye } from "react-icons/bs";
import { CgClipboard } from "react-icons/cg";
import { IoAnalyticsSharp } from "react-icons/io5";
import { useLogin } from "Context/ExportContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function GTrainerTab({ gradeId }) {
  const { userToken } = useLogin();

  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  const [value, setValue] = React.useState(false);

  const [trainerData, setTrainerData] = useState(null);

  /////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////
  const getPaginatedTrainers = async (gradeId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedTrainers?gradeId=${gradeId}
      `,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTrainerData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  // //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getPaginatedTrainers(gradeId);
  }, [gradeId]);

  console.log(trainerData, "trainerData>>>>>>>>>>>>>>>>>>>>");
  console.log(gradeId, "gradeId>>>>>>>>>>>>>>>>>>>>>>");

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(trainerData, "trainer>>>>>>>>>>>>>>>>>>>>>>>>>");
  return (
    <>
      {/* <div className="DivisionStyleGrid"> */}
      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Trainer’s Name </TableCell>
              <TableCell className="ColHead"> School Name</TableCell>
              <TableCell className="ColHead"> Location</TableCell>
              <TableCell className="ColHead"> Grade </TableCell>
              <TableCell className="ColHead"> Division </TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {trainerData &&
              trainerData.length > 0 &&
              trainerData.map((tdata) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link>1122040 </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {tdata.trainerDetail.fullName}
                  </TableCell>
                  <TableCell className="Col">
                    <Link>
                      {/* <TrianerProfileDailog i={selected} /> :{" "}
                    <TrianerProfileDailog i={2} /> */}
                      <TrianerProfileDailog
                        TrainerId={tdata._id}
                        trainer={tdata}
                      />
                      {/* {tdata.assignedSchool[0].schoolName} */}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {tdata.assignedSchool[0].location}
                  </TableCell>
                  <TableCell className="Col">
                    {tdata.assignedSchool[0].gradeName}
                  </TableCell>
                  <TableCell className="Col"> A</TableCell>

                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <BsEye className="ActionButtonIconGrey" />
                    </IconButton>
                    <IconButton
                      className="ActionButtonSize"
                      onClick={() => setValue(!value)}
                    >
                      {/* <TrianerProfileDailog i={value ? 2 : 0} /> */}
                      <IoAnalyticsSharp
                        onClick={() => setValue(!value)}
                        className="ActionButtonIconGrey"
                      />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <CgClipboard className="ActionButtonIconGrey" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
      {/* </div> */}
    </>
  );
}

import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Input,
  TextareaAutosize,
  IconButton,
  Tab,
  Box,
  Tabs,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation } from "react-router-dom";

import DeleteDialog from "../ActionButtonDialog's/LocomotorActionB/DeleteLocoDialog";
import SwitchDialog from "../ActionButtonDialog's/LocomotorActionB/SwitchLocoDialog";
import EditDialog from "../ActionButtonDialog's/LocomotorActionB/EdiLocomotor/LocoEditMain";
import SkillDetailsTab from "./SPLocoTab/SkillDetialsTab";
import RubricsTab from "./SPLocoTab/SPRubricsTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SkillProfile(props) {
  const [access, setAccess] = useState(1);
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    mainSkill,
    setMainSkill,
    subSkillName,
    setSubSkillName,
    subSkillUid,
    setSubSkillUid,
    subSkillDesc,
    setSubSkillDesc,
    xpAssigned,
    setXpAssigned,
    level,
    setLevel,
    rubricsQts,
    setRubricsQts,
    skillsData,
    skills,
    searchSkill,
  } = location.states;

  console.log("Skill data ===================>yoo ", skills);
  return (
    <>
      {/* <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div> */}

      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Skill Library
          </Button>
          <div className="Breadcrumb2">{skills.mainSkill}</div>
        </Breadcrumbs>
      </div>
      <Grid container>
        <Grid item container className="ZoomerStyle">
          <Typography className="GreyColorTypography zoomerTypo ">
            {skills.subSkillName}
          </Typography>
          {/* <IconButton className="ActionButtonSize">
            <EditDialog />
          </IconButton>
          <IconButton className="ActionButtonSize">
            <SwitchDialog accessCheck={access} />
          </IconButton>
          <DeleteDialog />{" "} */}
        </Grid>
      </Grid>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Skill Details"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Rubrics "
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <SkillDetailsTab skills={skills} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <RubricsTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            rubricsQts,
            setRubricsQts,
            skills,
            skillsData,
            searchSkill,
          }}
        />
      </TabPanel>
    </>
  );
}

import React, { useState, useEffect } from "react";
import LoaderDialog from "components/Loader/LoaderDialog";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination, Typography } from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core";
//core Component
import EquipDeleteDialog from "../ActionDialog/EquipmentActionDialog's/EquipDeleteDialog";
import EquipSwitchDialog from "../ActionDialog/EquipmentActionDialog's/EquipSwitchDialog";
import EquipEdit from "../ActionDialog/EquipmentActionDialog's/EquipEditDialog";
import EAssignGrade from "../ActionDialog/EquipmentActionDialog's/EAssignGradeDialog";
import EAssignLearner from "../ActionDialog/EquipmentActionDialog's/EAssignLearner";
import EAssignDivision from "../ActionDialog/EquipmentActionDialog's/EAssignDivisionDialog";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function RenameTab(props) {
  const [value, setValue] = React.useState(0);
  const [access, setAccess] = useState(1);
  const [posts, setPosts] = useState([]);
  // const [total, setTotal] = useState();
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const { id } = useParams();

  const { userToken } = useLogin();
  const {
    states: {
      equipmentData,
      serachData,
      schoolSelected,
      searchDataE,
      pageNoE,
      setPageNoE,
      totalE,
      setTotalE,
      rowsPerPageE,
      setRowsPerPageE,
    },
    getAllEquipment,
    handleSearchE,
  } = props;
  console.log(" Data Table in Equipment Tab", equipmentData);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPageE(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteEquipment = async (EquipmentId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/deleteEquipment/${EquipmentId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response ==>", res);
        getAllEquipment();
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID</TableCell>
              <TableCell className="Head">Equipment Name </TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {equipmentData &&
              equipmentData.length > 0 &&
              equipmentData.map((equipment) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link>{equipment.equipmentUid} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: `/admin/equipmentprofile/${equipment._id}`,
                        state: { equipment },
                      }}
                    >
                      {equipment.equipmentName}
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Typography className="GreyColorTypography500 overflow-style">
                      {equipment.equipmentDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Action">
                    <EquipEdit
                      equipmentData={equipmentData}
                      getAllEquipment={getAllEquipment}
                      equipment={equipment}
                    />
                    <IconButton className="ActionButtonSize">
                      <EAssignGrade equipmentId={equipment._id} />
                    </IconButton>

                    <IconButton className="ActionButtonSize">
                      <EAssignDivision equipmentId={equipment._id} />
                    </IconButton>

                    <IconButton className="ActionButtonSize">
                      <EAssignLearner equipmentId={equipment._id} />
                    </IconButton>

                    {/* <IconButton className="ActionButtonSize">
                      <EquipSwitchDialog
                        equipmentId={equipment._id}
                        getAllEquipment={getAllEquipment}
                        access={equipment.access}
                      />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {equipmentData?.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Equipment Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected || searchDataE || equipmentData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              handleSearchE(value, value2);
              setPageNoE(value);
            }}
            pageSize={rowsPerPageE}
            total={totalE}
            current={pageNoE}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Input,
  TextareaAutosize,
  IconButton,
  Tab,
  Box,
  Tabs,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import EquipmentDetails from "./EProfileTab/PEquipmentDetails";
import EAssignedto from "./EProfileTab/EAssignedTab/EAssignedToTab";
import { useLocation } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function EquipmentProfile() {
  const location = useLocation();
  const history = useHistory();
  const { equipment } = location.state;

  const [value, setValue] = React.useState(0);
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Equipments
          </Button>
          <div className="Breadcrumb2">Paddle</div>
        </Breadcrumbs>
      </div>

      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Equipment Details"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Assigned To "
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <EquipmentDetails equipment={equipment} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <EAssignedto equipment={equipment} />
      </TabPanel>
    </>
  );
}

import Dashboard from "views/Dashboard/Dashboard.js";
import AdminLeaderBoard from "views/AdminLeaderBoard/AdminBoardTab";
import CreatorMode from "views/CreatorMode/App.js";
import Pages from "views/CreatorMode/CreatorModeTab/NOFPage";
import PublieshedPage from "views/CreatorMode/PublishedTab/PublishedPage";
import Editor from "views/CreatorMode/template-editor/index";
import School from "views/School/School/School.js";
import SchoolDetails from "views/School/School/SchooNamelDetails/SchoolDetails";
import Grade from "views/School/Grade/Grade.js";
import GradeProfile from "views/School/Grade/GradeProfile's/GradeProfieMainPage";
import Trainer from "views/School/Trainer/Trainer.js";
import Learner from "views/School/Learner/Learner";
import Course from "views/Course/Course";
import AddCourse from "./views/Course/CourseAddPage/CourseAdd";
import CourseProfile from "views/Course/CourseProfile-Edit/CourseProfile";
import EditCourse from "./views/Course/CourseActionDialog.js/EditCourse/CourseEdit";
import Activity from "views/ActivityLibrary/ActivityLibrary.js";
import AddActivity from "./views/ActivityLibrary/ActivityAdd/ActivityAdd";
import EditActivity from "./views/ActivityLibrary/ActivityAction/EditActivity/EdittActivity";
import ActivityProfile from "./views/ActivityLibrary/ActivityProfile/ActivityProfile";
import Lesson from "views/LessonPlans/LessonPlans.js";
import LessonPlanAdd from "./views/LessonPlans/AddLessonPlan/LessonPlanAdd";
import LessonPlanTrainerProfile from "./views/LessonPlans/LPTrainerProfile-W-Edit/LPTrainerProfle";
import LessonPlanProfile from "./views/LessonPlans/LPLearnerProfile-W-Edit/LessonPlanProfle";
import AssignLessonPlan from "./views/LessonPlans/AssingLessonplan/LessonPlanAssign";
import Skill from "views/Skills/Skills.js";
import SkillProfile from "./views/Skills/SkillsProfile/SkillProfileMain";
import BMProfile from "./views/Skills/BodyMgtProfile/BMProfileMain";
import OCProfile from "./views/Skills/ObjectControlProfile/OCProfileMain";
import Equipments from "views/Equipments/Eqipments.js";
import EquipmentProfile from "views/Equipments/EquipmentProfile/EquipmentProfile";
import KitProfile from "views/Equipments/KitProfile/EquipmentKitProfile";
import Assessment from "views/Assesments/Assesments.js";
import AResultLPProfile from "views/Assesments/ResultProfile/RLessonPalnProfile/LessonPlanProfile";
import AResultActivityProfile from "views/Assesments/ResultProfile/RActivityProfile/ActivityProfile";
import AResultSkillsProfile from "views/Assesments/ResultProfile/RSkillsProfile/SkillProfile";
import Events from "./views/Events/Events.js";
import User from "views/Users/User.js";
import UserProfile from "views/Users/UserProfile/UserProfile.js";
import MarketPlace from "views/ManageMarketPlace/MarketPlace";

//Other Core Component
import SignIn from "views/SignIn/SignIn";
//ReactIcons
import { FaSchool } from "react-icons/fa";
import { IoBody, IoSchoolOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { GiTeacher, GiSoccerBall } from "react-icons/gi";
import { MdOutlineLibraryBooks, MdDashboard } from "react-icons/md";
import { BiBook } from "react-icons/bi";
import { BsFileEarmark, BsBook } from "react-icons/bs";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import MessageIcon from "@material-ui/icons/Message";
import { MdOutlineBook } from "react-icons/md";
import { BsBarChartLine } from "react-icons/bs";
import { BsTrophy } from "react-icons/bs";
import { BiStoreAlt } from "react-icons/bi";
import { BsFillBarChartLineFill } from "react-icons/bs";
const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: MdDashboard,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    rtlName: "الرسوم البيانية",
    icon: BsFillBarChartLineFill,
    component: AdminLeaderBoard,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/CaretorMode",
    component: CreatorMode,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/folder/:folderId",
    component: Pages,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/editor/:pageId/:folderId",
    component: Editor,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/publishedpage/:folderId",
    component: PublieshedPage,
    layout: "/admin",
  },

  {
    collapse: true,
    icon: FaSchool,
    name: "School",
    layout: "/admin",
    path: "/school",
    component: School,
    state: "componentsCollapse",
    views: [
      {
        path: "/school",
        name: "School",
        rtlName: "وصفت",
        icon: IoSchoolOutline,
        rtlMini: "ب",
        component: School,
        layout: "/admin",
      },

      {
        invisible: true,
        path: "/schooldetails",
        component: SchoolDetails,
        layout: "/admin",
      },

      {
        path: "/grade",
        name: "Grade",
        icon: BsFileEarmark,
        component: Grade,
        layout: "/admin",
      },
      {
        invisible: true,
        path: "/gradeprofile/:id",
        component: GradeProfile,
        layout: "/admin",
      },

      {
        path: "/learner",
        name: "Learner",
        rtlName: "الحلو تنبيه",
        icon: AiOutlineUser,
        rtlMini: "ومن",
        component: Learner,
        layout: "/admin",
      },
      {
        path: "/trainer",
        name: "Trainer",
        rtlName: "إخطارات",
        icon: GiTeacher,
        rtlMini: "ن",
        component: Trainer,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/course",
    name: "Courses",
    rtlName: "الرسوم البيانية",
    icon: BsBook,
    component: Course,
    layout: "/admin",
  },

  {
    invisible: true,
    path: "/addCourse",
    component: AddCourse,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/EditCourse",
    component: EditCourse,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/courseprofile",
    component: CourseProfile,
    layout: "/admin",
  },
  // {
  //   path: "/activity",
  //   name: "Activity Library",
  //   rtlName: "الرسوم البيانية",
  //   icon: IoBody,
  //   component: Activity,
  //   layout: "/admin",
  // },
  {
    invisible: true,
    path: "/addActivity",
    component: AddActivity,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/EditActivity",
    component: EditActivity,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/activityprofile",
    component: ActivityProfile,
    layout: "/admin",
  },
  {
    path: "/Lesson",
    name: "Lesson Plans",
    rtlName: "الرسوم البيانية",
    icon: BiBook,
    component: Lesson,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/addLessonPlan",
    component: LessonPlanAdd,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/assignLessonPlan/:id",
    component: AssignLessonPlan,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/lessonplanprofile",
    component: LessonPlanProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/lessonplantrainerprofile",
    component: LessonPlanTrainerProfile,
    layout: "/admin",
  },
  {
    path: "/skills",
    name: "Skills",
    rtlName: "التقويم",
    icon: BsBarChartLine,
    component: Skill,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/skillprofile/:id",
    component: SkillProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/bodymgtprofile/:id",
    component: BMProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/objectcontrolprofile/:id",
    component: OCProfile,
    layout: "/admin",
  },
  {
    path: "/equipments",
    name: "Equipments",
    rtlName: "الحاجيات",
    icon: GiSoccerBall,
    component: Equipments,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/equipmentprofile/:id",
    component: EquipmentProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/equipmentkitprofile/:id",
    component: KitProfile,
    layout: "/admin",
  },
  {
    path: "/assesments",
    name: "Assesments",
    rtlName: "الحاجيات",
    icon: MdOutlineBook,
    component: Assessment,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/resultlessonplanprofile",
    component: AResultLPProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/resultactivityprofile",
    component: AResultActivityProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/resultskillsprofile",
    component: AResultSkillsProfile,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    icon: BsTrophy,
    component: Events,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleOutlineRoundedIcon,
    component: User,
    layout: "/admin",
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    icon: BiStoreAlt,
    component: MarketPlace,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/userprofile",
    component: UserProfile,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/signin",
    name: "SignIn",
    rtlName: "الحاجيات",
    icon: MessageIcon,
    component: SignIn,
    layout: "/auth",
  },
];

export default dashRoutes;

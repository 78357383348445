import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  Grid,
} from "@material-ui/core";
import FeadbackProfileDialog from "./FeadbackProfileDiallog";
import Feadback from "assets/img/feadback.gif";
export default function ReciveFeadback({ EditSubmitClick }) {
  const [faedback, setFeadBack] = useState(null);

  return (
    <>
      <div className=" width100 ResponsiveTable">
        {faedback?.map((data) => {
          return (
            <>
              <Table className="mt-20" style={{ border: "1px solid #d4d4d4" }}>
                <TableHead>
                  <TableRow className="Head">
                    <TableCell className="ColHead">UID</TableCell>
                    <TableCell className="ColHead">Name </TableCell>
                    <TableCell className="ColHead"> School Name </TableCell>
                    <TableCell className="ColHead"> Location </TableCell>
                    <TableCell className="ColHead"> Date </TableCell>
                    <TableCell className="ColHead"> Feedback </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell className=" Col700">#2431</TableCell>
                    <TableCell className=" Col700">Loreum ipsum plan</TableCell>
                    <TableCell className=" Col700">
                      Loreum lesson plan
                    </TableCell>
                    <TableCell className=" Col700">Loreum ipsum</TableCell>
                    <TableCell className="Col700  ">50 PP</TableCell>
                    <TableCell className="ActionButtonSize Action ">
                      <FeadbackProfileDialog />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>{" "}
            </>
          );
        })}

        {faedback === null ? (
          <Grid
            container
            style={{
              height: "50vh",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Feadback}
              style={{
                height: "50vh",
              }}
            />
            <Typography
              align="center"
              className="DarkBlackColorTypo width100 mt-10"
            >
              No feedback recieved for this course.
            </Typography>
          </Grid>
        ) : null}
      </div>
    </>
  );
}

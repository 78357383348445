import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
export default function RenameTab(props) {
  const { TabNext } = props;
  const {
    states: {
      courseUid,
      setCourseUid,
      courseTitle,
      setCourseTitle,
      courseStartDate,
      setCourseStartDate,
      courseEndate,
      setCourseEndDate,
      courseUidText,
      courseTitleText,
    },
  } = props;
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  GreyColorTypography">
              Course UID<span className="ImpColor"> *</span>
            </Typography>
            <Grid item containermd={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                value={courseUid}
                onChange={(e) => setCourseUid(e.target.value)}
              ></Input>
            </Grid>
            {!courseUid && (
              <Typography className="warningTypography">
                {courseUidText}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="InputTitleTypography  GreyColorTypography">
            Course Title<span className="ImpColor"> *</span>
          </Typography>
          <Grid item containermd={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              value={courseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
            ></Input>
          </Grid>
          {!courseTitle && (
            <Typography className="warningTypography">
              {courseTitleText}
            </Typography>
          )}
        </Grid>
        {/* <Grid
          item
          md={6}
          sm={6}
          xs={6}
          lg={6}
          style={{ marginBottom: "308px " }}
          direction="column"
        >
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  BlueColorTypography">
              Start Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid itemmd={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="MM/dd/yyyy"
                    value={courseStartDate}
                    onChange={(e) => setCourseStartDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography className="InputTitleTypography  BlueColorTypography">
              End Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid itemmd={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="MM/dd/yyyy"
                    value={courseEndate}
                    onChange={(e) => setCourseEndDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}

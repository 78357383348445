import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
} from "@material-ui/core";
// @material-ui/core components
import DeleteDialog from "../../ActivityAction/DeleteActivity";
import SwitchDialog from "../../ActivityAction/SwitchActivity";
import VarientDialog from "./APVarientTab/EditVarientDialog";
///React Icons
import { MdEdit } from "react-icons/md";

export default function ExtendedTables() {
  const [access, setAccess] = useState(1);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <div>
        <div className="ResponsiveTableAL">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="ColHeadAL">UID </TableCell>
                <TableCell className="ColHeadAL">
                  Name of activity Variant
                </TableCell>

                <TableCell className="ColHeadAL">Level </TableCell>
                <TableCell className="ColHeadAL"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {posts.slice(page, rowsPerPage).map((post) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link>1.2</Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Link>
                      <VarientDialog />{" "}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700"> Beginner </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <Link to="/admin/EditActivity">
                        <IconButton className="ActionButtonSize">
                          <MdEdit className="ActionButtonIcon" />
                        </IconButton>
                      </Link>{" "}
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <SwitchDialog accessCheck={access} />
                    </IconButton>
                    <DeleteDialog />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>{" "}
      </div>
    </>
  );
}

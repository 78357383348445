import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
// React Icons
import GTrainer from "./PAddTrainer/TrainerAdd";
import TrainerEdit from "../../../../PrincipalTrainer/PTrainerActionDialog/TrainerEdit/EditTainer";

import TrainerProfile from "./GTrainerProfile/GTrainerProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function GTrainerTab(props) {
  const { gradeId, data } = props;

  const [trainerData, setTrainerData] = React.useState(null);
  const { userToken, schoolDetail, loadingHandler } = useLogin();

  const getAllTrainers = async (gradeId) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedTrainers?gradeId=${gradeId}&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        console.log("My response of Trainers==>", res);
        setTrainerData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllTrainers(gradeId);
  }, []);

  return (
    <>
      <Grid container>
        <Grid container item className="  DivisionStyleGridSkill ">
          <Grid container item className="DiviStyle ">
            <Typography></Typography>
            <GTrainer
              data={data}
              gradeId={gradeId}
              getAllTrainers={getAllTrainers}
            />
          </Grid>
        </Grid>
      </Grid>

      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead"> Trainer's Name</TableCell>
              <TableCell className="ColHead"> Mobile Number</TableCell>
              <TableCell className="ColHead"> Email ID </TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainerData &&
              trainerData.length &&
              trainerData.map((trainer) => (
                <TableRow>
                  <TableCell className="Col">
                    {trainer?.trainerDetail?.regNo}
                  </TableCell>
                  <TableCell className="Col">
                    <TrainerProfile
                      data={data}
                      trainer={trainer}
                      TrainerId={trainer?._id}
                    />
                  </TableCell>
                  <TableCell className="Col">
                    {/* {trainer.assignedGrades
                      ? trainer.assignedGrades.length
                      : "--"} */}
                    {trainer?.contactDetail?.trainerPhoneNo
                      ? trainer?.contactDetail?.trainerPhoneNo
                      : "--"}
                  </TableCell>
                  <TableCell className="Col">
                    {/* {trainer.assignedGrades
                      ? trainer.assignedGrades.length
                      : "--"} */}
                    {trainer?.contactDetail?.trainerEmail
                      ? trainer?.contactDetail?.trainerEmail
                      : "--"}
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <TrainerEdit
                        trainer={trainer}
                        gradeId={gradeId}
                        getAllTrainers={getAllTrainers}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <TrainerSwitch
                        access={trainer.access}
                        TrainerId={trainer._id}
                        paginatedTrainer={paginatedTrainer}
                        gradeId={gradeId}
                        getAllTrainers={getAllTrainers}
                      />
                    </IconButton> */}
                    {/* <IconButton className="ActionButtonSize">
                      <TrainerDelete
                        TrainerId={trainer._id}
                        deleteTrainer={deleteTrainer}
                      />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
// @material-ui/core components
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@material-ui/core";
import LoaderDialog from "components/Loader/LoaderDialog";
import axios from "axios";
import { GoSearch } from "react-icons/go";

// core components
import LearnerAdd from "./AddLearner/LearnerAdd";
import LearnerEdit from "./LearnerActionDialog/EditDialog_s/EditDialog";
import LearnerSwicth from "./LearnerActionDialog/SwitchDialog";
import LLessonPlan from "./LearnerActionDialog/LLessonPlanDialog/LLPDialogMain";
import LearnerProfile from "./LearnerProfile/LearnerProfile";
import UploadExcel from "./UploadExcel/AddGradeExcel";
import AssignAssement from "./StudentAction/LLessonPlanDialog/LAssesmentMain";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Learner(props) {
  const tableRef = useRef();
  const { userToken } = useLogin();
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [learnerData, setLearnerData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [pageNo2, setPageNo2] = useState(1);
  const [total2, setTotal2] = useState(10);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);

  const [serachData, setSerachData] = useState(null);

  const [serachData2, setSerachData2] = useState(null);

  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [selectGrade, setSelectGrade] = useState("");
  const [gradeSelected, setGradeSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState("");
  const [loading, setLoading] = useState(true);

  const onShowSizeChange = (current, pageSize) => {
    //.log("Page size ========>", current, pageSize);
    setRowsPerPage(pageSize);
    handleSearch(null, pageSize);
  };

  const onShowSizeChange2 = (current, pageSize) => {
    //.log("Page size ========>", current, pageSize);
    setRowsPerPage2(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const itemRender2 = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  let learnerProp = 1;
  // For DropDown
  ////////////////getDistinctSchoolAndLocation////////////////////////////
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      setSchoolLocationSelected(null);
      setGradeSelected(null);
      setLocationForGrade(null);
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          //.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);

          setSchoolNames(res.msg);
        }
      } else {
        //.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  //////////////////////get grade///////////////////////////////////
  const getGrades = async (location) => {
    setGradeSelected("");
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        //.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  //////////////////////get grade///////////////////////////////////
  ////////////////////////getPaginatedLearners/////////////////////////
  const getLearnersBySchool = async (school) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?pagination=true&schoolName=${school}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      setLoading(false);
      if (response.ok) {
        //.log("My response of paginated Trainers==>", res);

        setLearnerData(res.msg);
        setTotal2(res.lengthData);
        setPageNo2(1);
        console.log("my data", res);
      } else {
        //.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  const getLearnersByLocation = async (location) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?pagination=true&schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      setLoading(false);
      if (response.ok) {
        //.log("My response of paginated Trainers==>", res);

        setLearnerData(res.msg);
        setTotal2(res.lengthData);
        setPageNo2(1);
        console.log("my data", res);
      } else {
        //.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  const getLearnersByGrade = async (grade) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?pagination=true&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      setLoading(false);
      if (response.ok) {
        //.log("My response of paginated Trainers==>", res);

        setLearnerData(res.msg);
        setTotal2(res.lengthData);
        setPageNo2(1);
        console.log("my data", res);
      } else {
        //.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  ////////////////////////getPaginatedLearners/////////////////////////
  ////////////////getDistinctSchoolAndLocation////////////////////////////

  /////////////////////handle search for Normal Learner/////////////////////////
  const handleSearch = async (pageNum, rowSize) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getPaginatedNormalLearner?page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);

        console.log(
          "My response of getAllZoomerPlatforLearners Learners==>",
          res
        );
        setLearnerZoommerPlatform(res.data);
        setTotal(res.lengthData);
        console.log("hhhhhhhhhhhhhhhhhhhhhhh");
        window.scrollTo(0, 0);
      } else {
        setLoading(false);

        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  const handleSearch2 = async (searchValue, pageNum, rowSize) => {
    setLoading(true);
    // if (searchValue?.length === 0) {
    //   getAllLearners();
    // }
    //.log("My handling of search ========>", rowSize);
    if (searchValue) {
      setPageNo2(1);
      setRowsPerPage2(10);
      setSchoolSelected("");
      setSchoolLocationSelected("");
    }
    const response = await fetch(
      `${FETCH_URL}/api/learner/getPaginatedLearner?pagination=true&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${gradeSelected}&search=${searchValue}&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    setLoading(false);
    if (response.ok) {
      setLearnerData(res.msg);
    } else {
    }
  };
  /////////////////////handle search//////////////////////////////////////////////
  /////////////////////getAllLearners////////////////////////////////////////////
  const getAllLearners = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My response of Learners==>", res);
        setLearnerData(res.msg);
        setTotal2(res.lengthData);
        setLoading(false);

        // history.push("/admin/dashboard");
      } else {
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  const [learnerZoommrtplatform, setLearnerZoommerPlatform] = useState([]);

  const getAllZoomerPlatforLearners = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getPaginatedNormalLearner?page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log(
          "My response of getAllZoomerPlatforLearners Learners==>",
          res
        );
        setLearnerZoommerPlatform(res.data);
        setTotal(res.lengthData);
        setLoading(false);
      } else {
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  /////////////////////getAllLearners////////////////////////////////////////////

  const deleteLearner = async (learnerId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/deleteLearner/${learnerId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        //.log("My response ==>", res);
        getAllLearners();
      } else {
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };
  const [schoolData, setSchoolData] = useState([]);
  const getAllSchool = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getAllSchools?pagination=true&limit=10&page=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setSchoolData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllSchool();
  }, []);

  useEffect(() => {
    getAllZoomerPlatforLearners();
    getAllLearners();
    // handleSearch();
    getDistinctSchoolAndLocation(); // for dropdowns
  }, []);
  useEffect(() => {
    getAllLearners();
    setSchoolLocationSelected("");
    setLocationForGrade("");
    setGradeSelected("");
    setSchoolLoc([]);
    setSelectGrade([]);
    setSerachData2("");
    setSchoolSelected(null);
  }, [value == 0]);
  //.log(learnerData, "yo i am am learner");

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <>
      {value == 1 ? (
        <>
          <Grid
            container
            direction="row"
            justify="space-between"
            className="mt-24"
          >
            <Grid item>
              <Typography className="BlueColorTypography fs-22">
                Manage School Learner
              </Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Input
                  disableUnderline
                  className="input hgt-33 mt-6"
                  onChange={(e) => {
                    handleSearch2(e.target.value);
                    setSerachData2(e.target.value);
                  }}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
                <LearnerAdd getAllLearners={getAllLearners} />
                <UploadExcel
                  getAllLearners={getAllLearners}
                  schoolData={schoolData}
                />{" "}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <FormControl variant="standard" className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={schoolSelected ? schoolSelected : 0}
                  // onChange={locChange}
                  label="Select School"
                  color="white"
                  disableUnderline
                  defaultValue={0}
                  onChange={(e) => {
                    //.log("event target =======>", e.target.value);
                    setLocationForGrade("");
                    setGradeSelected("");
                    setSchoolLoc([]);
                    setSelectGrade([]);
                    getDistinctSchoolAndLocation(e.target.value);

                    if (e.target.value != 0) setSchoolSelected(e.target.value);
                    getLearnersBySchool(e.target.value);
                    if (e.target.value == 0) {
                      getAllLearners();
                      setSchoolSelected(null);
                      setSchoolLocationSelected("");
                      setLocationForGrade("");
                      setGradeSelected("");
                      setSchoolLoc([]);
                      setSelectGrade([]);
                      setSerachData2("");
                    }
                  }}
                >
                  <MenuItem value={0} className="Selectmenustyle">
                    All School
                  </MenuItem>
                  {schoolNames &&
                    schoolNames.length > 0 &&
                    schoolNames.map((school) => (
                      <MenuItem value={school._id} className="Selectmenustyle">
                        {school._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={schoolLocationSelected ? schoolLocationSelected : 0}
                  onChange={(e) => {
                    setLocationForGrade("");
                    setGradeSelected("");
                    setSelectGrade([]);
                    setSchoolLocationSelected(e.target.value);
                    getGrades(e.target.value);
                    getLearnersByLocation(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                  defaultValue={0}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Select a location
                  </MenuItem>
                  {schoolLoc &&
                    schoolLoc.length > 0 &&
                    schoolLoc.map((location) => (
                      <MenuItem
                        value={location._id}
                        className="Selectmenustyle"
                      >
                        {location._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
              <FormControl variant="standard" className="MainPageFormControl ">
                <Select
                  className=" Selectdropstyle"
                  value={gradeSelected ? gradeSelected : 0}
                  label=" Select Grade"
                  color="white"
                  onChange={(e) => {
                    getLearnersByGrade(e.target.value);
                    setGradeSelected(e.target.value);
                  }}
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Grade
                  </MenuItem>
                  {selectGrade &&
                    selectGrade.length > 0 &&
                    selectGrade.map((a) => (
                      <MenuItem className="Selectmenustyle" value={a._id}>
                        {a._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography className="BlueColorTypography fs-22 mt-24 ">
          Manage Zoommer Platform Learner
        </Typography>
      )}
      <LoaderDialog loading={loading} />

      <Box className="mt-16">
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="TabsCustom-Style"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Zoommer Platform"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="School"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="width100">
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow>
                <TableCell className="Head">Learner's Name </TableCell>
                <TableCell className="Head">Email </TableCell>
                <TableCell className="Head">Mobile Number </TableCell>
                {/* <TableCell className="Head"> Total PP</TableCell> */}
                <TableCell className="Head"> Date of joining</TableCell>
                <TableCell className="Head"> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {learnerZoommrtplatform &&
                learnerZoommrtplatform?.length > 0 &&
                learnerZoommrtplatform?.map((learner) => (
                  <TableRow>
                    <TableCell className="Col">
                      <Link>
                        <LearnerProfile
                          learner={learner}
                          normalLearner={true}
                        />
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      {learner?.contactDetail?.learnerEmail
                        ? learner?.contactDetail?.learnerEmail
                        : "N/A"}
                    </TableCell>
                    <TableCell className="Col">
                      {learner?.authUserId?.phoneNo
                        ? learner?.authUserId?.phoneNo
                        : "N/A"}
                    </TableCell>
                    {/* <TableCell className="Col">
                      {learner.contactDetail.location}
                    </TableCell> */}
                    <TableCell className="Col">
                      {learner?.authUserId?.createdOn?.split("T")[0]}
                    </TableCell>{" "}
                    <TableCell className="Action">
                      <AssignAssement
                        getAllLearners={getAllLearners}
                        learnerId={learner._id}
                        notificationToken={learner.notificationToken}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {learnerZoommrtplatform?.length == 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Learner Found!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {learnerZoommrtplatform?.length == 0 ? null : (
          <div className="paginationStyle">
            <Pagination
              onChange={(value, value2) => {
                handleSearch(value, value2);
                setPageNo(value);
              }}
              pageSize={rowsPerPage}
              total={total}
              current={pageNo}
              showSizeChanger
              showQuickJumper
              onShowSizeChange={onShowSizeChange}
              itemRender={itemRender}
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1} className="width100">
        <div className="ResponsiveTable">
          <Table className="Tableroot" ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell className="Head"> UID </TableCell>
                <TableCell className="Head">Learner's Name </TableCell>
                <TableCell className="Head">password </TableCell>
                <TableCell className="Head">School Name </TableCell>
                <TableCell className="Head"> Location</TableCell>
                <TableCell className="Head"> Grade</TableCell>
                {/* <TableCell className="Head"> Division </TableCell> */}
                <TableCell className="Head"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {learnerData &&
                learnerData.length > 0 &&
                learnerData.map((learner) => (
                  <TableRow>
                    <TableCell className="Col">
                      {learner.learnerDetail?.regNo}
                    </TableCell>
                    <TableCell className="Col">
                      <Link>
                        <LearnerProfile
                          learner={learner}
                          normalLearner={false}
                        />
                      </Link>
                    </TableCell>
                    <TableCell className="Col">{learner.password}</TableCell>
                    <TableCell className="Col">
                      {learner.academicDetails.schoolName}
                    </TableCell>
                    <TableCell className="Col">
                      {learner.academicDetails.location}
                    </TableCell>
                    <TableCell className="Col">
                      {learner.academicDetails.grade}
                    </TableCell>
                    {/* <TableCell className="Col">
                    {" "}
                    {learner.academicDetails.division}
                  </TableCell> */}
                    <TableCell className="Action">
                      <LearnerEdit
                        learner={learner}
                        getAllLearners={getAllLearners}
                        learnerProp={learnerProp}
                      />
                      {/* <LearnerSwicth
                        learnerProp={learnerProp}
                        access={learner.access}
                        getAllLearners={getAllLearners}
                        learnerId={learner._id}
                      /> */}
                      <LLessonPlan
                        learnerId={{
                          learnerId: learner._id,
                          notificationToken: learner.notificationToken,
                          gradeId: learner.assignedGrade,
                          schoolId: learner.assignedSchool,
                          schoolName: learner.academicDetails.schoolName,
                          location: learner.academicDetails.location,
                        }}
                      />
                      {/* <IconButton className="ActionButtonSize">
                      <LearnerDelete
                        learnerId={learner._id}
                        deleteLearner={deleteLearner}
                      />
                    </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {learnerData?.length == 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Learner Found!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {serachData2 || learnerData?.length == 0 ? null : (
          <div className="paginationStyle">
            <Pagination
              onChange={(value, value2) => {
                handleSearch2("", value, value2);
                setPageNo2(value);
              }}
              pageSize={rowsPerPage2}
              total={total2}
              current={pageNo2}
              showSizeChanger
              showQuickJumper
              onShowSizeChange={onShowSizeChange2}
              itemRender={itemRender2}
            />
          </div>
        )}
      </TabPanel>
    </>
  );
}

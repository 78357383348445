import React, { useState, useEffect } from "react";

// @material-ui/core components
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
// React Icons'
import { GoSearch } from "react-icons/go";
// core components
import LearnerProfile from "./PLearnerProfile/LearnerMainTab";
import AddLeaner from "./PAddLearner/LearnerAdd";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function ExtendedTables() {
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [learnerData, setLearnerData] = useState(null);
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [gradeData, setGradeData] = useState(null);
  const [gradeValue, setGradeValue] = useState(null);
  const [divisionData, setDivisionData] = useState(null);
  const [divisionValue, setDivisionValue] = useState(null);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  //get all grades
  const getAllGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setGradeData(res.msg);
      } else {
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  //get all divisions
  const getAllGradedivision = async (grade) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${grade}&page=${
          page ? page : 1
        }&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setDivisionData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  const getPaginatedLearner = async (pageNum, rowSize) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedLearner?pagination=true&schoolId=${
          schoolDetail?.schoolId
        }&gradeId=${gradeValue}&division=${divisionValue}&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setLearnerData(res.msg);
        setTotal(res.length);
        console.log("My learner Divison response ==>", res);
      } else {
        loadingHandler(false);
        console.log("Error while getting divison", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Error while getting divison =======>", error);
    }
  };
  //handle search
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    loadingHandler(true);
    if (searchValue.length === 0) {
      getPaginatedLearner();
    }
    console.log("My handling of search ========>", rowSize);
    const response = await fetch(
      `${FETCH_URL}/api/learner/searchLearner/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      loadingHandler(false);
      setLearnerData(res.msg);
      setTotal(res?.length);
    } else {
      loadingHandler(false);
      console.log("Error while searching single school", res.err);
    }
  };
  useEffect(() => {
    getPaginatedLearner();
    setPage(1);
  }, [gradeValue, divisionValue]);
  useEffect(() => {
    getAllGrade();
  }, []);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">
          {" "}
          {`${schoolDetail?.schoolName} ${schoolDetail?.location} `}
        </Typography>
        <div className="divHeadSearch">
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setGradeValue("");
                    setDivisionValue("");
                  }}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>{" "}
          <AddLeaner getAllLearners={getPaginatedLearner} />
        </div>
      </Toolbar>
      <Typography className="GreyColorTypography Title2Typo">
        List of Students
      </Typography>
      <Grid container className="MT-15px">
        <Grid container item md={2} sm={2} xs={2} lg={2}>
          <Grid item md={9} className="MainPageFCIG">
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={gradeValue ? gradeValue : 0}
                defaultValue={0}
                disableUnderline
                onChange={(e) => {
                  getAllGradedivision(e.target.value);
                  setGradeValue(e.target.value);
                  setDivisionValue(null);
                }}
                label="Select grade"
              >
                <MenuItem value={0} className="Selectmenustyle" disabled>
                  Select grade
                </MenuItem>
                {gradeData?.map((a, i) => (
                  <MenuItem key={i} className="Eventmenustyle" value={a?._id}>
                    {a?.grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item md={6} sm={6} xs={6} lg={4}>
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={divisionValue ? divisionValue : 0}
                defaultValue={0}
                // onChange={locChange}
                onChange={(e) => {
                  setDivisionValue(e.target.value);
                }}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0} disabled>
                  Select Division
                </MenuItem>
                {divisionData?.map((a, i) => (
                  <MenuItem className="Eventmenustyle" value={a._id}>
                    {a.division}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={3} sm={3} md={3}></Grid>
        {/* <Grid
          container
          item
          md={3}
          sm={3}
          xs={3}
          lg={3}
          style={{ justifyContent: "flex-end" }}
        >
          <Typography className="GreyColorTypography  MR-15px">
            Sort by
          </Typography>
          <Grid item>
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={school ? school : 0}
                onChange={handleChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  UID
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  23/12/2021
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  24/12/2021
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  29/12/2021
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid> */}
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow>
              <TableCell className="Head"> UID </TableCell>
              <TableCell className="Head">Learner's Name </TableCell>
              <TableCell className="Head">School Name </TableCell>
              <TableCell className="Head"> Location</TableCell>{" "}
              <TableCell className="Head"> Grade</TableCell>{" "}
              <TableCell className="Head"> Division </TableCell>
              {/* <TableCell className="Head"> Attendnace</TableCell> */}
              <TableCell className="Head"> Result</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerData &&
              learnerData.length > 0 &&
              learnerData.map((learner) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    <Link> {learner.learnerDetail.regNo} </Link>
                  </TableCell>
                  <TableCell className="Col700WAB">
                    <LearnerProfile
                      learner={learner}
                      states={{ learnerData }}
                    />
                    <Link> </Link>
                  </TableCell>

                  <TableCell className="Col700WAB">
                    {learner.academicDetails.schoolName}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {learner.academicDetails.location}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {learner.academicDetails.grade}
                  </TableCell>
                  <TableCell className="Col700WAB"> A </TableCell>

                  <TableCell className="Col700WAB">
                    <Link> 88%</Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {learnerData?.length == 0 && (
        <Grid
          container
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography>
            <Typography className="width100 DarkBlackColorTypo">
              No Learner Found!
            </Typography>
          </Typography>
        </Grid>
      )}
      {learnerData?.length > 0 && (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, pageSize) => {
              getPaginatedLearner(value, pageSize);
              setPage(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
//Icons
import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
//core component
import ContactTab from "./ContactTab";
import AcademicTab from "./AcademicTab";
import DocumentTab from "./DocumentTAb";
import LearnerTab from "./LearnerTab";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditDialog({ learner, getAllLearners }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [datevalue, setDateValue] = React.useState(null);
  const { userToken } = useLogin();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DialogClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    console.log(open);
    setOpen(false);
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  function TabNext3() {
    setValue(3);
  }
  //For CreateInput
  const [fullName, setFullName] = useState(null);
  const [dob, setDob] = useState(null);
  const [regNo, setRegNo] = useState(null);
  const [enrollmentDate, setEnrollmentDate] = useState(null);
  const [fathersName, setFathersName] = useState(null);
  const [mothersName, setMothersName] = useState(null);
  const [learnerEmail, setLearnerEmail] = useState(null);
  const [learnerPhoneNo, setLearnerPhoneNo] = useState(null);
  const [learnerLocation, setLearnerLocation] = useState(null);
  const [learnerAddress, setLearnerAddress] = useState(null);
  const [learnerZipCode, setLearnerZipCode] = useState(null);
  const [learnerCity, setLearnerCity] = useState(null);
  const [learnerState, setLearnerState] = useState(null);
  const [learnerCountry, setlearnerCountry] = useState(null);
  const [guardianFullName, setGuardianFullName] = useState(null);
  const [guardianLocation, setGuardianLocation] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState(null);
  const [guardingPhoneNo, setGuardingPhoneNo] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [grade, setGrade] = useState(null);
  const [location, setLocation] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [firstTermStartDate, setFirstTermStartDate] = useState(null);
  const [secondTermStartDate, setsecondTermStartDate] = useState(null);
  const [firstTermEndDate, setfirstTermEndDate] = useState(null);
  const [secondTermEndDate, setsecondTermEndDate] = useState(null);
  const [apiGrades, setApiGrades] = useState(null);
  //Api Calling
  const submitClick = async (e) => {
    // For Editing
    e.preventDefault();

    const response = await fetch(
      `${FETCH_URL}/api/learner/editLearner/${learner._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          fullName: fullName ? fullName : learner.learnerDetail.fullName,
          dob: dob ? dob : learner.learnerDetail.dob,
          enrollmentDate: enrollmentDate
            ? enrollmentDate
            : learner.learnerDetail.enrollmentDate,
          fathersName: fathersName
            ? fathersName
            : learner.learnerDetail.fathersName,
          mothersName: mothersName
            ? mothersName
            : learner.learnerDetail.mothersName,
          learnerEmail: learnerEmail
            ? learnerEmail
            : learner.contactDetail.learnerEmail,
          learnerPhoneNo: learnerPhoneNo
            ? learnerPhoneNo
            : learner.contactDetail.learnerPhoneNo,
          learnerLocation: learnerLocation
            ? learnerLocation
            : learner.contactDetail.learnerLocation,
          learnerAddress: learnerAddress
            ? learnerAddress
            : learner.contactDetail.learnerAddress,
          learnerZipCode: learnerZipCode
            ? learnerZipCode
            : learner.contactDetail.learnerZipCode,
          learnerCity: learnerCity
            ? learnerCity
            : learner.contactDetail.learnerCity,
          learnerState: learnerState
            ? learnerState
            : learner.contactDetail.learnerState,
          learnerCountry: learnerCountry
            ? learnerCountry
            : learner.contactDetail.learnerCountry,
          guardianFullName: guardianFullName
            ? guardianFullName
            : learner.contactDetail.guardianFullName,
          guardianLocation: guardianLocation
            ? guardianLocation
            : learner.contactDetail.guardianLocation,
          guardianEmail: guardianEmail
            ? guardianEmail
            : learner.contactDetail.guardianEmail,
          guardingPhoneNo: guardingPhoneNo
            ? guardingPhoneNo
            : learner.contactDetail.guardianPhoneNo,
          schoolName: schoolName
            ? schoolName
            : learner.academicDetails.schoolName,
          grade: grade ? grade : learner.academicDetails.grade,
          location: location ? location : learner.academicDetails.location,
          rollNumber: rollNumber
            ? rollNumber
            : learner.academicDetails.rollNumber,
          firstTermStartDate: firstTermStartDate
            ? firstTermStartDate
            : learner.academicDetails.firstTermStartDate,
          secondTermStartDate: secondTermStartDate
            ? secondTermStartDate
            : learner.academicDetails.secondTermStartDate,
          firstTermEndDate: firstTermEndDate
            ? firstTermEndDate
            : learner.academicDetails.firstTermEndDate,
          secondTermEndDate: secondTermEndDate
            ? secondTermEndDate
            : learner.academicDetails.secondTermEndDate,
        }),
      }
    );

    const res = await response.json();

    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      getAllLearners();
      setOpen(false);
    } else {
      console.log("Error from learnerEdited =====>", res);
    }
  };

  useEffect(() => {
    getGradesAsPerSchoolLocation();
  }, []);

  const getGradesAsPerSchoolLocation = async () => {
    try {
      console.log("Fetching Schools for grades from EditDialog.js ==>");
      const response = await fetch(
        `${FETCH_URL}/api/school/getLtdSchool?schoolName=${learner.academicDetails.schoolName}&location=${learner.academicDetails.location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();

      if (response.ok) {
        console.log("School Grades Edit Dialog resp ====>", res);
        setApiGrades(res.msg);
      } else {
        console.log("Error from getGradesAsPerSchoolLocation =====>", res);
      }
    } catch (error) {
      console.log("Error from fetch school", error);
    }
  };
  return (
    <div>
      <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Learner Detail"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Contact Detail"
                {...a11yProps(1)}
              />
              <Tab
                className="TabStyle"
                label="Academic Detail"
                {...a11yProps(2)}
              />
              <Tab className="TabStyle" label="Documents" {...a11yProps(2)} />
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>{" "}
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <LearnerTab
              learner={learner}
              states={{
                setFullName,
                setDob,
                setEnrollmentDate,
                setFathersName,
                setMothersName,
              }}
              Tabnext={TabNext}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ContactTab
              learner={learner}
              states={{
                setLearnerEmail,
                setLearnerPhoneNo,
                setLearnerLocation,
                setLearnerAddress,
                setLearnerZipCode,
                setLearnerCity,
                setLearnerState,
                setlearnerCountry,
                setGuardianFullName,
                setGuardianLocation,
                setGuardianEmail,
                setGuardingPhoneNo,
              }}
              Tabnext2={TabNext2}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AcademicTab
              learner={learner}
              states={{
                setSchoolName,
                schoolName,
                setGrade,
                grade,
                setLocation,
                location,
                setRollNumber,
                rollNumber,
                setFirstTermStartDate,
                firstTermStartDate,
                setsecondTermStartDate,
                secondTermEndDate,
                setfirstTermEndDate,
                firstTermEndDate,
                setsecondTermEndDate,
                secondTermStartDate,
                setApiGrades,
                apiGrades,
              }}
              Tabnext3={TabNext3}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DocumentTab submitClick={submitClick} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component
import { FETCH_URL } from "fetchIp";
// import DeleteDialog from "../../../TrainerActionDialog/TrainerDeleteDialog";
// import SwtichDialog from "../../../TrainerActionDialog/TrainerSwitch";
export default function TAOngoingCourseTab({ newArrayonGoing }) {
  //   const { TrainerId } = props;
  // console.log("courseOnGoing", courseOnGoing);

  // const [courseData, ] = useState(courseOnGoing);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [courseData, setCourseData] = useState([]);

  const [access, setAccess] = useState(1);
  console.log("courseData", courseData);

  const GetAssignedOnGoingCourses = async (newArrayonGoing) => {
    try {
      console.log("newArrayonGoing2", newArrayonGoing);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getCoursesByCourseids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            CourseIds: newArrayonGoing,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setCourseData(res.msg);
        console.log("Get AssignedOnGoingLessonPlan Responce   ", res.msg);
      } else {
        //.log("Check GetAssignedCourseOngoing Error ", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
      console.log("newArrayonGoing2", newArrayonGoing);
    }
  };
  useEffect(() => {
    GetAssignedOnGoingCourses(newArrayonGoing);
  }, []);
  // const loadUsers = async () => {
  //   const res = await axios.get(
  //     "https://jsonplaceholder.typicode.com/comments "
  //   );
  //   setPosts(res.data);
  //   setTotal(res.data.length);
  // };

  // useEffect(() => {
  //   loadUsers();
  // }, []);

  //   const getSingleTrainer = async () => {
  //     try {
  //       const response = await fetch(
  //         `${FETCH_URL}/api/learner/getSingleTrainer/${TrainerId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             // Authorization: `Bearer ${userToken}`,
  //           },
  //         }
  //       );
  //       const res = await response.json();
  //       if (response.ok) {
  //         let CourseIds = res.msg?.assignedCourses.map((obj) => ({
  //           courseId: obj?.courseId,
  //         }));
  //         FetchCourse(CourseIds);
  //       } else {
  //         console.log("Error while fetching single school", res.err);
  //       }
  //     } catch (error) {
  //       console.log("Catch block ====>", error);
  //     }
  //   };

  //   const FetchCourse = async (CourseIds) => {
  //     try {
  //       const response = await fetch(
  //         `${FETCH_URL}/api/lessonplan/course/getCoursesByCourseids`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //           },
  //           body: JSON.stringify({
  //             CourseIds,
  //           }),
  //         }
  //       );
  //       const res = await response.json();
  //       if (response.ok) {
  //         setCourseData(res.msg);

  //         // //.log("Get GetAssignedCourse Responce   ", res.msg);
  //       } else {
  //         //.log("Check AssignedCourse Error ", res.err);
  //       }
  //     } catch (error) {
  //       //.error("Catch block ====>", error);
  //     }
  //   };
  //   useEffect(() => {
  //     getSingleTrainer();
  //   }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead"> Subject </TableCell>
                <TableCell className="ColHead"> Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {courseData &&
                courseData?.length > 0 &&
                courseData?.map((data) => (
                  <TableRow>
                    <TableCell className="Col">
                      {" "}
                      <Link>{data.courseUid}</Link>
                    </TableCell>
                    <TableCell className="Col">{data.courseTitle}</TableCell>
                    <TableCell className="Col ActionButtonSize">
                      <Typography className="GreyColorTypography500 overflow-style">
                        {data.courseDesc}
                      </Typography>
                    </TableCell>

                    {/* <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <SwtichDialog accessCheck={access} />
                    </IconButton>
                    <DeleteDialog />
                  </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {courseData?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Course Assign!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {/* <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div> */}
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  Tab,
  Tabs,
  Radio,
  RadioGroup,
  Box,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  FormControlLabel,
  FormControl,
  Tooltip,
} from "@material-ui/core";
//React Icpns
import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

//core Component
import SkillAssesmentDetails from "./ActivityAddTab/AssesSCEdit/SkillAssesmentDetails";
import SkillRubrics from "./ActivityAddTab/AssesSCEdit/SkillRubrics";
import SkillChoose from "./ActivityAddTab/AssesSCEdit/SkillChoose";
import ActivityChoose from "./ActivityAddTab/AssesACEdit/ActivityChoose";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssesmentEditDialog({
  assesmnet,
  getAllAssesmentLearner,
}) {
  const classes = useStyles();
  const [radiovalue, setRadioValue] = React.useState("Skill");
  // console.log(assesmnet, "assesmnet >>>>>>>>>>>> I am here man>>>>>>>>>>");
  const RadioChange = (event) => {
    console.log("Radio Check", event.target.value);
    setRadioValue(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const EditTabOpen = () => {
    setOpen(true);
  };
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  //For CreateInput
  const { userToken } = useLogin();
  const [uid, setUid] = useState(null);
  const [assessmentName, setAssessmentName] = useState(null);
  const [assessmentDesc, setAssessmentDesc] = useState(null);
  const [assignedXp, setAssignedXp] = useState(null);
  const [level, setLevel] = useState(null);
  const [rubricsQts, setRubricsQts] = useState(null);
  const [skills, setSkills] = useState(null);
  const [activities, setactivities] = useState(null);

  const [Imagevalue, setImageValue] = React.useState("");
  const [file, setFile] = React.useState(assesmnet?.assessmentImage);
  const [image, setImage] = React.useState(null);

  const [locomotorP, setLocomotorP] = useState("");
  const [bodyManagementP, setBodyManagementP] = useState("");
  const [objectControlP, setObjectControlP] = useState("");
  const [originalData, setOriginalData] = React.useState([]);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function storeSkill(e, x, i, dataPercent) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].percent = dataPercent;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }

  console.log(" Check Locomotor Data", originalData);

  const [bmoriginalData, setBMOriginalData] = React.useState([]);
  function BMstoreSkill(e, x, index, dataPercent) {
    let storeArr = [];
    storeArr = bmoriginalData;
    storeArr[index] = x;
    storeArr[index].percent = dataPercent;
    storeArr[index].isChecked = e.target.checked;
    setBMOriginalData(storeArr);
  }
  const [ocoriginalData, setOCOriginalData] = React.useState([]);
  function OCstoreSkill(e, x, index, dataPercent) {
    let storeArr = [];
    storeArr = ocoriginalData;
    storeArr[index] = x;
    storeArr[index].percent = dataPercent;
    storeArr[index].isChecked = e.target.checked;
    setOCOriginalData(storeArr);
  }
  /////////////////////////////////////////////////////////////////
  //Api Calling
  const imageUpload = async (assessmentId) => {
    const formdata = new FormData();
    formdata.set("assessmentId", assessmentId);
    formdata.set("assessmentUid", uid);
    formdata.append("file", image);
    // console.log("I am the form data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/uploadAssessmentImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        getAllAssesmentLearner();
        console.log("I am the response after image upload >>", res);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      console.error("imgError>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", error);
    }
  };

  useEffect(() => {
    let locoSkill = [];
    let bodySkill = [];
    let objSkill = [];
    if (assesmnet.skills != undefined) {
      assesmnet.skills.map((x, i) => {
        if (x.mainSkill == "Locomotor") {
          locoSkill.push(x);
          setLocomotorP(x.percent);
        } else if (x.mainSkill == "Body Management") {
          bodySkill.push(x);
          setBodyManagementP(x.percent);
        } else if (x.mainSkill == "Object Control") {
          objSkill.push(x);
          setObjectControlP(x.percent);
        }
      });

      setOriginalData(locoSkill);
      setBMOriginalData(bodySkill);
      setOCOriginalData(objSkill);
    }
  }, [assesmnet]);

  //Api Calling
  // const submitClick = async (e) => {
  //   console.log("Skill----------");
  //   e.preventDefault();
  // };

  const submitClick = async (e) => {
    e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/editAssessment/${assesmnet._id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            assessmentName: assessmentName
              ? assessmentName
              : assesmnet.assessmentName,
            assessmentDesc: assessmentDesc
              ? assessmentDesc
              : assesmnet.assessmentDesc,
            assignedXp: assignedXp ? assignedXp : assesmnet.assignedXp,
            level: level ? level : assesmnet.level,
          }),
        }
      );
      let res = await responce.json();
      // console.log(res, "res>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.");
      let assessmentId = assesmnet._id;
      if (assessmentId) {
        imageUpload(assessmentId);
        // console.log(
        //   "Response after create check assessment ID >> ",
        //   res.msg2._id
        // );
      }
      if (responce.ok) {
        getAllAssesmentLearner();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        scroll={scroll}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              centered
              onChange={TabChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Assessment Details"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Chose Skill "
                {...a11yProps(1)}
              />
              {/* <Tab className="TabStyle" label="Rubrics" {...a11yProps(2)} /> */}
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SkillAssesmentDetails
              states={{
                uid,
                setUid,
                assessmentName,
                setAssessmentName,
                assessmentDesc,
                setAssessmentDesc,
                assignedXp,
                setAssignedXp,
                level,
                setLevel,
                skills,
                setSkills,
                Imagevalue,
                setImageValue,
                file,
                setFile,
                image,
                setImage,
                imageUpload,
                assesmnet,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SkillChoose
              assesmnet={assesmnet}
              storeSkill={storeSkill}
              BMstoreSkill={BMstoreSkill}
              OCstoreSkill={OCstoreSkill}
              locomotorP={locomotorP}
              setLocomotorP={setLocomotorP}
              bodyManagementP={bodyManagementP}
              setBodyManagementP={setBodyManagementP}
              objectControlP={objectControlP}
              setObjectControlP={setObjectControlP}
              states={{
                originalData,
                setOriginalData,
                bmoriginalData,
                ocoriginalData,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            className="GreenButton   EquipSaveButton"
            onClick={submitClick}
          >
            Submit
          </Button>
          <Button
            className="GreenButton   EquipSaveButton"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Save" : "Next"}
          </Button>
          <Button
            className=" GreyButton   EquipCancelButton "
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

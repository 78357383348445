import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Pagination } from "antd";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  Input,
  Grid,
  MenuItem,
} from "@material-ui/core";

export default function ExtendedTables({ equipment }) {
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginatedLearner, setPaginatedLearner] = useState([]);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const equipmentId = equipment?._id;

  //////////////////////////////////////////////////////////////////////////////////////

  const getPaginatedLearners = async (equipmentId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedLearner?equipmentId=${equipmentId}&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated learners in equipments==>", res);
        setPaginatedLearner(res?.msg);
      } else {
        console.log("Error while paginated learners", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getPaginatedLearners(equipmentId);
  }, []);

  console.log(paginatedLearner, "paginatedLearnerpaginatedLearner");
  return (
    <>
      <div>
        <div className="ResponsiveTableAL">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="ColHeadALBLue">UID </TableCell>
                <TableCell className="ColHeadALBLue"> Name</TableCell>

                <TableCell className="ColHeadALBLue">Grade </TableCell>
                <TableCell className="ColHeadALBLue"> Allocated On </TableCell>
                <TableCell className="ColHeadALBLue"> Quantity </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedLearner?.map((lDeatails) => (
                <TableRow>
                  <TableCell className="Col700WAB">
                    {lDeatails?.learnerDetail?.regNo}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {lDeatails?.learnerDetail?.fullName}
                  </TableCell>
                  <TableCell className="Col700WAB">
                    {lDeatails?.academicDetails?.grade}{" "}
                  </TableCell>{" "}
                  <TableCell className="Col700WAB"> 28/10/2021 </TableCell>
                  <TableCell className="Col700WAB"> 40 </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

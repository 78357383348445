import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Tooltip,
  Grid,
  Input,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//ReactIcons
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { PostAdd } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Publish this learner to marketplace
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  lplearner,
  Learnerdata,
  getLearnerLessonPlan,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const classes = useStyles();
  console.log("Check lplearner in market place ", lplearner);
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const handleClose = () => {
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClick = () => {
    setOpen(true);
  };
  const [uid, setUid] = useState(null);
  const [title, setTitle] = useState(null);
  const AddToMarketPlace = async () => {
    console.log("add", lplearner, Learnerdata);
    console.log("add UID", uid, title);

    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/addLessonPlanToMarket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            lessonPlanId: lplearner ? lplearner?._id : Learnerdata?._id,
            LPUid: uid,
            LPTitle: title,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of AddToMarketPlace==>", res.msg);
        setOpen(false);
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        console.log("Error while AddToMarketPlace", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        setOpen(false);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    setTitle(lplearner?.LPTitle ?? "");
  }, [lplearner]);
  useEffect(() => {
    setTitle(Learnerdata?.LPTitle ?? "");
  }, [Learnerdata]);

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <Tooltip title="Add To Marketplace">
        {lplearner ? (
          <IconButton className="ActionButtonSize">
            <AiOutlineAppstoreAdd
              className="Icon-Color"
              open={open}
              onClick={() => {
                handleClick();
              }}
            />
          </IconButton>
        ) : (
          <Button
            className="GreenButton700 width165px b-r10"
            onClick={() => {
              handleClick();
            }}
          >
            Add to marketplace
          </Button>
        )}
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <form onSubmit={handleSubmit(AddToMarketPlace)}>
          <DialogContent dividers={false} className="TabPanelStyle">
            <Grid container spacing={4} className="DailogLTRBStyle">
              <Grid item md={6} sm={6} xs={6} direction="column">
                <Typography className="InputTitleTypography  GreyColorTypography">
                  Rename Lessonplan UID <span className="ImpColor"> *</span>
                </Typography>
                <Input
                  className="Input1"
                  value={uid}
                  {...register("Uid-ErrorInput", {
                    required: "Learner Uid is required.",
                    onChange: (e) => {
                      setUid(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="Uid-ErrorInput"
                  render={({ message }) => (
                    <Typography className="RedColorTypography">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6} direction="column">
                <Typography className="InputTitleTypography  GreyColorTypography">
                  Learner Title <span className="ImpColor"> *</span>
                </Typography>
                <Input
                  className="Input1"
                  value={title}
                  {...register("title-ErrorInput", {
                    required: "Learner Title is required.",
                    onChange: (e) => {
                      setTitle(e.target.value);
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="title-ErrorInput"
                  render={({ message }) => (
                    <Typography className="RedColorTypography">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              className="GreyButton  SwitchCancelB"
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button className="GreenButton  SwitchOkB" autoFocus type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

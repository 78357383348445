import {
  TABLE_DATA_SUCCESS,
  TABLE_DATA_REQUEST,
  TABLE_DATA_FAILS,
  EDIT_SCHOOL_DETAILS,
  SCHOOL_EDITED,
  SCHOOL_DELETED,
  SCHOOL_IS_DELETED,
} from "../constants/Constants";

const initialState = {
  table: "",
  loading: true,
  bla: "sdasds",
  editMsg: "",
  schoolEdited: false,
  schoolDeleted: false,
};

export const tableDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_DATA_SUCCESS:
      console.log("Action incoming =====>", action);
      return { ...state, loading: true, table: action.payload };
      break;
    case TABLE_DATA_REQUEST:
      return { ...state, loading: false, table: action.payload };
      break;
    case TABLE_DATA_FAILS:
      return { ...state, loading: false, error: action.payload };
      break;
    case EDIT_SCHOOL_DETAILS:
      return {
        ...state,
        editMsg: action.payload,
        schoolEdited: action.isEdited,
      };
      break;
    case SCHOOL_EDITED:
      console.log("School edited case be runnin =====>", action);
      return {
        ...state,
        schoolEdited: action.isEdited,
      };
      break;
    case SCHOOL_DELETED:
      console.log("School edited case be runnin =====>", action);
      return {
        ...state,
        schoolDeleted: action.payload,
      };
      break;
    case SCHOOL_IS_DELETED:
      console.log("School edited case be runnin =====>", action);
      return {
        ...state,
        schoolDeleted: action.payload,
      };
      break;
    default:
      return state;
  }
};

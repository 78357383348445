import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

export default function LgSkill() {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    " Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Locomotor",
        data: [11, 2, 3, 11, 5, 6, 7, 8, 9, 10, 11, 12],
        backgroundColor: "rgb(71, 209, 71)",
      },
      {
        label: "Body Management",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10, 11],
        backgroundColor: "rgb(0, 172, 230)",
      },
      {
        label: "Object Control",
        data: [1, 2, 3, 4, 1, 11, 7, 8, 9, 10, 11, 12],
        backgroundColor: "rgb(255, 153, 0)",
      },
    ],
  };
  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94 colorwhite">
        <Grid
          item
          container
          className="LGSkillsTab"
          md={2}
          sm={2}
          xs={2}
          lg={2}
        >
          <FormControl className="MainPageFormControl">
            <Select
              className=" SkillSelectdropstyle"
              value={school ? school : 0}
              onChange={handleChange}
              label="Monthly"
              color="white"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Monthly
              </MenuItem>

              <MenuItem className="Selectmenustyle" value={10}>
                Yearly
              </MenuItem>
              <MenuItem className="Selectmenustyle" value={20}>
                Last 7 Days{" "}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          container
          md={10}
          sm={10}
          xs={10}
          lg={10}
          style={{ marginLeft: "100px" }}
        >
          <Bar options={options} data={data} />
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

// core components
import EquipmentTab from "./GEquipmentTabs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Equipment({ gradeId }) {
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [value, setValue] = React.useState(0);
  const [equipmentData, setEquipmentData] = React.useState(null);
  const [equipmentKit, setEquipmentKit] = React.useState(null);

  // Get API
  // Get API
  const getAllEquipment = async (gradeId) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getPaginatedEquipment?gradeId=${gradeId}&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        console.log("My response of Trainers==>", res);
        setEquipmentData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  // useEffect(() => {

  // }, []);

  // KITAPI
  const getAllEquipmentKIT = async (gradeId) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/getPaginatedEquipmentsKit?gradeId=${gradeId}&schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        console.log("My response of Trainers==>", res);
        setEquipmentKit(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllEquipmentKIT(gradeId);
    getAllEquipment(gradeId);
  }, []);

  return (
    <>
      {" "}
      <EquipmentTab
        getAllEquipment={getAllEquipment}
        getAllEquipmentKIT={getAllEquipmentKIT}
        states={{ value, setValue, equipmentData, equipmentKit }}
      />{" "}
    </>
  );
}

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";

const OthersTabPanel = ({
  tableData,
  selectDepartmentForApi,
  schoolLocationSelectForApi,
  selectGradeForApi,
  selectDivisionForApi,
}) => {
  return (
    <>
      <Grid container>
        <Grid container item>
          <div className="ResponsiveTableLeaderBoard width_98">
            <Table className="LeaderTableroot border_primary">
              <TableHead>
                <TableRow className="HeadAL">
                  <TableCell className="LeaderColHeadBlack"> Rank </TableCell>
                  <TableCell className="LeaderColHeadBlack">
                    Department
                  </TableCell>
                  {selectDepartmentForApi && (
                    <TableCell className="LeaderColHeadBlack">School</TableCell>
                  )}
                  {schoolLocationSelectForApi && (
                    <TableCell className="LeaderColHeadBlack">Grade</TableCell>
                  )}
                  {selectGradeForApi && (
                    <TableCell className="LeaderColHeadBlack">
                      Division
                    </TableCell>
                  )}
                  <TableCell className="LeaderColHeadBlack">PP </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData?.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell className="LeaderCol700WAB">
                      {data?.rank}
                    </TableCell>
                    <TableCell className="LeaderCol700WAB">
                      {data?.departmentName ? data?.departmentName : "--"}
                    </TableCell>
                    {selectDepartmentForApi && (
                      <TableCell className="LeaderCol700WAB">
                        {data?.schoolName ? data?.schoolName : "--"}
                      </TableCell>
                    )}
                    {schoolLocationSelectForApi && (
                      <TableCell className="LeaderCol700WAB">
                        {data?.gradeName ? data?.gradeName : "--"}
                      </TableCell>
                    )}
                    {selectGradeForApi && (
                      <TableCell className="LeaderColHeadBlack">
                        {data?.divisionName ? data?.divisionName : "--"}
                      </TableCell>
                    )}
                    <TableCell className="LeaderCol700WAB">
                      {" "}
                      {data?.totalPP}{" "}
                    </TableCell>{" "}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OthersTabPanel;

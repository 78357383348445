import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Grid, Typography, Tab, Tabs } from "@material-ui/core";
// core components
import BodyMgtTab from "./GSkillTab/GSBodyMgtTab";
import ObjMgtTab from "./GSkillTab/GSObjMgtTab";
import LocomotorTab from "./GSkillTab/GSLocomotorTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { useLocation } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables() {
  const [value, setValue] = React.useState(0);
  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const locationData = useLocation();

  const gradeId = locationData.state.data._id;
  console.log(" Check gradeId", gradeId);
  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  ////////////////////////////////////////////////////////////////////////////////
  const { userToken } = useLogin();
  const [skillsData, setSkillsData] = React.useState(null);
  const [trainer, setTrainer] = React.useState("");
  const [opne, setOpen] = React.useState(true);
  const [mainSkill, setMainSkill] = React.useState(null);
  const [subSkillName, setSubSkillName] = React.useState(null);
  const [subSkillUid, setSubSkillUid] = React.useState(null);
  const [subSkillDesc, setSubSkillDesc] = React.useState(null);
  const [xpAssigned, setXpAssigned] = React.useState(null);
  const [level, setLevel] = React.useState(null);
  ///////////////////////////////////////////////////////////////////////////

  const getAllSkill = async () => {
    console.log("hiiiiiiiiiiiii");
    try {
      const response = await fetch(`${FETCH_URL}/api/skill/getPaginatedSkill`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setSkillsData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////
  // Search Skill:

  const searchSkill = async (gradeId) => {
    try {
      let mainSkill;
      switch (value) {
        case 0:
          mainSkill = "Locomotor";
          break;
        case 1:
          mainSkill = "Body Management";
          break;
        case 2:
          mainSkill = "Object Control";
          break;
        default:
          console.error("Invalid Value");
      }
      const response = await fetch(
        `${FETCH_URL}/api/skill/getPaginatedSkill?mainSkill=${mainSkill}&gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Skills in paginated skills==>", res);
        setSkillsData(res.msg);
      } else {
        setSkillsData([]);
        console.log("Error while fetching Skills", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log(skillsData, "skilllsData>>>>>>>>>>>>>>>>>>>>>>>>......");
  React.useEffect(() => {
    searchSkill(gradeId);
  }, [value]);

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Locomotor"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Body Management"
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Object Control"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Grid container>
        <Grid container item className="   DivisionStyleGridSkill  ">
          <Grid container item className="DiviStyle ">
            <Typography></Typography>

            {/* <AddSkillDialog /> */}
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <LocomotorTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            value,
            setValue,
          }}
          searchSkill={searchSkill}
          gradeId={gradeId}
          getAllSkill={getAllSkill}
          skillsData={skillsData}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BodyMgtTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            value,
            setValue,
          }}
          searchSkill={searchSkill}
          gradeId={gradeId}
          getAllSkill={getAllSkill}
          skillsData={skillsData}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ObjMgtTab
          states={{
            mainSkill,
            setMainSkill,
            subSkillName,
            setSubSkillName,
            subSkillUid,
            setSubSkillUid,
            subSkillDesc,
            setSubSkillDesc,
            xpAssigned,
            setXpAssigned,
            level,
            setLevel,
            value,
            setValue,
          }}
          searchSkill={searchSkill}
          gradeId={gradeId}
          getAllSkill={getAllSkill}
          skillsData={skillsData}
        />
      </TabPanel>
    </>
  );
}

import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { FcFolder } from "react-icons/fc";
import { Link } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import DeletePubFolder from "./ActiionButton/DeletePubFolder";
export default function Publieshed({ setLoading }) {
  const { userToken } = useLogin();

  const [folders, setFolders] = React.useState([]);

  React.useEffect(() => {
    GetFolder();
  }, []);
  const GetFolder = async (pageNum, rowSize) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getPaginatedFolders?isPublished=true&pagination=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);
        setFolders(res.msg);
        console.log("My Folder response from searching ==>", res.msg);
      } else {
        setLoading(false);
        console.log("Error while searching single school", res.err);
      }
    } catch (error) {}
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="left"
        className=" FolderContainer"
      >
        {folders &&
          folders?.length > 0 &&
          folders?.map((folder, index) => (
            <>
              <Grid item className="FolderGridItemPublished">
                <Typography
                  align="right"
                  style={{ marginRight: "-15px", marginTop: "-10px" }}
                >
                  <DeletePubFolder
                    folderId={folder._id}
                    GetFolder={GetFolder}
                  />
                </Typography>
                <Link
                  to={{
                    pathname: `/admin/publishedpage/${folder._id}`,
                    state: { foldersData: folder },
                  }}
                  // data={{ getFolders: getFolders }}
                >
                  <div className="Icon4">
                    <FcFolder fontSize={70} />
                    <Tooltip
                      title={folder?.folderName}
                      aria-label={folder?.folderName}
                    >
                      <Typography
                        align="center"
                        className="GreyColorTypography folderPublishedstyle   ml-5px"
                      >
                        {folder?.folderName}
                      </Typography>
                    </Tooltip>
                  </div>
                </Link>
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//core Module
import SubSkills from "./SubSkillsTab";
import RubricsTab from "./RubricsTab";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {/* <Typography className="DialogTitleTypo">Skill Add</Typography> */}
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  /////////////////////////////////////////////

  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
    },
    skillsData,
    searchSkill,
  } = props;
  ///////////////////////////////////////////////
  const CreateSkills = async (e) => {
    console.log(rubricsQts, "rubricsQts yo thhis is i am");
    e.preventDefault();
    try {
      const responce = await fetch(`${FETCH_URL}/api/skill/createSkill`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          mainSkill,
          subSkillName,
          subSkillUid,
          subSkillDesc,
          xpAssigned,
          level,
          rubricsQts,
        }),
      });

      let res = await responce.json();
      console.log(res, "i am the response");
      if (!responce.ok) {
        console.log(res, "i am the response not ok");
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        return;
      }
      let skillId = res.msg2._id;

      if (skillId) {
        imageUpload(skillId);
        console.log("Response after create >> ", res.msg2._id);
      }
      if (responce.ok) {
        console.log("Mah respo =====>", res);
        searchSkill();

        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
      } else {
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();

  const imageUpload = async (skillId) => {
    const formdata = new FormData();
    formdata.set("skillId", skillId);
    formdata.set("subSkillUid", subSkillUid);
    formdata.append("file", image);
    try {
      const responce = await fetch(`${FETCH_URL}/api/skill/uploadSkillImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const [scroll, setScroll] = React.useState("paper");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValue(0);
    setMainSkill("");
    setSubSkillName("");
    setSubSkillUid("");
    setSubSkillDesc("");
    setFile();
    setRubricsQts([
      {
        quesNo: "",
        quesDesc: "",
        quesMarks: "",
        quesType: "",
        minValue: "",
      },
    ]);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={handleClickOpen} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Sub Skill Details"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Rubrics" {...a11yProps(1)} />
          </Tabs>{" "}
          <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SubSkills
              states={{
                mainSkill,
                setMainSkill,
                subSkillName,
                setSubSkillName,
                subSkillUid,
                setSubSkillUid,
                subSkillDesc,
                setSubSkillDesc,
                xpAssigned,
                setXpAssigned,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
                image,
                setImage,
                file,
                setFile,
              }}
              imageUpload={imageUpload}
              TabNext={TabNext}
              CreateSkills={CreateSkills}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RubricsTab
              states={{
                mainSkill,
                setMainSkill,
                subSkillName,
                setSubSkillName,
                subSkillUid,
                setSubSkillUid,
                subSkillDesc,
                setSubSkillDesc,
                xpAssigned,
                setXpAssigned,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
              }}
              CreateSkills={CreateSkills}
              skillsData={skillsData}
            />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

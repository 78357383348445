import * as React from "react";

import {
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  Input,
  TextareaAutosize,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

export default function ExtendTab(props) {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const {
    states: {
      fullName,
      setFullName,
      trainerEmail,
      setTrainerEmail,
      trainerPhoneNo,
      setTrainerPhoneNo,
      trainerLocation,
      setTrainerLocation,
      trainerAddress,
      setTrainerAddress,
      trainerZipCode,
      setTrainerZipCode,
      trainerCity,
      setTrainerCity,
      trainerState,
      setTrainerState,
      trainerCountry,
      setTrainerCountry,
      guardianFullName,
      setGuardianFullName,
      guardianLocation,
      setGuardianLocation,
      guardianEmail,
      setGuardianEmail,
      guardingPhoneNo,
      setGuardingPhoneNo,
    },
    TabNext2,
    submitClick,
  } = props;

  const [postalData, setPostalData] = React.useState([]);

  const [postCodeMsg, setPostCodeMsg] = React.useState("");

  const searchByPostalCode = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    console.log(response, "response");
    if (response.ok) {
      const Postaldata = res;
      console.log(res, "resres");
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setPostCodeMsg("please enter a valid post code!");
        setTrainerCity("");
        setTrainerState("");
        setTrainerCountry("");
        return;
      }
      setPostCodeMsg("");
      setPostalData(Postaldata);
      setTrainerCity(Postaldata?.map((a) => a?.PostOffice[0].District)[0]);
      setTrainerState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setTrainerCountry(Postaldata?.map((a) => a?.PostOffice[0].Country)[0]);
    }
  };

  console.log(postalData, "postalData>>>>>>>>>>>");
  React.useEffect(() => {
    if (String(trainerZipCode).length === 6) {
      searchByPostalCode(trainerZipCode);
    } else {
      console.log("iiiiiiiiiiiiiiiiiii");
    }
    if (String(trainerZipCode).length < 6) {
      setTrainerCity("");
      setTrainerState("");
      setTrainerCountry("");
    }
  }, [trainerZipCode]);

  return (
    <form>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6}>
          <Typography className="BlueColorTypography400" align="center">
            Trainer Contact Detail
          </Typography>
          <Grid item container direction="column" style={{ marginLeft: "3%" }}>
            <Typography className="InputTitleTypography GreyColorTypography">
              Email ID <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={trainerEmail}
                onChange={(e) => setTrainerEmail(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Mobile Number <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="number"
                value={trainerPhoneNo}
                onChange={(e) => setTrainerPhoneNo(e.target.value.slice(0, 10))}
                onKeyDown={handleEnter}
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location <span style={{ color: "red" }}> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={trainerLocation}
                onChange={(e) => setTrainerLocation(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Address <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <TextareaAutosize
                className="ContactTextArea"
                aria-label="minimum height"
                value={trainerAddress}
                onChange={(e) => setTrainerAddress(e.target.value)}
                onKeyDown={handleEnter}
              />
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              ZipCode
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="number"
                value={trainerZipCode}
                onChange={(e) => setTrainerZipCode(e.target.value.slice(0, 6))}
                onKeyDown={handleEnter}
              ></Input>{" "}
              {postCodeMsg.length > 0 && (
                <Typography className="warningTypography">
                  {postCodeMsg}
                </Typography>
              )}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              City
              {/* <span style={{ color: "red" }}> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={trainerCity}
                onChange={(e) => setTrainerCity(e.target.value)}
                onKeyDown={handleEnter}
                disabled
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              State
              {/* <span style={{ color: "red" }}> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={trainerState}
                onKeyDown={handleEnter}
                onChange={(e) => setTrainerState(e.target.value)}
                disabled
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Country
              {/* <span style={{ color: "red" }}> *</span> */}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={trainerCountry}
                onKeyDown={handleEnter}
                onChange={(e) => setTrainerCountry(e.target.value)}
                disabled
              ></Input>{" "}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} sm={6} xs={6} direction="column">
          <Typography className="BlueColorTypography400" align="center">
            Guardian’s Contact Detail
          </Typography>
          <Grid item container direction="column">
            <Typography className="InputTitleTypography GreyColorTypography">
              Full Name <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={guardianFullName}
                onChange={(e) => setGuardianFullName(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Location <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={guardianLocation}
                onChange={(e) => setGuardianLocation(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Email ID <span style={{ color: "red" }}> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                value={guardianEmail}
                onChange={(e) => setGuardianEmail(e.target.value)}
                onKeyDown={handleEnter}
              ></Input>{" "}
            </Grid>
            <Typography className="InputTitleTypography GreyColorTypography">
              Mobile Number <span style={{ color: "red" }}> *</span>
            </Typography>{" "}
            <Grid item container md={10} sm={10} xs={10}>
              <Input
                className="Input1"
                type="number"
                value={guardingPhoneNo}
                onChange={(e) =>
                  setGuardingPhoneNo(e.target.value.slice(0, 10))
                }
                onKeyDown={handleEnter}
              ></Input>{" "}
            </Grid>
            <DialogActions className="">
              {/* <Button
                className="GreenButton700 NextActionBottom"
                autoFocus
                onClick={TabNext2}
              >
                Next
              </Button> */}
              <Button
                type="submit"
                className="SumbitButton"
                onClick={submitClick}
              >
                Submit
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

import React from "react";
import { Typography, Grid, Input, TextareaAutosize } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import paddleImg from "assets/img/paddleImg.jpg";
export default function KitProfile(props) {
  const { kit } = props;
  console.log("Kit Detials ", kit);
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid container item className="PT-10px">
          <Grid item md={3} xs={3} sm={3}>
            <Typography className="GreyColorTypography PL-20px  ">
              {" "}
              Name
            </Typography>{" "}
          </Grid>

          <Grid md={9} xs={9} sm={9} item>
            <Typography className="GreenColorTypography">
              {" "}
              {kit.equipmentKitName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid md={3} xs={3} sm={3} item>
            <Typography className="GreyColorTypography PL-20px">
              Description
            </Typography>{" "}
          </Grid>
          <Grid md={9} xs={9} sm={9} item>
            <Typography className="GreyColorTypography">
              {kit.equipmentKitDesc}
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container item className="PL-20px">
          <Typography className="GreyColorTypography"> Equipments</Typography>{" "}
        </Grid>

        <Grid container item>
          {kit.equipmentNames.map((user, key) => (
            <Grid item className="PL-20px">
              <Chip
                label={user}
                className="WhiteColorTypography GPCEquipChip   "
              />
            </Grid>
          ))}
        </Grid>

        {/* <Grid container item md={12} className="GPCEqupImage ">
          <img style={{ width: "512px" }} src={paddleImg} />
        </Grid> */}
      </Grid>
    </>
  );
}

import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  IconButton,
  Toolbar,
  Button,
  Typography,
  Breadcrumbs,
  Grid,
} from "@material-ui/core";
import SchoolTab from "./SchoolTab";
import DeleteDialog from "../HSchoolActionButton's/HSDeleteDialog";
import SwitchDialog from "../HSchoolActionButton's/HSSwitchDialog";

import { useHistory } from "react-router-dom";

// // material-ui icons
import { IoIosArrowBack } from "react-icons/io";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles((theme) => ({
  Title: {
    color: "#2E3180",
    fontFamily: ["Play", "sans-serif"].join(","),
    fontSize: "19px",
    marginTop: "4px",
    fontWeight: 700,
  },
  Title1: {
    color: "#2E3180",
    fontSize: "17px",
    opacity: "1",
    minWidth: "0px",
    marginTop: "18px",
    textTransform: "none",
    marginBottom: "17.4px",
    padding: "0px",
    fontWeight: 700,
  },
  Title2: {
    color: "#2E3180",
    fontSize: "15px",
    height: "10px",
    opacity: "1",
    marginTop: "14.2px",
    textTransform: "none",
    marginBottom: "17.4px",
    padding: "0px",
    fontWeight: 700,

    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "none",
    },
  },
}));

export default function SchoolDetails(props) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <Grid container>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Button
                onClick={() => history.goBack()}
                disableRipple
                className="Breadcrumbbutton"
              >
                {location?.state?.school?.departmentDetails?.departmentName}
              </Button>
              <div className="Breadcrumb2">School Detail</div>
            </Breadcrumbs>
          </div>
          {/* <Grid item container className="ZoomerStyle">
            <Typography className="GreyColorTypography zoomerTypo ">
              Zoomer Public School,
            </Typography>
            <IconButton className="ActionButtonSize">
              <SwitchDialog accessCheck={access} />
            </IconButton>
            <DeleteDialog />{" "}
          </Grid> */}
        </Grid>
      </div>
      <SchoolTab />
    </>
  );
}

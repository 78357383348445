import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tabs,
  Tab,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
//Icons
import { MdEdit } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import MuiAlert from "@material-ui/lab/Alert";
import { useLogin } from "Context/ExportContext";
//Core Component
import ContactTab from "./ContactTab";
import DocumnetTab from "./DocumentTab";
import TrainerDetails from "./TrainerDetails";
import { FETCH_URL } from "fetchIp";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function EditDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const { userToken } = useLogin();
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const TabOpen = () => {
    setOpen(true);
  };

  const TabClose = () => {
    setOpen(false);
  };
  const [tabvalue, setTabValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function TabNext() {
    setTabValue(1);
  }
  function Tabnext2() {
    setTabValue(2);
  }
  const { trainer, getAllTrainers, gradeId } = props;

  //For CreateInput
  const [fullName, setFullName] = useState(null);
  const [dob, setDob] = useState(null);
  const [regNo, setRegNo] = useState(null);
  const [enrollmentDate, setEnrollmentDate] = useState(null);
  const [fathersName, setFathersName] = useState(null);
  const [mothersName, setMothersName] = useState(null);
  const [trainerEmail, setTrainerEmail] = useState(null);
  const [trainerPhoneNo, setTrainerPhoneNo] = useState(null);
  const [trainerLocation, setTrainerLocation] = useState(null);
  const [trainerAddress, setTrainerAddress] = useState(null);
  const [trainerZipCode, setTrainerZipCode] = useState(null);
  const [trainerCity, setTrainerCity] = useState(null);
  const [trainerState, setTrainerState] = useState(null);
  const [trainerCountry, setTrainerCountry] = useState(null);
  const [guardianFullName, setGuardianFullName] = useState(null);
  const [guardianLocation, setGuardianLocation] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState(null);
  const [guardingPhoneNo, setGuardingPhoneNo] = useState(null);
  const [gender, setGender] = useState(null);
  const [learnerState, setLearnerState] = useState(null);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState(trainer?.trainerDetail?.trainerImg);
  //Api Calling
  const submitClick = async (e) => {
    // For Editing
    e.preventDefault();

    const response = await fetch(
      `${FETCH_URL}/api/learner/editTrainer/${trainer._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          fullName: fullName ? fullName : trainer.trainerDetail.fullName,
          dob: dob ? dob : trainer.trainerDetail.dob,
          enrollmentDate: enrollmentDate
            ? enrollmentDate
            : trainer.trainerDetail.enrollmentDate,
          fathersName: fathersName
            ? fathersName
            : trainer.trainerDetail.fathersName,
          mothersName: mothersName
            ? mothersName
            : trainer.trainerDetail.mothersName,
          gender: gender ? gender : trainer.trainerDetail.gender,
          trainerEmail: trainerEmail
            ? trainerEmail
            : trainer.contactDetail.trainerEmail,
          trainerPhoneNo: trainerPhoneNo
            ? trainerPhoneNo
            : trainer.contactDetail.trainerPhoneNo,
          trainerLocation: trainerLocation
            ? trainerLocation
            : trainer.contactDetail.trainerLocation,
          trainerAddress: trainerAddress
            ? trainerAddress
            : trainer.contactDetail.trainerAddress,
          trainerZipCode: trainerZipCode
            ? trainerZipCode
            : trainer.contactDetail.trainerZipCode,
          trainerCity: trainerCity
            ? trainerCity
            : trainer.contactDetail.trainerCity,
          trainerState: trainerState
            ? learnerState
            : trainer.contactDetail.trainerState,
          trainerCountry: trainerCountry
            ? trainerCountry
            : trainer.contactDetail.trainerCountry,
          guardianFullName: guardianFullName
            ? guardianFullName
            : trainer.contactDetail.guardianFullName,
          guardianLocation: guardianLocation
            ? guardianLocation
            : trainer.contactDetail.guardianLocation,
          guardianEmail: guardianEmail
            ? guardianEmail
            : trainer.contactDetail.guardianEmail,
          guardianPhoneNo: guardingPhoneNo
            ? guardingPhoneNo
            : trainer.contactDetail.guardianPhoneNo,
        }),
      }
    );

    const res = await response.json();
    console.log(res, "miss me");
    let trainerId = res.msg._id;
    if (trainerId) {
      imageUpload(trainerId);
      console.log("Response after create >> ", res.msg._id);
    }
    if (response.ok) {
      console.log("Learner has been successfully edited ====>", res);
      if (gradeId) {
        getAllTrainers(gradeId);
      } else {
        getAllTrainers();
      }

      setSnackOpen(true);
      setSnackMsg(res.msg);
      setOpen(false);
      setTabValue(0);
    } else {
      setSnackerropen(true);
      setSnackErrMsg(res.err);
      console.log("Error from learnerEdited =====>", res);
    }
  };

  ///////////////////////////////////////////////////////////////////////////
  const imageUpload = async (trainerId) => {
    const formdata = new FormData();
    formdata.set("trainerId", trainerId);
    formdata.set("regNo", regNo);
    formdata.append("file", image);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/learner/uploadTrainerImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  /////////////////////////////////////////////////////////////////////

  return (
    <div>
      {" "}
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={3000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={TabOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={TabClose}
        scroll={scroll}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        {" "}
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={tabvalue}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Trainer Detail"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Contact Detail"
                {...a11yProps(1)}
              />
              {/* <Tab className="TabStyle" label="Documents" {...a11yProps(2)} /> */}

              <BootstrapDialogTitle onClose={TabClose}></BootstrapDialogTitle>
            </Tabs>
          </Box>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle ">
          <TabPanel value={tabvalue} index={0}>
            <TrainerDetails
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                gender,
                setGender,
                image,
                setImage,
                file,
                setFile,
              }}
              trainer={trainer}
              TabNext={TabNext}
            />
          </TabPanel>
          <TabPanel value={tabvalue} index={1}>
            <ContactTab
              states={{
                trainerEmail,
                setTrainerEmail,
                trainerPhoneNo,
                setTrainerPhoneNo,
                trainerLocation,
                setTrainerLocation,
                trainerAddress,
                setTrainerAddress,
                trainerZipCode,
                setTrainerZipCode,
                trainerCity,
                setTrainerCity,
                trainerState,
                setTrainerState,
                trainerCountry,
                setTrainerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
              }}
              TabNext2={Tabnext2}
              trainer={trainer}
              submitClick={submitClick}
            />
          </TabPanel>
          <TabPanel value={tabvalue} index={2}>
            <DocumnetTab submitClick={submitClick} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

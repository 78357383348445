import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  DialogContent,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Equipment Details
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const { getAllEquipment } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEquipmentUid("");
    setEquipmentNames("");
    setEquipmentDesc("");
    setImage("");
    setFile("");
  };

  //For CreateInput
  const [equipmentUid, setEquipmentUid] = React.useState(null);
  const [equipmentName, setEquipmentNames] = React.useState(null);
  const [equipmentDesc, setEquipmentDesc] = React.useState(null);

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const submitClick = async (e) => {
    e.preventDefault();

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/equipment/createEquipment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            equipmentUid,
            equipmentName,
            equipmentDesc,
          }),
        }
      );
      let res = await responce.json();
      if (!responce.ok) {
        console.log("Error noy ok =====>", res);

        setSnackerropen(true);
        setSnackErrMsg(res.err);
        return;
      }
      // console.log(responce);
      let equipmentId = res.msg2._id;
      if (equipmentId) {
        imageUpload(equipmentId);
        console.log("Response after create >> ", res.msg2._id);
      }
      if (responce.ok) {
        console.log("Mah respo =====>", res);

        getAllEquipment();
        setSnackOpen(true);
        setSnackMsg(res.msg);

        handleClose();
      } else {
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };

  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();

  const imageUpload = async (equipmentId) => {
    const formdata = new FormData();
    formdata.set("equipmentId", equipmentId);
    formdata.set("equipmentUid", equipmentUid);
    formdata.append("file", image);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/equipment/uploadThumbnail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={handleClickOpen} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle">
            {" "}
            <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
              <Grid
                container
                direction="column"
                item
                style={{ marginLeft: "25px" }}
              >
                <Typography className="SkillsInputTitle  GreyColorTypography">
                  Equipment UID <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    value={equipmentUid}
                    onChange={(e) => setEquipmentUid(e.target.value)}
                  ></Input>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Equipment Name <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    value={equipmentName}
                    onChange={(e) => setEquipmentNames(e.target.value)}
                  ></Input>
                </Grid>
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Equipment Description <span style={{ color: "red" }}> *</span>
                </Typography>{" "}
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <TextareaAutosize
                    className="SkillTextArea"
                    aria-label="minimum height"
                    value={equipmentDesc}
                    onChange={(e) => setEquipmentDesc(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={6} sm={6} xs={6} lg={6} direction="column">
              <Typography className="GreyColorTypography  EquipMeadiaTypo  ">
                Media
              </Typography>
              {/* <Grid item container className="ThumbnilGridStyle">
                <Typography className=" BlueColorTypography AddThumbnailTypography">
                  Add Thumbnail
                </Typography>
                <div className="CCFB">
                  <div>
                    <Button className=" GreenButton CourseCFButton">
                      <IoIosAddCircle className="CFButtonIcon" />
                      Choose File
                    </Button>
                  </div>
                </div>
              </Grid> */}
              <Grid item className="ThumbnilGridStyle">
                <Typography className=" BlueColorTypography AddThumbnailTypography">
                  Add Thumbnail
                </Typography>
                <Typography
                  style={{ marginLeft: "20px", marginBottom: "10px" }}
                >
                  <img
                    src={file}
                    style={{
                      height: "170px",
                      width: "320px",
                      marginTop: "10px",
                    }}
                  />
                </Typography>
                <Grid item className="Thumbnildiv">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="raised-button-file"
                    accept="image/*"
                    onChange={ImageUploader}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      className="GreenButton700 SkillsCFB"
                      variant="raised"
                      component="span"
                    >
                      <IoIosAddCircle className="AddCircleIcon" />
                      Choose File
                    </Button>
                  </label>
                </Grid>
                {/* <Typography className=" BlueColorTypography AddThumbnailTypography">
                Add Thumbnail
              </Typography>

              <div className="Thumbnildiv">
                <div>
                  <Button className="GreenButton700 SkillsCFB">
                    <IoIosAddCircle className="CFButtonIcon" /> Choose File
                  </Button>
                </div>
              </div> */}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="EquipDialogActionBottom">
          <Button
            className="GreenButton700 EquipActionBottom"
            style={{ marginRight: "1.3%" }}
            autoFocus
            onClick={submitClick}
          >
            Save
          </Button>
          <Button
            className="GreyButton700  EquipActionBottom"
            style={{ marginRight: "1%" }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

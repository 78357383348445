import * as React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";

export default function HosOrganisationDetails() {
  const location = useLocation();
  const orgDetails = location.state;
  console.log(orgDetails, "orgDetails>>>>>>>>>>>>>>>>>>>>>>>");
  return (
    <Grid container spacing={3} className="SPContainerStyle">
      <Grid item container lg={5} md={6} xs={8} className=" MT">
        <Grid container item>
          <Grid
            item
            container
            xs={6}
            direction="column"
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="BlueColorTypography SPCardTypo">
                School Name
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Location
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Enrolled on
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                1st Sem Start Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                1st Sem End Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                2nd Sem Start Date
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                2nd Sem End Date
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.organisationDetails?.schoolName}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.organisationDetails?.location}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {
                  orgDetails?.school?.organisationDetails?.enrolledOn?.split(
                    "T"
                  )?.[0]
                }
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {
                  orgDetails?.school?.organisationDetails?.firstTermStartDate?.split(
                    "T"
                  )?.[0]
                }
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {
                  orgDetails?.school?.organisationDetails?.firstTermEndDate?.split(
                    "T"
                  )?.[0]
                }
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {
                  orgDetails?.school?.organisationDetails?.secondTermStartDate?.split(
                    "T"
                  )?.[0]
                }
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {
                  orgDetails?.school?.organisationDetails?.secondTermEndDate?.split(
                    "T"
                  )?.[0]
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container lg={4} md={6} xs={8}>
        <Grid container item>
          <Grid
            item
            container
            xs={9}
            direction="column"
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="BlueColorTypography SPCardTypo">
                Active Grades
              </Typography>

              <Typography className="BlueColorTypography SPCardTypo">
                Assigned Courses
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Assigned LessonPlans
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Active Learners{" "}
              </Typography>
              <Typography className="BlueColorTypography SPCardTypo">
                Active Trainers{" "}
              </Typography>

              {/* <Typography className="BlueColorTypography SPCardTypo">
                Leaderboard Rank{" "}
              </Typography> */}
            </div>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={3}
            className="HSGrid6Style"
          >
            <div className="HSdivStyle6">
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.grade?.length
                  ? orgDetails?.school?.grade?.length
                  : 0}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.assignedCourses?.length
                  ? orgDetails?.school?.assignedCourses?.length
                  : 0}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.assignedLessonPlans?.length
                  ? orgDetails?.school?.assignedLessonPlans?.length
                  : 0}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.assignedLearners?.length
                  ? orgDetails?.school?.assignedLearners?.length
                  : 0}
              </Typography>
              <Typography className="GreyColorTypography SPCardTypo">
                {orgDetails?.school?.assignedTrainers?.length
                  ? orgDetails?.school?.assignedTrainers?.length
                  : 0}
              </Typography>
              {/* <Typography className="GreyColorTypography SPCardTypo">
              {orgDetails?.school?.assignedTrainers?.length
                  ? orgDetails?.school?.assignedTrainers?.length
                  : 0}
              </Typography> */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container lg={3} md={6} xs={8}>
        <Grid container item>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            direction="column"
            className="colorwhite"
          >
            <Typography className="BlueColorTypography " justify="center">
              Organisation
            </Typography>
            <Typography justify="center" className="BlueColorTypography ">
              Logo
            </Typography>
            <img
              style={{
                width: "219px",
                height: "80px",
                marginTop: "80px",
              }}
              src={orgDetails?.school?.departmentDetails?.imageUrl}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumbs,
  Typography,
  Grid,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
//ReactIcons
import { BsDownload } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { GrView } from "react-icons/gr";
import { FaFileDownload } from "react-icons/fa";
//core component
import LPReportCard from "./LPReportCard";

export default function EquipmentProfile() {
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [value, setValue] = React.useState(0);

  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton"
          >
            Assesments
          </Button>
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton2"
          >
            Result
          </Button>
          <Button
            onClick={() => history.goBack()}
            disableRipple
            className="Breadcrumbbutton3"
          >
            Lesson Plan
          </Button>
          <div className="Breadcrumb2">LTP1</div>
        </Breadcrumbs>
      </div>
      <Grid container>
        <Grid container item className="   colorwhite LPDialogGrid  ">
          <Grid container item>
            <Grid container item md={5} sm={5} xs={5}>
              <Typography className="GreenColorTypography Grade1Typo">
                Grade 1
                <span className="GreyColorTypography SchoolTypo">
                  Zoomer Public School, Pune
                </span>
              </Typography>
            </Grid>
            <Grid container item md={4} sm={4} xs={4}>
              <Typography className="GreenColorTypography">
                LTP1 - Learn To Play
              </Typography>
            </Grid>
            <Grid container item md={1} sm={1} xs={1} justify="flex-end">
              <IconButton className="DownIconButton">
                <FaFileDownload className="DownIcon" />{" "}
              </IconButton>
            </Grid>{" "}
            <Grid container item md={2} sm={2} xs={2}>
              <Typography className="GreyColorTypography DownTypo">
                Download Grade Report
              </Typography>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID </TableCell>
              <TableCell className="Head"> Roll No. </TableCell>
              <TableCell className="Head"> Learner Name </TableCell>

              <TableCell className="Head"> Marks </TableCell>
              <TableCell className="Head"> Attempts </TableCell>
              <TableCell className="Head"> Attempted On </TableCell>

              <TableCell className="Head"> Action </TableCell>
            </TableRow>
          </TableHead>

          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {posts.slice(page, rowsPerPage).map((post) => (
              <TableRow>
                <TableCell className="Col">
                  <Link> 011 </Link>
                </TableCell>
                <TableCell className="Col">
                  <Link>A01</Link>
                </TableCell>
                <TableCell className="Col">
                  <Link>
                    <LPReportCard />
                  </Link>
                </TableCell>
                <TableCell className="Col">
                  <Link>15/30</Link>
                </TableCell>
                <TableCell className="Col">3/3</TableCell>
                <TableCell className="Col">19/10/21</TableCell>
                <TableCell className="Action">
                  <IconButton className="ActionButtonSize">
                    <BsDownload className="ActionButtonIcon" />
                  </IconButton>

                  <IconButton className="ActionButtonSize">
                    <GrView />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

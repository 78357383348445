import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Box,
  Tab,
  Tabs,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";

import EventDelete from "./EventActionDialog/EventDeleteDialog";
import EventSwitch from "./EventActionDialog/EventSwitchDialog";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const { userToken } = useLogin();
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [completedEvent, setAllCompletedEvent] = useState([]);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const {
    states: {
      eventData,
      completedEvent,
      setAllCompletedEvent,
      value,
      setValue,
    },
    getAllEvent,
  } = props;

  console.log("Some event Data", eventData);
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  // const deleteEvent = async (EventId) => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/school/event/deleteEvent/${EventId}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );

  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response ==>", res);
  //       getAllEvent();
  //     } else {
  //       console.log("Error while fetching single school", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };
  ////////////////////////////////////////////////////////////////////////

  const date = eventData?.map((d) => {
    const newDate = d.endDate.split("T")[0];
    return newDate;
  });
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Upcoming"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Completed"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="Head">UID </TableCell>
                <TableCell> Name</TableCell>
                <TableCell className="Head"> Description</TableCell>
                <TableCell className="Head"> PP</TableCell>

                <TableCell className="Head"> Start Date</TableCell>
                <TableCell className="Head"> End Date</TableCell>
                <TableCell className="Head">created On</TableCell>

                {/* <TableCell className="Head"> Action </TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {eventData &&
                eventData?.length > 0 &&
                eventData.map((event) => (
                  <TableRow>
                    <TableCell className="Col ActionButtonSize">
                      <Link> {event.eventUid} </Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Link> {event.eventName}</Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Typography className="GreyColorTypography500 overflow-style">
                        {event.eventDetails}
                      </Typography>
                    </TableCell>
                    <TableCell className="Col">{event?.assignedPP}</TableCell>
                    {/* {formatDate.map((a)=>)} */}
                    <TableCell className="Col">
                      {event.startDate?.split("T")[0]}
                    </TableCell>
                    <TableCell className="Col">
                      {event.endDate?.split("T")[0]}
                    </TableCell>
                    <TableCell className="Col">
                      {event.dateOfCreation?.split("T")[0]}
                    </TableCell>
                    {/* <TableCell className="Action"> */}
                    {/*  */}

                    {/* <IconButton className="ActionButtonSize">
                        <EventSwitch
                          eventId={event._id}
                          access={event.access}
                          getAllEvent={getAllEvent}
                          // accessCheck={access}
                        />
                      </IconButton> */}

                    {/*  */}

                    {/* <IconButton className="ActionButtonSize">
                        <EventDelete
                          EventId={event._id}
                          deleteEvent={deleteEvent}
                        />
                      </IconButton> */}
                    {/* </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {eventData.length == 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Event Found!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {/* <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="Head">UID </TableCell>
                <TableCell className="Head"> Name </TableCell>
                <TableCell className="Head"> Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {completedEvent.map((cEvent) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {cEvent.eventUid} </Link>
                  </TableCell>
                  <TableCell className="Col"> {cEvent.eventName} </TableCell>
                  <TableCell className="ActionButtonSize Col">
                    <Link>{cEvent.eventDetails}</Link>
                  </TableCell>
                  {/* <TableCell className="Action"> */}
                  {/* <SwitchDialog accessCheck={access} /> */}

                  {/* <IconButton className="ActionButtonSize">
                     <DeleteDialog />
                   </IconButton> */}
                  {/* </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {completedEvent.length == 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Event Found!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {/* <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div> */}
      </TabPanel>
    </>
  );
}

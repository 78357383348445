import React, { useState } from "react";
import {
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
// ReactIcons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
// core components
import AssesmentTabs from "./HosEventsTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function ExtendedTables() {
  const { userToken } = useLogin();
  const [grade, setGrade] = React.useState("");
  const [eventData, setEventData] = React.useState([]);
  const GradeChange = (event) => {
    setGrade(event.target.value);
  };

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };

  ///////////////////////////////////////////////////////

  const getAllEvent = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/event/getAllEvents`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of ==>", res);
        setEventData(res.msg);
      } else {
        console.log("Error while fetching ", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  //////////////////////////SEARCH API////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      getAllEvent();
    }
    const response = await fetch(
      `${FETCH_URL}/api/school/event/getAllEvents?search=${searchValue}&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setEventData(res.msg);
      console.log("My event response from searching ==>", res);
      // getAllGrades();
    } else {
      console.log("Error while searching single event", res.err);
    }
  };
  //////////////////////////SEARCH API////////////////////////////
  React.useEffect(() => {
    getAllEvent();
  }, []);

  ///////////////////////////////////////////////////////////
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Events</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this

                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
        </div>
      </Toolbar>
      {/* <Grid container style={{ marginTop: "15px" }}>
        <Grid container item xs={3} sm={3} md={3}>
          <Grid container item>
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={school ? school : 0}
                onChange={handleChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Select school
                </MenuItem>

                <MenuItem className="Eventmenustyle" value={10}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  Zoomer Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  Ridge Public School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item md={2} sm={2} xs={2} lg={2}>
          <Grid item md={9} className="MainPageFCIG">
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Location
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  Pune
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  Delhi
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  Mumbai
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={4} lg={4} className="">
          <Grid item md={4} className="">
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Grade
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  XI
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  X
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  XXI
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={3}
          sm={3}
          xs={3}
          lg={3}
          style={{ justifyContent: "flex-end" }}
        >
          <Typography
            className="GreyColorTypography  "
            style={{ marginRight: "15px" }}
          >
            {" "}
            Sort By
          </Typography>
          <Grid item>
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={school ? school : 0}
                onChange={handleChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Date
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  23/12/2021
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  24/12/2021
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  29/12/2021
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>{" "}
        </Grid>
      </Grid> */}

      <AssesmentTabs getAllEvent={getAllEvent} states={{ eventData }} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
// React Icons
import GTrainer from "./AddGTrainer/GTrainerAdd";
import TrainerEdit from "../../../../Trainer/TrainerActionDialog/TrainerEdit.js/EditTainer";
import TrainerDelete from "../../../../Trainer/TrainerActionDialog/TrainerDeleteDialog";
import TrainerSwitch from "../../../../Trainer/TrainerActionDialog/TrainerSwitch";
import TrainerProfile from "./GTrainerProfile/GTrainerProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function GTrainerTab(props) {
  const { gradeId, data, setLoading } = props;

  const [school, setSchool] = React.useState("");
  const [trainerData, setTrainerData] = React.useState(null);
  const { userToken } = useLogin();
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  const paginatedTrainer = 1;
  console.log(
    "Grade Id from the params of getTrainer Tab ======>",
    trainerData
  );

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return orignalElement;
  };
  // console.log(" Trainer Data ", trainerData);
  const getAllTrainers = async (gradeId) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/learner/getPaginatedTrainers?gradeId=${gradeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTrainerData(res.msg);
        setLoading(false);
      } else {
        setLoading(false);

        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllTrainers(gradeId);
  }, []);
  // const getAllTrainers = async () => {
  //   try {
  //     const response = await fetch(`${FETCH_URL}/api/learner/getAllTrainers`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userToken}`,
  //       },
  //     });
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response of Trainers==>", gradeId);
  //       let data = res.msg;
  //       let filtered = [];
  //       for (let i = 0; i < data.length; i++) {
  //         for (let j = 0; j < data[i].assignedSchool.length; j++) {
  //           if (data[i].assignedSchool[j].gradeId === gradeId) {
  //             filtered.push(data[i]);
  //           }
  //         }
  //       }
  //       setTrainerData(filtered);
  //     } else {
  //       console.log("Error while fetching trainers", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };
  // const deleteTrainer = async (TrainerId) => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/learner/deleteTrainer/${TrainerId}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response ==>", res);
  //       getAllTrainers();
  //     } else {
  //       console.log("Error while fetching single school", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };
  return (
    <>
      <Grid container>
        {/* <Grid container item className="  colorwhite DivisionStyleGrid  "> */}
        <Grid container item className="  DivisionStyleGridSkill ">
          <Grid container item className="DiviStyle ">
            <Typography></Typography>
            <GTrainer
              data={data}
              idGrade={gradeId}
              getAllTrainers={getAllTrainers}
            />
          </Grid>
        </Grid>
      </Grid>

      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead"> Trainer's Name</TableCell>
              <TableCell className="ColHead"> Mobile Number</TableCell>
              <TableCell className="ColHead"> Email ID </TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainerData &&
              trainerData.length &&
              trainerData.map((trainer) => (
                <TableRow>
                  <TableCell className="Col">
                    {trainer.trainerDetail.regNo}
                  </TableCell>
                  <TableCell className="Col">
                    <TrainerProfile
                      data={data}
                      trainer={trainer}
                      TrainerId={trainer._id}
                    />
                  </TableCell>
                  <TableCell className="Col">
                    {/* {trainer.assignedGrades
                      ? trainer.assignedGrades.length
                      : "--"} */}
                    {trainer?.contactDetail?.trainerPhoneNo
                      ? trainer?.contactDetail?.trainerPhoneNo
                      : "--"}
                  </TableCell>
                  <TableCell className="Col">
                    {/* {trainer.assignedGrades
                      ? trainer.assignedGrades.length
                      : "--"} */}
                    {trainer?.contactDetail?.trainerEmail
                      ? trainer?.contactDetail?.trainerEmail
                      : "--"}
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <TrainerEdit
                        trainer={trainer}
                        gradeId={gradeId}
                        paginatedTrainer={paginatedTrainer}
                        getAllTrainers={getAllTrainers}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <TrainerSwitch
                        access={trainer.access}
                        TrainerId={trainer._id}
                        paginatedTrainer={paginatedTrainer}
                        gradeId={gradeId}
                        getAllTrainers={getAllTrainers}
                      />
                    </IconButton> */}
                    {/* <IconButton className="ActionButtonSize">
                      <TrainerDelete
                        TrainerId={trainer._id}
                        deleteTrainer={deleteTrainer}
                      />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {/* <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div> */}
    </>
  );
}

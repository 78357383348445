import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Input,
  Typography,
  Button,
} from "@material-ui/core";
// @material-ui/core components
import EquipmentAdd from "./EquipmentDialog";
// @matertial-ui/core  Icons
import { FaPercent } from "react-icons/fa";

export default function ExtendedTables() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const LabelName = [
    {
      labelname: "Skipping",
    },
    {
      labelname: "Walking",
    },
    {
      labelname: "Running",
    },
    {
      labelname: "Jumping",
    },
    {
      labelname: "Hopping",
    },
    {
      labelname: "Leaping",
    },
    {
      labelname: "Skipping",
    },
    {
      labelname: "Swinging",
    },
    {
      labelname: "Side Stepping ",
    },
    {
      labelname: "Dodging",
    },
    {
      labelname: "Galloping",
    },
    {
      labelname: "Climbing",
    },
    {
      labelname: "Crawling",
    },
  ];
  const LabelRow2 = [
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
    {
      labelname2: "Rolling",
    },
    {
      labelname2: "Rotatin",
    },
    {
      labelname2: "Bending",
    },
    {
      labelname2: "Stretching",
    },
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
  ];
  const LabelRow3 = [
    {
      labelname3: "Catching",
    },
    {
      labelname3: "Overhand Throwing",
    },
    {
      labelname3: "Underhand Throwing",
    },
    {
      labelname3: "Foot Dribbling",
    },
    {
      labelname3: "Balancing Wand",
    },
    {
      labelname3: "Hand Dribbling",
    },
    {
      labelname3: "Chest Passing",
    },
    {
      labelname3: "Catching",
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container md={3} sm={3} xs={3} lg={3} direction="column">
          <Grid
            item
            container
            style={{
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              md={7}
              sm={7}
              xs={7}
              lg={7}
              className=" CheckHeadTitle"
            >
              <Typography className=" GreenColorTypography ALAddSkilltypography">
                Locomotor Skills
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={5}
              xs={5}
              lg={5}
              className=" CheckHeadTitle"
            >
              <span>
                <Input className="PercentInput"></Input>
              </span>
              <FaPercent className="PercenIcon  " />
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelName.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item container md={3} sm={3} xs={3} lg={3} direction="column">
          <Grid
            item
            container
            style={{
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              md={7}
              sm={7}
              xs={7}
              lg={7}
              className=" CheckHeadTitle"
            >
              <Typography className=" GreenColorTypography ALAddSkilltypography">
                Body Management
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={5}
              xs={5}
              lg={5}
              className=" CheckHeadTitle"
            >
              <span>
                <Input className="PercentInput"></Input>
              </span>
              <FaPercent className="PercenIcon  " />
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelRow2.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname2}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item container md={3} sm={3} xs={3} lg={3} direction="column">
          <Grid
            item
            container
            style={{
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              md={7}
              sm={7}
              xs={7}
              lg={7}
              className=" CheckHeadTitle"
            >
              <Typography className=" GreenColorTypography ALAddSkilltypography">
                Object Control
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={5}
              sm={5}
              xs={5}
              lg={5}
              className=" CheckHeadTitle"
            >
              <span>
                <Input className="PercentInput"></Input>
              </span>
              <FaPercent className="PercenIcon  " />
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelRow3.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname3}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item container xs={3} md={3} className="GridHeight">
          <Grid
            item
            container
            className="EquipGridHeight"
            style={{ backgroundColor: "white" }}
          >
            <Typography className="BlueColorTypography AddEquipmentTypography">
              Add Equipment
            </Typography>
            <Grid
              item
              container
              style={{
                justifyContent: " center",
              }}
            >
              <EquipmentAdd />
            </Grid>
            <Grid item container className="EquipDoneButton">
              <Button className=" GreenButton700    ">Done</Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  TextField,
  Chip,
  IconButton,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { FiPlus } from "react-icons/fi";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "date-fns";

// ReactIcons
import { IoIosAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
// Import Files
import AssignEquipment from "../AssignEquipment";
import AssignSkills from "../AssignSkillsDialog";

export default function AboutLP(props) {
  const {
    states: {
      TabNext,
      uid,
      setUid,
      duration,
      setDuration,
      durationSlot,
      setDurationSlot,
      price,
      setPrice,
      folderSelected,
      setFolderSelected,
      playpoints,
      setPlayPoints,
      isForTrainer,
      setIsForTrainer,
      isForLearner,
      setIsForLearner,
      title,
      setTitle,
      grade,
      setGrade,
      sports,
      setSports,
      about,
      setAbout,
      open,
      setOpen,
      openEquip,
      setOpenEquip,
      assignequipment,
      filterAssignequiment,
      id,
      calculateLPXp,
      foldername,
      file,
      setFile,
      media,
      setMedia,
      mediatype,
      setMediaType,
      originalData,
      bmoriginalData,
      ocoriginalData,
      skillstore,
      Equiomentstore,
      handleChangeDuration,
      TargetUserOptons,
      selectuidValid,
      durationValid,
      durationSlotValid,
      priceValid,
      titleValid,
      gradeValid,
      sportsValid,
      aboutValid,
      skillsValid,
      imageValid,
      equipmentValid,
      medaiValid,
      playpointsValid,
      folderSelectValid,
    },
    MediaUploadClick,
    reuploadClick,
    MediaUploader,
    ImageUploader,
    storeSkill,
    BMstoreSkill,
    OCstoreSkill,
    locomotorP,
    setLocomotorP,
    bodyManagementP,
    setBodyManagementP,
    objectControlP,
    setObjectControlP,
    storeEquipment,
    handleEnter,
    Sports,
    top100Films,
  } = props;
  const SkillsData = skillstore.slice(0, 1);
  const totalCount = skillstore.length - 1;
  const EquipmentData = filterAssignequiment.slice(0, 5);
  const totalEquipmentCount = filterAssignequiment.length - 5;
  const [inputValue, setInputValue] = React.useState("");

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid container className="ml-18">
            <Grid container md={12} sm={12} xs={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Typography
                    className={
                      selectuidValid ? "InputTitle-warn" : "InputTitle"
                    }
                  >
                    LP UID{" "}
                  </Typography>
                  <Input
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
                    disableUnderline
                    className={
                      selectuidValid ? "InputStyle-warn" : "InputStyle"
                    }
                  />
                </Grid>
                <Grid item md={5}>
                  <Typography
                    className={
                      durationValid || durationSlotValid
                        ? "InputTitle-warn"
                        : "InputTitle"
                    }
                  >
                    LP Duration
                  </Typography>
                  <Grid
                    container
                    className={
                      durationValid || durationSlotValid
                        ? "border-red  b-r10"
                        : "border-grey b-r10"
                    }
                  >
                    <Grid
                      item
                      md={5}
                      style={{ borderRight: "1px solid #d4d4d4" }}
                      className={
                        durationValid
                          ? " border--right-red "
                          : "border-right-grey"
                      }
                    >
                      <Input
                        value={duration}
                        type="number"
                        onChange={(e) => setDuration(e.target.value)}
                        disableUnderline
                        className="InputStyle-no-br width100"
                      />
                    </Grid>
                    <Grid item md={7}>
                      <FormControl className="MainPageFormControl  width100">
                        <Select
                          className="Selectdrop-no  hgt-33"
                          value={durationSlot}
                          onChange={handleChangeDuration}
                          label="Select School"
                          disableUnderline
                        >
                          <MenuItem className="Selectmenustyle" value={"Month"}>
                            Month
                          </MenuItem>
                          <MenuItem className="Selectmenustyle" value={"Days"}>
                            Days
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    className={priceValid ? "InputTitle-warn" : "InputTitle"}
                  >
                    Price
                  </Typography>
                  <Input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    disableUnderline
                    className={priceValid ? "InputStyle-warn" : "InputStyle"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={8}>
                <Typography
                  //  className="LPTypo BlueColorTypography"
                  className={
                    folderSelectValid ? "InputTitle-warn" : "InputTitle"
                  }
                >
                  Lesson Plan Folders
                </Typography>
                <FormControl variant="standard" className="FormControlInput">
                  <Select
                    // className="Selectdrop-no border-grey hgt-33"
                    className={
                      folderSelectValid
                        ? "InputStyle-warn"
                        : "Selectdrop-no border-grey hgt-33"
                    }
                    value={folderSelected ? folderSelected : 0}
                    onChange={(e) => {
                      setFolderSelected(e.target.value);
                      calculateLPXp(e.target.value);
                    }}
                    defaultValue={0}
                    disableUnderline
                  >
                    <MenuItem value={0} className="Selectmenustyle" disabled>
                      Select Folders
                    </MenuItem>
                    {foldername &&
                      foldername.length > 0 &&
                      foldername.map((data, index) => (
                        <MenuItem value={data._id} className="Selectmenustyle">
                          {data.folderName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <Typography
                  className={playpointsValid ? "InputTitle-warn" : "InputTitle"}
                >
                  Play Points{" "}
                </Typography>
                <Input
                  value={playpoints}
                  onChange={(e) => setPlayPoints(e.target.value)}
                  disableUnderline
                  disabled={true}
                  className="InputStyle"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className="mt-18">
              <Typography className="BlueColorTypography">Users</Typography>
              <FormGroup className="ml-16">
                <FormControlLabel
                  control={<Checkbox checked={isForTrainer ? true : false} />}
                  className="CheckStyleRecommendation"
                  label="Trainer"
                  value={isForTrainer}
                  onChange={(e) => {
                    setIsForTrainer(!isForTrainer);
                  }}
                />
              </FormGroup>
              <FormGroup className="ml-16">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isForLearner ? true : false}
                      className="CheckStyleRecommendation"
                    />
                  }
                  className="CheckStyleRecommendation"
                  label="Learner"
                  value={isForLearner}
                  onChange={(e) => {
                    setIsForLearner(!isForLearner);
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TextField
                id={titleValid ? "outlined-error" : "outlined-basic"}
                error={titleValid ? true : null}
                label={
                  <spa>
                    LP Title <span className="ImpColor"> * </span>
                  </spa>
                }
                variant="outlined"
                size="small"
                className={
                  titleValid
                    ? "width90 mt-24 textfield-outline-error"
                    : "width90 mt-24 textfield-outline"
                }
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                size="small"
                options={top100Films}
                getOptionLabel={(option) => option}
                value={grade}
                className={
                  gradeValid
                    ? "width100  b-r10  autocomplete-course-warn"
                    : "width100 b-r10  autocomplete-course"
                }
                onChange={(event, value, data) => {
                  setGrade(value);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Grade"
                    className="width90"
                  />
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12} className="mt-18">
              <Typography
                className={sportsValid ? "InputTitle-warn" : "InputTitle"}
              >
                Sports <span className="ImpColor"> * </span>
              </Typography>
              <TagsInput
                value={sports}
                placeholder="Add a sports tag"
                className={
                  sportsValid ? "InputStyle-warn tags" : "InputStyle tags"
                }
                onChange={(event) => setSports(event)}
              />
            </Grid>
            <Grid item md={12} className="mt-18">
              <div>
                <Typography
                  // className="TypographyTitle"
                  className={aboutValid ? "InputTitle-warn" : "InputTitle"}
                >
                  About{" "}
                </Typography>
                <div>
                  <TextareaAutosize
                    className={
                      aboutValid
                        ? "TextArea width90  border-red "
                        : " border-grey TextArea width90"
                    }
                    aria-label="minimum height"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography
              className={
                skillsValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10"
              }
            >
              Add Skills <span className="ImpColor"> * </span>
            </Typography>
            <div
              className={
                skillsValid
                  ? "Thumbnailform  border-red"
                  : "Thumbnailform border-grey"
              }
            >
              {open === false ? (
                <>
                  {SkillsData?.map((data) => {
                    let icon;

                    if (data.subSkillName === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <Chip
                        icon={icon}
                        label={data.subSkillName}
                        className="Course-SkillChip"
                        // onDelete={
                        //   data.subSkillName === "React"
                        //     ? undefined
                        //     : handleDelete(data)
                        // }
                      />
                    );
                  })}
                  {skillstore.length > 1 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}

              <AssignSkills
                LessonplanId={id}
                storeSkill={storeSkill}
                BMstoreSkill={BMstoreSkill}
                OCstoreSkill={OCstoreSkill}
                locomotorP={locomotorP}
                setLocomotorP={setLocomotorP}
                bodyManagementP={bodyManagementP}
                setBodyManagementP={setBodyManagementP}
                objectControlP={objectControlP}
                setObjectControlP={setObjectControlP}
                skillstore={skillstore}
                states={{
                  open,
                  setOpen,
                  originalData,
                  bmoriginalData,
                  ocoriginalData,
                }}
              />
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography
              //  className="TypographyTitle mt-16"
              className={
                imageValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10 fs-16"
              }
            >
              Add Thumbnail
            </Typography>
            <Grid item className="ChooseFileButtonItem ">
              {file ? (
                <Grid
                  container
                  className={
                    imageValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                  style={{
                    height: "200px",
                    border: "1px solid #d4d4d4",
                    borderRadius: "10px",
                  }}
                >
                  <Grid
                    container
                    style={{
                      position: "relative",
                    }}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        reuploadClick();
                        setFile(undefined);
                      }}
                      style={{
                        position: "absolute",
                        right: 8,
                        color: "red",
                      }}
                    >
                      <IoMdClose />
                    </IconButton>
                  </Grid>
                  <img
                    src={file}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      marginTop: "10px",
                      height: "180px",
                    }}
                  />{" "}
                </Grid>
              ) : (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="Image-button-file"
                    accept="image/*"
                    onChange={(e) => {
                      ImageUploader(e);
                    }}
                  />
                  <Grid
                    item
                    md={12}
                    className={
                      imageValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                    style={{
                      border: "1px solid #d4d4d4",
                      height: "200px",
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <label htmlFor="Image-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle
                          style={{ fontSize: "100px", cursor: "pointer" }}
                          className=" Icon-Color mt-24"
                        />
                      </Typography>
                    </label>
                    <Typography
                      style={{
                        width: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12"
                    >
                      Click here to upload image
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item xs={11} direction="column">
            <Typography
              className={
                equipmentValid
                  ? "RedColorTypography mt-10 fs-16"
                  : "TypographyTitle mt-10"
              }
            >
              Add Equipment <span className="ImpColor"> * </span>
            </Typography>
            <div
              className={
                equipmentValid
                  ? "Thumbnailform  border-red mb20px"
                  : "Thumbnailform border-grey mb20px"
              }
            >
              {openEquip === false ? (
                <>
                  {EquipmentData?.map((data) => {
                    let icon;

                    if (data.subSkillName === "React") {
                      icon = <TagFacesIcon />;
                    }

                    return (
                      <Chip
                        icon={icon}
                        label={data.name}
                        className="Course-SkillChip"
                      />
                    );
                  })}
                  {EquipmentData.length > 5 ? (
                    <Chip
                      className="Course-SkillChip"
                      label={
                        <>
                          <FiPlus />
                          <span className="fs-16"> {totalEquipmentCount}</span>
                        </>
                      }
                    />
                  ) : null}
                </>
              ) : null}
              <AssignEquipment
                storeEquipment={storeEquipment}
                states={{ assignequipment, openEquip, setOpenEquip }}
              />
            </div>
          </Grid>
          <Grid item md={11}>
            <Typography
              className={
                medaiValid
                  ? "RedColorTypography fs-16 mt-10"
                  : "TypographyTitle mt-10  fs-16"
              }
            >
              Add Hero Conent{" "}
            </Typography>
            <Grid item className="ChooseFileButtonItem ">
              {media ? (
                <Grid
                  container
                  // className="mb20px"
                  className={
                    medaiValid
                      ? "mb20px  mt-10 ThumbnailGrid  border-red "
                      : "mb20px   mt-10 ThumbnailGrid border-grey "
                  }
                  style={{
                    height: "200px",
                    border: "1px solid #d4d4d4",
                    borderRadius: "10px",
                  }}
                >
                  <Grid
                    container
                    style={{
                      height: "200px",
                      border: "1px solid #d4d4d4",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      container
                      style={{
                        position: "relative",
                      }}
                    >
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          MediaUploadClick();
                          setMedia(undefined);
                          setMediaType(undefined);
                        }}
                        style={{
                          position: "absolute",
                          right: 8,
                          color: "red",
                        }}
                      >
                        <IoMdClose />
                      </IconButton>
                    </Grid>
                    {mediatype === "video/mp4" ? (
                      <video
                        className="width100"
                        autoplay
                        muted
                        src={media}
                        type="video/mp4"
                        style={{
                          height: "180px",
                          width: "100%",
                          objectFit: "contain",
                          marginTop: "10px",
                        }}
                      />
                    ) : (
                      <>
                        <img
                          src={media}
                          style={{
                            height: "180px",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <>
                  {mediatype === "video/mp4" ? (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="raised-button-file"
                        accept="image/*,video/*"
                        onChange={(e) => {
                          MediaUploader(e);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="raised-button-file"
                        accept="image/*,video/*"
                        onChange={(e) => {
                          MediaUploader(e);
                        }}
                      />
                    </>
                  )}
                  <Grid
                    item
                    md={12}
                    className={
                      medaiValid
                        ? "ThumbnailGrid  mt-10  border-red"
                        : "ThumbnailGrid  mt-10 border-grey"
                    }
                    style={{
                      border: "1px solid #d4d4d4",
                      height: "200px",
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <label htmlFor="raised-button-file">
                      <Typography className="width100" align="center">
                        <IoIosAddCircle
                          style={{ fontSize: "100px", cursor: "pointer" }}
                          className=" Icon-Color mt-24"
                        />
                      </Typography>
                    </label>
                    <Typography
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "90%",
                      }}
                      align="center"
                      className="GreyColorTypography  fs-14  mt-12"
                    >
                      Click here to upload image/video
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <DialogActions className=" mb-20 width100">
          <Button className="GreenButton700 next-btn" onClick={TabNext}>
            Next
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Grid,
  Input,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { FaPercent } from "react-icons/fa";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { IoIosAddCircle } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem" style={{}}>
        Assign Skills
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SkilsDialog(props) {
  const {
    states: {
      open,
      setOpen,
      originalData,
      bmoriginalData,
      ocoriginalData,
      inputDisable,
    },
    storeSkill,
    OCstoreSkill,
    BMstoreSkill,
    locomotorP,
    setLocomotorP,
    bodyManagementP,
    setBodyManagementP,
    objectControlP,
    setObjectControlP,
    skillstore,
  } = props;

  // console.log(totaldata,'i am the total data')
  const classes = useStyles();
  const { userToken } = useLogin();
  const [value] = React.useState(0);
  const [message, setMessage] = useState("");
  const [scroll, setScroll] = React.useState("paper");
  const [subSkillName, setSubSkillName] = React.useState(null);
  const [disable, setDisable] = useState(true);

  // const [originalData, setOriginalData] = React.useState([]); // original array with undefined data
  /////////////////////////////////////

  useEffect(() => {
    const totaldata =
      parseInt(locomotorP ? locomotorP : 0) +
      parseInt(bodyManagementP ? bodyManagementP : 0) +
      parseInt(objectControlP ? objectControlP : 0);

    const disabled = (totaldata) => {
      if (totaldata === 100 && skillstore.length > 0) {
        setDisable(false);
        setMessage("Skill percentage is 100 now");
      } else {
        setDisable(true);
        setMessage("Skill percentage should be Equal to 100");
      }
    };
    disabled(totaldata);
    console.log(totaldata);
  }, [locomotorP, bodyManagementP, objectControlP, skillstore]);
  //////////////////////////////////////////////////////////////////
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [skillsData, setSkillsData] = React.useState(null);
  const [locomotor, setLocomotor] = React.useState([]);
  const [BodyMgt, setBodyMgt] = React.useState([]);
  const [objcontrol, setObjectControl] = React.useState([]);
  ////////////////////////////////////////////////////////////////

  // Get API
  const getAllSkill = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/skill/viewAllSkills`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Skills==>", res);
        setLocomotor(
          res.msg.filter((skill) => skill.mainSkill === "Locomotor")
        );
        setBodyMgt(
          res.msg.filter((skill) => skill.mainSkill === "Body Management")
        );
        setObjectControl(
          res.msg.filter((skill) => skill.mainSkill === "Object Control")
        );
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getAllSkill();
  }, [value]);
  useEffect(() => {}, []);

  return (
    <div>
      {inputDisable ? null : (
        <div style={{ position: "absolute", right: 6, bottom: 0 }}>
          <IoIosAddCircle
            className="fs-28 ActionIcon cursor"
            onClick={handleClickOpen}
          />
        </div>
      )}

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <div>
            <Typography
              className={
                disable ? "RedColorTypography " : "GreenColorTypography"
              }
              style={{ textAlign: "center", marginTop: "5px" }}
            >
              {message}
            </Typography>
            <Grid container className="DialogLTRStyle">
              <Grid container item>
                <Grid
                  item
                  container
                  md={4}
                  sm={4}
                  xs={4}
                  lg={4}
                  direction="column"
                >
                  <Grid container item>
                    <Grid item container md={10} sm={10} xs={10} lg={10}>
                      <Grid
                        item
                        container
                        md={7}
                        sm={7}
                        xs={7}
                        lg={7}
                        className=" CheckHeadTitle"
                      >
                        <Typography className=" GreenColorTypography ALAddSkilltypography">
                          Locomotor Skills
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        md={5}
                        sm={5}
                        xs={5}
                        lg={5}
                        className=" CheckHeadTitle"
                      >
                        <span>
                          <Input
                            type="number"
                            className="PercentInput"
                            value={locomotorP}
                            onChange={(e) => setLocomotorP(e.target.value)}
                          ></Input>
                        </span>
                        <FaPercent className="PercenIcon" />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      md={10}
                      sm={10}
                      xs={10}
                      lg={10}
                      style={{
                        borderTop: "2px solid #8EBF4D",
                        maxHeight: "340px",
                        overflow: "auto",
                      }}
                    >
                      {locomotor &&
                        locomotor.length > 0 &&
                        locomotor.map((x, i) => (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            className="SECheckBox  "
                            item
                            container
                            direction="column"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      originalData.length > 0 &&
                                      originalData[i] !== null &&
                                      originalData[i] !== undefined
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                className="CheckLabelStyleGrey  "
                                value={x.subSkillName}
                                onChange={(e) =>
                                  storeSkill(e, x, i, locomotorP)
                                }
                                label={x.subSkillName}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  md={4}
                  sm={4}
                  xs={4}
                  lg={4}
                  direction="column"
                >
                  <Grid
                    item
                    container
                    md={10}
                    sm={10}
                    xs={10}
                    lg={10}
                    style={{ marginLeft: "2%", flexBasis: "18.5%" }}
                  >
                    <Grid item container>
                      <Grid
                        item
                        container
                        md={7}
                        sm={7}
                        xs={7}
                        lg={7}
                        className=" CheckHeadTitle"
                      >
                        <Typography className=" GreenColorTypography ALAddSkilltypography">
                          Body Management
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        md={5}
                        sm={5}
                        xs={5}
                        lg={5}
                        className=" CheckHeadTitle"
                      >
                        <span>
                          <Input
                            className="PercentInput"
                            type="number"
                            value={bodyManagementP}
                            onChange={(e) => setBodyManagementP(e.target.value)}
                          ></Input>
                        </span>
                        <FaPercent className="PercenIcon  " />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        borderTop: "2px solid #8EBF4D",
                        maxHeight: "350px",
                        overflow: "auto",
                      }}
                    >
                      {BodyMgt &&
                        BodyMgt.length > 0 &&
                        BodyMgt.map((x, i) => (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            className="SECheckBox"
                            item
                            container
                            direction="column"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      bmoriginalData.length > 0 &&
                                      bmoriginalData[i] !== null &&
                                      bmoriginalData[i] !== undefined
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                value={x.subSkillName}
                                onChange={(e) =>
                                  BMstoreSkill(e, x, i, bodyManagementP)
                                }
                                label={x.subSkillName}
                                className="CheckLabelStyleGrey  "
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  md={4}
                  sm={4}
                  xs={4}
                  lg={4}
                  direction="column"
                >
                  <Grid
                    item
                    container
                    md={10}
                    sm={10}
                    xs={10}
                    lg={10}
                    style={{ marginLeft: "5%", flexBasis: "16%" }}
                  >
                    {" "}
                    <Grid item container>
                      <Grid
                        item
                        container
                        md={7}
                        sm={7}
                        xs={7}
                        lg={7}
                        className=" CheckHeadTitle"
                      >
                        <Typography className=" GreenColorTypography ALAddSkilltypography">
                          Object Control
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        md={5}
                        sm={5}
                        xs={5}
                        lg={5}
                        className=" CheckHeadTitle"
                      >
                        <span>
                          <Input
                            className="PercentInput"
                            type="number"
                            value={objectControlP}
                            onChange={(e) => setObjectControlP(e.target.value)}
                          ></Input>
                        </span>
                        <FaPercent className="PercenIcon  " />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        borderTop: "2px solid #8EBF4D",
                        overflow: "auto",
                      }}
                    >
                      {objcontrol &&
                        objcontrol.length > 0 &&
                        objcontrol.map((x, i) => (
                          <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            className="SECheckBox  "
                            item
                            container
                            direction="column"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      ocoriginalData.length > 0 &&
                                      ocoriginalData[i] !== null &&
                                      ocoriginalData[i] !== undefined
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                className="CheckLabelStyleGrey"
                                value={x.subSkillName}
                                onChange={(e) =>
                                  OCstoreSkill(e, x, i, objectControlP)
                                }
                                label={x.subSkillName}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            // className="GreenButton700   EquipSaveButton"
            className={
              disable
                ? "GreyColorTypography400 EquipSaveButton"
                : "GreenButton700 EquipSaveButton"
            }
            onClick={() => {
              handleClose();
            }}
            disabled={disable}
          >
            OK
          </Button>
          <Button
            className=" GreyButton700   EquipCancelButton "
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

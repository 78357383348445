import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoCloseSharp } from "react-icons/io5";
import { BsBook } from "react-icons/bs";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

//core Module
import SelectCourse from "./GCourseTab/SelectCourse";
import RenameTab from "./GCourseTab/RenameTab";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ data, getPaginatedCourses }) {
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const { userToken } = useLogin();
  const [course, setAllCourses] = React.useState([]);
  //////////////////////////////////////////////////////
  const [originalData, setOriginalData] = React.useState([]);
  ///////////////////////////////////////////////////////
  const [courseUid, setCourseUid] = React.useState(null);
  const [courseTitle, setCourseTitle] = React.useState(null);
  const [courseStartDate, setCourseStartDate] = React.useState(null);
  const [courseEndate, setCourseEndDate] = React.useState(null);
  /////////////////////////////////////////////////////////////////
  const [open, setOpen] = React.useState(false);
  const [courseId, setCourseId] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setCourseId("");
    setValue(0);
    setCourseUid("");
    setCourseTitle("");
    setSelectCourseText("");
    setCourseUidText("");
    setCourseTitleText("");
  };

  const [selectCourseText, setSelectCourseText] = React.useState("");
  const [courseUidText, setCourseUidText] = React.useState("");
  const [courseTitleText, setCourseTitleText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = React.useState(false);
  const [snackmsg, setSnackMsg] = React.useState("");
  const [snackErrMsg, setSnackErrMsg] = React.useState();
  const [snackerropen, setSnackerropen] = React.useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    if (!courseId) {
      setSelectCourseText("please select a lesson plan!");
      return;
    }
    setSelectCourseText("");
    setCourseUidText("");
    setCourseTitleText("");
    setValue(newValue);
  };
  function TabNext() {
    if (!courseId) {
      setSelectCourseText("please select a Course!");
      return;
    }
    setSelectCourseText("");
    setCourseUidText("");
    setCourseTitleText("");
    setValue(1);
  }

  function storeCourse(e, x, i) {
    // let storeArr = [...originalData];
    let storeArr = [];

    console.log(x, i);
    if (e.target.checked) {
      // storeArr[i] = x;
      // storeArr[i].isChecked = e.target.checked;
      storeArr.push(x);
    }
    // else {
    //   storeArr[i] = null;
    // }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  console.log(
    filterdata.map((a) => a._id),
    "originalData>>12>>>12>>>"
  );
  // const courseId = filterdata.map((a) => a._id);

  ////////////////////////////////////////////////////////////////////////////

  const getAllCourses = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getAllCourses`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of all courses==>", res.msg);
        setAllCourses(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  React.useEffect(() => {
    getAllCourses();
  }, []);
  console.log(data, "data>>>>>>>>>>>>>>>>>>>>>is in tha house");
  const gradeId = {
    _id: data._id,
    schoolId: data.schoolId,
    schoolName: data.schoolName,
    location: data.location,
    grade: data.grade,
  };

  // const assignedLearners = data.assignedLearners;
  const assignedLearners = data.assignedLearnersWithPushNotificationTokens;

  console.log("assignedLearners", assignedLearners);
  const submitClick = async (e) => {
    if (
      courseTitle === null ||
      courseUid.length === 0 ||
      (courseUid === undefined && courseTitle === null) ||
      courseTitle === undefined ||
      courseTitle.length === 0
    ) {
      if (!courseUid) {
        setCourseUidText("please enter course uid !");
      }

      if (!courseTitle) {
        setCourseTitleText("please enter course title !");
      }
      return;
    }
    e.preventDefault();
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/assignCourseToGradeFromGrade/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            gradeId: gradeId,
            assignedLearners: assignedLearners,
            courseUid: courseUid,
            courseTitle: courseTitle,
            // courseDuration: [courseStartDate, courseEndate],
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My assign course to trainer ==>", res);
        handleClose();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getPaginatedCourses(data._id);
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////////////////

  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Course">
        <Button className="BlueButton700" onClick={handleClickOpen}>
          Add Course
        </Button>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Select Course" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <SelectCourse
              states={{ course, selectCourseText, courseId, setCourseId }}
              // storeCourse={storeCourse}
              // originalData={originalData}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenameTab
              states={{
                courseUid,
                setCourseUid,
                courseTitle,
                setCourseTitle,
                courseStartDate,
                setCourseStartDate,
                courseEndate,
                setCourseEndDate,
                courseUidText,
                courseTitleText,
              }}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            // onClick={value == 0 ? TabNext : value == 1 ? submitClick : null}
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

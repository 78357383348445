import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  Grid,
  Input,
  Checkbox,
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  DialogTitle,
  TextareaAutosize,
  Typography,
  IconButton,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";
import { FaPercent } from "react-icons/fa";
// @matertial-ui/core
import EquipmentAdd from "../EditSkillTabDialog/EditEquipmentDialog";

export default function SkillTab() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const TabOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const LabelName = [
    {
      labelname: "Skipping",
    },
    {
      labelname: "Walking",
    },
    {
      labelname: "Running",
    },
    {
      labelname: "Jumping",
    },
    {
      labelname: "Hopping",
    },
    {
      labelname: "Leaping",
    },
    {
      labelname: "Skipping",
    },
    {
      labelname: "Swinging",
    },
    {
      labelname: "Side Stepping ",
    },
    {
      labelname: "Dodging",
    },
    {
      labelname: "Galloping",
    },
    {
      labelname: "Climbing",
    },
    {
      labelname: "Crawling",
    },
  ];
  const LabelRow2 = [
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
    {
      labelname2: "Rolling",
    },
    {
      labelname2: "Rotatin",
    },
    {
      labelname2: "Bending",
    },
    {
      labelname2: "Stretching",
    },
    {
      labelname2: "Balancing",
    },
    {
      labelname2: "Climbing",
    },
  ];
  const LabelRow3 = [
    {
      labelname3: "Catching",
    },
    {
      labelname3: "Overhand Throwing",
    },
    {
      labelname3: "Underhand Throwing",
    },
    {
      labelname3: "Foot Dribbling",
    },
    {
      labelname3: "Balancing Wand",
    },
    {
      labelname3: "Hand Dribbling",
    },
    {
      labelname3: "Chest Passing",
    },
    {
      labelname3: "Catching",
    },
  ];
  return (
    <>
      <Grid container className="DailogLTRBStyle-Na">
        <Grid item container md={3} sm={3} xs={3} lg={3} direction="column">
          <Grid item container style={{}}>
            <Grid
              item
              container
              md={6}
              sm={6}
              xs={6}
              lg={6}
              className=" CheckHeadTitle"
            >
              <Typography className=" GreenColorTypography ALAddSkillTabTypo">
                Locomotor Skills
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={4}
              sm={4}
              xs={4}
              lg={4}
              className=" CheckHeadTitle"
            >
              <span>
                <Input className="PercentInput"></Input>
              </span>
              <FaPercent className="PercenIcon  " />
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={10}
            lg={10}
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelName.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item container md={3} sm={3} xs={3} lg={3} direction="column">
          <Grid item container>
            <Grid
              item
              container
              md={6}
              sm={6}
              xs={6}
              lg={6}
              className=" CheckHeadTitle"
            >
              <Typography className=" GreenColorTypography ALAddSkillTabTypo">
                Body Management
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={4}
              sm={4}
              xs={4}
              lg={4}
              className=" CheckHeadTitle"
            >
              <span>
                <Input className="PercentInput"></Input>
              </span>
              <FaPercent className="PercenIcon  " />
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={10}
            lg={10}
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelRow2.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname2}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item container md={3} sm={3} xs={3} lg={3} direction="column">
          <Grid item container>
            <Grid
              item
              container
              md={6}
              sm={6}
              xs={6}
              lg={6}
              className=" CheckHeadTitle"
            >
              <Typography className=" GreenColorTypography ALAddSkillTabTypo">
                Object Control
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={4}
              sm={4}
              xs={4}
              lg={4}
              className=" CheckHeadTitle"
            >
              <span>
                <Input className="PercentInput"></Input>
              </span>
              <FaPercent className="PercenIcon  " />
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={10}
            lg={10}
            style={{
              borderTop: "2px solid #8EBF4D",
              maxHeight: "340px",
              overflow: "auto",
            }}
          >
            {LabelRow3.map((user, key) => (
              <Grid
                md={12}
                sm={12}
                xs={12}
                lg={12}
                className="SECheckBox  "
                item
                container
                direction="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    className="CheckLabelSkillTabTypo  "
                    label={user.labelname3}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item container md={3} sm={3} xs={3} lg={3} className="GridHeight">
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={10}
            lg={10}
            className="EquipGridHeight"
            style={{ backgroundColor: "white" }}
          >
            <Typography className="BlueColorTypography AddEquipmentTypography">
              Add Equipment
            </Typography>
            <Grid
              item
              container
              style={{
                justifyContent: " center",
              }}
            >
              <EquipmentAdd />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

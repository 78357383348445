import React from "react";
import { Typography, Grid } from "@material-ui/core";
//Ract Icons
import Avatar from "@material-ui/core/Avatar";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import Badges from "../../../../../assets/img/Badges.png";

export default function StatesTab(props) {
  const { TrainerId, trainer, fmsScore, totalPP } = props;
  console.log(trainer, "trainer>>>>>>>>>>>>>>>>>>>>>.");
  return (
    <>
      <Grid container className="DailogLTRBStyle-95">
        <Grid item className="L-profile-grid width-23">
          <Grid item className="width80-LR">
            {trainer?.trainerDetail?.trainerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={trainer?.trainerDetail?.trainerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",

                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {trainer?.trainerDetail?.fullName.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {trainer?.trainerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">Trainer</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.regNo ? (
                    trainer?.trainerDetail?.regNo
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.enrollmentDate ? (
                    trainer?.trainerDetail?.enrollmentDate.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span> <span className="pl-5">{fmsScore}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span> <span className="pl-5">{totalPP} </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? (
                    trainer?.trainerDetail?.dob.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Gender</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? "HC" : <span>N/A</span>}
                </span>
              </Typography>
            </Grid>
          </Grid> */}
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.fathersName ? (
                    trainer?.trainerDetail?.fathersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.mothersName ? (
                    trainer?.trainerDetail?.mothersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="ml-12 L-profile-grid width-75">
          <Grid item md={5} className="DailogLTRBStyle-Na94 ">
            <Typography align="center" className="BlueColorTypography">
              Badges
            </Typography>

            <Grid item className="colorwhite" style={{ marginTop: "18px" }}>
              {" "}
              <img src={Badges} style={{ width: "100%", height: "396px" }} />
            </Grid>
          </Grid>
          <Grid item md={6} className="DailogLTRBStyle-Na94">
            {" "}
            <Typography align="center" className="BlueColorTypography">
              Ranking
            </Typography>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              className=" DailogLTRBStyle-Na94 colorwhite RankingGridStyle"
            >
              <Typography className="BlueColorTypography  RankingTypoStyle">
                #5
              </Typography>
              <Typography className="BlueColorTypography RankingTypoStyle">
                Teacher ranking
              </Typography>
              <Typography className="DarkBlackColorTypo RankingTypoStyle">
                National Level
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

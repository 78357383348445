import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  FormControl,
  makeStyles,
  Input,
  MenuItem,
  Typography,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

//React Icons
import { BsFileEarmark } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";

//core comnponent
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(90),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Add Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GradeAddDialogs({ fetchUserDetails, data }) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setText("");
  };
  const [schoolUid, setSchoolUid] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [schoolLocation, setSchoolLocation] = useState(null);
  const [gradeName, setGradeName] = useState("");
  const [schoolId, setSchoolId] = useState(null);
  const [gradeData, setGradeData] = useState(null);
  const [text, setText] = useState("");
  /**
   * It takes the user's input and sends it to the backend to be stored in the database
   */
  console.log(typeof gradeName, "gradeName>>>>>>>");
  const addGrade = async () => {
    if (
      gradeName === null ||
      gradeName === undefined ||
      gradeName.length === 0
    ) {
      setText("please enter the grade!");
      return;
    }
    try {
      const response = await fetch(`${FETCH_URL}/api/school/grade/addGrade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          schoolId: data._id,
          grade: gradeName,
          location: data.organisationDetails.location,
          schoolName: data.organisationDetails.schoolName,
          schoolUid: data.schoolUid,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        // console.log("My Grade  Post response ==>", res);
        // fetchUserDetails();
        handleClose();
        setGradeName("");
        setSnackOpen(true);
        setSnackMsg(res.msg);
      } else {
        // console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Grade">
        <IconButton className="ActionButtonSize">
          <BsFileEarmark
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>

        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid item md={6} sm={6} xs={6} direction="column">
              <Grid item>
                <Typography className="InputTitleTypography  GreyColorTypography">
                  School UID <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12} sm={12} xs={12}>
                  <Input
                    className="Input1"
                    value={data.schoolUid}
                    disabled
                    // onChange={(e) => {
                    //   setSchoolUid(e.target.value);
                    //   fetchUserDetails(e.target.value);
                    // }}
                  ></Input>
                </Grid>

                <Typography className="InputTitleTypography  GreyColorTypography">
                  Location <span className="ImpColor"> *</span>
                </Typography>
                <Grid item container md={12}>
                  <Input
                    className="Input1"
                    disabled
                    value={data.organisationDetails.location}
                  ></Input>
                </Grid>
              </Grid>
            </Grid>

            <Grid direction="column" item md={6} sm={6} xs={6}>
              <Typography className="InputTitleTypography  GreyColorTypography">
                School Name <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Input
                  className="Input1"
                  disabled
                  value={data.organisationDetails.schoolName}
                ></Input>
              </Grid>
              <Typography className="InputTitleTypography  GreyColorTypography">
                Grade <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={12} sm={12} xs={12}>
                <Input
                  className="Input1"
                  onChange={(e) => {
                    setGradeName(e.target.value);
                    // fetchUserDetails(e.target.value);
                  }}
                />
              </Grid>
              {gradeName.length === 0 && (
                <Grid item container md={12} sm={12} xs={12}>
                  <Typography className="warningTypography">{text}</Typography>
                </Grid>
              )}

              <DialogActions className="SGradeBottom">
                <Button
                  className="GreenButton GradeSaveB"
                  onClick={() => {
                    addGrade();
                  }}
                >
                  Save
                </Button>
                <Button
                  type="submit"
                  className="GreyButton GradeCancelB"
                  onChange={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React from "react";
import {
  Typography,
  Grid,
  Tabs,
  Button,
  Tab,
  Box,
  DialogContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";

//core comonenet '
import SchoolTab from "./TASchoolTab";
import GradeTab from "./TAGradesTab";
// import CourseTab from "./TACourseTab";
// import LessonPlanTab from "./TALessonPlanTab";
import CourseTab from "./TACourseTab/TACourseTab";
import LessonPlanTab from "./TALessonPlanTab/TALessonPlanTab";

//Ract Icons
import ManImage from "../../../../../../assets/img/Man.png";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AcadmicTab(props) {
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const { TrainerId, trainer, fmsScore, totalPP } = props;
  console.log(trainer, "trainer>>>>>>>>>>>>>>>>>>>>>.");
  return (
    <>
      <Grid container className="DailogLTRBStyle-95">
        <Grid item className="L-profile-grid width-23">
          <Grid item className="width80-LR">
            {trainer?.trainerDetail?.trainerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={trainer?.trainerDetail?.trainerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",

                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {trainer?.trainerDetail?.fullName.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {trainer?.trainerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">Trainer</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.regNo ? (
                    trainer?.trainerDetail?.regNo
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.enrollmentDate ? (
                    trainer?.trainerDetail?.enrollmentDate.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span> <span className="pl-5">{fmsScore} </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span> <span className="pl-5">{totalPP} </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? (
                    trainer?.trainerDetail?.dob.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Gender</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? "N/A" : <span>N/A</span>}
                </span>
              </Typography>
            </Grid>
          </Grid> */}
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.fathersName ? (
                    trainer?.trainerDetail?.fathersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.mothersName ? (
                    trainer?.trainerDetail?.mothersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="ml-12 L-profile-grid width-75">
          <Grid container item className="GridStyle-2">
            <Box style={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={TabChange}
                centered
                aria-label="basic tabs example"
              >
                <Tab className="TabStyle" label="School" {...a11yProps(0)} />
                <Tab className="TabStyle" label="Grade " {...a11yProps(1)} />
                <Tab className="TabStyle" label="Course" {...a11yProps(2)} />
                <Tab
                  className="TabStyle"
                  label="Lesson Plans"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <DialogContent dividers={false} className="TabPanelStyle">
              <TabPanel value={value} index={0}>
                <SchoolTab TrainerId={TrainerId} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <GradeTab TrainerId={TrainerId} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CourseTab TrainerId={TrainerId} trainer={trainer} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <LessonPlanTab TrainerId={TrainerId} trainer={trainer} />
              </TabPanel>
            </DialogContent>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

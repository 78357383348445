import {
  Typography,
  Grid,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "antd";

import React, { useState, useEffect } from "react";

const GradeDivision = ({ divisonData, gradeId, getAllDivisons }) => {
  useEffect(() => {
    getAllDivisons(gradeId);
  }, []);

  return (
    <>
      <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">Division Name</TableCell>
              <TableCell className="ColHead">Grade</TableCell>
              <TableCell className="ColHead">Learner </TableCell>
              {/* <TableCell className="ColHead"> Action </TableCell> */}
            </TableRow>
          </TableHead>
          {divisonData.length > 0 && (
            <TableBody>
              {divisonData &&
                divisonData.length > 0 &&
                divisonData.map((divdata) => (
                  <>
                    <TableRow>
                      <TableCell className="Col">{divdata.division}</TableCell>
                      <TableCell className="Col">{divdata.gradeName}</TableCell>
                      <TableCell className="Col ActionButtonSize">
                        {divdata.assignedLearners &&
                        divdata.assignedLearners.length > 0
                          ? divdata.assignedLearners.length
                          : "--"}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          )}
        </Table>
        {divisonData.length === 0 && (
          <Typography
            style={{
              marginTop: "27px",
              textAlign: "center",
            }}
            className="ColHead"
          >
            No Division Found
          </Typography>
        )}
      </div>
    </>
  );
};

export default GradeDivision;

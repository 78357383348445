import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
// ReactIcons
import { MdEdit } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";

//core Component
import ContactTab from "./EditContactDialog";
import DocumentTab from "./EditDocumentDialog";
import EditOrganisation from "./EditOrganisationDialog";
import EditAggrement from "./EditAggrement";
import { useLogin } from "../../../../../Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import {
  editSchool,
  schoolEdited,
} from "../../../../../redux/action/TableAction";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditDialog({ data, handleSearch, getAllSchool }) {
  const classes = useStyles();
  const [datevalue, setDateValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  let isEdited = useSelector((state) => state.tableData.schoolEdited);

  console.log("data incoming on editDialog ====>", data);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const { userToken } = useLogin();

  const dispatch = useDispatch();
  //For CreateInput
  const [uid, setUid] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [enrolledOn, setEnrolledOn] = useState(null);
  const [firstTermStartDate, setFirstTermStartDate] = useState(null);
  const [secondTermStartDate, setsecondTermStartDate] = useState(null);
  const [firstTermEndDate, setfirstTermEndDate] = useState(null);
  const [secondTermEndDate, setsecondTermEndDate] = useState(null);
  const [thirdTermStartDate, setThirdTermStartDate] = useState(null);
  const [thirdTermEndDate, setThirdTermEndDate] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [representativeName, setRepresentativeName] = useState(null);
  const [representativeEmailId, setRepresentativeEmailId] = useState(null);
  const [
    representativeMobilePhoneNo,
    setRepresentativeMobilePhoneNo,
  ] = useState(null);
  const [representativeAddress, setRepresentativeAddress] = useState(null);

  const [departMentDetail, setDepartMentDetails] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  ////////////////////////////////////////////////////////////
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  const [rimage, setRImage] = useState();
  const [rfile, setRFile] = useState(data?.contactDetail?.representativeImg);

  //Events
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  function TabNext3() {
    setValue(3);
  }

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //Api Calling
  const submitClick = async (e) => {
    // console.log("StudentUID----------", uid);
    e.preventDefault();

    dispatch(
      editSchool(
        userToken,
        data._id,
        schoolName ? schoolName : data.organisationDetails.schoolName,
        location ? location : data.organisationDetails.location,
        address ? address : data.contactDetail.address,
        enrolledOn ? enrolledOn : data.organisationDetails.enrolledOn,
        firstTermStartDate
          ? firstTermStartDate
          : data.organisationDetails.firstTermStartDate,
        firstTermEndDate
          ? firstTermEndDate
          : data.organisationDetails.firstTermEndDate,
        secondTermStartDate
          ? secondTermStartDate
          : data.organisationDetails.secondTermStartDate,
        secondTermEndDate
          ? secondTermEndDate
          : data.organisationDetails.secondTermEndDate,

        thirdTermStartDate
          ? thirdTermStartDate
          : data.organisationDetails.thirdTermStartDate,
        thirdTermEndDate
          ? thirdTermEndDate
          : data.organisationDetails.thirdTermEndDate,
        zipCode ? zipCode : data.contactDetail.zipCode,
        city ? city : data.contactDetail.city,
        state ? state : data.contactDetail.state,
        country ? country : data.contactDetail.country,
        representativeName
          ? representativeName
          : data.contactDetail.representativeName,
        representativeEmailId
          ? representativeEmailId
          : data.contactDetail.representativeEmailId,
        representativeMobilePhoneNo
          ? representativeMobilePhoneNo
          : data.contactDetail.representativeMobilePhoneNo,
        representativeAddress
          ? representativeAddress
          : data.contactDetail.representativeAddress,
        file ? file : file,
        rfile ? rfile : rfile,
        imageUpload ? imageUpload : imageUpload
      )
    );
  };
  ///////////////////////////////////////////////////////////////////////////
  const imageUpload = async (ID) => {
    console.log("I am inside inage upload");
    const formdata = new FormData();
    formdata.set("schoolId", ID);
    formdata.set("schoolName", schoolName);
    formdata.append("logo", image);
    formdata.append("representative_photo", rimage);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/school/uploadImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/auth/getAllDepartments?pagination=true&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        let dptDATA = [];
        res.msg.map((departmentSelect) => {
          let departmentDetails = {
            departmentName: departmentSelect.departmentName,
            uid: departmentSelect.uid,
            departmentId: departmentSelect._id,
            imageUrl: departmentSelect.imageUrl,
            createdOn: departmentSelect.createdOn,
            zipCode: departmentSelect.zipCode,
            emailId: departmentSelect.emailId,
            address: departmentSelect.address,
          };
          dptDATA.push(departmentDetails);
        });

        setDepartMentDetails(dptDATA);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // console.log("IsEdited ???? =======>", isEdited);
    if (isEdited === true) {
      handleSearch();
      getAllSchool();
      handleClose();
      dispatch(schoolEdited());
    }
  }, [isEdited]);

  // console.log("SchoolNameEdit========>", { schoolName });

  useEffect(() => {
    getDepartmentDetails();
  }, []);
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Tabs
            value={value}
            onChange={TabChange}
            centered
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Organisation Details"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Contact" {...a11yProps(1)} />
            {/* <Tab className="TabStyle" label="Documents" {...a11yProps(2)} /> */}
            {/* <Tab className="TabStyle" label="Aggrement" {...a11yProps(3)} /> */}
            <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
          </Tabs>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle ">
          <TabPanel value={value} index={0}>
            <EditOrganisation
              data={data}
              states={{
                schoolName,
                setSchoolName,
                location,
                setLocation,
                address,
                setAddress,
                enrolledOn,
                setEnrolledOn,
                firstTermStartDate,
                setFirstTermStartDate,
                secondTermStartDate,
                setsecondTermStartDate,
                firstTermEndDate,
                setfirstTermEndDate,
                secondTermEndDate,
                setsecondTermEndDate,
                thirdTermStartDate,
                setThirdTermStartDate,
                thirdTermEndDate,
                setThirdTermEndDate,
                zipCode,
                setZipCode,
                city,
                setCity,
                state,
                setState,
                country,
                setCountry,
                representativeName,
                setRepresentativeName,
                representativeEmailId,
                setRepresentativeEmailId,
                representativeMobilePhoneNo,
                setRepresentativeMobilePhoneNo,
                representativeAddress,
                setRepresentativeAddress,
                image,
                setImage,
                file,
                setFile,
                departMentDetail,
                setDepartMentDetails,
                departmentSelect,
                setDepartmentSelect,
              }}
              imageUpload={imageUpload}
              TabNext={TabNext}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ContactTab
              data={data}
              states={{
                schoolName,
                setSchoolName,
                location,
                setLocation,
                address,
                setAddress,
                enrolledOn,
                setEnrolledOn,
                firstTermStartDate,
                setFirstTermStartDate,
                secondTermStartDate,
                setsecondTermStartDate,
                firstTermEndDate,
                setfirstTermEndDate,
                secondTermEndDate,
                setsecondTermEndDate,
                zipCode,
                setZipCode,
                city,
                setCity,
                state,
                setState,
                country,
                setCountry,
                representativeName,
                setRepresentativeName,
                representativeEmailId,
                setRepresentativeEmailId,
                representativeMobilePhoneNo,
                setRepresentativeMobilePhoneNo,
                representativeAddress,
                setRepresentativeAddress,
                rimage,
                setRImage,
                rfile,
                setRFile,
              }}
              TabNext2={TabNext2}
              submitClick={submitClick}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DocumentTab submitClick={submitClick} Tabnext3={TabNext3} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EditAggrement submitClick={submitClick} />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  FormControl,
  MenuItem,
  IconButton,
  Input,
  Select,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
import { AiOutlineMinus as RemoveIcon } from "react-icons/ai";
//core Component
import ReciveFeadback from "./RecieveFeadbackTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function FeedbackTab(props) {
  const {
    states: {
      inputDisable,
      setInputDisable,
      FeadbackQuestion,
      setFeadBackQuestion,
    },
    EditSubmitClick,
  } = props;
  const [show, setShowData] = useState(false);
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("Check ", FeadbackQuestion);
  const [options, setOptions] = useState([
    { id: uuidv4(), option: "", optionNo: "" },
  ]);
  const handleAddFields = (e) => {
    setFeadBackQuestion([
      ...FeadbackQuestion,
      {
        feedbackQuery: "",
        feedbackType: "",
      },
    ]);
  };
  const handleRemoveFields = (e, i) => {
    const inputList = [...FeadbackQuestion];
    inputList.splice(i, 1);
    setFeadBackQuestion(inputList);
  };

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const inputList = [...FeadbackQuestion];
    inputList[i][name] = value;
    if (inputList[i][name] === 20) {
      setShowData(true);
    } else {
      setShowData(false);
    }
    setFeadBackQuestion(inputList);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={TabChange}
        aria-label="basic tabs example"
        className="TabsCustom-Style-nobg mt-16"
      >
        <Tab
          className="ActivityTabChanges"
          label="Feedback"
          {...a11yProps(0)}
        />
        <Tab
          className="ActivityTabChanges"
          label="Recieved Feedback"
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0} className="width100">
        <Grid container className="shadow-style  mt-24">
          <Grid container style={{ padding: "20px" }}>
            <Typography className="DarkBlackColorTypo fs-18">
              Add feedback
            </Typography>
            <Grid container>
              {options.slice(0, 10).map((option, index) => (
                <Grid
                  container
                  key={option.id}
                  style={{
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Grid container style={{ gap: "30px" }}>
                    {FeadbackQuestion?.map((x, i) => {
                      console.log("TESTING ==>", i, x);
                      return (
                        <>
                          <Grid container key={i}>
                            <Grid item xs={3}>
                              <Typography className="BlackColorTypography">
                                Type
                              </Typography>
                              <Grid item xs={9}>
                                <FormControl className="width100">
                                  <Select
                                    className="RubicInputs"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    name="feedbackType"
                                    value={x ? x.feedbackType : "feedbackType"}
                                    disabled={inputDisable ? true : false}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <MenuItem value={1}>Star</MenuItem>
                                    <MenuItem value={2}>Theoretical</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography className="BlackColorTypography">
                                QuestionName
                              </Typography>
                              <Grid item md={12} sm={12} xs={12} lg={12}>
                                <Input
                                  className="RubicInputs"
                                  id="QuestionName"
                                  name="feedbackQuery"
                                  value={x ? x.feedbackQuery : "feedbackQuery"}
                                  disabled={inputDisable ? true : false}
                                  onChange={(e) => handleInputChange(e, i)}
                                ></Input>
                              </Grid>
                            </Grid>

                            {inputDisable ? null : (
                              <Grid item xs={2}>
                                <Grid
                                  container
                                  style={{
                                    alignItems: "center",
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    paddingTop: "20px",
                                  }}
                                >
                                  <IconButton
                                    style={{ marginRight: "30px" }}
                                    className="GreyButton700"
                                    disabled={option.length === 1}
                                    onClick={(e) => handleRemoveFields(e, i)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  <IconButton
                                    className="GreenButton700"
                                    onClick={(e) => handleAddFields(e)}
                                  >
                                    <AddIcon />
                                  </IconButton>{" "}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <DialogActions className="mt-24 mb-20 width100">
              {inputDisable ? (
                <Button
                  className="GreenButton700 next-btn"
                  onClick={() => setInputDisable(false)}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    className="GreenButton-border next-btn"
                    onClick={() => setInputDisable(true)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="GreenButton700 next-btn"
                    onClick={() => EditSubmitClick()}
                  >
                    Save
                  </Button>
                </>
              )}
            </DialogActions>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1} className="width100">
        <ReciveFeadback EditSubmitClick={EditSubmitClick} />
      </TabPanel>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Input,
  Checkbox,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  FormControlLabel,
  FormControl,
  Select,
  FormGroup,
  MenuItem,
  DialogContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";

//React Icons
import { IoCloseSharp } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Assign Activity
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [school, setSchool] = React.useState("");
  const schoolChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locationChange = (event) => {
    setLocation(event.target.value);
  };
  const ActivityName = [
    {
      activityname: "   Bouncing Smiley Standing ",
      loconame: " L : 80% ",
      bodyname: " B : 10%",
      objname: "  O : 10%",
    },
    {
      activityname: "   Bouncing Smiley Standing ",
      loconame: " L : 80% ",
      bodyname: " B : 10%",
      objname: "  O : 10%",
    },
    {
      activityname: "   Bouncing Smiley Standing ",
      loconame: " L : 80% ",
      bodyname: " B : 10%",
      objname: "  O : 10%",
    },
    {
      activityname: "   Bouncing Smiley Standing ",
      loconame: " L : 80% ",
      bodyname: " B : 10%",
      objname: "  O : 10%",
    },
    {
      activityname: "   Bouncing Smiley Standing ",
      loconame: " L : 80% ",
      bodyname: " B : 10%",
      objname: "  O : 10%",
    },
    {
      activityname: "   Bouncing Smiley Standing ",
      loconame: " L : 80% ",
      bodyname: " B : 10%",
      objname: "  O : 10%",
    },
  ];
  return (
    <div>
      <div className="CCFB">
        <Typography
          style={{ cursor: "pointer" }}
          align="center"
          className=" GreenButton700 LPSkillButton"
          onClick={handleClickOpen}
        >
          {" "}
          Click here to assign Activities{" "}
        </Typography>
      </div>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle-Na">
            <Grid container item md={12} sm={12} xs={12} lg={12}>
              <Grid item md={3} sm={3} xs={3} lg={3}>
                <FormControl className="MainPageFormControl">
                  <Select
                    className=" DialogSelectdropstyle"
                    value={school ? school : 0}
                    onChange={handleChange}
                    label="Select School"
                    color="white"
                    disableUnderline
                  >
                    <MenuItem className="Selectmenustyle" value={0}>
                      Select Skill
                    </MenuItem>

                    <MenuItem className="Selectmenustyle" value={10}>
                      Blue Ridge Public School
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={20}>
                      Zoomer Public School
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={30}>
                      Ridge Public School
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3} sm={3} xs={3} lg={3} className="MainPageFCIG">
                <FormControl className="MainPageFormControl">
                  <Select
                    className=" Selectdropstyle"
                    value={location ? location : 0}
                    onChange={locationChange}
                    label="Select Location"
                    disableUnderline
                  >
                    <MenuItem className="Selectmenustyle" value={0}>
                      Select Equipment
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={10}>
                      Math
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={20}>
                      English
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={30}>
                      Hindi
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
                <Grid item>
                  {ActivityName.map((user, key) => (
                    <Grid
                      container
                      item
                      md={10}
                      sm={10}
                      xs={10}
                      lg={10}
                      style={{ maxHeight: "300px", overflow: "auto" }}
                      className=" AssignActivitesGridPaper  AssignActivites  "
                    >
                      <Grid container item md={12} sm={12} xs={12} lg={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            className="CheckLabelStyleBlue"
                            label={
                              <Typography>
                                Bouncing Smiley Standing <br />
                                <span className="GreyColorTypography ActivityChooseDialog ">
                                  {user.loconame}
                                </span>
                                <span className="GreyColorTypography ActivityChooseDialog2">
                                  {user.bodyname}
                                </span>
                                <span className="GreyColorTypography  ActivityChooseDialog2">
                                  {user.objname}
                                </span>
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {/* Row2 */}
              <Grid item md={4} sm={4} xs={4} lg={4}>
                <Grid item>
                  {ActivityName.map((user, key) => (
                    <Grid
                      container
                      item
                      md={10}
                      sm={10}
                      xs={10}
                      lg={10}
                      className=" AssignActivitesGridPaper  AssignActivitesRow2  "
                    >
                      <Grid container item md={12} sm={12} xs={12} lg={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            className="CheckLabelStyleBlue"
                            label={
                              <Typography>
                                Bouncing Smiley Standing <br />
                                <span className="GreyColorTypography ActivityChooseDialog ">
                                  {user.loconame}
                                </span>
                                <span className="GreyColorTypography ActivityChooseDialog2">
                                  {user.bodyname}
                                </span>
                                <span className="GreyColorTypography  ActivityChooseDialog2">
                                  {user.objname}
                                </span>
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Row3 */}
              <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
                {ActivityName.map((user, key) => (
                  <Grid
                    container
                    item
                    md={10}
                    sm={10}
                    xs={10}
                    lg={10}
                    className=" AssignActivitesGridPaper  AssignActivitesRow3 "
                  >
                    <Grid container item md={12} sm={12} xs={12} lg={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          className="CheckLabelStyleBlue"
                          label={
                            <Typography>
                              Bouncing Smiley Standing <br />
                              <span className="GreyColorTypography ActivityChooseDialog ">
                                {user.loconame}
                              </span>
                              <span className="GreyColorTypography ActivityChooseDialog2">
                                {user.bodyname}
                              </span>
                              <span className="GreyColorTypography  ActivityChooseDialog2">
                                {user.objname}
                              </span>
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            className="GreenButton   EquipSaveButton"
            onClick={handleClose}
          >
            Save
          </Button>
          <Button
            className=" GreyButton   EquipCancelButton "
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

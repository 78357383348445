import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import LoaderDialog from "components/Loader/LoaderDialog";
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Input,
  InputAdornment,
} from "@material-ui/core";
// material-ui icons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
// core components
import TrainerAdd from "./AddTrainer/TrainerAdd";
import TrainerEdit from "./TrainerActionDialog/TrainerEdit.js/EditTainer";
import TGradeDialog from "./TrainerActionDialog/TGradeDialog";
import TCourseDialog from "./TrainerActionDialog/TCourseDialog/TCourseDialogMain";
import TLessonPlan from "./TrainerActionDialog/TLessonPlanDialog/TLPDialogMain";
import TrainerProfile from "./TrainerProfile/TrainerProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Pages() {
  const { userToken } = useLogin();
  const [trainerData, setTrainerData] = React.useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [serachData, setSerachData] = useState(null);

  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");

  // States for addGrade
  const [schoolNamesForAddGrade, setSchoolNamesForAddGrade] = useState([]);
  const [locationsForAddGrade, setLocationsForAddGrade] = useState([]);
  const [selectedGradeIndex, setSelectedGradeIndex] = useState([]);
  // const [lessonPlanId, setLessonPlanId] = useState([]);
  // const [locationForGrade, setLocationForGrade] = useState([]);
  ////////////////////////////////////////////////////////////////////
  const [locationForGrade, setLocationForGrade] = useState("");
  ///////////////////////////////////////////////////////////////
  const [selectGrade, setSelectGrade] = useState("");
  const [gradeSelected, setGradeSelected] = useState("");
  const [loading, setLoading] = useState(true);

  const onShowSizeChange = (current, pageSize) => {
    console.log("Page size ========>", current, pageSize);
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  // For DropDown
  const getDistinctSchoolAndLocation = async (
    nameOfSchool,
    isForGradeDialog
  ) => {
    console.log("Is this coming from TgradeDialog ====>", isForGradeDialog); // This here is undefined

    try {
      setGradeSelected("");
      setSchoolLocationSelected("");

      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      console.log(res, "i am the greatest res>>>>>>>>>>>");
      if (response.ok) {
        // make a check if it's for the dialog then save it in different state
        if (isForGradeDialog === undefined) {
          console.log(
            "My Distinct schools when isForGradeDialog is undefined ============>",
            res
          );

          if (nameOfSchool) {
            console.log("If condition coming", nameOfSchool);
            setSchoolLoc(res.msg);
            setLocationsForAddGrade(res.msg);
          } else {
            console.log("Else condition coming", nameOfSchool);
            setSchoolNames(res.msg);
            setSchoolNamesForAddGrade(res.msg);
          }
        } else {
          console.log(
            "My Distinct schools when isForGradeDialog is true ============>",
            res
          );
          if (nameOfSchool) {
            console.log("If condition coming", nameOfSchool);
            setLocationsForAddGrade(res.msg);
          } else {
            console.log("Else condition coming", nameOfSchool);
            setSchoolNamesForAddGrade(res.msg);
          }
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  const handleSearch = async (searchValue, pageNum, rowSize) => {
    // if (searchValue.length === 0) {
    //   getAllTrainers();
    // }

    setLoading(true);
    let url;
    if (searchValue) {
      url = `${FETCH_URL}/api/learner/getAllTrainers/?search=${searchValue}`;
      setPageNo(1);
      setRowsPerPage(10);
      setSchoolSelected("");
      setSchoolLocationSelected("");
    } else {
      url = `${FETCH_URL}/api/learner/getAllTrainers?pagination=true&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${gradeSelected}&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    }
    const response = await fetch(
      // `${FETCH_URL}/api/learner/searchTrainer/${searchValue}?page=${
      //   pageNum ? pageNum : 1
      // }&limit=${rowSize ? rowSize : 10}`,
      url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLoading(false);

      setTrainerData(res.msg);
      console.log("My School response from searching ==>", res);
      // getAllGrades();
    } else {
      setLoading(false);

      console.log("Error while searching single school", res.err);
    }
  };

  const getAllTrainers = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);

        setTrainerData(res.msg);
        setTotal(res.lengthData);
        setLoading(false);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const deleteTrainer = async (TrainerId) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/deleteTrainer/${TrainerId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response ==>", res);
        getAllTrainers();
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllTrainers();
    // handleSearch();
    getDistinctSchoolAndLocation(); // for dropdowns
  }, []);
  useEffect(() => {
    getAllTrainers();
    setSchoolLocationSelected("");
    setGradeSelected("");
    setSchoolLoc([]);
    setSelectGrade([]);
  }, [schoolSelected == 0 || ""]);
  const [grade, setGrade] = React.useState("");
  // const GradeChange = (event) => {
  //   setGrade(event.target.value);
  // };

  // const lessonPlanId = trainerData?.map((a) =>
  //   a.assignedLessonPlans.map((b) => b.lessonPlanId)
  // );
  // console.log(lessonPlanId, "yoooo");

  // const nwLessonPlanId = lessonPlanId?.filter((a) => {
  //   if (a.length > 0) {
  //     return a;
  //   }
  // });

  // console.log(nwLessonPlanId.flat(), "lessonPlanId>>>>>>>>123>>>");
  const getGrades = async (location) => {
    setGradeSelected("");
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////////////
  const getTrainersBySchool = async (school) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?schoolName=${school}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      setLoading(false);
      if (response.ok) {
        console.log("My response of paginated Trainers==>", res);

        setTrainerData(res.msg);
        setTotal(res.lengthData);
      } else {
        console.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  const getTrainersByLocation = async (location) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      setLoading(false);

      if (response.ok) {
        console.log("My response of paginated Trainers==>", res);

        setTrainerData(res.msg);
        setTotal(res.lengthData);
      } else {
        console.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  const getTrainersByGrade = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated Trainers==>", res);

        setTrainerData(res.msg);
      } else {
        console.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // console.log(trainerData, ">>>>>>>>>>>>>>>>>>>");
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Trainer</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    // setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                    setSerachData(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          <TrainerAdd getAllTrainers={getAllTrainers} />
          {/* <IconButton className="HeaderAddButton">
            <BsUpload size="1.1em" color="#5FA12F" />
          </IconButton> */}
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid container>
        <Grid item xs={3}>
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              // onChange={locChange}
              label="Select School"
              color="white"
              disableUnderline
              defaultValue={0}
              onChange={(e) => {
                console.log("event target =======>", e.target.value);
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
                if (e.target.value != 0 && e.target.value != null) {
                  getTrainersBySchool(e.target.value);
                }
              }}
            >
              <MenuItem value={0} className="Selectmenustyle">
                All School
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getGrades(e.target.value);
                getTrainersByLocation(e.target.value);
              }}
              label="Select Location"
              disableUnderline
              defaultValue={0}
            >
              <MenuItem value={0} className="Selectmenustyle" disabled>
                Select a location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl variant="standard" className="MainPageFormControl ">
            <Select
              className=" Selectdropstyle"
              value={gradeSelected ? gradeSelected : 0}
              label=" Select Grade"
              color="white"
              onChange={(e) => {
                // getPaginatedTrainers(e.target.value);
                getTrainersByGrade(e.target.value);
                setGradeSelected(e.target.value);
              }}
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Grade
              </MenuItem>
              {selectGrade &&
                selectGrade.length > 0 &&
                selectGrade.map((a) => (
                  <MenuItem className="Selectmenustyle" value={a._id}>
                    {a._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head"> UID </TableCell>

              <TableCell className="Head">Trainer's Name </TableCell>
              <TableCell className="Head">Password </TableCell>
              <TableCell className="Head"> No. of Schools </TableCell>
              <TableCell className="Head"> No. of Grade</TableCell>
              <TableCell className="Head"> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainerData &&
              trainerData.length &&
              trainerData.map((trainer) => {
                const newData = new Set();
                trainer.assignedSchool.map((x) => {
                  newData.add(x.schoolId);
                });

                return (
                  <TableRow>
                    <TableCell className="Col">
                      {trainer.trainerDetail.regNo}
                    </TableCell>
                    <TableCell className="Col">
                      <Link>
                        <TrainerProfile
                          trainer={trainer}
                          TrainerId={trainer._id}
                        />
                      </Link>
                    </TableCell>{" "}
                    <TableCell className="Col700">
                      {trainer.trainerDetail.password}
                    </TableCell>
                    <TableCell className="Col">{newData.size}</TableCell>
                    <TableCell className="Col">
                      {trainer.assignedSchool &&
                      trainer.assignedSchool.length > 0
                        ? trainer.assignedSchool.length
                        : "--"}
                    </TableCell>
                    <TableCell className="Action">
                      <IconButton className="ActionButtonSize">
                        <TrainerEdit
                          trainer={trainer}
                          getAllTrainers={getAllTrainers}
                        />
                      </IconButton>
                      {/* <IconButton className="ActionButtonSize">
                      <TSchoolDialog />
                    </IconButton> */}
                      <IconButton className="ActionButtonSize">
                        <TGradeDialog
                          setSchoolNamesForAddGrade={setSchoolNamesForAddGrade}
                          schoolNamesForAddGrade={schoolNamesForAddGrade}
                          setLocationsForAddGrade={setLocationsForAddGrade}
                          locationsForAddGrade={locationsForAddGrade}
                          getDistinctSchoolAndLocation={
                            getDistinctSchoolAndLocation
                          }
                          setSelectedGradeIndex={setSelectedGradeIndex}
                          selectedGradeIndex={selectedGradeIndex}
                          trainerId={trainer._id}
                          getAllTrainers={getAllTrainers}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <TCourseDialog
                          trainerId={{
                            trainerId: trainer._id,
                            notificationToken: trainer.notificationToken,
                            assignedSchool: trainer.assignedSchool,
                          }}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <TLessonPlan
                          trainerId={{
                            trainerId: trainer._id,
                            notificationToken: trainer.notificationToken,
                            assignedSchool: trainer.assignedSchool,
                          }}
                        />
                      </IconButton>
                      {/* <IconButton className="ActionButtonSize">
                        <TrainerSwitch
                          access={trainer.access}
                          TrainerId={trainer._id}
                          getAllTrainers={getAllTrainers}
                        />
                      </IconButton> */}

                      {/* <IconButton className="ActionButtonSize">
                      <TrainerDelete
                        TrainerId={trainer._id}
                        deleteTrainer={deleteTrainer}
                      />
                    </IconButton> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {trainerData?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Trainer Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {serachData || trainerData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              handleSearch("", value, value2);
              setPageNo(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={pageNo}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

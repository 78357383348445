import React, { useState, useEffect } from "react";
// @material-ui/core components
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import LoaderDialog from "components/Loader/LoaderDialog";
import {
  Grid,
  FormControl,
  Select,
  Input,
  MenuItem,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Tab,
  Tabs,
} from "@material-ui/core";
// React Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { GoSearch } from "react-icons/go";
//core component
import AssignLearner from "./CourseActionDialog.js/AssignLearner/LearnerMainDialog";
import AssingTrainer from "./CourseActionDialog.js/AssignTrainer/TrianerAssignMainDialog";
import SwitchDialog from "./CourseActionDialog.js/SwitchDialog";
import CGradeDialog from "./CourseActionDialog.js/AssignGradeDialog/LPGradeMainDialog";
import CourseDivisionDialog from "./CourseActionDialog.js/AssignCourseDivisionDialog/DivisionMainDialog";
import AddToMarketPlace from "./CourseActionDialog.js/AddCourseToMarketPlace";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import CourseAssignedTab from "./CourseAssignedTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ExtendedTables() {
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const { userToken } = useLogin();

  const loadUsers = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getAllCourses`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of LoadUsers==>", res.msg);
        setPosts(res.msg);
        setTotal(res.lengthData);
        setLoading(false);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    loadUsers();
    getDistinctSchoolAndLocation(); // for dropdowns
  }, []);
  const onShowSizeChange = (current, pageSize) => {
    console.log("pageSize111111", pageSize);
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [selectGrade, setSelectGrade] = useState("");
  const [gradeSelected, setGradeSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState("");

  const changeCourseStatus = async (courseId, access) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/changeCourseStatus/${courseId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({ access: access === 0 ? 1 : 0 }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Grade status response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        loadUsers();
      } else {
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {}
  };
  //////////////////////get grade///////////////////////////////////
  const getGrades = async (location) => {
    setGradeSelected("");
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////////////getPaginatedLearners/////////////////////////
  const getPaginatedCourses = async (grade) => {
    try {
      const response = await fetch(
        // `${FETCH_URL}/api/lessonplan/course/getPaginatedCourse?/admin/trainerschoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        `${FETCH_URL}/api/lessonplan/course/getRenamedCourses?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated Trainers==>", res);

        // setPosts(res.msg);
        setAssignedCourse(res.msg);
        setLoading(false);
      } else {
        console.log("Error while fetching paginated trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  ////////////////////////getPaginatedLearners///////////////////////
  const getDropDownSchools = async (location) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getAllCourses?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My DropDown schools ============>", res);
        setPosts(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      // .error("Catch block ====>", error);
    }
  };
  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      setGradeSelected("");
      setSchoolLocationSelected("");
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  /////////////////////handle search//////////////////////////////////////////////
  const handleSearch = async (search, pageNum, rowSize) => {
    // if (searchValue.length === 0) {
    //   loadUsers();
    // }
    setPage(pageNum ? pageNum : 1);
    // console.log("My handling of search ========>", searchValue);
    const response = await fetch(
      `${FETCH_URL}/api/lessonplan/course/getAllCourses?search=${search}&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setPosts(res.msg);
      if (search) {
        setTotal(res.msg?.length);
      } else {
        setTotal(res?.lengthData);
      }
      console.log("My searching course response ==>", res);

      // getAllGrades();
    } else {
      console.log("Error while searching course", res.err);
    }
  };

  const [totalCA, setTotalCA] = useState(); //
  const [pageCA, setPageCA] = useState(1);
  const [rowsPerPageCa, setRowsPerPageCA] = useState(10);
  const [searchCA, setSearchCA] = useState("");

  const [assignedCourse, setAssignedCourse] = useState(null);
  const ForAssignedCourse = async (searchCA1, pageNum, rowSize) => {
    let url;
    if (searchCA1) {
      setPageCA(1);
      setRowsPerPageCA(10);
      setSchoolSelected("");
      setSchoolLocationSelected("");
      url = `${FETCH_URL}/api/lessonplan/course/getRenamedCourses?search=${searchCA1}`;
    } else {
      url = `${FETCH_URL}/api/lessonplan/course/getRenamedCourses?pagination=true&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`;
    }
    setPageCA(pageNum ? pageNum : 1);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const res = await response.json();
    if (response.ok) {
      setAssignedCourse(res.msg);
      if (searchCA1) {
        setTotalCA(res.msg.length);
      } else {
        setTotalCA(res.lengthData);
      }
      console.log("My ForAssignedCourse course response ==>", res);
    } else {
      console.log("Error while ForAssignedCourse course", res.err);
    }
  };
  const onShowSizeChangeCA = (current, pageSize) => {
    setRowsPerPageCA(pageSize);
  };

  useEffect(() => {
    handleSearch(search);
  }, [search, value]);

  useEffect(() => {
    ForAssignedCourse();
  }, []);
  useEffect(() => {
    ForAssignedCourse();
    setSchoolLocationSelected("");
    setGradeSelected("");
    setSchoolLoc([]);
  }, [schoolSelected == 0 || ""]);
  /////////////////////handle search//////////////////////////////////////////////
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Course</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                {value == 0 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); // Add debouncing for this
                      setSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    value={searchCA}
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); // Add debouncing for this
                      ForAssignedCourse(e.target.value);
                      setSearchCA(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                )}
              </FormControl>
            </div>
          </Box>

          <IconButton className="HeaderAddButton">
            <Link
              to={{
                pathname: "/admin/addCourse",
                data: { loadUsers: loadUsers },
              }}
            >
              <IoIosAddCircleOutline className="AddIcon" />
            </Link>
          </IconButton>
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      {value == 1 ? (
        <Grid container>
          <Grid item xs={3}>
            <FormControl variant="standard" className="MainPageFormControl">
              <Select
                className=" Selectdropstyle"
                value={schoolSelected ? schoolSelected : 0}
                // onChange={locChange}
                label="Select School"
                color="white"
                disableUnderline
                defaultValue={0}
                onChange={(e) => {
                  console.log("event target =======>", e.target.value);
                  getDistinctSchoolAndLocation(e.target.value);
                  setSchoolSelected(e.target.value);
                }}
              >
                <MenuItem value={0} className="Selectmenustyle">
                  All School
                </MenuItem>
                {schoolNames &&
                  schoolNames.length > 0 &&
                  schoolNames.map((school) => (
                    <MenuItem value={school._id} className="Selectmenustyle">
                      {school._id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
            <FormControl className="MainPageFormControl">
              <Select
                className=" Selectdropstyle"
                value={schoolLocationSelected ? schoolLocationSelected : 0}
                onChange={(e) => {
                  setSchoolLocationSelected(e.target.value);
                  getGrades(e.target.value);
                }}
                label="Select Location"
                disableUnderline
                defaultValue={0}
              >
                <MenuItem value={0} className="Selectmenustyle" disabled>
                  Select a location
                </MenuItem>
                {schoolLoc &&
                  schoolLoc.length > 0 &&
                  schoolLoc.map((location) => (
                    <MenuItem value={location?._id} className="Selectmenustyle">
                      {location?._id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
            <FormControl variant="standard" className="MainPageFormControl ">
              <Select
                className=" Selectdropstyle"
                value={gradeSelected ? gradeSelected : 0}
                label=" Select Grade"
                color="white"
                onChange={(e) => {
                  getPaginatedCourses(e.target.value);
                  setGradeSelected(e.target.value);
                }}
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  Select Grade
                </MenuItem>
                {selectGrade &&
                  selectGrade?.length > 0 &&
                  selectGrade?.map((a) => (
                    <MenuItem className="Selectmenustyle" value={a?._id}>
                      {a?._id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <Typography className="BlueColorTypography ml-12 ">
          Showing {posts?.length} out of {total} Courses
        </Typography>
      )}
      <Box className="mt-16">
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="TabsCustom-Style   "
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Original"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Assigned"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="width100">
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead">Subject </TableCell>
                <TableCell className="ColHead"> Description</TableCell>
                <TableCell className="ColHead"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {posts &&
                posts.length > 0 &&
                posts.map((post) => (
                  <TableRow>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: `/admin/courseprofile`,
                          state: { post },
                          data: { loadUsers },
                        }}
                      >
                        {post.courseUid}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: `/admin/courseprofile`,
                          state: { post },
                          data: { loadUsers },
                        }}
                      >
                        {post?.courseTitle}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Typography align="center" className="width100">
                        <Typography className="GreyColorTypography500 overflow-style">
                          {post?.courseDesc}
                        </Typography>
                      </Typography>
                    </TableCell>
                    <TableCell className="Action">
                      {/* <Link
                        to={{
                          pathname: "/admin/EditCourse",
                          state: { post },
                          data: { loadUsers },
                        }}
                      >
                        <Tooltip title="Edit">
                          <IconButton className="ActionButtonSize">
                            <MdEdit
                              loadUsers={loadUsers}
                              className="ActionButtonIcon"
                            />
                          </IconButton>
                        </Tooltip>
                      </Link> */}
                      <AddToMarketPlace loadUsers={loadUsers} post={post} />
                      <IconButton className="ActionButtonSize">
                        <AssingTrainer
                          loadUsers={loadUsers}
                          CourseId={post._id}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <AssignLearner
                          loadUsers={loadUsers}
                          CourseId={post._id}
                        />
                      </IconButton>

                      <CGradeDialog CourseId={post._id} loadUsers={loadUsers} />
                      <CourseDivisionDialog
                        CourseId={post._id}
                        loadUsers={loadUsers}
                      />
                      {/* <SwitchDialog
                        changeCourseStatus={changeCourseStatus}
                        loadUsers={loadUsers}
                        courseId={post._id}
                        access={post.access}
                        snackopen={snackopen}
                        snackmsg={snackmsg}
                        snackErrMsg={snackErrMsg}
                        snackerropen={snackerropen}
                        SnanbarClose={SnanbarClose}
                        SnackbarErrorClose={SnackbarErrorClose}
                      /> */}
                      {/* <DeleteDialog /> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {posts?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Course Found!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
        {search || posts?.length == 0 ? null : (
          <div className="paginationStyle">
            <Pagination
              onChange={(value, value2) => {
                handleSearch(search, value, value2);
              }}
              pageSize={rowsPerPage}
              total={total}
              current={page}
              showSizeChanger
              showQuickJumper
              onShowSizeChange={onShowSizeChange}
              itemRender={itemRender}
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1} className="width100">
        <CourseAssignedTab
          states={{
            assignedCourse,
            rowsPerPageCa,
            totalCA,
            pageCA,
            searchCA,
            schoolSelected,
            changeCourseStatus,
          }}
          ForAssignedCourse={ForAssignedCourse}
          itemRender={itemRender}
          onShowSizeChangeCA={onShowSizeChangeCA}
        />
      </TabPanel>
    </>
  );
}

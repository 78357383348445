import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Link,
} from "@material-ui/core";
import { Pagination } from "antd";

//  Core Module
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import LessonPlanEdit from "./ActionButton/MLessonplanEdit";
import LoaderDialog from "components/Loader/LoaderDialog";

export default function LessonPlanTab() {
  const { userToken } = useLogin();

  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [posts, setPosts] = useState(null);
  const [search, setSearch] = useState("");

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
    // MarketPlaceLessonPlan("undefined", null, pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const [lessonplan, setLessonPlan] = useState([]);
  const [loading, setLoading] = useState(true);

  const MarketPlaceLessonPlan = async (pageNum, rowSize) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getCourseOfMarketPlace?isCourse=false&page=${
          pageNum ? pageNum : page ? page : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLoading(false);

        setLessonPlan(res.msg);
        if (search) {
          setTotal(res.msg.length);
        } else {
          setTotal(res.lengthData);
        }
        console.log("My MarketPlaceLessonPlan course response ==>", res);
      } else {
        setLoading(false);

        console.log("Error while MarketPlaceLessonPlan course", res.err);
      }
    } catch (error) {
      console.log("This is Catch Block", error);
    }
  };
  useEffect(() => {
    MarketPlaceLessonPlan();
  }, []);
  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Name </TableCell>
              <TableCell className="ColHead"> Description</TableCell>
              <TableCell className="ColHead"> PP </TableCell>
              <TableCell className="ColHead"> Price </TableCell>
              <TableCell className="ColHead"> Duration </TableCell>
              <TableCell className="ColHead"> Published </TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lessonplan &&
              lessonplan.length > 0 &&
              lessonplan.map((post) => (
                <TableRow>
                  <TableCell className="Col">{post?.courseUid}</TableCell>
                  <TableCell className="Col">{post?.courseTitle}</TableCell>
                  <TableCell className="Col ">
                    <Typography
                      align="center"
                      className="GreyColorTypography500 overflow-style-nwidth minWidth"
                    >
                      {post?.courseDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Col"> {post?.LPXp}</TableCell>
                  <TableCell className="Col">
                    {post?.coursePrice ? post?.coursePrice : <sapn> N/A </sapn>}
                  </TableCell>
                  <TableCell className="Col">
                    {post?.duration?.courseDuration}{" "}
                    {post?.duration?.durationSlot}
                  </TableCell>
                  <TableCell className="Col">
                    {post?.createdOn?.split("T")[0]}
                  </TableCell>

                  <TableCell className="Action">
                    <LessonPlanEdit
                      marketPlaceCourseId={post._id}
                      MarketPlaceLessonPlan={MarketPlaceLessonPlan}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {lessonplan?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Lesson Plan Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      <LoaderDialog loading={loading} />

      <div className="paginationStyle">
        <Pagination
          onChange={(value, value2) => {
            setPage(value);
            MarketPlaceLessonPlan(value, value2);
          }}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import PropTypes from "prop-types";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Switch,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core";
// React Icons
import { MdEdit } from "react-icons/md";
//core component
import OrgDelete from "../ActionDialog/OrganisationActionDialog/OrgDeleteDialog";
import OrgSwitch from "../ActionDialog/OrganisationActionDialog/OrgSwitchDialog";
import OrgEdit from "../ActionDialog/OrganisationActionDialog/OrgEdit/EditUserMain";
export default function OrgnasationTab(props) {
  const [access, setAccess] = useState(1);
  const { userToken } = useLogin();
  const [posts, setPosts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    states: { searchO, setSearchO, pageO, setPageO, totalO, setTotalO },
    departMentDetail,
    setDepartMentDetails,
    getDepartmentDetails,
    handleSearch,
  } = props;

  const onShowSizeChange = (current, pageSize) => {
    console.log("pageSize111111", pageSize);
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  // const [departMentDetail, setDepartMentDetails] = useState([]);

  // const getDepartmentDetails = async () => {
  //   try {
  //     const response = await fetch(`${FETCH_URL}/api/auth/getAllDepartments`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userToken}`,
  //       },
  //     });
  //     const res = await response.json();
  //     if (response.ok) {
  //       console.log("My response of DepartmentDetails==>", res);
  //       setDepartMentDetails(res);
  //     } else {
  //       console.log("Error while fetching DepartmentDetails", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };

  // useEffect(() => {
  //   // loadUsers();
  //   getDepartmentDetails();
  // }, []);
  console.log("departMentDetail", departMentDetail);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">Organisation</TableCell>
              <TableCell className="Head">Number of Schools</TableCell>
              <TableCell className="Head">Point of Contact</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departMentDetail &&
              Object.keys(departMentDetail).length > 0 &&
              departMentDetail.msg.map((dptData) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {dptData.departmentName} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {dptData.associatedSchool?.length}
                  </TableCell>
                  <TableCell className="Col">
                    <Link>{dptData.pointOfContact} </Link>
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <OrgEdit
                        getDepartmentDetails={getDepartmentDetails}
                        dptData={dptData}
                        departmentId={dptData._id}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <OrgSwitch
                        accessCheck={access}
                        getDepartmentDetails={getDepartmentDetails}
                        departmentId={dptData._id}
                        access={dptData.access}
                      />
                    </IconButton> */}
                    {/* <IconButton className="ActionButtonSize">
                    <OrgDelete />
                  </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {departMentDetail.msg.length == 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Organisation Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {searchO || departMentDetail?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              setPageO(value);
              handleSearch("", value, value2);
            }}
            pageSize={rowsPerPage}
            total={totalO}
            current={pageO}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component

// import DeleteDialog from "../../../TrainerActionDialog/TrainerDeleteDialog";
// import SwtichDialog from "../../../TrainerActionDialog/TrainerSwitch";
export default function Radio() {
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);

  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">UID</TableCell>
                <TableCell className="ColHead"> School Name </TableCell>
                <TableCell className="ColHead"> Location</TableCell>
                <TableCell className="ColHead"> Grade</TableCell>
                <TableCell className="ColHead"> Assigned on </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {posts.slice(page, rowsPerPage).map((post) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> ST-101 </Link>
                  </TableCell>
                  <TableCell className="Col"> Lorem Ipsum</TableCell>
                  <TableCell className="Col"> Lorem Ipsum</TableCell>
                  <TableCell className="Col"> Grade1</TableCell>
                  <TableCell className="Col"> Lorem Ipsum</TableCell>
                  {/* <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <SwtichDialog accessCheck={access} />
                    </IconButton>
                    <DeleteDialog />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import LoaderDialog from "components/Loader/LoaderDialog";
// ReactIcons
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
import DateFnsUtils from "@date-io/date-fns";
// core components
import EventAdd from "./AddEvents/EventAddDialog";
import EventTabs from "./EventsTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function ExtendedTables() {
  const { userToken } = useLogin();
  const [loading, setLoading] = useState(true);

  const [grade, setGrade] = React.useState("");
  const GradeChange = (event) => {
    setGrade(event.target.value);
  };
  const [school, setSchool] = React.useState("");
  const [completedEvent, setAllCompletedEvent] = useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event) => {
    setSchool(event.target.value);
  };
  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };

  const [eventData, setEventData] = React.useState([]);
  const [eventDate, setEventDate] = useState(null);
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const getAllEvent = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/event/getAllEvents`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of ==>", res);
        setEventData(res.msg);
        setLoading(false);
      } else {
        setLoading(false);

        console.log("Error while fetching ", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const [filteredLocations, setFilteredLocations] = React.useState(null);

  const getAllCompleteEvent = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/event/getAllEvents?isCompleted=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of ==>", res);
        setAllCompletedEvent(res.msg);
        setLoading(false);
      } else {
        setLoading(false);

        console.log("Error while fetching ", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  /////////////////////////////////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    setEventDate(null);
    if (value === 0) {
      if (searchValue.length === 0) {
        getAllEvent();
      }
      const response = await fetch(
        `${FETCH_URL}/api/school/event/getAllEvents?search=${searchValue}&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setEventData(res.msg);
        console.log("My event response from searching ==>", res);
        // getAllGrades();
      } else {
        console.log("Error while searching single event", res.err);
      }
    } else {
      if (searchValue.length === 0) {
        getAllCompleteEvent();
      }
      const response = await fetch(
        `${FETCH_URL}/api/school/event/getAllEvents?isCompleted=true&search=${searchValue}&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setAllCompletedEvent(res.msg);
        console.log("My  response from searching completed event ==>", res);
        // getAllGrades();
      } else {
        console.log("Error while searching single event", res.err);
      }
    }
  };
  /////////////////////////////////////////////////////////////

  // console.log(formatDate(eventDate), "eventDate i am here");

  const getAllEventByDate = async (date) => {
    function formatDate(date) {
      if (date === null) {
        return;
      }

      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      if (d < 10) d = "0" + d;
      if (m < 10) m = "0" + m;
      return y + "-" + m + "-" + d;
    }
    const eventByDate = formatDate(date);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/event/getAllEvents`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of ==>", res);
        console.log(res, "resresres");
        const getEventByDate = res.msg.filter((date) => {
          if (date.startDate === eventByDate) {
            return date;
          }
        });
        // console.log(getEventByDate, "getEventByDate");
        // console.log(eventByDate, "eventsByDates123");
        setEventData(getEventByDate);
      } else {
        console.log("Error while fetching ", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  console.log(eventData, "eventData>>>>>>>>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    getAllEvent();
    getAllCompleteEvent();
  }, []);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Event</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    // setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          <EventAdd getAllEvent={getAllEvent} states={{ eventData }} />
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid container>
        <Typography
          className="GreyColorTypography  "
          style={{
            paddingTop: "5px",
            paddingRight: "9px",
            paddingLeft: "15px",
          }}
        >
          {" "}
          Sort By
        </Typography>
        <Grid item md={2} sm={2} xs={2} lg={2}>
          <MuiPickersUtilsProvider className="Calender" utils={DateFnsUtils}>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <KeyboardDatePicker
                className="Calender"
                // id="date-picker-dialog".
                onKeyDown={handleEnter}
                format="yyyy-MM-dd"
                value={eventDate}
                onChange={(e) => {
                  setEventDate(e);
                  getAllEventByDate(e);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        {/* <Grid item md={1}>
          <FormControl className="FormControlInput">
            <Select
              className=" SkillsSelectStyle"
              value={school ? school : 0}
              onChange={handleChange}
              label="Select School"
              color="white"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Date
              </MenuItem>
              <MenuItem className="Selectmenustyle" value={10}>
                23/12/2021
              </MenuItem>
              <MenuItem className="Selectmenustyle" value={20}>
                24/12/2021
              </MenuItem>
              <MenuItem className="Selectmenustyle" value={30}>
                29/12/2021
              </MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>

      <EventTabs
        getAllEvent={getAllEvent}
        states={{
          eventData,
          completedEvent,
          setAllCompletedEvent,
          value,
          setValue,
        }}
      />
    </>
  );
}

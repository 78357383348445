import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Toolbar,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import ManImage from "assets/img/Man.png";
// React Icons
import { TiArrowSortedUp } from "react-icons/ti";
export default function LeaderBoard() {
  const [grade, setGrade] = React.useState("");
  const GradeChange = (event) => {
    setGrade(event.target.value);
  };

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      <Grid container className="LeaderContainer">
        <Grid container item md={8} sm={8} xs={8}>
          <Grid item container className="LeaderTitle">
            <Typography className="BlueColorTypography LeaderTitleTypo ">
              Leaderboard
            </Typography>
            <Grid container item md={2} sm={2} xs={2} lg={2}>
              <FormControl className="MainPageFormControl">
                <Select
                  className=" LeaderDropStyle"
                  value={school ? school : 0}
                  onChange={handleChange}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Weekly
                  </MenuItem>

                  <MenuItem className="Selectmenustyle" value={10}>
                    Blue Ridge Public School
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={20}>
                    Zoomer Public School
                  </MenuItem>
                  <MenuItem className="Selectmenustyle" value={30}>
                    Ridge Public School
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item md={2} sm={2} xs={2} lg={2}>
            <Typography className="GreyColorTypography LeadSelectTypo">
              User
            </Typography>
            <FormControl className="MainPageFormControl">
              <Select
                className=" LeaderDropStyle"
                value={school ? school : 0}
                onChange={handleChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  School
                </MenuItem>

                <MenuItem className="Selectmenustyle" value={10}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={20}>
                  Zoomer Public School
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={30}>
                  Ridge Public School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
            <Typography className="GreyColorTypography LeadSelectTypo">
              Location
            </Typography>
            <FormControl className="MainPageFormControl">
              <Select
                className=" LeaderDropStyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  State
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={10}>
                  Pune
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={20}>
                  Delhi
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={30}>
                  Mumbai
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
            <Typography className="GreyColorTypography LeadSelectTypo">
              Board
            </Typography>
            <FormControl className="MainPageFormControl">
              <Select
                className=" LeaderDropStyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  CBSE
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={10}>
                  Pune
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={20}>
                  Delhi
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={30}>
                  Mumbai
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
            <Typography className="GreyColorTypography LeadSelectTypo">
              Skill
            </Typography>
            <FormControl className="MainPageFormControl">
              <Select
                className=" LeaderDropStyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Selectmenustyle" value={0}>
                  All
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={10}>
                  XI
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={20}>
                  X
                </MenuItem>
                <MenuItem className="Selectmenustyle" value={30}>
                  XXI
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={4} md={4} sm={4} style={{ marginTop: "20px " }}>
              <Grid item md={10} className="LeaderProfileCard10">
                <Grid container item className="LImageGrid">
                  <img src={ManImage} className="LImageStyle" />
                </Grid>
                <Typography align="center" className="GreyColorTypography">
                  Delhi Public School
                </Typography>
                <Typography align="center" className="GreyColorTypography">
                  Delhi
                </Typography>
                <Grid container item className="LeaderRankDiv">
                  <Typography align="center" className="WhiteColorTypography ">
                    255224 PP
                  </Typography>
                </Grid>
                <Button className="WhiteColorTypography  LeaderCardButton ">
                  View Profile
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} sm={4} style={{ marginTop: "20px " }}>
              <Grid item md={10} className="LeaderProfileCard10">
                <Grid container item className="LImageGrid">
                  <img src={ManImage} className="LImageStyle" />
                </Grid>
                <Typography align="center" className="GreyColorTypography">
                  Delhi Public School
                </Typography>
                <Typography align="center" className="GreyColorTypography">
                  Delhi
                </Typography>
                <Grid container item className="LeaderRankDiv">
                  <Typography align="center" className="WhiteColorTypography ">
                    255224 PP
                  </Typography>
                </Grid>
                <Button className="WhiteColorTypography  LeaderCardButton ">
                  View Profile
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} sm={4} style={{ marginTop: "20px " }}>
              <Grid item md={10} className="LeaderProfileCard10">
                <Grid container item className="LImageGrid">
                  <img src={ManImage} className="LImageStyle" />
                </Grid>
                <Typography align="center" className="GreyColorTypography">
                  Delhi Public School
                </Typography>
                <Typography align="center" className="GreyColorTypography">
                  Delhi
                </Typography>
                <Grid container item className="LeaderRankDiv">
                  <Typography align="center" className="WhiteColorTypography ">
                    255224 PP
                  </Typography>
                </Grid>
                <Button className="WhiteColorTypography  LeaderCardButton ">
                  View Profile
                </Button>
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid container item>
            <div className="ResponsiveTableLeaderBoard">
              <Table className="LeaderTableroot">
                <TableHead>
                  <TableRow className="HeadAL">
                    <TableCell className="LeaderColHeadBlack"> Rank </TableCell>
                    <TableCell className="LeaderColHeadBlack">Name</TableCell>

                    <TableCell className="LeaderColHeadBlack">
                      Location{" "}
                    </TableCell>
                    <TableCell className="LeaderColHeadBlack"> State</TableCell>
                    <TableCell className="LeaderColHeadBlack">Board</TableCell>
                    <TableCell className="LeaderColHeadBlack">Points</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {posts.slice(page, rowsPerPage).map((post) => (
                    <TableRow>
                      <TableCell className="LeaderCol700WAB">
                        {/* <TiArrowSortedUp
                          style={{
                            marginRight: "3px",
                            padding: "2px",
                            fontSize: "20px",
                          }}
                        /> */}
                        4{" "}
                      </TableCell>
                      <TableCell className="LeaderCol700WAB">
                        Horizon Public School
                      </TableCell>
                      <TableCell className="LeaderCol700WAB">Dwarka </TableCell>{" "}
                      <TableCell className="LeaderCol700WAB">Delhi</TableCell>
                      <TableCell className="LeaderCol700WAB">CBSE</TableCell>
                      <TableCell className="LeaderCol700WAB">
                        12334 PP
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={4} className="LeadGrid4StyleImg">
          <Grid container item className="LImageGrid">
            <img src={ManImage} className="LeaderGridMain4Image" />
          </Grid>
          <Typography align="center" className="GreyColorTypography">
            Delhi Public School
          </Typography>
          <Typography align="center" className="GreyColorTypography">
            Delhi
          </Typography>
          <Typography
            align="center"
            className="GreyColorTypography LeadCard4AddTypo PT-5px"
          >
            Dwarka, Delhi, India
          </Typography>
          <Typography
            align="center"
            className="GreyColorTypography  LeadCard4AddTypo PT-5px"
          >
            201301
          </Typography>
          <Grid container item className="MT-10px">
            <Grid container item className="LeadCard4GridProfileData">
              <Grid item xs={6} className="LeadCard4-6GridStyle">
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName "
                >
                  Weekly Rank
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle   "
                >
                  1
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName"
                >
                  Points
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle "
                >
                  12452
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className="LeadCard4GridProfileData">
              <Grid item xs={6} className="LeadCard4-6GridStyle">
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName "
                >
                  Locomotor Ranking
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle   "
                >
                  2
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName"
                >
                  Points
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle "
                >
                  4520
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className="LeadCard4GridProfileData">
              <Grid item xs={6} className="LeadCard4-6GridStyle">
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName "
                >
                  Object Control Ranking
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle   "
                >
                  1
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName"
                >
                  Points
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle "
                >
                  5000
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className="LeadCard4GridProfileData">
              <Grid item xs={6} className="LeadCard4-6GridStyle">
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName "
                >
                  Body Management Ranking{" "}
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle   "
                >
                  3
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  align="center"
                  className="GreyColorTypography  LeadCard4-6TypoName"
                >
                  Points
                </Typography>
                <Typography
                  align="center"
                  className="GreenColorTypography LeadCard4-6TypoStyle "
                >
                  6000
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

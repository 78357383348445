import React, { useState, useEffect } from "react";

import { Typography, Button, DialogActions, Grid } from "@material-ui/core";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function Benefits(props) {
  const {
    states: { benefitesState, benefitesValid },
  } = props;

  return (
    <>
      <Grid container className="shadow-style  mt-24">
        <Grid container style={{ padding: "20px" }}>
          <Typography className="DarkBlackColorTypo fs-18 width100">
            Benefits
          </Typography>

          <Grid container>
            <TextareaAutosize
              className={
                benefitesValid
                  ? "textareaauto  width100 border-red  mt-16"
                  : " textareaauto width100   border-grey  mt-16"
              }
              aria-label="maximum height"
              value={benefitesState}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

// import DeleteDialog from "../../../../../../ActionDialog/DeleteDialog";
// import SwtichDialog from "../../../../../../ActionDialog/SwitchDialog";
export default function Grade(props) {
  const { userToken } = useLogin();
  const { TrainerId } = props;

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  const [agradetrainer, setAGradeTrainer] = useState({});
  console.log("AssignLPS", agradetrainer);

  const getAllAssignGradeToTriner = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getTrainerGrades/${TrainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setAGradeTrainer(res.msg);
        console.log(" Grade Assign Trainer =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getAllAssignGradeToTriner();
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="height24vw">
        <div className="ResponsiveTable" style={{ marginRight: "22px" }}>
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                {/* <TableCell className="ColHead">UID</TableCell> */}
                <TableCell className="ColHead"> School Name </TableCell>
                <TableCell className="ColHead"> Location</TableCell>
                <TableCell className="ColHead"> Grade</TableCell>
                {/* <TableCell className="ColHead"> Assigned on </TableCell>
                <TableCell className="ColHead"> Action </TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {agradetrainer &&
                agradetrainer.length > 0 &&
                agradetrainer.map((grade) => (
                  <TableRow>
                    {/* <TableCell className="Col">
                      <Link> ST-101 </Link>
                    </TableCell> */}
                    <TableCell className="ActionButtonSize Col">
                      {grade.schoolName}
                    </TableCell>
                    <TableCell className="Col"> {grade.location}</TableCell>
                    <TableCell className="Col"> {grade.grade}</TableCell>
                    {/* <TableCell className="Col"> Lorem Ipsum</TableCell> */}
                    {/* <TableCell className="Action">
                      <IconButton className="ActionButtonSize">
                        <SwtichDialog accessCheck={access} />
                      </IconButton>
                      <DeleteDialog />
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <div className="paginationStyle">
          <Pagination
            onChange={(value) => setPage(value)}
            pageSize={rowsPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import { BsDownload } from "react-icons/bs";
//React-Icons
import { IoCloseSharp } from "react-icons/io5";
import { BsUpload } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import gradeSample from "../../../../assets/csv/grade.csv";

import { Empty } from "antd";
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Upload Grade
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CustomizedDialogs({ getAllGrades }) {
  const { userToken } = useLogin();
  const [gradeSelected, setGradeSelected] = useState("");
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(120),
    },
  }));
  const [readgrade, setReadGrade] = useState("");
  const [open, setOpen] = React.useState(false);
  const [xlfile, setXlfile] = React.useState();
  const [rowData, setRowData] = useState(null);
  const [file, setFile] = React.useState("");
  ///////////////////////////////////////////////////////////
  const [SchoolName, setSchoolName] = useState([]);
  // const [grade, setGrade] = useState([]);
  const [divisonData, setDivisonData] = useState(null);
  const [divisionId, setDivisionId] = useState(null);
  const classes = useStyles();
  // const [locmotor, setSchool] = React.useState("");
  const schooluid = rowData?.schoolUid;
  const schoolName = rowData?.organisationDetails.schoolName;
  const location = rowData?.organisationDetails.location;

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [fileError, setFileError] = useState("");

  const [schoolData, setSchoolData] = useState([]);
  const getAllSchool = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/school/getAllSchools`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setSchoolData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllSchool();
  }, []);
  // const gradename = gradeSelected.grade;
  const schoolId = rowData?._id;
  // const gradeId = gradeSelected.gradeId;
  // const locmotorChange = (event) => {
  //   setSchool(event.target.value);
  // };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRowData(null);
  };
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  const [scroll, setScroll] = React.useState("paper");
  // Grade add states
  // useEffect(() => {
  //   handleSchoolSearch();
  // });
  const handleSchoolSearch = async (schoolUid) => {
    console.log(schoolUid, "schoolUid i am here");
    const response = await fetch(
      `${FETCH_URL}/api/school/getSingleSchool/${schoolUid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My School response from searching ==>", res);
    } else {
      //.log("Error while searching single school", res.err);
    }
  };

  // const getSchoolDetails = async (schoolUId) => {
  //   console.log(schoolUId, "i am schoolUId");
  //   if (!schoolUId) {
  //     setSchoolName("");
  //     setSchoolLocation("");
  //     setSchoolId("");
  //     return;
  //   }
  //   setSchoolUid(schoolUId);
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/school/getSingleSchool/${schoolUId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       //.log("My Grade Get response ==>", res);
  //       setSchoolName(res.msg.organisationDetails.schoolName);
  //       setSchoolLocation(res.msg.organisationDetails.location);
  //       setSchoolId(res.msg._id);
  //     } else {
  //       //.log("Error while fetching single school", res.err);
  //       setSchoolName("");
  //       setSchoolLocation("");
  //       setSchoolId("");
  //     }
  //   } catch (e) {
  //     //.log("Catch block error =>", e);
  //   }
  // };
  const submitClick = async (e) => {
    if (!schooluid) {
      setSnackerropen(true);
      setSnackErrMsg("Select School UID");
    } else if (!file) {
      setSnackerropen(true);
      setSnackErrMsg("Choose File");
      return;
    } else {
      const formdata = new FormData();
      formdata.set("schoolUid", schooluid);
      formdata.set("schoolName", schoolName);
      formdata.set("location", location);
      // formdata.set("gradename", gradename);
      formdata.set("schoolId", schoolId);
      // formdata.set("gradeId", gradeId);
      formdata.append("xlfile", file);
      if (!file) {
        alert("Please Upload File");
        return;
      }
      // formdata.append("xlfile", image);
      e.preventDefault();
      try {
        const responce = await fetch(
          `${FETCH_URL}/api/school/grade/addGradeFromFile`,
          {
            method: "POST",
            headers: {
              // Accept: "application/json",
              // "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: formdata,
          }
        );
        let res = await responce.json();
        if (responce.ok) {
          handleClose();
          getAllGrades();
        } else {
          console.log(res.err);
          setFileError(res.err);
        }
      } catch (error) {
        console.log("Error while adding Learner =======>", error);
      }
    }
  };
  function FileUploader(e) {
    setFileError("");
    console.log(e.target.files);
    setXlfile(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }
  // function ImageUploader(e) {
  //   console.log(e.target.files);
  //   setXlfile(URL.createObjectURL(e.target.files[0]));
  //   // setImage(e.target.files[0]);
  // }
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton" onClick={handleClickOpen}>
        <BsUpload className="UploadIcon" onClick={handleClickOpen} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="md"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Grid container spacing={4} className="DailogLTRBStyle">
          <Typography align="right" className="width100">
            <Button
              className="GreenButton width180px  mt-10px"
              style={{ textTransform: "capitalize" }}
            >
              <a href={gradeSample} download="gradeSample">
                <BsDownload
                  className="ml-5px fs-15"
                  style={{ marginRight: "5px", color: "white" }}
                />

                <span className="WhiteColorTypography">Download Sample</span>
              </a>
            </Button>
          </Typography>
          <Grid item md={2} sm={2} xs={2} direction="column">
            <Typography className=" GreyColorTypography">
              School UID <span className="ImpColor"> *</span>
            </Typography>
            {/* <Grid item container md={12} sm={12} xs={12}>
                  <Input
                    className="Input1"
                    value={schoolUid}
                    onChange={(e) => {
                      setSchoolUid(e.target.value);
                      getSchoolDetails(e.target.value);
                    }}
                  ></Input>
                </Grid> */}

            <FormControl
              variant="standard"
              className="FormControlInput colorwhite"
            >
              <Select
                className=" SkillsSelectStyle "
                value={rowData?.schoolUid}
                onChange={(e) => {
                  handleSchoolSearch(e.target.value);
                  // getSchoolDetails(e.target.value);
                }}
                color="white"
                label="Locomotor"
                disableUnderline
              >
                {schoolData && schoolData.length > 0 ? (
                  schoolData.map((school, i) => (
                    <MenuItem
                      key={i}
                      className="Selectmenustyle"
                      value={school.schoolUid}
                    >
                      {school.schoolUid}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem className="Selectmenustyle" value={0} disabled>
                    No Schools Available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={3} xs={3} direction="column">
            <Typography className="GreyColorTypography">
              School Name<span className="ImpColor"> *</span>
            </Typography>
            <Input
              className="Input1 width100"
              disabled
              value={rowData?.organisationDetails.schoolName}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={3} direction="column">
            <Typography className="GreyColorTypography">
              Location <span className="ImpColor"> *</span>
            </Typography>

            <Input
              className="Input1"
              disabled
              value={rowData?.organisationDetails.location}
            ></Input>
          </Grid>

          <Grid item xs={2}>
            <Typography className=" GreyColorTypography ">
              Add File <span className="ImpColor"> *</span>
            </Typography>
            <div>
              <label htmlFor="raised-button-file">
                <input
                  type="file"
                  accept=".csv,application/vnd.ms-excel"
                  id="raised-button-file"
                  onChange={FileUploader}
                />
              </label>
            </div>
          </Grid>
          {/* <Grid item xs={2}>
            <Typography align="right" className="width100">
              <Button
                className="IconColor width165px  mt-10px"
                style={{ textTransform: "capitalize" }}
              >
                <a href={gradeSample} download="gradeSample">
                  <BsDownload
                    className="IconColor  ml-5px fs-15"
                    style={{ marginRight: "5px" }}
                  />

                  <span className="mr1">Download Sample</span>
                </a>
              </Button>
            </Typography>
          </Grid> */}
        </Grid>
        <Typography className="fileError">{fileError}</Typography>

        <DialogActions>
          <Button
            type="submit"
            className="GreenButton "
            style={{ padding: "3px 20px" }}
            onClick={submitClick}
          >
            Upload
          </Button>
          <Button className="GreyButton GradeCancelB" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";

export default function OverviewTab({ lp }) {
  return (
    <>
      <Typography className="GreyColorTypography400">
        Through the program and with the help of the Multi-Skill Kit your child
        will be able to better these following skills:
      </Typography>{" "}
      <Typography className="BlueColorTypography  SkillsTitle">
        Interception, Prediction, Dynamic Balance, Striking, Throwing, Catching.{" "}
      </Typography>{" "}
      <Typography className="GreyColorTypography400 GPOTitle1">
        The program has been aptly designed by international experts in the
        early childhood physical education space to ensure that limitations of
        age and ability are strictly taken into mind while delivering the course
      </Typography>
    </>
  );
}

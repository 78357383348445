import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  FormGroup,
} from "@material-ui/core";

export default function SelectSchoolTab(props) {
  const {
    states: {
      divisionData,
      schoolLocationSelected,
      schoolLoc,
      setSchoolLocationSelected,
      schoolNames,
      setSchoolSelected,
      schoolSelected,
      originalData,
      selectDivisionText,
      schoolGradeSelected,
      setschoolGradeSelected,
      schoolGrade,
    },
    storeGrade,
    getDropDownSchools,
    getDistinctSchoolAndLocation,
    filterdata,
    getDivisions,
  } = props;
  console.log(" Grade Check  getAll data", divisionData);

  return (
    <>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{ marginLeft: "2%" }}
        >
          <Grid item container>
            <Grid item container className="ParticipantStyleSelect">
              <FormControl variant="standard" className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={schoolSelected ? schoolSelected : 0}
                  label="Select School"
                  color="white"
                  disableUnderline
                  defaultValue={0}
                  onChange={(e) => {
                    getDistinctSchoolAndLocation(e.target.value);
                    setSchoolSelected(e.target.value);
                  }}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Select a school
                  </MenuItem>
                  {schoolNames &&
                    schoolNames.length > 0 &&
                    schoolNames.map((school) => (
                      <MenuItem value={school._id} className="Selectmenustyle">
                        {school._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>{" "}
            </Grid>
            <Grid item container className="ParticipantStyleSelect1">
              <FormControl variant="standard" className="MainPageFCIG">
                <Select
                  className=" Selectdropstyle"
                  value={schoolLocationSelected ? schoolLocationSelected : 0}
                  onChange={(e) => {
                    setSchoolLocationSelected(e.target.value);
                    getDropDownSchools(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                  defaultValue={0}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Select a location
                  </MenuItem>
                  {schoolLoc &&
                    schoolLoc.length > 0 &&
                    schoolLoc.map((location) => (
                      <MenuItem
                        value={location._id}
                        className="Selectmenustyle"
                      >
                        {location._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="ParticipantStyleSelect">
              <FormControl variant="standard" className="MainPageFCIG">
                <Select
                  className=" Selectdropstyle"
                  value={schoolGradeSelected ? schoolGradeSelected : 0}
                  onChange={(e) => {
                    setschoolGradeSelected(e.target.value);
                    getDivisions(e.target.value);
                  }}
                  label="Select Grade"
                  disableUnderline
                  defaultValue={0}
                >
                  <MenuItem value={0} className="Selectmenustyle" disabled>
                    Select a Grade
                  </MenuItem>
                  {schoolGrade &&
                    schoolGrade.length > 0 &&
                    schoolGrade.map((grade) => (
                      <MenuItem value={grade._id} className="Selectmenustyle">
                        {grade._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {divisionData &&
            divisionData.length > 0 &&
            divisionData.map((x, i) => (
              <Grid
                container
                item
                md={4}
                direction="row"
                style={{ marginTop: "15px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        originalData.length > 0 &&
                        originalData[i] !== null &&
                        originalData[i] !== undefined
                          ? true
                          : false
                      }
                    />
                  }
                  onChange={(e) => storeGrade(e, x, i)}
                  // onChange={(e) => storeGrade(e, x, i)}

                  value={x._id}
                  className=" AssignActivitesGridPaper  AssignActivites  CheckLabelStyleBlue "
                  label={
                    <Typography style={{ paddingLeft: "15px" }}>
                      {x.division}
                      {/* <span style={{ paddingLeft: "20px" }}>
                        {x.schoolName}
                      </span> */}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          {filterdata.length === 0 && (
            <Grid container style={{ marginTop: "10px" }}>
              <Typography className="warningTypography">
                {selectDivisionText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";

export default function AssesmentTab({ assesmentOnGoing, learner }) {
  const OngoingAssement = learner?.assignedAssessments?.filter(
    (item) => item.completion === false
  );

  return (
    <>
      <Grid container style={{ minHeight: "70vh" }}>
        <div className="ResponsiveTable width100">
          <Table className="Tableroot">
            <TableHead>
              <TableRow>
                <TableCell className="Head"> UID </TableCell>
                <TableCell className="Head">Name </TableCell>
                <TableCell className="Head">Assigned PP</TableCell>
                <TableCell className="Head"> Date</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {assesmentOnGoing &&
                assesmentOnGoing?.length > 0 &&
                assesmentOnGoing?.map((Data) => (
                  <TableRow>
                    <TableCell className="Col">{Data?.uid}</TableCell>
                    <TableCell className="Col">
                      {Data?.assessmentName}
                    </TableCell>
                    <TableCell className="Col ActionButtonSize">
                      {Data?.assignedXp}
                    </TableCell>
                    <TableCell className="Col">
                      {Data?.assignedOn?.split("T")[0]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {assesmentOnGoing?.length === 0 ? (
            <Grid
              container
              style={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Typography>
                <Typography className="width100 DarkBlackColorTypo">
                  No Assesment Assign!
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

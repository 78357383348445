import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

//Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FETCH_URL } from "fetchIp";
import { IoIosAddCircle } from "react-icons/io";

//core component
import ContactTab from "./GContactTab";
import AcademicTab from "./GAcademicTab";
import DocumentTab from "./GDocumentTAb";
import LearnerTab from "./GLearnerTab";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function handleEnter(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}
export default function AddDialog(props) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const {
    gradeData,
    gradeIds,
    getPaginatedLearner,
    rowData1,
    divisionDataId,
  } = props;
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DialogClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    setOpen(false);
  };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  function TabNext3() {
    setValue(3);
  }

  // const { getAllLearners } = props;

  //For CreateInput
  const [fullName, setFullName] = useState(null);
  const [dob, setDob] = useState(null);
  const [regNo, setRegNo] = useState(null);
  const [enrollmentDate, setEnrollmentDate] = useState(null);
  const [fathersName, setFathersName] = useState(null);
  const [mothersName, setMothersName] = useState(null);
  const [learnerEmail, setLearnerEmail] = useState(null);
  const [learnerPhoneNo, setLearnerPhoneNo] = useState(null);
  const [learnerLocation, setLearnerLocation] = useState(null);
  const [learnerAddress, setLearnerAddress] = useState(null);
  const [learnerZipCode, setLearnerZipCode] = useState(null);
  const [learnerCity, setLearnerCity] = useState(null);
  const [learnerState, setLearnerState] = useState(null);
  const [learnerCountry, setlearnerCountry] = useState(null);
  const [guardianFullName, setGuardianFullName] = useState(null);
  const [guardianLocation, setGuardianLocation] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState(null);
  const [guardingPhoneNo, setGuardingPhoneNo] = useState(null);
  const [schoolName, setSchoolName] = useState(gradeData.schoolName);
  const [grade, setGrade] = useState(gradeData.grade);
  const [location, setLocation] = useState(gradeData.location);
  const [rollNumber, setRollNumber] = useState(null);
  const [firstTermStartDate, setFirstTermStartDate] = useState(null);
  const [secondTermStartDate, setsecondTermStartDate] = useState(null);
  const [firstTermEndDate, setfirstTermEndDate] = useState(null);
  const [secondTermEndDate, setsecondTermEndDate] = useState(null);
  const [division, setDivision] = useState(null);
  const [gender, setGender] = useState(null);
  const [gradeId, setFilteredGrades] = React.useState(gradeIds);
  const [schoolId, setSchoolId] = React.useState(rowData1?._id);
  const [thirdTermStartDate, setThirdTermStartDate] = useState(null);
  const [thirdTermEndDate, setThirdTermEndDate] = useState(null);
  const departmentId = rowData1?.departmentDetails?.departmentId;
  const departmentName = rowData1?.departmentDetails?.departmentName;
  const schooluid = rowData1?.schoolUid;

  //Api Calling
  const submitClick = async (e) => {
    e.preventDefault();
    try {
      const responce = await fetch(`${FETCH_URL}/api/learner/createLearner`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          fullName,
          dob,
          regNo,
          enrollmentDate,
          fathersName,
          mothersName,
          learnerEmail,
          learnerPhoneNo,
          learnerLocation,
          learnerAddress,
          learnerZipCode,
          learnerCity,
          learnerState,
          learnerCountry,
          guardianFullName,
          guardianLocation,
          guardianEmail,
          guardingPhoneNo,
          schoolName,
          grade,
          gender,
          location,
          divisionId: division._id,
          firstTermStartDate,
          secondTermStartDate,
          firstTermEndDate,
          secondTermEndDate,
          thirdTermStartDate,
          thirdTermEndDate,
          rollNumber,
          gradeId: gradeId,
          schoolId: schoolId,
          departmentId,
          divisionName: division.division,
          departmentName,
        }),
      });
      let res = await responce.json();

      if (responce.ok) {
        let learnerId = res.msg2._id;

        if (learnerId) {
          imageUpload(learnerId);
          console.log("Response after create >> ", res.msg2._id);
        }
        DialogClose();
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getPaginatedLearner(divisionDataId);
        console.log("Mah respo =====>", res);
      } else {
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();

  const imageUpload = async (learnerId) => {
    const formdata = new FormData();
    formdata.set("learnerId", learnerId);
    formdata.set("regNo", regNo);
    formdata.append("file", image);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/learner/uploadLearnerImage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          body: formdata,
        }
      );
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };
  return (
    <div style={{ marginRight: "30px" }}>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>

      <Button
        className="ButtonTransparent "
        onClick={handleClickOpen("paper")}
        style={{ paddingTop: "10px" }}
      >
        <IoIosAddCircle
          className="MiniAddIcon IconColor"
          onClick={handleClickOpen("paper")}
        />
        Add Learner
      </Button>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              onChange={TabChange}
              centered
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Learner Detail"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Contact Detail"
                {...a11yProps(1)}
              />
              <Tab
                className="TabStyle"
                label="Academic Detail"
                {...a11yProps(2)}
              />
              {/* <Tab className="TabStyle" label="Documents" {...a11yProps(2)} /> */}
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>{" "}
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <LearnerTab
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                learnerEmail,
                setLearnerEmail,
                learnerPhoneNo,
                setLearnerPhoneNo,
                learnerLocation,
                setLearnerLocation,
                learnerAddress,
                setLearnerAddress,
                learnerZipCode,
                setLearnerZipCode,
                learnerCity,
                setLearnerCity,
                learnerState,
                setLearnerState,
                learnerCountry,
                setlearnerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
                schoolName,
                setSchoolName,
                grade,
                setGrade,
                location,
                setLocation,
                image,
                setImage,
                file,
                setFile,
                gender,
                setGender,
              }}
              Tabnext={TabNext}
              imageUpload={imageUpload}
              handleEnter={handleEnter}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ContactTab
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                learnerEmail,
                setLearnerEmail,
                learnerPhoneNo,
                setLearnerPhoneNo,
                learnerLocation,
                setLearnerLocation,
                learnerAddress,
                setLearnerAddress,
                learnerZipCode,
                setLearnerZipCode,
                learnerCity,
                setLearnerCity,
                learnerState,
                setLearnerState,
                learnerCountry,
                setlearnerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
                schoolName,
                setSchoolName,
                grade,
                setGrade,
                location,
                setLocation,
              }}
              Tabnext2={TabNext2}
              handleEnter={handleEnter}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AcademicTab
              states={{
                fullName,
                setFullName,
                dob,
                setDob,
                regNo,
                setRegNo,
                enrollmentDate,
                setEnrollmentDate,
                fathersName,
                setFathersName,
                mothersName,
                setMothersName,
                learnerEmail,
                setLearnerEmail,
                learnerPhoneNo,
                setLearnerPhoneNo,
                learnerLocation,
                setLearnerLocation,
                learnerAddress,
                setLearnerAddress,
                learnerZipCode,
                setLearnerZipCode,
                learnerCity,
                setLearnerCity,
                learnerState,
                setLearnerState,
                learnerCountry,
                setlearnerCountry,
                guardianFullName,
                setGuardianFullName,
                guardianLocation,
                setGuardianLocation,
                guardianEmail,
                setGuardianEmail,
                guardingPhoneNo,
                setGuardingPhoneNo,
                schoolName,
                setSchoolName,
                grade,
                setGrade,
                location,
                setLocation,
                firstTermStartDate,
                setFirstTermStartDate,
                secondTermStartDate,
                setsecondTermStartDate,
                firstTermEndDate,
                setfirstTermEndDate,
                secondTermEndDate,
                setsecondTermEndDate,
                setThirdTermStartDate,
                thirdTermStartDate,
                thirdTermEndDate,
                setThirdTermEndDate,
                rollNumber,
                setRollNumber,
                gradeData,
                gradeIds,
                division,
                setDivision,
              }}
              Tabnext3={TabNext3}
              handleEnter={handleEnter}
              submitClick={submitClick}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DocumentTab submitClick={submitClick} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

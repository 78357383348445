import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState } from "react";
//React-Icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { LOCAL_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Page Name
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreatePageDialog({ folderId, getFolderPages }) {
  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      width: theme.spacing(90),
      height: theme.spacing(37),
    },
  }));

  const { userToken } = useLogin();

  const classes = useStyles();
  const [locmotor, setSchool] = React.useState("");
  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShowMarkInput(false);
    setPageName("");
    setmarks("");
  };
  const [scroll, setScroll] = React.useState("paper");
  const [showMarkInput, setShowMarkInput] = React.useState(false);
  const [marks, setmarks] = useState(null);
  // folder states:
  const [pageName, setPageName] = React.useState("");
  const createPage = async () => {
    if (!pageName) {
      return alert("Please enter the name of the Folder");
    }
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/createPage/${folderId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            pageName,
            isMcq: showMarkInput,
            marks: +marks,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after creating page", res);
        handleClose();
        getFolderPages();
      } else {
        console.log("On resp err on creating page", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <IconButton className="">
        <AiOutlinePlusCircle
          onClick={handleClickOpen}
          style={{
            fontSize: "4rem",
            color: "#cddc39",
            marginLeft: "16px",
            marginTop: "15px",
          }}
        />{" "}
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapMediuemDialog",
        }}
        maxWidth="sm"
        scroll={scroll}
        open={open}
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>

        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle">
            <Grid item md={12}>
              <Typography className="InputTitleTypography  GreyColorTypography width100">
                Name of Page <span className="ImpColor"> *</span>
              </Typography>
              <Input
                className="Input1"
                onChange={(e) => {
                  setPageName(e.target.value);
                }}
              ></Input>
            </Grid>
            <Grid item md={12}>
              <Typography className="InputTitleTypography  GreyColorTypography width100">
                Enter Marks<span className="ImpColor"> *</span>
              </Typography>
              <Input
                style={{ marginTop: "4px" }}
                className="Input1 width100"
                // value={courseTitle}
                onChange={(e) => setmarks(e.target.value)}
              ></Input>
            </Grid>
            <Grid container className="mt-10px">
              <Grid item md={3}>
                <FormControlLabel
                  control={<Checkbox />}
                  // onChange={(e) => storeGrade(e, x, i)}
                  // onChange={(e) => storeGrade(e, x, i)}
                  onChange={() => {
                    setShowMarkInput(!showMarkInput);
                  }}
                  // value={x.grade}
                  className=" AssignActivitesGridPaper  AssignActivites  CheckLabelStyleBlue "
                  label={
                    <Typography style={{ paddingLeft: "15px" }}>
                      {/* {x.grade} */}
                      <span style={{ paddingLeft: "20px" }}>
                        {/* {x.schoolName} */} MCQ
                      </span>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={9} justifyContent="flex-end" alignItems="flex-end">
                <DialogActions className="SGradeBottom">
                  <Button
                    className="GreenButton GradeSaveB "
                    onClick={createPage}
                  >
                    Create
                  </Button>
                </DialogActions>
              </Grid>
              {"  "}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Grid,
  TextareaAutosize,
  Input,
  InputAdornment,
  FormControl,
  Select,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { GoSearch } from "react-icons/go";

//core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Kit Details
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
export default function AddKit(props) {
  const classes = useStyles();
  const { userToken } = useLogin();

  const [open, setOpen] = React.useState(false);
  const [locmotor, setSchool] = React.useState("");
  const [scroll, setScroll] = React.useState("paper");

  const locmotorChange = (event) => {
    setSchool(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEquipmentKitUid("");
    setEquipmentKitName("");
    setEquipmentKitDesc("");
  };
  //For CreateInput
  const [equipmentKitUid, setEquipmentKitUid] = React.useState(null);
  const [equipmentKitName, setEquipmentKitName] = React.useState(null);
  const [equipmentKitDesc, setEquipmentKitDesc] = React.useState(null);

  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // For Checkbox:
  const [originalData, setOriginalData] = React.useState([]);

  const submitClick = async (e) => {
    console.log("addeddItem", sendData);
    const addedEquipment = sendData.map((data) => {
      return {
        equipmentId: data.equipmentId,
        equipmentImg: data.equipmentImg,
        equipmentName: data.equipmentName,
      };
    });
    console.log("addedEquipment", addedEquipment);

    e.preventDefault();
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/equipment/createEquipmentKit`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            equipmentKitUid,
            equipmentKitName,
            equipmentKitDesc,
            addedEquipment,
            // equipmentIds: sendData.map((data) => data.equipmentId),
            // equipmentNames: sendData.map((data) => data.equipmentName),
          }),
        }
      );
      let res = await responce.json();
      // console.log(responce);
      if (responce.ok) {
        console.log("Mah respo =====>", res);
        // fetchUserDetails();
        getAllEquipmentKIT();
        setSnackOpen(true);
        setSnackMsg(res.msg);

        handleClose();
      } else {
        console.log(res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Error while adding Learner =======>", error);
    }
  };
  const {
    states: { equipmentKit },
    equipmentData,
    setEquipmentKit,
    getAllEquipmentKIT,
  } = props;

  const peopleChecked = (event) => {
    setEquipmentKit(
      equipmentKit.map((info) => {
        return { ...info, checked: event.target.checked };
      })
    );
  };

  // function storeEquipment(e, x, index) {
  //   let storeArr = [];
  //   console.log(x, index);
  //   storeArr = originalData;
  //   storeArr[index] = { equipmentId: x._id, equipmentName: x.equipmentName };
  //   storeArr[index].isChecked = e.target.checked;

  //   console.log(storeArr);
  //   setOriginalData(storeArr);

  // setTimeout(() => {
  //   cleanArr(storeArr);
  // }, 2000);
  // }

  function storeEquipment(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }
  //////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined || null) {
      return a;
    }
  });
  /////////////////////////////////////////////////////
  const sendData = filterdata.map((a) => {
    return {
      equipmentId: a._id,
      equipmentName: a.equipmentName,
      equipmentImg: a.equipmentImg,
      isChecked: a.isChecked,
    };
  });
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <IconButton className="HeaderAddButton">
        <IoIosAddCircleOutline className="AddIcon" onClick={handleClickOpen} />
      </IconButton>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
        scroll={scroll}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container direction="Column" className="DailogLTRBStyle">
            <Grid item container style={{ marginLeft: "25px" }}>
              <Grid item md={3} sm={3} xs={3} lg={3}>
                <Typography className="SkillsInputTitle  GreyColorTypography">
                  Equipment KIT UID <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Input
                    className="RubicInputs"
                    value={equipmentKitUid}
                    onChange={(e) => setEquipmentKitUid(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>
              <Grid
                item
                md={7}
                sm={7}
                xs={7}
                lg={7}
                style={{ marginLeft: "70px" }}
              >
                <Typography className="SkillsInputTitle GreyColorTypography">
                  Equipment KIT Name <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid item container md={12} sm={12} xs={12} lg={12}>
                  <Input
                    className="RubicInputs"
                    value={equipmentKitName}
                    onChange={(e) => setEquipmentKitName(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>
              <Typography className="SkillsInputTitle GreyColorTypography">
                Equipment Description <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={11} sm={11} xs={11} lg={11}>
                <TextareaAutosize
                  className="Kit_TextArea"
                  aria-label="minimum height"
                  value={equipmentKitDesc}
                  onChange={(e) => setEquipmentKitDesc(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography className="Kit-Title BlueColorTypography">
                Add Equipments to KIT
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <FormControl className="KItSerachInput">
                <Input
                  disableUnderline
                  className="KitInput"
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton style={{ marginRight: "7px" }}>
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </Grid>
            <Grid
              container
              direction="row"
              style={{ marginBottom: "20px", marginTop: "40px" }}
            >
              {equipmentData &&
                equipmentData.length > 0 &&
                equipmentData.map((name, i) => {
                  return (
                    <Grid item xs={3} style={{ marginTop: "20px" }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="equipmentNames"
                        className="CheckLabelStyleGrey  Kit-CheckBox width100"
                        onChange={(e) => storeEquipment(e, name, i)}
                        value={name.equipmentName}
                        label={
                          <Typography className="width100">
                            {name.equipmentName}
                          </Typography>
                        }
                      />
                    </Grid>
                  );
                })}{" "}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="KitDialogActionBottom">
          <Button
            className="GreenButton700 EquipActionBottom"
            style={{ marginRight: "1.3%" }}
            autoFocus
            onClick={submitClick}
          >
            Save
          </Button>
          <Button
            className="GreyButton700  EquipActionBottom"
            style={{ marginRight: "1%" }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Input,
  Typography,
  Button,
  Grid,
  Box,
  DialogActions,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
import { AiOutlineMinus as RemoveIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function SubSkills(props) {
  const {
    states: {
      qs,
      quesDesc,
      setQuesDesc,
      quesMarks,
      setQuesMarks,
      quesNo,
      setQuesNo,
      quesType,
      setQuesType,
      minValue,
      setMinValue,
    },
    searchSkill,
    submitClick,
    skillsData,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [rubData, setRubData] = useState(qs);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  console.log(rubData, "i am qs");
  return (
    <div>
      <Grid container className="DailogLTRBStyle">
        <Grid
          container
          style={{
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Grid container style={{ gap: "30px" }}>
            <Grid container style={{ gap: "20px", paddingLeft: "44px" }}>
              <Grid item xs={1}>
                <Typography>Q.No</Typography>
                <Grid item md={11} sm={11} xs={11} lg={11}>
                  <Input
                    className="RubicInputs"
                    type="number"
                    id="QuNo"
                    name="quesNo"
                    defaultValue={rubData.quesNo}
                    onChange={(e) => setQuesNo(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>

              <Grid item xs={10}>
                <Typography>Question Description</Typography>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Input
                    className="RubicInputs"
                    id="QuestionName"
                    name="quesDesc"
                    defaultValue={rubData.quesDesc}
                    onChange={(e) => setQuesDesc(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>
              <Grid item xs={2} style={{ marginTop: "5px" }}>
                <Typography style={{ marginBottom: "8px" }}>Marks</Typography>
                <Grid xs={9}>
                  <Input
                    className="RubicInputs"
                    type="number"
                    name="quesMarks"
                    id="MarksName"
                    defaultValue={rubData.quesMarks}
                    onChange={(e) => setQuesMarks(e.target.value)}
                  ></Input>
                </Grid>
              </Grid>
              <Grid item xs={2} style={{ marginTop: "5px" }}>
                <Typography>Type</Typography>
                <Grid xs={9}>
                  <FormControl className={classes.formControl}>
                    <Select
                      className="RubicInputs"
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="quesType"
                      defaultValue={rubData.quesType}
                      onChange={(e) => setQuesType(e.target.value)}
                    >
                      <MenuItem value={1}>Theortical</MenuItem>
                      <MenuItem value={2}>YES/No</MenuItem>
                      <MenuItem value={3}>Not Yet Type</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {rubData.quesType && rubData.quesType === 1 && quesType === 1 && (
                <Grid item xs={2} style={{ marginTop: "5px" }}>
                  <Typography style={{ marginBottom: "8px" }}>
                    Min Value
                  </Typography>
                  <Grid xs={9}>
                    <Input
                      className="RubicInputs"
                      type="number"
                      defaultValue={rubData.minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                      name="minValue"
                      id="minValue"
                    ></Input>
                  </Grid>
                </Grid>
              )}
              {rubData.quesType && rubData.quesType !== 1 && quesType === 1 && (
                <Grid item xs={2} style={{ marginTop: "5px" }}>
                  <Typography style={{ marginBottom: "8px" }}>
                    Min Value
                  </Typography>
                  <Grid xs={9}>
                    <Input
                      className="RubicInputs"
                      type="number"
                      defaultValue={rubData.minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                      name="minValue"
                      id="minValue"
                    ></Input>
                  </Grid>
                </Grid>
              )}
              {/* {quesType && quesType === 1 && (
                <Grid item xs={2} style={{ marginTop: "5px" }}>
                  <Typography style={{ marginBottom: "8px" }}>
                    Min Value
                  </Typography>
                  <Grid xs={9}>
                    <Input
                      className="RubicInputs"
                      type="number"
                      onChange={(e) => setMinValue(e.target.value)}
                      name="minValue"
                      id="minValue"
                    ></Input>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
          </Grid>
        </Grid>

        <DialogActions className="DialogActionBottom ">
          <Button
            className="GreenButton700   RubricsActionButton "
            onClick={submitClick}
          >
            Save
          </Button>
        </DialogActions>
      </Grid>
    </div>
  );
}

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";

const TrainerTabPanel = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(); //
  const loadUsers = async () => {
    const res = await axios.get(
      "https://jsonplaceholder.typicode.com/comments "
    );
    setPosts(res.data);
    setTotal(res.data.length);
  };

  useEffect(() => {
    loadUsers();
  }, []);
  return (
    <>
      <Grid container>
        <Grid container item>
          <div className="ResponsiveTableLeaderBoard width_98">
            <Table className="LeaderTableroot border_primary">
              <TableHead>
                <TableRow className="HeadAL">
                  <TableCell className="LeaderColHeadBlack"> Rank </TableCell>
                  <TableCell className="LeaderColHeadBlack">Name</TableCell>

                  <TableCell className="LeaderColHeadBlack">School </TableCell>
                  <TableCell className="LeaderColHeadBlack">
                    {" "}
                    Location
                  </TableCell>
                  <TableCell className="LeaderColHeadBlack">
                    Playpoints
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {posts.slice(page, rowsPerPage).map((post) => (
                  <TableRow>
                    <TableCell className="LeaderCol700WAB">
                      {/* <TiArrowSortedUp
                  style={{
                    marginRight: "3px",
                    padding: "2px",
                    fontSize: "20px",
                  }}
                /> */}
                      4{" "}
                    </TableCell>
                    <TableCell className="LeaderCol700WAB">
                      Horizon Public School
                    </TableCell>
                    <TableCell className="LeaderCol700WAB">Dwarka </TableCell>{" "}
                    <TableCell className="LeaderCol700WAB">Delhi</TableCell>
                    <TableCell className="LeaderCol700WAB">CBSE</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TrainerTabPanel;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
// ReactIcons
import { MdEdit } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
//Core Component
// import LPDelete from "../ActionDialogs/LearnerActionButton's/LearnerDelete";
// import LPSwitch from "../ActionDialogs/LearnerActionButton's/LearnerSwitch";
// import AssignLearner from "../ActionDialogs/LearnerActionButton's/AssignLearner/LearnerMainDialog";
// import AssignSchool from "../ActionDialogs/LearnerActionButton's/LearnerAssignSchool/LASchoolMainDialog";
// import AssignGrade from "../ActionDialogs/LearnerActionButton's/LearnerGradeDialog/LPGradeMainDialog";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function PublieshedTable(props) {
  const {
    states: { foldername, total, setTotal, pageNo, setPageNo },
    TabNext,
    GetFolder,
  } = props;
  console.log(foldername);
  const { userToken } = useLogin();

  const [access, setAccess] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    console.log("Page size ========>", current, pageSize);
    setRowsPerPage(pageSize);
    // GetFolder("undefined", null, pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return orignalElement;
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head"> Folder Name </TableCell>
              <TableCell className="Head"> PP </TableCell>
              <TableCell className="Head"> Created On</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {foldername &&
              foldername.length > 0 &&
              foldername.map((data, index) => {
                let pp = 0;
                data.pages.map((data) => {
                  pp = pp + parseInt(data?.marks);
                });
                return (
                  <TableRow>
                    <TableCell className="Col">{data.folderName}</TableCell>
                    <TableCell className="Col">{pp}</TableCell>
                    <TableCell className="Col">
                      {" "}
                      {data?.createdAt?.split("T")[0]}
                    </TableCell>
                    <TableCell className="Action">
                      {/* <Link to="/admin/addLessonPlan">
                    <Tooltip title="Edit">
                      <IconButton className="ActionButtonSize">
                        <MdEdit className="ActionButtonIcon" />
                      </IconButton>
                    </Tooltip>
                  </Link> */}
                      {/* <IconButton className="ActionButtonSize">
                    <AssignSchool />
                  </IconButton>
                  <IconButton className="ActionButtonSize">
                    <AssignGrade />
                  </IconButton>
                  <IconButton className="ActionButtonSize">
                    <AssignLearner />
                  </IconButton> */}
                      {/* <IconButton className="ActionButtonSize">
                    <GiTeacher className="ActionButtonIcon" />
                  </IconButton> */}
                      <IconButton className="ActionButtonSize">
                        <Link
                          to={{
                            pathname: "/admin/addLessonPlan",
                            data: { GetFolder: GetFolder },
                            folderIdFromPublishedTable: data._id,
                          }}
                          // state={{ folderIdFromPublishedTable: data._id }}
                        >
                          <IoIosAddCircleOutline
                            className="ActionAddButton"
                            fontSize="1.6rem"
                          />
                        </Link>
                      </IconButton>
                      {/* <IconButton className="ActionButtonSize">
                    <LPSwitch accessCheck={access} />
                  </IconButton>
                  <IconButton className="ActionButtonSize">
                    <LPDelete />
                  </IconButton> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {foldername?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No LessonPlan Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {foldername?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              GetFolder(value, value2);
              setPageNo(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={pageNo}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

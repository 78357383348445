import React, { useState } from "react";
import {
  Input,
  Button,
  Grid,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { BsDownload } from "react-icons/bs";
import { BiSort } from "react-icons/bi";
import UploadAgreement from "./UploadAgreement";

export default function ExtendTab({
  data,
  schoolAgreement,
  setSchoolAgreement,
}) {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  let dataNew = schoolAgreement?.file?.split("-");
  // dataNew.splice(0, 1);
  if (dataNew) {
    dataNew.shift(0, 1);
    dataNew = dataNew.join("-");
  }

  return (
    <>
      {/* <Grid container justify="center" spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          md={5}
          sm={5}
          xs={5}
          lg={5}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <DropzoneArea
            filesLimit={3}
            maxFileSize={2097152}
            acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
            useChipsForPreview={true}
            dropzoneText="Upload Docs"
          />
        </Grid>
      </Grid> */}
      <div className="ResponsiveTableAL">
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <UploadAgreement
            schoolId={data._id}
            schoolAgreement={schoolAgreement}
            setSchoolAgreement={setSchoolAgreement}
            schoolUId={data.schoolUid}
          />
        </div>
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="HeadAL ">
              <TableCell className="ColHeadALBLue ">
                Document Name
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>
              <TableCell className="ColHeadALBLue">
                Uploaded on
                <IconButton disableRipple className="SortIocn">
                  <BiSort className="SortIocnColor" />
                </IconButton>
              </TableCell>

              <TableCell className="ColHeadALBLue"> Action</TableCell>
            </TableRow>
          </TableHead>
          {data.agreement != undefined && (
            <TableBody>
              <TableRow>
                <TableCell className="Col700">
                  {/* {data?.agreement?.file?.split("-")[1]} */}
                  {dataNew}
                </TableCell>
                <TableCell className="Col700">
                  {data?.agreement?.uploadOn?.split("T")[0]}
                </TableCell>
                {/* <TableCell className="Col700"> 2 MB </TableCell> */}
                <TableCell className="Action">
                  <a href={data?.agreement?.file}>
                    <IconButton className="ActionButtonSize">
                      <BsDownload color="#cddc39" />
                    </IconButton>
                  </a>
                  {/* <IconButton className="ActionButtonSize">
        <DeleteDialog />
      </IconButton> */}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        {data.agreement == undefined || null ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Agreement Found !
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
    </>
  );
}

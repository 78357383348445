import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  Tab,
  Tabs,
  Radio,
  RadioGroup,
  Box,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  FormControlLabel,
  FormControl,
  Tooltip,
} from "@material-ui/core";
//React Icpns
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

//core Component
import SkillAssesmentDetails from "./ActivityEditTab/SCEdit/SkillAssesmentDetails";
import SkillRubrics from "./ActivityEditTab/SCEdit/SkillRubrics";
import SkillChoose from "./ActivityEditTab/SCEdit/SkillChoose";
import ActivityChoose from "./ActivityEditTab/CAEdit/ActivityChoose";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog() {
  const classes = useStyles();
  const [radiovalue, setRadioValue] = React.useState("Skill");

  const RadioChange = (event) => {
    console.log("Radio Check", event.target.value);
    setRadioValue(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabNext() {
    setValue(1);
  }
  function TabNext2() {
    setValue(2);
  }
  //For CreateInput
  const [uid, setUid] = useState(null);
  const [assessmentName, setAssessmentName] = useState(null);
  const [assessmentDesc, setAssessmentDesc] = useState(null);
  const [assignedXp, setAssignedXp] = useState(null);
  const [level, setLevel] = useState(null);
  const [rubricsQts, setRubricsQts] = useState(null);
  const [skills, setSkills] = useState(null);
  const [activities, setactivities] = useState(null);

  //     "rubricsQts":[
  //         { "quesNo":1,
  //     "quesDesc":"What do you mean by diving",
  //     "quesMarks":2
  //         },
  //         { "quesNo":2,
  //     "quesDesc":"What do you mean by swimming",
  //     "quesMarks":4
  //         }
  //       ]
  //Api Calling
  const submitClick = async (e) => {
    console.log("Skill----------");
    e.preventDefault();
  };

  return (
    <div>
      <Tooltip title="Edit">
        <IconButton className="ActionButtonSize">
          <MdEdit className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        scroll={scroll}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              centered
              onChange={TabChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="TabStyle"
                label="Chose Skill or Activity"
                {...a11yProps(0)}
              />
              <Tab
                className="TabStyle"
                label="Assesment Details"
                {...a11yProps(1)}
              />
              <Tab className="TabStyle" label="Rubrics" {...a11yProps(2)} />
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>
        </DialogTitle>

        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <div className="RadioStyleSkill">
              <FormControl component="fieldset">
                <RadioGroup
                  defaultValue={radiovalue}
                  row
                  onChange={(e) => RadioChange(e)}
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Skill"
                    control={<Radio />}
                    label="Skills"
                    className=" GreyColorTypography RadioStyleTypo"
                  />
                  <FormControlLabel
                    value="activity"
                    control={<Radio />}
                    label="Activity"
                    className=" GreyColorTypography RadioStyleTypo"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {radiovalue === "Skill" ? (
              <SkillChoose
                states={{
                  uid,
                  setUid,
                  assessmentName,
                  setAssessmentName,
                  assessmentDesc,
                  setAssessmentDesc,
                  assignedXp,
                  setAssignedXp,
                  level,
                  setLevel,
                  rubricsQts,
                  setRubricsQts,
                  skills,
                  setSkills,
                  activities,
                  setactivities,
                }}
              />
            ) : (
              <ActivityChoose
                states={{
                  uid,
                  setUid,
                  assessmentName,
                  setAssessmentName,
                  assessmentDesc,
                  setAssessmentDesc,
                  assignedXp,
                  setAssignedXp,
                  level,
                  setLevel,
                  rubricsQts,
                  setRubricsQts,
                  skills,
                  setSkills,
                  activities,
                  setactivities,
                }}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SkillAssesmentDetails
              states={{
                uid,
                setUid,
                assessmentName,
                setAssessmentName,
                assessmentDesc,
                setAssessmentDesc,
                assignedXp,
                setAssignedXp,
                level,
                setLevel,
                rubricsQts,
                setRubricsQts,
                skills,
                setSkills,
                activities,
                setactivities,
              }}
              TabNext={TabNext2}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SkillRubrics
              states={{
                rubricsQts,
                setRubricsQts,
              }}
              submitClick={submitClick}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            className="GreenButton   EquipSaveButton"
            onClick={value == 0 ? TabNext : value == 1 ? TabNext2 : null}
          >
            {value === 2 ? "Save" : "Next"}
          </Button>
          <Button
            className=" GreyButton   EquipCancelButton "
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

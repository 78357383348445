import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  InputAdornment,
  FormControl,
  IconButton,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  FormGroup,
  Box,
} from "@material-ui/core";
//React Icons
import { GoSearch } from "react-icons/go";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { useState } from "react";
export default function SelectSchoolTab(props) {
  const {
    states: { learnerData, setLearenerData, originalData, selectLearnerText },
    storeSkill,
    getAllLearner,
    filterdata,
  } = props;
  const { userToken } = useLogin();
  //////////////////////////////////////////api for learner ///////////////////////////
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState([]);
  const [selectGrade, setSelectGrade] = useState([]);
  const [gradeSelected, setGradeSelected] = useState("");
  ///////////////////////////////////////////////////////////////////
  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  /////getPaginatedLearner
  const getPaginatedLearner = async (grade) => {
    console.log(schoolSelected, "schoolSelected>>>>>>>>>>>>>>>>>>");
    console.log(
      schoolLocationSelected,
      "schoolLocationSelected>>>>>>>>>>>>>>>>>>"
    );
    console.log(grade, "grade>>>>>>>>>>>>>>>>>>");

    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated learners==>", res);
        setLearenerData(res.msg);
        // setAssignLearnerId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////

  /////////////////////handle search//////////////////////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      getAllLearner();
    }
    console.log("My handling of search ========>", rowSize);
    const response = await fetch(
      `${FETCH_URL}/api/learner/searchLearner/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLearenerData(res.msg);
      console.log("My learner response from searching ==>", res);

      // getAllGrades();
    } else {
      console.log("Error while searching single school", res.err);
    }
  };
  /////////////////////handle search//////////////////////////////////////////////

  React.useEffect(() => {
    getDistinctSchoolAndLocation();
  }, []);

  console.log("get data from assign from learner ", learnerData);
  return (
    <>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{ marginLeft: "2%" }}
        >
          <Grid item container>
            <Grid item container className="ParticipantStyleSelect">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" DialogSelectdropstyle"
                  //   value={school ? school : 0}
                  //   onChange={handleChange}
                  value={schoolSelected ? schoolSelected : 0}
                  onChange={(e) => {
                    getDistinctSchoolAndLocation(e.target.value);
                    setSchoolSelected(e.target.value);
                  }}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select school
                  </MenuItem>
                  {schoolNames &&
                    schoolNames.length > 0 &&
                    schoolNames.map((school) => (
                      <MenuItem value={school._id} className="Selectmenustyle">
                        {school._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="ParticipantStyleSelect1">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={schoolLocationSelected ? schoolLocationSelected : 0}
                  onChange={(e) => {
                    setSchoolLocationSelected(e.target.value);
                    getGrades(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Location
                  </MenuItem>
                  {schoolLoc &&
                    schoolLoc.length > 0 &&
                    schoolLoc.map((location) => (
                      <MenuItem
                        value={location._id}
                        className="Selectmenustyle"
                      >
                        {location._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="ParticipantStyleSelect1">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={gradeSelected ? gradeSelected : 0}
                  onChange={(e) => {
                    getPaginatedLearner(e.target.value);
                    setGradeSelected(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Grade
                  </MenuItem>
                  {selectGrade &&
                    selectGrade.length > 0 &&
                    selectGrade.map((a) => (
                      <MenuItem className="Selectmenustyle" value={a._id}>
                        {a._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Typography className="GreyColorTypography   OrStyleTypography">
              OR
            </Typography>{" "}
            <div style={{ display: "flex", marginLeft: "8px" }}>
              <Box style={{ marginTop: "10px" }}>
                <div>
                  <FormControl className="AssignLPinput">
                    <Input
                      className="input"
                      disableUnderline
                      placeholder="Search"
                      onChange={(e) => {
                        console.log("Search Value =========>", e.target.value); // Add debouncing for this
                        handleSearch(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className="HeadSearchButton">
                            <GoSearch className=" HaedAddIcon" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Serach"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </Grid>
          {learnerData &&
            learnerData.length > 0 &&
            learnerData.map((x, i) => (
              <Grid
                container
                item
                md={4}
                direction="row"
                style={{ marginTop: "15px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        originalData.length > 0 &&
                        originalData[i] !== null &&
                        originalData[i] !== undefined
                          ? true
                          : false
                      }
                    />
                  }
                  onChange={(e) => storeSkill(e, x, i)}
                  value={x.learnerDetail.fullName}
                  className=" AssignActivitesGridPaper  AssignActivites  CheckLabelStyleBlue "
                  label={
                    <Typography style={{ paddingLeft: "15px" }}>
                      {x.learnerDetail.fullName}
                      <span style={{ paddingLeft: "20px" }}>
                        {x.learnerDetail.regNo}
                      </span>
                    </Typography>
                  }
                />
              </Grid>
            ))}
          {filterdata.length === 0 && (
            <Grid container style={{ marginTop: "10px" }}>
              <Typography className="warningTypography">
                {selectLearnerText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

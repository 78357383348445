import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  Box,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  Typography,
  Toolbar,
  Grid,
  Input,
  InputAdornment,
  Tab,
  Tabs,
} from "@material-ui/core";
// material-ui icons
import { IoIosAddCircleOutline } from "react-icons/io";
import { BsUpload } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
// core components
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import LearnerTab from "./PLessonTable/LearnerTab";
import TrainerTab from "./PLessonTable/TrainerTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables(props) {
  const [value, setValue] = React.useState(0);
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [lessonPlans, setLessonPlans] = useState([]);
  const [trainerlessonPlans, setTrainerLessonPlans] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  ///////////////////////////////lesson plan api//////////////////////////

  const getAllLessonPlans = async (query) => {
    loadingHandler(true);
    let url;
    if (query == "isForLearner") {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&schoolId=${schoolDetail?.schoolId}`;
    } else if (query == "isForTrainer") {
      url = `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&schoolId=${schoolDetail?.schoolId}`;
    }
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        if (value == 0) {
          setLessonPlans(res.msg);
        } else {
          setTrainerLessonPlans(res.msg);
        }
      } else {
        loadingHandler(false);
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };

  ////////////////////////handleSearch///////////////////////////////////////////

  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (value === 0) {
      if (searchValue.length === 0) {
        getAllLessonPlans("isForLearner");
      }
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForLearner=true&page=${
          pageNum ? pageNum : 1
        }&search=${searchValue}&limit=${rowSize ? rowSize : 10}&schoolId=${
          schoolDetail?.schoolId
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlans(res.msg);
        console.log("My response from searching ==>", res);
      } else {
        console.log("Error while searching  ", res.err);
      }
    } else {
      if (searchValue.length === 0) {
        getAllLessonPlans("isForTrainer");
      }
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true&page=${
          pageNum ? pageNum : 1
        }&search=${searchValue}&limit=${rowSize ? rowSize : 10}&schoolId=${
          schoolDetail?.schoolId
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setTrainerLessonPlans(res.msg);
        console.log("My response from searching ==>", res);
      } else {
        console.log("Error while searching  ", res.err);
      }
    }
  };
  ////////////////////////handleSearch///////////////////////////////////////////
  useEffect(() => {
    if (value == 0) {
      getAllLessonPlans("isForLearner");
    } else {
      getAllLessonPlans("isForTrainer");
    }
    setSearchVal("");
  }, [value]);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Lesson Plans</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  className="input"
                  disableUnderline
                  placeholder="Search"
                  value={searchVal}
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
        </div>
      </Toolbar>

      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Learner"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Trainer"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <LearnerTab
          handleSearch={handleSearch}
          getAllLessonPlans={getAllLessonPlans}
          lessonPlans={lessonPlans}
          setLessonPlans={setLessonPlans}
          states={{ value, setValue }}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TrainerTab
          trainerlessonPlans={trainerlessonPlans}
          setTrainerLessonPlans={setTrainerLessonPlans}
        />
      </TabPanel>
    </>
  );
}

import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Tooltip,
} from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useParams, useLocation } from "react-router-dom";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import Page from "../.../../../../../src/assets/img/page.png";
import CreatePageDialog from "./CreatePageDialog";
import MenuForPageDialog from "./MenuForPageDialog";
// Icons
const emails = ["username@gmail.com", "user02@gmail.com"];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
    fontSize: 12,
  },
}));

export default function App() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const classes = useStyles();

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const [page, setPage] = useState([{ firstPage: "1" }]);
  const [pages, setPages] = useState([]);
  const { folderId } = useParams();
  const { userToken } = useLogin();
  const location = useLocation();

  const { folders, folder } = location.state;
  console.log("Check Clone Msg location", folder);

  const handleAddFields = () => {
    setPage([...page, { firstPage: "" }]);
  };

  React.useEffect(() => {
    getFolderPages();
  }, []);
  // Get All Pages
  const getFolderPages = async () => {
    const response = await fetch(
      `${FETCH_URL}/api/lessonplan/getFolderPages/${folderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      console.log("On response arrived after getting pages", res);
      setPages(res.msg.pages);
    } else {
      console.log("On resp err on getting pages", res.err);
    }
  };

  //Published Api Methods
  const putpublishedFolder = async () => {
    const response = await fetch(`${FETCH_URL}/api/lessonplan/publishFolder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        folderId: folderId,
        folderName: folder?.folderName,
      }),
    });
    const res = await response.json();
    if (response.ok) {
      console.log("On response arrived after getting pages", res);
      setSnackOpen(true);
      setSnackMsg(res.msg);
    } else {
      console.log("On resp err on getting pages", res.err);
      setSnackerropen(true);
      setSnackErrMsg(res.err);
    }
  };
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);

  const snackClick = () => {
    setSnackOpen(true);
  };

  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };

  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  console.log("Check No OF PAge", pages);
  const PageID = pages.map((title) => title?._id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    console.log("clicked");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12}>
          <Link to="/admin/CaretorMode">
            <div className="BackHead">
              <IoIosArrowBack className="BackIcon" />
              <Button disableRipple className="Backbutton ">
                Back
              </Button>{" "}
            </div>
          </Link>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Button disableRipple className="Breadcrumbbutton">
                <Link to="/admin/Lesson" className="LinkColor">
                  LessonPlan
                </Link>
              </Button>
              <div className="Breadcrumb2">
                <Link className="LinkColor" to="/admin/CaretorMode">
                  Creator Mode
                </Link>
              </div>
              <div className="Breadcrumb2">{folder?.folderName} </div>
            </Breadcrumbs>
          </div>
          <Grid container item className="NOFContainer">
            <Typography className="GreyColorTypography  NNOFItem">
              Name of Pages
            </Typography>
            <Typography> </Typography>
            <Typography className=" PublsiheTypo">
              <Button
                onClick={putpublishedFolder}
                className=" GreenButton700 PublsiheButton"
              >
                Publish
              </Button>
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="left"
            className=" FolderContainer"
          >
            <Grid container className="FolderContainer">
              <Grid item conatiner className="AddPageIcon">
                <CreatePageDialog
                  folderId={folderId}
                  getFolderPages={getFolderPages}
                />
              </Grid>
              {pages &&
                pages.length > 0 &&
                pages.map((page, index) => (
                  <Grid item className="PageGridItem" key={index}>
                    <Link
                      to={{
                        pathname: `/grapes/editor/${page._id}`,
                        state: { folderId, page, folders, folder },
                      }}
                    >
                      <div className="Icon2">
                        <img
                          src={Page}
                          style={{
                            width: "44px",
                            height: "55px",
                            marginTop: "10px",
                          }}
                        />
                        <div className="Icon3"></div>
                      </div>
                    </Link>
                    <div className={classes.root}>
                      <div>
                        <Tooltip
                          title={page?.pageName}
                          aria-label={page?.pageName}
                        >
                          <Typography className="GreyColorTypography folderTypostyle   ml-5px">
                            {page?.pageName}
                          </Typography>
                        </Tooltip>
                      </div>

                      {/* <MoreVertIcon
                        onClick={handleClickOpen}
                        style={{ fontSize: "20px" }}
                      /> */}

                      <MenuForPageDialog
                        folders={folders}
                        PageID={page._id}
                        getFolderPages={getFolderPages}
                        page={page}
                        folderId={folderId}
                        setSnackOpen={setSnackOpen}
                        setSnackMsg={setSnackMsg}
                        setSnackerropen={setSnackerropen}
                        setSnackErrMsg={setSnackErrMsg}
                      />
                    </div>
                    {/*                     
                    <DialogActions style={{ padding: "0px" }}>
                      <div className={classes.root}>
                        <div>
                          <Typography
                            className="GreyColorTypography ml-5px"
                            style={{ fontSize: "12px" }}
                          >
                            {page.pageName}
                          </Typography>
                        </div>
                        <div>
                          <MoreVertIcon
                            onClick={handleClickMenu}
                            style={{ fontSize: "20px" }}
                          />
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 3.5,
                                width: "20ch",
                              },
                            }}
                          >
                            <MenuItem onClick={handleClose}>
                              <ClonePage
                                folders={folders}
                                PageID={page._id}
                                getFolderPages={getFolderPages}
                              />
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <RenamePage
                                PageID={page._id}
                                page={page}
                                getFolderPages={getFolderPages}
                              />
                            </MenuItem>

                            <MenuItem onClick={handleClose}>
                              <DeletePage
                                states={{
                                  setSnackOpen,
                                  setSnackMsg,
                                  setSnackerropen,
                                  setSnackErrMsg,
                                }}
                                PageID={page._id}
                                folderId={folderId}
                                getFolderPages={getFolderPages}
                              />
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </DialogActions> */}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={2}></Grid> */}
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import {
  IconButton,
  Button,
  Breadcrumbs,
  Grid,
  Typography,
  Select,
  Input,
  FormControl,
  MenuItem,
  Toolbar,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
//ReactIcons
import { IoIosArrowBack } from "react-icons/io";
import { GoSearch } from "react-icons/go";

//core component
import GradeProfileTab from "./GradePMainPageTab";
import { useLogin } from "Context/ExportContext";
export default function GradeProfile() {
  const { departmentDetail } = useLogin();
  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const history = useHistory();
  const locationdata = useLocation()?.state?.data;

  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div>
      <Grid container>
        <Grid item md={3} sm={3} xs={3} lg={3}>
          <Typography className="Title">
            {departmentDetail?.departmentName}
          </Typography>{" "}
        </Grid>
        {/* <Grid item md={2} sm={2} xs={2} lg={2}>
          <Grid
            item
            xs={9}
            style={{ justifyContent: "center", marginTop: "25px" }}
          >
            <FormControl variant="standard" className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Pune
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  Blue Ridge Public School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>{" "}
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2}>
          <Grid
            item
            xs={9}
            style={{ justifyContent: "center", marginTop: "25px" }}
          >
            <FormControl variant="standard" className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={location ? location : 0}
                onChange={locChange}
                label="Select School"
                color="white"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0}>
                  Grade 1
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={10}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={20}>
                  Blue Ridge Public School
                </MenuItem>
                <MenuItem className="Eventmenustyle" value={30}>
                  Blue Ridge Public School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>{" "}
        <Grid container item md={3} sm={3} xs={3} lg={3}></Grid>
        <Grid item md={2} sm={2} xs={2} lg={2}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
        </Grid> */}
      </Grid>
      <GradeProfileTab gradeId={locationdata?._id} gradeData={locationdata} />
    </>
  );
}

import React, { useState } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
  Grid,
} from "@material-ui/core";
// ReactIcons
//Core Component0
import LPSwitch from "../ActionDialogs/TrainerActionButton's/TrainerSwitch";
import AssignTrainer from "../ActionDialogs/TrainerActionButton's/AssignTrainer/TrianerAssignMainDialog";
import AddToMarketPlace from "../ActionDialogs/TrainerActionButton's/AddLearnerToMarketPlace";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LearnerTab(props) {
  const {
    states: {
      foldername,
      trainerlessonPlans,
      setTrainerLessonPlans,
      pageNoT,
      setPageNoT,
      totalT,
      setTotalT,
      rowsPerPageT,
      setRowsPerPageT,
      searchDataT,
      setSearchDataT,
    },
    GetFolder,
    getTrainerLessonPlan,
    TrainerPaginatedData,
  } = props;

  const [value, setValue] = React.useState(0);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(4);
  const [radiovalue, setRadioValue] = React.useState("Skill");

  // const [trainerlessonPlans, setTrainerLessonPlans] = useState([]);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPageT(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  // useEffect(() => {
  //   getTrainerLessonPlan();
  // }, []);
  console.log(" Check Data of Trainers", trainerlessonPlans);
  // const getTrainerLessonPlan = async () => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/lessonplan/getAllLessonPlans?isForTrainer=true`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       setTrainerLessonPlans(res.msg);
  //       // console.log("Lesson Plans Skills =======>", res.msg[0]);
  //     } else {
  //       console.error("API error =====>", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block ====>", error);
  //   }
  // };

  /////////////////////////////////////////////////////// Status Chnage Api //////////////////////////
  ///api/lessonplan/changeLessonPlanStatus/:lessonPlanId

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="warning">
          This service is currently not available !
        </Alert>
      </Snackbar>

      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID </TableCell>
              <TableCell className="Head"> Lesson Plan Name </TableCell>
              <TableCell className="Head"> Assigned PP</TableCell>
              <TableCell className="Head"> Created On</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {trainerlessonPlans &&
              trainerlessonPlans.length > 0 &&
              trainerlessonPlans.map((lp) => {
                let newDate = new Date(lp.createdOn);

                return (
                  <TableRow>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: "/admin/lessonplantrainerprofile",
                          state: { lp },
                        }}
                      >
                        {" "}
                        {lp.LPUid}{" "}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">
                      <Link
                        to={{
                          pathname: "/admin/lessonplantrainerprofile",
                          state: { lp },
                        }}
                      >
                        {lp.LPTitle}
                      </Link>
                    </TableCell>
                    <TableCell className="Col">{lp.LPXp}</TableCell>
                    <TableCell className="Col">
                      {/* {lp.createdOn.split("T")[0]} */}
                      {newDate.getDate()} /{newDate.getMonth() + 1}/{" "}
                      {newDate.getFullYear()}
                    </TableCell>
                    <TableCell className="Action">
                      <AddToMarketPlace Trainerlp={lp} />

                      <IconButton className="ActionButtonSize">
                        <AssignTrainer
                          getTrainerLessonPlan={getTrainerLessonPlan}
                          TrainerId={lp._id}
                        />
                      </IconButton>
                      {/* <IconButton className="ActionButtonSize">
                        <LPSwitch
                          trainerId={lp._id}
                          getTrainerLessonPlan={getTrainerLessonPlan}
                          access={lp.access}
                        />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {trainerlessonPlans?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No LessonPlan Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {searchDataT || trainerlessonPlans?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, pageSize) => {
              TrainerPaginatedData(value, pageSize);
              setPageNoT(value);
            }}
            pageSize={rowsPerPageT}
            total={totalT}
            current={pageNoT}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

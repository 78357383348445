import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  RadioGroup,
  Radio,
  useRadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <FormControlLabel checked={checked} {...props} />;
}
export default function RenameTab(props) {
  const {
    states: {
      LPUid,
      setLPUid,
      LPStartDate,
      setLPStartDate,
      LPEndDate,
      setLPEndDate,
      LPTitle,
      setLPTitle,
      lessonPlanTitleText,
      lessonplanUidText,
    },
  } = props;

  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  GreyColorTypography">
              LessonPlan UID<span className="ImpColor"> * </span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                value={LPUid}
                onChange={(e) => setLPUid(e.target.value)}
              ></Input>
            </Grid>
            {LPUid.length === 0 && (
              <Typography className="warningTypography">
                {lessonplanUidText}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="InputTitleTypography  GreyColorTypography">
            LessonPlan Title<span className="ImpColor"> * </span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              value={LPTitle}
              onChange={(e) => setLPTitle(e.target.value)}
            ></Input>
          </Grid>
          {LPTitle.length === 0 && (
            <Typography className="warningTypography">
              {lessonPlanTitleText}
            </Typography>
          )}
        </Grid>
        {/* <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  BlueColorTypography">
              Start Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="MM/dd/yyyy"
                    value={LPStartDate}
                    onChange={setLPStartDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography className="InputTitleTypography  BlueColorTypography">
              End Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="MM/dd/yyyy"
                    value={LPEndDate}
                    onChange={setLPEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{
            marginLeft: "25px",
            marginBottom: "308px ",
            marginTop: "10px",
          }}
        >
          <RadioGroup
            className="RadioStyle"
            name="use-radio-group"
            defaultValue="first"
          >
            <MyFormControlLabel
              className=" BlueColorTypography RadioLabel"
              value="first"
              defaultValue="first"
              label={
                <Typography>
                  Correct Response Required{" "}
                  <span style={{ color: "red" }}> * </span>
                  <span
                    className="GreyColorTypography400"
                    style={{ fontSize: "13px" }}
                  >
                    ( Correct Response Required to Move to Next Lesson )
                  </span>
                </Typography>
              }
              control={<Radio />}
            />
          </RadioGroup>
        </Grid> */}
      </Grid>
    </>
  );
}

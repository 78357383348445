import React, { useState, useEffect } from "react";
import {
  Input,
  Typography,
  Button,
  Grid,
  Box,
  DialogActions,
  TextField,
  IconButton,
} from "@material-ui/core";
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
import { AiOutlineMinus as RemoveIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
export default function SubSkills(props) {
  const {
    states: { rubricsQts, setRubricsQts },
  } = props;

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // const [rubricsQts, setRubricsQts] = React.useState([
  //   { quesNo: "", quesDesc: "", quesMarks: "" },
  // ]);

  const { CreateSkills } = props;
  const handleAddFields = (e) => {
    // setOptions([...options, { id: uuidv4(), option: "", optionNo: "" }]);
    setRubricsQts([
      ...rubricsQts,
      { quesNo: "", quesDesc: "", quesMarks: "" * 1 },
    ]);
  };

  const handleRemoveFields = (e, i) => {
    // const values = [...options];
    // values.splice(
    //   values.findIndex((value) => value.id === id),
    //   1
    // );
    // setOptions(values);
    const inputList = [...rubricsQts];
    inputList.splice(i, 1);
    setRubricsQts(inputList);
  };
  const [options, setOptions] = useState([
    { id: uuidv4(), option: "", optionNo: "" },
  ]);

  console.log(" Check options: ");
  useEffect(() => {
    if (options.length > 10) {
      alert("Can't add more than 10 questions");
    }
    console.log("options ===> ", options);
  }, [options]);

  // const [multiInput, setmultiInput] = useState([
  //   { quesNo: "", quesDesc: "", quesMarks: "" },
  // ]);

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const inputList = [...rubricsQts];
    inputList[i][name] = value;
    setRubricsQts(inputList);
  };
  console.log(rubricsQts, "yohhhhhh");
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className="DailogLTRBStyle">
          {options.slice(0, 10).map((option, index) => (
            <div key={option.id} style={{ marginTop: "20px", width: "100%" }}>
              {rubricsQts.map((x, i) => {
                return (
                  <Grid container>
                    <Grid item md={1} sm={1} xs={1} lg={1}>
                      <Typography className="GreyColorTypography QNoTypo">
                        QuNo
                      </Typography>
                      <Grid
                        item
                        md={11}
                        sm={11}
                        xs={11}
                        lg={11}
                        className="QNoInput"
                      >
                        <Input
                          className="RubicInputs"
                          id="QuNo"
                          name="quesNo"
                          onChange={(e) => handleInputChange(e, i)}
                          // value={rubricsInput}
                          // onChange={(e) => {
                          //   // add to list
                          //   setRubricsInput([
                          //     ...rubricsQts,
                          //     {
                          //       quesNo: " ",
                          //     },
                          //   ]);
                          // }}
                        ></Input>
                      </Grid>
                    </Grid>
                    <Grid item md={7} sm={7} xs={7} lg={7}>
                      <Typography className="GreyColorTypography  QuesTypo">
                        QuestionName
                      </Typography>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        className="QuestionInput"
                      >
                        <Input
                          className="RubicInputs"
                          id="QuestionName"
                          name="quesDesc"
                          onChange={(e) => handleInputChange(e, i)}
                          // value={rubricsInput}
                          // onChange={(e) => setRubricsInput(e.target.value)}
                        ></Input>
                      </Grid>
                    </Grid>
                    <Grid item md={2} sm={2} xs={2} lg={2}>
                      <Typography className="GreyColorTypography TotalTypo ">
                        Question Marks
                      </Typography>
                      <Grid xs={9} className="TotalInput">
                        <Input
                          className="RubicInputs"
                          name="quesMarks"
                          id="MarksName"
                          onChange={(e) => handleInputChange(e, i)}
                        ></Input>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      lg={2}
                      style={{
                        alignItems: "center",
                        alignSelf: "center",
                        paddingTop: "34px",
                        paddingLeft: "20px",
                      }}
                    >
                      <IconButton
                        style={{ marginRight: "10px" }}
                        className="GreyButton700"
                        disabled={option.length === 1}
                        onClick={(e) => handleRemoveFields(e, i)}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton
                        className="GreenButton700"
                        onClick={(e) => handleAddFields(e)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          ))}
          {/* <DialogActions className="DialogActionBottom ">
            <Button
              className="GreenButton700   RubricsActionButton "
              onClick={CreateSkills}
            >
              Save
            </Button>
          </DialogActions> */}
        </Grid>
      </Box>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Pagination } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import {
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  Input,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";

import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
// @material-ui icons
import { GoSearch } from "react-icons/go";
import { BiSort } from "react-icons/bi";
//core component

function name() {
  window.addEventListener("beforeunload", (e) => {
    e.preventDefault();
    return alert("Test---1");
  });
}
window.onload = function () {
  name();
};
export default function ExtendedTables() {
  const [sort, setSort] = useState(true);
  const sortValue = !sort ? "asc" : "desc";
  //Pagination
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRowData] = useState(null);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
    handleSearch("undefined", null, pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  // CreateState

  const { userToken, departmentDetail } = useLogin();
  // API Calling
  const getAllSchool = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getAllSchools?pagination=true&page=1&limit=10&schoolName=${departmentDetail?.departmentName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setRowData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  /////////////////////////// For Search API /////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    //.log("My handling of search ========>", rowSize);
    const response = await fetch(
      `${FETCH_URL}/api/school/getAllSchools?search=${searchValue}&page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
    } else {
      //.log("Error while searching single school", res.err);
    }
  };
  ///////////////////////////////////////////////////////////////////

  const sortingSchool = async (sortValue) => {
    const response = await fetch(
      `${FETCH_URL}/api/school/getAllSchools?sort=organisationDetails.schoolName&sortOrder=${sortValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My  response from sorting School ==>", res);
      // getAllGrades();
    } else {
      //.log("Error while searching single school", res.err);
    }
  };

  const sortingLocation = async (sortValue) => {
    const response = await fetch(
      `${FETCH_URL}/api/school/getAllSchools?sort=organisationDetails.location&sortOrder=${sortValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setRowData(res.msg);
      console.log("My  response from sorting location name==>", res);
      // getAllGrades();
    } else {
      //.log("Error while searching single school", res.err);
    }
  };

  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getAllSchool();
  }, []);

  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Schools</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    //.log("Search Value =========>", e.target.value); // Add debouncing for this
                    // setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          {/* <AddDialog getAllSchool={getAllSchool} handleSearch={handleSearch} /> */}
        </div>
      </Toolbar>

      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID </TableCell>
              <TableCell className="ColHead">
                School Name
                <IconButton disableRipple style={{ height: "10px" }}>
                  <BiSort
                    onClick={() => {
                      sortingSchool(sortValue);
                      setSort(!sort);
                    }}
                    color="#FFF"
                    size=".8em"
                  />
                </IconButton>
              </TableCell>
              <TableCell className="ColHead">
                Location
                <IconButton disableRipple style={{ height: "10px" }}>
                  <BiSort
                    onClick={() => {
                      sortingLocation(sortValue);
                      setSort(!sort);
                    }}
                    color="#FFF"
                    size=".8em"
                  />
                </IconButton>
              </TableCell>
              <TableCell className="ColHead">Contact </TableCell>
              {/* <TableCell className="ColHead"> Action </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {rowData &&
              rowData?.length > 0 &&
              rowData?.map((school) => (
                <TableRow>
                  <TableCell className="Col">{school?.schoolUid}</TableCell>
                  <TableCell className="Col">
                    <Link
                      to={{
                        pathname: "/hos/schoolprofile",
                        state: { school },
                      }}
                    >
                      {school?.organisationDetails?.schoolName}
                    </Link>
                  </TableCell>
                  <TableCell
                    // className="Col"
                    className="ActionButtonSize Col"
                  >
                    {" "}
                    {school.organisationDetails?.location}
                  </TableCell>
                  <TableCell className="Col">
                    {school.contactDetail?.representativeMobilePhoneNo}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      <div className="paginationStyle">
        <Pagination
          onChange={(value) => {
            handleSearch("undefined", value);
            setPage(value);
          }}
          // onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
//core component

import LearnerProfile from "../GLearnerProfile/LearnerProfile";
import LearnerEdit from "../../../../../PrincipalLearner/PLearnerActionDialog/EditDialog_s/EditDialog";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import LLessonPlan from "../../../../../PrincipalLearner/PLearnerActionDialog/LLessonPlanDialog/LLPDialogMain";
// React Icons
import { BiBook } from "react-icons/bi";
export default function Radio(props) {
  const { userToken } = useLogin();
  const {
    learnerDivisonData,
    getPaginatedLearner,
    divisionDataId,
    divisionName,
  } = props;
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      {/* <div className="DivisionStyleGrid"> */}
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Roll No </TableCell>
              <TableCell className="ColHead"> Learner’s Name</TableCell>
              <TableCell className="ColHead"> Password </TableCell>

              <TableCell className="ColHead"> Division</TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerDivisonData &&
              learnerDivisonData.length > 0 &&
              learnerDivisonData.map((learner) => (
                <TableRow>
                  <TableCell className="Col">
                    {learner.learnerDetail.regNo}
                  </TableCell>
                  <TableCell className="Col">
                    {learner.academicDetails.rollNumber}
                  </TableCell>
                  <TableCell className="Col">
                    <LearnerProfile learner={learner} />
                  </TableCell>
                  <TableCell className="Col">{learner.password}</TableCell>

                  <TableCell className="Col">{divisionName}</TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <LearnerEdit
                        getPaginatedLearner={getPaginatedLearner}
                        learner={learner}
                        divisionDataId={divisionDataId}
                      />
                    </IconButton>

                    {/* <IconButton className="ActionButtonSize">
                      <LLessonPlan
                        learnerId={{
                          learnerId: learner._id,
                          notificationToken: learner.notificationToken,
                          gradeId: learner.assignedGrade,
                          schoolId: learner.assignedSchool,
                          schoolName: learner.academicDetails.schoolName,
                          location: learner.academicDetails.location,
                        }}
                        getPaginatedLearner={getPaginatedLearner}
                        divisionDataId={divisionDataId}
                      />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {/* <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div> */}
      {/* </div> */}
    </>
  );
}

import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
//core component
import HosProfile from "./HosEventProfile/HosEProfile";

export default function BasicTabs(props) {
  const [value, onChange] = useState(new Date());
  const {
    states: { eventData },
    getAllEvent,
  } = props;
  console.log(eventData, "eventData>>>>>>>>>>>>>123>>>>>>>>>>");

  return (
    <>
      {eventData &&
        eventData.length > 0 &&
        eventData.map((event) => (
          <Grid container direction="row" className="EventContainer">
            <Grid
              container
              item
              md={8}
              sm={8}
              xs={8}
              lg={8}
              className="colorwhite"
              style={{ borderRadius: "10px" }}
            >
              <Grid
                item
                md={2}
                sm={2}
                xs={2}
                className="EventCardGrid2Style"
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                <Typography className="WhiteColorTypography ">21st</Typography>{" "}
                <Typography className="WhiteColorTypography ">
                  Dec 2021
                </Typography>
                <Typography className="WhiteColorTypography ">
                  {" "}
                  10:30 AM
                </Typography>
                <Typography className="BlueColorTypography ">
                  <HosProfile />
                </Typography>
              </Grid>

              <Grid item md={5} sm={5} xs={5} className="EventCardGrid5Style ">
                <Typography className="BlueColorTypography ">
                  {event.eventName}
                  <span> {event.board}</span>
                </Typography>
                <Typography className="PT-20px">
                  {event.eventDetails}
                </Typography>
              </Grid>
              <Grid item md={2} sm={2} xs={2} className="PT-10px">
                <Typography className="BlueColorTypography Grade1Typo">
                  Mode
                </Typography>
                <Typography className="BlueColorTypography Grade1Typo">
                  Participants
                </Typography>
                <Typography className="BlueColorTypography Grade1Typo">
                  Location
                </Typography>
              </Grid>
              <Grid item md={2} sm={2} xs={2} className="PT-10px">
                <Typography className="DarkBlackColorTypo Grade1Typo">
                  Offline
                </Typography>
                <Typography className="DarkBlackColorTypo Grade1Typo">
                  All School
                </Typography>
                <Typography className="DarkBlackColorTypo Grade1Typo">
                  {event.location}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4} sm={4} xs={4} lg={4}>
              <Grid item className="EventCardGrid4Style">
                <Grid item className="CalenderGridStyle">
                  <Calendar onChange={onChange} value={value} />{" "}
                </Grid>
                <Grid item className="EventCalenderGridStyle">
                  <Grid container item className="BlueCardGridStyle">
                    <Typography className="WhiteColorTypography BlueCardTypoStyle ">
                      {event.eventName}
                    </Typography>
                    <Typography className="WhiteColorTypography PR-10px  BlueCardTypoStyle">
                      All School
                    </Typography>
                  </Grid>
                  <Typography className="WhiteColorTypography BlueCardTypoStyle">
                    {" "}
                    Offline
                  </Typography>
                  <Typography className="GreenColorTypography BlueCardTypoStyle">
                    See details
                  </Typography>
                </Grid>
                <Grid item className="EventCalenderGridStyle">
                  <Grid container item className="BlueCardGridStyle">
                    <Typography className="WhiteColorTypography BlueCardTypoStyle">
                      Sports Olympic
                    </Typography>
                    <Typography className="WhiteColorTypography PR-10px  BlueCardTypoStyle">
                      All School
                    </Typography>
                  </Grid>
                  <Typography className="WhiteColorTypography BlueCardTypoStyle">
                    {" "}
                    Offline
                  </Typography>
                  <Typography className="GreenColorTypography BlueCardTypoStyle">
                    See details
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
}

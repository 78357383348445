import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import {
  FormControl,
  MenuItem,
  makeStyles,
  Select,
  Breadcrumbs,
  TextareaAutosize,
  IconButton,
  Grid,
  Input,
  Typography,
  Button,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";
// React Icons
import { IoIosArrowBack } from "react-icons/io";

//core component
import ActivityDetails from "./AProfileTab/ALActivityDetail";
import SkillEquiTab from "./AProfileTab/ALSkill&EquiTab";
import VarientTab from "./AProfileTab/ALVatientTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ExtendedTables() {
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="BackHead">
        <IoIosArrowBack
          className="BreadcrumbBackIcon"
          onClick={() => history.goBack()}
        />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Breadcrumbackbutton"
        >
          Back
        </Button>
      </div>

      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Activity Detail"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Variant"
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Skill & Equipment"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <ActivityDetails />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <VarientTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SkillEquiTab />
      </TabPanel>
    </>
  );
}

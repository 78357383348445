import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Box,
  Tab,
  Tabs,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
import { FETCH_URL } from "fetchIp";

//core Component
import OngoingTab from "./OngoingTab";
import CompleteTab from "./CompleteTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LgCourse({ learner }) {
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const completedCourse = learner?.assignedCourses?.filter(
  //   (item) => item.completion === true
  // );

  // const OngoingCourse = learner?.assignedCourses?.filter(
  //   (item) => item.completion === false
  // );

  let currentDate = moment().format();

  const OngoingCourse = learner?.MarketPlaceCourses?.filter(
    (item) => currentDate <= moment(item?.expireOn).format()
  );
  const completedCourse = learner?.MarketPlaceCourses?.filter(
    (item) => currentDate > moment(item?.expireOn).format()
  );

  const newArray = completedCourse?.map((obj) => ({
    marketPlaceCourseId: obj?.marketPlaceCourseId,
    newStartDate: obj?.purchageOn,
    newEndDate: obj?.expireOn,
  }));
  const newArrayonGoing = OngoingCourse?.map((obj) => ({
    marketPlaceCourseId: obj?.marketPlaceCourseId,
    newStartDate: obj?.purchageOn,
    newEndDate: obj?.expireOn,
  }));
  // //.log("Check newArray", newArray);

  const [course, setCourse] = useState([]);
  const [courseOnGoing, setCourseonGoing] = useState([]);
  // const completedCourse = course?.filter((item) => item.completion === true);

  const GetAssignedCourse = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getMarketPlaceCourseByMarketPlaceCourseIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            marketPlaceCourseIds: newArray,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setCourse(res.msg);
        // //.log("Get GetAssignedCourse Responce   ", res.msg);
      } else {
        //.log("Check AssignedCourse Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  const GetAssignedCourseOngoing = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/course/getMarketPlaceCourseByMarketPlaceCourseIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            marketPlaceCourseIds: newArrayonGoing,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setCourseonGoing(res.msg);
        // //.log(" GetAssignedCourseOngoing  Responce   ", res.msg);
      } else {
        //.log("Check GetAssignedCourseOngoing Error ", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };
  useEffect(() => {
    GetAssignedCourse();
    GetAssignedCourseOngoing();
  }, []);

  // const totalAssignedPP = learner?.lessonPlans[1].reduce(
  //   (acc, curr) => acc + curr.assignedPP,
  //   0
  // );
  const totalAssignedPP = totalAssignedPP;

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Grid item conatiner>
          <Tabs
            value={value}
            onChange={TabChange}
            aria-label="basic tabs example"
            className="GProcessTabStyle"
          >
            <Tab
              className="GProceesTabChanges"
              label="Ongoing"
              {...a11yProps(0)}
            />
            <Tab
              className="GProceesTabChanges"
              label="Expired"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <OngoingTab
            courseOnGoing={courseOnGoing}
            learner={learner}
            totalAssignedPP={totalAssignedPP}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {" "}
          <CompleteTab course={course} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

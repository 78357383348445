import React, { useState, useEffect } from "react";

// @material-ui/core components
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import axios from "axios";
// // material-ui icons
import { BsUpload } from "react-icons/bs";
import { BiBook } from "react-icons/bi";
import { GoSearch } from "react-icons/go";

// core components
import LearnerAdd from "./AddLearner/LearnerAdd";
import LearnerEdit from "./LearnerActionDialog/EditDialog_s/EditDialog";
import LearnerDelete from "./LearnerActionDialog/DeleteDialog";
import LearnerSwicth from "./LearnerActionDialog/SwitchDialog";
import LLessonPlan from "./LearnerActionDialog/LLessonPlanDialog/LLPDialogMain";
import LearnerProfile from "./LearnerProfile/LearnerProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//react icons
import { BsEye } from "react-icons/bs";
import { CgClipboard } from "react-icons/cg";
import { IoAnalyticsSharp } from "react-icons/io5";
export default function Learner(props) {
  const { userToken, departmentDetail } = useLogin();

  const [learnerData, setLearnerData] = useState(null);
  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const [grade, setGrade] = React.useState("");
  const GradeChange = (event) => {
    setGrade(event.target.value);
  };

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [access, setAccess] = useState(1);
  // States for dropdowns
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolLoc, setSchoolLoc] = useState([]);
  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  useEffect(() => {
    getAllLearners();
    // getDistinctSchoolAndLocation();
  }, []);

  ////////////////getDistinctSchoolAndLocation////////////////////////////

  ////////////////getDistinctSchoolAndLocation////////////////////////////
  ////////////////////////getDropDownschools/////////////////////////////////

  /////////////////////handle search//////////////////////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      getAllLearners();
    }
    console.log("My handling of search ========>", rowSize);
    const response = await fetch(
      `${FETCH_URL}/api/learner/searchLearner/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLearnerData(res.msg);
      console.log("My School response from searching ==>", res);

      // getAllGrades();
    } else {
      console.log("Error while searching single school", res.err);
    }
  };
  /////////////////////handle search//////////////////////////////////////////////

  ////////////////////////getDropDownschools/////////////////////////////////

  const getAllLearners = async () => {
    let url = `/api/learner/getAllLearners?schoolName=${departmentDetail?.departmentName}`;
    try {
      const response = await fetch(`${FETCH_URL}${url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setLearnerData(res.msg);

        // history.push("/admin/dashboard");
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Learner</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    handleSearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          {/* <LearnerAdd getAllLearners={getAllLearners} />
          <IconButton className="HeaderAddButton">
            <BsUpload size="1.1em" color="#5fa12f" />
          </IconButton> */}
        </div>
      </Toolbar>
      {/* <Grid container>
        <Grid item md={3} sm={3} xs={3} lg={3}>
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              defaultValue={0}
              onChange={(e) => {
                console.log("event target =======>", e.target.value);
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
              }}
              label="Select School"
              color="white"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select School
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2} style={{ marginLeft: "20px" }}>
          <FormControl variant="standard" className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getDropDownSchools(e.target.value);
              }}
              label="Select Location"
              disableUnderline
              defaultValue={0}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid> */}
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead"> Learner’s Name</TableCell>
              <TableCell className="ColHead"> School Name</TableCell>
              <TableCell className="ColHead"> Location </TableCell>
              <TableCell className="ColHead"> Grade </TableCell>
              <TableCell className="ColHead"> Enrollment Date </TableCell>
              <TableCell className="ColHead"> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerData &&
              learnerData.length > 0 &&
              learnerData.map((learner) => (
                <TableRow>
                  <TableCell className="Col">
                    <Link> {learner.learnerDetail.regNo} </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {learner.learnerDetail.fullName}
                  </TableCell>
                  <TableCell className="Col">
                    <Link>
                      <LearnerProfile learner={learner} />
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    {learner.contactDetail.learnerLocation}{" "}
                  </TableCell>{" "}
                  <TableCell className="Col">
                    {" "}
                    {learner.academicDetails.grade}
                  </TableCell>
                  <TableCell className="Col">
                    {" "}
                    {learner.learnerDetail.enrollmentDate.split("T")[0]}
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <BsEye className="ActionButtonIconGrey" />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <IoAnalyticsSharp className="ActionButtonIconGrey" />
                    </IconButton>
                    <IconButton className="ActionButtonSize">
                      <CgClipboard className="ActionButtonIconGrey" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          // onChange={(value) => setPage(value)}
          onChange={(value) => {
            handleSearch("undefined", value);
            setPage(value);
          }}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

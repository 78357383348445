import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  MenuItem,
  Input,
  Select,
  InputAdornment,
  FormControl,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import axios from "axios";
// // material-ui icons

import { GoSearch } from "react-icons/go";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
// core components
import BodyMgtTab from "./PSkillTab/BodyMgtTab";
import ObjMgtTab from "./PSkillTab/ObjMgtTab";
import LocomotorTab from "./PSkillTab/LocomotorTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ExtendedTables() {
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [skillsData, setSkillsData] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = useState(1); //
  const [gradeData, setGradeData] = useState([]);
  const [gradeValue, setGradeValue] = useState(null);
  const [searchText, setSearchText] = useState("");
  const TabChange = (event, newValue) => {
    setValue(newValue);
    setGradeValue(null);
    setSearchText("");
  };

  const handleSearch = async (searchValue, pageNum, rowSize) => {
    loadingHandler(true);
    let mainSkill;
    switch (value) {
      case 0:
        mainSkill = "Locomotor";
        break;
      case 1:
        mainSkill = "Body Management";
        break;
      case 2:
        mainSkill = "Object Control";
        break;
      default:
        console.error("Invalid Value");
    }
    const response = await fetch(
      `${FETCH_URL}/api/skill/getPaginatedSkill?search=${
        searchValue?.trim() ? searchValue?.trim() : ""
      }&gradeId=${gradeValue}&pagination=true&schoolId=${
        schoolDetail?.schoolId
      }&mainSkill=${mainSkill}&page=${pageNum ? pageNum : 1}&limit=${
        rowSize ? rowSize : 10
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      loadingHandler(false);
      console.log("My skill response from searching ==>", res);
      setSkillsData(res.msg);
      setTotal(res.lengthData);
      // getAllGrades();
    } else {
      loadingHandler(false);
      console.log("Error while searching skills", res.err);
    }
  };
  //get grades
  const getAllGrade = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setGradeData(res.msg);
      } else {
        loadingHandler(false);
      }
    } catch (error) {
      loadingHandler(false);
    }
  };
  useEffect(() => {
    handleSearch();
  }, [value, gradeValue]);

  useEffect(() => {
    getAllGrade();
  }, []);

  return (
    <>
      <Grid container className="MT-3 ML-10px">
        <Grid container item className="  TitleGridStyle">
          <div>
            <Grid container>
              {" "}
              <Typography className="BlueColorTypography LPTitle  PL-5">
                {`${schoolDetail?.schoolName} ${schoolDetail?.location} `}
              </Typography>
              <FormControl variant="standard" className="ML-20px">
                <Select
                  className=" EventDatedropstyle"
                  value={gradeValue ? gradeValue : 0}
                  defaultValue={0}
                  disableUnderline
                  onChange={(e) => {
                    setSearchText("");
                    setGradeValue(e.target.value);
                  }}
                  label="Select grade"
                >
                  <MenuItem value={0} className="Eventmenustyle" disabled>
                    Select grade
                  </MenuItem>
                  {gradeData?.map((a, i) => (
                    <MenuItem key={i} className="Eventmenustyle" value={a?._id}>
                      {a?.grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </div>

          <Grid item>
            <div>
              <Box>
                <div>
                  <FormControl>
                    <Input
                      disableUnderline
                      className="input"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        setGradeValue(null);
                        handleSearch(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className="HeadSearchButton">
                            <GoSearch className=" HaedAddIcon" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Serach"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="MT-25px">
        <Grid container item md={6} sm={6} xs={6} lg={6}>
          <Typography className="BlueColorTypography LPTitle PL-19px">
            Assigned Skills
          </Typography>
        </Grid>

        {/* <Grid container item md={6} sm={6} xs={6} lg={6}>
          <Grid
            container
            item
            md={12}
            sm={12}
            xs={12}
            lg={12}
            className="SortByGridStyle"
          >
            <Typography className="GreyColorTypography MR-15px   PT-3px FS-13px  ">
              Sort by
            </Typography>
            <Grid item>
              <FormControl className="MainPageFormControl">
                <Select
                  className=" EventDatedropstyle"
                  value={school ? school : 0}
                  onChange={handleChange}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Eventmenustyle" value={0}>
                    Date
                  </MenuItem>
                  <MenuItem className="Eventmenustyle" value={10}>
                    23/12/2021
                  </MenuItem>
                  <MenuItem className="Eventmenustyle" value={20}>
                    24/12/2021
                  </MenuItem>
                  <MenuItem className="Eventmenustyle" value={30}>
                    29/12/2021
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>

      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Locomotor"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Body Management "
            {...a11yProps(1)}
          />
          <Tab
            className="ActivityTabChanges"
            label=" Object Control "
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <LocomotorTab
          skillsData={skillsData}
          handleSearch={handleSearch}
          total={total}
          setTotal={setTotal}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BodyMgtTab
          skillsData={skillsData}
          handleSearch={handleSearch}
          total={total}
          setTotal={setTotal}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ObjMgtTab
          skillsData={skillsData}
          handleSearch={handleSearch}
          total={total}
          setTotal={setTotal}
        />
      </TabPanel>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      {/* <Typography className="DialogTitleTypographyMediuem">
        Assesment Details
      </Typography> */}
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog(props) {
  const { assesmnet } = props;
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(assesmnet, " i am assesment>>>");
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Button
        className="ButtonTransparent overflow-style-nwidth"
        onClick={handleClickOpen("paper")}
      >
        {assesmnet.assessmentName}
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box>
            <Tabs
              value={value}
              onChange={TabChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab
                className="TabStyle"
                label="Assesment Details"
                {...a11yProps(0)}
              />
              <Tab className="TabStyle" label="Rubrics" {...a11yProps(1)} />
              <BootstrapDialogTitle
                onClose={handleClose}
              ></BootstrapDialogTitle>
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <Grid container className="DailogLTRBStyle">
              <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
                <Grid
                  container
                  direction="column"
                  item
                  style={{ marginLeft: "25px" }}
                >
                  <Typography className="SkillsInputTitle  GreyColorTypography">
                    Assesment UID<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <Input
                      className="RubicInputs"
                      defaultValue={assesmnet.uid}
                      disabled
                    ></Input>
                  </Grid>
                  <Typography className="SkillsInputTitle GreyColorTypography">
                    Assesment Name <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <Input
                      className="RubicInputs"
                      defaultValue={assesmnet.assessmentName}
                      disabled
                    ></Input>
                  </Grid>
                  <Typography className="SkillsInputTitle GreyColorTypography">
                    Assesment Description{" "}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>{" "}
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <TextareaAutosize
                      className="SkillTextArea"
                      aria-label="minimum height"
                      defaultValue={assesmnet.assessmentDesc}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                md={6}
                sm={6}
                xs={6}
                lg={6}
                direction="column"
              >
                <Typography className="GreyColorTypography   EquipMeadiaTypo  ">
                  Media
                </Typography>
                <Grid item container className="AddThumbnilGridStyle">
                  <img
                    width="100%"
                    height="100%"
                    src={assesmnet.assessmentImage}
                    style={{ borderRadius: "9px" }}
                  />
                </Grid>
                <Grid item container className="GridPP-levelStyle">
                  <Grid container item className=" PPGridStyle">
                    <Typography className="BlueColorTypography  PPTypo">
                      PP
                    </Typography>
                    <Input
                      disableUnderline
                      className="SkilsPPInput"
                      defaultValue={assesmnet.assignedXp}
                      disabled
                      // value={xpAssigned}
                      // onChange={(e) => setXpAssigned(e.target.value)}
                    />
                  </Grid>
                  <Grid item container className="LevelGridStyle">
                    <Typography className="BlueColorTypography  LevelTypo">
                      Level
                    </Typography>

                    {/* <FormControl variant="standard" className="FormControlInput">
                  <Select
                    className=" SkillsSelectStyle"
                    // defaultValue={assesmnet.level}
                    disabled
                    value={10}
                    // onChange={setLevel}
                    color="white"
                    disableUnderline
                  >
                    <MenuItem className="Selectmenustyle" value={10}>
                      XI
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={20}>
                      X
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={30}>
                      Vi
                    </MenuItem>
                  </Select>
                </FormControl> */}

                    <Input
                      disableUnderline
                      className="SkilsPPInput"
                      defaultValue={assesmnet.level}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="LargeDialogLRBStyle">
              <div
                className="ResponsiveTableHaedBlack"
                style={{ marginRight: "22px" }}
              >
                <Table className="Tableroot">
                  <TableHead>
                    <TableRow className="HeadAL">
                      <TableCell className="ColHead">Q.no</TableCell>
                      <TableCell className="ColHead">Question </TableCell>
                      <TableCell className="ColHead"> Total Marks</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {assesmnet.skills.map((rbsqs) =>
                      rbsqs.rubricsQts.map((qs) => (
                        <TableRow>
                          <TableCell className="Col">{qs.quesNo}</TableCell>
                          <TableCell className="Col">{qs.quesDesc}</TableCell>
                          <TableCell className="Col">{qs.quesMarks}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </div>{" "}
            </div>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Input,
  TextareaAutosize,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import ManImage from "assets/img/Man.png";
//React Icons
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";

export default function EquipmentProfile() {
  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const { usrData } = location.state;
  console.log("usrData>>>>>>>>>>>>", usrData);

  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const LabelName = [
    {
      labelname: "View Users",
    },
    {
      labelname: "Edit Users",
    },
    // {
    //   labelname: "Delete Users",
    // },
  ];
  const LabelName2 = [
    {
      labelname2: "View ",
    },
    {
      labelname2: "Edit ",
    },
    // {
    //   labelname2: "Delete ",
    // },
  ];
  return (
    <>
      {/* <div className="BackHead">
        <IoIosArrowBack className="BackIcon" onClick={() => history.goBack()} />
        <Button
          onClick={() => history.goBack()}
          disableRipple
          className="Backbutton "
        >
          Back
        </Button>
      </div> */}
      <div role="presentation" onClick={handleClick}>
        <div className="BreadCrumbsDiv">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => history.goBack()}
              disableRipple
              className="Breadcrumbbutton"
            >
              Users
            </Link>
            <div className="Breadcrumb2">{usrData.fullName}</div>
          </Breadcrumbs>
        </div>

        <div>
          <Typography
            align="center"
            className="GreyColorTypography SchoolnameTypo "
          >
            {/* {usrData.department} */}
            {usrData.state}
            <span className="BlueColorTypography GradeTYpo"> Grade -1 </span>
          </Typography>
        </div>
      </div>

      <Grid container className="ContainerStyle">
        <Grid
          container
          item
          direction="column"
          md={2}
          sm={2}
          xs={2}
          lg={2}
          className="RowBackColor"
        >
          <Grid container item className="DetailsTypo-3">
            <img
              className="UPSidebarImg"
              src={usrData.imageUrl ? usrData.imageUrl : ManImage}
            />
          </Grid>
          <Typography align="center" className="BlueColorTypography">
            {usrData.fullName}
          </Typography>
          <Typography align="center" className="BlueColorTypography">
            {usrData.userStrRole.toUpperCase()}
          </Typography>
          <Typography align="center" className="GreenColorTypography">
            Active
          </Typography>
          <Typography
            align="center"
            className="BlueColorTypography JoindateTypo"
          >
            Joined on :{" "}
            <span className="GreenColorTypography">
              {" "}
              {usrData.dateOfJoining.split("T")[0]}{" "}
            </span>
          </Typography>
          <Grid container item className="Row1IconsGrid">
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <AiOutlineMail className=" ActionButtonIcon GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography className="BlackColorTypography  GtSideGridStyle  UPLocTypo  ">
                {usrData.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item className="Row1IconsGrid">
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <MdPhone className="  ActionButtonIcon GTMailIcon " />
            </Grid>
            <Grid container item md={10} sm={10} xs={10} lg={10}>
              <Typography a className="BlackColorTypography  UPLocTypo  ">
                {usrData.phoneNo}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item className="Row1IconsGrid">
            <Grid container item md={2} sm={2} xs={2} lg={2} justify="center">
              <MdLocationPin className="   ActionButtonIcon  GTMailIcon " />
            </Grid>
            <Grid
              container
              item
              md={10}
              sm={10}
              xs={10}
              lg={10}
              className="LocIconsGrid"
            >
              <Typography className="BlackColorTypography  UPLocTypo  ">
                {usrData.address}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={4}
          sm={4}
          xs={4}
          lg={4}
          className="RowBackColor2"
        >
          <Grid container item direction="column" className="RowGridStyle2">
            <Grid container item className="PersonalDetailGrid">
              <Typography className="GreenColorTypography PersonalDetailTypo">
                Personal Details
              </Typography>
            </Grid>
            <Grid container item className="RoleGrid">
              <Typography className="GreyColorTypography ">
                Role{" "}
                <span className="BlueColorTypography UPPrinciTypo">
                  {" "}
                  {usrData.userStrRole.toUpperCase()}
                </span>
              </Typography>
            </Grid>
            <Grid container item>
              <Grid item md={10} className="InputGridStyle ">
                <Typography className="GreyColorTypography UPInputTypo">
                  UID
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Typography variant className="RubicInputs">
                    {usrData.uid}
                  </Typography>
                </Grid>
                {/* <Input className="UPInput">{usrData.uid}</Input> */}
              </Grid>
              <Grid item md={10} className="InputGridStyle">
                <Typography className="GreyColorTypography UPInputTypo">
                  Full Name
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Typography variant className="RubicInputs">
                    {usrData.fullName}
                  </Typography>
                </Grid>
                {/* <Input className="UPInput"> </Input> */}
              </Grid>

              <Grid item md={10} className="InputGridStyle">
                <Typography className="GreyColorTypography UPInputTypo">
                  Mobile Number
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Typography variant className="RubicInputs">
                    {usrData.phoneNo}
                  </Typography>
                </Grid>
                {/* <Input className="UPInput"> </Input> */}
              </Grid>
              <Grid item md={10} className="InputGridStyle">
                <Typography className="GreyColorTypography UPInputTypo">
                  Email ID
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Typography variant className="RubicInputs">
                    {usrData.email}
                  </Typography>
                </Grid>

                {/* <Input className="UPInput"> </Input> */}
              </Grid>
              <Grid
                item
                container
                md={10}
                sm={10}
                xs={10}
                lg={10}
                className="InputGridStyle "
              >
                <Typography className="GreyColorTypography UPInputTypo">
                  Addresss
                </Typography>
                <Grid item container md={10} sm={10} xs={10} lg={10}>
                  <Typography variant className="RubicInputs">
                    {usrData.address}
                  </Typography>
                </Grid>
                {/* <TextareaAutosize
                  className="UserTextArea"
                  aria-label="minimum height"
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="RowBackColor2" item md={5} sm={5} xs={5} lg={5}>
          <Grid item container className="RowGridStyle2">
            <Grid container item className="PersonalDetailGrid">
              <Typography className="GreenColorTypography PersonalDetailTypo">
                User Access
              </Typography>
            </Grid>
            <Grid container item>
              <Typography className="BlueColorTypography AccessTypo">
                Access
              </Typography>
            </Grid>
            <Grid container item className="UserControlGrid">
              <Grid item md={4} sm={4} lg={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  User Control
                </Typography>
                {LabelName.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid item md={8} sm={8} lg={8} xs={8} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Organisation Control
                </Typography>
                {LabelName.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Typography className="BlueColorTypography ModuleCTypo">
                Module Control
              </Typography>
            </Grid>

            <Grid container item>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  School Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Course Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid
                container
                item
                md={4}
                sm={4}
                xs={4}
                lg={4}
                direction="column"
              >
                <Typography className="GreyColorTypography UserControlTypo">
                  Lesson Plan Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
            </Grid>
            <Grid container item className="ActivityModuleTypo">
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Skills Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Equipment Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
              <Grid container item md={4} sm={4} xs={4} direction="column">
                <Typography className="GreyColorTypography UserControlTypo">
                  Assessment Module
                </Typography>
                {LabelName2.map((user, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      className="CheckLabelStyleGrey400   "
                      label={user.labelname2}
                    />
                  </FormGroup>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

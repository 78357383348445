import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Input,
  FormControl,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  InputAdornment,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  FormGroup,
  IconButton,
  makeStyles,
  Box,
  Tooltip,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { GoSearch } from "react-icons/go";
// core component
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import { data } from "jquery";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Assign Learner
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddDialog(props) {
  const { userToken } = useLogin();
  const { equipmentId } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [school, setSchool] = React.useState("");
  const [Quantity, setQuantity] = React.useState("");

  const handleChange = (event) => {
    setSchool(event.target.value);
  };
  const [location, setLocation] = React.useState("");

  const [learnerData, setLearnerData] = useState(null);
  // const [equipmentId, setEquipmentId] = useState("");
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data
  const [finalData, setFinalData] = React.useState([]); // finalData holds the value that will be sent to the API

  //////////////////////////////////////////api for learner ///////////////////////////
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState([]);
  const [selectGrade, setSelectGrade] = useState([]);
  const [gradeSelected, setGradeSelected] = useState("");
  ///////////////////////////////////////////////////////////////////
  const [selectLearnerText, setSelectLearnerText] = React.useState("");
  // SnackBar
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  /////getPaginatedLearner
  const getPaginatedLearner = async (grade) => {
    console.log(schoolSelected, "schoolSelected>>>>>>>>>>>>>>>>>>");
    console.log(
      schoolLocationSelected,
      "schoolLocationSelected>>>>>>>>>>>>>>>>>>"
    );
    console.log(grade, "grade>>>>>>>>>>>>>>>>>>");
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of paginated learners==>", res);
        setLearnerData(res.msg);
        // setAssignLearnerId();
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////

  /////////////////////handle search//////////////////////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (searchValue.length === 0) {
      getAllLearners();
    }
    console.log("My handling of search ========>", rowSize);
    const response = await fetch(
      `${FETCH_URL}/api/learner/searchLearner/${searchValue}?page=${
        pageNum ? pageNum : 1
      }&limit=${rowSize ? rowSize : 10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLearnerData(res.msg);
      console.log("My learner response from searching ==>", res);

      // getAllGrades();
    } else {
      console.log("Error while searching single school", res.err);
    }
  };
  /////////////////////handle search//////////////////////////////////////////////

  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const DialogClose = () => {
    setOpen(false);
    setOriginalData([]);
    setQuantity("");
    setSelectLearnerText("");
  };

  const getAllLearners = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/learner/getAllLearners`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setLearnerData(res.msg);
      } else {
        console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllLearners();
  }, []);
  console.log(" Equipment  Id ", equipmentId);

  function storeSkill(e, x, i) {
    let storeArr = [...originalData];
    console.log(x, i);
    if (e.target.checked) {
      storeArr[i] = x;
      storeArr[i].isChecked = e.target.checked;
    } else {
      storeArr[i] = null;
    }
    console.log(storeArr);
    setOriginalData(storeArr);
  }

  ////////////////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  //////////////////////////////////////////////////////////////////

  const assignedLearnerId = filterdata.map((a) => {
    return {
      learnerId: a._id,
      quantity: Quantity,
    };
  });

  console.log(
    assignedLearnerId,
    "assignedLearnerId>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
  );

  const submitAssignLearner = async (e) => {
    e.preventDefault();
    if (filterdata.length === 0) {
      setSelectLearnerText("please select a learner!");
      return;
    }
    console.log("The quantity is ", Quantity);
    if (Quantity <= 0) {
      return alert("Quantity cannot be negative/zero");
    }

    try {
      const response = await fetch(
        `${FETCH_URL}/api/equipment/assignEquipmentToLearner`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            equipmentId,
            assignedLearnerId: assignedLearnerId,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My assingned learner to equipment data ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getAllLearners();
        DialogClose();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const cleanArr = (arr) => {
    const filtered = arr.filter(
      (a) => a !== undefined && a.isChecked !== false
    );

    console.log("Filtered ====>", filtered);
  };
  React.useEffect(() => {
    getDistinctSchoolAndLocation();
  }, []);
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Learner">
        <IconButton className="ActionButtonSize">
          <AiOutlineUser
            className="ActionButtonIcon"
            onClick={handleClickOpen}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={DialogClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container spacing={4} className="DailogLTRBStyle">
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              lg={12}
              style={{ marginLeft: "2%" }}
            >
              <Grid item container>
                <Grid item container className="ParticipantStyleSelect">
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" DialogSelectdropstyle"
                      //   value={school ? school : 0}
                      //   onChange={handleChange}
                      value={schoolSelected ? schoolSelected : 0}
                      onChange={(e) => {
                        getDistinctSchoolAndLocation(e.target.value);
                        setSchoolSelected(e.target.value);
                      }}
                      label="Select School"
                      color="white"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0}>
                        Select school
                      </MenuItem>
                      {schoolNames &&
                        schoolNames.length > 0 &&
                        schoolNames.map((school) => (
                          <MenuItem
                            value={school._id}
                            className="Selectmenustyle"
                          >
                            {school._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container className="ParticipantStyleSelect1">
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" Selectdropstyle"
                      value={
                        schoolLocationSelected ? schoolLocationSelected : 0
                      }
                      onChange={(e) => {
                        setSchoolLocationSelected(e.target.value);
                        getGrades(e.target.value);
                      }}
                      label="Select Location"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0}>
                        Select Location
                      </MenuItem>
                      {schoolLoc &&
                        schoolLoc.length > 0 &&
                        schoolLoc.map((location) => (
                          <MenuItem
                            value={location._id}
                            className="Selectmenustyle"
                          >
                            {location._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container className="ParticipantStyleSelect1">
                  <FormControl className="MainPageFormControl">
                    <Select
                      className=" Selectdropstyle"
                      value={gradeSelected ? gradeSelected : 0}
                      onChange={(e) => {
                        getPaginatedLearner(e.target.value);
                        setGradeSelected(e.target.value);
                      }}
                      label="Select Location"
                      disableUnderline
                    >
                      <MenuItem className="Selectmenustyle" value={0}>
                        Select Grade
                      </MenuItem>
                      {selectGrade &&
                        selectGrade.length > 0 &&
                        selectGrade.map((a) => (
                          <MenuItem className="Selectmenustyle" value={a._id}>
                            {a._id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Typography className="GreyColorTypography   OrStyleTypography">
                  OR
                </Typography>{" "}
                <div style={{ display: "flex", marginLeft: "8px" }}>
                  <Box style={{ marginTop: "10px" }}>
                    <div>
                      <FormControl className="AssignLPinput">
                        <Input
                          className="input"
                          disableUnderline
                          placeholder="Search"
                          onChange={(e) => {
                            console.log(
                              "Search Value =========>",
                              e.target.value
                            ); // Add debouncing for this
                            handleSearch(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton className="HeadSearchButton">
                                <GoSearch className=" HaedAddIcon" />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Serach"
                        />
                      </FormControl>
                    </div>
                  </Box>
                </div>
              </Grid>
              {learnerData &&
                learnerData.length > 0 &&
                learnerData.map((x, i) => (
                  <Grid
                    container
                    item
                    md={4}
                    direction="row"
                    style={{ marginTop: "15px" }}
                  >
                    <FormControlLabel
                      className=" AssignActivitesGridPaper  AssignActivites  CheckLabelStyleBlue "
                      control={<Checkbox />}
                      onChange={(e) => storeSkill(e, x, i)}
                      value={x.learnerDetail.fullName}
                      label={
                        <Typography>
                          {x.learnerDetail.fullName}
                          <span style={{ paddingLeft: "10px" }}>
                            {x.learnerDetail.regNo}
                          </span>
                        </Typography>
                      }
                    />
                  </Grid>
                ))}

              {/* Row2 */}
              {/* <Grid item md={4} sm={4} xs={4} lg={4}>
                  {TypoName.map((user, key) => (
                    <Grid
                      container
                      item
                      md={10}
                      sm={10}
                      xs={10}
                      lg={10}
                      className=" AssignActivitesGridPaper  AssignActivites  "
                    >
                      <Grid container item md={12} sm={12} xs={12} lg={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            className="CheckLabelStyleBlue"
                            label={<Typography>{user.LearnerName}</Typography>}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid> */}
            </Grid>
          </Grid>
        </DialogContent>
        {filterdata.length === 0 && (
          <Grid container style={{ marginTop: "10px", marginLeft: "25px" }}>
            <Typography className="warningTypography">
              {selectLearnerText}
            </Typography>
          </Grid>
        )}
        <DialogActions className=" ACourseAction">
          <Grid item container className="ASGQuanitiyStyleLearner">
            <Typography className="BlueColorTypography OuantityTypo">
              Quantity
            </Typography>
            <Input
              className="QuntityInput"
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
            >
              {" "}
            </Input>
          </Grid>

          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={submitAssignLearner}
          >
            Ok
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={DialogClose}
          >
            {" "}
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
  Typography,
} from "@material-ui/core";

//Core Component
import ObjectDelete from "../ActionButtonDialog's/ObjMgtActionB/OMDeleteDialog";
import ObjectEdit from "../ActionButtonDialog's/ObjMgtActionB/OMEdit/OMEditMain";
import ObjectSwitch from "../ActionButtonDialog's/ObjMgtActionB/OMSwitchDialog";

export default function ObjMgtTab(props) {
  const { TabNext } = props;
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
    },

    skillsData,
    searchSkill,
    getAllSkill,
    gradeId,
  } = props;

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Name of Skill</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead">Action</TableCell>
            </TableRow>
          </TableHead>
          {/* //  const indexOfLastPage = page + postPerPage;
  //  const indexOffFirstPage = indexOfLastPage - postPerPage;
  //  const currentPosts = posts.slice(indexOfLastPage,indexOffFirstPage) */}
          <TableBody>
            {skillsData &&
              skillsData.length > 0 &&
              skillsData.map((skill) => (
                <TableRow>
                  <TableCell className="Col">{skill.subSkillUid}</TableCell>
                  <TableCell className="Col">{skill.subSkillName}</TableCell>
                  <TableCell
                    className="Col"
                    style={{ wordBreak: "break-word" }}
                  >
                    <Typography className="GreyColorTypography500 overflow-style">
                      {skill.subSkillDesc}
                    </Typography>
                  </TableCell>
                  <TableCell className="Action">
                    <IconButton className="ActionButtonSize">
                      <ObjectEdit
                        states={{
                          mainSkill,
                          setMainSkill,
                          subSkillName,
                          setSubSkillName,
                          subSkillUid,
                          setSubSkillUid,
                          subSkillDesc,
                          setSubSkillDesc,
                          xpAssigned,
                          setXpAssigned,
                          level,
                          setLevel,
                          rubricsQts,
                          setRubricsQts,
                          skill,
                        }}
                        searchSkill={searchSkill}
                        gradeId={gradeId}
                      />
                    </IconButton>
                    {/* <IconButton className="ActionButtonSize">
                      <ObjectSwitch
                        getAllSkill={getAllSkill}
                        searchSkill={searchSkill}
                        SkillsId={skill._id}
                        access={skill.access}
                        gradeId={gradeId}
                      />
                    </IconButton> */}
                    {/* <IconButton className="ActionButtonSize">
                      <ObjectDelete />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  Input,
  FormControl,
  Select,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  RadioGroup,
  Radio,
  useRadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <FormControlLabel checked={checked} {...props} />;
}
export default function RenameTab(props) {
  const { TabNext } = props;
  const {
    states: {
      assestmentUid,
      setAssestmentUid,
      assestmentStartDate,
      setAssestmentStartDate,
      assestmentEndate,
      setAssestmentEndate,
      assestmentTitle,
      setAssestmentTitle,
      assesmentTitleText,
      assesmentUidText,
    },
  } = props;
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  GreyColorTypography">
              Assesment UID<span className="ImpColor"> *</span>
            </Typography>
            <Grid item container md={10} sm={10} xs={10} lg={10}>
              <Input
                className="Input1"
                value={assestmentUid}
                onChange={(e) => setAssestmentUid(e.target.value)}
              ></Input>
            </Grid>
            {assestmentUid.length === 0 && (
              <Typography className="warningTypography">
                {assesmentUidText}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Typography className="InputTitleTypography  GreyColorTypography">
            Assesment Title<span className="ImpColor"> *</span>
          </Typography>
          <Grid item container md={10} sm={10} xs={10} lg={10}>
            <Input
              className="Input1"
              value={assestmentTitle}
              onChange={(e) => setAssestmentTitle(e.target.value)}
            ></Input>
          </Grid>
          {assestmentTitle.length === 0 && (
            <Typography className="warningTypography">
              {assesmentTitleText}
            </Typography>
          )}
        </Grid>
        {/* <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "25px" }}
          >
            <Typography className="InputTitleTypography  BlueColorTypography">
              Start Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    value={assestmentStartDate}
                    onChange={(e) => setAssestmentStartDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Typography className="InputTitleTypography  BlueColorTypography">
              End Date <span style={{ color: "red" }}> *</span>
            </Typography>
            <Grid item md={10} sm={10} xs={10} lg={10}>
              <MuiPickersUtilsProvider
                className="Calender"
                utils={DateFnsUtils}
              >
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <KeyboardDatePicker
                    className="Calender"
                    format="dd/MM/yyyy"
                    value={assestmentEndate}
                    placeholder="DD/MM/YYYY"
                    minDate={moment(
                      moment(assestmentStartDate).format("YYYY-MM-DD")
                    ).add(1, "days")}
                    onChange={(e) => setAssestmentEndate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          item
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{
            marginLeft: "25px",
            marginBottom: "308px ",
            marginTop: "10px",
          }}
        >
          <RadioGroup
            className="RadioStyle"
            name="use-radio-group"
            defaultValue="first"
          >
            <MyFormControlLabel
              className=" BlueColorTypography RadioLabel"
              value="first"
              label={
                <Typography>
                  Correct Response Required{" "}
                  <span style={{ color: "red" }}> * </span>
                  <span
                    className="GreyColorTypography400"
                    style={{ fontSize: "13px" }}
                  >
                    ( Correct Response Required to Move to Next Lesson )
                  </span>
                </Typography>
              }
              control={<Radio />}
            />
          </RadioGroup>
        </Grid> */}
      </Grid>
    </>
  );
}

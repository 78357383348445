import React from "react";
import { Pagination } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@material-ui/core";
const CommonPagination = ({ getDataFromPagination, total }) => {
  const [pageNo, setPageNo] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRowData] = useState(null);
  console.log(total, "totaltotal");
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };
  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return orignalElement;
  };
  useEffect(() => {
    getDataFromPagination(pageNo);
  }, [pageNo]);
  return (
    <>
      {total == 10 && (
        <Grid container style={{ justifyContent: "center" }}>
          {" "}
          <Pagination
            onChange={(value, value2) => {
              setPageNo(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={pageNo}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </Grid>
      )}
    </>
  );
};

export default CommonPagination;

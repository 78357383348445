import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, DialogContent } from "@material-ui/core";
//core component
import CourseTab from "./LCourse/LGCourseTab";
import AssemnetTab from "./AssmentTab/LAssesment";
import SkillTab from "./LGSkill/LSkills";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LearnerTab({ learner }) {
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle1"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Courses"
            {...a11yProps(0)}
          />

          <Tab
            className="ActivityTabChanges"
            label="Assessment"
            {...a11yProps(1)}
          />

          <Tab
            className="ActivityTabChanges"
            label="Skills"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <DialogContent dividers={false} className="TabPanelStyle">
        <TabPanel value={value} index={0}>
          <CourseTab learner={learner} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AssemnetTab learner={learner} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SkillTab learner={learner} />
        </TabPanel>
      </DialogContent>
    </>
  );
}

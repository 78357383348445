import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import { Link } from "react-router-dom";
//React Icons
import { IoCloseSharp } from "react-icons/io5";
//core component
import OverviewTab from "./GCTab's/OverviewTab";
import RewardsTab from "./GCTab's/Rewards";
import SkillsTab from "./GCTab's/SkillsTab";
import Img1 from "assets/img/learner.png";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Courses Details
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddDialog({ post }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [value, setValue] = React.useState(0);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const multiSkillKit = post.equipmentKit.filter(
    (k) => k !== null && k !== undefined
  );

  console.log(post, "course inside profileeee");
  return (
    <div>
      <Link className="ButtonTransparent " onClick={handleClickOpen("paper")}>
        {post.courseTitle}
      </Link>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <Grid container className="DialogLRBStyle">
          <Grid item md={2} xs={2} sm={2} className="GCPGridSize-2">
            <Grid container item className="GCPGridCenter">
              <img className="GPRImageStyle " src={Img1} />
              <Grid
                className="GCPGridCenter"
                container
                direction="row"
                item
                md={10}
                xs={10}
                sm={10}
              >
                <Typography className=" BlueColorTypography PT-5px">
                  Learn to Play
                </Typography>
                <Typography className="WhiteColorTypography">
                  Duration : 1 week
                </Typography>
              </Grid>
              <Grid container item direction="column" className="GCPGridCenter">
                <Typography
                  align="center"
                  className="BlackColorTypography PT-25px"
                >
                  UID{" "}
                </Typography>
                <Typography align="center" className="BlueColorTypography">
                  {post.courseUid}
                </Typography>
                <Typography
                  align="center"
                  className="BlackColorTypography PT-25px"
                >
                  Grade{" "}
                </Typography>
                <Typography align="center" className="BlueColorTypography">
                  {post && post.assignedGrades.length > 0
                    ? post.assignedGrades.map((g) => g.grade)
                    : "--"}
                </Typography>
                <Typography
                  align="center"
                  className="GreyColorTypography PT-25px"
                >
                  Price :{" "}
                  <span className="BlueColorTypography">
                    {" "}
                    {post.coursePrice}{" "}
                  </span>
                </Typography>
                <Typography
                  align="center"
                  className="GreyColorTypography PT-45px"
                >
                  Assigned On
                </Typography>
                <Typography align="center" className="BlueColorTypography">
                  20/09/2021
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={7} xs={7} sm={7}>
            <Grid item className="TabGridStyle">
              <Box className="GPCTabBox">
                <Tabs
                  value={value}
                  onChange={TabChange}
                  centered
                  className="GPCTabStyle "
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="GPCTabStyle "
                    label="Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="GPCTabStyle "
                    label="Skills "
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="GPCTabStyle "
                    label="Rewards"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} className="GPCTabPanelStyle">
                <OverviewTab post={post.courseDesc} />
              </TabPanel>

              <TabPanel value={value} index={1} className="GPCTabPanelStyle">
                <SkillsTab post={post} />
              </TabPanel>
              <TabPanel value={value} index={2} className="GPCTabPanelStyle">
                <RewardsTab post={post} />
              </TabPanel>
            </Grid>
          </Grid>

          <Grid item md={3} xs={3} sm={3} className="GCPGridCenter">
            <Typography
              align="center"
              className="WhiteColorTypography GPCGrid-3SkillTypo"
            >
              Multi-Skill Kit
            </Typography>
            <Grid
              container
              item
              direction="column"
              className="GPCGrid-3SkillChipGrid "
            >
              {multiSkillKit.map((msKit, i) => (
                <Grid container item key={i} className="GCPGridCenter">
                  <Chip
                    label={msKit.name}
                    className="GreyColorTypography  GPCMultiSkill-ChipStyle  "
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
const LearnerTabPanel = ({ tableData }) => {
  // console.log(tableData, "tableData");
  return (
    <>
      <Grid container>
        <div className="ResponsiveTableLeaderBoard width_98">
          <Table className="LeaderTableroot border_primary">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="LeaderColHeadBlack"> Rank </TableCell>
                <TableCell className="LeaderColHeadBlack">Name</TableCell>

                <TableCell className="LeaderColHeadBlack">School </TableCell>
                <TableCell className="LeaderColHeadBlack"> Location</TableCell>
                <TableCell className="LeaderColHeadBlack">Playpoints</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData?.map((post, index) => (
                <TableRow key={index}>
                  <TableCell className="LeaderCol700WAB">
                    {post?.rank}
                  </TableCell>
                  <TableCell className="LeaderCol700WAB">
                    {post?.name}
                  </TableCell>
                  <TableCell className="LeaderCol700WAB">
                    {" "}
                    {post?.schoolName ? post?.schoolName : "N/A"}{" "}
                  </TableCell>{" "}
                  <TableCell className="LeaderCol700WAB">
                    {" "}
                    {post?.location ? post?.location : "N/A"}
                  </TableCell>
                  <TableCell className="LeaderCol700WAB">
                    {" "}
                    {post?.totalPP}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
};

export default LearnerTabPanel;

import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Box,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  IconButton,
  DialogActions,
  FormControl,
  Select,
} from "@material-ui/core";
//React Icons
import { IoIosAddCircle } from "react-icons/io";
import { useLogin } from "Context/ExportContext";
import { FETCH_URL } from "fetchIp";
import { setuid } from "process";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dataURItoBlob } from "dropzone";
export default function ExtendedTab({
  handleClose,
  getUserDetails,
  setSnackOpen,
  setSnackMsg,
  setSnackerropen,
  setSnackErrMsg,
}) {
  const { userToken } = useLogin();
  const [uid, setUid] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [role, setRole] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [address, setAddress] = useState(null);

  const [schoolId, setSchoolId] = useState("");
  const [schoolUid, setSchoolUid] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [location, setLocation] = useState("");
  const [locationSelected, setLocationSelected] = useState("");

  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [email, setEmail] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState();
  const [departMentDetail, setDepartMentDetails] = useState();
  const [schoolLoc, setSchoolLoc] = useState();
  const [error, setError] = useState();

  const [departMent, setDepartMent] = useState([]);
  const [postalData, setPostalData] = React.useState([]);
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const imageUid =
    departMentDetail &&
    Object.keys(departMentDetail).length > 0 &&
    departMentDetail.msg.map((imgUid) => imgUid.uid);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const departMentName = departMent.departmentName;
  const departmentId = departMent._id;
  const departmentSchoolId = departMent.departmentSchoolId;

  console.log("getUserDetails", getUserDetails);
  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const imageUpload = async (userId) => {
    console.log(" User Id Check", userId);

    const formdata = new FormData();
    formdata.set("userId", userId);
    formdata.set("uid", uid);
    formdata.append("file", image);

    // console.log("I am the form data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/auth/addUserImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", res);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      console.error("imgError>>>>>>>>>>>>", error);
    }
  };

  const submitClick = async (e) => {
    e.preventDefault();
    if (!isEmail(email)) {
      setError("invalid email");
    } else {
      setError("");
      const response = await fetch(`${FETCH_URL}/api/auth/createUser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          uid,
          fullName,
          role,
          phoneNo,
          address,
          departmentId,
          assignedSchool:
            role == 2
              ? {}
              : role == 3
              ? { schoolId, schoolUid, schoolName, location }
              : {},
          location,
          dateOfJoining,
          email,
          state,
          zipCode,
          city,
          country,
        }),
      });
      let res = await response.json();
      console.log(res, "whats upppppppppppppp");
      let userId = res.msg2;
      if (userId) {
        imageUpload(userId);
        console.log("Response after create >> ", res.msg2);
      }
      if (response.ok) {
        setSnackOpen(true);
        setSnackMsg(res.msg);
        getUserDetails();
        handleClose();
        console.log("I am the response after users data upload >>", res);
      } else {
        console.log("errorrrrrrrrrr", res);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    }
  };

  ////////  api call for Deparment details ////////
  const getDepartmentDetails = async () => {
    try {
      const response = await fetch(`${FETCH_URL}/api/auth/getAllDepartments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        console.log("My response of DepartmentDetails==>", res);
        setDepartMentDetails(res);
      } else {
        console.log("Error while fetching DepartmentDetails", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getSchoolLocationBySchoolName = async (nameOfSchool) => {
    try {
      const response = await fetch(
        // `${FETCH_URL}/api/school/getDistinctScholAndLocation?schoolName=${nameOfSchool}`,
        `${FETCH_URL}/api/school/getSchoolLocationBySchoolName?schoolName=${nameOfSchool}`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const datas = res.msg?.map((data) => {
          return {
            schoolId: data._id,
            schoolName: data.organisationDetails.schoolName,
            location: data.organisationDetails.location,
            schoolUid: data.schoolUid,
          };
        });
        setSchoolLoc(datas);
        console.log("My Distinct schools ============>", res);
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////  api call for Deparment details ////////

  React.useEffect(() => {
    getDepartmentDetails();
    // setRole()
  }, []);
  // console.log(departMent, "departMent>>>>>>>>>>>>>>>>>.its me>>>>>>");

  const [postCodeMsg, setPostCodeMsg] = React.useState("");

  const searchByPostalCode = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    console.log(response, "response");
    if (response.ok) {
      const Postaldata = res;
      console.log(res, "resres");
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setPostCodeMsg("please enter a valid post code!");
        setCity("");
        setState("");
        setCountry("");
        return;
      }
      setPostCodeMsg("");
      setPostalData(Postaldata);
      setCity(Postaldata?.map((a) => a?.PostOffice[0].District)[0]);
      setState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setCountry(Postaldata?.map((a) => a?.PostOffice[0].Country)[0]);
    }
  };
  React.useEffect(() => {
    if (String(zipCode).length === 6) {
      searchByPostalCode(zipCode);
    } else {
      console.log("iiiiiiiiiiiiiiiiiii");
    }
    if (String(zipCode).length < 6) {
      setCity("");
      setState("");
      setCountry("");
    }
  }, [zipCode]);
  return (
    <>
      <Grid container className="DailogLTRBStyle">
        <Grid container spacing={4} justify="flex-start">
          <Grid item md={6} sm={6} xs={6} lg={6}>
            <Grid
              container
              direction="column"
              item
              style={{ marginLeft: "8%" }}
            >
              <Typography className="UserTitleTypography GreyColorTypography">
                Full Name <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                UID
                <span style={{ color: "red" }}> *</span>
              </Typography>{" "}
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                {" "}
                <Input
                  className="Input1"
                  onChange={(e) => setUid(e.target.value)}
                  value={uid}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Role <span style={{ color: "red" }}> *</span>
              </Typography>
              {/* <Typography className="UserTitleTypography GreyColorTypography">
                Role <span className="ImpColor"> *</span>
              </Typography> */}
              <Grid container item style={{ width: "83.8%" }}>
                <FormControl variant="standard" className="FormControlInput">
                  <Select
                    className=" SkillsSelectStyle "
                    value={role ? role : 0}
                    onChange={(e) => setRole(e.target.value)}
                    color="white"
                    label="Locomotor"
                    disableUnderline
                  >
                    {/* <MenuItem className="Selectmenustyle" value={0}>
                      Superadmin
                    </MenuItem> */}
                    <MenuItem className="Selectmenustyle" value={1}>
                      Sub-Admin
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={2}>
                      Hos
                    </MenuItem>
                    <MenuItem className="Selectmenustyle" value={3}>
                      Principal
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input className="Input1" 
                value={role}
                onChange={(e)=>setRole(e.target.value)}> </Input>
              </Grid> */}
              <Typography className="UserTitleTypography GreyColorTypography">
                Mobile Number <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onChange={(e) => setPhoneNo(e.target.value.slice(0, 10))}
                  value={phoneNo}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Address <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="UserTextArea"
                  aria-label="minimum height"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </Grid>
              <Grid
                item
                style={{
                  borderBottom: "1px solid #2E3180",
                  backgroundColor: "white",
                  width: "96%",
                }}
              >
                <Typography
                  style={{ marginLeft: "15px", width: "100%" }}
                  className="LPTypo BlueColorTypography"
                >
                  Add Thumbnail
                  <img
                    src={file}
                    style={{
                      height: "100px",
                      width: "340px",
                      marginTop: "10px",
                    }}
                  />
                </Typography>{" "}
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="raised-button-file"
                  multiple
                  accept="image/*"
                  onChange={ImageUploader}
                />{" "}
                <label htmlFor="raised-button-file">
                  <Button
                    style={{ margin: "15px 15px" }}
                    className="GreenButton700  "
                    variant="raised"
                    component="span"
                    // className={classes.button}
                  >
                    <IoIosAddCircle className="AddCircleIcon" />
                    Choose File
                  </Button>
                </label>
                {/* <input
                className="   LPATChooseButton "
                type="file"
                id="image-input"
                accept="image/jpeg, image/png, image/jpg"
              /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={6}
            lg={6}
            direction="column"
            style={{ marginTop: "1px" }}
          >
            <Grid container direction="column" item>
              <Typography className="InputTitleTypography GreyColorTypography">
                DepartMent<span className="ImpColor"> *</span>
              </Typography>
              <Grid container item md={10} sm={10} xs={10}>
                <FormControl
                  variant="standard"
                  className="FormControlInput colorwhite"
                >
                  <Select
                    className=" SkillsSelectStyle "
                    value={departMent}
                    onChange={(e) => {
                      setDepartMent(e.target.value);
                      setSchoolName(e.target.value.departmentName);
                      getSchoolLocationBySchoolName(
                        e.target.value.departmentName
                      );
                      // getSchoolLocation(e.target.value);
                    }}
                    onKeyDown={handleEnter}
                    color="white"
                    label="Locomotor"
                    disableUnderline
                  >
                    {departMentDetail &&
                    Object.keys(departMentDetail).length > 0 ? (
                      departMentDetail.msg.map((dprData, i) => (
                        <MenuItem
                          key={i}
                          className="Selectmenustyle"
                          value={dprData}
                        >
                          {dprData.departmentName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem className="Selectmenustyle" value={0} disabled>
                        No Department Available
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {role == 3 ? (
                <>
                  <Typography className="InputTitleTypography GreyColorTypography">
                    School Name <span className="ImpColor"> *</span>
                  </Typography>
                  <Grid item container md={10} sm={10} xs={10} lg={10}>
                    <Input
                      className="Input1 "
                      type="text"
                      onKeyDown={handleEnter}
                      required
                      value={schoolName}
                      disabled
                    ></Input>
                  </Grid>
                  <Typography className="InputTitleTypography GreyColorTypography">
                    Location<span className="ImpColor"> *</span>
                  </Typography>
                  <Grid container item md={10} sm={10} xs={10}>
                    <FormControl
                      variant="standard"
                      className="FormControlInput colorwhite"
                    >
                      <Select
                        className=" SkillsSelectStyle"
                        value={locationSelected}
                        onChange={(e) => {
                          setLocationSelected(e.target.value);
                          console.log("loccccc", e.target.value);
                          setSchoolId(e.target.value.schoolId);
                          setSchoolName(e.target.value.schoolName);
                          setLocation(e.target.value.location);
                          setSchoolUid(e.target.value.schoolUid);
                        }}
                        label="Select Location"
                        disableUnderline
                        defaultValue={0}
                      >
                        <MenuItem
                          value={0}
                          className="Selectmenustyle"
                          disabled
                        >
                          Select a location
                        </MenuItem>
                        {schoolLoc &&
                          schoolLoc.length > 0 &&
                          schoolLoc.map((location) => (
                            <MenuItem
                              value={location}
                              className="Selectmenustyle"
                            >
                              {location.location}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : null}

              <Typography className="UserTitleTypography GreyColorTypography">
                Date of joining
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10}>
                <MuiPickersUtilsProvider
                  className="Calender"
                  utils={DateFnsUtils}
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <KeyboardDatePicker
                      className="Calender"
                      // id="date-picker-dialog"
                      format="yyyy/MM/dd"
                      value={dateOfJoining}
                      onChange={(e) => {
                        setDateOfJoining(e);
                      }}
                      onKeyDown={handleEnter}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Email Id
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setError("");
                    } else if (!isEmail(e.target.value)) {
                      setError("invalid email");
                    } else {
                      setError("");
                    }
                    setEmail(e.target.value);
                  }}
                  value={email}
                >
                  {" "}
                </Input>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {error}
                </span>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Zip Code <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value.slice(0, 6))}
                >
                  {" "}
                </Input>
                {postCodeMsg.length > 0 && (
                  <Typography className="warningTypography">
                    {postCodeMsg}
                  </Typography>
                )}
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                City <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                State <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="InputTitleTypography GreyColorTypography">
                Country <span className="ImpColor"> *</span>
              </Typography>
              <Grid item md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions className="">
        <Button
          className="GreenButton700  EquipActionBottom"
          style={{ marginRight: "1%" }}
          onClick={submitClick}
        >
          Save
        </Button>
        {/* <Button
          className="GreyButton700 EquipActionBottom"
          style={{ marginRight: "2.7%" }}
          // onClick={handleClose}
        >
          Cancel
        </Button> */}
      </DialogActions>
    </>
  );
}

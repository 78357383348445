import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import "date-fns";
import Avatar from "@material-ui/core/Avatar";

// React Icons
import ManImage from "assets/img/Man.png";

// export default function LearnerTab(props) {
//   const { learner } = props;

//   return (
//     <>
//       <Grid container className="DailogLTRBStyle-Na94">
//         <Grid container item md={6} sm={6} xs={6}>
//           <Grid container item className="colorwhite    GridStyle2">
//             <Grid container item md={5} sm={5} xs={5}>
//               <Grid container item className="GLGridStyle">
//                 <img
//                   src={learner.learnerDetail.learnerImg}
//                   className="GLearnerProfileImage"
//                 />
//               </Grid>
//             </Grid>
//             <Grid container item md={7} sm={7} xs={7}>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Enrolled on
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     {learner.learnerDetail.enrollmentDate?.split("T")[0]}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Status
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreenColorTypography PesoTypo-1">
//                     Active
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Role
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     Learner
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Level
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     5
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     PP
//                   </Typography>
//                 </Grid>

//                 <Grid
//                   item
//                   container
//                   md={6}
//                   sm={6}
//                   xs={6}
//                   className="Mb"
//                   style={{ marginBottom: "20px" }}
//                 >
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     600
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid item md={6} sm={6} xs={6} direction="column">
//           <Grid container item className="colorwhite    GridStyle2">
//             <Grid container item md={12} sm={12} xs={12}>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Grade
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.academicDetails.grade}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Courses
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     2/5
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Lesson Plan
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     5/15
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Attendance
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     85%
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Score
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   container
//                   md={6}
//                   sm={6}
//                   xs={6}
//                   style={{ marginBottom: "20px" }}
//                 >
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     80%
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid container item className="PDGridContainer2">
//           <Grid
//             container
//             item
//             md={4}
//             sm={4}
//             xs={4}
//             direction="column"
//             className="GridStyleMain"
//           >
//             <Grid container item md={11} sm={11} xs={11} className="colorwhite">
//               <Grid item conatiner className="DetailsTypo-3">
//                 <Typography
//                   align="center"
//                   className="BlueColorTypography DetailsTypo-3  "
//                 >
//                   Personal Details
//                 </Typography>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Full Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.learnerDetail.fullName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Gender
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     Male
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Date of Birth
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.learnerDetail.dob?.split("T")[0]}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Father Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.learnerDetail.fathersName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Mother Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.learnerDetail.mothersName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     City
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.learnerCity}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Zipcode
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.learnerZipCode}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item md={5} sm={5} xs={5} direction="column">
//             <Grid container item md={11} sm={11} xs={11} className="colorwhite">
//               <Typography
//                 align="center"
//                 className="BlueColorTypography   DetailsTypo-3 "
//               >
//                 Contact Details
//               </Typography>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Mobile Number
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.learnerPhoneNo}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Email ID
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.learnerEmail}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Full Address
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.learnerAddress}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Guardian Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.guardianFullName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Guardian Number
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6} className="MBCard5">
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {learner.contactDetail.guardianPhoneNo}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item md={3} sm={3} xs={3} direction="column">
//             <Grid container item md={12} sm={12} xs={12} className="colorwhite">
//               <Typography
//                 align="center"
//                 className="BlueColorTypography  DetailsTypo-3 "
//               >
//                 Fee Details
//               </Typography>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Total Fee
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     5000rs.
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Paid Fee
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     2000rs.
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Pending Fee
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6} className="MBCard6">
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     3000rs.
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

export default function LearnerTab(props) {
  const { learner, fmsScore, pp, totalPP } = props;
  const [locopp, setLocoPP] = React.useState(0);
  const [objpp, setObjPP] = React.useState(0);
  const [bodypp, setBodyPP] = React.useState(0);
  React.useEffect(() => {
    if (pp.length > 0) {
      pp.map((data) => {
        if (data?._id?.skillName == "Locomotor") {
          setLocoPP(data?.total);
        }
        if (data?._id?.skillName == "Object Control") {
          setObjPP(data?.total);
        }
        if (data?._id?.skillName == "Body management") {
          setBodyPP(data?.total);
        }
      });
    }
  }, [learner]);

  return (
    <>
      <Grid container className="DialogLTB">
        <Grid item md={2.8} className="L-profile-grid">
          <Grid item>
            {learner?.learnerDetail?.learnerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={learner?.learnerDetail?.learnerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",
                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {learner?.learnerDetail?.fullName.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {learner?.learnerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">N/A</Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {learner?.learnerDetail?.regNo ? (
                  learner?.learnerDetail?.regNo
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.enrollmentDate ? (
                  learner?.learnerDetail?.enrollmentDate.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={5}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {fmsScore.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={5}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>
            :
            <Grid md={6}>
              <Typography className="GreyColorTypography ml-8">
                {totalPP}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.dob ? (
                  learner?.learnerDetail?.dob.split("T")[0]
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Gender</Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">N/A</Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.fathersName ? (
                  learner?.learnerDetail?.fathersName
                ) : (
                  <Typography className=" GreyColorTypography">N/A</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>
            :
            <Grid md={5}>
              <Typography className="GreyColorTypography ml-8">
                {" "}
                {learner?.learnerDetail?.mothersName ? (
                  learner?.learnerDetail?.mothersName
                ) : (
                  <Typography className=" GreyColorTypography">
                    Learner
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9} className="ml-12 L-profile-grid ">
          <Grid container>
            <Typography className="width100 BlueColorTypography mb-10">
              Academics
            </Typography>

            <Grid item md={5} className="mb-24">
              <Grid container>
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Courses
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedCourses?.length ? (
                      learner?.assignedCourses?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Lessonplan
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    {learner?.assignedLessonPlans?.length ? (
                      learner?.assignedLessonPlans?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Assessments
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedAssessments?.length ? (
                      learner?.assignedAssessments?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={5} className="mb-24">
              <Grid container>
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Equipment
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    {learner?.assignedEquipments?.length ? (
                      learner?.assignedEquipments?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Equipment KIT
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedEquipmentKits?.length ? (
                      learner?.assignedEquipmentKits?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={7}>
                  <Typography className="BlackColorTypography">
                    Assigned Skills
                  </Typography>
                </Grid>
                <Grid item md={4} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {learner?.assignedSkills?.length ? (
                      learner?.assignedSkills?.length
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ borderTop: "2px solid #dddddd" }}>
              <Typography className="width100 BlueColorTypography mb-10 mt-10">
                FMS Skill
              </Typography>
              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={7}>
                    <Typography className="BlackColorTypography">
                      Locomotor Skill PP
                    </Typography>
                  </Grid>
                  <Grid item md={4} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {locopp}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={7}>
                    <Typography className="BlackColorTypography">
                      Body Management Skill PP
                    </Typography>
                  </Grid>
                  <Grid item md={4} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {bodypp}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={7}>
                    <Typography className="BlackColorTypography">
                      Object Control Skill
                    </Typography>
                  </Grid>
                  <Grid item md={4} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {objpp}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//React Icons
import { IoSchoolOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { GiTeacher } from "react-icons/gi";
import { BsFileEarmark } from "react-icons/bs";
import { useState } from "react";
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [learnerData, setLearnerData] = useState(null);
  const [gradeData, setGradeData] = useState(null);
  const [trainerData, setTrainerData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const { userToken } = useLogin();
  const getAllLearners = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllLearners?pagination=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Learners==>", res);
        setLearnerData(res?.lengthData);
        // history.push("/admin/dashboard");
      } else {
        // console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };

  const getAllGrades = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?pagination=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My  All Grade Get response ==>", res);
        setGradeData(res?.lengthData);
      } else {
        //.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      //.log("Catch block ====>", error);
    }
  };

  const getAllTrainers = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?pagination=true&limit =10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Trainers==>", res);
        setTrainerData(res?.lengthData);
      } else {
        // console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };

  const getAllSchool = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/getAllSchools?pagination=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("My response of Learners==>", res);
        setRowData(res?.lengthData);
      } else {
        // console.log("Error while fetching single school", res.err);
      }
    } catch (error) {
      // console.log("Catch block ====>", error);
    }
  };
  ////////school detail api/////////
  React.useEffect(() => {
    getAllSchool();
    getAllLearners();
    getAllGrades();
    getAllTrainers();
  }, []);
  return (
    <div>
      <GridContainer style={{ marginTop: "40px " }}>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link to="/admin/school" style={{ width: "100%" }}>
            <Card className="cardStyle">
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <IoSchoolOutline />
                </CardIcon>
                <p className={classes.cardCategory}>School</p>
                <h3 className={classes.cardTitle}>{rowData ?? 0}</h3>
              </CardHeader>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link to="/admin/grade" style={{ width: "100%" }}>
            <Card className="cardStyle">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <BsFileEarmark />
                </CardIcon>
                <p className={classes.cardCategory}>Grade</p>
                <h3 className={classes.cardTitle}>{gradeData ?? 0}</h3>
              </CardHeader>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link to="/admin/learner" style={{ width: "100%" }}>
            <Card className="cardStyle">
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <AiOutlineUser />
                </CardIcon>
                <p className={classes.cardCategory}>Learner </p>
                <h3 className={classes.cardTitle}>{learnerData ?? 0}</h3>
              </CardHeader>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link to="/admin/trainer" style={{ width: "100%" }}>
            <Card className="cardStyle">
              <CardHeader stats icon>
                <CardIcon color="danger">
                  <GiTeacher color="white" />
                </CardIcon>
                <p className={classes.cardCategory}>Trainer</p>
                <h3 className={classes.cardTitle}>{trainerData ?? 0}</h3>
              </CardHeader>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  IconButton,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import CloneFolder from "../ActionButton/CloneFolder";
import DeleteFolder from "../ActionButton/DeleteFolder";
import RenameFolder from "../ActionButton/RenameFolder";
import { TfiViewList } from "react-icons/tfi";
import { IoCloseSharp } from "react-icons/io5";
import { HiMenu } from "react-icons/hi";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Folder Actions
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function SimpleDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    setMenuOpen,
    // folders,
    // PageID,
    // getFolderPages,
    // page,
    // folderId,
    // setSnackOpen,
    // setSnackMsg,
    folder,
    getFolders,
    FolderID,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      PaperProps={{
        className: "BootstrapSmallDialog",
      }}
      maxWidth="lg"
      open={open}
      classes={{ paper: classes.dialogWrapper }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
      ></BootstrapDialogTitle>
      {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}
      <DialogContent dividers={false} className="TabPanelStyle">
        <Grid
          container
          justifyContent="space-between"
          style={{ margin: "5px", padding: "20x" }}
        >
          <Grid item md={4} style={{ width: "140px" }}>
            <RenameFolder
              folder={folder}
              getFolders={getFolders}
              FolderID={FolderID}
              onClose={handleClose}
            />
          </Grid>
          <Grid item md={4} style={{ width: "140px" }}>
            <CloneFolder
              getFolders={getFolders}
              FolderID={FolderID}
              onClose={handleClose}
            />
          </Grid>

          <Grid item md={4} style={{ width: "140px" }}>
            <DeleteFolder
              getFolders={getFolders}
              FolderID={FolderID}
              onClose={handleClose}
            />
          </Grid>
          {/* <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick("addAccount")}
        >
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem> */}
          <Grid item md={12} justifyContent="flex-end" alignItems="flex-end">
            <DialogActions className="SGradeBottom ResponsiveTableAL">
              <Button className="GreenButton GradeSaveB " onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function MenuForFolderDialog({ folder, getFolders, FolderID }) {
  const [open, setMenuOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setMenuOpen(true);
  };

  const handleClose = (value) => {
    setMenuOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <HiMenu
        onClick={handleClickOpen}
        style={{ fontSize: "14px", marginRight: "10px" }}
      />
      <br />
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setMenuOpen={setMenuOpen}
        folder={folder}
        getFolders={getFolders}
        FolderID={FolderID}
        // folders={folders}
        // PageID={PageID}
        // getFolderPages={getFolderPages}
        // page={page}
        // folderId={folderId}
        // setSnackOpen={setSnackOpen}
        // setSnackMsg={setSnackMsg}
        // setSnackerropen={setSnackerropen}
        // setSnackErrMsg={setSnackErrMsg}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tab,
  Tabs,
} from "@material-ui/core";

// core components
import EquipmentTab from "./EquipmentTab's/EquipmentTab";
import KitTab from "./EquipmentTab's/KitTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { setPageNo, pageNo, handleSearch } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EquipmnetTabs(props) {
  const {
    states: {
      equipmentData,
      totalEquipmentData,
      equipmentKit,
      value,
      setValue,
      schoolSelected,
      searchDataE,
      searchDataEK,
      pageNoE,
      setPageNoE,
      totalE,
      setTotalE,
      rowsPerPageE,
      setRowsPerPageE,
      pageNoEK,
      setPageNoEK,
      totalEK,
      setTotalEK,
      rowsPerPageEK,
      setRowsPerPageEK,
    },
    getAllEquipment,
    getAllEquipmentKIT,
    TabChange,
    handleSearchE,
    handleSearchEK,
  } = props;

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Equipment"
            {...a11yProps(0)}
          />
          <Tab className="ActivityTabChanges" label="Kit" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <EquipmentTab
          states={{
            equipmentData,
            searchDataE,
            schoolSelected,
            pageNoE,
            setPageNoE,
            totalE,
            setTotalE,
            rowsPerPageE,
            setRowsPerPageE,
          }}
          getAllEquipment={getAllEquipment}
          handleSearchE={handleSearchE}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KitTab
          states={{
            equipmentKit,
            equipmentData,
            schoolSelected,
            totalEquipmentData,
            pageNoEK,
            searchDataEK,
            setPageNoEK,
            totalEK,
            setTotalEK,
            rowsPerPageEK,
            setRowsPerPageEK,
          }}
          getAllEquipmentKIT={getAllEquipmentKIT}
          handleSearchE={handleSearchE}
          handleSearchEK={handleSearchEK}
        />
      </TabPanel>
    </>
  );
}

import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import "date-fns";

// React Icons
import ManImage from "assets/img/Man.png";

export default function LearnerTab(props) {
  const { learner } = props;

  return (
    <>
      <Grid container className="DailogLTRBStyle-Na94">
        <Grid container item md={6} sm={6} xs={6}>
          <Grid container item className="colorwhite    GridStyle2">
            <Grid container item md={5} sm={5} xs={5}>
              <Grid container item className="GLGridStyle">
                <img
                  src={learner?.learnerDetail.learnerImg}
                  className="GLearnerProfileImage"
                />
              </Grid>
            </Grid>
            <Grid container item md={7} sm={7} xs={7}>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography PesoTypo1">
                    Enrolled on
                  </Typography>
                </Grid>

                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo-1">
                    {learner.learnerDetail.enrollmentDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography PesoTypo1">
                    Status
                  </Typography>
                </Grid>

                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreenColorTypography PesoTypo-1">
                    Active
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography PesoTypo1">
                    Role
                  </Typography>
                </Grid>

                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo-1">
                    Learner
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography PesoTypo1">
                    Level
                  </Typography>
                </Grid>

                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo-1">
                    5
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography PesoTypo1">
                    PP
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  md={6}
                  sm={6}
                  xs={6}
                  className="Mb"
                  style={{ marginBottom: "20px" }}
                >
                  <Typography className="GreyColorTypography PesoTypo-1">
                    600
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} direction="column">
          <Grid container item className="colorwhite    GridStyle2">
            <Grid container item md={12} sm={12} xs={12}>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Grade
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.academicDetails.grade}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Courses
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    2/5
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Lesson Plan
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    5/15
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Attendance
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    85%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Score
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography className="GreyColorTypography PesoTypo1">
                    80%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className="PDGridContainer2">
          <Grid
            container
            item
            md={4}
            sm={4}
            xs={4}
            direction="column"
            className="GridStyleMain"
          >
            <Grid container item md={11} sm={11} xs={11} className="colorwhite">
              <Grid item conatiner className="DetailsTypo-3">
                <Typography
                  align="center"
                  className="BlueColorTypography DetailsTypo-3  "
                >
                  Personal Details
                </Typography>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Full Name
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.learnerDetail.fullName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Gender
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    Male
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Date of Birth
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.learnerDetail.dob}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Father Name
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.learnerDetail.fathersName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Mother Name
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.learnerDetail.mothersName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    City
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.learnerCity}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Zipcode
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.learnerZipCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} sm={5} xs={5} direction="column">
            <Grid container item md={11} sm={11} xs={11} className="colorwhite">
              <Typography
                align="center"
                className="BlueColorTypography   DetailsTypo-3 "
              >
                Contact Details
              </Typography>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Mobile Number
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.learnerPhoneNo}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Email ID
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.learnerEmail}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Full Address
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.learnerAddress}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Guardian Name
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.guardianFullName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Guardian Number
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6} className="MBCard5">
                  <Typography className="GreyColorTypography PesoTypo1">
                    {learner.contactDetail.guardianPhoneNo}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} sm={3} xs={3} direction="column">
            <Grid container item md={12} sm={12} xs={12} className="colorwhite">
              <Typography
                align="center"
                className="BlueColorTypography  DetailsTypo-3 "
              >
                Fee Details
              </Typography>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Total Fee
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    5000rs.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Paid Fee
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="GreyColorTypography PesoTypo1">
                    2000rs.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item container md={6} sm={6} xs={6}>
                  <Typography className="BlueColorTypography CardTypo1">
                    Pending Fee
                  </Typography>
                </Grid>
                <Grid item container md={6} sm={6} xs={6} className="MBCard6">
                  <Typography className="GreyColorTypography PesoTypo1">
                    3000rs.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Grid,
  Input,
  Checkbox,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
//React Icons
import { IoCloseSharp } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleMediuem" {...other}>
      <Typography className="DialogTitleTypographyMediuem">
        Allocate Equipment KIT
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Equip(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const {
  //   states: { equipment, setEquipment },
  // } = props;
  const EquipmentName = [
    {
      labelname: " Magic wand ",
    },
    {
      labelname: "Fluffy Ball ",
    },
    {
      labelname: "Bounce ",
    },
    {
      labelname: "Paddle ",
    },
    {
      labelname: "Fluffy Ball ",
    },
    {
      labelname: "Magic wand  ",
    },
  ];
  return (
    <>
      <div className="CCFB">
        <Button
          className="  GreenButton700  LPSkillsB "
          onClick={handleClickOpen}
        >
          Click here to assign Equipments
        </Button>
      </div>

      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        <DialogContent dividers={false} className="TabPanelStyle">
          <Grid container className="DailogLTRBStyle-Na">
            <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
              {EquipmentName.map((user, key) => (
                <Grid
                  container
                  item
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  className="AssignGridPaper AssignEquipment"
                >
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        className="CheckLabelStyleBlue  BlueColorTypography"
                        label={user.labelname}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    md={1}
                    sm={1}
                    xs={1}
                    lg={1}
                  >
                    <IoCloseSharp className="EquiCloseIcon" />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    md={3}
                    sm={3}
                    xs={3}
                    lg={3}
                  >
                    <Input className="EquipmentInputCheck"> </Input>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {/* Row2 */}
            <Grid item md={4} sm={4} xs={4} lg={4}>
              {EquipmentName.map((user, key) => (
                <Grid
                  container
                  item
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  className="AssignGridPaper AssignEquipment"
                >
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        className="CheckLabelStyleBlue  BlueColorTypography"
                        label={user.labelname}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    md={1}
                    sm={1}
                    xs={1}
                    lg={1}
                  >
                    <IoCloseSharp className="EquiCloseIcon" />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    md={3}
                    sm={3}
                    xs={3}
                    lg={3}
                  >
                    <Input className="EquipmentInputCheck"> </Input>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* Row3 */}
            <Grid item md={4} sm={4} xs={4} lg={4} direction="column">
              {EquipmentName.map((user, key) => (
                <Grid
                  container
                  item
                  md={10}
                  sm={10}
                  xs={10}
                  lg={10}
                  className="AssignGridPaper AssignEquipment"
                >
                  <Grid container item md={8} sm={8} xs={8} lg={8}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        className="CheckLabelStyleBlue  BlueColorTypography"
                        label={user.labelname}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    md={1}
                    sm={1}
                    xs={1}
                    lg={1}
                  >
                    <IoCloseSharp className="EquiCloseIcon" />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    md={3}
                    sm={3}
                    xs={3}
                    lg={3}
                  >
                    <Input className="EquipmentInputCheck"> </Input>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="GreenButton   CourseEquipSB" onClick={handleClose}>
            Save
          </Button>
          <Button
            className=" GreyButton   CourseEquipCB "
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// import * as React from "react";
// import {
//   Grid,
//   Input,
//   Typography,
//   Button,
//   DialogActions,
// } from "@material-ui/core";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

// // React Icons
// import { IoIosAddCircle } from "react-icons/io";
// import ManImage from "assets/img/Man.png";

// export default function LearnerTab(props) {
//   const { trainer } = props;
//   const courseAttempt = trainer.assignedCourses.filter(
//     (data) => data.completion == true
//   );
//   const lessonPlanAttempt = trainer.assignedLessonPlans.filter(
//     (data) => data.completion == true
//   );
//   console.log(" Personal Data", trainer);

//   return (
//     <>
//       <Grid container className="DailogLTRBStyle-Na94">
//         <Grid container item md={6} sm={6} xs={6}>
//           <Grid container item className="colorwhite    GridStyle2">
//             <Grid container item md={5} sm={5} xs={5}>
//               <Grid container item className="GLGridStyle">
//                 <img
//                   src={trainer.trainerDetail.trainerImg}
//                   className="GLearnerProfileImage"
//                 />
//               </Grid>{" "}
//             </Grid>
//             <Grid container item md={7} sm={7} xs={7}>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Enrolled on
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     {trainer.trainerDetail.enrollmentDate.split("T")[0]}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Status
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreenColorTypography PesoTypo-1">
//                     Active
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Trainer
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     Learner
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     Level
//                   </Typography>
//                 </Grid>

//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     5
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography PesoTypo1">
//                     PP
//                   </Typography>
//                 </Grid>

//                 <Grid
//                   item
//                   container
//                   md={6}
//                   sm={6}
//                   xs={6}
//                   className="Mb"
//                   style={{ marginBottom: "20px" }}
//                 >
//                   <Typography className="GreyColorTypography PesoTypo-1">
//                     600
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid item md={6} sm={6} xs={6} direction="column">
//           <Grid container item className="colorwhite    GridStyle2">
//             <Grid container item md={12} sm={12} xs={12}>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Grade
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     5th Grade
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Courses
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {courseAttempt?.length ||
//                     trainer.assignedCourses?.length ? (
//                       <>
//                         {courseAttempt?.length}/
//                         {trainer.assignedCourses?.length}
//                       </>
//                     ) : (
//                       <Typography className=" GreyColorTypography">
//                         N/A
//                       </Typography>
//                     )}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Lesson Plan
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {lessonPlanAttempt?.length ||
//                     trainer.assignedLessonPlans?.length ? (
//                       <>
//                         {lessonPlanAttempt?.length}/
//                         {trainer.assignedLessonPlans?.length}{" "}
//                       </>
//                     ) : (
//                       <Typography className=" GreyColorTypography">
//                         N/A
//                       </Typography>
//                     )}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Attendance
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     85%
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Score
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   container
//                   md={6}
//                   sm={6}
//                   xs={6}
//                   style={{ marginBottom: "20px" }}
//                 >
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     80%
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid container item className="PDGridContainer2">
//           <Grid
//             container
//             item
//             md={4}
//             sm={4}
//             xs={4}
//             direction="column"
//             className="GridStyleMain"
//           >
//             <Grid container item md={11} sm={11} xs={11} className="colorwhite">
//               <Grid item conatiner className="DetailsTypo-3">
//                 <Typography
//                   align="center"
//                   className="BlueColorTypography DetailsTypo-3  "
//                 >
//                   Personal Details
//                 </Typography>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Full Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.trainerDetail.fullName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Gender
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.trainerDetail.gender}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Date of Birth
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.trainerDetail.dob.split("T")[0]}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Father Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.trainerDetail.fathersName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Mother Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.trainerDetail.mothersName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     City
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.trainerState}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Zipcode
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.trainerZipCode}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item md={5} sm={5} xs={5} direction="column">
//             <Grid container item md={11} sm={11} xs={11} className="colorwhite">
//               <Typography
//                 align="center"
//                 className="BlueColorTypography   DetailsTypo-3 "
//               >
//                 Contact Details
//               </Typography>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Mobile Number
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.trainerPhoneNo}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Email ID
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.trainerEmail}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Full Address
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.trainerAddress}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Guardian Name
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.guardianFullName}
//                   </Typography>
//                 </Grid>
//               </Grid>
//               <Grid item container direction="row">
//                 <Grid item container md={6} sm={6} xs={6}>
//                   <Typography className="BlueColorTypography CardTypo1">
//                     Guardian Number
//                   </Typography>
//                 </Grid>
//                 <Grid item container md={6} sm={6} xs={6} className="MBCard5">
//                   <Typography className="GreyColorTypography PesoTypo1">
//                     {trainer.contactDetail.guardianPhoneNo}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

import * as React from "react";
import {
  Grid,
  Input,
  Typography,
  Button,
  DialogActions,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// React Icons
import { IoIosAddCircle } from "react-icons/io";
import ManImage from "assets/img/Man.png";

export default function trainerTab({ trainer, fmsScore, totalPP }) {
  console.log(" Personal Data", trainer);
  const courseAttempt = trainer.assignedCourses.filter(
    (data) => data.completion == true
  );
  const lessonPlanAttempt = trainer.assignedLessonPlans.filter(
    (data) => data.completion == true
  );
  const newData = new Set();
  trainer.assignedSchool.map((x) => {
    newData.add(x.schoolId);
  });

  return (
    <>
      <Grid container className="DailogLTRBStyle-95">
        <Grid item className="L-profile-grid width-23">
          <Grid item className="width80-LR">
            {trainer?.trainerDetail?.trainerImg ? (
              <img
                style={{
                  height: "210px",
                  width: "210px",
                  borderRadius: "10px",
                }}
                src={trainer?.trainerDetail?.trainerImg}
              />
            ) : (
              <>
                <Avatar
                  style={{
                    height: "210px",
                    width: "210px",

                    borderRadius: "10px",
                    fontSize: "60px",
                  }}
                  variant="rounded"
                >
                  {trainer?.trainerDetail?.fullName.slice(" ")[0][0]}
                </Avatar>
              </>
            )}
          </Grid>
          <Typography align="center" className="BlueColorTypography mt-14">
            {trainer?.trainerDetail?.fullName}
          </Typography>

          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> Role</Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">Trainer</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography "> UID</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.regNo ? (
                    trainer?.trainerDetail?.regNo
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Enrolled on
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.enrollmentDate ? (
                    trainer?.trainerDetail?.enrollmentDate.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                FMS Score
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span> <span className="pl-5">{fmsScore}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className="mt-10 pb-10"
            style={{ borderBottom: "2px solid #dddddd" }}
          >
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Total PP</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span> <span className="pl-5">{totalPP}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Date of birth
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? (
                    trainer?.trainerDetail?.dob.split("T")[0]
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">Gender</Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.dob ? "N/A" : <span>N/A</span>}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Father’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.fathersName ? (
                    trainer?.trainerDetail?.fathersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="mt-10">
            <Grid md={6}>
              <Typography className="BlueColorTypography ">
                Mother’s name
              </Typography>
            </Grid>

            <Grid md={6}>
              <Typography className="GreyColorTypography ">
                <span> : </span>
                <span className="pl-5">
                  {trainer?.trainerDetail?.mothersName ? (
                    trainer?.trainerDetail?.mothersName
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="ml-12 L-profile-grid width-75">
          <Grid container>
            <Typography className="width100 BlueColorTypography mb-10">
              Academic Detail
            </Typography>
            {/* 
            <Grid item md={5} className="mb-10">
              <Grid container>
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    School Name
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {trainer?.academicDetails?.schoolName ? (
                      trainer?.academicDetails?.schoolName
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Location
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    {trainer?.academicDetails?.location ? (
                      trainer?.academicDetails?.location
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Grade
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {trainer?.academicDetails?.grade ? (
                      trainer?.academicDetails?.grade
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Division
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">N/A</Typography>
                </Grid>
              </Grid>{" "}
              <Grid container className="mt-10">
                <Grid item md={5}>
                  <Typography className="BlackColorTypography">
                    Roll Number
                  </Typography>
                </Grid>
                <Grid item md={6} className="ml-8">
                  <Typography className="DarkBlackColorTypo">
                    {" "}
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.academicDetails?.rollNumber ? (
                        trainer?.academicDetails?.rollNumber
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
            {/* <Grid item md={1}></Grid> */}
            <Grid item md={5} className="mb-10">
              <Grid container>
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    School
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {newData?.size ? (
                      newData?.size
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    Grade
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {trainer.assignedSchool && trainer.assignedSchool.length > 0
                      ? trainer.assignedSchool.length
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    Courses
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {courseAttempt?.length ||
                    trainer.assignedCourses?.length ? (
                      <>
                        {courseAttempt?.length}/
                        {trainer.assignedCourses?.length}
                      </>
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="mt-10">
                <Grid item md={6}>
                  <Typography className="BlackColorTypography">
                    Lesson Plan
                  </Typography>
                </Grid>
                <Grid item md={5} className="ml-8">
                  <Typography className="GreyColorTypography ml-8">
                    {lessonPlanAttempt?.length ||
                    trainer.assignedLessonPlans?.length ? (
                      <>
                        {lessonPlanAttempt?.length}/
                        {trainer.assignedLessonPlans?.length}{" "}
                      </>
                    ) : (
                      <Typography className=" GreyColorTypography">
                        N/A
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ borderTop: "2px solid #dddddd" }}>
              <Typography className="width100 BlueColorTypography mb-10 mt-10">
                Contact Details
              </Typography>
              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Mobile Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerPhoneNo ? (
                        trainer?.contactDetail?.trainerPhoneNo
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Email ID
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerEmail ? (
                        trainer?.contactDetail?.trainerEmail
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Full Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerAddress ? (
                        trainer?.contactDetail?.trainerAddress
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Pincode
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerZipCode ? (
                        trainer?.contactDetail?.trainerZipCode
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>{" "}
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      City
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerCity ? (
                        trainer?.contactDetail?.trainerCity
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      State
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {" "}
                      {trainer?.contactDetail?.trainerState ? (
                        trainer?.contactDetail?.trainerState
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Country
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.trainerCountry ? (
                        trainer?.contactDetail?.trainerCountry
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>

              <Grid item md={5} className="mb-10">
                <Grid container>
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Name
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianFullName ? (
                        trainer?.contactDetail?.guardianFullName
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Location
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianLocation ? (
                        trainer?.contactDetail?.guardianLocation
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Email
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianEmail ? (
                        trainer?.contactDetail?.guardianEmail
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-10">
                  <Grid item md={5}>
                    <Typography className="BlackColorTypography">
                      Guardian Mob No.
                    </Typography>
                  </Grid>
                  <Grid item md={6} className="ml-8">
                    <Typography className="DarkBlackColorTypo">
                      {trainer?.contactDetail?.guardianPhoneNo ? (
                        trainer?.contactDetail?.guardianPhoneNo
                      ) : (
                        <Typography className=" GreyColorTypography">
                          N/A
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";

import {
  Input,
  InputAdornment,
  FormControl,
  IconButton,
  Typography,
  Button,
  TextareaAutosize,
  Grid,
  DialogActions,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  FormGroup,
  Box,
} from "@material-ui/core";
//React Icons
import { GoSearch } from "react-icons/go";

export default function SelectSchoolTab(props) {
  const {
    states: {
      trainerData,
      schoolLoc,
      setSchoolLoc,
      schoolNames,
      setSchoolNames,
      schoolSelected,
      setSchoolSelected,
      schoolLocationSelected,
      setSchoolLocationSelected,
      locationForGrade,
      setLocationForGrade,
      selectGrade,
      setSelectGrade,
      gradeSelected,
      setGradeSelected,
    },
    storeSkill,
    getDistinctSchoolAndLocation,
    getGrades,
    getPaginatedTrainer,
    handleSearch,
    originalData,
    selectTrainerText,
    filterdata,
  } = props;
  console.log("Trainer Data", trainerData);
  React.useEffect(() => {
    getDistinctSchoolAndLocation();
  }, []);

  return (
    <>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{ marginLeft: "2%" }}
        >
          <Grid item container>
            <Grid item container className="ParticipantStyleSelect">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" DialogSelectdropstyle"
                  //   value={school ? school : 0}
                  //   onChange={handleChange}
                  value={schoolSelected ? schoolSelected : 0}
                  onChange={(e) => {
                    getDistinctSchoolAndLocation(e.target.value);
                    setSchoolSelected(e.target.value);
                  }}
                  label="Select School"
                  color="white"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select school
                  </MenuItem>
                  {schoolNames &&
                    schoolNames.length > 0 &&
                    schoolNames.map((school) => (
                      <MenuItem value={school._id} className="Selectmenustyle">
                        {school._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="ParticipantStyleSelect1">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={schoolLocationSelected ? schoolLocationSelected : 0}
                  onChange={(e) => {
                    setSchoolLocationSelected(e.target.value);
                    getGrades(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Location
                  </MenuItem>
                  {schoolLoc &&
                    schoolLoc.length > 0 &&
                    schoolLoc.map((location) => (
                      <MenuItem
                        value={location._id}
                        className="Selectmenustyle"
                      >
                        {location._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="ParticipantStyleSelect1">
              <FormControl className="MainPageFormControl">
                <Select
                  className=" Selectdropstyle"
                  value={gradeSelected ? gradeSelected : 0}
                  onChange={(e) => {
                    getPaginatedTrainer(e.target.value);
                    setGradeSelected(e.target.value);
                  }}
                  label="Select Location"
                  disableUnderline
                >
                  <MenuItem className="Selectmenustyle" value={0}>
                    Select Grade
                  </MenuItem>
                  {selectGrade &&
                    selectGrade.length > 0 &&
                    selectGrade.map((a) => (
                      <MenuItem className="Selectmenustyle" value={a._id}>
                        {a._id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Typography className="GreyColorTypography   OrStyleTypography">
              OR
            </Typography>{" "}
            <div style={{ display: "flex", marginLeft: "8px" }}>
              <Box style={{ marginTop: "10px" }}>
                <div>
                  <FormControl className="AssignLPinput">
                    <Input
                      className="input"
                      disableUnderline
                      placeholder="Search"
                      onChange={(e) => {
                        console.log("Search Value =========>", e.target.value); // Add debouncing for this
                        // setSearchVal(e.target.value);
                        handleSearch(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className="HeadSearchButton">
                            <GoSearch className=" HaedAddIcon" />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Serach"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
          </Grid>
          {trainerData &&
            trainerData.length > 0 &&
            trainerData.map((x, i) => (
              <Grid
                container
                item
                md={4}
                direction="row"
                style={{ marginTop: "15px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        originalData.length > 0 &&
                        originalData[i] !== null &&
                        originalData[i] !== undefined
                          ? true
                          : false
                      }
                    />
                  }
                  onChange={(e) => storeSkill(e, x, i)}
                  value={x.trainerDetail.fullName}
                  className=" AssignActivitesGridPaper  AssignActivites  CheckLabelStyleBlue "
                  label={
                    <Typography style={{ paddingLeft: "15px" }}>
                      {x.trainerDetail.fullName}
                      <span style={{ paddingLeft: "20px" }}>
                        {x.trainerDetail.regNo}
                      </span>
                    </Typography>
                  }
                />
              </Grid>
            ))}
          {filterdata.length === 0 && (
            <Grid container style={{ marginTop: "10px" }}>
              <Typography className="warningTypography">
                {selectTrainerText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import {
  MenuItem,
  Box,
  Grid,
  TextareaAutosize,
  Input,
  Typography,
  Button,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
//React Icons
import { IoIosAddCircle } from "react-icons/io";
import { useState } from "react";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ExtendedTab(props) {
  const { userToken } = useLogin();
  const { getDepartmentDetails, dptData, dePartmentId, setOpen } = props;
  const [uid, setUid] = useState(null);
  const [pointOfContact, setPointOfContact] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [address, setAddress] = useState(null);
  const [departmentName, setDepartment] = useState(null);
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [email, setEmail] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [location, setLocation] = useState(null);
  const [schoolData, setSchoolData] = useState([]);
  const [image, setImage] = React.useState("");
  const [file, setFile] = useState(dptData?.imageUrl);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [postCodeMsg, setPostCodeMsg] = React.useState("");
  const [error, setError] = useState();

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [scroll, setScroll] = React.useState("paper");
  console.log(dptData, "dptData>>>>>>>>>>>>>>>");
  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  const DialogClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function ImageUploader(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  const imageUpload = async (departmentId) => {
    const formdata = new FormData();
    formdata.set("departmentId", departmentId);
    formdata.set("uid", uid);
    formdata.append("file", image);

    // console.log("I am th eform data >> ", image);
    // return;
    try {
      const responce = await fetch(`${FETCH_URL}/api/auth/addDepartmentImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formdata,
      });
      let res = await responce.json();
      if (responce.ok) {
        console.log("I am the response after image upload >>", responce);
      } else {
        // setSnackOpen(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const searchByPostalCode = async (pincode) => {
    setCity("");
    setState("");
    setCountry("");
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    const res = await response.json();
    console.log(response, "response");
    if (response.ok) {
      const Postaldata = res;
      console.log(res, "resres");
      if (res[0].PostOffice === null || res[0].PostOffice === undefined) {
        setPostCodeMsg("please enter a valid post code!");

        return;
      }
      setPostCodeMsg("");
      setCity(Postaldata?.map((a) => a?.PostOffice[0].District)[0]);
      setState(Postaldata?.map((a) => a?.PostOffice[0].State)[0]);
      setCountry(Postaldata?.map((a) => a?.PostOffice[0].Country)[0]);
    }
  };
  React.useEffect(() => {
    if (String(zipCode).length === 6) {
      searchByPostalCode(zipCode);
    } else {
      // setCity("");
      // setState("");
      // setCountry("");
    }
  }, [zipCode]);

  const submitClick = async (e) => {
    e.preventDefault();
    const responce = await fetch(
      `${FETCH_URL}/api/auth/editDepartment/${dePartmentId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          // fullName: fullName ? fullName : dptData.fullName,
          // role: role ? role : dptData.role,
          // phoneNo: phoneNo ? phoneNo : dptData.phoneNo,
          pointOfContact: pointOfContact
            ? pointOfContact
            : dptData.pointOfContact,
          phoneNo: phoneNo ? phoneNo : dptData.phoneNo,
          state: address ? address : dptData.address,
          location: location ? location : dptData.location,
          dateOfJoining: dateOfJoining ? dateOfJoining : dptData.dateOfJoining,
          state: state ? state : dptData.state,
          zipCode: zipCode ? zipCode : dptData.zipCode,
          city: city ? city : dptData.city,
          country: country ? country : dptData.country,
        }),
      }
    );
    let res = await responce.json();
    let departmentId = dptData._id;
    if (departmentId) {
      imageUpload(departmentId);
      console.log("Response after create >> ", departmentId);
    }
    if (responce.ok) {
      setSnackOpen(true);
      setSnackMsg(res.msg);
      getDepartmentDetails();
      setOpen(false);
    } else {
      // setSnackOpen(true);
      console.log("errorrrrrrrrrr", res);
      setSnackerropen(true);
      setSnackErrMsg(res.err);
    }
  };

  return (
    <>
      <Snackbar open={snackopen} autoHideDuration={6000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Grid container className="DailogLTRBStyle">
        <Grid container spacing={4} justify="flex-start">
          <Grid item md={6} sm={6} xs={6} lg={6}>
            <Grid
              container
              direction="column"
              item
              style={{ marginLeft: "8%" }}
            >
              <Typography className="UserTitleTypography GreyColorTypography">
                Organisation
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  defaultValue={dptData.departmentName}
                  disabled
                  value={departmentName}
                  onChange={(e) => setDepartment(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Point Of Contact(POC)<span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  defaultValue={dptData.pointOfContact}
                  value={pointOfContact}
                  onChange={(e) => setPointOfContact(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Mobile Number <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  type="number"
                  defaultValue={dptData.phoneNo}
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value.slice(0, 10))}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Address <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <TextareaAutosize
                  className="UserTextArea"
                  aria-label="minimum height"
                  defaultValue={dptData.address}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid
                item
                container
                md={11}
                sm={11}
                xs={11}
                style={{
                  borderRadius: "15px",
                  // border: "1.2px solid #d4d4d4",
                  marginTop: "3%",
                }}
              >
                {" "}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Typography className=" BlueColorTypography ">
                    Add Image{" "}
                    <img
                      src={file}
                      style={{
                        // height: "100px",
                        // width: "91%",
                        // marginTop: "10px",
                        width: "100%",
                        height: "250px",
                        objectFit: "contain",
                      }}
                    />
                  </Typography>
                  <Grid item className="ChooseFileButtonItem">
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="raised-button-file"
                      accept="image/*"
                      onChange={ImageUploader}
                    />
                  </Grid>
                </div>
                <label htmlFor="raised-button-file">
                  <Button
                    style={{ marginTop: "10px" }}
                    className="GreenButton700 "
                    variant="raised"
                    component="span"
                  >
                    <IoIosAddCircle
                      size="1.6em"
                      style={{ marginRight: "4px" }}
                    />{" "}
                    Choose File
                  </Button>
                </label>{" "}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6} lg={6} direction="column">
            <Grid container direction="column" item>
              <Typography className="UserTitleTypography GreyColorTypography">
                UID
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  defaultValue={dptData.uid}
                  disabled
                  onChange={(e) => setUid(e.target.value)}
                  value={uid}
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Location
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  // defaultValue={dptData.location}
                  defaultValue={dptData.location}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                ></Input>
              </Grid>

              <Typography className="UserTitleTypography GreyColorTypography">
                Date of joining
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  onKeyDown={handleEnter}
                  value={dateOfJoining}
                  defaultValue={dptData.dateOfJoining}
                  onChange={(e) => setDateOfJoining(e.target.value)}
                ></Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Email Id
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={email}
                  defaultValue={dptData.emailId}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setError("");
                    } else if (!isEmail(e.target.value)) {
                      setError("invalid email");
                    } else {
                      setError("");
                    }
                    setEmail(e.target.value);
                  }}
                  // onChange={(e) => setEmail(e.target.value)}
                >
                  {" "}
                </Input>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {error}
                </span>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Zip Code
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={zipCode}
                  defaultValue={dptData.zipCode}
                  onChange={(e) => setZipCode(e.target.value.slice(0, 6))}
                >
                  {" "}
                </Input>
                {postCodeMsg.length > 0 && (
                  <Typography className="warningTypography">
                    {postCodeMsg}
                  </Typography>
                )}
              </Grid>

              <Typography className="UserTitleTypography GreyColorTypography">
                City
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={city}
                  defaultValue={dptData.city}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                State
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={state}
                  defaultValue={dptData.state}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
              <Typography className="UserTitleTypography GreyColorTypography">
                Country
                <span className="ImpColor"> *</span>
              </Typography>
              <Grid item container md={10} sm={10} xs={10} lg={10}>
                <Input
                  className="Input1"
                  value={country}
                  defaultValue={dptData.country}
                  disabled
                >
                  {" "}
                </Input>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions className="">
        <Button
          className="GreenButton700  EquipActionBottom  mr1"
          onClick={submitClick}
        >
          Save
        </Button>
        <Button
          className="GreyButton700 EquipActionBottom mr2-7"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

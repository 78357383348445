import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import LoaderDialog from "components/Loader/LoaderDialog";
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Toolbar,
  Box,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Input,
  InputAdornment,
} from "@material-ui/core";
// material-ui icons
import { GoSearch } from "react-icons/go";
// core components
import TrainerAdd from "./PAddTrainer/TrainerAdd";
import AssignGrade from "./PTrainerActionDialog/TAssignGrade/TAssignGrade";
import TrainerProfile from "./PTrainerProfile/TrainerProfile";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
export default function Pages() {
  const { userToken, schoolDetail, loadingHandler } = useLogin();
  const [trainerData, setTrainerData] = React.useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [serachData, setSerachData] = useState(null);
  const [gradeData, setGradeData] = useState(null);
  const [gradeValue, setGradeValue] = useState(null);
  const [divisionData, setDivisionData] = useState(null);
  const [divisionValue, setDivisionValue] = useState(null);
  // States for dropdowns

  // on dropdown select
  const [schoolSelected, setSchoolSelected] = useState("");
  const [loading, setLoading] = useState(true);

  const onShowSizeChange = (current, pageSize) => {
    console.log("Page size ========>", current, pageSize);
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    setLoading(true);
    let url;
    if (searchValue) {
      url = `${FETCH_URL}/api/learner/getAllTrainers/?search=${searchValue}&schoolId=${schoolDetail?.schoolId}`;
      setPageNo(1);
      setRowsPerPage(10);
      setSchoolSelected("");
    } else {
      url = `${FETCH_URL}/api/learner/getAllTrainers?pagination=true&schoolId=${
        schoolDetail?.schoolId
      }&grade=${gradeValue}&page=${pageNum ? pageNum : 1}&limit=${
        rowSize ? rowSize : 10
      }`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const res = await response.json();
    if (response.ok) {
      setLoading(false);

      setTrainerData(res.msg);
      console.log("My School response from searching ==>", res);
      // getAllGrades();
    } else {
      setLoading(false);

      console.log("Error while searching single school", res.err);
    }
  };

  const getAllTrainers = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getAllTrainers?schoolId=${schoolDetail?.schoolId}&gradeId=${gradeValue}&divisionId=${divisionValue}&pagination=true&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const res = await response.json();
      setLoading(false);
      if (response.ok) {
        loadingHandler(false);
        setTrainerData(res.msg);
        setTotal(res.lengthData);
      } else {
        loadingHandler(false);
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllGrade();
  }, []);
  const getAllGrade = async () => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllGrades?schoolId=${schoolDetail?.schoolId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        // console.log("My response of Trainers==>", res);
        setGradeData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  const getAllGradedivision = async (grade) => {
    loadingHandler(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getAllDivisions?gradeId=${grade}&page=${
          pageNo ? pageNo : 1
        }&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        loadingHandler(false);
        setDivisionData(res.msg);
      } else {
        loadingHandler(false);
        console.log("Error while fetching grades", res.err);
      }
    } catch (error) {
      loadingHandler(false);
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllTrainers();
    setSerachData("");
  }, [gradeValue, divisionValue]);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Manage Trainer</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                <Input
                  disableUnderline
                  className="input"
                  placeholder="Search"
                  value={serachData}
                  onChange={(e) => {
                    console.log("Search Value =========>", e.target.value); // Add debouncing for this
                    // setSearchVal(e.target.value);
                    handleSearch(e.target.value);
                    setSerachData(e.target.value);
                    setGradeValue("");
                    setDivisionValue("");
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton className="HeadSearchButton">
                        <GoSearch className=" HaedAddIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Serach"
                />
              </FormControl>
            </div>
          </Box>
          <TrainerAdd getAllTrainers={getAllTrainers} />
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid container>
        <Grid container item md={2} sm={2} xs={2} lg={2}>
          <Grid item md={9} className="MainPageFCIG">
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={gradeValue ? gradeValue : 0}
                defaultValue={0}
                disableUnderline
                onChange={(e) => {
                  getAllGradedivision(e.target.value);
                  setGradeValue(e.target.value);
                  setDivisionValue(null);
                }}
                label="Select grade"
              >
                <MenuItem value={0} className="Selectmenustyle" disabled>
                  Select grade
                </MenuItem>
                {gradeData?.map((a, i) => (
                  <MenuItem key={i} className="Eventmenustyle" value={a?._id}>
                    {a?.grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={4} lg={4}>
          <Grid item md={6} sm={6} xs={6} lg={4}>
            <FormControl className="MainPageFormControl">
              <Select
                className=" EventDatedropstyle"
                value={divisionValue ? divisionValue : 0}
                defaultValue={0}
                onChange={(e) => {
                  setDivisionValue(e.target.value);
                }}
                label="Select Location"
                disableUnderline
              >
                <MenuItem className="Eventmenustyle" value={0} disabled>
                  Select Division
                </MenuItem>
                {divisionData?.map((a, i) => (
                  <MenuItem className="Eventmenustyle" value={a._id}>
                    {a.division}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head"> UID </TableCell>
              <TableCell className="Head">Trainer's Name </TableCell>
              <TableCell className="Head">Password </TableCell>
              <TableCell className="Head"> No. of Schools </TableCell>
              <TableCell className="Head"> No. of Grade</TableCell>
              <TableCell className="Head">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainerData &&
              trainerData.length > 0 &&
              trainerData.map((trainer) => {
                const newData = new Set();
                trainer.assignedSchool.map((x) => {
                  newData.add(x.schoolId);
                });

                return (
                  <TableRow>
                    <TableCell className="Col">
                      {trainer.trainerDetail.regNo}
                    </TableCell>
                    <TableCell className="Col">
                      <Link>
                        <TrainerProfile
                          trainer={trainer}
                          TrainerId={trainer?._id}
                        />
                      </Link>
                    </TableCell>{" "}
                    <TableCell className="Col700">
                      {trainer.trainerDetail.password}
                    </TableCell>
                    <TableCell className="Col">{newData.size}</TableCell>
                    <TableCell className="Col">
                      {trainer?.assignedSchool &&
                      trainer?.assignedSchool?.length > 0
                        ? trainer?.assignedSchool?.length
                        : "--"}
                    </TableCell>
                    <TableCell className="Col">
                      <AssignGrade
                        trainerId={trainer?._id}
                        getAllTrainers={getAllTrainers}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {trainerData?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Trainer Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {serachData || trainerData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              handleSearch("", value, value2);
              setPageNo(value);
            }}
            pageSize={rowsPerPage}
            total={total}
            current={pageNo}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

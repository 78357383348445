import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
// ReactIcons
import { AiOutlineDelete } from "react-icons/ai";
import { BiWindowClose } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
//core Component

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(70),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="DialogTitleSmall" {...other}>
      <Typography className="DialogTitleTypographySmall">
        Are you sure you want to discard this changes?
      </Typography>
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  savePage,
  folderId,
  PageID,
  folders,
  folder,
}) {
  const classes = useStyles();

  const [open, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = async () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        endIcon={<BiWindowClose style={{ fill: "#ffffff" }} />}
        style={{
          color: "#ffffff",
          backgroundColor: "transparent",
          textTransform: "capitalize",
        }}
        className="savebutton"
        onClick={handleClickOpen}
      >
        Close
      </Button>
      <Dialog
        PaperProps={{
          className: "BootstrapSmallDialog",
        }}
        maxWidth="lg"
        classes={{ paper: classes.dialogWrapper }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogActions>
          <Button
            className="GreyButton DeleteCancelB2"
            autoFocus
            onClick={handleClose}
          >
            <Link className="Linkffffff" to={`/admin/CaretorMode/${folderId}`}>
              Exit Anyway{" "}
            </Link>
          </Button>
          <Button
            className="GreenButton  DeleteOkB2"
            autoFocus
            onClick={savePage}
          >
            <Link
              className="Linkffffff"
              to={{
                pathname: `/admin/folder/${folderId}`,
                state: { folders, folder },
              }}
            >
              Save & Exit
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  TableBody,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import axios from "axios";
import { useLocation } from "react-router-dom";

//core component
import DeleteDialog from "../../ActionButtonDialog's/LocomotorActionB/DeleteLocoDialog";
import SwitchDailog from "../../ActionButtonDialog's/LocomotorActionB/SwitchLocoDialog";
import EditDialog from "../../ActionButtonDialog's/LocomotorActionB/EdiLocomotor/LocoEditMain";
import AddProfile from "../../AddSkills/SkillAdd";
export default function SubSkills(props) {
  const [open, setOpen] = React.useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(); //
  const location = useLocation();
  const { getAllSkill } = location.data;

  // const { access } = location.skills;
  const {
    states: {
      mainSkill,
      setMainSkill,
      subSkillName,
      setSubSkillName,
      subSkillUid,
      setSubSkillUid,
      subSkillDesc,
      setSubSkillDesc,
      xpAssigned,
      setXpAssigned,
      level,
      setLevel,
      rubricsQts,
      setRubricsQts,
      skill,
    },
  } = props;
  const rubricsQus = [];
  rubricsQus.push(skill);
  console.log(rubricsQus, "rubricsQus>>>>>>>>>>>>>");
  return (
    <>
      <Grid container>
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          item
          className="SkillRubDialofGrid"
        >
          <Grid
            container
            md={12}
            sm={12}
            xs={12}
            lg={12}
            item
            className="DiviStyle "
          >
            <Typography></Typography>

            <AddProfile />
          </Grid>
        </Grid>
      </Grid>
      <div className="LargeDialogLRBStyle">
        <div
          className="ResponsiveTableHaedBlack"
          style={{ marginRight: "22px" }}
        >
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="HeadAL">
                <TableCell className="ColHead">Q.no</TableCell>
                <TableCell className="ColHead">Question </TableCell>
                <TableCell className="ColHead"> Total Marks</TableCell>
                <TableCell className="ColHead"> Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rubricsQus.map((rbsqs) =>
                rbsqs.rubricsQts.map((qs) => (
                  <TableRow>
                    <TableCell className="Col">{qs.quesNo}</TableCell>
                    <TableCell className="Col">{qs.quesDesc}</TableCell>
                    <TableCell className="Col">{qs.quesMarks}</TableCell>
                    <TableCell className="Action">
                      <IconButton className="ActionButtonSize">
                        <EditDialog
                          states={{
                            mainSkill,
                            setMainSkill,
                            subSkillName,
                            setSubSkillName,
                            subSkillUid,
                            setSubSkillUid,
                            subSkillDesc,
                            setSubSkillDesc,
                            xpAssigned,
                            setXpAssigned,
                            level,
                            setLevel,
                            rubricsQts,
                            setRubricsQts,
                          }}
                        />
                      </IconButton>
                      <IconButton className="ActionButtonSize">
                        <SwitchDailog
                          // accessCheck={access}
                          getAllSkill={getAllSkill}
                          SkillsId={id}
                        />
                      </IconButton>
                      {/* <DeleteDialog /> */}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>{" "}
      </div>
    </>
  );
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
const AssesmentPopUp = () => {
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <Grid container style={{ padding: "20px" }}>
        <Grid xs={12}>
          <Typography style={{ textAlign: "center" }}>
            {" "}
            Assesment type
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginTop: "20px" }}>
          <Grid
            container
            style={{
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Grid item xs={4}>
              <Grid container style={{ justifyContent: "center" }}>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <RadioGroup
                    aria-label="Assessmet Type"
                    name="Assessmet Type"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Not Yet Type"
                      control={<Radio />}
                      label="Not Yet Type"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container style={{ justifyContent: "center" }}>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <RadioGroup
                    aria-label="Assessmet Type"
                    name="Assessmet Type"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes/No Type"
                      control={<Radio />}
                      label="Yes/No Type"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AssesmentPopUp;

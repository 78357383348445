import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
} from "@material-ui/core";

//Core Component
import LocoDeleteDialog from "../ActionButtonDialog's/LocomotorActionB/DeleteLocoDialog";
import LocoEdit from "../ActionButtonDialog's/LocomotorActionB/EdiLocomotor/LocoEditMain";
import LocoSwitch from "../ActionButtonDialog's/BodyMgtActionB/BMSwitchDialog";
import GSkillProfile from "../GSkillProfile/GSkillProfile";

export default function LocomotorTab(props) {
  const { TabNext } = props;
  const [access, setAccess] = useState(1);

  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(); //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    skillsData,
    getAllSkill,
  } = props;
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };
  // const loadUsers = async () => {
  //   const res = await axios.get(
  //     "https://jsonplaceholder.typicode.com/comments "
  //   );
  //   setPosts(res.data);
  //   setTotal(res.data.length);
  // };

  // useEffect(() => {
  //   loadUsers();
  // }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="ColHead">UID</TableCell>
              <TableCell className="ColHead">Name of Skill</TableCell>
              <TableCell className="ColHead">Description</TableCell>
              <TableCell className="ColHead"> Assigned On </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {skillsData &&
              skillsData.length &&
              skillsData.map((skills, index) => (
              <TableRow>
                <TableCell className="Col">
                  <Link> {skills.subSkillUid} </Link>
                </TableCell>
                <TableCell className="Col">
                  <Link>
                    {" "}
                    {skills.subSkillName}
                    {/* <GSkillProfile />{" "} */}
                  </Link>
                </TableCell>
                <TableCell className="Col">
                {skills.subSkillDesc}
                </TableCell>
                <TableCell className="Col">14/09/2021</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="paginationStyle">
        <Pagination
          onChange={(value) => setPage(value)}
          pageSize={rowsPerPage}
          total={total}
          current={page}
          showSizeChanger
          showQuickJumper
          onShowSizeChange={onShowSizeChange}
          itemRender={itemRender}
        />
      </div>
    </>
  );
}

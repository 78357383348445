import React, { useState } from "react";
import {
  Input,
  Button,
  Grid,
  Typography,
  DialogActions,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function ExtendTab({ agreement, setAgreement, submitClick }) {
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const handleFileChange = (files) => {
    if (files.length > 0) {
      setAgreement(files[0]);
    }
  };

  return (
    <>
      <Grid container justify="center" spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          lg={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <DropzoneArea
            filesLimit={1}
            maxFileSize={2097152}
            // acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
            acceptedFiles={["application/pdf"]}
            onChange={handleFileChange}
            useChipsForPreview={true}
            dropzoneText="Upload Agreement"
            multiple={false}
          />
          <Typography className="ALAddSkilltypography">
            Support only .pdf format (max file size 2 MB)
          </Typography>
          {agreement && (
            <Typography className="ALAddSkilltypography">
              {agreement?.path}
            </Typography>
          )}

          <DialogActions>
            <Button
              type="submit"
              className="SumbitButton"
              onClick={submitClick}
            >
              Submit
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  Input,
  InputAdornment,
  IconButton,
  Toolbar,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import LoaderDialog from "components/Loader/LoaderDialog";

// ReactIcons
import { GoSearch } from "react-icons/go";
import { useSelector } from "react-redux";
// core components
import AssessmentAdd from "./AddAssements/AssesmentAdd";
import AssesforTrianer from "./AddforTrainerAssesment/AssesmentAdd-Trainer";
import AssesmentTabs from "./AssesmentsTabMain";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function ExtendedTables() {
  const { userToken } = useLogin();
  const [grade, setGrade] = React.useState("");
  const [assesvalue, setAssesValue] = React.useState(0);
  const [value, setValue] = useState(0);

  const [totalA, setTotalA] = useState();
  const [pageA, setPageA] = useState(1);
  const [totalR, setTotalR] = useState();
  const [pageR, setPageR] = useState(1);

  const [searchA, setSearchA] = useState("");
  const [searchR, setSearchR] = useState("");

  const GradeChange = (event) => {
    setGrade(event.target.value);
  };

  const [school, setSchool] = React.useState("");
  const handleChange = (event) => {
    setSchool(event.target.value);
  };
  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const [assessmentdata, setAssesmentData] = useState(null);
  const [learnerAssessmentData, setLearnerAssessmentData] = useState(null);
  const [trainerAssessmentData, setTrainerAssessmentData] = useState(null);
  const [learnerAssesmentResult, setLearnerAssesmentResult] = useState([]);
  ////////////////////////////////////////////////////////////////
  const [schoolLoc, setSchoolLoc] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState("");
  const [schoolLocationSelected, setSchoolLocationSelected] = useState("");
  const [locationForGrade, setLocationForGrade] = useState();
  const [selectGrade, setSelectGrade] = useState([]);
  const [gradeSelected, setGradeSelected] = useState("");
  const [loading, setLoading] = useState(true);

  ////////////////////////////////////////////////////////////
  const LearnerTrainerTabValue = useSelector(
    (state) => state.GetTabvalue.LTValue
  );
  const AssesmentresultTabValue = useSelector(
    (state) => state.GetTabvalue.ARValue
  );

  // console.log(LearnerTrainerTabValue, "LearnerTrainerTabValue>>>>>>>>>>>>>");
  // console.log(AssesmentresultTabValue, "AssesmentresultTabValue>>>>>>");
  //////////////////////////////////////////////////////////////////

  // For DropDown
  const getDistinctSchoolAndLocation = async (nameOfSchool) => {
    try {
      setGradeSelected("");
      setSchoolLocationSelected("");
      const response = await fetch(
        `${FETCH_URL}/api/school/getDistinctSchoolAndLocation?schoolName=${
          nameOfSchool ? nameOfSchool : "empty"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My Distinct schools ============>", res);
        if (nameOfSchool) {
          console.log("If condition coming", nameOfSchool);
          setSchoolLoc(res.msg);
        } else {
          //.log("Else condition coming", nameOfSchool);
          setSchoolNames(res.msg);
        }
      } else {
        console.log("Error while fetching distinct school", res.err);
      }
    } catch (error) {
      //.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////

  const getGrades = async (location) => {
    setGradeSelected("");
    setLocationForGrade(location);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getDistinctGrades?schoolName=${schoolSelected}&location=${location}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response for grade 123 ============>", res);
        setSelectGrade(res.msg);
      } else {
        console.log("Error while fetching dropdown data", res.err);
      }
    } catch (error) {
      console.error("Catch block ====>", error);
    }
  };

  ////////////////////////////////////////////////////////////
  // Get API
  const getAllAssesmentLearner = async () => {
    try {
      const response = await fetch(
        // `${FETCH_URL}/api/assessment/getAllAssessments?isLearner=true`,
        `${FETCH_URL}/api/assessment/getPaginatedAssessments?isLearner=true&page=1&limit=10`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setLearnerAssessmentData(res.msg);
        setLoading(false);
        setTotalA(res.lengthData);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // Get API
  const getAllAssesmentTrainer = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAllAssessments?isTrainer=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTrainerAssessmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // /////////////paginated Learner////////////////////////////////////
  const getPaginatedAssesmentLearner = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAllAssessments?isLearner=true&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setLearnerAssessmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  // /////////////paginated Learner////////////////////////////////////

  // /////////////paginated Trainer////////////////////////////////////
  const getPaginatedAssesmentTrainer = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getAllAssessments?isTrainer=true&schoolName=${schoolSelected}&location=${locationForGrade}&grade=${grade}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Trainers==>", res);
        setTrainerAssessmentData(res.msg);
      } else {
        console.log("Error while fetching trainers", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  // /////////////paginated Trainer////////////////////////////////////
  //////////////////////Result APi/////////////////////////////////////
  const getAssesmentResults = async (grade) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getpaginatedFilteredAssessmentResult?isLearner=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // console.log("learnerAssesmentResult", res);
        console.log("result", res.msg);
        setLearnerAssesmentResult(res.msg);
        setTotalR(res.lengthData);
      } else {
        console.log("Error while fetching Assesment result", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  //////////////////////Result APi/////////////////////////////////////
  //////////////////////handle search ///////////////////////////////
  const handleSearch = async (searchValue, pageNum, rowSize) => {
    if (AssesmentresultTabValue === 0) {
      let url;
      setPageA(pageNum ? pageNum : 1);
      setLoading(true);
      // if (LearnerTrainerTabValue === 0) {
      // if (searchValue?.length === 0) {
      //   getAllAssesmentLearner();
      //   getAllAssesmentTrainer();
      // }
      if (searchValue) {
        setSchoolSelected("");
        setSchoolLocationSelected("");
        url = `${FETCH_URL}/api/assessment/getPaginatedAssessments?isLearner=true&search=${searchValue}`;
      } else {
        url = `${FETCH_URL}/api/assessment/getPaginatedAssessments?isLearner=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`;
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLearnerAssessmentData(res.msg);
        setLoading(false);
        // setTrainerAssessmentData(res.msg);
        console.log("My searching course response ==>", res);

        // getAllGrades();
      } else {
        setLoading(false);
        console.log("Error while searching course", res.err);
      }
      // }

      // else {
      //   if (searchValue.length === 0) {
      //     getAllAssesmentLearner();
      //     getAllAssesmentTrainer();
      //   }
      //   console.log("My handling of search ========>", searchValue);
      //   const response = await fetch(
      //     `${FETCH_URL}/api/assessment/getPaginatedAssessments?isTrainer=true&search=${searchValue}&page=${
      //       pageNum ? pageNum : 1
      //     }&limit=${rowSize ? rowSize : 10}`,
      //     {
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Accept: "application/json",
      //         Authorization: `Bearer ${userToken}`,
      //       },
      //     }
      //   );
      //   const res = await response.json();
      //   if (response.ok) {
      //     setTrainerAssessmentData(res.msg);
      //     // setTrainerAssessmentData(res.msg);
      //     console.log("My searching course response ==>", res);

      //     // getAllGrades();
      //   } else {
      //     console.log("Error while searching course", res.err);
      //   }
      // }
    } else {
      console.log("this is result search response");

      let url;
      setPageR(pageNum ? pageNum : 1);
      setLoading(true);
      if (searchValue) {
        setSchoolSelected("");
        setSchoolLocationSelected("");
        url = `${FETCH_URL}/api/assessment/getpaginatedFilteredAssessmentResult?isLearner=true&search=${searchValue}`;
      } else {
        url = `${FETCH_URL}/api/assessment/getpaginatedFilteredAssessmentResult?isLearner=true&page=${
          pageNum ? pageNum : 1
        }&limit=${rowSize ? rowSize : 10}`;
      }
      console.log("My handling of search ========>", searchValue);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLearnerAssesmentResult(res.msg);
        console.log("Result", res.msg);
        setLoading(false);
        // setTrainerAssessmentData(res.msg);
        console.log("My searching course response ==>", res);

        // getAllGrades();
      } else {
        setLoading(false);
        console.log("Error while searching course", res.err);
      }
    }
  };
  /////////////////////handle search/////////////////////////////////
  // console.log(assessmentdata, "assessmentdata>>>>>>>>>>>>>>>>");

  // useEffect(() => {
  //   handleSearch(search);
  // }, [search, value]);
  useEffect(() => {
    // getAllAssesment();
    getAllAssesmentLearner();
    // getAllAssesmentTrainer();
    getDistinctSchoolAndLocation();

    getAssesmentResults();
  }, []);

  useEffect(() => {
    getAllAssesmentLearner();
    setSchoolLocationSelected("");
    setGradeSelected("");
    setSchoolLoc([]);
  }, [schoolSelected == 0 || ""]);
  return (
    <>
      <Toolbar className="headtoolbar">
        <Typography className="Title">Assessments</Typography>
        <div style={{ display: "flex" }}>
          <Box>
            <div>
              <FormControl className="inputcontrol">
                {value == 0 ? (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); // Add debouncing for this
                      setSearchA(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                ) : (
                  <Input
                    disableUnderline
                    className="input"
                    placeholder="Search"
                    onChange={(e) => {
                      console.log("Search Value =========>", e.target.value); // Add debouncing for this
                      setSearchR(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className="HeadSearchButton">
                          <GoSearch className=" HaedAddIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Serach"
                  />
                )}
              </FormControl>
            </div>
          </Box>
          {assesvalue == 0 ? (
            <AssessmentAdd getAllAssesmentLearner={getAllAssesmentLearner} />
          ) : (
            <AssesforTrianer
              getAllAssesmentTrainer={getAllAssesmentTrainer}
              // getAllAssesment={getAllAssesment}
            />
          )}
        </div>
      </Toolbar>
      <LoaderDialog loading={loading} />

      <Grid container>
        <Grid item md={3} sm={3} xs={3} lg={3}>
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolSelected ? schoolSelected : 0}
              // onChange={handleChange}
              label="Select School"
              color="white"
              disableUnderline
              onChange={(e) => {
                getDistinctSchoolAndLocation(e.target.value);
                setSchoolSelected(e.target.value);
              }}
            >
              <MenuItem className="Selectmenustyle" value={0}>
                All School
              </MenuItem>
              {schoolNames &&
                schoolNames.length > 0 &&
                schoolNames.map((school) => (
                  <MenuItem value={school._id} className="Selectmenustyle">
                    {school._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={schoolLocationSelected ? schoolLocationSelected : 0}
              onChange={(e) => {
                setSchoolLocationSelected(e.target.value);
                getGrades(e.target.value);
              }}
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Location
              </MenuItem>
              {schoolLoc &&
                schoolLoc.length > 0 &&
                schoolLoc.map((location) => (
                  <MenuItem value={location._id} className="Selectmenustyle">
                    {location._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2} className="MainPageFCIG">
          <FormControl className="MainPageFormControl">
            <Select
              className=" Selectdropstyle"
              value={gradeSelected ? gradeSelected : 0}
              onChange={(e) => {
                getPaginatedAssesmentLearner(e.target.value);
                getPaginatedAssesmentTrainer(e.target.value);
                setGradeSelected(e.target.value);
              }}
              label="Select Grade"
              disableUnderline
            >
              <MenuItem className="Selectmenustyle" value={0}>
                Select Grade
              </MenuItem>
              {selectGrade &&
                selectGrade.length > 0 &&
                selectGrade.map((a) => (
                  <MenuItem className="Selectmenustyle" value={a._id}>
                    {a._id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <AssesmentTabs
        getAllAssesmentLearner={getAllAssesmentLearner}
        getAllAssesmentTrainer={getAllAssesmentTrainer}
        getAssesmentResults={getAssesmentResults}
        handleSearch={handleSearch}
        value={value}
        setValue={setValue}
        states={{
          learnerAssessmentData,
          setLearnerAssessmentData,
          trainerAssessmentData,
          setTrainerAssessmentData,
          learnerAssesmentResult,
          setLearnerAssesmentResult,
          assesvalue,
          setAssesValue,
          schoolSelected,
          setSchoolSelected,
          setSchoolLocationSelected,
          setGradeSelected,
          totalA,
          pageA,
          totalR,
          pageR,
          searchA,
          searchR,
        }}
      />
    </>
  );
}

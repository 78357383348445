import * as React from "react";
import { Input, Grid, Typography, Button } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";

export default function ExtendTab({ submitClick }) {
  return (
    <div>
      <Grid container spacing={4} className="DailogLTRBStyle">
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          direction="column"
          style={{ marginTop: "1px" }}
        >
          <Grid container direction="column" item style={{ marginLeft: "3%" }}>
            <Typography className="BlueColorTypography AddDocumentTypography">
              Document Name{" "}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input className="Input1"></Input>
            </Grid>
            <Typography className="BlueColorTypography AddDocumentTypography">
              Document Name{" "}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input className="Input1"> </Input>
            </Grid>
            <Typography className="BlueColorTypography AddDocumentTypography">
              Document Name{" "}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input className="Input1"> </Input>
            </Grid>
            <Typography className="BlueColorTypography AddDocumentTypography">
              Document Name{" "}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input className="Input1"> </Input>
            </Grid>
            <Typography className="BlueColorTypography AddDocumentTypography">
              Document Name{" "}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input className="Input1"> </Input>
            </Grid>
            <Typography className="BlueColorTypography AddDocumentTypography">
              Document Name{" "}
            </Typography>
            <Grid item container md={10} sm={10} xs={10}>
              <Input className="Input1"> </Input>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6} direction="column">
          <DropzoneArea
            filesLimit={3}
            maxFileSize={2097152}
            acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
            // onChange={(e) => handleImageChange(e)}
            useChipsForPreview={true}
            dropzoneText="Upload Docs"
          />
          <Button className="DocumnetSubmit" onClick={submitClick}>
            {" "}
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import {
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";
//Core Component
import AssesmentProfile from "../AssesmentProfile/AssesmentProfile";
export default function BasicTabs(props) {
  const {
    states: { learnerAssessmentData, schoolSelected, totalA, pageA, searchA },
    handleSearch,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [value, setValue] = React.useState(0);
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize);
  };

  const itemRender = (current, type, orignalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }

    return orignalElement;
  };

  return (
    <>
      <div className="ResponsiveTable">
        <Table className="Tableroot">
          <TableHead>
            <TableRow className="Head">
              <TableCell className="Head">UID </TableCell>
              <TableCell> Assessment Name</TableCell>
              <TableCell className="Head"> Description</TableCell>
              <TableCell className="Head"> PP</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {learnerAssessmentData &&
              learnerAssessmentData.length > 0 &&
              learnerAssessmentData.map((assesmnet) => (
                <TableRow>
                  <TableCell className="Col">
                    {" "}
                    <Link> {assesmnet?.uid} </Link>{" "}
                  </TableCell>
                  <TableCell className="Col width100px">
                    <Link>
                      <AssesmentProfile assesmnet={assesmnet} />
                    </Link>
                  </TableCell>
                  <TableCell className="Col">
                    <Typography align="center">
                      <Typography
                        align="center"
                        className="GreyColorTypography500 overflow-style-nwidth width100px"
                      >
                        {assesmnet?.assessmentDesc}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className="Col">{assesmnet?.assignedXp}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {learnerAssessmentData?.length === 0 ? (
          <Grid
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography>
              <Typography className="width100 DarkBlackColorTypo">
                No Assessment Found!
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </div>
      {schoolSelected ||
      searchA ||
      learnerAssessmentData?.length == 0 ? null : (
        <div className="paginationStyle">
          <Pagination
            onChange={(value, value2) => {
              handleSearch("", value, value2);
              // setPage(value);
            }}
            pageSize={rowsPerPage}
            total={totalA}
            current={pageA}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
            itemRender={itemRender}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { Box, Tab, Tabs } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  saveLearnerTrainerTabValue,
  saveAssestmentResultTabValue,
} from "redux/action/TableAction";
//Core Component
import AssesmentTab from "./AssesmenTab's/AssesmentTab";
import ResultTab from "./AssesmenTab's/ResultTab";

// React Icons
import { BsFileEarmark } from "react-icons/bs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssesmentTabs(props) {
  const {
    states: {
      assessmentdata,
      learnerAssessmentData,
      setLearnerAssessmentData,
      trainerAssessmentData,
      setTrainerAssessmentData,
      assesvalue,
      setAssesValue,
      schoolSelected,
      setSchoolSelected,
      setSchoolLocationSelected,
      setGradeSelected,
      learnerAssesmentResult,
      setLearnerAssesmentResult,
      totalA,
      pageA,
      searchA,
      totalR,
      pageR,
      searchR,
    },
    // getAllAssesment,
    handleSearch,
    getAllAssesmentTrainer,
    getAllAssesmentLearner,
    value,
    setValue,
    getAssesmentResults,
  } = props;
  const dispatch = useDispatch();

  const TabChange = (event, newValue) => {
    setValue(newValue);
    setSchoolSelected("");
    setSchoolLocationSelected("");
    setGradeSelected("");
    dispatch(saveAssestmentResultTabValue(newValue));
  };

  return (
    <>
      <Box style={{ marginTop: "20px " }}>
        <Tabs
          value={value}
          onChange={TabChange}
          aria-label="basic tabs example"
          className="AssesmentTabsStyle"
          centered
        >
          <Tab
            className="ActivityTabChanges"
            label="Assessment"
            {...a11yProps(0)}
          />
          <Tab
            className="ActivityTabChanges"
            label="Result"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <AssesmentTab
          states={{
            assessmentdata,
            learnerAssessmentData,
            schoolSelected,
            setLearnerAssessmentData,
            trainerAssessmentData,
            setTrainerAssessmentData,
            assesvalue,
            setAssesValue,
            totalA,
            pageA,
            searchA,
          }}
          // getAllAssesment={getAllAssesment}
          handleSearch={handleSearch}
          getAllAssesmentTrainer={getAllAssesmentTrainer}
          getAllAssesmentLearner={getAllAssesmentLearner}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ResultTab
          states={{
            learnerAssesmentResult,
            setLearnerAssesmentResult,
            schoolSelected,
            totalR,
            pageR,
            searchR,
          }}
          getAssesmentResults={getAssesmentResults}
          handleSearch={handleSearch}
        />
      </TabPanel>
    </>
  );
}

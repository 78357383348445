import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextareaAutosize,
  makeStyles,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { IoCloseSharp } from "react-icons/io5";
import { BiBook } from "react-icons/bi";
import { useState } from "react";
//core Module
import AssignLessonPlan from "./TLessonPlanTab/TAssignLP";
import RenameTab from "./TLessonPlanTab/TLPRenameTab";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CommonPagination from "components/CommonPagination/CommonPagination";
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: theme.spacing(200),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {/* <Typography className="DialogTitleTypo">Assign LessonPlan</Typography> */}
      {children}
      {onClose ? (
        <IconButton
          className="DialogCloseButton"
          aria-label="close"
          onClick={onClose}
        >
          <IoCloseSharp className="DialogCloseIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssignLPDialog({ trainerId }) {
  const classes = useStyles();
  const { userToken } = useLogin();
  const [scroll, setScroll] = React.useState("paper");
  ////////////////////////////////////////////////////////
  const [lessonPlanUid, setLessonPlanUid] = React.useState("");
  const [lessonPlanTitle, setLessonPlanTitle] = React.useState("");
  const [lessonPlanStartDate, setLessonPlanStartDate] = React.useState(null);
  const [lessonPlanEndate, setLessonPlanEndate] = React.useState(null);
  //////////////////////////////////////////////////////////////////////
  const [open, setOpen] = React.useState(false);
  //////////////////
  const [selectLessonPlanText, setSelectLessonPlanText] = React.useState("");
  const [lessonPlanUidText, setLessonPlanUidText] = React.useState("");
  const [lessonPlanTitleText, setLessonPlanTitleText] = React.useState("");
  // SnackBar

  const [snackopen, setSnackOpen] = useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackErrMsg, setSnackErrMsg] = useState();
  const [snackerropen, setSnackerropen] = useState(false);
  const [lessonPlanId, setLessonPlanId] = React.useState("");
  const [total, setTotal] = useState(10);
  const [page, setPage] = useState(1);
  function getDataFromPagination(a) {
    setPage(a);
  }
  const SnanbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackMsg("");
  };
  const SnackbarErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackerropen(false);
    setSnackErrMsg("");
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOriginalData([]);
    setLessonPlanUid("");
    setLessonPlanTitle("");
    setSelectLessonPlanText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setLessonPlanStartDate(null);
    setLessonPlanEndate(null);
    setLessonPlanId("");

    setValue(0);
  };
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    // if (filterdata.length === 0) {
    //   setSelectLessonPlanText("please select a lesson plan!");
    //   return;
    // }
    if (!lessonPlanId) {
      setSelectLessonPlanText("please select a lesson plan!");
      return;
    }
    setSelectLessonPlanText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setValue(newValue);
  };
  function TabNext() {
    // if (filterdata.length === 0) {
    //   setSelectLessonPlanText("please select a lesson plan!");
    //   return;
    // }
    if (!lessonPlanId) {
      setSelectLessonPlanText("please select a lesson plan!");
      return;
    }
    setSelectLessonPlanText("");
    setLessonPlanUidText("");
    setLessonPlanTitleText("");
    setValue(1);
  }
  const [lessonplan, setLessonPlan] = React.useState(null);
  const [assigntrainerid, setAssignTrainerId] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]); // original array with undefined data

  const getAllLessonPlan = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans?pagination=true&limit=10&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response of Lesson Plan==>", res);
        setLessonPlan(res.msg);
        setAssignTrainerId();
      } else {
        console.log("Error while fetching LessonPlan", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  useEffect(() => {
    getAllLessonPlan();
  }, [page]);

  //////////////////////////////////////////////////////////
  const filterdata = originalData.filter((a) => {
    if (a !== undefined) {
      return a;
    }
  });
  console.log(filterdata, "filterdata");
  // const lessonPlanId = filterdata?.map((a) => a._id);
  // console.log(lessonPlanId, "lessonPlanId>>>>>>>>>>>>>>>>>>>>>>>>>");
  //////////////////////////////////////////////////////////
  // const assignedTrainers = filterdata
  //   .map((a) => a.assignedTrainers.map((b) => b.trainerId))
  //   .flat();

  const submitAssignLessonPlan = async (e) => {
    e.preventDefault();
    if (
      lessonPlanUid === null ||
      lessonPlanUid.length === 0 ||
      (lessonPlanUid === undefined && lessonPlanTitle === null) ||
      lessonPlanTitle === undefined ||
      lessonPlanTitle.length === 0
    ) {
      if (lessonPlanUid.length === 0) {
        setLessonPlanUidText("please enter lesson plan uid !");
      }

      if (lessonPlanTitle.length === 0) {
        setLessonPlanTitleText("please enter lesson plan title !");
      }
      return;
    }

    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/assignLessonPlanToTrainerFromTrainer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            trainerId,
            lessonPlanId: lessonPlanId,
            LPUid: lessonPlanUid,
            LPTitle: lessonPlanTitle,
            // LPStartDate: lessonPlanStartDate,
            // LPEndDate: lessonPlanEndate,
          }),
        }
      );

      // console.log("Assign Id", filtered);
      const res = await response.json();
      if (response.ok) {
        console.log("My assign lessonPlan response ==>", res);
        setSnackOpen(true);
        setSnackMsg(res.msg);
        handleClose();
      } else {
        console.log("Error while fetching single school", res.err);
        setSnackerropen(true);
        setSnackErrMsg(res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  return (
    <div>
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={SnanbarClose}>
        <Alert onClose={SnanbarClose} severity={"success"}>
          {snackmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackerropen}
        autoHideDuration={1000}
        onClose={SnackbarErrorClose}
      >
        <Alert onClose={SnackbarErrorClose} severity={"error"}>
          {snackErrMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="LessonPlan">
        <IconButton className="ActionButtonSize">
          <BiBook className="ActionButtonIcon" onClick={handleClickOpen} />
        </IconButton>
      </Tooltip>
      <Dialog
        PaperProps={{
          className: "BootstrapLargeDialog",
        }}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
      >
        <DialogTitle
          style={{ justifyContent: "space-around" }}
          id="scroll-dialog-title"
        >
          <Tabs
            value={value}
            centered
            onChange={TabChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="TabStyle"
              label="Select Lesson Plan"
              {...a11yProps(0)}
            />
            <Tab className="TabStyle" label="Rename" {...a11yProps(1)} />
          </Tabs>

          <BootstrapDialogTitle onClose={handleClose}></BootstrapDialogTitle>
        </DialogTitle>
        {/* TabsClose       */}
        <DialogContent dividers={false} className="TabPanelStyle">
          <TabPanel value={value} index={0}>
            <AssignLessonPlan
              states={{
                lessonplan,
                selectLessonPlanText,
                lessonPlanId,
                setLessonPlanId,
              }}
            />
            <CommonPagination
              getDataFromPagination={getDataFromPagination}
              total={total}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenameTab
              states={{
                lessonPlanUid,
                setLessonPlanUid,
                lessonPlanStartDate,
                setLessonPlanStartDate,
                lessonPlanEndate,
                setLessonPlanEndate,
                lessonPlanTitle,
                setLessonPlanTitle,
                lessonPlanUidText,
                lessonPlanTitleText,
              }}
            />
          </TabPanel>
        </DialogContent>

        <DialogActions className=" ACourseAction">
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={
              value == 0 ? TabNext : value == 1 ? submitAssignLessonPlan : null
            }
          >
            {value === 1 ? "Done" : "Next"}
          </Button>
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={
              value == 0 ? handleClose : value == 1 ? () => setValue(0) : null
            }
          >
            {value === 1 ? "Back" : "Cancel"}
          </Button>
        </DialogActions>
        {/* <DialogActions className=" ACourseAction">
          <Button
            className="GreyButton700 EquipActionBottom"
            onClick={handleClose}
          >
            {"Cancel"}
          </Button>
          <Button
            className="GreenButton700 EquipActionBottom"
            onClick={submitAssignLessonPlan}
          >
            {"Done"}
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { FETCH_URL } from "fetchIp";
import { useLogin } from "Context/ExportContext";

export default function GradeDetailTab({ rowData, data, setLoading }) {
  const { userToken } = useLogin();
  const [learner, setLearner] = useState(data?.assignedLearners?.length || 0);
  const [trainer, setTrainer] = useState(data?.assignedTrainers?.length || 0);
  const [course, setCourse] = useState(data?.assignedCourses?.length || 0);
  const [equipment, setEquipment] = useState(
    data?.assignedEquipments.length || 0
  );
  const [LessonPlan, setLessonPlan] = useState(
    data?.assignedLessonPlans.length || 0
  );
  const getGrade = async (gradeId) => {
    setLoading(true);
    const response = await fetch(
      `${FETCH_URL}/api/school/grade/getSingleGrade/${gradeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const res = await response.json();
    if (response.ok) {
      setLoading(false);
      setLearner(res.msg.assignedLearners.length);
      setTrainer(res.msg.assignedTrainers.length);
      setLessonPlan(res.msg.assignedLessonPlans.length);
      setCourse(res.msg.assignedCourses.length);
      setEquipment(res.msg.assignedEquipments.length);

      console.log("My School response from searching ==>", res);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGrade(data?.gradeId);
  }, []);

  const location = useLocation();

  // const { data } = location.state;

  return (
    <>
      <Grid container>
        <Grid
          item
          container
          sm={8}
          xs={12}
          lg={4}
          className="ScreenStyleTBR colorwhite"
        >
          <Grid item container direction="row" md={12}>
            <Grid item container md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography CardTypo1">
                School Name
              </Typography>
            </Grid>

            <Grid item container md={8} sm={8} xs={8}>
              <Typography className="GreyColorTypography CardTypo-1">
                {location.state?.data?.schoolName},
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" md={12}>
            <Grid item container md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography CardTypo1">
                Location Name
              </Typography>
            </Grid>
            <Grid item container md={8} sm={8} xs={8}>
              <Typography className="GreyColorTypography CardTypo-1">
                {location.state?.data?.location},
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" md={12} sm={12} xs={12}>
            <Grid item container md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography CardTypo1">
                Address
              </Typography>
            </Grid>
            <Grid item container md={8} sm={8} xs={8}>
              <Typography className="GreyColorTypography CardTypo-1">
                {rowData?.contactDetail.address}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" md={12} sm={12} xs={12}>
            <Grid item container md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography CardTypo1">
                ZipCode
              </Typography>
            </Grid>
            <Grid item container md={8} sm={8} xs={8}>
              <Typography className="GreyColorTypography CardTypo-1">
                {rowData?.contactDetail.zipCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" md={12} sm={12} xs={12}>
            <Grid item container md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography CardTypo1">
                State
              </Typography>
            </Grid>
            <Grid item container md={8} sm={8} xs={8}>
              <Typography className="GreyColorTypography CardTypo-1">
                {rowData?.contactDetail.state}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            md={12}
            sm={12}
            xs={12}
            style={{ marginBottom: "10px" }}
          >
            <Grid item container md={4} sm={4} xs={4}>
              <Typography className="BlueColorTypography CardTypo1">
                Country
              </Typography>
            </Grid>
            <Grid item container md={8} sm={8} xs={8}>
              <Typography className="GreyColorTypography CardTypo-1">
                {rowData?.contactDetail.country}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Card 2 */}
        <Grid item container lg={4} sm={8} xs={12} className="ScreenStyleTBR ">
          <Grid item container md={9} sm={9} xs={9} className="colorwhite">
            <Grid item container direction="row" md={9}>
              <Grid item container md={10}>
                <Typography className="BlueColorTypography CardTypo1">
                  Assigned Courses
                </Typography>
              </Grid>

              <Grid item container md={2} sm={2} xs={2}>
                <Typography className="GreyColorTypography CardTypo-1">
                  {course}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={9} sm={9} xs={9}>
              <Grid item container md={10} sm={10} xs={10}>
                <Typography className="BlueColorTypography CardTypo1">
                  Assigned LessonPlans
                </Typography>
              </Grid>
              <Grid item container md={2} sm={2} xs={2}>
                <Typography className="GreyColorTypography CardTypo-1">
                  {LessonPlan}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={9} sm={9} xs={9}>
              <Grid item container md={10} sm={10} xs={10}>
                <Typography className="BlueColorTypography CardTypo1">
                  Assigned Equipments
                </Typography>
              </Grid>
              <Grid item container md={2} sm={2} xs={2}>
                <Typography className="GreyColorTypography CardTypo-1">
                  {equipment}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={9} sm={9} xs={9}>
              <Grid item container md={10} sm={10} xs={10}>
                <Typography className="BlueColorTypography CardTypo1">
                  Active Learners
                </Typography>
              </Grid>
              <Grid item container md={2} sm={2} xs={2}>
                <Typography className="GreyColorTypography CardTypo-1">
                  {learner}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" md={9} sm={9} xs={9}>
              <Grid item container md={10} sm={10} xs={10}>
                <Typography className="BlueColorTypography CardTypo1">
                  Active Trainers
                </Typography>
              </Grid>
              <Grid item container md={2} sm={2} xs={2}>
                <Typography className="GreyColorTypography CardTypo-1">
                  {trainer}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container lg={4} xs={12}></Grid>
      </Grid>
    </>
  );
}
